export function getNotebookIFrameWindow(): HTMLIFrameElement | null {
  return document.querySelector('iframe[data-test-id="notebook-frame"]');
}

export function getNotebookIFrameContent(): Document | null {
  return getNotebookIFrameWindow()?.contentWindow?.document ?? null;
}

export function getNotebookIFrameContentDocument(): Document | null {
  return getNotebookIFrameWindow()?.contentDocument ?? null;
}

export function getNotebookElement(
  selector: string,
): HTMLElement | undefined | null {
  return getNotebookIFrameContent()?.querySelector(selector);
}

export function getNotebookElements(
  selector: string,
): NodeListOf<Element> | undefined | null {
  return getNotebookIFrameContent()?.querySelectorAll(selector);
}
