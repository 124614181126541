import React from 'react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { retry as reduxRetry } from '@reduxjs/toolkit/dist/query';
import { t } from '@lingui/macro';

import {
  HubSession,
  IframeState,
  Kernel,
  NOTEBOOK_FILES,
} from 'ui/notebook/notebookTypes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getUserName } from 'ui/auth/utils';
import { uiFlagsActions, NotebookSection } from 'app/slices/uiFlagsSlice';
import {
  CellFormat,
  jupyterSelectors,
  jupyterActions,
  SERVER_STATUS,
} from 'app/slices/jupyterSlice';
import { retry } from 'util/retry';
import {
  getNotebookElement,
  getNotebookIFrameContentDocument,
  getNotebookIFrameWindow,
} from 'ui/notebook/notebookUtils';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import {
  useGetJupyterStatusQuery,
  useDeleteJupyterProjectsDeleteFileMutation,
  usePostJupyterStartMutation,
  usePostJupyterProjectsRenameFileMutation,
  usePostJupyterProjectsCreateFileMutation,
  usePostJupyterProjectsCopyFileMutation,
  generatedApi,
} from 'app/apiGenerated/generatedApi';
import domSelectors from 'ui/notebook/jupyterHelpers/selectors';
import { downloadUrlFile, fileToBase64, nameToFileType } from 'util/fileUtils';
import { useAppParams } from 'util/useAppParams';
import { RootState } from 'app/store';
import { authActions } from 'app/slices/authSlice';
import { PostJupyterProjectsCreateFileApiArg } from 'app/apiGenerated/generatedApiTypes';
import { getAdminAssumedUser } from 'app/config/adminAssumedUser';
import { API_BASE_URL } from 'app/config/globalApplicationConfig';

export const API_TOKEN_DURATION = 12 * 3600;
export const API_TOKEN_SCOPE = 'jupyter';
export const JUPYTER_TOKEN_LOCAL_STORAGE_KEY = 'jupyterToken';
const MAX_UNKNOWN_RETRIES = 20;

const REDUCER_PATH = 'jupyterApi';

const baseQuery = fetchBaseQuery({
  baseUrl: `/hub`,
  prepareHeaders: (headers, { getState }) => {
    const xsrfToken = (getState() as RootState).auth.xsrfToken;
    headers.set('X-XSRFToken', xsrfToken);
    return headers;
  },
});
const retryBaseQuery = reduxRetry(baseQuery, { maxRetries: 3 });

export const jupyterApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: retryBaseQuery,
  tagTypes: ['Sessions', 'Kernels'],
  endpoints: (builder) => ({
    getSessions: builder.query<HubSession[], { user: string }>({
      query: ({ user }) => `user/${user}/api/sessions`,
      providesTags: ['Sessions'],
    }),
    getKernels: builder.query<Kernel[], { user: string }>({
      query: ({ user }) => `user/${user}/api/kernels?${Date.now()}`,
      providesTags: ['Kernels'],
    }),
    deleteKernel: builder.mutation<void, { user: string; kernelId: string }>({
      query: ({ user, kernelId }) => ({
        url: `user/${user}/api/kernels/${kernelId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Kernels', 'Sessions'],
    }),
  }),
});

export const {
  useGetSessionsQuery,
  useGetKernelsQuery,
  useDeleteKernelMutation,
} = jupyterApi;

export function useJupyter() {
  const dispatch = useAppDispatch();
  const { fileName } = useAppParams();
  const { showError, showCompletion } = useNotifications();
  const { hasAccessToJupyter } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const cellFormat = useAppSelector(jupyterSelectors.selectCellFormat);
  const isJupyterReady = useAppSelector(jupyterSelectors.selectIframeReady);
  const serverStatus = useAppSelector(jupyterSelectors.selectServerStatus);
  const user = getUserName() || '';
  const { useGetSessionsQuery, useGetKernelsQuery, useDeleteKernelMutation } =
    jupyterApi;
  const [callDeleteKernelApi] = useDeleteKernelMutation();
  const [callDeleteFileApi] = useDeleteJupyterProjectsDeleteFileMutation();
  const [callStartServerApi] = usePostJupyterStartMutation();
  const [callRenameFileApi] = usePostJupyterProjectsRenameFileMutation();
  const [callCreateFileApi] = usePostJupyterProjectsCreateFileMutation();
  const [callDuplicateJupyterFileApi] =
    usePostJupyterProjectsCopyFileMutation();

  const { data: sessions, refetch: refetchSessions } = useGetSessionsQuery(
    {
      user,
    },
    { skip: !isJupyterReady, refetchOnMountOrArgChange: true },
  );
  const { data: kernels } = useGetKernelsQuery(
    {
      user,
    },
    { skip: !isJupyterReady },
  );

  const { data: status, refetch: refetchStatus } = useGetJupyterStatusQuery(
    undefined,
    { skip: !hasAccessToJupyter },
  );

  React.useEffect(() => {
    const checkStatus = () =>
      new Promise<void>((resolve, reject) => {
        dispatch(
          generatedApi.endpoints.getJupyterStatus.initiate(undefined, {
            forceRefetch: true,
          }),
        ).then((data) => {
          if (data.data?.status !== 'online') {
            reject();
          } else {
            dispatch(jupyterActions.setserverStatus(SERVER_STATUS.LOADED));
            resolve();
          }
        });
      });
    if (serverStatus === SERVER_STATUS.NOT_LOADED && hasAccessToJupyter) {
      dispatch(jupyterActions.setserverStatus(SERVER_STATUS.LOADING));
      retry(checkStatus, 10, 1000);
    }
  }, [dispatch, refetchStatus, serverStatus, hasAccessToJupyter]);

  const fetchXsrfToken = React.useCallback(() => {
    const findXsrfToken = () =>
      new Promise<string>((resolve, reject) => {
        const xsrf = getNotebookIFrameContentDocument()
          ?.cookie.split('_xsrf=')?.[1]
          ?.split(';')?.[0];
        if (xsrf) {
          resolve(xsrf);
        } else {
          reject();
        }
      });
    return retry(findXsrfToken, 20, 1000).then((value) => {
      dispatch(authActions.setXsrfToken(value));
    });
  }, [dispatch]);

  const createFile = async (
    projectId: string,
    extension: string,
    name: string,
    content?: string,
  ) => {
    const args: PostJupyterProjectsCreateFileApiArg = {
      projectUuid: projectId,
      jupyterProjectFileCreateRequest: {
        name: `${name}.${extension}`,
      },
    };
    if (content) {
      args.jupyterProjectFileCreateRequest.content = content;
    }
    await callCreateFileApi(args);
    dispatch(jupyterActions.requestRefetchFiles());
  };

  const deleteFile = async (projectId: string, name: string) => {
    await callDeleteFileApi({
      projectUuid: projectId,
      filename: encodeURIComponent(name),
    });
    dispatch(jupyterActions.requestRefetchFiles());
  };

  const downloadFile = async (projectId: string, name: string) => {
    try {
      let downloadLink = `${API_BASE_URL}/jupyter/projects/${projectId}/files/${encodeURIComponent(
        name,
      )}`;

      const assumedUser = getAdminAssumedUser();
      if (assumedUser) {
        downloadLink += `?user_uuid=${assumedUser}`;
      }

      downloadUrlFile(downloadLink, name);
    } catch (e) {
      showError(
        t({
          id: 'jupyerApi.downloadFile.error',
          message:
            'There was an error downloading a Jupyter file. Please try again later',
        }),
        e,
      );
    }
  };

  const renameFile = async (
    oldName: string,
    newName: string,
    projectId: string,
  ) => {
    await callRenameFileApi({
      filename: encodeURIComponent(oldName),
      jupyterProjectFileRenameRequest: { name: newName },
      projectUuid: projectId,
    });
    dispatch(jupyterActions.requestRefetchFiles());
    return newName;
  };

  const duplicateFile = async (
    projectUuid: string,
    filename: string,
    name: string,
    destination_project_uuid: string,
  ) => {
    try {
      await callDuplicateJupyterFileApi({
        projectUuid,
        filename: encodeURIComponent(filename),
        jupyterProjectFileCopyRequest: { name, destination_project_uuid },
      });

      dispatch(jupyterActions.requestRefetchFiles());
    } catch (e) {
      showError(
        t({
          id: 'jupyerApi.duplicateFile.error',
          message: 'There was an error duplicating Jupyter file.',
        }),
        e,
      );
    }
  };

  const deleteKernels = (kernelIds: string[]) => {
    let sameFileName = false;
    for (const kernelId of kernelIds) {
      const session = sessions?.find(
        (session) => session.kernel.id === kernelId,
      );
      if (session?.name === fileName) {
        sameFileName = true;
      }
      callDeleteKernelApi({ user, kernelId });
    }
    showCompletion(
      t({
        id: 'jupyterApi.deleteKernels.success',
        message: 'Kernel deleted successfully',
      }),
    );
    if (sameFileName) {
      dispatch(uiFlagsActions.setNotebookSection(NotebookSection.None));
    }
  };

  const uploadFile = async (projectId: string, file?: File) => {
    if (!projectId || !file) {
      return null;
    }
    try {
      const result = await fileToBase64(file);
      const blob = result.split('base64,')[1];
      const fileType = nameToFileType(file.name);
      const fileData = NOTEBOOK_FILES[fileType];
      const fileName = file.name.slice(0, file.name.lastIndexOf('.'));
      createFile(projectId, fileData.extension, fileName, blob);
      showCompletion(
        t({
          id: 'jupyterApi.uploadFile.success',
          message: 'File uploaded successfully',
        }),
      );
    } catch (e) {
      showError(
        t({
          id: 'jupyerApi.uploadFile.error',
          message:
            'There was an error uploading the Jupyter file. Please try again later',
        }),
        e,
      );
    }
  };

  const setCellFormat = (cellFormat: CellFormat) => {
    dispatch(jupyterActions.setCellFormat(cellFormat));
  };

  const changeIframeCellFormat = (cellFormat: CellFormat) => {
    const el = getNotebookElement(
      domSelectors.cellTypeSelect(),
    ) as HTMLSelectElement;
    el.value = cellFormat.value;
    el.dispatchEvent(new Event('change', { bubbles: false, cancelable: true }));
  };

  const startServer = React.useCallback(
    () => callStartServerApi().unwrap(),
    [callStartServerApi],
  );

  /**
   * Checks the iframe content and returns a state based on it
   */
  const iframeCheck = React.useCallback((): IframeState => {
    const { header, loader, errorMessage, modalTitle, serverMessage } =
      domSelectors;

    const isLoading = !!getNotebookElement(loader());

    if (isLoading) {
      return IframeState.LOADING;
    }

    // Error messages
    const errorText = getNotebookElement(errorMessage())?.innerText || '';
    if (errorText.startsWith('404')) {
      return IframeState.NOT_FOUND;
    }

    // Modal messages
    const modalText = getNotebookElement(modalTitle())?.innerText || '';
    if (modalText === 'Connection failed') {
      return IframeState.CONNECTION_FAILED;
    }
    // Server messages
    const messageText = getNotebookElement(serverMessage())?.innerText || '';
    if (messageText.startsWith('Your server is not running')) {
      return IframeState.SERVER_STOPPED;
    }
    if (messageText.startsWith('Your server is starting up')) {
      return IframeState.SERVER_STARTING;
    }
    // Ready
    const hasHeader = getNotebookElement(header());
    if (hasHeader) {
      return IframeState.READY;
    }
    return IframeState.UNKNOWN;
  }, []);

  const delay = <T>(t: number) =>
    new Promise<T>((resolve) => {
      setTimeout(resolve.bind(null), t);
    });

  /**
   * Analyzes the status of the iframe using the "iframeCheck" and based on its
   * state, it will decide if it needs to wait more, to finish successfully
   * or fail
   */
  const monitorIframeStatus =
    React.useCallback((): Promise<IframeState | void> => {
      let unknownRetries = 0;
      const state = iframeCheck();
      if (state === IframeState.UNKNOWN) {
        unknownRetries += 1;
      } else {
        unknownRetries = 0;
      }
      switch (state) {
        case IframeState.SERVER_STARTING:
        case IframeState.SERVER_STOPPED:
          getNotebookIFrameWindow()?.contentDocument?.location?.reload?.();
          return Promise.reject(state);
        case IframeState.LOADING:
        case IframeState.CONNECTION_FAILED:
          break;
        case IframeState.READY:
          return Promise.resolve(state);
        case IframeState.UNKNOWN:
          if (unknownRetries === MAX_UNKNOWN_RETRIES) {
            return Promise.reject(state);
          }
          break;
        case IframeState.NOT_FOUND:
          return Promise.reject(state);
        default:
          break;
      }
      return delay<void>(200).then(monitorIframeStatus);
    }, [iframeCheck]);

  const initIframe = React.useCallback(() => {
    dispatch(jupyterActions.setIframeReady(false));
    return startServer()
      .then(fetchXsrfToken)
      .then(monitorIframeStatus)
      .then((state) => {
        dispatch(jupyterActions.setIframeReady(true));
        return state;
      });
  }, [dispatch, startServer, fetchXsrfToken, monitorIframeStatus]);

  const insertCell = React.useCallback(() => {
    getNotebookElement(domSelectors.insertCellButton())?.click();
  }, []);

  const cutCell = React.useCallback(() => {
    getNotebookElement(domSelectors.cutCellButton())?.click();
  }, []);

  const saveFile = React.useCallback(() => {
    getNotebookElement(domSelectors.saveFileButton())?.click();
  }, []);

  const saveNotebook = React.useCallback(() => {
    getNotebookElement(domSelectors.saveNotebookButton())?.click();
  }, []);

  const copyCell = React.useCallback(() => {
    getNotebookElement(domSelectors.copyCellButton())?.click();
  }, []);

  const pasteCell = React.useCallback(() => {
    getNotebookElement(domSelectors.pasteCellButton())?.click();
  }, []);

  const runCell = React.useCallback(() => {
    getNotebookElement(domSelectors.runCellButton())?.click();
  }, []);

  const stopKernel = React.useCallback(() => {
    getNotebookElement(domSelectors.stopKernelButton())?.click();
  }, []);

  const restartKernel = React.useCallback(() => {
    getNotebookElement(domSelectors.restartKernelButton())?.click();
  }, []);

  const restartRerun = React.useCallback(() => {
    getNotebookElement(domSelectors.restartRerunButton())?.click();
  }, []);

  return {
    createFile,
    copyCell,
    cutCell,
    deleteFile,
    downloadFile,
    deleteKernels,
    initIframe,
    insertCell,
    pasteCell,
    refetchSessions,
    runCell,
    renameFile,
    duplicateFile,
    stopKernel,
    restartKernel,
    restartRerun,
    saveFile,
    saveNotebook,
    setCellFormat,
    startServer,
    uploadFile,
    changeIframeCellFormat,
    status,
    sessions,
    kernels,
    cellFormat,
  };
}
