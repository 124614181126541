import React from 'react';
import { PyodideWorkerMessageType } from './PyodideWorkerMessageTypeEnum';

// WARNING: pyodide is downloaded by index.html and the TypeScript version
// must match otherwise you'll end up with mismatching API definitions.

// FIXME: 0.0.1 is not the real version of the whl. We renamed it
// to simplify the deployment of the wheel (version MUST be in the filename).
// In the future we should let micropip find pycollimator on pypi and add
// an option to override it (from env configs?) for local testing.
const PYCOLLIMATOR_WHEEL = `${process.env.PUBLIC_URL}/wheels/pycollimator-0.0.1-py3-none-any.whl`;

interface PythonProviderProps {
  children?: React.ReactNode;
  pyCollimatorWheelUrl?: string;
}

interface PyodideSubInterface {
  runPythonAsync: (
    code: string,
    returnVariables?: string[],
  ) => Promise<unknown>;
  globalsSet: (key: string, value: unknown) => void;
}

export interface PythonContext {
  pyodide: PyodideSubInterface | null;
  isReady: boolean;
  readStdout: () => string;
}

const singletonContext = React.createContext<PythonContext>({
  pyodide: null,
  isReady: false,
  readStdout: () => '',
});

class DeferredPromise<T> {
  promise: Promise<T>;

  resolve!: (value: T | PromiseLike<T>) => void;

  reject!: (reason?: any) => void;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

export const PythonProvider = ({
  children,
  pyCollimatorWheelUrl,
}: PythonProviderProps) => {
  const id = React.useRef(0);
  const [isReady, setIsReady] = React.useState(false);
  const worker = React.useRef<Worker | null>(null);
  const promises = React.useRef<Map<number, DeferredPromise<unknown>>>(
    new Map(),
  );

  const pyodideCallAsync = React.useCallback(
    async (
      type: PyodideWorkerMessageType,
      args: unknown[],
    ): Promise<unknown> => {
      id.current += 1;

      const deferred = new DeferredPromise<unknown>();
      promises.current.set(id.current, deferred);

      worker.current?.postMessage({
        type,
        id: id.current,
        args,
      });
      const result = await deferred.promise;
      return result;
    },
    [],
  );

  // Create the worker only once
  React.useEffect(() => {
    if (worker.current) return;
    worker.current = new Worker(
      new URL('workers/pyodideWorker.ts', import.meta.url),
    );

    // wait 1 second in case the onmessage handler is not set yet
    setTimeout(() => {
      worker.current?.postMessage({
        type: PyodideWorkerMessageType.INIT,
        args: [pyCollimatorWheelUrl ?? PYCOLLIMATOR_WHEEL],
      });
    }, 1000);

    return () => {
      worker.current?.terminate();
      worker.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listen to messages from the worker
  React.useEffect(() => {
    if (!worker.current) return;
    worker.current.onmessage = (event) => {
      const { id, isReady, ...data } = event.data;
      if (isReady) {
        setIsReady(true);
        return;
      }
      const promise = promises.current.get(id);
      if (!promise) return;
      if (data.error) {
        promise.reject(data.error);
      } else {
        promise.resolve(data.results);
      }
    };
  }, [promises]);

  const contextValue = React.useMemo(
    () => ({
      pyodide: {
        runPythonAsync: async (code: string, returnVariables?: string[]) =>
          pyodideCallAsync(PyodideWorkerMessageType.RUN_PYTHON, [
            code,
            returnVariables,
          ]),
        globalsSet: (key: string, value: unknown) =>
          pyodideCallAsync(PyodideWorkerMessageType.GLOBALS_SET, [key, value]),
      },
      readStdout: () => '', // FIXME: figure out the stdout stuff
      isReady,
    }),
    [isReady, pyodideCallAsync],
  );
  return (
    <singletonContext.Provider value={contextValue}>
      {children}
    </singletonContext.Provider>
  );
};

// NOTE: If possible, use directly usePythonExecutor instead.
export const usePython = () => {
  const pyContext = React.useContext(singletonContext);
  if (!pyContext) {
    throw new Error('usePython must be used within a PythonProvider');
  }
  return pyContext;
};
