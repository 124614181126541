import { t } from '@lingui/macro';
import { useModels } from 'app/api/useModels';
import { useProject } from 'app/api/useProject';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { isValidModelOrSubmodelName } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import DescriptionField from 'ui/modelEditor/DescriptionField';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsLabel,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';

interface Props {
  projectId: string;
  modelId: string;
}

const ModelDiagramDetails: React.FC<Props> = ({ projectId, modelId }) => {
  const dispatch = useAppDispatch();

  const { versionId } = useAppParams();
  const { canEditModelMetadata } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const model = useAppSelector((state) => state.model.present);

  const { updateModelSummary } = useModels();
  const { project } = useProject();

  const submodels = useAppSelector(
    (state) =>
      state.submodels.projectIdToSubmodelInfoLites[projectId || ''] || [],
  );
  const validationRules = React.useMemo(
    () =>
      project ? isValidModelOrSubmodelName(modelId, project, submodels) : [],
    [modelId, project, submodels],
  );

  const currentModelInfo = project?.models.find(
    (model) => model.uuid === modelId,
  );

  const saveDescription = React.useCallback(
    (newDescription) => {
      if (!currentModelInfo) return;

      updateModelSummary(
        currentModelInfo.uuid,
        currentModelInfo.name,
        newDescription,
      );
    },
    [currentModelInfo, updateModelSummary],
  );

  const onNameChange = React.useCallback(
    (newName: string) => {
      if (canEditModelMetadata) {
        dispatch(modelActions.updateOpenModelName(newName));
      }
    },
    [dispatch, canEditModelMetadata],
  );

  if (!currentModelInfo) return null;

  return (
    <>
      <SectionHeading testId="model">
        {t({
          id: 'modelRenderer.propertiesSidebar.model.heading',
          message: 'Model',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        <DetailsSection>
          <DetailsLabel>
            {t({
              id: 'modelRenderer.propertiesSidebar.modelName.label',
              message: 'Name',
            })}
          </DetailsLabel>
          <DetailsInput
            onSubmitValue={onNameChange}
            value={model.name}
            disabled={!canEditModelMetadata}
            validationRules={validationRules}
          />
        </DetailsSection>
        <DescriptionField
          testId="model-description-textarea"
          placeholder={t({
            id: 'modelRenderer.propertiesSidebar.modelDescription.placeholder',
            message: 'Describe this model and its purpose',
          })}
          savedDescription={currentModelInfo.description || ''}
          saveDescription={saveDescription}
          canEdit={canEditModelMetadata}
        />
      </DetailInputRowsSection>
    </>
  );
};

export default ModelDiagramDetails;
