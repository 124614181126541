import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Map param name to expressions (not values)
export type ParamOverrides = Record<string, string>;

export type EnsembleSimConfig = {
  paramOverrides: ParamOverrides;
};

type EnsembleSimState = {
  // Maps model id to ensemble config
  configsByModelId: Record<string, EnsembleSimConfig>;
};

const initialState: EnsembleSimState = {
  configsByModelId: {},
};

const ensembleSimsSlice = createSlice({
  name: 'ensembleSimsSlice',
  initialState,
  reducers: {
    reset: () => initialState,
    saveParamOverrides(
      state,
      action: PayloadAction<{
        modelId: string;
        paramOverrides: ParamOverrides;
      }>,
    ) {
      const { modelId, paramOverrides } = action.payload;
      state.configsByModelId[modelId] = {
        paramOverrides,
      };
    },
  },
});

export const ensembleSimsActions = ensembleSimsSlice.actions;

export default ensembleSimsSlice;
