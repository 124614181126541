import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { CmlType, DimExpr, Rank } from 'app/generated_types/CmlTypes';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { printNameToSpacedDisplayName } from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { modelActions } from 'app/slices/modelSlice';
import {
  getAllLinkTreeUuidsFromParent,
  getLinkTreeParent,
} from 'app/utils/linkUtils';
import React from 'react';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import { isValidSignalNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsLabel,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import VisualizerToggler from 'ui/modelEditor/Visualizer/VisualizerToggler';
import { getPortPathName } from 'ui/modelEditor/portPathNameUtils';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';

interface Props {
  modelId: string;
  selectionParentPath: string[];
  selectedLinkId: string;
  canEditCurrentModelVersion: boolean;
}

const dimExprDimensionNumberValue = (dimExpr: DimExpr): string => {
  if (!dimExpr) return '';

  switch (dimExpr.kind) {
    case 'Int':
      return `${dimExpr.value}`;
    case 'Var':
      return `${dimExpr.value[1]}`;
  }

  return '';
};

const rankDimensionNumberValue = (rank: Rank): string => {
  if (!rank) return '';

  // For a single dimensional vector, there should be a trailing
  // comma in the dimensions display string.  This should match Python convention.
  switch (rank.kind) {
    case 'Var':
      return `${rank.value[1]},`;
    case 'Dims':
      if (rank.value.length === 1) {
        return `${dimExprDimensionNumberValue(rank.value[0])},`;
      }
      return rank.value
        .map((dimExpr) => dimExprDimensionNumberValue(dimExpr))
        .join(',');
  }

  return '';
};

export const cmlTypeDimensions = (cmt?: CmlType): string => {
  if (!cmt) return '';

  switch (cmt.kind) {
    case 'Tuple':
      return `[${cmt.value.length}]`;
    case 'Tensor':
      const dimensions = rankDimensionNumberValue(cmt.value[0]);
      return `(${dimensions})`;
    case 'Python':
      return cmt.value;
  }

  return '()';
};

export const cmlTypeName = (cmt?: CmlType): string => {
  if (!cmt) return '';

  switch (cmt.kind) {
    case 'Tensor':
      if (cmt.value[0] instanceof Array) {
        // FIXME: cmlc-v1 support
        return (cmt.value[0] || []).length > 1 ? 'Matrix' : 'Vector';
      }

      if (cmt.value[0].kind === 'Var') {
        return 'Unknown Tensor';
      }
      return (cmt.value[0].value || []).length > 1 ? 'Matrix' : 'Vector';

    case 'Float':
      return `Float${cmt.value}`;
    case 'UInt':
    case 'Int':
    case 'Complex':
      return `${cmt.kind}${cmt.value}`;
  }

  return cmt.kind;
};

export const cmlIsMatrix = (dimensionsArray?: any): boolean => {
  if (dimensionsArray instanceof Array) {
    // FIXME: cmlc-v1 support
    return (dimensionsArray || []).length > 1;
  }
  return (
    (dimensionsArray?.kind === 'Dims' ? dimensionsArray.value : []).length > 1
  );
};

const JumpToNodeArrowContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
`;

const JumpToNodeArrow = ({
  parentPath,
  nodeUuid,
}: {
  parentPath: string[];
  nodeUuid: string;
}) => {
  const dispatch = useAppDispatch();

  const jumpToNode = () => {
    if (parentPath && nodeUuid) {
      dispatch(
        modelActions.setSelections({
          selectionParentPath: parentPath,
          selectedBlockIds: [nodeUuid],
          selectedLinkIds: [],
          selectedAnnotationIds: [],
        }),
      );
    }
  };

  return (
    <JumpToNodeArrowContainer onClick={jumpToNode}>
      <img src={`${process.env.PUBLIC_URL}/assets/jump_to_block_arrow.svg`} />
    </JumpToNodeArrowContainer>
  );
};

export const LinkDetails: React.FC<Props> = ({
  modelId,
  selectionParentPath,
  selectedLinkId,
  canEditCurrentModelVersion,
}) => {
  const dispatch = useAppDispatch();

  const referenceSubmodelId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelReferenceId,
  );
  const isViewingReferenceSubmodel =
    referenceSubmodelId && referenceSubmodelId !== modelId;

  const canEdit = canEditCurrentModelVersion && !isViewingReferenceSubmodel;

  const links = useAppSelector(
    (state) => state.modelMetadata.currentDiagram?.links,
  );
  const nodes = useAppSelector(
    (state) => state.modelMetadata.currentDiagram?.nodes,
  );
  const selectedLink = links?.find((link) => link.uuid === selectedLinkId);

  const sourceNodeId = selectedLink?.src?.node || '';
  const selectedLinkNamePath =
    nodes &&
    sourceNodeId &&
    getPortPathName(
      getCurrentModelRef().topLevelNodes,
      getCurrentModelRef().submodels,
      {
        parentPath: selectionParentPath,
        nodeId: sourceNodeId,
        portIndex: selectedLink?.src?.port || 0,
      },
      { includePortNameForNonSubmodels: false },
    );

  const { datatypeAndDimensions, timeMode } = useAppSelector((state) => {
    if (selectedLinkNamePath) {
      const signalData =
        state.compilationData.signalsData[selectedLinkNamePath];
      if (signalData) {
        return signalData;
      }
    }
    return {
      datatypeAndDimensions: undefined,
      timeMode: undefined,
    };
  });

  if (!selectedLink) {
    return null;
  }

  const compiledTimeMode = timeMode?.mode || 'Unknown';

  // this might seem a bit complex,
  // but it is designed to meticulously avoid exponential or quadratic scenarios.
  // (with the exception of where not reasonably avoidable, e.g. the .includes() calls)
  // NOTE: we should consider moving the refs object to a higher-level hook,
  // because it is becoming a pattern that we are using outside of the renderer itself.
  // (it's a good pattern because it saves us a lot of performance with minimal overhaul to the whole system)
  let originNode: NodeInstance | undefined;
  const destinationNodes: NodeInstance[] = [];
  const destinationNodeUuids: string[] = [];

  const linkTreeParent = getLinkTreeParent(selectedLinkId);
  const linksToCheckDest: string[] = linkTreeParent
    ? getAllLinkTreeUuidsFromParent(linkTreeParent)
    : [];

  while (linksToCheckDest.length > 0) {
    if (!rendererState) break;
    const checkingLinkUuid = linksToCheckDest.pop() || '';
    const checkingLinkIndex =
      rendererState.refs.current.linksIndexLUT[checkingLinkUuid];
    const checkingLink = rendererState.refs.current.links[checkingLinkIndex];
    const dependentLinkUuids =
      rendererState.refs.current.linksRenderingDependencyTree[
        checkingLink?.uuid
      ] || [];

    if (checkingLink?.dst) destinationNodeUuids.push(checkingLink?.dst.node);
    if (dependentLinkUuids.length > 0) {
      for (let i = 0; i < dependentLinkUuids.length; i++) {
        linksToCheckDest.push(dependentLinkUuids[i]);
      }
    }
  }

  if (nodes) {
    for (let i = 0; i < nodes.length; i++) {
      const cNode = nodes[i];
      if (cNode && cNode.uuid === selectedLink.src?.node) originNode = cNode;
      if (cNode && destinationNodeUuids.includes(cNode.uuid)) {
        destinationNodes.push(cNode);
      }
    }
  }

  const nameValue = linkTreeParent?.name;
  const namePlaceholder = originNode
    ? `${originNode?.name}.${
        originNode?.outputs[selectedLink.src?.port || 0]?.name
      }`
    : '';

  const onChangeLinkName = (newName: string) => {
    dispatch(
      modelActions.changeLinkName({
        linkUuid: linkTreeParent?.uuid || '',
        newName,
      }),
    );
  };

  const portDataTypeAndDimensions = (datatypeAndDimensions || [])[
    selectedLink.src ? selectedLink.src.port : -1
  ];

  return (
    <>
      <SectionHeading
        testId="block-title"
        Action={
          selectedLink.src && originNode ? (
            <VisualizerToggler
              nodeId={selectedLink.src.node}
              parentPath={selectionParentPath}
              portIndex={selectedLink.src.port}
              output={originNode.outputs[selectedLink.src.port]}
            />
          ) : undefined
        }>
        {printNameToSpacedDisplayName(
          t({
            id: 'modelRenderer.propertiesSidebar.lineDetails.title',
            message: 'Line',
          }),
        )}
      </SectionHeading>
      <DetailInputRowsSection>
        <DetailsSection vertical>
          <DetailsLabel stretch disabled={!canEdit}>
            {t({
              id: 'modelRenderer.propertiesSidebar.lineDetails.signalName.label',
              message: 'Signal name',
            })}
          </DetailsLabel>
          <DetailsInput
            grow
            value={nameValue}
            placeholder={namePlaceholder}
            placeholderTextColorDark
            onSubmitValue={onChangeLinkName}
            disabled={!canEdit}
            validationRules={isValidSignalNameRuleSet(
              links,
              linkTreeParent?.uuid || '', // a link name comes from the parent
            )}
          />
        </DetailsSection>
        <DetailsSection>
          <DetailsLabel>
            {t({
              id: 'modelRenderer.propertiesSidebar.lineDetails.datatype.label',
              message: 'Data type',
            })}
          </DetailsLabel>
          <DetailsInput
            disabled
            value={cmlTypeName(portDataTypeAndDimensions) || 'Unknown'}
            align={TextInputAlign.Left}
          />
        </DetailsSection>
        <DetailsSection>
          <DetailsLabel>
            {t({
              id: 'modelRenderer.propertiesSidebar.lineDetails.dimensions.label',
              message: 'Dimensions',
            })}
          </DetailsLabel>
          <DetailsInput
            disabled
            value={cmlTypeDimensions(portDataTypeAndDimensions) || 'Unknown'}
            align={TextInputAlign.Left}
          />
        </DetailsSection>
      </DetailInputRowsSection>
      <SectionHeading testId="simulation">
        {t({
          id: 'modelRenderer.propertiesSidebar.lineDetails.simulation.label',
          message: 'Simulation',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        <DetailsSection>
          <DetailsLabel>
            {t({
              id: 'blockDetails.TimeModeLabel',
              message: 'Time mode',
            })}
          </DetailsLabel>
          <DetailsInput
            disabled
            value={compiledTimeMode}
            align={TextInputAlign.Left}
          />
        </DetailsSection>
      </DetailInputRowsSection>
      {originNode && (
        <>
          <SectionHeading testId="origin">
            {t({
              id: 'modelRenderer.propertiesSidebar.lineDetails.origin.label',
              message: 'Origin',
            })}
          </SectionHeading>
          <DetailInputRowsSection>
            <DetailsSection>
              <DetailsLabel>{originNode?.name}</DetailsLabel>
              <JumpToNodeArrow
                parentPath={selectionParentPath}
                nodeUuid={originNode?.uuid}
              />
            </DetailsSection>
          </DetailInputRowsSection>
        </>
      )}
      {destinationNodes.length > 0 && (
        <>
          <SectionHeading testId="destinations">
            {t({
              id: 'modelRenderer.propertiesSidebar.lineDetails.destinations.label',
              message: 'Destinations',
            })}
          </SectionHeading>
          <DetailInputRowsSection>
            {destinationNodes.map((dNode) => (
              <DetailsSection key={dNode.uuid}>
                <DetailsLabel>{dNode?.name}</DetailsLabel>
                <JumpToNodeArrow
                  parentPath={selectionParentPath}
                  nodeUuid={dNode?.uuid}
                />
              </DetailsSection>
            ))}
          </DetailInputRowsSection>
        </>
      )}
    </>
  );
};
