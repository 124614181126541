import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { DISABLE_ONBOARDING } from 'app/config/globalApplicationConfig';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { tourActions } from 'app/slices/tourSlice';
import { RootState } from 'app/store';
import React from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { useVisualizerPrefs } from 'ui/modelEditor/useVisualizerPrefs';
import { useAppParams } from 'util/useAppParams';
import { v4 as uuid } from 'uuid';

import steps from './OnboardingSteps';
import OnboardingTooltip from './OnboardingTooltip';
import {
  calculateNodeCoords,
  calculateVisualizerCoords,
  getRelevantLinks,
  getRelevantNodes,
  waitUntilInvisible,
  waitUntilVisible,
} from './onboardingUtils';

const FloaterStyles = css`
  .__floater {
    transition: none !important;
  }
`;

const BlockPosition = styled.div<{
  left?: number;
  top?: number;
}>(({ left, top }) => ({
  position: 'absolute',
  width: 0,
  height: 0,
  left,
  top,
}));

const Onboarding: React.FC = () => {
  const onboardingEnabled = !DISABLE_ONBOARDING;

  const dispatch = useAppDispatch();
  const { modelId } = useAppParams();
  const triggerOnboarding = useAppSelector(
    (state) => state.tour.triggers.onboarding,
  );
  const camera = useAppSelector((state: RootState) => state.camera.coord);
  const model = useAppSelector((state) => state.model.present);
  const simulationSummary = useAppSelector(
    (state) => state.project.simulationSummary,
  );
  const { getIsPortInChart } = useVisualizerPrefs();
  const justAcceptedTerms = localStorage.getItem('justAcceptedToS');
  const [run, setRun] = React.useState(false);
  const [stepIndex, setStepIndex] = React.useState(0);
  const [targetCoordinates, setTargetCoordinates] = React.useState({
    left: -1,
    top: -1,
  });

  React.useEffect(() => {
    if (!onboardingEnabled) return;

    if (justAcceptedTerms === 'true') {
      dispatch(tourActions.setTrigger({ tour: 'onboarding', value: true }));
      localStorage.removeItem('justAcceptedToS');
    }
  }, [dispatch, justAcceptedTerms, onboardingEnabled]);

  const resetState = React.useCallback(() => {
    if (!onboardingEnabled) return;

    setStepIndex(0);
    setTargetCoordinates({ left: -1, top: -1 });
    dispatch(tourActions.setStatus({ tour: 'onboarding', value: 'idle' }));
  }, [dispatch, onboardingEnabled]);

  React.useEffect(() => {
    if (!onboardingEnabled) return;

    if (triggerOnboarding) {
      setRun(true);
      resetState();
      dispatch(tourActions.setTrigger({ tour: 'onboarding', value: false }));
      dispatch(tourActions.setStatus({ tour: 'onboarding', value: 'active' }));
    }
  }, [dispatch, triggerOnboarding, run, resetState, onboardingEnabled]);

  // Effect to move steps forward for special cases where we need information from Redux
  React.useEffect(() => {
    if (!onboardingEnabled) return;

    const { signal1, signal2, gain, adder } = getRelevantNodes(
      model.rootModel.nodes,
    );

    const { signal1Link, signal2AdderLink, signal2GainLink, gain2AdderLink } =
      getRelevantLinks(model.rootModel.links, signal1, signal2, adder, gain);
    if (adder) {
      // Step forward conditions
      if (stepIndex === 4) {
        setStepIndex((step) => step + 1);
      }
      if (stepIndex === 5 && adder?.inputs.length === 3 && signal1) {
        setStepIndex((step) => step + 1);
      }
      if (stepIndex === 6 && signal1Link) {
        setStepIndex((step) => step + 1);
      }
      if (
        stepIndex === 7 &&
        signal2 &&
        adder &&
        gain &&
        signal1Link?.dst?.node === adder?.uuid &&
        !signal2AdderLink
      ) {
        dispatch(
          modelActions.addNewLinkToModel({
            uuid: uuid(),
            linkType: {
              connection_method: 'direct_to_block',
            },
            linkPayload: {
              source: {
                node: gain.uuid,
                port: 0,
              },
              destination: {
                node: adder.uuid,
                port: (signal1Link.dst.port + 1) % 3,
              },
            },
          }),
        );
        dispatch(
          modelActions.addNewLinkToModel({
            uuid: uuid(),
            linkType: {
              connection_method: 'direct_to_block',
            },
            linkPayload: {
              source: {
                node: signal2.uuid,
                port: 0,
              },
              destination: {
                node: adder.uuid,
                port: (signal1Link.dst.port + 2) % 3,
              },
            },
          }),
        );
        setStepIndex((step) => step + 1);
      }
      if (stepIndex === 8 && signal2GainLink) {
        setStepIndex((step) => step + 1);
      }
      if (
        stepIndex === 9 &&
        getIsPortInChart({ nodeId: adder.uuid, portIndex: 0, parentPath: [] })
      ) {
        setStepIndex((step) => step + 1);
      }
      if (stepIndex === 10 && simulationSummary?.status === 'completed') {
        setStepIndex((step) => step + 1);
      }
      // Step tracker coordinates
      if (stepIndex === 6 && signal1)
        setTargetCoordinates(calculateNodeCoords(camera, signal1));
      if (stepIndex === 7)
        setTargetCoordinates(calculateNodeCoords(camera, adder));
      if (stepIndex === 8 && signal2)
        setTargetCoordinates(calculateNodeCoords(camera, signal2));
      if (stepIndex === 9)
        setTargetCoordinates(calculateVisualizerCoords(camera, adder));

      // situations where we have to go back

      // If removed any block on the model editor, we finish the tour
      if (stepIndex > 4 && (!adder || !signal1 || !signal2 || !gain)) {
        setRun(false);
        resetState();
        // if removed any inpport from adder, we restore back to 5
      } else if (stepIndex > 5 && adder?.inputs.length !== 3) {
        setStepIndex(5);
        dispatch(
          modelActions.removeEntitiesFromModel({
            linkUuids: [
              signal1Link?.uuid || '',
              signal2GainLink?.uuid || '',
              signal2AdderLink?.uuid || '',
              gain2AdderLink?.uuid || '',
            ],
          }),
        );
        // If removed any needed link, we step back to 5
      } else if (
        stepIndex > 8 &&
        (!signal1Link ||
          !signal2AdderLink ||
          !signal2GainLink ||
          !gain2AdderLink)
      ) {
        setStepIndex(5);
        dispatch(
          modelActions.removeEntitiesFromModel({
            linkUuids: [
              signal1Link?.uuid || '',
              signal2GainLink?.uuid || '',
              signal2AdderLink?.uuid || '',
              gain2AdderLink?.uuid || '',
            ],
          }),
        );
      }
    }
  }, [
    dispatch,
    camera,
    model,
    getIsPortInChart,
    simulationSummary,
    stepIndex,
    resetState,
    onboardingEnabled,
  ]);

  React.useEffect(() => {
    if (!onboardingEnabled) return;

    if (!modelId && stepIndex > 3) {
      setRun(false);
      resetState();
    }
    if (stepIndex === 2) {
      // We have one particular use case where, if we detect the user has closed the Create Project modal we want to close the tour
      // but if we go from the project creation to the project detail page, even though the modal disappears, we want to keep the tour flow
      waitUntilInvisible(steps[stepIndex].target as string).then(() => {
        if (!window.location.href.endsWith('projects')) return;
        setRun(false);
        resetState();
      });
    }
  }, [modelId, stepIndex, resetState, onboardingEnabled]);

  const handleCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
    const nextStep = steps[nextStepIndex];

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setRun(false);
      resetState();
    } else if (([EVENTS.STEP_AFTER] as string[]).includes(type)) {
      setStepIndex(nextStepIndex);
    } else if (type === EVENTS.STEP_BEFORE) {
      if (index > 0) {
        if (index === 1 || index === 2 || index === 3) {
          waitUntilVisible(nextStep.target as string).then(() => {
            setStepIndex(nextStepIndex);
          });
        }
      }
    }
  };

  if (!onboardingEnabled) return null;

  return (
    <>
      <Global styles={FloaterStyles} />
      <Joyride
        key={`tour-onboarding-${stepIndex}-${targetCoordinates.left}-${targetCoordinates.top}`}
        steps={steps}
        run={run}
        showProgress
        callback={handleCallback}
        tooltipComponent={OnboardingTooltip}
        spotlightClicks
        disableOverlayClose
        floaterProps={{
          styles: {
            floater: {
              filter:
                'drop-shadow(0px 19px 30px rgba(0, 0, 0, 0.09)) drop-shadow(0px 7.09px 14px rgba(0, 0, 0, 0.0425)) drop-shadow(0px 3.33px 3.9px rgba(0, 0, 0, 0.0225))',
            },
          },
        }}
        stepIndex={stepIndex}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      {stepIndex >= 6 && stepIndex <= 9 && (
        <BlockPosition
          className={`tour-onboarding-${stepIndex}`}
          {...targetCoordinates}
        />
      )}
    </>
  );
};

export default Onboarding;
