import { useProjectItems } from 'app/api/useProjectItems';
import { useProjectOrRedirect } from 'app/behaviorHooks/useProjectOrRedirect';
import React from 'react';
import SelectInput from 'ui/common/SelectInput';
import { ProjectItemType } from 'ui/dashboard/dashboardTypes';
import { useAppParams } from 'util/useAppParams';

type Props = {
  currentValue: string;
  onSelectValue: (newValue: string) => void;
};

export const ExperimentModelParameter: React.FC<Props> = ({
  currentValue,
  onSelectValue,
}) => {
  const { modelId } = useAppParams();
  const { project } = useProjectOrRedirect();
  const { projectItems } = useProjectItems(project);
  const projectModels = projectItems.filter(
    (item) => item.type === ProjectItemType.MODEL && item.uuid != modelId,
  );

  const options = React.useMemo(
    () =>
      projectModels.map((modelData) => ({
        value: modelData.uuid,
        label: modelData.name,
      })),
    [projectModels],
  );

  return (
    <SelectInput
      currentValue={currentValue}
      options={options}
      onSelectValue={onSelectValue}
    />
  );
};
