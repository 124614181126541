import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { compilationAnalysisDataActions } from 'app/slices/compilationAnalysisDataSlice';
import { getSubpath } from 'app/utils/parentPathUtils';
import {
  setNavigationURLParams,
  setSelectionURLParams,
} from 'app/utils/URLParamsUtils';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Edit } from 'ui/common/Icons/Standard';
import { useAppParams } from 'util/useAppParams';

const EditButton = styled(Button)`
  margin: 0 ${({ theme }) => theme.spacing.small};
`;

export const SubmodelTools: React.FC = () => {
  const navigate = useNavigate();
  const { projectId, modelId } = useAppParams();

  const dispatch = useAppDispatch();

  const theme = useTheme();

  const submodelInstanceId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelInstanceId,
  );
  const referenceSubmodelProjectId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelReferenceProjectId,
  );
  const referenceSubmodelId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelReferenceId,
  );

  const submodelToPublish = useAppSelector(
    (state) => state.submodels.submodelToPublish,
  );

  const selectedBlockIds = useAppSelector(
    (state) => state.model.present.selectedBlockIds,
  );
  const selectedLinkIds = useAppSelector(
    (state) => state.model.present.selectedLinkIds,
  );
  const selectedAnnotationIds = useAppSelector(
    (state) => state.model.present.selectedAnnotationIds,
  );

  const selectionParentPath = useAppSelector(
    (state) => state.model.present?.selectionParentPath,
  );

  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present?.currentSubmodelPath,
  );

  const navigateToSubmodel = React.useCallback(
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!submodelInstanceId) {
        return;
      }

      const parentPathInSubmodel = getSubpath(
        currentSubmodelPath,
        submodelInstanceId,
      );
      const selectionParentPathInSubmodel = getSubpath(
        selectionParentPath,
        submodelInstanceId,
      );

      const searchParams = new URLSearchParams();
      setNavigationURLParams(searchParams, {
        parentPath: parentPathInSubmodel,
      });
      // FIXME: why is selection sometimes driven by the URL and sometimes by the redux store? We have a two way data flow happening.
      setSelectionURLParams(searchParams, {
        selectionParentPath: selectionParentPathInSubmodel,
        selectedBlockIds,
        selectedLinkIds,
        selectedAnnotationIds,
      });

      navigate({
        pathname: `/projects/${referenceSubmodelProjectId}/models/${referenceSubmodelId}`,
        search: `?${searchParams.toString()}`,
      });
      e?.preventDefault();
      e?.stopPropagation();

      dispatch(compilationAnalysisDataActions.clearAllCompilationState());
    },
    [
      referenceSubmodelProjectId,
      referenceSubmodelId,
      navigate,
      dispatch,
      currentSubmodelPath,
      selectionParentPath,
      selectedBlockIds,
      selectedLinkIds,
      selectedAnnotationIds,
      submodelInstanceId,
    ],
  );

  if (
    !referenceSubmodelId ||
    !projectId ||
    !modelId ||
    modelId === referenceSubmodelId
  ) {
    return null;
  }

  // Don't allow the user to create a new submodel until
  // we have finished creating the last one that the user requested.
  const isSubmodelCreationDisabled = !!submodelToPublish;

  return (
    <>
      {/* Edit reference submodel button */}
      <EditButton
        Icon={Edit}
        onClick={navigateToSubmodel}
        testId="navbar-edit-submodel-button"
        variant={ButtonVariants.SmallPrimary}
        disabled={isSubmodelCreationDisabled}
        tint={theme.colors.brand.tertiary.darker}>
        {t({
          id: 'navBar.editReferenceSubmodelButton.label',
          message: 'Edit',
        })}
      </EditButton>
    </>
  );
};
