// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const Constant: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Constant",
      "namespace": "core",
      "description": "Returns a given constant value.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#constant",
      "supports_experiments": true
    },
    "modes": {
      "time": "constant"
    },
    "ports": {
      "outputs": {
        "static": [
          {
            "name": "out_0",
            "parameter_definitions": [
              {
                "name": "dtype",
                "data_type": "string",
                "default_value": "float64",
                "value_is_a_type": true,
                "description": "Data type of the output signal. 'auto' means ",
                "feature_level": "dev"
              },
              {
                "name": "shape",
                "data_type": "any",
                "default_value": "()",
                "description": "Shape or dimension of the output signal, follows numpy syntax like: (2,3) or ()",
                "feature_level": "dev"
              }
            ]
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "value",
        "description": "The value to return",
        "data_type": "float",
        "default_value": "0.0"
      }
    ]
  }
);
