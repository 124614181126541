import { css, Theme } from '@emotion/react';
import { ButtonVariants } from './buttonTypes';

const getCommonStyle = (theme: Theme, isIcon: boolean) =>
  css({
    display: 'flex',
    alignItems: 'center',
    border: 0,
    borderRadius: '2px',
    padding: isIcon ? '0' : `0px ${theme.spacing.small}`,
    cursor: 'pointer',
    transition: 'all 0.3s',
    '&:focus,&:focus-visible': {
      boxShadow: `0px 0px 0px 2px ${theme.colors.brand.primary.lighter}`,
      outline: '0',
    },
    '&:disabled,&:disabled:active': {
      opacity: 0.3,
      boxShadow: 'none',
      cursor: 'default',
    },
  });

const Primary = (
  { colors, tools }: Theme,
  tint?: string,
  hasRedText?: boolean,
) =>
  css({
    backgroundColor: tint || colors.brand.primary.base,
    color: colors.grey[2],
    '&:hover': {
      backgroundColor:
        (tint && tools.darker(tint)) || colors.brand.primary.darker,
      color: colors.grey[2],
    },
    '&:active': {
      backgroundColor:
        (tint && tools.darkest(tint)) || colors.brand.primary.darkest,
    },
    '&:disabled': {
      backgroundColor: tint || colors.brand.primary.base,
      color: colors.grey[2],
    },
    '& *': {
      fill: hasRedText ? colors.ui.error : colors.grey[2],
    },
  });

const Secondary = (
  { colors, tools }: Theme,
  tint?: string,
  hasRedText?: boolean,
) =>
  css({
    backgroundColor: colors.grey[2],
    border: `1px solid ${colors.grey[10]}`,
    color: colors.text.secondary,
    '& *': {
      fill: hasRedText ? colors.ui.error : tint || colors.text.tertiary,
    },
    '&:hover': {
      backgroundColor: colors.grey[2],
      border: `1px solid ${colors.grey[30]}`,
      color: (tint && tools.darker(tint)) || colors.text.primary,
      '& *': {
        fill: hasRedText
          ? colors.ui.error
          : (tint && tools.darker(tint)) || colors.text.secondary,
      },
    },

    '&:active': {
      backgroundColor: colors.grey[30],
      border: `1px solid ${colors.grey[30]}`,
      color: (tint && tools.darkest(tint)) || colors.text.primary,
      '& *': {
        fill: hasRedText
          ? colors.ui.error
          : (tint && tools.darkest(tint)) || colors.text.primary,
      },
    },
    '&:disabled': {
      backgroundColor: colors.grey[2],
      border: `1px solid ${colors.grey[30]}`,
      color: tint || colors.text.secondary,
      '& *': {
        fill: hasRedText ? colors.ui.error : tint || colors.text.secondary,
      },
    },
  });

const Tertiary = (
  { colors, tools }: Theme,
  tint?: string,
  hasRedText?: boolean,
) =>
  css({
    backgroundColor: 'transparent',
    color: tint || colors.text.secondary,
    '& *': {
      fill: hasRedText ? colors.ui.error : tint || colors.grey[30],
    },
    '&:hover': {
      backgroundColor: colors.grey[10],
      color: (tint && tools.darker(tint)) || colors.text.primary,
      '& *': {
        fill: hasRedText
          ? colors.ui.error
          : (tint && tools.darker(tint)) || colors.text.secondary,
      },
    },

    '&:active': {
      backgroundColor: colors.grey[30],
      color: (tint && tools.darkest(tint)) || colors.text.primary,
      '& *': {
        fill: hasRedText
          ? colors.ui.error
          : (tint && tools.darkest(tint)) || colors.text.primary,
      },
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: tint || colors.text.secondary,
      '& *': {
        fill: hasRedText ? colors.ui.error : tint || colors.grey[30],
      },
    },
  });

const Danger = ({ colors, tools }: Theme) =>
  css({
    backgroundColor: colors.ui.error,
    color: colors.grey[2],
    '&:hover': {
      backgroundColor: tools.lighter(colors.ui.error),
      color: colors.grey[2],
    },
    '&:active': {
      backgroundColor: tools.darker(colors.ui.error),
    },
    '&:disabled': {
      backgroundColor: colors.ui.error,
      color: colors.grey[2],
    },
    '& *': {
      fill: colors.grey[2],
    },
  });

const Large = (theme: Theme, isIcon: boolean) =>
  css({
    height: isIcon ? '24px' : '32px',
    lineHeight: theme.typography.font.standardHighlight.lineHeight,
    fontWeight: theme.typography.font.standardHighlight.weight,
    fontSize: theme.typography.font.standardHighlight.size,
  });
const Small = (theme: Theme, isIcon: boolean) =>
  css({
    height: isIcon ? '16px' : '24px',
    lineHeight: theme.typography.font.smallEmphasis.lineHeight,
    fontWeight: theme.typography.font.smallEmphasis.weight,
    fontSize: theme.typography.font.smallEmphasis.size,
  });

const getSizeStyle = (
  theme: Theme,
  variant: ButtonVariants,
  isIcon: boolean,
) => {
  switch (variant) {
    case ButtonVariants.LargePrimary:
    case ButtonVariants.LargeSecondary:
    case ButtonVariants.LargeTertiary:
    case ButtonVariants.Danger:
      return Large(theme, isIcon);
    case ButtonVariants.SmallPrimary:
    case ButtonVariants.SmallSecondary:
    case ButtonVariants.SmallTertiary:
      return Small(theme, isIcon);
  }
};

const getVariantStyle = (
  theme: Theme,
  variant: ButtonVariants,
  tint?: string,
  hasRedText?: boolean,
) => {
  switch (variant) {
    case ButtonVariants.LargePrimary:
    case ButtonVariants.SmallPrimary:
      return Primary(theme, tint, hasRedText);
    case ButtonVariants.LargeSecondary:
    case ButtonVariants.SmallSecondary:
      return Secondary(theme, tint, hasRedText);
    case ButtonVariants.LargeTertiary:
    case ButtonVariants.SmallTertiary:
      return Tertiary(theme, tint, hasRedText);
    case ButtonVariants.Danger:
      return Danger(theme);
  }
};

export const getButtonStyle = (
  theme: Theme,
  variant: ButtonVariants,
  isIcon: boolean,
  tint?: string,
  hasRedText?: boolean,
) => [
  getCommonStyle(theme, isIcon),
  getSizeStyle(theme, variant, isIcon),
  getVariantStyle(theme, variant, tint, hasRedText),
];
