/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Dimensions: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.99999 1.95956L2.3131 3.64645C2.11784 3.84171 2.11784 4.1583 2.3131 4.35356C2.50837 4.54882 2.82495 4.54882 3.02021 4.35356L3.49999 3.87378V11.6667C3.49999 12.1269 3.87309 12.5 4.33332 12.5H12.1262L11.6464 12.9798C11.4512 13.175 11.4512 13.4916 11.6464 13.6869C11.8417 13.8822 12.1583 13.8822 12.3535 13.6869L14.0404 12L12.3535 10.3131C12.1583 10.1179 11.8417 10.1179 11.6464 10.3131C11.4512 10.5084 11.4512 10.825 11.6464 11.0202L12.1262 11.5H4.49999V3.87378L4.97977 4.35356C5.17503 4.54882 5.49161 4.54882 5.68688 4.35356C5.88214 4.1583 5.88214 3.84171 5.68688 3.64645L3.99999 1.95956Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.66666 5.66667C5.66666 5.29848 5.96513 5 6.33332 5H10.3333C10.7015 5 11 5.29848 11 5.66667V9.66667C11 10.0349 10.7015 10.3333 10.3333 10.3333H6.33332C5.96513 10.3333 5.66666 10.0349 5.66666 9.66667V5.66667ZM6.33332 5.66667H10.3333V9.66667H6.33332V5.66667Z"
      fill={props.fill}
    />
  </svg>
);

Dimensions.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Dimensions;
