import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useUserCredentials } from 'app/api/useUserCredentials';
import {
  UserCredentialsKind,
  UserCredentialsRequest,
} from 'app/apiGenerated/generatedApiTypes';
import {
  useDeleteUserCredentialsMutation,
  usePostUserCredentialsMutation,
} from 'app/enhancedApi';
import React, { useState } from 'react';
import { Cell, Column, Row, TableState } from 'react-table';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Plus, Remove } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import { isRequiredRule } from 'ui/common/Input/inputValidation';
import SelectInput, { SelectInputOption } from 'ui/common/SelectInput';
import Table from 'ui/common/Table/Table';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { TOKEN_WIDTH_PX, TableWrapper } from './ApiKeysModal';

const CENSORED_TOKEN = '*****-*****-*****-*****-*****';

// FIXME: adapt to 3rd party creds
interface ApiKeysTableData extends Record<string, string> {
  token: string;
  uuid: string;
  scope: string;
  createdAt: string;
  expiry: string;
}

interface ThirdPartyTableData extends Record<string, string> {
  name: string;
  credentialType: string;
  secret: string;
}

const ThirdPartyCredInputWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

const ThirdPartyInput = styled(Input)<{ grow?: boolean }>`
  margin-right: 8px;
  width: 130px;

  ${(props) => props.grow && 'flex-grow: 1;'}
`;

// FIXME: Find a better way to define and share this mapping
// maybe in the JSON schemas?
const credsOptions: SelectInputOption[] = [
  {
    label: 'OpenAI API Key',
    value: 'openai_api_key',
  },
];

const credsKindToText = (kind: UserCredentialsKind) => {
  switch (kind) {
    case 'github_refresh_token':
      return 'GitHub App';
    case 'github_pat':
      return 'GitHub PAT';
    case 'openai_api_key':
      return 'OpenAI API Key';
    default:
      return kind;
  }
};

const CredentialInput: React.FC = () => {
  const [name, setName] = useState('');
  const [kind, setKind] = useState('');
  const [secret, setSecret] = useState('');

  const [editable, setEditable] = useState(true);

  const { showInfo, showError } = useNotifications();
  const [callAddCredsApi] = usePostUserCredentialsMutation();

  const onSubmitThirdPartyCred = React.useCallback(() => {
    if (!name || !secret || !kind) return;

    setEditable(false);

    const userCredentialsRequest: UserCredentialsRequest = {
      name,
      kind: kind as UserCredentialsKind,
      secret,
      expires_at: undefined,
    };

    callAddCredsApi({ userCredentialsRequest })
      .unwrap()
      .then(() => {
        showInfo(t`Credentials saved`);
        setName('');
        setSecret('');
        setKind('');
      })
      .catch((e) => {
        showError(t`Error adding credentials`, e);
      })
      .finally(() => {
        setEditable(true);
      });
  }, [name, secret, kind, callAddCredsApi, showInfo, showError]);

  return (
    <ThirdPartyCredInputWrapper>
      <ThirdPartyInput
        autoFocus
        hasBorder
        disabled={!editable}
        onChangeText={setName}
        value={name}
        validationRules={[isRequiredRule]}
        placeholder={name || t`Name`}
      />
      <SelectInput options={credsOptions} onSelectValue={setKind} />
      <ThirdPartyInput
        grow
        hasBorder
        disabled={!editable}
        onChangeText={setSecret}
        value={secret}
        validationRules={[isRequiredRule]}
        placeholder={secret || t`Secret`}
      />
      <Button
        type="button"
        disabled={!editable || !name || !secret || !kind}
        variant={ButtonVariants.SmallTertiary}
        Icon={Plus}
        onClick={() => onSubmitThirdPartyCred()}>
        {t({
          id: 'authTokensModal.newThirdPartyCredentialSubmitButton.label',
          message: 'Add',
        })}
      </Button>
    </ThirdPartyCredInputWrapper>
  );
};

export const ApiKeys3rdPartyModalContents: React.FC = () => {
  const { userCredentials } = useUserCredentials();
  const [deleteUserCredentials] = useDeleteUserCredentialsMutation();

  const RemoveCell = React.useCallback(
    (cell: Cell<ApiKeysTableData>) => {
      const onDelete = (data: ApiKeysTableData) => {
        deleteUserCredentials({ name: data.name }).unwrap();
      };

      return (
        <Button
          Icon={Remove}
          variant={ButtonVariants.LargeTertiary}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            onDelete(cell.row.original);
          }}
        />
      );
    },
    [deleteUserCredentials],
  );

  const thirdPartyColumns: Column<ThirdPartyTableData>[] = [
    { Header: 'UUID', accessor: 'uuid', minWidth: 0, maxWidth: 0 },
    {
      Header: t({
        id: 'dashboard.thirdPartyCredsModal.table.name.label',
        message: 'Name',
      }),
      accessor: 'name',
      minWidth: 160,
      maxWidth: 0,
    },
    {
      Header: t({
        id: 'dashboard.thirdPartyCredsModal.table.type.label',
        message: 'Type',
      }),
      accessor: 'credentialType',
      minWidth: 100,
      maxWidth: 0,
    },
    {
      Header: t({
        id: 'dashboard.thirdParyCredsModal.table.secret.label',
        message: 'Secret',
      }),
      accessor: 'secret',
      minWidth: TOKEN_WIDTH_PX,
      maxWidth: 2000,
    },
    {
      Header: '',
      id: 'remove',
      disableSortBy: true,
      minWidth: 20,
      maxWidth: 0,
      disableResizing: true,
      Cell: RemoveCell,
    },
  ];

  const initialState: Partial<TableState<ApiKeysTableData>> = {
    hiddenColumns: ['uuid'],
  };

  const thirdPartyTokensData: Array<ThirdPartyTableData> = (
    userCredentials?.items || []
  ).map((cred) => ({
    uuid: cred.uuid,
    name: credsKindToText(cred.name as UserCredentialsKind),
    credentialType: credsKindToText(cred.kind),
    secret: CENSORED_TOKEN,
  }));

  return (
    <>
      <TableWrapper>
        <Table
          key="thirdPartyCreds"
          columns={thirdPartyColumns}
          data={thirdPartyTokensData}
          initialState={initialState}
          getRowTestId={(row: Row<ThirdPartyTableData>) => row.original.uuid}
        />
      </TableWrapper>
      <CredentialInput />
    </>
  );
};
