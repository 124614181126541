import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Menu as MenuIcon } from 'ui/common/Icons/Small';
import {
  isInRange,
  isNumberRule,
  isRequiredRule,
} from 'ui/common/Input/inputValidation';
import Menu from 'ui/common/Menu/Menu';
import { ChangedItem, ItemType } from 'ui/common/Menu/menuItemTypes';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { PlotType } from 'ui/dataExplorer/dataExplorerTypes';

const ChartMenuWrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.normal,
  top: theme.spacing.normal,
  zIndex: 1,
  cursor: 'pointer',
}));

interface Props {
  chart: echarts.ECharts;
  traceIds: string[];
  zoomData: (from: number, to: number) => void;
  minY: number;
  maxY: number;
  timeRanges?: { from?: number; to?: number };
  initialTimeRanges?: { from: number; to: number };
}

const ChartMenu: React.FC<Props> = ({
  chart,
  traceIds,
  zoomData,
  minY,
  maxY,
  initialTimeRanges = { from: 0, to: 0 },
  timeRanges,
}) => {
  const dispatch = useAppDispatch();

  const idToTrace = useAppSelector((state) => state.dataExplorer.idToTrace);

  const minX =
    timeRanges?.from !== undefined ? timeRanges.from : initialTimeRanges.from;
  const maxX =
    timeRanges?.to !== undefined ? timeRanges.to : initialTimeRanges.to;
  const [minimumY, setMinimumY] = React.useState<number>(minY);
  const [maximumY, setMaximumY] = React.useState<number>(maxY);

  // If all trace plot types match, set the plotType to that trace plotType,
  // otherwise show a roll-up state of null.
  let plotType: PlotType | undefined;
  for (let i = 0; i < traceIds.length; i++) {
    const traceId = traceIds[i];
    const trace = idToTrace[traceId];
    if (trace) {
      if (plotType === undefined) {
        plotType = trace.plotType;
      } else if (plotType !== trace.plotType) {
        plotType = undefined;
        break;
      }
    }
  }
  const commonValidationRules = [isRequiredRule, isNumberRule];

  const onMenuChanged = (changed: ChangedItem) => {
    if (!chart) return;
    if (changed?.minimumX?.[0] && changed?.minimumX?.[0] !== minX) {
      chart.setOption({ xAxis: { min: changed.minimumX[0] } });
      zoomData(changed.minimumX[0] as number, maxX);
    }
    if (changed?.minimumY?.[0] && changed?.minimumY?.[0] !== minimumY) {
      setMinimumY(parseInt(changed.minimumY[0] as string));
      chart.setOption({ yAxis: { min: changed.minimumY[0] } });
    }
    if (changed?.maximumX?.[0] && changed?.maximumX?.[0] !== maxX) {
      chart.setOption({ xAxis: { max: changed.maximumX[0] } });
      zoomData(minX, changed.maximumX[0] as number);
    }
    if (changed?.maximumY?.[0] && changed?.maximumY?.[0] !== maximumY) {
      setMaximumY(parseInt(changed.maximumY[0] as string));
      chart.setOption({ yAxis: { max: changed.maximumY[0] } });
    }
    if (changed?.lineType?.[0]) {
      const plotType = changed?.lineType?.[0] as PlotType;
      if (plotType) {
        dispatch(
          dataExplorerActions.setPlotType({
            traceIds,
            plotType,
          }),
        );
      }
    }
  };

  const onAutoX = () => {
    if (!chart) return;
    chart.setOption({
      xAxis: { min: initialTimeRanges.from, max: initialTimeRanges.to },
    });
    zoomData(initialTimeRanges.from, initialTimeRanges.to);
  };

  const onAutoY = () => {
    if (!chart) return;
    chart.setOption({
      yAxis: { min: minY, max: maxY },
    });
    setMinimumY(minY);
    setMaximumY(maxY);
  };

  const onRemove = () => {
    dispatch(dataExplorerActions.removeTraces({ traceIds }));
  };

  return (
    <ChartMenuWrapper>
      <Menu
        placement={TooltipPlacement.BOTTOM_LEFT}
        onSubmitValue={onMenuChanged}
        items={[
          {
            type: ItemType.Submenu,
            text: t`Set X Range`,
            items: [
              {
                type: ItemType.Button,
                content: 'Auto',
                onClick: onAutoX,
              },
              { type: ItemType.Separator },
              {
                type: ItemType.Input,
                value: maxX,
                text: t`Maximum`,
                id: 'maximumX',
                validationRules: [
                  ...commonValidationRules,
                  isInRange(initialTimeRanges.from, initialTimeRanges.to, true),
                ],
              },
              {
                type: ItemType.Input,
                value: minX,
                text: t`Minimum`,
                id: 'minimumX',
                validationRules: [
                  ...commonValidationRules,
                  isInRange(initialTimeRanges.from, Number(maxX), true),
                ],
              },
            ],
          },
          {
            type: ItemType.Submenu,
            text: t`Set Y Range`,
            items: [
              {
                type: ItemType.Button,
                content: 'Auto',
                onClick: onAutoY,
              },
              { type: ItemType.Separator },
              {
                type: ItemType.Input,
                value: maximumY,
                text: t`Maximum`,
                id: 'maximumY',
                validationRules: [
                  ...commonValidationRules,
                  isInRange(Number(minimumY)),
                ],
              },
              {
                type: ItemType.Input,
                value: minimumY,
                text: t`Minimum`,
                id: 'minimumY',
                validationRules: [
                  ...commonValidationRules,
                  isInRange(undefined, Number(maximumY)),
                ],
              },
            ],
          },
          { type: ItemType.Separator },
          { type: ItemType.Title, text: 'Chart type' },
          {
            type: ItemType.Select,
            id: 'lineType',
            initialSelected: plotType,
            options: [
              {
                id: 'line',
                content: t({
                  id: 'visualizer.lineType.line',
                  message: 'Line',
                }),
              },
              {
                id: 'scatter',
                content: t({
                  id: 'visualizer.lineType.scatter',
                  message: 'Scatter',
                }),
              },
              {
                id: 'step',
                content: t({
                  id: 'visualizer.lineType.step',
                  message: 'Step',
                }),
              },
            ],
          },
          { type: ItemType.Separator },
          {
            type: ItemType.Button,
            content: 'Remove',
            onClick: onRemove,
          },
        ]}>
        <Button variant={ButtonVariants.SmallTertiary} Icon={MenuIcon} />
      </Menu>
    </ChartMenuWrapper>
  );
};

export default ChartMenu;
