import styled from '@emotion/styled/macro';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  TreeArrowCollapsed,
  TreeArrowExpanded,
} from 'ui/common/Icons/Standard';
import { Small } from 'ui/common/typography/Typography';

const SectionTitle = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing.normal,
  flexShrink: 0,
}));

const TitleText = styled(Small)(({ theme }) => ({
  padding: theme.spacing.small,
}));

const SectionContent = styled.div(({ theme: { spacing } }) => ({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: 'repeat(2, 72px)',
  columnGap: '32px',
  justifyItems: 'center',
  justifyContent: 'space-around',
  alignItems: 'center',
  padding: `0 ${spacing.xxlarge}`,
  overflow: 'hidden',
  paddingBottom: spacing.xlarge,
  paddingTop: spacing.normal,
  flexShrink: 0,
}));

const TitleCount = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 16,
  padding: '0 6px',
  marginLeft: 4,
  borderRadius: 4,
  background: theme.colors.brand.primary.base,
  fontWeight: 'bold',
  color: 'white',
}));

interface Props {
  title?: string;
  children?: React.ReactNode;
  count?: number;
}

const BlockSubsection: React.FC<Props> = ({ children, title, count }) => {
  // If there's no title there's no expander, so default to expanded
  const [isExpanded, setIsExpanded] = React.useState(!title);
  return (
    <>
      {title && (
        <SectionTitle>
          <Button
            variant={ButtonVariants.LargeTertiary}
            Icon={isExpanded ? TreeArrowExpanded : TreeArrowCollapsed}
            onClick={() => setIsExpanded(!isExpanded)}
          />
          <TitleText>{title}</TitleText>
          {count !== undefined && <TitleCount>{count}</TitleCount>}
        </SectionTitle>
      )}
      {isExpanded && <SectionContent>{children}</SectionContent>}
    </>
  );
};

export default BlockSubsection;
