import {
  RedirectToSignIn as ClerkRedirectToSignIn,
  SignedIn as ClerkSignedIn,
  SignedOut as ClerkSignedOut,
  UserButton as ClerkUserButton,
  useUser as clerkUseUser,
} from '@clerk/clerk-react';
import { UserResource } from '@clerk/types';
import { PropsWithChildren } from 'react';

// Copied from clerk useUser.
type UseUserReturn =
  | {
      isLoaded: false;
      isSignedIn: undefined;
      user: undefined;
    }
  | {
      isLoaded: true;
      isSignedIn: false;
      user: null;
    }
  | {
      isLoaded: true;
      isSignedIn: true;
      user: UserResource;
    };

export const SignedIn: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  if (process.env.REACT_APP_IS_OFFLINE === 'true') {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return <ClerkSignedIn>{children}</ClerkSignedIn>;
};

export const SignedOut: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  if (process.env.REACT_APP_IS_OFFLINE === 'true') {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return <ClerkSignedOut>{children}</ClerkSignedOut>;
};

export const RedirectToSignIn: React.FC = () => {
  if (process.env.REACT_APP_IS_OFFLINE === 'true') {
    return null;
  }
  return <ClerkRedirectToSignIn />;
};

export const UserButton: React.FC = () => {
  if (process.env.REACT_APP_IS_OFFLINE === 'true') {
    return null;
  }
  return <ClerkUserButton />;
};

export function useUser(): UseUserReturn {
  if (process.env.REACT_APP_IS_OFFLINE === 'true') {
    return {
      isLoaded: false,
      isSignedIn: undefined,
      user: undefined,
    };
  }
  return clerkUseUser();
}
