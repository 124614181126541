import React from 'react';
import { useAppDispatch } from 'app/hooks';
import { notificationsActions } from 'app/slices/notificationsSlice';
import { Check, Exclamation, Information } from 'ui/common/Icons/Standard';
import { extractErrorMessage } from 'ui/common/notifications/errorFormatUtils';

export function matchesExpectedError(
  error: any,
  expectedErrorMessage: string,
): boolean {
  if (error.data?.code === 400 && typeof error.data?.message === 'string') {
    const message = error.data.message as string;
    return message.startsWith(expectedErrorMessage);
  }
  return false;
}

export function useNotifications() {
  const dispatch = useAppDispatch();

  const showErrorBase = React.useCallback(
    (
      clientErrorMessage: string,
      serverError?: any,
      options?: { isExpectedError?: boolean },
      icon?: React.FC<any>,
    ) => {
      const serverErrorMessage = extractErrorMessage(serverError);
      const fullError = serverErrorMessage
        ? `${clientErrorMessage} ${serverErrorMessage}`
        : clientErrorMessage;
      dispatch(
        notificationsActions.setCurrentMessage({
          message: fullError,
          icon,
          canClose: true,
        }),
      );

      if (!options?.isExpectedError) {
        console.error(fullError, serverErrorMessage);
      }
    },
    [dispatch],
  );

  const showError = React.useCallback(
    (
      clientErrorMessage: string,
      serverError?: any,
      options?: { isExpectedError?: boolean },
    ) => {
      showErrorBase(clientErrorMessage, serverError, options, Exclamation);
    },
    [showErrorBase],
  );

  const createShowError = React.useCallback(
    (clientErrorMessage: string) => (serverError: any) =>
      showError(clientErrorMessage, serverError),
    [showError],
  );

  const showCompletion = React.useCallback(
    (infoMessage: string) => {
      dispatch(
        notificationsActions.setCurrentMessage({
          message: infoMessage,
          icon: Check,
          canClose: true,
        }),
      );
    },
    [dispatch],
  );

  const showInfo = React.useCallback(
    (infoMessage: string) => {
      dispatch(
        notificationsActions.setCurrentMessage({
          message: infoMessage,
          icon: Information,
          canClose: true,
        }),
      );
    },
    [dispatch],
  );

  return {
    createShowError,
    showError,
    showInfo,
    showCompletion,
  };
}
