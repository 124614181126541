/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Check: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M18.45 6.40001C18.7814 6.64854 18.8485 7.11864 18.6 7.45001L11.263 17.2327C10.912 17.7007 10.2359 17.7687 9.79865 17.3801L5.50172 13.5606C5.19213 13.2854 5.16425 12.8113 5.43944 12.5017C5.71462 12.1922 6.18868 12.1643 6.49827 12.4395L10.3891 15.8979L17.4 6.55001C17.6485 6.21864 18.1186 6.15149 18.45 6.40001Z"
      fill={props.fill}
    />
  </svg>
);

Check.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Check;
