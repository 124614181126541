/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const S3: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.552 18.04C6.656 18.04 5.90466 17.9093 5.298 17.648C4.70066 17.3867 4.248 17.046 3.94 16.626C3.632 16.206 3.46866 15.758 3.45 15.282C3.45 15.2073 3.478 15.142 3.534 15.086C3.59 15.0207 3.66 14.988 3.744 14.988H5.242C5.36333 14.988 5.45666 15.016 5.522 15.072C5.58733 15.1187 5.648 15.1793 5.704 15.254C5.76933 15.4127 5.872 15.5667 6.012 15.716C6.16133 15.8653 6.362 15.9913 6.614 16.094C6.866 16.1873 7.17866 16.234 7.552 16.234C8.15866 16.234 8.61133 16.136 8.91 15.94C9.218 15.744 9.372 15.478 9.372 15.142C9.372 14.8993 9.288 14.7033 9.12 14.554C8.96133 14.4047 8.70466 14.2693 8.35 14.148C8.00466 14.0267 7.54266 13.896 6.964 13.756C6.264 13.6067 5.66666 13.4153 5.172 13.182C4.68666 12.9393 4.318 12.6313 4.066 12.258C3.82333 11.8753 3.702 11.404 3.702 10.844C3.702 10.284 3.856 9.78933 4.164 9.36C4.472 8.92133 4.906 8.58067 5.466 8.338C6.026 8.086 6.69333 7.96 7.468 7.96C8.09333 7.96 8.644 8.044 9.12 8.212C9.60533 8.38 10.0067 8.59933 10.324 8.87C10.6507 9.14067 10.898 9.43 11.066 9.738C11.234 10.046 11.3227 10.3447 11.332 10.634C11.332 10.7087 11.304 10.7787 11.248 10.844C11.192 10.9 11.122 10.928 11.038 10.928H9.47C9.37666 10.928 9.29266 10.9093 9.218 10.872C9.14333 10.8347 9.08266 10.7693 9.036 10.676C8.98 10.4333 8.812 10.2233 8.532 10.046C8.26133 9.85933 7.90666 9.766 7.468 9.766C7.01066 9.766 6.64666 9.85 6.376 10.018C6.11466 10.186 5.984 10.4427 5.984 10.788C5.984 11.012 6.04933 11.2033 6.18 11.362C6.32 11.5113 6.54866 11.6467 6.866 11.768C7.19266 11.88 7.622 12.006 8.154 12.146C8.97533 12.314 9.64266 12.5147 10.156 12.748C10.6693 12.9813 11.0473 13.2847 11.29 13.658C11.5327 14.022 11.654 14.484 11.654 15.044C11.654 15.6693 11.4767 16.206 11.122 16.654C10.7767 17.102 10.296 17.4473 9.68 17.69C9.07333 17.9233 8.364 18.04 7.552 18.04Z"
      fill={props.fill}
    />
    <path
      d="M16.5728 18.04C15.8821 18.04 15.2895 17.956 14.7948 17.788C14.3001 17.62 13.8941 17.4053 13.5768 17.144C13.2595 16.8827 13.0215 16.598 12.8628 16.29C12.7041 15.9727 12.6201 15.6693 12.6108 15.38C12.6108 15.296 12.6388 15.226 12.6948 15.17C12.7508 15.114 12.8208 15.086 12.9048 15.086H14.4448C14.5475 15.086 14.6315 15.1093 14.6968 15.156C14.7621 15.1933 14.8228 15.2633 14.8788 15.366C14.9535 15.5807 15.0748 15.7533 15.2428 15.884C15.4108 16.0147 15.6068 16.108 15.8308 16.164C16.0641 16.2107 16.3115 16.234 16.5728 16.234C17.0955 16.234 17.5061 16.1127 17.8048 15.87C18.1035 15.6273 18.2528 15.2867 18.2528 14.848C18.2528 14.4 18.1128 14.078 17.8328 13.882C17.5528 13.686 17.1561 13.588 16.6428 13.588H15.1448C15.0515 13.588 14.9721 13.5553 14.9068 13.49C14.8415 13.4247 14.8088 13.3453 14.8088 13.252V12.566C14.8088 12.454 14.8275 12.3607 14.8648 12.286C14.9115 12.2113 14.9581 12.1553 15.0048 12.118L17.4128 9.906H13.4648C13.3621 9.906 13.2781 9.87333 13.2128 9.808C13.1475 9.74267 13.1148 9.66333 13.1148 9.57V8.45C13.1148 8.34733 13.1475 8.26333 13.2128 8.198C13.2781 8.13267 13.3621 8.1 13.4648 8.1H19.6808C19.7835 8.1 19.8675 8.13267 19.9328 8.198C19.9981 8.26333 20.0308 8.34733 20.0308 8.45V9.458C20.0308 9.56067 20.0121 9.64933 19.9748 9.724C19.9375 9.78933 19.8908 9.84067 19.8348 9.878L17.5528 12.132L17.6928 12.146C18.2435 12.202 18.7335 12.3373 19.1628 12.552C19.5921 12.7667 19.9281 13.0747 20.1708 13.476C20.4228 13.868 20.5488 14.3673 20.5488 14.974C20.5488 15.6087 20.3761 16.1547 20.0308 16.612C19.6855 17.0693 19.2141 17.424 18.6168 17.676C18.0195 17.9187 17.3381 18.04 16.5728 18.04Z"
      fill={props.fill}
    />
  </svg>
);

S3.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default S3;
