import * as NVG from 'nanovg-js';
import { drawNode } from 'ui/modelRendererInternals/drawNode';
import { drawLink } from 'ui/modelRendererInternals/drawLink';
import {
  keysPressed,
  PortConnLUTType,
  RendererState,
} from 'ui/modelRendererInternals/modelRenderer';
import { MouseActions } from 'app/common_types/MouseTypes';
import { drawAnnotation } from './drawAnnotation';

const SELECTION_COLOR = NVG.RGB(50, 188, 184);

export function drawScene(
  nvg: NVG.Context,
  rs: RendererState,
  connectedPortsLUT: PortConnLUTType,
  selectedBlockIds: string[],
  width: number,
  height: number,
): void {
  for (let i = 0; i < rs.refs.current.annotations.length; i++) {
    const annoData = rs.refs.current.annotations[i];
    const selected = rs.refs.current.selectedAnnotationIds.includes(
      annoData.uuid,
    );
    drawAnnotation(nvg, rs, annoData, selected);
  }

  for (let i = 0; i < rs.linksRenderFrameData.length; i++) {
    const linkData = rs.linksRenderFrameData[i];
    drawLink(
      nvg,
      rs, // TODO: decouple renderer state from primitive render funcs
      linkData,
      rs.camera.x,
      rs.camera.y,
    );
  }

  for (let i = 0; i < rs.refs.current.nodes.length; i++) {
    const node = rs.refs.current.nodes[i];
    const renderX = node.uiprops.x + rs.camera.x;
    const renderY = node.uiprops.y + rs.camera.y;
    drawNode(
      nvg,
      rs,
      node,
      connectedPortsLUT[node.uuid],
      selectedBlockIds.includes(node.uuid),
      renderX,
      renderY,
    );
  }

  if (
    rs.mouseState.state === MouseActions.MakingSelection ||
    rs.mouseState.state === MouseActions.DefiningAnnotationBox
  ) {
    const isForAnnotation =
      rs.mouseState.state === MouseActions.DefiningAnnotationBox;
    const { rawScreenCursorStartX, rawScreenCursorStartY } = rs.mouseState;
    const minX = Math.min(rs.screenCursorRaw.x, rawScreenCursorStartX);
    const maxX = Math.max(rs.screenCursorRaw.x, rawScreenCursorStartX);
    const minY = Math.min(rs.screenCursorRaw.y, rawScreenCursorStartY);
    const maxY = Math.max(rs.screenCursorRaw.y, rawScreenCursorStartY);

    const w = maxX - minX;
    const h = maxY - minY;
    const widthSteps = w / 10;
    const heightSteps = h / 10;

    nvg.strokeWidth(1);
    nvg.strokeColor(
      isForAnnotation ? nvg.RGBA(100, 100, 100, 255) : SELECTION_COLOR,
    );

    for (let i = 0; i < widthSteps; i++) {
      const lineStart = minX + i * 10;
      const lineEnd = Math.min(lineStart + 5, maxX);
      // beginning side line
      nvg.beginPath();
      nvg.moveTo(lineStart, minY);
      nvg.lineTo(lineEnd, minY);
      nvg.stroke();
      // ending side line
      nvg.beginPath();
      nvg.moveTo(lineStart, maxY);
      nvg.lineTo(lineEnd, maxY);
      nvg.stroke();
    }

    for (let i = 0; i < heightSteps; i++) {
      const lineStart = minY + i * 10;
      const lineEnd = Math.min(lineStart + 5, maxY);
      // beginning side line
      nvg.beginPath();
      nvg.moveTo(minX, lineStart);
      nvg.lineTo(minX, lineEnd);
      nvg.stroke();
      // ending side line
      nvg.beginPath();
      nvg.moveTo(maxX, lineStart);
      nvg.lineTo(maxX, lineEnd);
      nvg.stroke();
    }
  }

  if (rs.refs.current.uiFlags.showDebugKeyInfo) {
    const parts = JSON.stringify(keysPressed, null, 2)
      .replace('{', '')
      .replace('}', '')
      .split('\n');

    nvg.beginPath();
    nvg.rect(240, 90, 150, parts.length * 15);
    nvg.fillColor(NVG.RGBA(255, 255, 255, 128));
    nvg.fill();

    for (let i = 0; i < parts.length; i++) {
      nvg.fontSize(12);
      nvg.textAlign(NVG.Align.LEFT);
      nvg.fillColor(nvg.RGBA(74, 74, 74, 255));
      nvg.text(250, 100 + i * 15, parts[i], null);
    }
  }
}
