/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Code: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.85355 6.43413C6.04882 6.64697 6.04882 6.99204 5.85355 7.20487L4.20711 8.99948L5.85355 10.7941C6.04882 11.0069 6.04882 11.352 5.85355 11.5648C5.65829 11.7777 5.34171 11.7777 5.14645 11.5648L3.14645 9.38485C2.95118 9.17201 2.95118 8.82694 3.14645 8.61411L5.14645 6.43413C5.34171 6.2213 5.65829 6.2213 5.85355 6.43413Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.1464 6.4344C9.95118 6.64723 9.95118 6.9923 10.1464 7.20514L11.7929 8.99974L10.1464 10.7944C9.95118 11.0072 9.95118 11.3523 10.1464 11.5651C10.3417 11.7779 10.6583 11.7779 10.8536 11.5651L12.8536 9.38511C13.0488 9.17228 13.0488 8.82721 12.8536 8.61438L10.8536 6.4344C10.6583 6.22157 10.3417 6.22157 10.1464 6.4344Z"
      fill={props.fill}
    />
    <path
      d="M8.26352 6.43889C8.38435 6.0767 8.80024 5.89368 9.11394 6.06464C9.34008 6.18788 9.4384 6.45968 9.35129 6.72077L7.73634 11.5612C7.61551 11.9233 7.19966 12.1063 6.886 11.9354C6.65989 11.8121 6.56159 11.5404 6.64868 11.2793L8.26352 6.43889Z"
      fill={props.fill}
    />
  </svg>
);

Code.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Code;
