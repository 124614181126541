import { ChatMessage } from 'app/third_party_types/chat-types';

export enum MessageBlockCodeType {
  Plot = 'plot',
  AnalyzeResult = 'analyze_result',
  Other = 'other',
}

export type FunctionMessageBlock = {
  result: string;
  hasError: boolean;
};

export type CodeMessageBlock = {
  code: string;
  codeType: MessageBlockCodeType;
} & FunctionMessageBlock;

export type PlotMessageBlock = {
  plot: string;
};

export type DefaultMessageBlock = {
  content: string;
};

export type MessageBlock =
  | DefaultMessageBlock
  | CodeMessageBlock
  | PlotMessageBlock;

export type MessageContents = {
  visibleBlocks: MessageBlock[];
  hiddenBlocks?: MessageBlock[];
};

const parsePlots = (blocks: MessageBlock[], plots: string[]) => {
  const plotRegex = /\[\[plot_id:(\d+)\]\]/g;
  return blocks.flatMap((block) => {
    if (!('content' in block)) {
      return block;
    }

    // find plotRegex in block.content and split the content into an array containing the text before the plot, the plot and the text after the plot
    const plotRegexMatch = block.content.match(plotRegex);
    if (plotRegexMatch) {
      const subblocks: MessageBlock[] = [];
      let lastBlockIndex = 0;
      plotRegexMatch.forEach((match) => {
        const plotId = match.replace('[[plot_id:', '').replace(']]', '');
        const plotIdInt = parseInt(plotId);
        const plotContent =
          plotIdInt < 0 || plotIdInt >= plots.length
            ? `Could not find plot with id ${plotIdInt}.`
            : plots[plotIdInt];
        const plotIndex = block.content.indexOf(match);
        subblocks.push({
          content: block.content.slice(lastBlockIndex, plotIndex),
        });
        subblocks.push({
          plot: plotContent,
        });
        lastBlockIndex = plotIndex + match.length;
      });
      subblocks.push({
        content: block.content.slice(lastBlockIndex),
      });
      return subblocks;
    }
    return block;
  });
};

const parseGptMessageBlocks = (
  text: string,
  plots?: string[],
): MessageBlock[] => {
  const blocks: MessageBlock[] = [];

  let currentBlock = '';
  let isCode = false;

  const lines = text.split('\n');
  lines.forEach((line) => {
    if (line.startsWith('```') || line.endsWith('```')) {
      if (currentBlock) {
        const pfx =
          line.endsWith('```') && line !== '```'
            ? `\n${line.slice(0, -3)}`
            : '';
        blocks.push(
          isCode
            ? {
                code: currentBlock + pfx,
                result: '',
                hasError: false,
                codeType: MessageBlockCodeType.Other,
              }
            : {
                content: currentBlock + pfx,
              },
        );
        currentBlock = '';
      }
      isCode = !isCode;
    } else if (line) {
      currentBlock += `${line}\n`;
    }
  });

  if (currentBlock) {
    blocks.push(
      isCode
        ? {
            code: currentBlock,
            codeType: MessageBlockCodeType.Other,
            result: '',
            hasError: false,
          }
        : {
            content: currentBlock,
          },
    );
  }

  if (!plots) return blocks;

  return parsePlots(blocks, plots);
};

const getCode = (functionArgs: string | undefined) => {
  if (!functionArgs) return '';
  try {
    const argsJson = JSON.parse(functionArgs);
    if (argsJson.code) {
      return argsJson.code;
    }
    return '';
  } catch (e) {
    return '';
  }
};

const functionNameToCodeType = (functionName: string) => {
  switch (functionName) {
    case 'execute_python':
      return MessageBlockCodeType.AnalyzeResult;
    case 'plot':
      return MessageBlockCodeType.Plot;
    default:
      return MessageBlockCodeType.Other;
  }
};

const parseGptFunctionMessage = (
  msg: ChatMessage,
  plots: string[],
): MessageContents => {
  const visibleBlocks: MessageBlock[] = [];
  const hiddenBlocks: MessageBlock[] = [
    {
      content: `GPT Function: ${msg.functionName} (${msg.functionArgs}) => ${msg.functionResult}`,
    },
  ];
  const plotRegex = /\[\[plot_id:(\d+)\]\]/g;
  const plotRegexMatch = msg.functionResult?.match(plotRegex);
  let codeBlock = getCode(msg.functionArgs);
  let block: MessageBlock;
  if (codeBlock) {
    block = {
      code: codeBlock,
      result: msg.functionResult,
      hasError: msg.functionHasError,
      codeType: msg.functionName
        ? functionNameToCodeType(msg.functionName)
        : undefined,
    } as CodeMessageBlock;
    visibleBlocks.push(block);
  }

  if (msg.functionResult && plotRegexMatch) {
    visibleBlocks.push(
      ...parsePlots(
        [
          {
            content: msg.functionResult,
          },
        ],
        plots,
      ),
    );
  }

  return {
    visibleBlocks,
    hiddenBlocks,
  };
};

export const parseGptMessage = (
  msg: ChatMessage,
  plots: string[],
): MessageContents => {
  if (msg.role === 'function') {
    return parseGptFunctionMessage(msg, plots);
  }
  const inputText = msg.content;

  if (msg.automaticMessageContent) {
    const autoContent = msg.automaticMessageContent;
    const userContent = msg.originalUserContent || msg.content;

    const hiddenBlocks = parseGptMessageBlocks(autoContent);
    const visibleBlocks = parseGptMessageBlocks(userContent, plots);

    return { hiddenBlocks, visibleBlocks };
  }

  const visibleBlocks = parseGptMessageBlocks(inputText, plots);
  return { visibleBlocks };
};
