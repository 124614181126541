// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const ImageSegmentation: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "ImageSegmentation",
      "namespace": "core",
      "description": "Panoptic segmentation. Model was trained on COCO 2017 panoptic. Output is a python dictionary of \"segmentations\" numpy array with each position set to the ID of the object detected and \"annotations\" list of python dictionary containing the \"label\", \"score\" and \"id\" for each object detected."
    },
    "modes": {
      "time": "discrete",
      "feedthrough": true
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "image"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "segmentations"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "model",
        "data_type": "string",
        "default_value": "detr-resnet-50-panoptic",
        "allowed_values": [
          "detr-resnet-50-panoptic"
        ],
        "description": "Image segmentation model."
      }
    ]
  }
);
