/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const QuestionMark: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.276 13.55C11.308 13.574 11.344 13.586 11.384 13.586H12.776C12.816 13.586 12.848 13.574 12.872 13.55C12.904 13.518 12.92 13.482 12.92 13.442V13.166C12.92 12.958 12.988 12.782 13.124 12.638C13.26 12.494 13.472 12.322 13.76 12.122C14.032 11.93 14.256 11.754 14.432 11.594C14.608 11.434 14.756 11.23 14.876 10.982C15.004 10.726 15.068 10.422 15.068 10.07C15.068 9.606 14.956 9.198 14.732 8.846C14.508 8.494 14.188 8.222 13.772 8.03C13.364 7.83 12.888 7.73 12.344 7.73C11.784 7.73 11.288 7.834 10.856 8.042C10.432 8.242 10.1 8.53 9.86 8.906C9.62 9.274 9.5 9.698 9.5 10.178V10.298C9.5 10.338 9.512 10.374 9.536 10.406C9.568 10.43 9.604 10.442 9.644 10.442L11.048 10.502C11.144 10.502 11.192 10.458 11.192 10.37V10.178C11.192 9.914 11.292 9.698 11.492 9.53C11.7 9.362 11.972 9.278 12.308 9.278C12.62 9.278 12.872 9.358 13.064 9.518C13.256 9.67 13.352 9.874 13.352 10.13C13.352 10.37 13.276 10.574 13.124 10.742C12.972 10.91 12.74 11.102 12.428 11.318C12.044 11.59 11.748 11.85 11.54 12.098C11.34 12.338 11.24 12.658 11.24 13.058V13.442C11.24 13.482 11.252 13.518 11.276 13.55Z"
      fill={props.fill}
    />
    <path
      d="M11.348 16.01C11.532 16.186 11.76 16.274 12.032 16.274C12.304 16.274 12.528 16.186 12.704 16.01C12.88 15.826 12.968 15.598 12.968 15.326C12.968 15.046 12.88 14.822 12.704 14.654C12.536 14.478 12.312 14.39 12.032 14.39C11.752 14.39 11.524 14.478 11.348 14.654C11.172 14.83 11.084 15.054 11.084 15.326C11.084 15.598 11.172 15.826 11.348 16.01Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM18.5 12C18.5 15.5899 15.5899 18.5 12 18.5C8.41015 18.5 5.5 15.5899 5.5 12C5.5 8.41015 8.41015 5.5 12 5.5C15.5899 5.5 18.5 8.41015 18.5 12Z"
      fill={props.fill}
    />
  </svg>
);

QuestionMark.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default QuestionMark;
