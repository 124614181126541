/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  LinkInstance,
  LinkSegmentType,
  NodeInstance,
} from '@collimator/model-schemas-ts';
import { renderConstants } from 'app/utils/renderConstants';
import ELK, { ElkNode } from 'elkjs/lib/elk.bundled.js';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { Coordinate } from './common_types/Coordinate';
import { modelActions } from './slices/modelSlice';
import { snapNumberToGrid } from './utils/modelDataUtils';

type ElkLink = { id: string; sources: string[]; targets: string[] };

const createElkPortId = (
  nodeID: string,
  port: number,
  side: 'input' | 'output',
) => `port|nodeID:${nodeID}|idx:${port}|side:${side}`;

const extractTapLinkDataFromElkNode = (elkNode: ElkNode) => {
  const linkIdInfo = elkNode.id
    .split('|')
    .reduce<{ [k: string]: string | undefined }>((acc, pair) => {
      const splitPair = pair.split(':');
      return {
        ...acc,
        [splitPair[0]]: splitPair[1],
      };
    }, {});

  return {
    tappingLinkUuid: linkIdInfo.tapnode_linkid || '',
    hostLinkUuid: linkIdInfo.hostlink || '',
    coordinate: {
      x: elkNode.x || 0,
      y: elkNode.y || 0,
    },
  };
};

export type AutoLayoutTapData = Array<
  ReturnType<typeof extractTapLinkDataFromElkNode>
>;

export type AutolayoutPayload = {
  nodeCoordinates: Record<string, Coordinate | undefined>;
  linkLayoutLut: Record<string, Array<LinkSegmentType>>;
  tapData: AutoLayoutTapData;
  tappedHostLinkIds: Set<string>;
};

export const runAutolayout = (
  nodes: NodeInstance[],
  links: LinkInstance[],
): Promise<AutolayoutPayload | undefined | void> => {
  const elk = new ELK();

  const elkNodes: Array<ElkNode> = nodes.map((node) => ({
    id: node.uuid,
    name: node.name, // Ignored by ELK, only for debug
    width: node.uiprops.grid_width
      ? node.uiprops.grid_width * renderConstants.GRID_SIZE
      : renderConstants.BLOCK_MIN_WIDTH,
    height: node.uiprops.grid_height
      ? node.uiprops.grid_height * renderConstants.GRID_SIZE
      : renderConstants.BLOCK_MIN_HEIGHT,
    layoutOptions: {
      'elk.portConstraints': 'FIXED_ORDER',
      'elk.portAlignment.east': 'CENTER',
      'elk.portAlignment.west': 'CENTER',
    },
    ports: [
      ...node.inputs.map((_, idx) => ({
        id: createElkPortId(node.uuid, idx, 'input'),
        width: 1,
        height: 1,
        properties: {
          'port.side': 'WEST',
          // 'port.index': `${idx}`,
          'port.index': `${node.outputs.length + node.inputs.length - idx - 1}`,
        },
      })),
      ...node.outputs.map((_, idx) => ({
        id: createElkPortId(node.uuid, idx, 'output'),
        width: 1,
        height: 1,
        properties: {
          'port.side': 'EAST',
          // 'port.index': `${node.inputs.length + idx}`,
          'port.index': `${idx}`,
        },
      })),
    ],
  }));

  const validLinksGroupedBySrc = links.reduce<{
    [srcIdWithPort: string]: LinkInstance[] | undefined;
  }>((acc, link) => {
    if (!link.src || !link.dst) {
      return acc;
    }

    const srcIdWithPort = `${link.src.node}:${link.src.port}`;
    return {
      ...acc,
      [srcIdWithPort]: [...(acc[srcIdWithPort] || []), link],
    };
  }, {});

  const elkLinks: ElkLink[] = [];
  const linkGroupKeys = Object.keys(validLinksGroupedBySrc);
  for (let i = 0; i < linkGroupKeys.length; i++) {
    const currentLinkGroup = validLinksGroupedBySrc[linkGroupKeys[i]];
    if (!currentLinkGroup || currentLinkGroup.length === 0) continue;
    if (currentLinkGroup.length === 1) {
      const link = currentLinkGroup[0];
      elkLinks.push({
        id: link.uuid,
        sources: [createElkPortId(link.src!.node, link.src!.port, 'output')],
        targets: [createElkPortId(link.dst!.node, link.dst!.port, 'input')],
      });
      continue;
    }

    let previousTapElkNodeId = '';
    const hostLinkDest = currentLinkGroup[0].dst!;
    for (let j = 0; j < currentLinkGroup.length; j++) {
      const link = currentLinkGroup[j];
      if (j === 0) {
        const nextLink = currentLinkGroup[j + 1];
        const nextLinkElkNodeId = `tapnode_linkid:${nextLink.uuid}|hostlink:${currentLinkGroup[0].uuid}`;
        elkNodes.push({
          id: nextLinkElkNodeId,
          width: 1,
          height: 1,
        });
        elkLinks.push({
          id: link.uuid,
          sources: [createElkPortId(link.src!.node, link.src!.port, 'output')],
          targets: [nextLinkElkNodeId],
        });
        elkLinks.push({
          id: nextLink.uuid,
          sources: [nextLinkElkNodeId],
          targets: [createElkPortId(link.dst!.node, link.dst!.port, 'input')],
        });
        previousTapElkNodeId = nextLinkElkNodeId;
      } else if (j === currentLinkGroup.length - 1) {
        elkLinks.push({
          id: `bridge_linkid:${link.uuid}`,
          sources: [previousTapElkNodeId],
          targets: [
            createElkPortId(hostLinkDest!.node, hostLinkDest!.port, 'input'),
          ],
        });
      } else {
        const nextLink = currentLinkGroup[j + 1];
        const nextLinkElkNodeId = `tapnode_linkid:${nextLink.uuid}|hostlink:${currentLinkGroup[0].uuid}`;
        elkNodes.push({
          id: nextLinkElkNodeId,
          width: 1,
          height: 1,
        });
        elkLinks.push({
          id: `bridge_linkid:${link.uuid}`,
          sources: [previousTapElkNodeId],
          targets: [nextLinkElkNodeId],
        });
        elkLinks.push({
          id: nextLink.uuid,
          sources: [nextLinkElkNodeId],
          targets: [createElkPortId(link.dst!.node, link.dst!.port, 'input')],
        });
        previousTapElkNodeId = nextLinkElkNodeId;
      }
    }
  }

  const elkGraph: ElkNode = {
    id: 'root',
    layoutOptions: {
      'elk.edge.thickness': `${renderConstants.GRID_SIZE}`,
      'elk.spacing.edgeEdge': `${renderConstants.GRID_SIZE * 2}`,
      'elk.spacing.edgeNode': `${renderConstants.BLOCK_MIN_WIDTH * 0.75}`,
      'elk.spacing.nodeNode': `${renderConstants.BLOCK_MIN_WIDTH}`,
      'elk.layered.spacing.edgeEdgeBetweenLayers': `${renderConstants.GRID_SIZE}`,
      'elk.layered.spacing.nodeNodeBetweenLayers': `${
        renderConstants.BLOCK_MIN_WIDTH * 0.5
      }`,
      'elk.layered.considerModelOrder.portModelOrder': 'true',
      'elk.layered.edgeRouting': 'ORTHOGONAL',
      'elk.portAlignment.default': 'CENTER',
    },
    children: elkNodes,
    edges: elkLinks,
  };

  const layout = (elkGraph: ElkNode, alg: string) =>
    elk.layout({
      ...elkGraph,
      layoutOptions: { ...elkGraph.layoutOptions, 'elk.algorithm': alg },
    });

  return layout(elkGraph, 'layered')
    .then((val) => {
      const tapData: AutoLayoutTapData = [];
      const tappedHostLinkIds = new Set<string>();

      const layoutNodes: ElkNode[] = val.children || [];
      const nodeCoordinates = layoutNodes.reduce<
        Record<string, Coordinate | undefined>
      >((acc, node) => {
        if (node.id.includes('tapnode_linkid')) {
          const data = extractTapLinkDataFromElkNode(node);
          tapData.push(data);
          tappedHostLinkIds.add(data.hostLinkUuid);
          return acc;
        }

        return {
          ...acc,
          [node.id]: {
            x: snapNumberToGrid(node.x || 0),
            y: snapNumberToGrid(node.y || 0),
          },
        };
      }, {});

      const linkLayoutLut: Record<string, Array<LinkSegmentType>> = (
        val.edges || []
      ).reduce((acc, edge) => {
        const source = edge.sources[0];
        const target = edge.targets[0];

        // tapping and tapped links are curently a special case in the layout.
        // as such, the resulting segment data is likely
        // not exactly valid for the final layout,
        // so links that are related to taps will be ignored.
        if (
          source.includes('tapnode_linkid') ||
          target.includes('tapnode_linkid')
        ) {
          return acc;
        }

        const bendPoints = (edge.sections || [])[0].bendPoints || [];
        return {
          ...acc,
          [edge.id]: bendPoints.reduce<Array<LinkSegmentType>>((acc, bp, i) => {
            // we discard the last "bend point" because
            // it's not valid for how our link rendering works
            if (i === bendPoints.length - 1) return acc;

            return [
              ...acc,
              {
                segment_direction: i % 2 === 0 ? 'vert' : 'horiz',
                coordinate: snapNumberToGrid(i % 2 === 0 ? bp.x : bp.y),
              },
            ];
          }, []),
        };
      }, {});

      return {
        nodeCoordinates,
        tapData,
        linkLayoutLut,
        tappedHostLinkIds,
      };
    })
    .catch(console.error);
};

export const globalRunAutolayout_avoidUsing = (): Promise<void> => {
  if (!rendererState) return Promise.resolve();
  return runAutolayout(
    rendererState.refs.current.nodes,
    rendererState.refs.current.links,
  ).then((layoutPayload) => {
    if (layoutPayload && rendererState) {
      rendererState.dispatch(modelActions.consumeAutoLayout(layoutPayload));
    }
  });
};

export const globalRunEraseModelLayout_avoidUsing = () => {
  if (!rendererState) return;

  rendererState.dispatch(modelActions.eraseModelLayout());
};
