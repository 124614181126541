import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FavoriteOptimisticUpdate {
  projectId: string;
  isFavorite: boolean;
}

interface ProjectMetadataState {
  projectIdToFavoriteOptimisticUpdate: Record<string, FavoriteOptimisticUpdate>;
}

const initialState: ProjectMetadataState = {
  projectIdToFavoriteOptimisticUpdate: {},
};

const projectMetadataSlice = createSlice({
  name: 'projectMetadataSlice',
  initialState,
  reducers: {
    addFavoriteOptimisticUpdate(
      state,
      action: PayloadAction<{
        projectId: string;
        isFavorite: boolean;
      }>,
    ) {
      const { projectId, isFavorite } = action.payload;

      state.projectIdToFavoriteOptimisticUpdate[projectId] = {
        projectId,
        isFavorite,
      };
    },
    clearFavoriteOptimisticUpdate(
      state,
      action: PayloadAction<{
        projectId: string;
      }>,
    ) {
      const { projectId } = action.payload;

      delete state.projectIdToFavoriteOptimisticUpdate[projectId];
    },
  },
});

export const projectMetadataActions = projectMetadataSlice.actions;

export default projectMetadataSlice;
