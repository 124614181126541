import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PlotCellData,
  TraceContentError,
  TraceLoadState,
} from 'ui/dataExplorer/dataExplorerTypes';

interface DataExplorerPlotDataState {
  idToTraceLoadState: Record<string, TraceLoadState>;
  idToPlotCellData: Record<string, PlotCellData>;
  idToPlotCellDataErrors: Record<string, TraceContentError[]>;
}

const initialState: DataExplorerPlotDataState = {
  idToTraceLoadState: {},
  idToPlotCellData: {},
  idToPlotCellDataErrors: {},
};

const dataExplorerPlotDataSlice = createSlice({
  name: 'DataExplorerPlotDataSlice',
  initialState,
  reducers: {
    resetDataExplorerPlotDataState: () => initialState,

    updateTraceLoadState(state, action: PayloadAction<TraceLoadState>) {
      const traceLoadState = action.payload;
      if (traceLoadState) {
        state.idToTraceLoadState[traceLoadState.traceId] = traceLoadState;
      }
    },

    updatePlotCellData(
      state,
      action: PayloadAction<{
        plotCellId: string;
        plotCellData: PlotCellData;
        plotCellDataErrors: TraceContentError[];
      }>,
    ) {
      const { plotCellId, plotCellData, plotCellDataErrors } = action.payload;
      if (plotCellId && plotCellData) {
        state.idToPlotCellData[plotCellId] = plotCellData;
        state.idToPlotCellDataErrors[plotCellId] = plotCellDataErrors;
      }
    },

    clearPlotCellData(
      state,
      action: PayloadAction<{
        plotCellId: string;
      }>,
    ) {
      const { plotCellId } = action.payload;
      if (plotCellId) {
        delete state.idToPlotCellData[plotCellId];
        delete state.idToPlotCellDataErrors[plotCellId];
      }
    },

    removeTraceLoadStates(
      state,
      action: PayloadAction<{
        traceIds: string[];
      }>,
    ) {
      const { traceIds } = action.payload;
      if (traceIds) {
        traceIds.forEach((traceId) => {
          delete state.idToTraceLoadState[traceId];
        });
      }
    },
  },
});

export const dataExplorerPlotDataActions = dataExplorerPlotDataSlice.actions;

export default dataExplorerPlotDataSlice;
