import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CmlType, TypeMapStruct } from 'app/generated_types/CmlTypes';

export type TimeModeType =
  | { mode: 'Continuous' | 'Constant' | 'Unknown' }
  | { mode: 'Discrete'; stepLevel: number; discreteStep: number };

type SignalAnalysisDataType = {
  datatypeAndDimensions: CmlType[];
  timeMode: TimeModeType | undefined;
};

export interface SignalsData {
  [blockPathName: string]: SignalAnalysisDataType | undefined;
}

export interface CompilationAnalysisDataState {
  signalsDataSimulationId: string;
  signalsData: SignalsData;
  discreteStepsLevelMap: { [stepValue: number]: number | undefined };
  discreteStepsOrderedByLevel: Array<number>;
}

const initialState: CompilationAnalysisDataState = {
  signalsDataSimulationId: '',
  signalsData: {},
  discreteStepsLevelMap: {},
  discreteStepsOrderedByLevel: [],
};

function setCacheSimulationId(
  state: CompilationAnalysisDataState,
  simulationId: string,
) {
  if (state.signalsDataSimulationId !== simulationId) {
    state.signalsData = {};
    state.signalsDataSimulationId = simulationId;
  }
}

const compilationDataSlice = createSlice({
  name: 'compilationDataSlice',
  initialState,
  reducers: {
    clearAllCompilationState() {
      return initialState;
    },
    clearSimulationCache(
      state,
      action: PayloadAction<{
        simulationId: string;
      }>,
    ) {
      const { simulationId } = action.payload;
      setCacheSimulationId(state, simulationId);
    },
    setDiscreteSteps(
      state,
      action: PayloadAction<{
        discreteStepsLevelMap: { [stepValue: number]: number | undefined };
        discreteStepsOrderedByLevel: Array<number>;
      }>,
    ) {
      state.discreteStepsLevelMap = action.payload.discreteStepsLevelMap;
      state.discreteStepsOrderedByLevel =
        action.payload.discreteStepsOrderedByLevel;
    },
    setAllNodeTimeModeData(
      state,
      action: PayloadAction<{
        simulationId: string;
        timeData: { [blockPathName: string]: TimeModeType };
      }>,
    ) {
      const { timeData, simulationId } = action.payload;

      // Clear out any earlier cache before repopulating the cache.
      setCacheSimulationId(state, simulationId);

      const blockPathNames = Object.keys(timeData);

      for (let i = 0; i < blockPathNames.length; i++) {
        const blockPathName = blockPathNames[i];
        const timeMode = timeData[blockPathName];

        state.signalsData[blockPathName] = {
          datatypeAndDimensions: [],
          ...state.signalsData[blockPathName],
          timeMode,
        };
      }
    },
    setAllDatatypeData(
      state,
      action: PayloadAction<{
        simulationId: string;
        typeMap: TypeMapStruct;
      }>,
    ) {
      const { simulationId, typeMap } = action.payload;

      // Clear out any earlier cache before repopulating the cache.
      setCacheSimulationId(state, simulationId);

      for (let i = 0; i < typeMap.length; i++) {
        const portInfo = typeMap[i];
        const portPathName = portInfo.path;
        const portIndex = portInfo.port_index;
        const typeData = portInfo.cml_type;

        const blockPathName = portInfo.is_outport
          ? portPathName
          : portPathName.slice(0, portPathName.lastIndexOf('.'));
        let signalData = state.signalsData[blockPathName];
        if (!signalData) {
          signalData = {
            timeMode: undefined,
            datatypeAndDimensions: [],
          };
          state.signalsData[blockPathName] = signalData;
        }
        signalData.datatypeAndDimensions[portIndex] = typeData;
      }
    },
  },
});

export const compilationAnalysisDataActions = compilationDataSlice.actions;

export default compilationDataSlice;
