// Tree shaking imports available: https://echarts.apache.org/handbook/en/basics/import/#

import styled from '@emotion/styled';
import useDebounce, {
  useAppDispatch,
  useAppSelector,
  useComponentSize,
} from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { traceDragActions } from 'app/slices/traceDragSlice';
import * as echarts from 'echarts';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTimeoutFn } from 'react-use';
import ChartMenu from 'ui/dataExplorer/charts/ChartMenu';
import ReactEChartsChart from 'ui/dataExplorer/charts/ReactEChartsChart';
import TraceContextMenu from 'ui/dataExplorer/charts/TraceContextMenu';
import {
  BASE_PLOT_CONF,
  MARKPOINT_PLOT_CONF,
  SERIES_BLOCK_CONF,
  X_AXIS,
  Y_AXIS,
} from 'ui/dataExplorer/charts/constants';
import {
  DataBounds,
  PartialDataBounds,
  PlotDataRow,
} from 'ui/dataExplorer/dataExplorerTypes';
import { getMouseCoords } from 'util/getMouseCoords';
import { assignFreeColor } from 'util/visualizerUtils';

// Keep this short to minimize the jitteriness of scroll.
const FETCH_CSV_DEBOUNCE_DELAY = 100;

// Zoom types for ECharts inside zoom: https://echarts.apache.org/en/option.html#dataZoom-inside
enum InsideZoom {
  X = 'insideX',
  Y = 'insideY',
}

// isolation: isolate temporarily removed until we figure out the overflowing chart menus.
const ReactEChartsChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

interface Props {
  canEdit: boolean;
  traceIds: string[];
  data: PlotDataRow[];
  initialBounds: DataBounds;
  zoomBounds?: PartialDataBounds;
  zoomData: (from: number, to: number) => void;
}

/**
 * A Chart for Data Explorer and Visualizer.
 * Adds custom Collimator logic to ECharts.
 */
const Chart: React.FC<Props> = ({
  canEdit,
  traceIds,
  data,
  initialBounds,
  zoomBounds,
  zoomData,
}) => {
  const dispatch = useAppDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const idToTrace = useAppSelector((state) => state.dataExplorer.idToTrace);

  const traceIdToMarkedPoints = useAppSelector(
    (state) => state.dataExplorer.traceIdToMarkedPoints,
  );

  const [contextMenuTraceId, setContextMenuTraceId] = useState('');

  const [echartsInstance, setEchartsInstance] = useState<echarts.ECharts>();

  // This is a bit finicky.
  // DataExplorerCellWrapper needs to have height set explicitly for auto resize to work instantly.
  // 100% causes pixel by pixel changes.
  const { height, width } = useComponentSize(wrapperRef);

  // Must we use coords ..
  const [lineMenuCoords, setLineMenuCoords] = useState({
    top: -9999,
    left: -9999,
  });

  // Data source (CSV file) zoom and its debounced state
  // TODO: clarify difference between CSV zoom and echarts only zoom.
  // Drag area zoom is already buggy before refactor.
  const [partialZoom, setPartialZoom] = useState<{
    from: number;
    to: number;
  }>();
  const debouncedZoom = useDebounce(partialZoom, FETCH_CSV_DEBOUNCE_DELAY);
  useEffect(() => {
    if (debouncedZoom?.from !== undefined && debouncedZoom?.to !== undefined) {
      zoomData(debouncedZoom.from, debouncedZoom.to);
    }
  }, [debouncedZoom, zoomData]);

  // Hack to control zoom behaviour. Turn off if no event emitted for set time.
  // ECharts swallows all wheel events when their own dataZoom is on.
  // Even specifying modifier keys does not work, and there's no API to allow default.
  const [echartsScrollZoomOn, setEChartsScrollZoomOn] = useState(false);
  const [_isReady, _cancel, reset] = useTimeoutFn(() => {
    setEChartsScrollZoomOn(false);
  }, 300); // 300ms is the sweet spot. Doesn't trigger while scrolling, and turns off shortly after pause.

  const turnOnDataZoom = useCallback(
    (e: React.WheelEvent<HTMLElement>) => {
      if (e.shiftKey || e.ctrlKey) {
        if (!echartsScrollZoomOn) {
          setEChartsScrollZoomOn(true);
        } else {
          reset();
        }
      }
    },
    [echartsScrollZoomOn, reset],
  );

  // ECharts options for the wrapped instance
  const chartOptions: echarts.EChartsCoreOption = useMemo(() => {
    const usedColors: string[] = [];

    if (!data) return {};

    return {
      ...BASE_PLOT_CONF,
      xAxis: {
        ...X_AXIS,
        min: initialBounds.startX,
        max: initialBounds.endX,
      },
      yAxis: Y_AXIS,
      dataset: [
        {
          source: data,
          // Use traceIds as they are unique across sims. A single chart may contain the same signal multiple times.
          // Unfortunately, these are not human-readable labels. Tooltips, for example, aren't as useful anymore.
          dimensions: [{ name: 'time', type: 'float' }, ...traceIds],
          seriesLayoutBy: 'row',
        },
      ],
      dataZoom: [
        {
          id: InsideZoom.X,
          type: 'inside',
          xAxisIndex: [0],
          zoomOnMouseWheel: 'shift',
          disabled: !echartsScrollZoomOn,
          filterMode: 'none',
          startValue: zoomBounds?.startX,
          endValue: zoomBounds?.endX,
          rangeMode: ['value', 'value'],
        },
        {
          id: InsideZoom.Y,
          type: 'inside',
          yAxisIndex: [0],
          zoomOnMouseWheel: 'ctrl',
          disabled: !echartsScrollZoomOn,
          filterMode: 'none',
        },
      ],
      series: traceIds.map((traceId: string) => {
        const trace = idToTrace[traceId];
        const markedPoints = traceIdToMarkedPoints[traceId] || [];

        const color = trace.color || assignFreeColor(usedColors);
        usedColors.push(color);

        const plotType = trace.plotType === 'step' ? 'line' : trace.plotType;
        const stepType = trace.plotType === 'step' ? 'end' : false;

        return {
          ...SERIES_BLOCK_CONF,
          type: plotType,
          // Used in trace drag from legen item.
          id: traceId,
          large: true,
          name: trace.displayName, // legend display name
          color,
          step: stepType,
          encode: { x: 'time', y: traceId },
          markPoint: {
            data: markedPoints.map((point) => ({
              coord: point,
              value: point[1],
              // Must use traceId because `seriesIndex` or `displayName` are no longer unique.
              name: traceId,
            })),
            ...MARKPOINT_PLOT_CONF,
          },
        };
      }),
    };
  }, [
    data,
    initialBounds,
    traceIds,
    echartsScrollZoomOn,
    zoomBounds,
    idToTrace,
    traceIdToMarkedPoints,
  ]);

  /**
   * Custom chart handler definitions go here, at the same level as custom chart components.
   * Pass to the ECharts wrapper as props, and attach to the ref there.
   */

  // Edit handlers. Use `canEdit` to gate passing as props.

  const prepTraceDrag = useCallback(
    (traceId: string, displayName: string, color: string) => {
      dispatch(
        traceDragActions.setSourceCandidate({
          traceId,
          displayName,
          color,
        }),
      );
    },
    [dispatch],
  );

  const toggleSeriesMarkPoint = useCallback(
    // ECharts needs to be updated to a version that exports the ECElementEvent type.
    (e: any) => {
      // The OptionDataItem type is a catch-all union type used to represent all the different shapes of data. The joys of using dynamically typed code in Typescript.
      if (e.componentType === 'series' && e.dimensionNames && e.seriesId) {
        const traceId = e.seriesId;
        const point: [number, number] = [e.data.time, e.data[traceId]];
        dispatch(dataExplorerActions.addMarkedPoint({ traceId, point }));
      } else if (e.componentType === 'markPoint') {
        const traceId = e.name;
        const point: [number, number] = e.data.coord;
        dispatch(dataExplorerActions.removeMarkedPoint({ traceId, point }));
      }
    },
    [dispatch],
  );

  // Display handlers

  const resetScrollZoomTimeout = useCallback(() => {
    // Don't need to check modifier keys, since dataZoom is emitted *after* the zooming which is caused by modifier key zoom.
    reset();
  }, [reset]);

  const showContextMenu = useCallback((e: any) => {
    setLineMenuCoords(getMouseCoords(wrapperRef, e.event.event));
    setContextMenuTraceId(e.seriesId);

    e.event.event.preventDefault();
  }, []);

  const restoreChartRange = useCallback(
    (e: any) => {
      zoomData(initialBounds.startX, initialBounds.endX);
    },
    [initialBounds, zoomData],
  );

  const setZoom = useCallback(
    (e: any) => {
      // First element in batch is the zoom data for the x-axis
      const zoomData = e.batch[0];
      // TODO implement y-axis setting... const zoomData = e.batch[1];

      // Y zoom does not change the data granularity, only X does.
      if (
        zoomData.dataZoomId === InsideZoom.X ||
        zoomData.dataZoomId.includes('xAxis')
      ) {
        // Either startValue (absolute value) or start (percentage) will be provided.
        if (zoomData.startValue !== undefined) {
          setPartialZoom({ from: zoomData.startValue, to: zoomData.endValue });
        } else {
          const initialRange = initialBounds.endX - initialBounds.startX;
          setPartialZoom({
            from: (initialRange * zoomData.start) / 100,
            to: (initialRange * zoomData.end) / 100,
          });
        }
      }
    },
    [initialBounds],
  );

  return (
    <ReactEChartsChartWrapper
      onWheelCapture={turnOnDataZoom}
      ref={wrapperRef}
      onClick={() => setContextMenuTraceId('')}>
      <TraceContextMenu
        top={lineMenuCoords.top}
        left={lineMenuCoords.left}
        traceId={contextMenuTraceId}
      />
      {echartsInstance && (
        <ChartMenu
          chart={echartsInstance}
          zoomData={zoomData}
          traceIds={traceIds}
          timeRanges={{
            from: zoomBounds?.startX,
            to: zoomBounds?.endX,
          }}
          initialTimeRanges={{
            from: initialBounds.startX,
            to: initialBounds.endX,
          }}
          minY={initialBounds.startY}
          maxY={initialBounds.endY}
        />
      )}
      <ReactEChartsChart
        options={chartOptions}
        width={width}
        height={height}
        // Edit handlers. Use `canEdit` to gate attachment.
        toggleSeriesMarkPoint={canEdit ? toggleSeriesMarkPoint : undefined}
        prepTraceDrag={canEdit ? prepTraceDrag : undefined}
        // Display handlers. Anyone can use since there is no backend change.
        resetScrollZoomTimeout={resetScrollZoomTimeout}
        showContextMenu={showContextMenu}
        restoreChartRange={restoreChartRange}
        setZoom={setZoom}
        setParentEChartsInstance={setEchartsInstance}
      />
    </ReactEChartsChartWrapper>
  );
};

export default Chart;
