import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Check, Close } from 'ui/common/Icons/Standard';
import { Standard, Title } from 'ui/common/typography/Typography';

const TooltipHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const TooltipBody = styled.div<{ withDrop?: boolean }>(
  ({ theme, withDrop }) => ({
    background: 'white',
    padding: theme.spacing.large,
    width: '300px',
    filter: withDrop
      ? 'drop-shadow(0px 19px 30px rgba(0, 0, 0, 0.09)) drop-shadow(0px 7.09px 14px rgba(0, 0, 0, 0.0425)) drop-shadow(0px 3.33px 3.9px rgba(0, 0, 0, 0.0225))'
      : '',
  }),
);

const TooltipTitle = styled(Title)(({ theme }) => ({
  marginBottom: theme.spacing.large,
}));

const FooterButtons = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const FooterStepTracker = styled.div({
  position: 'relative',
  height: '24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const RightButton = styled(Button)({
  position: 'absolute',
  right: 0,
});

const LeftButton = styled(Button)({
  position: 'absolute',
  left: 0,
});

const StepIndex = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const TooltipFooter = styled.div<{ separate: boolean; onRight?: boolean }>(
  ({ theme, separate = true, onRight }) => ({
    display: 'flex',
    justifyContent: onRight ? 'flex-end' : '',
    paddingTop: theme.spacing.large,
    marginTop: theme.spacing.large,
    borderTop: separate ? `1px solid ${theme.colors.grey[10]}` : '',
    color: theme.colors.text.tertiary,
  }),
);

export const PseudoOnboardingTooltip = ({
  title,
  onClose,
  children,
}: React.PropsWithChildren<{
  title: string;
  onClose: () => void;
}>) => (
  <TooltipBody withDrop>
    {title && (
      <TooltipHeader>
        <TooltipTitle>{title}</TooltipTitle>
        <Button
          onClick={onClose}
          variant={ButtonVariants.LargeTertiary}
          Icon={Close}
        />
      </TooltipHeader>
    )}
    <Standard>{children}</Standard>
    <TooltipFooter separate={false} onRight>
      <Button
        onClick={onClose}
        variant={ButtonVariants.SmallPrimary}
        Icon={Check}>
        {t({ id: 'pseudoOnboarding.gotit', message: 'Got it' })}
      </Button>
    </TooltipFooter>
  </TooltipBody>
);

const OnboardingTooltip: React.FC<TooltipRenderProps> = ({
  step,
  primaryProps,
  skipProps,
  tooltipProps,
  closeProps,
  index,
  size,
}) => {
  const renderFooter = () => {
    if (index === 0) {
      return (
        <FooterButtons>
          <Button variant={ButtonVariants.SmallSecondary} {...skipProps}>
            {t({ id: 'tour.onboarding.closeButton', message: 'No thanks' })}
          </Button>
          <Button variant={ButtonVariants.SmallPrimary} {...primaryProps}>
            {t({ id: 'tour.onboarding.nextButton', message: 'Get started' })}
          </Button>
        </FooterButtons>
      );
    }
    return (
      <FooterStepTracker>
        <StepIndex>
          {t({
            id: 'tour.onboarding.stepCount',
            message: 'Step {index} of {total}',
            values: { index, total: size - 1 },
          })}
        </StepIndex>
        {index === 11 && (
          <RightButton
            {...primaryProps}
            variant={ButtonVariants.SmallSecondary}>
            {t({ id: 'tour.onboarding.next', message: 'Next' })}
          </RightButton>
        )}
        {index === size - 1 && (
          <RightButton {...closeProps} variant={ButtonVariants.SmallPrimary}>
            {t({ id: 'tour.onboarding.done', message: 'Done' })}
          </RightButton>
        )}
      </FooterStepTracker>
    );
  };
  return (
    <TooltipBody {...tooltipProps}>
      {step.title && (
        <TooltipHeader>
          <TooltipTitle>{step.title}</TooltipTitle>
          <Button
            variant={ButtonVariants.LargeTertiary}
            Icon={Close}
            {...skipProps}
          />
        </TooltipHeader>
      )}
      <Standard>{step.content}</Standard>
      <TooltipFooter separate>{renderFooter()}</TooltipFooter>
    </TooltipBody>
  );
};

export default OnboardingTooltip;
