import { useAppDispatch, useAppSelector } from 'app/hooks';
import { projectMetadataActions } from 'app/slices/projectMetadataSlice';
import React from 'react';
import { Cell } from 'react-table';
import { StarEmpty, StarFilled } from 'ui/common/Icons/Standard';
import { ProjectsListTableData as TableData } from 'ui/dashboard/dashboardTypes';
import { useEditProjectFavorites } from 'ui/userPreferences/useEditProjectFavorites';

interface Props {
  projectId: string;

  // Latest server value of "isFavorite" for this project.
  // This is only used when we don't have a client-side optimistic update
  // that would take precedence.
  isFavoriteOnServer: boolean | undefined;
}

export const FavoriteIcon: React.FC<Props> = ({
  projectId,
  isFavoriteOnServer,
}) => {
  const dispatch = useAppDispatch();

  const favoriteOptimisticUpdate = useAppSelector(
    (state) =>
      state.projectMetadata.projectIdToFavoriteOptimisticUpdate[projectId],
  );

  const isFavorite = favoriteOptimisticUpdate
    ? favoriteOptimisticUpdate.isFavorite
    : isFavoriteOnServer || false;

  const { setAsFavorite, removeFavorite } = useEditProjectFavorites();

  const toggleFavoriteOn = React.useCallback(() => {
    setAsFavorite(projectId);
  }, [setAsFavorite, projectId]);
  const toggleFavoriteOff = React.useCallback(() => {
    removeFavorite(projectId);
  }, [removeFavorite, projectId]);

  React.useEffect(() => {
    if (favoriteOptimisticUpdate) {
      if (favoriteOptimisticUpdate.isFavorite === isFavoriteOnServer) {
        dispatch(
          projectMetadataActions.clearFavoriteOptimisticUpdate({ projectId }),
        );
      }
    }
  }, [dispatch, projectId, favoriteOptimisticUpdate, isFavoriteOnServer]);

  return isFavorite ? (
    <StarFilled onClick={toggleFavoriteOff} />
  ) : (
    <StarEmpty onClick={toggleFavoriteOn} />
  );
};

export const FavoriteIconForTable = (cell: Cell<TableData>) => {
  const projectId = cell.row.original.uuid;
  return (
    <FavoriteIcon
      projectId={projectId}
      isFavoriteOnServer={cell.row.original.isFavorite}
    />
  );
};
