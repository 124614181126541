import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useGetFileReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { BlockInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { fmiModelDescription } from 'app/third_party_types/fmi-2.0-modelDescription';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { requiredRules } from 'ui/common/Input/inputValidation';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsLabel,
  DetailsRow,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import {
  DynamicVariableCommon,
  orderedExtraParameters,
  updateExtraParameter,
} from 'util/dynamicBlockUtils';
import { useAppParams } from 'util/useAppParams';
import { CommonBlockParametersDetails } from '../CommonBlockParameterDetails';

interface Props {
  parentPath: string[];
  node: BlockInstance;
  disabled: boolean;
}

/**
 * Quick implementation of a dynamic display for ports and params.
 * Derived from the FMU the user uploads, does not perform extra validation.
 * FIXME: Would be simpler if there was a dedicated FMU block file selection param.
 * That way you don't diff between renders, and simply trigger on user action.
 */
const FmuBlockParameterDetails: React.FC<Props> = ({
  parentPath,
  node,
  disabled,
}: Props) => {
  const dispatch = useAppDispatch();
  const { showError } = useNotifications();

  const fmuFileName = node.parameters.file_name?.value || ''; // unselected file has value empty string, but types don't know that.
  const [prevFmuFileName, setPrevFmuFileName] = useState(fmuFileName);
  // Only fetch the FMU on a selection change. Else, the block has already been updated.
  const fmuFileSelectionChanged = fmuFileName !== prevFmuFileName;

  const { projectId } = useAppParams();
  const { project } = useProject();

  const extraParameterNames = orderedExtraParameters(node);

  // Find file UUID here for now. Existing param dropdown doesn't save file UUID.
  const projectFiles =
    project?.files.filter(
      (file) => !file.error && !(file.status === 'processing_in_progress'),
    ) || [];
  const fileUuid = projectFiles.find(
    (projectFile) => projectFile.name === fmuFileName,
  )?.uuid;

  const { data: fileData, isFetching } = useGetFileReadByUuidQuery(
    {
      projectUuid: projectId || '',
      fileUuid: fileUuid || '',
    },
    {
      skip: !(!!projectId && !!fileUuid && fmuFileSelectionChanged),
    },
  );

  // Reconfigure the block on file_name param change.
  // Note: does not work if the file contents changed after selection.
  useEffect(() => {
    if (fmuFileSelectionChanged) {
      // With the most up to date file info
      if (fileData && !isFetching) {
        setPrevFmuFileName(fmuFileName);

        const fmiDesc = fileData.properties as fmiModelDescription;
        const modelVariables = fmiDesc?.ModelVariables;
        if (!modelVariables) {
          // Can't prevent selection since it's not controlled here. See FIXME for component.
          // Also, should not happen since the file is processed on upload.
          showError(
            t({
              id: 'fmuBlockDetails.invalidFmuFileMessage',
              message: `Model variables missing from selected file ${fileData.summary.uuid}`,
            }),
          );
          return;
        }

        const dynamicVariables: DynamicVariableCommon[] = modelVariables.map(
          (fmiVariable) => {
            const id = fmiVariable.cml_name;
            const causality = fmiVariable.causality;
            const start =
              fmiVariable.Boolean?.start ??
              fmiVariable.Integer?.start ??
              fmiVariable.Real?.start ??
              fmiVariable.String?.start ??
              // TODO: add support for enumeration parameters
              // I didn't because I didn't find a reference example with enum
              // and the spec is ... hard to read.
              // fmiVariable.Enumeration?.start ??
              undefined;

            return {
              id,
              causality,
              defaultValue: start !== undefined ? `${start}` : undefined,
            };
          },
        );

        batch(() => {
          dispatch(modelActions.resetDynamicBlock({ parentPath, node }));
          dispatch(
            modelActions.configureDynamicBlock({
              parentPath,
              node,
              dynamicVariables,
            }),
          );
        });
      }
    }
  }, [
    dispatch,
    extraParameterNames,
    fileData,
    fmuFileName,
    fmuFileSelectionChanged,
    isFetching,
    node,
    parentPath,
    showError,
  ]);

  return (
    <>
      <SectionHeading testId="fmu-parameter-definition-details">
        {t({
          id: 'blockDetails.fmuBlockParametersTitle',
          message: 'Parameters',
        })}
      </SectionHeading>
      <CommonBlockParametersDetails
        parentPath={parentPath}
        selectedNode={node}
        canEdit={!disabled}
      />
      <DetailInputRowsSection>
        {extraParameterNames.map((paramName) => {
          const param = node.parameters[paramName];
          const valueKey = `extra-param-value-${paramName}`;
          const paramValue = param?.value || '';
          return (
            <DetailsSection key={paramName} vertical>
              <DetailsLabel stretch title={paramName}>
                {paramName}
              </DetailsLabel>
              <DetailsRow>
                <DetailsInput
                  grow
                  testId={valueKey}
                  value={paramValue}
                  onSubmitValue={updateExtraParameter(
                    dispatch,
                    parentPath,
                    node,
                    paramName,
                  )}
                  disabled={disabled}
                  hasBorder
                  allowMultiline
                  validationRules={requiredRules}
                />
              </DetailsRow>
            </DetailsSection>
          );
        })}
      </DetailInputRowsSection>
    </>
  );
};

export default FmuBlockParameterDetails;
