import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React from 'react';
import ReactDOM from 'react-dom';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import TooltipPointer from './TooltipPointer';
import { getTooltipPosition } from './utils';

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff88;
`;

interface CommonTooltipProps {
  children: React.ReactElement;
  placement: TooltipPlacement;
  invertedColor?: boolean;
  showBorder?: boolean;
  overlay?: boolean;
  testId?: string;
}

interface Props extends CommonTooltipProps {
  triggerEl: HTMLElement;
}

interface ManualProps extends CommonTooltipProps {
  isHidden: boolean;
  delayMs?: number;
  top: number;
  left: number;
  contentElRef?: React.RefObject<HTMLDivElement>;
  triggerRect?: DOMRect | undefined;
  contentRect?: DOMRect | undefined;
  isShowingPointer: boolean;
  uninteractable?: boolean;
}

const delayedFadein = keyframes`
from {
  opacity: 0;
}
80%{
  opacity: 0;
}
to {
  opacity: 1;
}
`;

// Use z-index so that tooltips are shown above notifications.
const ButtonTooltipWrapper = styled.div<{
  isHidden: boolean;
  top: number;
  left: number;
  delayMs?: number;
}>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  padding-right: 2px;
  ${({ isHidden }) => (isHidden ? 'visibility: hidden;' : '')}
  z-index: 100;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  ${({ delayMs }) =>
    delayMs
      ? css`
          animation: ${delayedFadein} 500ms;
        `
      : ''})}
  max-height: 100%;
`;

const ButtonTooltipContent = styled.div<{ uninteractable?: boolean }>`
  background-color: ${(props) => props.theme.colors.grey[2]};
  border-radius: ${(props) => props.theme.spacing.small};
  box-shadow: ${(props) => props.theme.shadows.higher};
  overflow: auto;
  flex-grow: 0;
  flex-shrink: 1;
  ${({ uninteractable }) => (uninteractable ? '' : 'pointer-events: auto;')}
`;

const TooltipContentSpacer = styled.div`
  height: 64px;
  flex-grow: 1;
  flex-shrink: 0;
  pointer-events: none;
`;

// To prevent the tooltip from flashing, use useLayoutEffect to position the
// tooltip correctly before paint, or isHidden to gate display to after positioning.
// Consider using popper.js when we need more fancy features.
export const ManualTooltip: React.FC<ManualProps> = ({
  isHidden,
  top,
  left,
  contentElRef,
  children,
  isShowingPointer,
  invertedColor,
  showBorder,
  placement,
  triggerRect,
  contentRect,
  testId,
  uninteractable,
  delayMs,
}) => (
  <ButtonTooltipWrapper
    isHidden={isHidden}
    delayMs={delayMs}
    top={top}
    left={left}
    data-test-id={testId}>
    <ButtonTooltipContent uninteractable={uninteractable} ref={contentElRef}>
      {children}
    </ButtonTooltipContent>
    <TooltipContentSpacer />

    {isShowingPointer && triggerRect && contentRect && (
      <TooltipPointer
        showBorder={showBorder}
        invertedColor={invertedColor || false}
        placement={placement}
        triggerRect={triggerRect}
        contentRect={contentRect}
      />
    )}
  </ButtonTooltipWrapper>
);

const PortalTooltip: React.FC<Props> = ({
  children,
  triggerEl,
  placement,
  invertedColor = true,
  showBorder,
  overlay = false,
  testId,
}) => {
  const contentElRef = React.useRef<HTMLDivElement>(null);
  const [isHidden, setIsHidden] = React.useState<boolean>(true);

  const contentRect = contentElRef.current?.getBoundingClientRect();

  const triggerRect = triggerEl.getBoundingClientRect();

  const showPointer = true;
  const isButtonTooltip = true;
  const tooltipPos = getTooltipPosition(
    triggerRect,
    contentRect,
    placement,
    showPointer,
    isButtonTooltip,
  );

  // The tooltip is initially hidden so we can measure its size
  // before positioning it.
  React.useEffect(() => {
    if (contentElRef.current && isHidden) {
      setIsHidden(false);
    }
  }, [contentElRef, isHidden, setIsHidden]);

  const isShowingPointer = !!(!isHidden && triggerRect && contentRect);

  const top = tooltipPos.top + triggerRect.top;
  const left = tooltipPos.left + triggerRect.left;

  const tooltipContainer: HTMLElement | null =
    document.getElementById('tooltips');

  if (!tooltipContainer) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      {overlay && <Overlay />}
      <ManualTooltip
        showBorder={showBorder}
        isHidden={isHidden}
        top={top}
        left={left}
        contentElRef={contentElRef}
        testId={testId}
        invertedColor={invertedColor}
        placement={placement}
        triggerRect={triggerRect}
        contentRect={contentRect}
        isShowingPointer={isShowingPointer}>
        {children}
      </ManualTooltip>
    </>,
    tooltipContainer,
  );
};

export default PortalTooltip;
