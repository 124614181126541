import { HoverEntity } from 'app/common_types/MouseTypes';
import {
  FakeSegmentType,
  HoverEntityType,
} from 'app/common_types/SegmentTypes';
import { LinkSegmentType } from 'app/generated_types/SimulationModel';
import { LinkRenderData, VertexSegmentType } from 'app/utils/linkToRenderData';

export const reifyAndGetSegmentData = (
  hoveringLink: HoverEntity,
  linkRenderData: LinkRenderData,
):
  | {
      newSegments: LinkSegmentType[];
      newInteractedIndex: number;
      shouldPrepend: boolean;
    }
  | undefined => {
  if (hoveringLink.entityType !== HoverEntityType.FakeLinkSegment) {
    return;
  }
  const shouldPrepend = hoveringLink.fakeSegmentType === FakeSegmentType.Start;
  const limitToSameFakeType =
    hoveringLink.fakeSegmentType === FakeSegmentType.Start ||
    hoveringLink.fakeSegmentType === FakeSegmentType.End;
  let metSameType = false;

  let newInteractedIndex = 0;
  let newSegments: LinkSegmentType[] = [];
  let previousReificationDir: 'horiz' | 'vert' | undefined;

  for (let i = 0; i < linkRenderData.vertexData.length - 1; i++) {
    const curVertex = linkRenderData.vertexData[i];

    if (limitToSameFakeType && !metSameType) {
      metSameType =
        curVertex.segmentType === VertexSegmentType.Fake &&
        curVertex.fakeSegmentType === hoveringLink.fakeSegmentType;
    }

    if (
      limitToSameFakeType &&
      metSameType &&
      (curVertex.segmentType !== VertexSegmentType.Fake ||
        curVertex.fakeSegmentType !== hoveringLink.fakeSegmentType)
    ) {
      return {
        shouldPrepend,
        newSegments,
        newInteractedIndex,
      };
    }

    if (
      curVertex.segmentType !== VertexSegmentType.Fake ||
      (limitToSameFakeType && !metSameType)
    ) {
      if (
        curVertex.segmentType === VertexSegmentType.Irrelevant ||
        curVertex.segmentType === VertexSegmentType.Fake ||
        (curVertex.segmentType === VertexSegmentType.Real &&
          curVertex.vertexBent)
      ) {
        continue;
      } else {
        newInteractedIndex += 1;
        continue;
      }
    }

    let skippedReificationDataCount = 0;

    for (let j = 0; j < curVertex.reificationData.length; j++) {
      const reificationData = curVertex.reificationData[j];
      if (previousReificationDir === reificationData.segment_direction) {
        skippedReificationDataCount += 1;
        continue;
      } else {
        newSegments.push(reificationData);
      }

      previousReificationDir = reificationData.segment_direction;
    }

    if (i < hoveringLink.vertexDataIndex) {
      newInteractedIndex +=
        curVertex.reificationData.length - skippedReificationDataCount;
    }

    if (i === hoveringLink.vertexDataIndex) {
      newInteractedIndex +=
        curVertex.reifiedSegmentInteractedIndex - skippedReificationDataCount;
    }
  }

  return {
    shouldPrepend,
    newSegments,
    newInteractedIndex,
  };
};
