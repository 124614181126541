import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Coordinate } from 'app/common_types/Coordinate';
import { RCMenuContent } from 'ui/modelEditor/RightClickMenu';

interface RCMenuState {
  open: boolean;
  coord: Coordinate;
  content?: RCMenuContent;
}

const initialState: RCMenuState = {
  open: false,
  coord: { x: 0, y: 0 },
};

const rcMenuSlice = createSlice({
  name: 'rcMenu',
  initialState,
  reducers: {
    openWithContent(
      state,
      action: PayloadAction<{
        coord: Coordinate;
        content: RCMenuContent;
      }>,
    ) {
      const { coord, content } = action.payload;

      state.open = true;
      state.coord = coord;
      state.content = content;
    },

    close(state) {
      state.open = false;
      delete state.content;
    },
  },
});

export const rcMenuActions = rcMenuSlice.actions;

export default rcMenuSlice;
