import { t } from '@lingui/macro';
import { useCreateSubmodel } from 'app/api/useCreateSubmodel';
import {
  ModelDiagram,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { submodelsActions } from 'app/slices/submodelsSlice';
import {
  SubmodelConfiguration,
  SubmodelFull,
} from 'app/apiGenerated/generatedApiTypes';
import { createDefaultSubdiagram } from 'app/utils/subdiagramUtils';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from 'ui/common/notifications/useNotifications';

/**
 * A hook that coordinates the RTK Query data and the redux store state
 * when creating a reference submodel.
 */
export function useCreateReferenceSubmodel() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { createSubmodel } = useCreateSubmodel();

  const { createShowError } = useNotifications();

  const createReferenceSubmodel = React.useCallback(
    (
      projectId: string,
      referenceSubmodelName: string,
      diagram?: ModelDiagram,
      submodels?: SubmodelsSection,
      configuration?: SubmodelConfiguration,
    ) => {
      const submodelsToCreate = (submodels || {
        references: {},
        diagrams: {},
      }) as any;

      const diagramToCreate =
        diagram || createDefaultSubdiagram('core.ReferenceSubmodel');

      // If the new submodel is not tied to the current diagram,
      // we can directly navigate to it once it is created,
      // which is also likely what the user intended because
      // the submodel will be an empty starting point with no instances yet.
      const navigateToNewSubmodel = !diagram && !submodels;

      createSubmodel({
        projectUuid: projectId,
        submodelCreateRequest: {
          name: referenceSubmodelName,
          diagram: diagramToCreate,
          submodels: submodelsToCreate,
          configuration,
        },
      })
        .then((apiSubmodel: SubmodelFull) => {
          dispatch(submodelsActions.submodelPublishCompleted(apiSubmodel));
          dispatch(submodelsActions.requestSubmodels(projectId));

          if (navigateToNewSubmodel) {
            // We are creating the submodel from the dashboard,
            // so navigate to the submodel when it is created.
            navigate(`/projects/${projectId}/models/${apiSubmodel.uuid}`);
          }
        })
        .catch(
          createShowError(
            t({
              id: 'submodelApi.createSubmodelError',
              message: 'Unable to create submodel.',
            }),
          ),
        );

      if (!navigateToNewSubmodel) {
        dispatch(submodelsActions.submodelPublishStarted());
      }
    },
    [createSubmodel, dispatch, createShowError, navigate],
  );

  return {
    createReferenceSubmodel,
  };
}
