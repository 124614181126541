import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ModelSimulationState, projectActions } from 'app/slices/projectSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { Play } from 'ui/common/Icons/Small';
import { useAppParams } from 'util/useAppParams';

const RunSimulationButton: React.FC = () => {
  const dispatch = useAppDispatch();

  const { versionId } = useAppParams();

  const modelSimulationState = useAppSelector(
    (state) => state.project.modelSimulationState,
  );

  const running =
    modelSimulationState === ModelSimulationState.StartingRun ||
    modelSimulationState === ModelSimulationState.MonitoringRun;

  if (versionId) return null;

  return (
    <Button
      Icon={Play}
      disabled={running}
      onClick={() => dispatch(projectActions.requestRun({}))}>
      {running
        ? t({
            id: 'visualizer.simulationRunInProgressDisplayText',
            message: 'Running simulation',
          })
        : t({
            id: 'visualizer.runSimulationButton',
            message: 'Run simulation',
          })}
    </Button>
  );
};

export default RunSimulationButton;
