import styled from '@emotion/styled/macro';
import React from 'react';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Menu from 'ui/common/Menu/Menu';
import { MenuConfig } from 'ui/common/Menu/menuItemTypes';
import { ChevronDown } from '../Icons/Standard';
import Button, { ButtonProps } from './Button';

const SplitButtonContainer = styled.div`
  display: flex;
`;

const LeftSideButton = styled(Button)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const RightSideButton = styled(Button)<{ variant?: ButtonVariants }>`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  padding: 0;
  > * {
    ${({ theme, variant }) =>
      variant === ButtonVariants.SmallPrimary
        ? ''
        : `width: ${theme.spacing.xxlarge};`}
    padding: 0;
  }
`;

const RightSideSeparator = styled.div`
  position: absolute;
  width: 1px;
  left: 0;
  top: 0px;
  bottom: 0px;
  background-color: ${({ theme }) => theme.colors.brand.primary.lighter};
`;

interface Props {
  mainTestId: string;
  mainLabel: string;
  mainButtonProps: ButtonProps;
  menuProps: MenuConfig;
}

export const SplitButton: React.FC<Props> = ({
  mainTestId,
  mainLabel,
  mainButtonProps,
  menuProps,
}) => (
  <SplitButtonContainer>
    <LeftSideButton testId={mainTestId} {...mainButtonProps}>
      {mainLabel}
    </LeftSideButton>
    <Menu {...menuProps}>
      <RightSideButton variant={mainButtonProps.variant}>
        <ChevronDown fill="#ffffff" />
        <RightSideSeparator />
      </RightSideButton>
    </Menu>
  </SplitButtonContainer>
);
