import styled from '@emotion/styled';
import {
  useGetFileDownloadByUuidQuery,
  usePutFileContentUpdateByUuidMutation,
} from 'app/apiGenerated/generatedApi';
import { useAppDispatch } from 'app/hooks';
import { NavbarContext, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CodeEditor } from 'ui/codeEditor/CodeEditor';
import Breadcrumb from 'ui/common/Breadcrumb/Breadcrumb';
import { PythonFile } from 'ui/common/Icons/Standard';
import { Spinner } from 'ui/common/Spinner';
import { AppContentWithFooterWrapper } from 'ui/common/layout/appLayout';

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${({ theme }) => theme.colors.grey[10]};
  top: 0;
  left: 0;
`;

const PythonEditorPageContentWrap = styled.div`
  display: flex;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey['5']};
`;

const PythonEditorCodeWrap = styled.div`
  width: 900px;
`;

export const PythonEditorPage: React.FC = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(uiFlagsActions.setNavbarContext(NavbarContext.ScriptEditor));
    return () => {
      dispatch(uiFlagsActions.setNavbarContext(NavbarContext.None));
    };
  }, [dispatch]);

  const { projectId, fileUuid } = useParams();

  const [callFileContentUpdateApi] = usePutFileContentUpdateByUuidMutation();

  const [loaded, setLoaded] = React.useState<boolean>(false);

  const [initiallyLoadedScriptValue, setInitialScriptContents] = React.useState<
    string | undefined
  >();

  React.useEffect(() => {
    setLoaded(false);
  }, [projectId, fileUuid]);

  const downloadData = useGetFileDownloadByUuidQuery({
    projectUuid: projectId || '',
    fileUuid: fileUuid || '',
  });

  const fileName = React.useMemo(() => {
    if (downloadData.data?.download_link) {
      return (
        ((downloadData.data?.download_link.split('?') || '').shift() || '')
          .split('/')
          .pop() || ''
      );
    }

    return '';
  }, [downloadData.data?.download_link]);

  React.useEffect(() => {
    if (downloadData.data?.download_link) {
      fetch(downloadData.data?.download_link, {
        headers: new Headers({
          Origin: location.origin,
        }),
        mode: 'cors',
      })
        .then((response) => response.text())
        .then((textContents) => {
          setInitialScriptContents(textContents);
          setLoaded(true);
        });
    }
  }, [downloadData.data?.download_link]);

  const codeChangeTimerId = React.useRef<number | null>(null);
  const onChangeCode = async (newCode: string | undefined) => {
    if (projectId && fileUuid && newCode) {
      if (codeChangeTimerId.current) {
        window.clearTimeout(codeChangeTimerId.current);
      }

      codeChangeTimerId.current = window.setTimeout(async () => {
        const body = new Blob([newCode]);
        const request = {
          projectUuid: projectId,
          fileUuid,
          fileContentUpdateRequest: { size: body.size },
        };

        const uploadApiData = await callFileContentUpdateApi(request).unwrap();
        if (uploadApiData) {
          const method = 'PUT';
          const headers: { [key: string]: string } = {
            'Content-Type': uploadApiData.summary.content_type,
          };
          const url = uploadApiData.put_presigned_url;
          return fetch(url, { method, headers, body });
        }
      }, 100);
    }
  };

  return (
    <AppContentWithFooterWrapper>
      {!loaded ? (
        <Loader>
          <Spinner width="36" height="36" />
        </Loader>
      ) : (
        <PythonEditorPageContentWrap>
          <Breadcrumb>
            <>
              <PythonFile />
              {fileName}
            </>
          </Breadcrumb>
          <PythonEditorCodeWrap>
            <CodeEditor
              noTopMargin
              noBreadcrumb
              defaultValue={initiallyLoadedScriptValue}
              onChangeCode={onChangeCode}
              editorDisplayPath={fileName}
              language="python"
            />
          </PythonEditorCodeWrap>
        </PythonEditorPageContentWrap>
      )}
    </AppContentWithFooterWrapper>
  );
};
