import { BlockParameterDefinition } from 'app/generated_types/ComputationBlockClass';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelDataTypeValues } from 'app/modelDataTypeValues';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { CheckboxStringValue } from 'ui/common/CheckboxStringValue';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import { requiredRules } from 'ui/common/Input/inputValidation';
import SelectInput from 'ui/common/SelectInput';
import DataFileParameter from 'ui/common/parameters/DataFileParameter';
import DataIntegrationIdParameter from 'ui/common/parameters/DataIntegrationIdParameter';
import { ExperimentModelParameter } from 'ui/common/parameters/ExperimentModelParameter';
import { ExperimentModelVersionParameter } from 'ui/common/parameters/ExperimentModelVersionParameter';
import Switch from 'ui/common/switch/Switch';
import { DetailsInput } from 'ui/modelEditor/DetailsComponents';
import {
  BooleanDisplayType,
  getBooleanDisplayType,
  getParameterParamOptionDisplayName,
  isDataFileDropdown,
  isDataIntegrationIdDropdown,
} from 'ui/modelEditor/blockParameterDisplay';
import { snakeCaseStringToSentenceCase } from 'util/snakeCaseStringToSentenceCase';
import BusTypeSelect from './BlockParameterDetails/busBlock/BusTypeSelect';

interface Props {
  parentPath: string[];
  selectedNode: NodeInstance;
  paramDef: BlockParameterDefinition;
  isReadOnly: boolean;
}

const BlockParameterValue: React.FC<Props> = ({
  parentPath,
  selectedNode,
  paramDef,
  isReadOnly,
}: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // I removed the param metadata since no callsite was using it and it interfered with the prop signature.
  // -jj 06/08/2023
  const changeBlockParam = (paramName: string) => (value: string) => {
    const correctedValue =
      value.trim() === '' ? paramDef.empty_value || value : value;
    dispatch(
      modelActions.changeBlockParameter({
        parentPath,
        nodeUuid: selectedNode.uuid,
        paramName,
        value: correctedValue,
      }),
    );
  };

  const paramValue =
    selectedNode.parameters[paramDef.name]?.value ||
    paramDef.default_value ||
    '';

  if (
    selectedNode.type === 'core.ExperimentModel' &&
    paramDef.name === 'model'
  ) {
    return (
      <ExperimentModelParameter
        onSelectValue={changeBlockParam(paramDef.name)}
        currentValue={paramValue}
      />
    );
  }

  if (
    selectedNode.type === 'core.ExperimentModel' &&
    paramDef.name === 'model_version' &&
    selectedNode.parameters.model
  ) {
    return (
      <ExperimentModelVersionParameter
        selectedModelId={selectedNode.parameters.model.value}
        onSelectValue={changeBlockParam(paramDef.name)}
        currentValue={paramValue}
      />
    );
  }
  if (
    (selectedNode.type === 'core.BusCreator' ||
      selectedNode.type === 'core.BusSelector') &&
    paramDef.name === 'bus_type'
  ) {
    return (
      <BusTypeSelect
        key={selectedNode.uuid}
        onSelectBusType={changeBlockParam(paramDef.name)}
        currentBusTypeId={paramValue}
        parentPath={parentPath}
        node={selectedNode}
        nodeType={selectedNode.type}
      />
    );
  }

  if (isDataIntegrationIdDropdown(paramDef)) {
    return (
      <DataIntegrationIdParameter
        onSelectValue={changeBlockParam(paramDef.name)}
        currentValue={paramValue}
        allowEmpty
      />
    );
  }
  if (isDataFileDropdown(selectedNode, paramDef)) {
    return (
      <DataFileParameter
        datafileType={
          paramDef.name === 'python_file' ? 'PythonScript' : selectedNode.type
        }
        dataIntegrationId={
          selectedNode.parameters.data_integration_id?.value || ''
        }
        onSelectValue={changeBlockParam(paramDef.name)}
        currentValue={paramValue}
      />
    );
  }

  const booleanDisplayType = getBooleanDisplayType(paramDef);

  switch (booleanDisplayType) {
    case BooleanDisplayType.None:
      break;
    case BooleanDisplayType.Checkbox:
      return (
        <CheckboxStringValue
          isDisabled={isReadOnly}
          value={paramValue}
          onChange={changeBlockParam(paramDef.name)}
        />
      );
    case BooleanDisplayType.List:
      if (paramDef.allowed_values) {
        return (
          <SelectInput
            isDisabled={isReadOnly}
            onSelectValue={changeBlockParam(paramDef.name)}
            currentValue={paramValue}
            options={paramDef.allowed_values.map((allowedValue, index) => ({
              value: index === 0 ? 'true' : 'false',
              label: snakeCaseStringToSentenceCase(`${allowedValue}`),
            }))}
          />
        );
      }
      break;

    case BooleanDisplayType.Switch:
      if (paramDef.allowed_values) {
        return (
          <Switch
            onSwitched={(isSwitchedLeft: boolean) =>
              changeBlockParam(paramDef.name)(isSwitchedLeft ? 'true' : 'false')
            }
            switchedLeft={paramValue === paramDef.allowed_values[0]}
            leftText={paramDef.allowed_values[0]}
            rightText={paramDef.allowed_values[1]}
            testId={`${paramDef.name}-${selectedNode.uuid}-switch`}
          />
        );
      }
      break;
  }

  const allowedValues = paramDef.value_is_a_type
    ? modelDataTypeValues
    : paramDef.allowed_values;

  if (allowedValues) {
    return (
      <SelectInput
        isDisabled={isReadOnly}
        onSelectValue={changeBlockParam(paramDef.name)}
        currentValue={paramValue}
        options={allowedValues.map((allowedValue) => ({
          value: allowedValue,
          label: getParameterParamOptionDisplayName(
            selectedNode.type,
            paramDef.name,
            allowedValue,
          ),
        }))}
      />
    );
  }

  const isDimensionParameter =
    selectedNode.type === 'core.ScalarBroadcast' &&
    (paramDef.name === 'm' || paramDef.name === 'n');

  // Allow dimension parameter values to be empty.
  const textParamValue = isDimensionParameter
    ? selectedNode.parameters[paramDef.name]?.value || ''
    : paramValue;

  return (
    <DetailsInput
      onSubmitValue={changeBlockParam(paramDef.name)}
      value={textParamValue}
      align={TextInputAlign.Right}
      validationRules={isDimensionParameter ? [] : requiredRules}
      disabled={isReadOnly}
      allowMultiline
      multilineHeight={
        selectedNode.uiprops?.parameter_heights
          ? selectedNode.uiprops?.parameter_heights[paramDef.name]
          : undefined
      }
      isMonospaced
      grow
      hasBorder
    />
  );
};

export default BlockParameterValue;
