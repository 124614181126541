export async function retry<T>(
  fn: () => Promise<T>,
  retriesLeft = 3,
  interval = 1000,
  exponential = false,
): Promise<T> {
  try {
    const val = await fn();
    return val;
  } catch (error) {
    if (retriesLeft) {
      await new Promise((r) => {
        setTimeout(r, interval);
      });
      return retry(
        fn,
        retriesLeft - 1,
        exponential ? interval * 2 : interval,
        exponential,
      );
    }
    throw new Error(`Max retries reached for function "${fn.name}"`);
  }
}
