import { generatedApi } from 'app/apiGenerated/generatedApi';
import { ModelWithSubmodels } from 'app/apiGenerated/generatedApiTypes';
import { CallbackResult } from 'app/openai';
import React, { useState } from 'react';
import { PythonContext, usePython } from 'ui/common/PythonProvider';
import { useAppParams } from 'util/useAppParams';
import { EditorMode, useModelEditorInfo } from './useModelEditorInfo';
import { useSimCompleteListener } from './SimCompleteListenerProvider';
import { MODEL_BUILDER } from './usePythonToJsonConverter';

const RESULTS_TO_DF = `import pandas as pd
import io
df = pd.read_csv(io.StringIO(inputs.last_results))
df_columns = list(df.columns)`;

export interface PythonModel {
  pythonStr: string;
  ids_to_uuids: unknown;
  ids_to_uiprops: unknown;
}

export interface PythonExecuteResult {
  results?: Map<string, unknown>;
  stdout?: string;
  error?: string;
}

type PythonRenderConfig = {
  output_groups: boolean;
  output_submodels: boolean;
};

export const indent = (code: string, spaces: number) => {
  const lines = code.split('\n');
  const indentedLines = lines.map((line) => `${' '.repeat(spaces)}${line}`);
  return indentedLines.join('\n');
};

const getCodeLine = (code: string, lineno: number) => {
  const lines = code.split('\n');
  return lines[lineno - 1].trim();
};

/**
 * Run python code.
 *
 * Returned `results` contains the variable specified in `returnVariables`. They are serialized using pickle
 * if not one of the following types: string, int, float, bool.
 *
 * Make sure to call the `deserialize` function if you want to resuse the results in python from another script.
 *
 */
const runPythonCheckError = async (
  python: PythonContext,
  importStatements: string,
  code: string,
  locals: { [key: string]: unknown } | undefined,
  executionId: number,
  returnVariableNames?: string[],
): Promise<{
  results?: Map<string, unknown>;
  stdout?: string;
  error?: string;
}> => {
  if (!python.isReady) return { error: 'python is not ready' };

  const keepLocals = [
    '__error_tb',
    '__error_msg',
    '__error_lineno',
    '__has_error',
  ]
    .concat(returnVariableNames || [])
    .map((v) => `'${v}'`);
  const keepLocalsPyStr = `[${keepLocals.join(', ')}]`;

  const wrappedCode = `
import base64
import pickle
import sys
import traceback
${importStatements}

def serialize(data):
    return base64.b64encode(pickle.dumps(data)).decode('utf-8')

def deserialize(data):
    return pickle.loads(base64.b64decode(data))

def __pycollimator_exec_${executionId}():
    try:
        inputs = __pycollimator_inputs_${executionId}
${indent(code, 8)}
    except Exception as e:
        exc_type, exc_value, exc_traceback = sys.exc_info()
        trace = traceback.extract_tb(exc_traceback)
        lineno = None
        pycollimator_tb = []
        for tb in trace:
            if tb.filename == '<exec>':
                lineno = tb.lineno
            if 'pycollimator' in tb.filename:
                pycollimator_tb.append(tb)
        if not pycollimator_tb:
            pycollimator_tb = trace
        pycollimator_tb = traceback.format_list(pycollimator_tb)
        __error_tb = ''.join(pycollimator_tb)
        __error_msg = f'{type(e).__name__}: {e}'
        if lineno:
            __error_lineno = lineno
        __has_error = True
    keep_vars = ${keepLocalsPyStr}
    return_vars = {k: v for k, v in locals().items() if k in keep_vars}
    return_vars = {
        k: v if type(v) in [str, int, float, bool] else serialize(v)
        for k, v in return_vars.items()
    }
    return return_vars
__pycollimator_exec_${executionId}()
`;

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.debug(`runPythonCheckError code:\n${wrappedCode}`);
  }

  let results: Map<string, unknown>;
  let stdout = '';

  if (locals) {
    await python.pyodide?.globalsSet(
      `__pycollimator_inputs_${executionId}`,
      locals,
    );
  }

  try {
    python.readStdout();
    results = (await python.pyodide?.runPythonAsync(
      wrappedCode,
      ['__has_error', '__error_tb', '__error_msg', '__error_lineno'].concat(
        returnVariableNames || [],
      ),
    )) as Map<string, unknown>;
    stdout = python.readStdout();
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.debug(`runPythonCheckError error:\n${stdout}\n${e}`);
    }
    return { stdout, error: `${e}` };
  }

  if (results.has('__has_error')) {
    let codeLine;
    if (results.has('__error_lineno')) {
      codeLine = getCodeLine(
        wrappedCode,
        results.get('__error_lineno') as number,
      );
      codeLine = `At line: ${codeLine}`;
    }
    const tb = results.get('__error_tb') as string;
    const errorMsg = results.get('__error_msg') as string;
    return {
      stdout,
      error: `Traceback:\n${tb}\n${errorMsg}\n${codeLine || ''}`,
    };
  }

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.debug('Output from Python:', results, 'stdout:\n', stdout);
  }

  if (returnVariableNames) {
    const filteredResults = new Map<string, unknown>();
    let error = '';
    for (const returnVariableName of returnVariableNames) {
      if (!results.has(returnVariableName)) {
        error = `${error}Error: You must set "${returnVariableName}"\n`;
        continue;
      }
      const result = results.get(returnVariableName);
      filteredResults.set(returnVariableName, result);
    }
    return { results: filteredResults, stdout };
  }

  return { stdout };
};

export interface ExecutePythonArgs {
  code: string;
  inputs?: { [key: string]: unknown };
  returnVariableNames?: string[];
  importStatements?: string;
}

export const usePythonExecutor = () => {
  const python = usePython();
  const [executionId, setExecutionId] = useState<number>(0);
  const executePythonCallback = React.useCallback(
    async ({
      code,
      inputs,
      returnVariableNames,
      importStatements,
    }: ExecutePythonArgs): Promise<PythonExecuteResult> => {
      if (!python.isReady) return { error: 'Python is not ready' };

      setExecutionId((id) => id + 1);

      const { results, stdout, error } = await runPythonCheckError(
        python,
        importStatements || '',
        code,
        inputs,
        executionId,
        returnVariableNames,
      );
      if (error) {
        return { stdout, error };
      }
      if (returnVariableNames && results) {
        let error = '';
        const runResults = results as Map<string, unknown>;
        const filteredResults = new Map<string, unknown>();

        for (const returnVariableName of returnVariableNames) {
          if (!runResults.has(returnVariableName)) {
            error = `${error}Error: You must set "${returnVariableName}"\n`;
            continue;
          }
          const result = runResults.get(returnVariableName);
          filteredResults.set(returnVariableName, result);
        }
        return { results: filteredResults, stdout, error };
      }
      return { stdout };
    },
    [executionId, python],
  );

  return executePythonCallback;
};

export const usePythonModelCallback = () => {
  const { projectId } = useAppParams();
  const { editorMode, modelInEditorUuid, groupBlockUuid } =
    useModelEditorInfo();
  const [getModelWithSubmodelsReadByUuidTrigger] =
    generatedApi.endpoints.getModelWithSubmodelsReadByUuid.useLazyQuery();
  const [getSubmodelWithSubmodelsTrigger] =
    generatedApi.endpoints.getSubmodelWithSubmodels.useLazyQuery();
  const executePython = usePythonExecutor();
  const importStatements = `
import json
from pycollimator.model_builder import from_json
from pycollimator.model_builder.to_python import to_python_str
`;

  const convertModelPyCode = `
json_data = json.loads(inputs.json_model)
root_model_builder, ids_to_uuids, ids_to_uiprops = from_json.parse_json(json_data)
model_builder = root_model_builder
if inputs.group_uuid:
    for group_block, group_model_builder in root_model_builder.groups.items():
        if ids_to_uuids[group_block.id] == inputs.group_uuid:
            model_builder = group_model_builder
            break
python_str = to_python_str(
    model_builder,
    builder_name='model_builder',
    output_submodels=inputs.output_submodels,
    output_groups=inputs.output_groups,
    omit_model_builder=True,
)
`;

  const convertModelToPython = React.useCallback(
    async (
      model: ModelWithSubmodels,
      pyRenderConfig: PythonRenderConfig,
    ): Promise<PythonModel> => {
      const { results, error } = await executePython({
        importStatements,
        code: convertModelPyCode,
        inputs: {
          json_model: JSON.stringify(model),
          group_uuid: groupBlockUuid,
          ...pyRenderConfig,
        },
        returnVariableNames: ['ids_to_uuids', 'ids_to_uiprops', 'python_str'],
      });

      if (error) {
        throw Error(error);
      }

      const resultsMap = results as Map<string, unknown>;
      return {
        pythonStr: resultsMap.get('python_str') as string,
        ids_to_uuids: resultsMap.get('ids_to_uuids'),
        ids_to_uiprops: resultsMap.get('ids_to_uiprops'),
      };
    },
    [executePython, importStatements, convertModelPyCode, groupBlockUuid],
  );

  const getCurrentModelInPython = React.useCallback(
    async (pyRenderConfig: PythonRenderConfig): Promise<PythonModel> => {
      let simulationModel;
      try {
        if (editorMode === EditorMode.Submodel) {
          if (!projectId) throw Error('projectId is not defined.');
          simulationModel = await getSubmodelWithSubmodelsTrigger({
            submodelUuid: modelInEditorUuid,
            projectUuid: projectId,
          }).unwrap();
        } else {
          simulationModel = await getModelWithSubmodelsReadByUuidTrigger({
            modelUuid: modelInEditorUuid,
          }).unwrap();
        }
      } catch (e) {
        throw Error('Failed to load the model.');
      }

      const results = await convertModelToPython(
        simulationModel,
        pyRenderConfig,
      );

      return results;
    },
    [
      convertModelToPython,
      editorMode,
      projectId,
      getSubmodelWithSubmodelsTrigger,
      modelInEditorUuid,
      getModelWithSubmodelsReadByUuidTrigger,
    ],
  );

  const validateCurrentPythonModel =
    React.useCallback(async (): Promise<string> => {
      let pythonModel;
      try {
        pythonModel = await getCurrentModelInPython({
          output_groups: true,
          output_submodels: true,
        });
      } catch (e: unknown) {
        return (e as Error).message;
      }
      const importStatements = `
from pycollimator.model_builder import core
from pycollimator.model_builder.model import ModelBuilder
`;
      const { error } = await executePython({
        importStatements,
        code: `${MODEL_BUILDER}\n${pythonModel.pythonStr}`,
      });
      return error || '';
    }, [executePython, getCurrentModelInPython]);

  return {
    getCurrentModelInPython,
    validateCurrentPythonModel,
  };
};

interface executePythonWithSimResultsArgs {
  code: string;
  onComplete: (r: CallbackResult) => void;
  onGetPythonResults: (results: string) => void;
  importStatements?: string;
  finalizeStatements?: string;
  allowDirty?: boolean;
}

export const useExecutePythonWithSimResults = () => {
  const { lastResults, lastResultsIsDirty } = useSimCompleteListener();
  const executePython = usePythonExecutor();

  const executePythonWithSimResults = React.useCallback(
    async ({
      code,
      onComplete,
      onGetPythonResults,
      importStatements,
      finalizeStatements,
      allowDirty,
    }: executePythonWithSimResultsArgs) => {
      if (!lastResults?.current) {
        onComplete({
          error: 'No results from simulation. Please run a simulation first.',
        });
        return;
      }
      if (lastResultsIsDirty?.current && !allowDirty) {
        onComplete({
          error: 'Model has been updated. Please re-run a simulation.',
        });
        return;
      }
      const wrappedCode = `${RESULTS_TO_DF}\n${code}\n${
        finalizeStatements || ''
      }`;
      executePython({
        code: wrappedCode,
        inputs: { last_results: lastResults?.current },
        returnVariableNames: ['results'],
        importStatements,
      })
        .then(({ results, error }) => {
          if (error) {
            onComplete({ error });
            return;
          }
          const resultsMap = results as Map<string, unknown>;
          onGetPythonResults(`${resultsMap.get('results') as string}`);
        })
        .catch((e) => onComplete({ error: `${e}` }));
    },
    [executePython, lastResults, lastResultsIsDirty],
  );

  return executePythonWithSimResults;
};
