import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import { SmallEmphasis } from 'ui/common/typography/Typography';
import Chart from 'ui/dataExplorer/charts/Chart';
import { DataExplorerPlotCellError } from 'ui/dataExplorer/grid/DataExplorerPlotCellError';

const RemoveButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.normal};
`;

interface Props {
  cellId: string;
  canEditVisualization: boolean;
}

export const DataExplorerPlotCell: React.FC<Props> = ({
  cellId,
  canEditVisualization,
}) => {
  const dispatch = useAppDispatch();

  const plotCell = useAppSelector(
    (state) => state.dataExplorer.idToPlotCell[cellId],
  );

  const plotCellData = useAppSelector(
    (state) => state.dataExplorerPlotData.idToPlotCellData[cellId],
  );

  const plotCellDataErrors = useAppSelector(
    (state) => state.dataExplorerPlotData.idToPlotCellDataErrors[cellId],
  );

  const zoomData = React.useCallback(
    (from: number, to: number) => {
      // TODO add support for zoom state management. This loses the initial range.
      dispatch(
        dataExplorerActions.setPlotCellZoomBounds({
          cellId,
          bounds: {
            startX: from,
            endX: to,
          },
        }),
      );
    },
    [dispatch, cellId],
  );

  if (!plotCell) {
    return null;
  }

  const isLoadingBounds = !plotCell.initialBounds;
  const isLoadingData = !plotCellData && !plotCellDataErrors;

  if (isLoadingBounds || isLoadingData) {
    return (
      <SpinnerWrapper>
        <Spinner />
        {isLoadingData ? (
          <SmallEmphasis>
            {t({
              id: 'dataExplorer.plotCell.loadingDataText',
              message: 'Loading data',
            })}
          </SmallEmphasis>
        ) : isLoadingBounds ? (
          <SmallEmphasis>
            {t({
              id: 'dataExplorer.plotCell.loadingBoundsText',
              message: 'Loading data bounds',
            })}
          </SmallEmphasis>
        ) : null}

        <RemoveButton
          Icon={Remove}
          variant={ButtonVariants.SmallSecondary}
          onClick={() =>
            dispatch(
              dataExplorerActions.removeTraces({
                traceIds: plotCell.traceIds,
              }),
            )
          }>
          {t({
            id: 'dataExplorer.plotCell.removePlotButtonText',
            message: 'Remove plot',
          })}
        </RemoveButton>
      </SpinnerWrapper>
    );
  }

  return (
    <>
      {plotCellDataErrors &&
        plotCellDataErrors.map((plotCellDataError) => (
          <DataExplorerPlotCellError
            key={plotCellDataError.traceId}
            traceId={plotCellDataError.traceId}
            tracePath={plotCellDataError.tracePath}
            errorMessage={plotCellDataError.errorMessage}
          />
        ))}
      {plotCellData.rows?.length > 0 && plotCell.initialBounds && (
        <Chart
          canEdit={canEditVisualization}
          traceIds={plotCell.traceIds}
          data={plotCellData.rows}
          initialBounds={plotCell.initialBounds}
          zoomBounds={plotCell.zoomBounds}
          zoomData={zoomData}
        />
      )}
    </>
  );
};
