import { transformBackendSimulationModel } from '@collimator/model-schemas-ts';
import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useCreateSubmodel } from 'app/api/useCreateSubmodel';
import { useDeleteSubmodel } from 'app/api/useDeleteSubmodel';
import { useUpdateSubmodel } from 'app/api/useUpdateSubmodel';
import { ModelLevelParameters } from 'app/apiData';
import { SubmodelFull } from 'app/apiGenerated/generatedApiTypes';
import {
  ModelDiagram,
  NodeInstance,
  ReferenceSubmodel,
  ReferenceSubmodels,
  SubmodelInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { nodeTypeIsLocalSubdiagram } from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { submodelsActions } from 'app/slices/submodelsSlice';
import React from 'react';
import { ActionCreators as UndoRedoActionCreators } from 'redux-undo';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { useAppParams } from 'util/useAppParams';

const DevButton = styled(Button)`
  padding: 0 ${({ theme }) => theme.spacing.normal};
`;

const ImportModelFromClipboardButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showError, createShowError } = useNotifications();
  const { projectId } = useAppParams();
  const { createSubmodel } = useCreateSubmodel();
  const { updateSubmodel } = useUpdateSubmodel();
  const currentSubmodels = useAppSelector(
    (state) => state.submodels.projectIdToSubmodelInfoLites[projectId || ''],
  );
  const { deleteSubmodel } = useDeleteSubmodel();

  const replaceReferenceSubmodels = React.useCallback(
    (
      diagram: ModelDiagram | undefined,
      groups: SubmodelsSection,
      referenceSubmodels: ReferenceSubmodels,
      nameUuidsMap: Map<string, { uuid: string; editId: string }>,
    ) => {
      if (!diagram) return;
      diagram.nodes.forEach((node: NodeInstance) => {
        if (nodeTypeIsLocalSubdiagram(node.type)) {
          const diagram_uuid = groups.references[node.uuid]?.diagram_uuid;
          if (!diagram_uuid) return;
          replaceReferenceSubmodels(
            groups.diagrams[diagram_uuid],
            groups,
            referenceSubmodels,
            nameUuidsMap,
          );
        } else if (node.type === 'core.ReferenceSubmodel') {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          replaceSubmodelsInSubmodels(
            node as SubmodelInstance,
            referenceSubmodels,
            nameUuidsMap,
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const replaceSubmodelsInSubmodels = React.useCallback(
    (
      referenceSubmodelInstance: SubmodelInstance,
      referenceSubmodels: ReferenceSubmodels,
      nameUuidsMap: Map<string, { uuid: string; editId: string }>,
    ) => {
      if (referenceSubmodelInstance.submodel_reference_uuid === undefined)
        return;

      const referenceSubmodel =
        referenceSubmodels[referenceSubmodelInstance.submodel_reference_uuid];
      if (!referenceSubmodel) return;

      replaceReferenceSubmodels(
        referenceSubmodel.diagram,
        referenceSubmodel.submodels,
        referenceSubmodels,
        nameUuidsMap,
      );

      const referenceSubmodelUuid = nameUuidsMap.get(
        referenceSubmodelInstance.submodel_reference_uuid,
      );

      if (referenceSubmodelUuid) {
        if (!projectId) return;

        updateSubmodel({
          projectUuid: projectId,
          submodelUuid: referenceSubmodelUuid.uuid,
          submodelUpdateRequest: {
            diagram: referenceSubmodel.diagram,
            edit_id: referenceSubmodelUuid.editId,
          },
        });
        referenceSubmodelInstance.submodel_reference_uuid =
          referenceSubmodelUuid.uuid;
      }
    },
    [replaceReferenceSubmodels, updateSubmodel, projectId],
  );

  const createReferenceSubmodel = (
    projectId: string,
    referenceSubmodelUuid: string,
    referenceSubmodel: ReferenceSubmodel,
  ) =>
    createSubmodel({
      projectUuid: projectId,
      submodelCreateRequest: {
        name: referenceSubmodel.name,
        diagram: referenceSubmodel.diagram,
        parameter_definitions: referenceSubmodel.parameters,
        submodels: referenceSubmodel.submodels,
      },
    })
      .then((apiSubmodel: SubmodelFull) => {
        dispatch(submodelsActions.submodelPublishCompleted(apiSubmodel));
        dispatch(submodelsActions.requestSubmodels(projectId));
        return [referenceSubmodelUuid, apiSubmodel.uuid, apiSubmodel.edit_id];
      })
      .catch(() => {
        createShowError(
          t({
            id: 'submodelApi.createSubmodelError',
            message: 'Unable to create submodel.',
          }),
        );
        return ['', ''];
      });

  const makeNameUuidMap = async (promises: Promise<string[]>[]) => {
    let submodelNameUuidPairs = await Promise.all(promises);
    const nameUuidMap = new Map<string, { uuid: string; editId: string }>();
    submodelNameUuidPairs.forEach(([oldUuid, newUuid, editId]) => {
      nameUuidMap.set(oldUuid, { uuid: newUuid, editId });
    });
    return nameUuidMap;
  };

  const deleteExistingSubmodelsWithSameName = async (
    projectId: string,
    submodels: ReferenceSubmodels,
  ) => {
    const currentSubmodelsMap = new Map<string, string>(); // name to uuid
    currentSubmodels.forEach((submodel) => {
      if (submodel.name) {
        currentSubmodelsMap.set(submodel.name, submodel.id);
      }
    });

    const promises = Object.entries(submodels).map(([_, submodel]) => {
      if (!submodel?.name) return;
      const uuid = currentSubmodelsMap.get(submodel.name);
      if (!uuid) return;
      return deleteSubmodel({
        projectUuid: projectId,
        submodelUuid: uuid,
        force: true,
      });
    });
    await Promise.all(promises);
  };

  const createReferenceSubmodels = async (
    projectId: string,
    submodels: ReferenceSubmodels,
  ) => {
    const promises: Promise<string[]>[] = Object.entries(submodels).map(
      ([uuid, submodel]) =>
        createReferenceSubmodel(projectId, uuid, submodel as ReferenceSubmodel),
    );
    return makeNameUuidMap(promises);
  };

  // Handle exported data from either backend (GET /model), frontend (react state) or even preprocessed
  const importModelData = async (modelData: any) => {
    if (modelData.unprocessed) {
      modelData = modelData.unprocessed;
    }

    const rootModel =
      modelData.diagram || modelData.rootModel || modelData.model.diagram;
    if (!rootModel) return;

    // handles clipboard import case
    const stateMachines = modelData.state_machines || modelData.stateMachines;

    const sm = transformBackendSimulationModel({
      uuid: rootModel.uuid,
      name: rootModel.name,
      diagram: rootModel,
      submodels: modelData.submodels || { diagrams: {}, references: {} },
      state_machines: stateMachines,
    });

    let parameters = {};
    if (modelData.parameters) {
      if (Array.isArray(modelData.parameters)) {
        parameters = modelData.parameters.reduce(
          (acc: ModelLevelParameters, curr: any) =>
            curr.name && curr.value
              ? {
                  ...acc,
                  [curr.name]: { value: curr.value },
                }
              : acc,
          {},
        );
      } else {
        parameters = modelData.parameters;
      }
    }

    if (projectId && modelData.reference_submodels) {
      await deleteExistingSubmodelsWithSameName(
        projectId,
        modelData.reference_submodels,
      );
      const nameUuidsMap = await createReferenceSubmodels(
        projectId,
        modelData.reference_submodels,
      );
      replaceReferenceSubmodels(
        sm.diagram,
        sm.submodels,
        modelData.reference_submodels,
        nameUuidsMap,
      );
    }

    dispatch(
      modelActions.loadModelContent({
        diagram: sm.diagram,
        submodels: sm.submodels,
        parameters,
        configuration: modelData.configuration, // NOTE: do we not sanitize the config? - jackson
        state_machines: sm.state_machines,
      }),
    );

    // clear out redux undo/redo history when a model is loaded
    // (shouldn't be possible to return to a state before the model is loaded)
    dispatch(UndoRedoActionCreators.clearHistory());
  };

  const importFromClipboard = async () => {
    try {
      const isFirefox =
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      if (isFirefox) {
        const modelText = prompt('Paste model JSON here');
        if (modelText) {
          try {
            const modelData = JSON.parse(modelText);
            await importModelData(modelData);
          } catch (e) {
            showError(
              'Could not parse model from clipboard - clipboard model data may be invalid.',
              e,
            );
          }
        }
      } else {
        navigator.clipboard.readText().then((modelText) => {
          if (modelText) {
            try {
              const modelData = JSON.parse(modelText);
              return importModelData(modelData);
            } catch (e) {
              showError(
                'Could not parse model from clipboard - clipboard model data may be invalid.',
                e,
              );
            }
          }
        });
      }
    } catch (e) {
      showError('Could not begin import model from clipboard process.', e);
    }
  };

  return (
    <DevButton
      testId="import-model-from-clipboard-button"
      variant={ButtonVariants.SmallTertiary}
      onClick={importFromClipboard}>
      Import model from clipboard
    </DevButton>
  );
};

export default ImportModelFromClipboardButton;
