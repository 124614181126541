/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Conditional: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.00001 7.16665C5.00001 6.98256 5.14925 6.83332 5.33334 6.83332H7.33334C7.51744 6.83332 7.66667 6.98256 7.66667 7.16665V8.83332C7.66667 9.01742 7.51744 9.16665 7.33334 9.16665H5.33334C5.14925 9.16665 5.00001 9.01742 5.00001 8.83332V7.16665Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.33334 7.16665C8.33334 6.98256 8.48258 6.83332 8.66667 6.83332H10.6667C10.8508 6.83332 11 6.98256 11 7.16665V8.83332C11 9.01742 10.8508 9.16665 10.6667 9.16665H8.66667C8.48258 9.16665 8.33334 9.01742 8.33334 8.83332V7.16665ZM9.00001 8.49999V7.49999H10.3333V8.49999H9.00001Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M2.66667 3.33332C2.66667 2.96513 2.96515 2.66666 3.33334 2.66666H12.6667C13.0349 2.66666 13.3333 2.96513 13.3333 3.33332V12.6667C13.3333 13.0348 13.0349 13.3333 12.6667 13.3333H3.33334C2.96515 13.3333 2.66667 13.0348 2.66667 12.6667V3.33332ZM3.66667 12.3333V3.66666H12.3333V12.3333H3.66667Z"
      fill={props.fill}
    />
  </svg>
);

Conditional.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Conditional;
