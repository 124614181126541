import { OutputLogLevel, ModelLogLine } from 'app/slices/simResultsSlice';
import { t } from '@lingui/macro';

export const getLogLevelDisplayName = (rawLevel?: string): string => {
  const level: OutputLogLevel =
    Object.values(OutputLogLevel).find((l) => l === rawLevel) ||
    OutputLogLevel.UNKNOWN;

  switch (level) {
    case OutputLogLevel.DBG:
      return t({
        id: 'modelEditor.consoleLogLine.logLevel.debug',
        message: 'DEBUG',
      });
    case OutputLogLevel.INF:
      return t({
        id: 'modelEditor.consoleLogLine.logLevel.info',
        message: 'INFO',
      });
    case OutputLogLevel.WRN:
      return t({
        id: 'modelEditor.consoleLogLine.logLevel.warn',
        message: 'WARNING',
      });
    case OutputLogLevel.ERR:
      return t({
        id: 'modelEditor.consoleLogLine.logLevel.error',
        message: 'ERROR',
      });
    case OutputLogLevel.FATAL:
      return t({
        id: 'modelEditor.consoleLogLine.logLevel.fatal',
        message: 'FATAL ERROR',
      });
    case OutputLogLevel.UNKNOWN:
    default:
      return t({
        id: 'modelEditor.consoleLogLine.logLevel.unknown',
        message: '???',
      });
  }
};

export function parseServerOutputLog(rawLog: ModelLogLine): [string, string][] {
  return Object.keys(rawLog)
    .filter(
      (key) =>
        ['level', 'message', 'timestamp', 'atd_error'].indexOf(key) === -1,
    )
    .map((key) => [key, JSON.stringify(rawLog[key])]);
}
