import { t } from '@lingui/macro';
import { useModelSimulationRequests } from 'app/api/useModelSimulationRequests';
import { SimulationStatus } from 'app/apiGenerated/generatedApiTypes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { codeGenActions } from 'app/slices/codeGenSlice';
import { modelMetadataActions } from 'app/slices/modelMetadataSlice';
import {
  ModelSimulationRequest,
  ModelSimulationState,
  SimulationResultType,
} from 'app/slices/projectSlice';
import { simResultsActions } from 'app/slices/simResultsSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { getNode } from 'app/utils/modelDiagramUtils';
import React, { useEffect } from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';

export enum SimulationDisplayStatus {
  None,
  Success,
  Fail,
}

export function useModelSimulationControls() {
  const dispatch = useAppDispatch();

  const { showInfo, showCompletion, showError } = useNotifications();

  const { startSimulation, stopSimulation } = useModelSimulationRequests();

  const { simIgnoreCache } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const worker_type = useAppSelector(
    (state) => state.model.present.configuration.worker_type,
  );
  const { loadedModelId, editId } = useAppSelector(
    (state) => state.modelMetadata,
  );
  const modelSimulationState = useAppSelector(
    (state) => state.project.modelSimulationState,
  );
  const modelSimulationRequest = useAppSelector(
    (state) => state.project.modelSimulationRequest,
  );
  const correlationId = useAppSelector((state) => state.project.correlationId);
  const simulationId = useAppSelector(
    (state) => state.project.simulationSummary?.uuid,
  );
  const waitingForModelUpdate = useAppSelector(
    (state) => state.project.waitingForModelUpdate,
  );
  const modelUpdatingId = useAppSelector(
    (state) => state.project.modelUpdatingId,
  );
  const status: SimulationStatus | undefined = useAppSelector(
    (state) => state.project.simulationSummary?.status,
  );
  const simulationEnsembleRequest = useAppSelector(
    (state) => state.project.simulationEnsembleRequest,
  );

  const { simulationSummaryType } = useAppSelector((state) => state.project);
  const selectedBlock = useAppSelector((state) =>
    state.model.present.selectedBlockIds.length > 0
      ? getNode(state.model.present, state.model.present.selectedBlockIds[0])
      : null,
  );
  const selectionParentPath = useAppSelector(
    (state) => state.model.present.selectionParentPath,
  );

  const { showDatatypesInModel } = useAppSelector((state) => ({
    showDatatypesInModel: state.uiFlags.showDatatypesInModel,
  }));

  const updateModelOverlays = React.useCallback(
    (
      status: SimulationStatus | undefined,
      simulationResultType: SimulationResultType | undefined,
    ) => {
      // SimulationResultType is enum that starts at 0. Check for undefined instead.
      if (!status || simulationResultType === undefined) return;

      if (status === 'failed') {
        dispatch(uiFlagsActions.setUIFlag({ showSignalNamesInModel: false }));
      } else if (status === 'completed') {
        // TODO: remove types check when we can display both rates and dimensions at the same time
        switch (simulationResultType) {
          // types, time mode, and data
          case SimulationResultType.CompileAndRun:
            dispatch(
              uiFlagsActions.setUIFlag({ showSignalNamesInModel: true }),
            );
            if (!showDatatypesInModel) {
              dispatch(uiFlagsActions.setUIFlag({ showRatesInModel: true }));
            }
            break;
          // types, time mode, no data
          case SimulationResultType.CompileOnly:
            if (!showDatatypesInModel) {
              dispatch(uiFlagsActions.setUIFlag({ showRatesInModel: true }));
            }
            break;
          // types, no time mode, no data
          case SimulationResultType.GenerateCode:
            break;
          default:
        }
      }
    },

    [dispatch, showDatatypesInModel],
  );

  React.useEffect(() => {
    if (status === 'failed') {
      if (simulationSummaryType === SimulationResultType.CompileOnly) {
        showError(
          t({
            id: 'modelsApi.compilationFailedStatusText',
            message: 'Compilation failed - See output for details',
          }),
          { isExpectedError: true },
        );
      } else if (simulationSummaryType === SimulationResultType.GenerateCode) {
        showError(
          t({
            id: 'modelsApi.generateCodeFailedStatusText',
            message: 'Code generation failed - See output for details',
          }),
          { isExpectedError: true },
        );
      } else {
        showError(
          t({
            id: 'modelsApi.simulationFailedStatusText',
            message: 'Simulation failed - See output for details',
          }),
          { isExpectedError: true },
        );
      }
    } else if (status === 'completed') {
      if (simulationSummaryType === SimulationResultType.CompileOnly) {
        showCompletion(
          t({
            id: 'modelsApi.compilationSuccessful',
            message: 'Compilation successful',
          }),
        );
      } else if (simulationSummaryType === SimulationResultType.GenerateCode) {
        dispatch(codeGenActions.requestCodeGenerationDownload());
      } else {
        showCompletion(
          t({
            id: 'modelsApi.simulationComplete',
            message: 'Simulation complete',
          }),
        );
      }
    } else if (
      status === 'pending' &&
      simulationSummaryType === SimulationResultType.GenerateCode
    ) {
      showInfo(
        t({
          id: 'modelsApi.codeGenInProgress',
          message: 'Generating source code...',
        }),
      );
    }
  }, [
    dispatch,
    status,
    showInfo,
    showCompletion,
    showError,
    simulationSummaryType,
  ]);

  useEffect(() => {
    updateModelOverlays(status, simulationSummaryType);
  }, [simulationSummaryType, status, updateModelOverlays]);

  // Watch for check, run, and stop requests and wait for all updates to complete
  // before triggering the api call for the action.
  React.useEffect(() => {
    if (
      loadedModelId &&
      editId !== null &&
      !waitingForModelUpdate &&
      !modelUpdatingId
    ) {
      if (modelSimulationState === ModelSimulationState.Ready) {
        if (modelSimulationRequest === ModelSimulationRequest.Check) {
          dispatch(
            modelMetadataActions.logVersionEvent(
              `before send start compile model editId=${editId}`,
            ),
          );
          dispatch(simResultsActions.resetSimResultsState());
          startSimulation(loadedModelId, {
            version: Number(editId),
            compile_only: true,
            ignore_cache: simIgnoreCache,
            target: 'visualizer',
          });
        } else if (modelSimulationRequest === ModelSimulationRequest.Run) {
          dispatch(
            modelMetadataActions.logVersionEvent(
              `before send start run simulation editId=${editId}`,
            ),
          );
          dispatch(simResultsActions.resetSimResultsState());
          startSimulation(loadedModelId, {
            version: Number(editId),
            compile_only: false,
            ignore_cache: simIgnoreCache,
            worker_type,
            target: 'visualizer',
          });
        } else if (
          modelSimulationRequest === ModelSimulationRequest.RunEnsemble
        ) {
          if (simulationEnsembleRequest?.modelOverrides) {
            dispatch(simResultsActions.resetSimResultsState());
            startSimulation(loadedModelId, {
              version: Number(editId),
              compile_only: false,
              ignore_cache: simIgnoreCache,
              model_overrides: simulationEnsembleRequest?.modelOverrides,
              worker_type,
              target: 'ensemble',
            });
          } else {
            console.error('No model overrides found for ensemble simulation');
          }
        } else if (
          modelSimulationRequest === ModelSimulationRequest.GenerateCode &&
          selectedBlock
        ) {
          dispatch(
            modelMetadataActions.logVersionEvent(
              `before send start code generation editId=${editId}`,
            ),
          );

          dispatch(simResultsActions.resetSimResultsState());
          startSimulation(loadedModelId, {
            version: Number(editId),
            compile_only: true,
            ignore_cache: simIgnoreCache,
            codegen: {
              name: selectedBlock.name,
              submodel_uuid: selectedBlock.uuid,
              submodel_uuid_path: [...selectionParentPath, selectedBlock.uuid],
            },
          });
        } else if (
          modelSimulationRequest === ModelSimulationRequest.FMUExport &&
          selectedBlock
        ) {
          dispatch(simResultsActions.resetSimResultsState());
          startSimulation(loadedModelId, {
            version: Number(editId),
            compile_only: true,
            ignore_cache: simIgnoreCache,
            fmucosim: {
              name: selectedBlock.name,
              submodel_uuid: selectedBlock.uuid,
              submodel_uuid_path: [...selectionParentPath, selectedBlock.uuid],
            },
          });
        }
      } else if (modelSimulationState === ModelSimulationState.MonitoringRun) {
        if (
          modelSimulationRequest === ModelSimulationRequest.Stop &&
          simulationId &&
          correlationId
        ) {
          stopSimulation(loadedModelId, simulationId, correlationId);
        }
      }
    }
  }, [
    dispatch,
    modelSimulationRequest,
    modelSimulationState,
    waitingForModelUpdate,
    modelUpdatingId,
    startSimulation,
    stopSimulation,
    correlationId,
    simulationId,
    loadedModelId,
    editId,
    simIgnoreCache,
    selectedBlock,
    selectionParentPath,
    simulationEnsembleRequest,
    worker_type,
  ]);

  const shouldPollSimulationStatus =
    modelSimulationState === ModelSimulationState.MonitoringCheck ||
    modelSimulationState === ModelSimulationState.MonitoringCodeGeneration ||
    modelSimulationState === ModelSimulationState.MonitoringRun ||
    modelSimulationState === ModelSimulationState.StoppingRun;

  const isChecking =
    modelSimulationState === ModelSimulationState.StartingCheck ||
    modelSimulationState === ModelSimulationState.MonitoringCheck;

  const isRunning =
    modelSimulationState === ModelSimulationState.StartingRun ||
    modelSimulationState === ModelSimulationState.MonitoringRun;

  const isStopping = modelSimulationState === ModelSimulationState.StoppingRun;
  const isValidSimulation =
    simulationSummaryType === SimulationResultType.CompileAndRun;

  const enableCheckButton = !isRunning && !isStopping;
  const enableRunButton = !isChecking && !isStopping;
  const enableDownloadDataButton =
    isValidSimulation && !isRunning && !isChecking && !isStopping;

  // Stopping the simulation can either cancel a requested run,
  // (i.e. before the API call is made) or can stop a run via an API call.
  const enableStopButton =
    modelSimulationRequest === ModelSimulationRequest.Run ||
    modelSimulationRequest === ModelSimulationRequest.RunEnsemble ||
    modelSimulationState === ModelSimulationState.StartingRun ||
    modelSimulationState === ModelSimulationState.MonitoringRun;

  const canLoadSignalResults = !!(
    simulationId &&
    modelSimulationState === ModelSimulationState.Ready &&
    [
      ModelSimulationRequest.Run,
      ModelSimulationRequest.RunEnsemble,
      ModelSimulationRequest.Check,
      ModelSimulationRequest.None,
    ].includes(modelSimulationRequest)
  );

  return {
    shouldPollSimulationStatus,
    isChecking,
    isRunning,
    isStopping,
    enableCheckButton,
    enableRunButton,
    enableDownloadDataButton,
    enableStopButton,
    canLoadSignalResults,
  };
}
