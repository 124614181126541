import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { entityPreferencesActions } from 'app/slices/entityPreferencesSlice';
import {
  DiagramFooterTab,
  NavbarContext,
  NotebookFooterTab,
  uiFlagsActions,
} from 'app/slices/uiFlagsSlice';
import React, { useRef, useState } from 'react';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import { DraggableCore } from 'react-draggable';
import { Close } from 'ui/common/Icons/Standard';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import DiagramFooterButtons from './DiagramFooterButtons';
import DiagramFooterContent from './DiagramFooterContent';
import FooterButton from './FooterButton';
import NotebookFooterButtons from './NotebookFooterButtons';
import NotebookFooterContent from './NotebookFooterContent';

const DEFAULT_HEIGHT = 400;
const DEFAULT_MAX_HEIGHT = 640;
const MIN_HEIGHT = 120;
const RESIZER_HEIGHT = 12;

// yes, this is a new css class for each height change, but it's not noticeable
const FooterContainerWrapper = styled.div<{
  isFooterOpen: boolean;
  height: number;
  isResizing?: boolean;
  isOnNotebook: boolean;
}>`
  height: ${({ isFooterOpen, height, isOnNotebook, theme }) =>
    !isFooterOpen
      ? theme.sizes.footerBarHeight
      : isOnNotebook
      ? theme.sizes.notebookFooterHalfHeight
      : `clamp(${MIN_HEIGHT}px, ${height}px, 100%)`};
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  pointer-events: auto;
  transition: ${({ isResizing = false }) =>
    isResizing ? 'none' : 'height 0.15s ease-in 0s'};
  z-index: 2;
  pointer-events: none;
  isolation: isolate;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const ButtonGroupsContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 0 0 ${({ theme }) => theme.sizes.footerBarHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.ui.background};
  pointer-events: auto;
`;

const ButtonGroup = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterContentWrapper = styled.div<{
  isFooterOpen: boolean;
  isOnNotebook: boolean;
}>`
  ${({ isFooterOpen }) => (isFooterOpen ? `flex-grow: 1;` : '')}
  ${({ isOnNotebook }) => (isOnNotebook ? '' : `pointer-events: auto;`)}
  overflow: hidden;
  transition: height 0.15s;
`;

const Resizer = styled.div<{
  enabled: boolean;
}>`
  position: absolute;
  top: 0;
  width: 100%;
  height: ${({ enabled }) => (enabled ? `${RESIZER_HEIGHT}px` : '0')};
  cursor: ns-resize;
  z-index: 1;
  pointer-events: auto;
`;

interface Props {
  enabled: boolean;
  savedHeight?: number;
  parentRef: HTMLDivElement | undefined;
}

const FooterContainer: React.FC<Props> = ({
  enabled,
  savedHeight = DEFAULT_HEIGHT,
  parentRef,
}) => {
  const dispatch = useAppDispatch();
  const navbarContext = useAppSelector((state) => state.uiFlags.navbarContext);

  const [prevHeight, setPrevHeight] = useState(savedHeight);
  const [height, setHeight] = useState(savedHeight);
  const [maxHeight, setMaxHeight] = useState(
    parentRef?.clientHeight || DEFAULT_HEIGHT,
  );

  // updating the height values according to saved height without an unnecessary rerender
  if (prevHeight !== savedHeight) {
    setPrevHeight(savedHeight);
    setHeight(savedHeight);
  }

  const [isResizing, setIsResizing] = useState(false);

  // library hack to suppress warning: https://github.com/react-grid-layout/react-draggable/blob/v4.4.2/lib/DraggableCore.js#L159-L171
  const resizerRef = useRef<null | HTMLDivElement>(null);

  const isFooterOpen = useAppSelector((state) => {
    if (navbarContext === NavbarContext.ModelEditor) {
      return state.uiFlags.diagramFooterTab !== DiagramFooterTab.None;
    }
    if (navbarContext === NavbarContext.Notebook) {
      return state.uiFlags.notebookFooterTab !== NotebookFooterTab.None;
    }
    return false;
  });

  const startResize = () => {
    // need to clamp the height and reset maxHeight
    // whenever a resize starts because the parent may have been resized
    const newMaxHeight = parentRef?.clientHeight || DEFAULT_MAX_HEIGHT;
    setHeight(Math.min(Math.max(MIN_HEIGHT, height), newMaxHeight));
    setMaxHeight(newMaxHeight);
    setIsResizing(true);
  };

  const handleResize = (event: DraggableEvent, data: DraggableData) => {
    // don't clamp the height during a resize. css will handle it.
    setHeight(height - data.deltaY);
  };

  const stopResize = (event: DraggableEvent, data: DraggableData) => {
    setIsResizing(false);

    // after a resize, save clamped height.
    const newHeight = Math.min(
      Math.max(MIN_HEIGHT, height - data.deltaY),
      maxHeight,
    );

    dispatch(
      entityPreferencesActions.onUserChangedBottomPanelHeight({
        height: newHeight,
      }),
    );
  };

  return (
    <FooterContainerWrapper
      className="tour-onboarding-11"
      isFooterOpen={isFooterOpen}
      height={height}
      isResizing={isResizing}
      isOnNotebook={navbarContext === NavbarContext.Notebook}>
      <DraggableCore
        nodeRef={resizerRef}
        offsetParent={parentRef}
        onStart={startResize}
        onDrag={handleResize}
        onStop={stopResize}>
        <Resizer ref={resizerRef} enabled={isFooterOpen} />
      </DraggableCore>
      {enabled && (
        <FooterWrapper>
          <ButtonGroupsContainer>
            {/* Footer Left Buttons */}
            <ButtonGroup>
              {navbarContext === NavbarContext.ModelEditor && (
                <DiagramFooterButtons />
              )}
              {navbarContext === NavbarContext.Notebook && (
                <NotebookFooterButtons />
              )}
            </ButtonGroup>

            {/* Footer Right Buttons */}
            {isFooterOpen && (
              <ButtonGroup>
                {/* Footer Close Button */}
                <FooterButton
                  testId="close-footer-button"
                  tooltipText={t({
                    id: 'footer.modelRenderer.closeButton.tooltip.text',
                    message: 'Close results',
                  })}
                  tooltipPlacement={TooltipPlacement.BOTTOM_LEFT}
                  Icon={Close}
                  onClickHandler={() => dispatch(uiFlagsActions.closeFooter())}
                />
              </ButtonGroup>
            )}
          </ButtonGroupsContainer>

          {/* Footer Content */}

          <FooterContentWrapper
            isFooterOpen={isFooterOpen}
            isOnNotebook={navbarContext === NavbarContext.Notebook}>
            {navbarContext === NavbarContext.ModelEditor && (
              <DiagramFooterContent isFooterOpen={isFooterOpen} />
            )}
            {navbarContext === NavbarContext.Notebook && (
              <NotebookFooterContent isFooterOpen={isFooterOpen} />
            )}
          </FooterContentWrapper>
        </FooterWrapper>
      )}
    </FooterContainerWrapper>
  );
};

export default FooterContainer;
