import React from 'react';
import { ModalOptions, ModalContext } from 'ui/common/Modal/modalTypes';
import { modalContext } from './ModalProvider';

const useModalState = (): ModalContext => {
  const [allowOverflow, setAllowOverflow] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<React.ReactNode | string>('');
  const [title, setTitle] = React.useState<string | undefined>();

  const triggerModal = (
    c: React.ReactNode | string,
    t?: string,
    options?: ModalOptions,
  ) => {
    if (options?.preventOverflow) setAllowOverflow(false);
    else setAllowOverflow(true);

    if (showModal) {
      // Currently the lifecycle of modal dialogs is a bit broken because it is not possible for
      // any component to close the modal dialog it opened without potentially affecting
      // other modal dialogs.  To fix this, we should move the modal dialog out of a context and into
      // a hook so the lifecycle can be managed by the triggering components.
      // For now, it is probably ok if we replace an existing dialog with the same dialog,
      // so only report an error if we are replacing one type of dialog with another type of dialog.
      if (title !== t) {
        console.error(
          `Attempted to open a modal dialog when a modal dialog is already open.  Old title: "${title}"  New title: "${t}" `,
        );
      }
    } else {
      setShowModal(true);
      if (c) {
        setContent(c);
        setTitle(t);
      }
    }
  };

  const closeModal = () => setShowModal(false);

  return { showModal, allowOverflow, content, title, triggerModal, closeModal };
};

const useModal = (): ModalContext => React.useContext(modalContext);

export { useModalState, useModal };
