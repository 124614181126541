/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Iterator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0202 7.68688C14.2155 7.49161 14.2155 7.17503 14.0202 6.97977C13.8249 6.78451 13.5084 6.78451 13.3131 6.97977L12.5 7.79288V4.33332C12.5 3.68899 11.9777 3.16666 11.3333 3.16666H3.99999C3.35566 3.16666 2.83333 3.68899 2.83333 4.33332V11.6667C2.83333 12.311 3.35566 12.8333 3.99999 12.8333H6.16666C6.4428 12.8333 6.66666 12.6095 6.66666 12.3333C6.66666 12.0572 6.4428 11.8333 6.16666 11.8333H3.99999C3.90795 11.8333 3.83333 11.7587 3.83333 11.6667L3.83333 4.33332C3.83333 4.24128 3.90795 4.16666 3.99999 4.16666H11.3333C11.4254 4.16666 11.5 4.24128 11.5 4.33332L11.5 7.79289L10.6869 6.97977C10.4916 6.78451 10.175 6.78451 9.97976 6.97977C9.7845 7.17503 9.7845 7.49162 9.97976 7.68688L12 9.7071L14.0202 7.68688Z"
      fill={props.fill}
    />
    <path
      d="M8.83333 12.8333C9.10947 12.8333 9.33333 12.6095 9.33333 12.3333C9.33333 12.0572 9.10947 11.8333 8.83333 11.8333H7.83333C7.55719 11.8333 7.33333 12.0572 7.33333 12.3333C7.33333 12.6095 7.55719 12.8333 7.83333 12.8333H8.83333Z"
      fill={props.fill}
    />
    <path
      d="M11.5198 12.8333C11.7959 12.8333 12.0198 12.6095 12.0198 12.3333C12.0198 12.0572 11.7959 11.8333 11.5198 11.8333L10.4798 11.8333C10.2036 11.8333 9.97976 12.0572 9.97976 12.3333C9.97976 12.6095 10.2036 12.8333 10.4798 12.8333H11.5198Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.66666 5.33332C5.29847 5.33332 4.99999 5.6318 4.99999 5.99999V7.33332C4.99999 7.70151 5.29847 7.99999 5.66666 7.99999H6.29629V9.33275C6.29629 9.88503 6.74401 10.3327 7.29629 10.3327H7.80488C7.92007 10.5323 8.1357 10.6667 8.38271 10.6667H9.65684C10.025 10.6667 10.3235 10.3682 10.3235 10V9.64908C10.3235 9.28089 10.025 8.98241 9.65684 8.98241H8.38271C8.01452 8.98241 7.71604 9.28089 7.71604 9.64908V9.66608H7.29629C7.1122 9.66608 6.96296 9.51684 6.96296 9.33275V7.99999H7.66666C8.03485 7.99999 8.33333 7.70151 8.33333 7.33332V5.99999C8.33333 5.6318 8.03485 5.33332 7.66666 5.33332H5.66666ZM7.66666 5.99999H5.66666L5.66666 7.33332H7.66666V5.99999ZM8.38271 9.64908H9.65684L9.65684 10H8.38271V9.64908Z"
      fill={props.fill}
    />
  </svg>
);

Iterator.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Iterator;
