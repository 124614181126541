import React from 'react';
import { Row } from 'react-table';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import MenuIcon from 'ui/common/Icons/Standard/Menu';
import PortalMenu from 'ui/common/Menu/PortalMenu';
import { usePortalPopup } from 'ui/common/Tooltip/usePortalPopup';
import { ProjectsDetailTableData as TableData } from 'ui/dashboard/dashboardTypes';
import ProjectItemMenu from './ProjectItemMenu';

interface Props {
  row: Row<TableData>;
  data: TableData[];
}

const ProjectItemMenuButton: React.FC<Props> = ({ row, data }) => {
  const { triggerElRef, isPopupOpen, setIsPopupOpen } =
    usePortalPopup<HTMLButtonElement>();

  return (
    <>
      <Button
        ref={triggerElRef}
        variant={ButtonVariants.LargeTertiary}
        Icon={MenuIcon}
        onClick={() => setIsPopupOpen(!isPopupOpen)}
        testId="project-detail-item-menu-button"
      />
      {isPopupOpen && triggerElRef.current && (
        <PortalMenu
          triggerEl={triggerElRef.current}
          triggerClose={() => setIsPopupOpen(false)}>
          <ProjectItemMenu
            row={row}
            data={data}
            triggerClose={() => setIsPopupOpen(false)}
          />
        </PortalMenu>
      )}
    </>
  );
};

export default ProjectItemMenuButton;
