import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useDeleteProjectGitRemoteMutation } from 'app/enhancedApi';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Cell, Column, TableState } from 'react-table';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ArrowLeft, Git, Menu, S3 } from 'ui/common/Icons/Standard';
import MenuContent from 'ui/common/Menu/MenuContent';
import PortalMenu, { PortalMenuBackground } from 'ui/common/Menu/PortalMenu';
import { ItemType, MenuItemConfig } from 'ui/common/Menu/menuItemTypes';
import { useModal } from 'ui/common/Modal/useModal';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import Table from 'ui/common/Table/Table';
import { usePortalPopup } from 'ui/common/Tooltip/usePortalPopup';
import {
  AppContentWithFooterWrapper,
  AppContentWrapper,
} from 'ui/common/layout/appLayout';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import DashboardLeftSidebar from 'ui/dashboard/sidebar/Sidebar';
import { useProjectPermission } from 'ui/permission/useProjectPermission';
import { formatDate } from 'util/dateUtils';
import { useAppParams } from 'util/useAppParams';
import {
  ProjectDetailContent,
  ProjectDetailHeadButtonsContainer,
} from './ProjectDetail';
import { BackToProjectsButtonContainer } from './ProjectDetailHeader';
import { GitRemoteModal } from './gitProjects/GitRemoteModal';
import ProjectRightSidebar from './rightSidebar/ProjectRightSidebar';

const IntegrationsTitle = styled.div`
  font-size: ${({ theme }) => theme.typography.font.heading1.size};
  line-height: ${({ theme }) => theme.typography.font.heading1.lineHeight};
  letter-spacing: ${({ theme }) =>
    theme.typography.font.heading1.letterSpacing};
  font-weight: ${({ theme }) => theme.typography.font.heading1.weight};
  height: ${({ theme }) => theme.typography.font.heading1.lineHeight};
  padding-left: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: ${(props) => props.theme.spacing.xxlarge};
`;

const IntegrationsHeadButtonContainer = styled(
  ProjectDetailHeadButtonsContainer,
)`
  justify-content: flex-end;
`;

type IntegrationsTableData = {
  projectUuid: string;
  uuid: string | undefined;
  name: string;
  integrationType: 'github' | 's3';
  contentName: string;
  createdAt?: Date;
};

const DateBlock = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const RepoLink = styled.a`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text.secondary};

  &:hover {
    text-decoration: underline;
  }
`;

const MenuButtonWrapper = styled.div`
  text-align: right;
`;

const DateCell = (cell: Cell<IntegrationsTableData>) => (
  <DateBlock title={cell.value}>{formatDate(cell.value)}</DateBlock>
);

const RepoCell = (cell: Cell<IntegrationsTableData>) => (
  <RepoLink
    title={cell.value}
    target="_blank"
    rel="noreferrer"
    href={`https://github.com/${cell.value}`}>
    {cell.value}
  </RepoLink>
);

const ProjectIntegrationMenu = ({
  triggerClose,
  projectUuid,
  canEditProject,
  cellData,
}: {
  triggerClose: () => void;
  projectUuid: string;
  canEditProject: boolean;
  cellData: IntegrationsTableData;
}) => {
  const [callDeleteApi] = useDeleteProjectGitRemoteMutation();
  const { createShowError, showInfo } = useNotifications();

  const onDisconnect = React.useCallback(
    () =>
      callDeleteApi({ projectUuid })
        .unwrap()
        .then(() => showInfo(t`Disconnected from git repository`))
        .catch(createShowError(t`Failed to disconnect from git repository`)),
    [callDeleteApi, showInfo, createShowError, projectUuid],
  );

  const items: MenuItemConfig[] = React.useMemo(
    () =>
      cellData.integrationType === 'github'
        ? [
            {
              type: ItemType.Button,
              content: t`Disconnect from repo`,
              disabled: !canEditProject,
              onClick: () => {
                if (
                  confirm(
                    'You are about to disconnect the project from the remote ' +
                      'git repository. This means it will not be possible to ' +
                      'push and pull changes anymore. Proceed?',
                  )
                ) {
                  onDisconnect();
                }
              },
            },
          ]
        : [],
    [onDisconnect, cellData, canEditProject],
  );

  return (
    <PortalMenuBackground>
      <MenuContent items={items} isMenuOpen triggerCloseMenu={triggerClose} />
    </PortalMenuBackground>
  );
};

const MenuButton = ({ cell }: { cell: Cell<IntegrationsTableData> }) => {
  const { projectId } = useAppParams();
  const { canEditProject } = useProjectPermission(projectId);
  const { triggerElRef, isPopupOpen, setIsPopupOpen } =
    usePortalPopup<HTMLButtonElement>();

  if (!projectId) return null;

  return (
    <MenuButtonWrapper>
      <Button
        ref={triggerElRef}
        Icon={Menu}
        variant={ButtonVariants.LargeTertiary}
        onClick={() => setIsPopupOpen(!isPopupOpen)}
        testId="project-list-item-menu-button"
      />
      {isPopupOpen && triggerElRef.current && (
        <PortalMenu
          triggerEl={triggerElRef.current}
          triggerClose={() => setIsPopupOpen(false)}>
          <ProjectIntegrationMenu
            projectUuid={projectId}
            canEditProject={canEditProject}
            cellData={cell.row.original}
            triggerClose={() => setIsPopupOpen(false)}
          />
        </PortalMenu>
      )}
    </MenuButtonWrapper>
  );
};

const MenuCell = (cell: Cell<IntegrationsTableData>) => MenuButton({ cell });

const IntegrationIcon = (cell: Cell<IntegrationsTableData>) =>
  cell.value === 's3' ? <S3 /> : <Git />;

const integrationsColumns: Column<IntegrationsTableData>[] = [
  { Header: 'UUID', accessor: 'uuid', minWidth: 0, maxWidth: 0 },
  {
    Header: '',
    accessor: 'integrationType',
    minWidth: 24,
    maxWidth: 0,
    Cell: IntegrationIcon,
    disableSortBy: true,
  },
  {
    Header: t({
      id: 'dashboard.projeectIntegrations.table.name.label',
      message: 'Name',
    }),
    accessor: 'name',
    minWidth: 128,
    maxWidth: 1,
  },
  {
    Header: t({
      id: 'dashboard.projeectIntegrations.table.contentName.label',
      message: 'Content name',
    }),
    accessor: 'contentName',
    minWidth: 164,
    maxWidth: 1,
    Cell: RepoCell,
  },
  {
    Header: t({
      id: 'dashboard.projeectIntegrations.table.createdAt.label',
      message: 'Created',
    }),
    accessor: 'createdAt',
    minWidth: 170,
    maxWidth: 0,
    Cell: DateCell,
  },
  {
    Header: '',
    id: 'menu',
    disableSortBy: true,
    minWidth: 20,
    maxWidth: 0,
    disableResizing: true,
    Cell: MenuCell,
  },
];

const initialColumnState: Partial<TableState<IntegrationsTableData>> = {
  hiddenColumns: ['uuid'],
};

export const ProjectIntegrations: React.FC = () => {
  const { triggerModal } = useModal();
  const { projectId } = useAppParams();
  const { isLoadingProject, project } = useProject();
  const navigate = useNavigate();

  const integrationColumnsData: IntegrationsTableData[] = React.useMemo(() => {
    if (!project) return [];

    const remote = project.gitRemote;
    if (remote) {
      return [
        {
          uuid: undefined,
          projectUuid: project.uuid,
          integrationType: 'github',
          name: t`GitHub Integration`,
          contentName: `${remote.owner}/${remote.name}`,
          createdAt: new Date(remote.created_at),
        },
      ];
    }

    return [];
  }, [project]);

  if (isLoadingProject) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (!project) {
    return null;
  }

  const openGitRemoteModal = () => {
    triggerModal(<GitRemoteModal />, 'Add Git Remote');
  };

  return (
    <AppContentWithFooterWrapper>
      <AppContentWrapper>
        <DashboardLeftSidebar />
        <ProjectDetailContent>
          <BackToProjectsButtonContainer>
            <Button
              variant={ButtonVariants.SmallTertiary}
              Icon={ArrowLeft}
              onClick={() => navigate(`/projects/${projectId}`)}>
              {t({
                id: 'dashboard.projectsIntegrations.backToProject.label',
                message: 'Back to project',
              })}
            </Button>
          </BackToProjectsButtonContainer>
          <IntegrationsTitle>
            {t({
              id: 'dashboard.projectsIntegrations.title',
              message: 'Integrations',
            })}
          </IntegrationsTitle>
          <IntegrationsHeadButtonContainer>
            <Button
              variant={ButtonVariants.SmallPrimary}
              testId="project-integrations-add-git-remote"
              Icon={Git}
              disabled={!!project.gitConnected}
              onClick={openGitRemoteModal}>
              {t({
                id: 'dashboard.projectsIntegrations.connectToGit.label',
                message: 'Connect to Git',
              })}
            </Button>
          </IntegrationsHeadButtonContainer>

          <Table
            columns={integrationsColumns}
            data={integrationColumnsData}
            initialState={initialColumnState}
            testId="project-integrations-table"
          />
        </ProjectDetailContent>
        <ProjectRightSidebar project={project} />
      </AppContentWrapper>
    </AppContentWithFooterWrapper>
  );
};
