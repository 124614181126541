import styled from '@emotion/styled/macro';
import { useProject } from 'app/api/useProject';
import { getAdminAssumedUser } from 'app/config/adminAssumedUser';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { Project, Public, SharedWithMe } from 'ui/common/Icons/Standard';
import { SidebarSection } from 'ui/dashboard/sidebar/SidebarSection';
import { getProjectFilter } from 'util/projectFilterUtils';

const SidebarProjectListWrapper = styled.aside`
  height: 100%;
  width: 256px;
  flex-shrink: 0;
  flex-grow: 0;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.grey[2]};
  pointer-events: auto;
  padding-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const Sidebar: React.FC = () => {
  const { project } = useProject();
  const userId = useAppSelector((state) => state.user.userId);
  const currentUserId = getAdminAssumedUser() ?? userId;

  const currentFilter =
    !!project && !!currentUserId
      ? getProjectFilter(project, currentUserId)
      : undefined;

  return (
    <SidebarProjectListWrapper data-test-id="dashboard-sidebar-projects">
      <SidebarSection
        icon={Project}
        projectFilter="mine"
        currentFilter={currentFilter}
        showPublicBadge
        showFavorites
      />
      <SidebarSection
        icon={SharedWithMe}
        projectFilter="sharedWithMe"
        currentFilter={currentFilter}
        showPublicBadge
        showFavorites
      />
      <SidebarSection
        icon={Public}
        projectFilter="public"
        currentFilter={currentFilter}
        showFavorites={false}
      />
    </SidebarProjectListWrapper>
  );
};

export default Sidebar;
