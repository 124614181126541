import { PortSide } from 'app/common_types/PortTypes';
import {
  AnnotationInstance,
  LinkInstance,
  NodeInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { nodeTypeIsSubdiagram } from 'app/helpers';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { modelActions } from 'app/slices/modelSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { AppDispatch, store } from 'app/store';
import { getPortWorldCoordinate } from 'app/utils/getPortOffsetCoordinate';
import { snapNumberToGrid } from 'app/utils/modelDataUtils';
import { renderConstants } from 'app/utils/renderConstants';
import { useVisualizerPrefs } from 'ui/modelEditor/useVisualizerPrefs';
import { v4 as makeUuid } from 'uuid';
import { getVisualNodeHeight } from './getVisualNodeHeight';
import { getVisualNodeWidth } from './getVisualNodeWidth';

const copyIntoClipboard = (
  dispatch: AppDispatch,
  getState: typeof store.getState,
  payload: {
    nodes: NodeInstance[];
    links: LinkInstance[];
    annotations: AnnotationInstance[];
    projectId: string;
  },
) => {
  const { nodes, links, annotations, projectId } = payload;
  let nodesCopy: NodeInstance[] = JSON.parse(JSON.stringify(nodes));

  const state = getState();

  const modelDoc = state.model.present;
  const submodelRefs = modelDoc.submodels.references;
  const submodelDiagrams = modelDoc.submodels.diagrams;

  const copiedSubmodelsSection: SubmodelsSection = {
    references: {},
    diagrams: {},
  };

  // recursively gather all group diagrams
  // nested to any level within the selection
  // so that we can reliably paste them even if deleted
  // or pasting in a different model.
  while (nodesCopy.length > 0) {
    const node = nodesCopy.pop();

    if (node && nodeTypeIsSubdiagram(node.type)) {
      const submRef = submodelRefs[node.uuid];
      if (!submRef) continue;
      const submDiagram = submodelDiagrams[submRef.diagram_uuid || ''];
      if (!submDiagram) continue;

      copiedSubmodelsSection.references[node.uuid] = submRef;
      copiedSubmodelsSection.diagrams[submRef.diagram_uuid] = submDiagram;
      if (submDiagram.nodes && submDiagram.nodes.length > 0) {
        nodesCopy = nodesCopy.concat(submDiagram.nodes);
      }
    }
  }

  dispatch(
    uiFlagsActions.setUIFlag({
      inAppClipboard: {
        nodes,
        links,
        annotations,
        copiedSubmodelsSection,
        projectId,
      },
    }),
  );
};

export const copyEntities = (
  projectId: string,
  nodes: NodeInstance[],
  links: LinkInstance[],
  annotations: AnnotationInstance[],
  nodeUuids: string[],
  linkUuids: string[],
  annotationUuids: string[],
  dispatch: AppDispatch,
  visualizerPrefs: ReturnType<typeof useVisualizerPrefs>,
  cut?: boolean,
): void => {
  dispatch((dispatch, getState) =>
    copyIntoClipboard(dispatch, getState, {
      nodes,
      links,
      annotations,
      projectId,
    }),
  );

  if (cut) {
    visualizerPrefs.removeAllBlockPortsFromChart(
      nodeUuids.map((nodeId) => ({
        nodeId,
        parentPath: getCurrentModelRef().submodelPath,
      })),
    );

    // TODO: call delete block endpoint so that link deletes cascade.
    // dispatch(enhancedApi.endpoints.deleteBlock.initiate());

    dispatch(
      modelActions.removeEntitiesFromModel({
        blockUuids: nodeUuids,
        linkUuids,
        annotationUuids,
      }),
    );
  }
};

export type PastedEntityUuids = {
  nodeUuids: string[];
  linkUuids: string[];
  annotationUuids: string[];
};

export const pasteEntities = (
  x: number,
  y: number,
  duplicateInPlace: boolean,
  pastingNodes: NodeInstance[],
  pastingLinks: LinkInstance[],
  pastingAnnotations: AnnotationInstance[],
  overrideSubmodelsSection: SubmodelsSection | undefined,
  allNodes: NodeInstance[],
  dispatch: AppDispatch,
): PastedEntityUuids => {
  const finalUuids: PastedEntityUuids = {
    nodeUuids: [],
    linkUuids: [],
    annotationUuids: [],
  };
  const newUuidMap: { [k: string]: string } = {};

  let minX = Number.MAX_SAFE_INTEGER;
  let minY = Number.MAX_SAFE_INTEGER;
  let maxX = Number.MIN_SAFE_INTEGER;
  let maxY = Number.MIN_SAFE_INTEGER;

  for (let i = 0; i < pastingNodes.length; i++) {
    const pastingNodeHeight = getVisualNodeHeight(pastingNodes[i]);
    const pastingNodeWidth = getVisualNodeWidth(pastingNodes[i]);
    newUuidMap[pastingNodes[i].uuid] = makeUuid();
    finalUuids.nodeUuids.push(newUuidMap[pastingNodes[i].uuid]);

    minX = Math.min(pastingNodes[i].uiprops.x, minX);
    minY = Math.min(pastingNodes[i].uiprops.y, minY);
    maxX = Math.max(pastingNodes[i].uiprops.x + pastingNodeWidth, maxX);
    maxY = Math.max(pastingNodes[i].uiprops.y + pastingNodeHeight, maxY);
  }

  for (let i = 0; i < pastingAnnotations.length; i++) {
    const pastingAnnotationHeight =
      pastingAnnotations[i].grid_height * renderConstants.GRID_SIZE;
    const pastingAnnotationWidth =
      pastingAnnotations[i].grid_width * renderConstants.GRID_SIZE;
    newUuidMap[pastingAnnotations[i].uuid] = makeUuid();
    finalUuids.annotationUuids.push(newUuidMap[pastingAnnotations[i].uuid]);

    minX = Math.min(pastingAnnotations[i].x, minX);
    minY = Math.min(pastingAnnotations[i].y, minY);
    maxX = Math.max(pastingAnnotations[i].x + pastingAnnotationWidth, maxX);
    maxY = Math.max(pastingAnnotations[i].y + pastingAnnotationHeight, maxY);
  }

  for (let i = 0; i < pastingLinks.length; i++) {
    newUuidMap[pastingLinks[i].uuid] = makeUuid();
    finalUuids.linkUuids.push(newUuidMap[pastingLinks[i].uuid]);

    for (let j = 0; j < pastingLinks[i].uiprops.segments.length; j++) {
      const segment = pastingLinks[i].uiprops.segments[j];

      if (segment.segment_direction == 'vert') {
        minX = Math.min(segment.coordinate, minX);
        maxX = Math.max(segment.coordinate, maxX);
      } else {
        minY = Math.min(segment.coordinate, minY);
        maxY = Math.max(segment.coordinate, maxY);
      }
    }

    const hangCoordStart = pastingLinks[i].uiprops.hang_coord_start;
    if (!pastingLinks[i].src && hangCoordStart) {
      minX = Math.min(hangCoordStart.x, minX);
      maxX = Math.max(hangCoordStart.x, maxX);
      minY = Math.min(hangCoordStart.y, minY);
      maxY = Math.max(hangCoordStart.y, maxY);
    }

    const hangCoordEnd = pastingLinks[i].uiprops.hang_coord_end;
    if (!pastingLinks[i].dst && hangCoordEnd) {
      minX = Math.min(hangCoordEnd.x, minX);
      maxX = Math.max(hangCoordEnd.x, maxX);
      minY = Math.min(hangCoordEnd.y, minY);
      maxY = Math.max(hangCoordEnd.y, maxY);
    }
  }

  const correctedPastingNodes: NodeInstance[] = [];
  const correctedPastingLinks: LinkInstance[] = [];
  const correctedPastingAnnotations: AnnotationInstance[] = [];

  const pasteWidth = maxX - minX;
  const pasteHeight = maxY - minY;

  const pasteX = duplicateInPlace ? minX : snapNumberToGrid(x - pasteWidth / 2);
  const pasteY = duplicateInPlace
    ? minY
    : snapNumberToGrid(y - pasteHeight / 2);

  for (let i = 0; i < pastingNodes.length; i++) {
    const block: NodeInstance = JSON.parse(JSON.stringify(pastingNodes[i]));
    block.uuid = newUuidMap[block.uuid] || block.uuid;
    block.uiprops.x = block.uiprops.x - minX + pasteX;
    block.uiprops.y = block.uiprops.y - minY + pasteY;

    correctedPastingNodes.push(block);
  }

  for (let i = 0; i < pastingAnnotations.length; i++) {
    const annotation: AnnotationInstance = JSON.parse(
      JSON.stringify(pastingAnnotations[i]),
    );
    annotation.uuid = newUuidMap[annotation.uuid] || annotation.uuid;
    annotation.x = annotation.x - minX + pasteX;
    annotation.y = annotation.y - minY + pasteY;

    correctedPastingAnnotations.push(annotation);
  }

  for (let i = 0; i < pastingLinks.length; i++) {
    if (!pastingLinks[i]) continue;

    const link: LinkInstance = JSON.parse(JSON.stringify(pastingLinks[i]));

    if (!link) continue;

    link.uuid = newUuidMap[link.uuid] || link.uuid;

    if (link.src && newUuidMap[link.src.node]) {
      link.src.node = newUuidMap[link.src.node];
    } else if (link.src) {
      const sourceNode = allNodes.find((node) =>
        link.src ? node.uuid === link.src.node : undefined,
      );

      if (sourceNode) {
        const hangCoord = getPortWorldCoordinate(
          sourceNode,
          PortSide.Output,
          link.src,
        );

        if (hangCoord) {
          link.uiprops.hang_coord_start = {
            x: hangCoord.x - minX + pasteX,
            y: hangCoord.y - minY + pasteY,
          };
        }
      }

      link.src = undefined;
    } else if (link.uiprops.hang_coord_start) {
      link.uiprops.hang_coord_start.x += pasteX - minX;
      link.uiprops.hang_coord_start.y += pasteY - minY;
    }

    if (link.dst && newUuidMap[link.dst.node]) {
      link.dst.node = newUuidMap[link.dst.node];
    } else if (link.dst) {
      const destNode = allNodes.find((node) =>
        link.dst ? node.uuid === link.dst.node : undefined,
      );

      if (destNode) {
        const hangCoord = getPortWorldCoordinate(
          destNode,
          PortSide.Input,
          link.dst,
        );

        if (hangCoord) {
          link.uiprops.hang_coord_end = {
            x: hangCoord.x - minX + pasteX,
            y: hangCoord.y - minY + pasteY,
          };
        }
      }

      link.dst = undefined;
    } else if (link.uiprops.hang_coord_end) {
      link.uiprops.hang_coord_end.x += pasteX - minX;
      link.uiprops.hang_coord_end.y += pasteY - minY;
    }

    if (
      link.uiprops.link_type.connection_method === 'link_tap' &&
      newUuidMap[link.uiprops.link_type.tapped_link_uuid]
    ) {
      link.uiprops.link_type.tapped_link_uuid =
        newUuidMap[link.uiprops.link_type.tapped_link_uuid];
    } else if (link.uiprops.link_type.connection_method === 'link_tap') {
      link.uiprops.link_type = {
        connection_method: 'direct_to_block',
      };
      link.src = undefined;
    }

    for (let j = 0; j < link.uiprops.segments.length; j++) {
      const segment = link.uiprops.segments[j];

      if (segment.segment_direction == 'vert') {
        link.uiprops.segments[j].coordinate =
          segment.coordinate - minX + pasteX;
      } else {
        link.uiprops.segments[j].coordinate =
          segment.coordinate - minY + pasteY;
      }
    }

    correctedPastingLinks.push(link);
  }

  dispatch(
    modelActions.addPremadeEntitiesToModel({
      nodes: correctedPastingNodes,
      links: correctedPastingLinks,
      annotations: correctedPastingAnnotations,
      referenceSubmodelIdToSubmodel: {},
      copiedEntitiesOldToNewMap: newUuidMap,
      overrideSubmodelsSection,
    }),
  );

  return finalUuids;
};
