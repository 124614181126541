// THIS FILE IS AUTO-GENERATED, DO NOT MANUALLY EDIT
export const blockIconIDsList = [
  'integrator',
  'discreteintegrator',
  'library',
  'iterator',
  'append',
  'selector',
  'sequence',
  'submodel',
  'stop',
  'gearbox',
  'gyroscope',
  'generic',
  'loopcounter',
  'loopbreak',
  'loopmemory',
  'derivative',
  'reciprocal',
  'exponent',
  'log',
  'abs',
  'squareroot',
  'power',
  'matrixoperator.inverse',
  'matrixoperator.multiply',
  'matrixoperator.dotproduct',
  'matrixoperator.concatenationhorizontal',
  'matrixoperator.concatenationvertical',
  'matrixoperator.transpose',
  'sumofelements',
  'productofelements',
  'matrixoperator.extraction',
  'trig.atan',
  'gain',
  'ramp',
  'pulse',
  'sinewave',
  'zeroorderhold',
  'quantizer',
  'fft',
  'switch',
  'ratelimiter',
  'discretederivative',
  'discretefilter',
  'chirp',
  'sawtooth',
  'rigidbodydynamics',
  'repeatingsequence',
  'signalgenerator',
  'clock',
  'delay',
  'videosource',
  'videosink',
  'imagesource',
  'counter',
  'ground',
  'random',
  'bandlimitednoise',
  'adder',
  'product',
  'code',
  'subtractor',
  'transferfunction',
  'transferfunctiondiscrete',
  'model',
  'group',
  'replicator',
  'conditional',
  'relay',
  'neuralnet',
  'offset',
  'discreteinitializer.initialstatetrue',
  'discreteinitializer.initialstatefalse',
  'crossproduct',
  'dotproduct',
  'edgedetector.rising',
  'edgedetector.falling',
  'edgedetector.either',
  'pythonscript',
  'cfunction',
  'cppfunction',
  'logic.nor',
  'logic.nand',
  'logic.not',
  'logic.and',
  'logic.or',
  'logic.xor',
  'comparator.gt',
  'comparator.ee',
  'comparator.ltgt',
  'comparator.lt',
  'comparator.gte',
  'comparator.lte',
  'lookuptable1d',
  'mux',
  'buscreator',
  'busselector',
  'inport',
  'outport',
  'demux',
  'lookuptable2d',
  'statemachine',
  'ifthenelse',
  'datasource',
  'datasink',
  'step',
  'constant',
  'minmax',
  'trig.sin',
  'randomnormal',
  'randomdistribution.bimodal',
  'randomdistribution.uniform',
  'randomdistribution.binomial',
  'randomdistribution.poisson',
  'randomdistribution.bernoulli',
  'randomdistribution.exponential',
  'trig.cos',
  'trig.tan',
  'trig.asin',
  'trig.acos',
  'trig.sinh',
  'trig.cosh',
  'trig.tanh',
  'trig.asinh',
  'trig.acosh',
  'trig.atanh',
  'pid.pid',
  'pid.pi',
  'pid.pd',
  'pid.p',
  'pid.i',
  'discretepid.pid',
  'discretepid.pi',
  'discretepid.pd',
  'discretepid.p',
  'discretepid.i',
  'unitdelay',
  'saturate',
  'deadzone',
  'batterycell',
  'coordinaterotationconversion.rotationanglestoquaternion',
  'coordinaterotationconversion.rotationanglestodcm',
  'coordinaterotationconversion.dcmtorotationangles',
  'coordinaterotation.quaternion',
  'coordinaterotation.rotationangles',
  'coordinaterotation.dcm',
  'coordinaterotationconversion.quaterniontorotationangles',
  'coordinaterotationconversion.quaterniontodcm',
  'coordinaterotationconversion.dcmtoquaternion',
  'vectorizer',
  'scalarbroadcast',
  'statespace',
  'tile',
  'stack',
  'cosine',
  'accelerometer',
  'magnetometer',
  'speedsensor',
  'highvoltagebattery',
  'electricmotor',
  'electricalvehicle',
  'chassis.longitudinalbody2axle',
  'chassis.axle',
  'mechanical.rotationalinertia',
  'modelicafmu',
  'objectdetection',
  'imagesegmentation',
  'predictor',
  'typeconversion',
  'drivecycle',
  'statemachine',
];
