/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Branch: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M17 2.93934L13.9697 5.96967C13.6768 6.26256 13.6768 6.73744 13.9697 7.03033C14.2626 7.32322 14.7374 7.32322 15.0303 7.03033L16.25 5.81066V15.5C16.25 16.1904 15.6904 16.75 15 16.75H8.88555C8.64135 15.9725 8.02752 15.3587 7.25 15.1144V14.75C7.25 14.3358 6.91421 14 6.5 14C6.08579 14 5.75 14.3358 5.75 14.75V15.1144C4.73572 15.433 4 16.3806 4 17.5C4 18.8807 5.11929 20 6.5 20C7.61941 20 8.56698 19.2643 8.88555 18.25H15C16.5188 18.25 17.75 17.0188 17.75 15.5V5.81066L18.9697 7.03033C19.2626 7.32322 19.7374 7.32322 20.0303 7.03033C20.3232 6.73744 20.3232 6.26256 20.0303 5.96967L17 2.93934ZM6.5 18.5C7.05228 18.5 7.5 18.0523 7.5 17.5C7.5 16.9477 7.05228 16.5 6.5 16.5C5.94772 16.5 5.5 16.9477 5.5 17.5C5.5 18.0523 5.94772 18.5 6.5 18.5Z"
      fill={props.fill}
    />
    <path
      d="M6.5 4C6.91421 4 7.25 4.33579 7.25 4.75V6.75C7.25 7.16421 6.91421 7.5 6.5 7.5C6.08579 7.5 5.75 7.16421 5.75 6.75V4.75C5.75 4.33579 6.08579 4 6.5 4Z"
      fill={props.fill}
    />
    <path
      d="M6.5 12.5C6.08579 12.5 5.75 12.1642 5.75 11.75V9.75C5.75 9.33579 6.08579 9 6.5 9C6.91421 9 7.25 9.33579 7.25 9.75V11.75C7.25 12.1642 6.91421 12.5 6.5 12.5Z"
      fill={props.fill}
    />
  </svg>
);

Branch.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Branch;
