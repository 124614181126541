import { t } from '@lingui/macro';

import { useJupyter } from 'app/api/useJupyter';
import { Play, RunAll, Unchecked } from 'ui/common/Icons/Standard';
import Reload from 'ui/common/Icons/Standard/Reload';

import { NavbarButton } from './NavbarButtons';

interface Props {
  isNotebookFile: boolean;
}

const NotebookControls: React.FC<Props> = ({ isNotebookFile }) => {
  const { runCell, stopKernel, restartKernel, restartRerun } = useJupyter();
  if (!isNotebookFile) {
    return null;
  }

  return (
    <>
      <NavbarButton
        tooltipText={t({
          id: 'navbar.notebook.runSelectedCellAndAdvanceTooltip',
          message: 'Run selected cell and advance',
        })}
        Icon={Play}
        isEnabled
        onClickHandler={runCell}
        testId="navbar-run-cell-button"
      />
      <NavbarButton
        tooltipText={t({
          id: 'navbar.notebook.stopKernelTooltip',
          message: 'Stop Kernel',
        })}
        Icon={Unchecked}
        isEnabled
        onClickHandler={stopKernel}
        testId="navbar-stop-kernel-button"
      />

      <NavbarButton
        tooltipText={t({
          id: 'navbar.notebook.restartKernelTooltip',
          message: 'Restart kernel',
        })}
        Icon={Reload}
        isEnabled
        onClickHandler={restartKernel}
        testId="navbar-restart-kernel-button"
      />
      <NavbarButton
        tooltipText={t({
          id: 'navbar.notebook.restartKernelThenRerunEntireNotebookTooltip',
          message: 'Restart kernel, then re-run entire notebook',
        })}
        Icon={RunAll}
        isEnabled
        onClickHandler={restartRerun}
        testId="navbar-run-all-notebooks-button"
      />
    </>
  );
};

export default NotebookControls;
