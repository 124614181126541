import styled from '@emotion/styled';
import {
  BlockId,
  CompilationErrors,
  readCompilationErrors,
} from 'app/generated_types/CmlTypes';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { navigationActions } from 'app/slices/navigationSlice';
import { ModelLogLine } from 'app/slices/simResultsSlice';
import { format } from 'date-fns';
import React from 'react';
import {
  getLogLevelDisplayName,
  parseServerOutputLog,
} from 'ui/appBottomBar/OutputLogLineUtils';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';

const CompilationErrorButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing.normal};

  > button {
    margin-bottom: ${({ theme }) => theme.spacing.normal};
    margin-right: ${({ theme }) => theme.spacing.normal};
  }
`;

const GoToBlockButton = ({
  blockName,
  uuidPath,
}: {
  blockName: string;
  uuidPath: string[];
}) => {
  const dispatch = useAppDispatch();

  const navigateToBlock = () => {
    const parentPath = uuidPath.slice(0, -1);
    const nodeUuid = uuidPath[uuidPath.length - 1];

    dispatch(
      modelActions.setSelections({
        selectionParentPath: parentPath,
        selectedBlockIds: [nodeUuid],
      }),
    );

    dispatch(
      navigationActions.requestFocusOnNode({
        nodeId: nodeUuid,
        parentPath,
      }),
    );
  };

  return (
    <Button variant={ButtonVariants.SmallSecondary} onClick={navigateToBlock}>
      Jump to {blockName}
    </Button>
  );
};

const CompilationErrorButtons = ({
  unparsedAtdError,
}: {
  unparsedAtdError: any;
}) => {
  let parsedErrors: CompilationErrors | undefined;
  try {
    parsedErrors = readCompilationErrors(unparsedAtdError);
  } catch {
    // We likely already emitted a console.error in useModelSimulationResults.ts
    // so let's not emit another Sentry alert here. Note that we will likely need
    // to switch this to console.error for runtime errors (eg. PythonScript syntax error)
    // but this is not the case right now. @jp - 2023-02-23
    console.warn('Could not parse compilation error');
  }

  if (!parsedErrors) return null;

  const blockIds: BlockId[] = [];
  const blockIdSet: Set<string> = new Set();

  for (let i = 0; i < parsedErrors.length; i++) {
    const errorVal = parsedErrors[i];
    switch (errorVal.kind) {
      case 'AlgebraicLoop':
        if (errorVal.value?.locations) {
          for (let j = 0; j < errorVal.value.locations.length; j++) {
            const loc = errorVal.value.locations[j];
            if (
              loc.block_id &&
              !blockIdSet.has(loc.block_id.uuid_path.join())
            ) {
              blockIds.push(loc.block_id);
              blockIdSet.add(loc.block_id.uuid_path.join());
            }
          }
        }
        break;
      case 'InvalidParam':
      case 'UnconnectedInput':
        if (
          errorVal.value?.block_id &&
          !blockIdSet.has(errorVal.value.block_id.uuid_path.join())
        ) {
          blockIds.push(errorVal.value.block_id);
          blockIdSet.add(errorVal.value.block_id.uuid_path.join());
        }
        break;
    }
  }

  return (
    <CompilationErrorButtonsContainer>
      {blockIds.map((block_id, i) => (
        <GoToBlockButton
          key={`${block_id}_${i}`}
          uuidPath={block_id.uuid_path}
          blockName={block_id.name_path.join('.')}
        />
      ))}
    </CompilationErrorButtonsContainer>
  );
};

export const OutputRow = styled.tr`
  display: table-row;
  white-space: pre-line;

  :hover {
    background-color: ${({ theme }) => theme.colors.ui.tint3};
  }
`;

const OutputRowElement = styled.td`
  padding: 0.5em;
  vertical-align: top;
  width: 1%;

  &.unknown {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const OutputRowTimestamp = styled(OutputRowElement)`
  color: ${({ theme }) => theme.colors.text.secondary};
  white-space: nowrap;
`;

export const OutputRowLevel = styled(OutputRowElement)`
  color: ${({ theme }) => theme.colors.text.tertiary};
  font-weight: bold;
  white-space: nowrap;

  &.fatal {
    color: ${({ theme }) => theme.colors.ui.error};
  }

  &.err {
    color: ${({ theme }) => theme.colors.ui.error};
  }

  &.wrn {
    color: ${({ theme }) => theme.colors.ui.highlight};
  }

  &.inf {
    color: ${({ theme }) => theme.colors.brand.tertiary.base};
  }

  &.dbg {
    color: ${({ theme }) => theme.colors.text.tertiary};
  }

  &.unknown {
    color: ${({ theme }) => theme.colors.text.tertiary};
  }
`;

export const OutputRowText = styled(OutputRowElement)`
  color: ${({ theme }) => theme.colors.text.primary};
  width: 100%;
  overflow-wrap: break-word;
`;

export const OutputRowMessage = styled.span`
  white-space: pre-wrap;
`;

const MetadataEntry = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-right: 1em;
  font-weight: normal;
  display: inline-block;

  :hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const MetadataName = styled.span`
  font-weight: bold;
  display: inline-block;
`;

interface Props {
  rawLog: ModelLogLine;
}

const OutputLogLine: React.FC<Props> = ({ rawLog }) => {
  const message: string = rawLog.message || '';

  const timestamp: string | null =
    typeof rawLog.timestamp === 'number'
      ? format(new Date(rawLog.timestamp * 1000), 'HH:mm:ss.SSS')
      : null;

  const metadata: [string, string][] = parseServerOutputLog(rawLog);

  const levelName = getLogLevelDisplayName(rawLog.level);
  const className = rawLog.level?.toLowerCase() || 'unknown';

  return (
    <OutputRow>
      <OutputRowTimestamp>{timestamp}</OutputRowTimestamp>
      <OutputRowLevel className={className}>{levelName}</OutputRowLevel>
      <OutputRowText className={className}>
        <OutputRowMessage>{message}</OutputRowMessage>
        {metadata ? <br /> : null}
        {metadata?.map((m, i) => (
          <MetadataEntry key={i}>
            <MetadataName>{m[0]}</MetadataName>={m[1]}
          </MetadataEntry>
        ))}
        {rawLog.atd_error ? (
          <CompilationErrorButtons unparsedAtdError={rawLog.atd_error} />
        ) : null}
      </OutputRowText>
    </OutputRow>
  );
};

export default OutputLogLine;
