import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useProjectActions } from 'app/api/useProjectActions';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import Input from 'ui/common/Input/Input';
import { NavbarMenuBackground } from 'ui/navbar/NavbarButtons';
import Models from './Models';
import Notebooks from './Notebooks';
import Other from './Other';

interface Props {
  triggerClose: () => void;
}

const Title = styled(Input)`
  input {
    font-size: ${({ theme }) => theme.typography.font.title.size};
    line-height: ${({ theme }) => theme.typography.font.title.lineHeight};
    line-weight: ${({ theme }) => theme.typography.font.title.weight};
    color: ${({ theme }) => theme.colors.grey[2]};
  }
  margin-top: ${({ theme }) => theme.spacing.normal};
  margin-left: ${({ theme }) => theme.spacing.large};
  margin-right: ${({ theme }) => theme.spacing.large};
`;

const Description = styled(Input)`
  input {
    color: ${({ theme }) => theme.colors.grey[30]};
  }
  margin-top: ${({ theme }) => theme.spacing.small};
  margin-left: ${({ theme }) => theme.spacing.large};
  margin-right: ${({ theme }) => theme.spacing.large};
`;

const Browser: React.FC<Props> = ({ triggerClose }) => {
  const { project } = useProject();
  const { updateProject } = useProjectActions();
  const { hasAccessToJupyter } = useAppSelector(
    (state) => state.userOptions.options,
  );

  if (!project) {
    return null;
  }

  const renameTitle = (newTitle: string) => {
    updateProject({
      projectUuid: project.uuid,
      projectUpdateRequest: {
        title: newTitle,
        description: project.description,
      },
    });
  };
  const renameDescription = (newDescription: string) => {
    updateProject({
      projectUuid: project.uuid,
      projectUpdateRequest: {
        title: project.title,
        description: newDescription,
      },
    });
  };

  return (
    <NavbarMenuBackground>
      <Title
        value={project.title}
        placeholder={t({
          id: 'browser.titlePlaceholder',
          message: 'Add project title',
        })}
        onSubmitValue={renameTitle}
      />
      <Description
        value={project.description}
        placeholder={t({
          id: 'browser.descriptionPlaceholder',
          message: 'Add project description',
        })}
        onSubmitValue={renameDescription}
      />
      <Models project={project} triggerClose={triggerClose} />
      {hasAccessToJupyter && (
        <Notebooks projectId={project.uuid} triggerClose={triggerClose} />
      )}
      <Other project={project} triggerClose={triggerClose} />
    </NavbarMenuBackground>
  );
};

export default Browser;
