// Build-time application config switches
// Allows us to disable certain features for GovCloud
// or offline mode.

export const DISABLE_INTERCOM =
  process.env.REACT_APP_GOVCLOUD === 'true' ||
  process.env.REACT_APP_IS_OFFLINE === 'true';
export const DISABLE_GA4 =
  process.env.REACT_APP_GOVCLOUD === 'true' ||
  process.env.REACT_APP_IS_OFFLINE === 'true' ||
  // eslint-disable-next-line
  // @ts-ignore
  window.Cypress;
export const DISABLE_SENTRY =
  process.env.REACT_APP_DISABLE_SENTRY === 'true' ||
  process.env.REACT_APP_GOVCLOUD === 'true' ||
  process.env.REACT_APP_IS_OFFLINE === 'true';

export const DISABLE_ONBOARDING = true;

export const API_BASE_URL = process.env.REACT_APP_IS_ELECTRON
  ? `${process.env.REACT_APP_API_ENDPOINT}/api/v0`
  : '/api/v0';

export const SENTRY_HACK_FOR_MATPLOTLIB = true;
