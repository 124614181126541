import { useGetUserOptionsQuery } from 'app/apiGenerated/generatedApi';
import { UserProfileOptionsResponse } from 'app/apiGenerated/generatedApiTypes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  UserOptions,
  initialState,
  userOptionsActions,
} from 'app/slices/userOptionsSlice';
import React from 'react';
import { isAdminUser } from 'ui/auth/utils';
import { AutocompleteModel } from 'ui/codeEditor/AutocompleteHandler';

function hasOptionEnabled(
  data: UserProfileOptionsResponse,
  optionKey: string,
  defValue = false,
) {
  if (!data) return false;
  if (!data[optionKey]) return defValue;
  if (data[optionKey].toLowerCase() === 'false') return false;
  if (data[optionKey].toLowerCase() === 'true') return true;
  return defValue;
}

export function useUserOptions() {
  const dispatch = useAppDispatch();

  const { data } = useGetUserOptionsQuery();

  const areUserOptionsLoaded = useAppSelector(
    (state) => state.userOptions.areUserOptionsLoaded,
  );

  React.useEffect(() => {
    // Only set user options once when the user signs in.
    if (!areUserOptionsLoaded && data) {
      const options: UserOptions = {
        developerModeEnabled: hasOptionEnabled(
          data,
          'developer_mode_enabled',
          isAdminUser(),
        ),
        clerkWhitelistEnabled: hasOptionEnabled(
          data,
          'clerk_whitelist_enabled',
        ),
        hasAccessToJupyter: hasOptionEnabled(data, 'allow_hosted_jupyter'),
        recordModeOptionEnabled: hasOptionEnabled(
          data,
          'record_mode_option_enabled',
          isAdminUser(),
        ),
        simIgnoreCache: hasOptionEnabled(data, 'sim_ignore_cache', false),
        maxQueuedSims:
          parseInt(data?.max_queued_sims) ?? initialState.options.maxQueuedSims,
        workerTypeSelectionEnabled: hasOptionEnabled(
          data,
          'worker_type_selection_enabled',
          false,
        ),
        pythonConsoleEnabled: hasOptionEnabled(data, 'python_console_enabled'),
        aiAssistantEnabled: hasOptionEnabled(data, 'ai_assistant_enabled'),
        dataIntegrationsEnabled: hasOptionEnabled(
          data,
          'data_integrations_enabled',
          false,
        ),
        fmuBlocksEnabled: hasOptionEnabled(data, 'fmu_blocks_enabled', false),
        cppBlocksEnabled: hasOptionEnabled(data, 'cpp_blocks_enabled', false),
        showSignalPreview: hasOptionEnabled(data, 'show_signal_preview', true),
        mediaVisualizerEnabled: hasOptionEnabled(
          data,
          'media_visualizer_enabled',
          false,
        ),
        dataExplorerEnabled: hasOptionEnabled(
          data,
          'data_explorer_enabled',
          false,
        ),
        experimentsEnabled: hasOptionEnabled(
          data,
          'experiments_enabled',
          false,
        ),
        codexAutocompleteEnabled: hasOptionEnabled(
          data,
          'codex_autocomplete_enabled',
          false,
        ),
        codexAutocompleteModel:
          (data?.codex_autocomplete_model as AutocompleteModel) ??
          'GPT3Dot5Turbo',
        uploadMlModelsEnabled: hasOptionEnabled(
          data,
          'upload_ml_models_enabled',
          false,
        ),
        experimentalPythonToC: hasOptionEnabled(
          data,
          'experimental_python_to_c',
        ),
        openAiKey: data?.openai_key ?? '',
        gitEnabled: hasOptionEnabled(data, 'git_enabled'),
        chatCommonKeyMaxCallCount:
          parseInt(data?.chat_common_key_max_call_count) ?? 10,
        chatFunctionsV2: hasOptionEnabled(data, 'chat_functions_v2', false),
      };

      dispatch(userOptionsActions.setUserOptions({ options }));
    }
  }, [dispatch, data, areUserOptionsLoaded]);
}
