import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useAppDispatch } from 'app/hooks';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React, { ReactElement } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { AiAgent, ArrowUp } from 'ui/common/Icons/Standard';
import { INPUT_BAR_HEIGHT } from './Sizings';
import DialogButtons, { DialogButtonsProps } from './DialogButtons';

const UserInputBoxDiv = styled.div`
  display: flex;
  position: flex-end;
  align-items: center;
  pointer-events: auto;
  z-index: 1;
  width: 100%;
  border-radius: 3px;

  background-color: ${({ theme }) => theme.colors.grey[2]};
  box-shadow: ${({ theme }) => theme.shadows.standard};
  border: none;

  &:focus-within {
    outline: 2px solid ${({ theme }) => theme.colors.brand.primary.lighter};
  }

  > button {
    padding: ${({ theme }) => theme.spacing.normal};
    color: ${({ theme }) => theme.colors.tint.base};
  }

  &:hover {
      background-color: ${({ theme }) => theme.colors.grey[2]};
      outline: 2px solid ${({ theme }) => theme.colors.brand.primary.lighter};
    }
  }
`;

const UserInput = styled.textarea`
  pointer-events: auto;

  width: 100%;
  height: ${INPUT_BAR_HEIGHT}px;
  padding: ${({ theme }) => theme.spacing.normal}
    ${({ theme }) => theme.spacing.xlarge};

  font-size: ${({ theme }) => theme.typography.font.standard.size};
  font-weight: ${({ theme }) => theme.typography.font.standard.weight};
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};
  border-radius: 3px;

  background-color: ${({ theme }) => theme.colors.grey[2]};
  outline: none;
  border: none;

  // Resizing could be useful but I couldn't get it right
  // the resize handle is always on the bottom-right, but it would be better on the top-right
  // resize: vertical;
  resize: none;
`;

const HowCanIHelpDiv = styled.div`
  padding: ${({ theme }) => theme.spacing.normal};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.font.standard.size};
  font-weight: ${({ theme }) => theme.typography.font.standard.weight};
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const ButtonsAndInputBoxDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UserInputDiv = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.grey[5]};
`;

export interface UserInputBoxProps {
  input: string;
  setInput: (input: string) => void;
  isReady: boolean;
  onSubmit: (input: string) => Promise<void>;
}

const UserInputBox = ({
  input,
  setInput,
  isReady,
  onSubmit,
  output,
  callCompletionCallback,
  isCurrentlyCompleting,
  onClickAbort,
}: UserInputBoxProps & DialogButtonsProps): ReactElement => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const handleEnter = React.useCallback(
    async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (
        e.key === 'Enter' &&
        input.length > 0 &&
        input[input.length - 1] !== '\\' &&
        !e.shiftKey
      ) {
        e.preventDefault();
        if (!isReady) return;
        await onSubmit(input);
      }
    },
    [input, onSubmit, isReady],
  );

  return (
    <UserInputDiv>
      <HowCanIHelpDiv>
        <AiAgent fill={theme.colors.brand.primary.base} />
        {t`How can I help?`}
      </HowCanIHelpDiv>
      <ButtonsAndInputBoxDiv>
        <DialogButtons
          output={output}
          callCompletionCallback={callCompletionCallback}
          isCurrentlyCompleting={isCurrentlyCompleting}
          onClickAbort={onClickAbort}
          isReady={isReady}
          onSubmit={onSubmit}
        />
        <UserInputBoxDiv>
          <UserInput
            value={input}
            onFocus={() =>
              dispatch(uiFlagsActions.setUIFlag({ textInputFocused: true }))
            }
            onBlur={() =>
              dispatch(uiFlagsActions.setUIFlag({ textInputFocused: false }))
            }
            onChange={(e) => {
              setInput(e.target.value);
            }}
            onKeyDown={handleEnter}
            placeholder={t`Ask a question or give me a task`}
          />
          <Button
            disabled={!isReady || input.length === 0}
            Icon={ArrowUp}
            variant={ButtonVariants.SmallTertiary}
            onClick={() => onSubmit(input)}
            testId="chat-submit-button"
          />
        </UserInputBoxDiv>
      </ButtonsAndInputBoxDiv>
    </UserInputDiv>
  );
};

export default UserInputBox;
