import React from 'react';
import { useGetSimulationProcessResultsReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { GetSimulationProcessResultsReadByUuidApiArg } from 'app/apiGenerated/generatedApiTypes';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { extractErrorMessage } from 'ui/common/notifications/errorFormatUtils';
import { dataExplorerPlotDataActions } from 'app/slices/dataExplorerPlotDataSlice';

export function useDataExplorerData({
  modelId,
  simulationId,
  traceId,
  tracePath,
  fromTime,
  toTime,
}: {
  modelId: string;
  simulationId: string;
  traceId: string;
  tracePath: string;
  fromTime?: number;
  toTime?: number;
}) {
  const dispatch = useAppDispatch();

  const downsamplingAlgorithm = useAppSelector(
    (state) => state.visualizer.downsamplingAlgorithm,
  );

  const requestBody: GetSimulationProcessResultsReadByUuidApiArg = {
    threshold: 5000,
    downsamplingAlgorithm:
      downsamplingAlgorithm === 'none' ? undefined : downsamplingAlgorithm,
    fromTime,
    toTime,
    modelUuid: modelId,
    simulationUuid: simulationId,
    files: tracePath,
  };

  const { data, error } =
    useGetSimulationProcessResultsReadByUuidQuery(requestBody);

  // Update the loader state so we know this trace is ready to be fetched.
  React.useEffect(() => {
    if (data) {
      dispatch(
        dataExplorerPlotDataActions.updateTraceLoadState({
          traceId,
          tracePath,
          s3_url: data.s3_urls[0],
        }),
      );
    }
  }, [dispatch, traceId, tracePath, data]);

  // Update the loader state so we know this trace has an error and we shouldn't wait for it.
  React.useEffect(() => {
    if (error) {
      dispatch(
        dataExplorerPlotDataActions.updateTraceLoadState({
          traceId,
          tracePath,
          errorMessage: extractErrorMessage(error),
        }),
      );
    }
  }, [dispatch, traceId, tracePath, error]);
}
