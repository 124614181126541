import { useGetSimulationsForUserReadAllQuery } from 'app/enhancedApi';

export function useSimulations() {
  const { data, isLoading } = useGetSimulationsForUserReadAllQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return {
    isLoadingSimulations: isLoading,
    simulations: data,
  };
}
