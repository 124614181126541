import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { ChatMessage, ChatMessageRole } from 'app/third_party_types/chat-types';
import React, { ReactElement } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Comment, Stop } from 'ui/common/Icons/Standard';
import Reload from 'ui/common/Icons/Standard/Reload';

const StyledButton = styled(Button)`
  box-shadow: ${({ theme }) => theme.shadows.higher};
  z-index: 1;
  margin: ${({ theme }) => theme.spacing.small};
  font-weight: ${({ theme }) => theme.typography.font.standardHighlight.weight};
  font-size: ${({ theme }) => theme.typography.font.standardHighlight.size};
`;

const UserInputFadeContainerDiv = styled.div`
  width: 100%;
  height: 100%;
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-bottom: ${({ theme }) => theme.spacing.normal};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const SystemButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const SuggestionButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  > button {
    outline: 1px solid ${({ theme }) => theme.colors.brand.primary.lighter};
    stroke: ${({ theme }) => theme.colors.brand.primary.lighter};

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.brand.primary.base};
    }
  }
`;

export interface DialogButtonsProps {
  output: ChatMessage[];
  callCompletionCallback: (output: ChatMessage[]) => Promise<void>;
  isCurrentlyCompleting: boolean;
  onClickAbort: () => void;
  isReady: boolean;
  onSubmit: (suggestion: string) => void;
}

const DialogButtons = ({
  output,
  callCompletionCallback,
  isCurrentlyCompleting,
  onClickAbort,
  isReady,
  onSubmit,
}: DialogButtonsProps): ReactElement => {
  const onRepeatQuestion = React.useCallback(async () => {
    if (output.length < 1) return;

    const newOutput = [...output];
    while (
      newOutput.length > 0 &&
      newOutput[newOutput.length - 1].role !== ChatMessageRole.User
    ) {
      newOutput.splice(newOutput.length - 1, 1);
    }
    if (newOutput[newOutput.length - 1].role === ChatMessageRole.User) {
      await callCompletionCallback(newOutput);
    }
  }, [output, callCompletionCallback]);

  return (
    <UserInputFadeContainerDiv>
      {!isCurrentlyCompleting && (
        <SuggestionButtonsDiv>
          {output.length > 1 && (
            <StyledButton
              disabled={!isReady}
              variant={ButtonVariants.SmallSecondary}
              Icon={Comment}
              onClick={() => onSubmit?.('Ok, keep going')}>
              {t`Ok, keep going`}
            </StyledButton>
          )}
          {output.length <= 1 && (
            <StyledButton
              disabled={!isReady}
              variant={ButtonVariants.SmallSecondary}
              Icon={Comment}
              onClick={() => onSubmit?.('What does this model do?')}>
              {t`What does this model do?`}
            </StyledButton>
          )}
          <StyledButton
            disabled={!isReady}
            variant={ButtonVariants.SmallSecondary}
            Icon={Comment}
            onClick={() => onSubmit?.('Show me the equations for this model')}>
            {t`Show me the equations for this model`}
          </StyledButton>
          <StyledButton
            disabled={!isReady}
            variant={ButtonVariants.SmallSecondary}
            Icon={Comment}
            onClick={() => onSubmit?.('Run a simulation and plot the results')}>
            {t`Run a simulation and plot the results`}
          </StyledButton>
        </SuggestionButtonsDiv>
      )}
      <SystemButtonsDiv>
        {isCurrentlyCompleting ? (
          <StyledButton
            variant={ButtonVariants.SmallSecondary}
            Icon={Stop}
            onClick={onClickAbort}>
            {t`Stop generating`}
          </StyledButton>
        ) : (
          output.length > 0 && (
            <StyledButton
              disabled={!isReady}
              variant={ButtonVariants.SmallSecondary}
              Icon={Reload}
              onClick={onRepeatQuestion}
              testId="repeat-question-button">
              {t`Repeat question`}
            </StyledButton>
          )
        )}
      </SystemButtonsDiv>
    </UserInputFadeContainerDiv>
  );
};

export default DialogButtons;
