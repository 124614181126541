// eslint-disable-next-line import/no-unassigned-import
import '@glideapps/glide-data-grid/dist/index.css';
import { t } from '@lingui/macro';
import { apiS3UploadDataFile } from 'app/apiData';
import {
  useGetRequirementsQuery,
  usePostRequirementsImportMutation,
  usePostRequirementsImportProcessMutation,
} from 'app/apiGenerated/generatedApi';
import {
  PostRequirementsImportApiArg,
  PostRequirementsImportApiResponse,
} from 'app/apiGenerated/generatedApiTypes';
import { useCallback, useRef } from 'react';
import { Upload } from 'ui/common/Icons/Standard';
import { useModal } from 'ui/common/Modal/useModal';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { UploadFileInput } from 'ui/dashboard/projectDetail/ProjectDetailImportButton';
import { NavbarButton } from 'ui/navbar/NavbarButtons';
import ReimportRequirementsModal from './ReimportRequirementsModal';

const RequirementsImportButton = ({
  projectUuid,
  enabled,
}: {
  projectUuid: string;
  enabled: boolean;
}) => {
  const { showError, showCompletion } = useNotifications();
  const { triggerModal } = useModal();

  // If the navbar hadn't been split out of the page component tree, the table and this button would share state.
  const { data, isFetching } = useGetRequirementsQuery({
    projectUuid,
    expand: true,
  });

  const requirements = data?.items ?? [];

  const [uploadRequirements] = usePostRequirementsImportMutation();
  const [processRequirements] = usePostRequirementsImportProcessMutation();

  const importRequirements = useCallback(
    async (request: PostRequirementsImportApiArg, file: File) => {
      try {
        const data: PostRequirementsImportApiResponse =
          await uploadRequirements(request).unwrap();

        if (!data.url) {
          // Keeping this to match the other upload error formats.
          throw new Error(
            t({
              id: 'projectApi.importIntoProject.importProjectDestinationError',
              message: 'Unable to find upload destination.',
            }),
          );
        }

        await apiS3UploadDataFile(data.url, 'text/csv', file);
        await processRequirements({
          projectUuid: request.projectUuid,
          importUuid: data.uuid,
        }).unwrap(); // access error or success immediately

        showCompletion(
          t({
            id: 'projectApi.importIntoProject.importProjectSuccess',
            message: 'Imported Requirements from CSV',
          }),
        );
      } catch (error) {
        showError(
          t({
            id: 'projectApi.importIntoProject.processImportError',
            message: 'Unable to import Requirements into project.',
          }),
          error,
        );
      }
    },
    [showError, processRequirements, showCompletion, uploadRequirements],
  );

  const importProjectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!projectUuid || !file) {
      return null;
    }

    importRequirements(
      {
        projectUuid,
      },
      file,
    );
  };
  const importReqRef = useRef<HTMLInputElement>(null);

  const triggerImport = () => {
    importReqRef.current?.click();
  };

  const triggerReimportModal = () => {
    triggerModal(
      <ReimportRequirementsModal onConfirm={triggerImport} />,
      t({
        id: 'reimportRequirementsModal.title',
        message: 'Import Requirements?',
      }),
    );
  };

  return (
    <>
      <NavbarButton
        tooltipText={t({
          id: 'navbar.requirements.import',
          message: 'Import Requirements from a CSV',
        })}
        Icon={Upload}
        isEnabled={enabled && !isFetching}
        onClickHandler={
          requirements.length > 0 ? triggerReimportModal : triggerImport
        }
        testId="navbar-requirements-import-button"
      />
      <UploadFileInput
        type="file"
        ref={importReqRef}
        accept=".csv"
        onChange={(e) => {
          importProjectFiles(e);
          e.target.value = '';
        }}
      />
    </>
  );
};

export default RequirementsImportButton;
