import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import { requiredRules } from 'ui/common/Input/inputValidation';
import { isValidParameterNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsRow,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';

const ModelParameterDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const modelParameters = useAppSelector(
    (state) => state.model.present?.parameters,
  );

  const addNewParameter = () => {
    dispatch(modelActions.addModelParameter({}));
  };

  const changeParamValue = (index: number, value: string) => {
    dispatch(modelActions.setModelParameterValue({ index, value }));
  };

  const onParamDelete = (index: number) => {
    dispatch(modelActions.removeModelParameter({ index }));
  };

  const changeParamName = (index: number) => (name: string) => {
    dispatch(modelActions.setModelParameterName({ index, name }));
  };

  return (
    <>
      <SectionHeading
        testId="model-parameters"
        onButtonClick={canEditCurrentModelVersion ? addNewParameter : undefined}
        buttonTooltip={t({
          id: 'blockDetails.addModelParametersButtonTooltip',
          message: 'Add model parameter',
        })}>
        {t({
          id: 'modelRenderer.propertiesSidebar.modelParameters.heading',
          message: 'Model parameters',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        {modelParameters.map((param, index, _array) => (
          <DetailsSection key={index} vertical>
            <DetailsRow>
              <DetailsInput
                grow
                testId={`model-param-name-${index}`}
                value={param.name}
                onSubmitValue={changeParamName(index)}
                disabled={!canEditCurrentModelVersion}
                validationRules={isValidParameterNameRuleSet(
                  modelParameters,
                  index,
                )}
              />
              {canEditCurrentModelVersion ? (
                <Button
                  testId={`model-param-remove-button-${index}`}
                  variant={ButtonVariants.LargeTertiary}
                  Icon={Remove}
                  onClick={() => onParamDelete(index)}
                />
              ) : null}
            </DetailsRow>
            <DetailsInput
              allowMultiline
              isMonospaced
              grow
              testId={`model-param-value-${index}`}
              value={param.value}
              onSubmitValue={(s) => changeParamValue(index, s)}
              disabled={!canEditCurrentModelVersion}
              hasBorder
              validationRules={requiredRules}
            />
          </DetailsSection>
        ))}
      </DetailInputRowsSection>
    </>
  );
};

export default ModelParameterDetails;
