import styled from '@emotion/styled';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  useGetChatSessionLastQuery,
  usePostChatAbortMutation,
  usePostChatSessionMutation,
  usePutChatSessionMutation,
} from 'app/apiGenerated/generatedApi';
import { TagType, enhancedApi } from 'app/enhancedApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { OpenAiModels, callCompletion } from 'app/openai';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { ChatMessage, ChatMessageRole } from 'app/third_party_types/chat-types';
import React, { ReactElement, useRef, useState } from 'react';
import { usePython } from 'ui/common/PythonProvider';
import { WebSocketContext, useWebSocket } from 'ui/common/WebSocketProvider';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { useAppParams } from 'util/useAppParams';
import BelowUserInputToolbar from './assistant/BelowUserInputToolbar';
import ChatContent, {
  MessageEdit,
  isAssistantSelfDebug,
} from './assistant/ChatContent';
import { useFunctions } from './assistant/GptFunctions';
import { CHAT_WIDTH, INPUT_BAR_HEIGHT } from './assistant/Sizings';
import UserInputBox from './assistant/UserInputBox';
import { useAuthorizationCheck } from './assistant/useAuthorizationCheck';
import { usePlotCache } from './assistant/usePlotCache';

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[5]};
  isolation: isolate;
  position: relative;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.large};
  margin: 0px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

const ChatGptWrapper = styled.div`
  overflow: auto;
  max-width: ${CHAT_WIDTH}px;
  margin-left: calc(max((100% - ${CHAT_WIDTH}px) / 2, 20px));
  margin-bottom: ${INPUT_BAR_HEIGHT * 2}px;

  font-size: ${({ theme }) => theme.typography.font.standard.size};
  font-weight: ${({ theme }) => theme.typography.font.standard.weight};
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};

  overflow-anchor: none;
`;

const OutputScrollAnchor = styled.div`
  height: ${INPUT_BAR_HEIGHT * 2}px;
  overflow-anchor: auto;
`;

const UserInputContainer = styled.div`
  position: absolute;
  max-width: ${CHAT_WIDTH}px;
  bottom: ${({ theme }) => theme.spacing.normal};
  left: calc(max((100% - ${CHAT_WIDTH}px) / 2, 20px));

  display: flex;
  flex-direction: column;
`;

const GradientFade = styled.div`
  width: 100%;
  bottom: 0px;
  height: ${INPUT_BAR_HEIGHT}px;
  z-index: 0;

  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.colors.grey[5]} 0%,
    #f1f3f300 100%
  );
`;

const useCallCompletionCallbackWithWebSocket = (
  setOutput: (o: ChatMessage[]) => void,
  setIsCurrentlyCompleting: (arg0: boolean) => void,
  abortController: AbortController,
  temperature: number,
  aiModelId: string,
  addPlot: (plot: string) => number,
  webSocket: WebSocketContext,
  setStreamUuid: (uuid: string) => void,
) => {
  const { showError } = useNotifications();

  const functions = useFunctions(addPlot);
  const dispatch = useAppDispatch();

  const callCompletionWrapper = React.useCallback(
    async (prompt: ChatMessage[]) => {
      setIsCurrentlyCompleting(true);
      try {
        await callCompletion(
          prompt,
          setOutput,
          webSocket,
          abortController.signal,
          (failed, finishReason) => {
            if (failed || (finishReason && finishReason !== 'stop')) {
              showError(`ChatGPT finished early: ${finishReason}`);
            }
            setIsCurrentlyCompleting(false);
          },
          temperature / 100,
          aiModelId,
          functions,
          setStreamUuid,
        );
      } catch (e) {
        console.error(e);
        setIsCurrentlyCompleting(false);
        showError('Request to AI failed:', e);
      }

      dispatch(
        enhancedApi.util.invalidateTags([{ type: TagType.UserStatistics }]),
      );
    },
    [
      abortController.signal,
      aiModelId,
      dispatch,
      functions,
      setIsCurrentlyCompleting,
      setOutput,
      setStreamUuid,
      showError,
      temperature,
      webSocket,
    ],
  );

  return callCompletionWrapper;
};

const makeUserPrompt = (prompt: string): ChatMessage => ({
  role: ChatMessageRole.User,
  content: prompt,
  originalUserContent: prompt,
});

const restoreOriginalUserContent = (messages: ChatMessage[]): ChatMessage[] =>
  messages.map((m) => ({
    ...m,
    content: m.originalUserContent ? m.originalUserContent : m.content,
  }));

const useSendPromptCallback = (
  setInput: (i: string) => void,
  output: ChatMessage[],
  setOutput: (o: ChatMessage[]) => void,
  callCompletionCallback: (prompt: ChatMessage[]) => Promise<void>,
  createChatSession: () => void,
) => {
  const sendPrompt = React.useCallback(
    async (prompt: string): Promise<void> => {
      createChatSession();
      setInput('');
      if (prompt === '') return;

      const originalOutput = restoreOriginalUserContent(output);
      const lastPrompt: ChatMessage = makeUserPrompt(prompt);
      const newOutput = [...originalOutput, lastPrompt];
      setOutput(newOutput);
      await callCompletionCallback(newOutput);
    },
    [createChatSession, setInput, output, setOutput, callCompletionCallback],
  );

  return sendPrompt;
};

const removeDialogTurn = (
  messages: ChatMessage[],
  index: number,
  removePlot: (index: number) => void,
) => {
  const newMessages = [...messages];
  if (newMessages[index].role === ChatMessageRole.Assistant) {
    const match = newMessages[index].content.match(/\[\[plot_id:(\d+)\]\]/);
    const plotIndex = match ? match[1] : undefined;
    if (plotIndex) {
      removePlot(parseInt(plotIndex));
    }
    newMessages.splice(index, 1);

    // Remove the previous function calls and assistant self debug messages.
    index--;
    while (
      index > 0 &&
      (newMessages[index].role === ChatMessageRole.Function ||
        isAssistantSelfDebug(index, newMessages))
    ) {
      newMessages.splice(index, 1);
      index--;
    }
  } else {
    newMessages.splice(index, 1);
  }
  return newMessages;
};

const ChatGpt = ({
  forceShowAdvancedOptions,
}: {
  forceShowAdvancedOptions?: boolean;
}): ReactElement => {
  /**
   * Local states
   */
  const scrollAnchor = useRef<HTMLDivElement | null>(null);
  const [autoScroll, setAutoScroll] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(
    forceShowAdvancedOptions ?? false,
  );
  const [isCurrentlyCompleting, setIsCurrentlyCompleting] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [input, setInput] = useState('');
  const [temperature, setTemperature] = useState(0);
  const [streamUuid, setStreamUuid] = useState('');
  const [aiModelId, setAiModelId] = useState(OpenAiModels[0].id);
  const [abortController, setAbortController] = React.useState(
    new AbortController(),
  );
  const [chatSessionId, setChatSessionId] = useState<string | undefined>();
  const { plots, addPlot, removePlot, setPlots } = usePlotCache();

  /**
   * External states
   */
  const dispatch = useAppDispatch();
  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present?.currentSubmodelPath,
  );
  const { modelId, projectId } = useAppParams();
  const webSocket = useWebSocket();
  const { isReady: pythonIsReady } = usePython();
  const { isAuthorized } = useAuthorizationCheck();

  /**
   * Callbacks
   * */
  const { showError } = useNotifications();
  const [callCreateChatSession] = usePostChatSessionMutation();
  const [callUpdateChatSession] = usePutChatSessionMutation();
  const [callChatAbort] = usePostChatAbortMutation();

  const callCompletionCallback = useCallCompletionCallbackWithWebSocket(
    setMessages,
    setIsCurrentlyCompleting,
    abortController,
    temperature,
    aiModelId,
    addPlot,
    webSocket,
    setStreamUuid,
  );

  const {
    currentData: chatSession,
    isFetching: isFetchingLastChatSession,
    isUninitialized: isUninitializedLastChatSession,
    refetch: refetchLastChatSession,
    isError: isLastChatSessionError,
    error: lastChatSessionError,
  } = useGetChatSessionLastQuery(
    {
      projectUuid: projectId ?? '',
      modelUuid: modelId ?? '',
      subdiagramPath:
        currentSubmodelPath.length > 0
          ? JSON.stringify(currentSubmodelPath)
          : undefined,
    },
    { skip: !modelId || !projectId },
  );

  const onClickAbort = React.useCallback(() => {
    callChatAbort({ streamUuid }).catch((e) => {
      showError(`Failed to abort the chat session.`);
    });
    abortController.abort();
    setAbortController(new AbortController());
  }, [abortController, callChatAbort, showError, streamUuid]);

  const createChatSession = React.useCallback(async () => {
    try {
      await callCreateChatSession({
        chatSessionCreateRequest: {
          project_uuid: projectId ?? '',
          model_uuid: modelId ?? '',
          subdiagram_path: currentSubmodelPath
            ? JSON.stringify(currentSubmodelPath)
            : undefined,
        },
      }).unwrap();
      refetchLastChatSession();
    } catch (e) {
      showError(
        `Failed to create a chat session. Your conversation will not be saved.`,
      );
    }
  }, [
    callCreateChatSession,
    currentSubmodelPath,
    modelId,
    projectId,
    showError,
    refetchLastChatSession,
  ]);

  const sendPrompt = useSendPromptCallback(
    setInput,
    messages,
    setMessages,
    callCompletionCallback,
    chatSessionId ? () => {} : createChatSession,
  );

  const restartConversation = React.useCallback(() => {
    for (let i = 0; i < plots.length; i++) {
      removePlot(i);
    }
    setMessages([]);
    setChatSessionId(undefined);
  }, [removePlot, plots]);

  const saveChatSession = React.useCallback(
    (sessionId: string, messages: ChatMessage[], plots: string[]) => {
      if (!sessionId) return;
      callUpdateChatSession({
        chatSession: {
          session_id: sessionId,
          messages,
          plots: plots.map((p, i) => ({ id: `${i}`, value: p })),
        },
      }).catch((e) => {
        showError(
          `Failed to update the chat session. Your conversation will not be saved.`,
        );
      });
    },
    [callUpdateChatSession, showError],
  );

  const onMessageEdit = React.useCallback(
    (edit: MessageEdit, index: number) => {
      const newMessages = [...messages];
      const editedMessage = { ...newMessages[index] };
      if (edit.functionArgs && 'code' in edit.functionArgs) {
        const args = JSON.parse(newMessages[index].functionArgs || '');
        args.code = (edit.functionArgs as any).code;
        editedMessage.functionArgs = JSON.stringify(args);
      }
      editedMessage.content =
        edit.content !== undefined ? edit.content : editedMessage.content;
      editedMessage.functionResult =
        edit.functionResult ?? editedMessage.functionResult;
      newMessages[index] = editedMessage;
      setMessages(newMessages);
    },
    [messages],
  );

  const onRemoveButtonClick = React.useCallback(
    (index) => {
      setMessages([...removeDialogTurn(messages, index, removePlot)]);
    },
    [messages, removePlot],
  );

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    const bottom =
      target.scrollHeight - target.scrollTop - target.clientHeight < 100;
    setAutoScroll(bottom);
  };

  /**
   * Effects
   * */
  React.useEffect(() => {
    dispatch(uiFlagsActions.requestLoadPython());
  }, [dispatch]);

  // Initialize with last chat session from the server
  React.useEffect(() => {
    if (!chatSession) {
      return;
    }

    setChatSessionId(chatSession?.session_id);

    if (chatSession.messages.length > 0) {
      // FIXME: rtk-query-codegen-openapi does not generate enum which triggers a
      // ts error here.
      // Remove this when the following PR is merged and we update reduxjs:
      // https://github.com/reduxjs/redux-toolkit/pull/2854
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setMessages(chatSession.messages);
    }

    if (chatSession.plots) {
      setPlots(chatSession.plots.map((p) => p.value));
    }
  }, [chatSession, setPlots]);

  // When there is no session, set to the default conversation starter
  React.useEffect(() => {
    if (
      chatSession ||
      isFetchingLastChatSession ||
      isUninitializedLastChatSession
    )
      return;
    setChatSessionId(undefined);
    setMessages([]);
  }, [chatSession, isFetchingLastChatSession, isUninitializedLastChatSession]);

  // Save the chat session when there is a new message or plot
  React.useEffect(() => {
    if (!chatSessionId || messages.length < 1 || isCurrentlyCompleting) return;
    saveChatSession(chatSessionId, messages, plots);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // chatSessionId, // we don't want to trigger saving when chatSessionId changes
    isCurrentlyCompleting,
    messages,
    plots,
    saveChatSession,
  ]);

  // Refetch the last chat session when the model changes
  React.useEffect(() => {
    refetchLastChatSession();
  }, [refetchLastChatSession, modelId, projectId, currentSubmodelPath]);

  React.useEffect(() => {
    if (
      isLastChatSessionError &&
      (lastChatSessionError as FetchBaseQueryError).status !== 404
    ) {
      showError(
        'Error fetching last chat session. Your conversation will not be saved.',
      );
    }
  }, [isLastChatSessionError, lastChatSessionError, showError]);

  React.useEffect(() => {
    if (autoScroll && scrollAnchor.current !== null) {
      scrollAnchor.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [autoScroll]);

  React.useEffect(() => {
    if (autoScroll && scrollAnchor.current !== null) {
      scrollAnchor.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, autoScroll]);

  const isReady =
    !isCurrentlyCompleting &&
    !isFetchingLastChatSession &&
    webSocket.isOpen &&
    pythonIsReady;

  return (
    <Background>
      <ChatGptWrapper onScroll={handleScroll}>
        <ChatContent
          output={messages}
          onRemoveButtonClick={
            isCurrentlyCompleting ? undefined : onRemoveButtonClick
          }
          onMessageEdit={isCurrentlyCompleting ? undefined : onMessageEdit}
          showAdvancedOptions={showAdvancedOptions}
          isCurrentlyCompleting={isCurrentlyCompleting}
          plots={plots}
          addPlot={addPlot}
        />
        <OutputScrollAnchor ref={scrollAnchor} />
        {/* <StayScrolledToBottom active={isCurrentlyCompleting} /> */}
      </ChatGptWrapper>
      <UserInputContainer>
        <GradientFade />
        <UserInputBox
          input={input}
          setInput={setInput}
          isReady={isReady && !!isAuthorized}
          onSubmit={sendPrompt}
          output={messages}
          callCompletionCallback={callCompletionCallback}
          isCurrentlyCompleting={isCurrentlyCompleting}
          onClickAbort={onClickAbort}
          data-cy="chat-gpt-input"
        />
        <BelowUserInputToolbar
          showAdvancedOptions={showAdvancedOptions}
          setShowAdvancedOptions={setShowAdvancedOptions}
          aiModelId={aiModelId}
          setAiModelId={setAiModelId}
          temperature={temperature}
          setTemperature={setTemperature}
          restartConversationOnClick={restartConversation}
        />
      </UserInputContainer>
      {isReady && (
        <div data-test-id="python-ready" style={{ display: 'none' }} />
      )}
    </Background>
  );
};

export default ChatGpt;
