import React from 'react';

import Breadcrumb from 'ui/common/Breadcrumb/Breadcrumb';
import { useAppParams } from 'util/useAppParams';

import BreadcrumbLink from 'ui/common/Breadcrumb/BreadcrumbLink';
import { splitFullName } from 'util/fileUtils';

const NotebookBreadcrumb: React.FC = () => {
  const { fileName = '' } = useAppParams();

  const [name] = splitFullName(fileName);

  return (
    <Breadcrumb>
      <BreadcrumbLink displayName={name} />
    </Breadcrumb>
  );
};

export default NotebookBreadcrumb;
