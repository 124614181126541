import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useModelSimulationResults } from 'app/api/useModelSimulationResults';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { projectActions } from 'app/slices/projectSlice';
import { userPreferencesActions } from 'app/slices/userPreferencesSlice';
import React from 'react';
import { Check, Play, RunAll, Stop } from 'ui/common/Icons/Standard';
import { useModal } from 'ui/common/Modal/useModal';
import { Spinner } from 'ui/common/Spinner';
import { useModelSimulationControls } from 'ui/modelEditor/useModelSimulationControls';
import { SignalTimeModePoller } from 'ui/navbar/SignalTimeModePoller';
import { useAppParams } from 'util/useAppParams';
import EnsembleSimModal from './EnsembleSimModal';
import { NavbarButton } from './NavbarButtons';
import { SignalTypePoller } from './SignalTypePoller';
import SimulationStatusPoller from './SimulationStatusPoller';
import {
  getDownloadDataButtonIcon,
  useDownloadResults,
} from './useDownloadResults';

const DefaultCheck = styled(Check)`
  & > * {
    fill: ${({ theme }) => theme.colors.grey[30]};
  }
`;

const DisabledCheck = styled(Check)`
  & > * {
    fill: ${({ theme }) => theme.colors.grey[70]};
  }
`;

const DefaultRun = styled(Play)`
  & > * {
    fill: ${({ theme }) => theme.colors.grey[30]};
  }
`;

const DisabledRun = styled(Play)`
  & > * {
    fill: ${({ theme }) => theme.colors.grey[70]};
  }
`;

const DefaultRunAll = styled(RunAll)`
  & > * {
    fill: ${({ theme }) => theme.colors.grey[30]};
  }
`;

const DisabledRunAll = styled(RunAll)`
  & > * {
    fill: ${({ theme }) => theme.colors.grey[70]};
  }
`;

function getCheckButtonIcon(
  enableCheckButton: boolean,
  isChecking: boolean,
): React.FC {
  if (isChecking) {
    return Spinner;
  }

  if (enableCheckButton) {
    return DefaultCheck;
  }

  return DisabledCheck;
}

function getRunButtonIcon(
  enableRunButton: boolean,
  isRunning: boolean,
  runAll = false,
): React.FC {
  if (isRunning) {
    return Spinner;
  }

  if (enableRunButton) {
    return runAll ? DefaultRunAll : DefaultRun;
  }

  return runAll ? DisabledRunAll : DisabledRun;
}

export const SimulationControls: React.FC = () => {
  const dispatch = useAppDispatch();
  const { triggerModal } = useModal();
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const { dataDownloadLoading, downloadData } = useDownloadResults();

  const { modelId, versionId } = useAppParams();

  const requiresTimeModeInfo = useAppSelector(
    (state) =>
      state.uiFlags.showRatesInModel || state.uiFlags.showSignalNamesInModel,
  );

  const correlationId = useAppSelector((state) => state.project.correlationId);
  const simulationId = useAppSelector(
    (state) => state.project.simulationSummary?.uuid,
  );
  const simulationSummary = useAppSelector(
    (state) => state.project.simulationSummary,
  );
  const modelName = useAppSelector((state) => state.model.present.name);

  const openEnsembleSimModal = () => {
    triggerModal(<EnsembleSimModal />, t`Run Ensemble Simulation`, {
      preventOverflow: true,
    });
  };

  const {
    shouldPollSimulationStatus,
    isChecking,
    isRunning,
    isStopping,
    enableCheckButton,
    enableRunButton,
    enableStopButton,
    enableDownloadDataButton,
    canLoadSignalResults,
  } = useModelSimulationControls();

  useModelSimulationResults();

  if (versionId) return null;

  return (
    <>
      {/* Compile button */}
      <NavbarButton
        tooltipText={t({
          id: 'navBar.compileModel',
          message: 'Compile',
        })}
        Icon={getCheckButtonIcon(enableCheckButton, isChecking)}
        isEnabled={enableCheckButton}
        onClickHandler={() => {
          dispatch(projectActions.requestCheck({}));
        }}
        testId="navbar-check-model-button"
      />

      {/* Run/Running button */}
      <NavbarButton
        tooltipText={t({
          id: 'simulationStatus.runSimulation',
          message: 'Run',
        })}
        Icon={getRunButtonIcon(enableRunButton, isRunning)}
        isEnabled={enableRunButton}
        onClickHandler={() => {
          dispatch(userPreferencesActions.setSeenBasicSimTutorial());
          dispatch(projectActions.requestRun({}));
        }}
        testId="navbar-run-simulation-button"
      />

      {/* Run/Running button */}
      {developerModeEnabled && (
        <NavbarButton
          tooltipText={t({
            id: 'simulationStatus.runEnsembleSimulation',
            message: 'Run Ensemble Simulation',
          })}
          Icon={getRunButtonIcon(enableRunButton, isRunning, true)}
          isEnabled={enableRunButton}
          onClickHandler={openEnsembleSimModal}
          testId="navbar-run-ensemble-simulation-button"
        />
      )}

      {/* Stop button */}
      {enableStopButton ? (
        <NavbarButton
          tooltipText={t({
            id: 'navBar.stopSimulation',
            message: 'Stop',
          })}
          Icon={isStopping ? Spinner : Stop}
          isEnabled={enableStopButton}
          onClickHandler={() => dispatch(projectActions.requestStop())}
          testId="navbar-stop-simulation-button"
        />
      ) : (
        <NavbarButton
          tooltipText={t({
            id: 'navBar.downloadData',
            message: 'Download simulation results',
          })}
          Icon={getDownloadDataButtonIcon(
            enableDownloadDataButton,
            dataDownloadLoading,
          )}
          isEnabled={
            !!simulationSummary &&
            enableDownloadDataButton &&
            simulationSummary?.results_available
          }
          onClickHandler={() =>
            simulationSummary &&
            downloadData({
              simulationId: simulationSummary.uuid,
              modelId: simulationSummary.model_uuid,
              modelName,
            })
          }
          testId="navbar-download-simulation-results-button"
        />
      )}

      {shouldPollSimulationStatus &&
        modelId &&
        correlationId &&
        simulationId && (
          <SimulationStatusPoller
            modelId={modelId}
            correlationId={correlationId}
            simulationId={simulationId}
          />
        )}
      {modelId && correlationId && simulationId && canLoadSignalResults && (
        <SignalTypePoller
          modelId={modelId}
          correlationId={correlationId}
          simulationId={simulationId}
        />
      )}
      {modelId &&
        correlationId &&
        simulationId &&
        canLoadSignalResults &&
        requiresTimeModeInfo && (
          <SignalTimeModePoller
            modelId={modelId}
            correlationId={correlationId}
            simulationId={simulationId}
          />
        )}
    </>
  );
};
