import { FileType, NOTEBOOK_FILES } from 'ui/notebook/notebookTypes';

export function fileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export function nameToFileType(n: string) {
  const extension = n.slice(n.lastIndexOf('.') + 1);
  for (const [type, data] of Object.entries(NOTEBOOK_FILES)) {
    if (data.extension === extension) {
      return type as FileType;
    }
  }
  throw new Error(`File extension not supported: ${extension}`);
}

export function splitFullName(fullName: string) {
  const extensionIndex = fullName.lastIndexOf('.');
  if (extensionIndex !== -1) {
    const name = fullName.slice(0, extensionIndex);
    const extension = fullName.slice(extensionIndex + 1);
    return [name, extension];
  }
  return [fullName, ''];
}

export function downloadUrlFile(href: string, fileName: string) {
  const a = document.createElement('a');
  a.href = href;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function downloadBlobFile(blob: Blob, fileName: string) {
  const href = window.URL.createObjectURL(blob);
  downloadUrlFile(href, fileName);
}

export function downloadResource(url: string, filename: string): Promise<void> {
  return fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
    mode: 'cors',
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      downloadUrlFile(blobUrl, filename);
    });
}
