import { autoType, csvParse, DSVParsedArray } from 'd3-dsv';
import { group, merge } from 'd3-array';
import { DataBounds, PlotDataRow } from 'ui/dataExplorer/dataExplorerTypes';

type CsvHeader = 'time' | string;

/**
 * It decides if a list of signals can be grouped or not
 * @param rows array of signal rows
 * @returns
 */
function canBeGrouped(rows: DSVParsedArray<PlotDataRow>[]) {
  // If there is only one signal, there is no need for grouping
  if (rows.length === 1) {
    return false;
  }
  // If any signal has a different amount of time datapoints, we know it won't be compatible
  if (!rows.every((signalRows) => signalRows.length === rows[0].length)) {
    return false;
  }

  // If we find one time that doesn't match with the first signal, it can't be grouped
  for (let i = 1; i < rows.length; i++) {
    let signalRows = rows[i];
    for (let j = 0; j < signalRows.length; j++) {
      let signalRow = signalRows[j];
      if (signalRow.time !== rows[0][j].time) {
        return false;
      }
    }
  }
  return true;
}

/**
 * It groups all the CSV text files into something readable for ECharts.
 * @example
 * Given a `texts` parameter with two text files:
 * time,signalA[0]
 * 0,0
 * 1,2
 * ....
 * And:
 * time,signalB[0]
 * 0,9
 * 1,10
 *
 * It will return:
 * [{ time: 0, signalA: 0, signalB: 9}, { time: 1, signalA: 2, signalB: 10}]
 *
 * @param texts The list of CSV files (as strings)
 * @returns The grouped results by time
 */
export function groupFileData(texts: string[]): PlotDataRow[] {
  const jsons = texts.map((text) =>
    csvParse<PlotDataRow, CsvHeader>(text, autoType),
  );
  const rows: PlotDataRow[] = merge(jsons);
  if (canBeGrouped(jsons)) {
    let result = [];
    const groups = group(rows, (d) => d.time);
    for (const group of groups.values()) {
      result.push({
        ...group.reduce((prev, curr) => ({ ...prev, ...curr }), { time: 0 }),
      });
    }
    return result;
  }
  return rows;
}

export function getDataBounds(data: PlotDataRow[]): DataBounds {
  let startX = Number.MAX_SAFE_INTEGER;
  let endX = Number.MIN_SAFE_INTEGER;
  let startY = Number.MAX_SAFE_INTEGER;
  let endY = Number.MIN_SAFE_INTEGER;

  for (const row of data) {
    for (const [key, value] of Object.entries(row)) {
      if (key === 'time') {
        if (value < startX) {
          startX = value;
        }
        if (value > endX) {
          endX = value;
        }
      } else {
        if (value < startY) {
          startY = value;
        }
        if (value > endY) {
          endY = value;
        }
      }
    }
  }

  return {
    startX,
    endX,
    startY,
    endY,
  };
}
