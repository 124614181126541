import styled from '@emotion/styled/macro';
import { Trans } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React, { ReactElement } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ChevronDown } from 'ui/common/Icons/Standard';
import Menu from 'ui/common/Menu/Menu';
import { ItemType, ShortcutModifier } from 'ui/common/Menu/menuItemTypes';
import { OpSys, detectedOS } from 'util/detectOS';
import { useAppParams } from 'util/useAppParams';

const altOrOption = detectedOS === OpSys.macOS ? '⌥' : 'Alt';

export const numberToFixedPrecisionNumberString = (
  num: number,
  prec: number,
) => {
  if (!isNaN(num)) {
    return num.toFixed(prec);
  }

  return undefined;
};

const LayerMenuButton = styled(Button)`
  color: ${({ theme }) => theme.colors.text.secondary};
  pointer-events: auto;
  padding: 0;
  height: 32px;
`;

const LayerMenuMenuComp = styled(Menu)`
  margin-right: ${({ theme }) => theme.spacing.normal};
`;

export const LayersMenu = (): ReactElement => {
  const dispatch = useAppDispatch();

  const { versionId } = useAppParams();
  const viewingAVersion = Boolean(versionId);

  React.useEffect(() => {
    if (viewingAVersion) {
      dispatch(
        uiFlagsActions.setUIFlag({
          showRatesInModel: false,
          showDatatypesInModel: false,
        }),
      );
    }
  }, [dispatch, viewingAVersion]);

  const showingLineLabels = useAppSelector(
    (state) => state.uiFlags.showSignalNamesInModel,
  );
  const showingRates = useAppSelector(
    (state) => state.uiFlags.showRatesInModel,
  );
  const showingDimensions = useAppSelector(
    (state) => state.uiFlags.showDatatypesInModel,
  );

  const toggleLineLabels = () =>
    dispatch(uiFlagsActions.toggleUIFlag('showSignalNamesInModel'));

  const toggleDimensions = () => {
    dispatch(uiFlagsActions.toggleUIFlag('showDatatypesInModel'));
    dispatch(uiFlagsActions.setUIFlag({ showRatesInModel: false }));
  };

  const toggleRates = () => {
    dispatch(uiFlagsActions.toggleUIFlag('showRatesInModel'));
    dispatch(uiFlagsActions.setUIFlag({ showDatatypesInModel: false }));
  };

  return (
    <LayerMenuMenuComp
      items={[
        {
          type: ItemType.Button,
          content: showingLineLabels ? (
            <Trans id="zoomMenu.hideLineLabels">Hide line labels</Trans>
          ) : (
            <Trans id="zoomMenu.showLinelabels">Show line labels</Trans>
          ),
          onClick: toggleLineLabels,
          shortcut: {
            keyStrings: [altOrOption, 'L'],
            modifier: ShortcutModifier.ALT,
            code: 'L',
          },
        },
        {
          type: ItemType.Button,
          disabled: viewingAVersion,
          content: showingRates ? (
            <Trans id="zoomMenu.hideRates">Hide rates</Trans>
          ) : (
            <Trans id="zoomMenu.showRates">Show rates</Trans>
          ),
          onClick: toggleRates,
          shortcut: {
            keyStrings: [altOrOption, 'R'],
            modifier: ShortcutModifier.ALT,
            code: 'R',
          },
        },
        {
          type: ItemType.Button,
          disabled: viewingAVersion,
          content: showingDimensions ? (
            <Trans id="zoomMenu.hideDimensions">Hide dimensions</Trans>
          ) : (
            <Trans id="zoomMenu.showDimensions">Show dimensions</Trans>
          ),
          onClick: toggleDimensions,
          shortcut: {
            keyStrings: [altOrOption, 'D'],
            modifier: ShortcutModifier.ALT,
            code: 'D',
          },
        },
      ]}>
      <LayerMenuButton variant={ButtonVariants.SmallSecondary}>
        <img src={`${process.env.PUBLIC_URL}/assets/layers_menu_icon.svg`} />
        <ChevronDown />
      </LayerMenuButton>
    </LayerMenuMenuComp>
  );
};
