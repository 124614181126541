import { ReferenceSubmodel } from 'app/generated_blocks/core/ReferenceSubmodel';

const GRID_SIZE = 8;
const BLOCK_MIN_GRID_WIDTH = 9;
const BLOCK_MIN_GRID_HEIGHT = 8;
const CONTAINER_DEFAULT_GRID_WIDTH = 14;
const CONTAINER_DEFAULT_GRID_HEIGHT = 12;
const REFSUBMODEL_DEFAULT_GRID_WIDTH =
  ReferenceSubmodel.ui_defaults?.grid_width || 0;
const REFSUBMODEL_DEFAULT_GRID_HEIGHT =
  ReferenceSubmodel.ui_defaults?.grid_height || 0;

export const renderConstants = {
  GRID_SIZE,
  BLOCK_MIN_GRID_WIDTH,
  BLOCK_MIN_GRID_HEIGHT,
  BLOCK_MIN_WIDTH: BLOCK_MIN_GRID_WIDTH * GRID_SIZE,
  BLOCK_MIN_HEIGHT: BLOCK_MIN_GRID_HEIGHT * GRID_SIZE,
  CONTAINER_DEFAULT_GRID_WIDTH,
  CONTAINER_DEFAULT_GRID_HEIGHT,
  REFSUBMODEL_DEFAULT_GRID_WIDTH,
  REFSUBMODEL_DEFAULT_GRID_HEIGHT,
  BLOCK_CORNER_RADIUS: 2,
  BLOCK_SHADOW_OFFSET: 1,
  BLOCK_SHADOW_RADIUS: 2,
  PORT_SIZE: 14,
  SIGNAL_PLOTTER_COLLISION_OFFSET_X: 5,
  SIGNAL_PLOTTER_COLLISION_OFFSET_Y: 10,
  SIGNAL_PLOTTER_GRAPHIC_OFFSET_Y: 15,
  SIGNAL_PLOTTER_WIDTH: 16,
  SIGNAL_PLOTTER_HEIGHT: 14,
};
