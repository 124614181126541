import { t } from '@lingui/macro';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { Checkbox } from 'ui/common/Checkbox';
import Input from 'ui/common/Input/Input';
import { isPositiveNumberRule } from 'ui/common/Input/inputValidation';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import {
  DetailInputRowsSection,
  DetailsLabel,
  DetailsRow,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';

interface Props {
  canEdit: boolean;
  submodelInfoReference?: SubmodelInfoUI;
}

export const SubmodelSimulationDetails: React.FC<Props> = ({
  canEdit,
  submodelInfoReference,
}: Props) => {
  const dispatch = useAppDispatch();

  // Get the value from the top level model or from the submodel reference configuration.
  const isAtomic = useAppSelector((state) =>
    submodelInfoReference
      ? submodelInfoReference.submodelConfiguration?.atomic?.value === 'true'
      : state.model.present?.submodelConfiguration?.atomic?.value === 'true',
  );

  const discreteStep = useAppSelector((state) =>
    submodelInfoReference
      ? submodelInfoReference.submodelConfiguration?.discrete_step?.value
      : state.model.present?.submodelConfiguration?.discrete_step?.value,
  );
  const [usingDiscreteStep, setUsingDiscreteStep] = React.useState(
    Boolean(discreteStep),
  );

  const toggleIsAtomic = () => {
    if (!canEdit) return;

    // Don't try to edit the submodel configuration if this is not the top level submodel.
    if (submodelInfoReference) {
      return;
    }

    dispatch(modelActions.setIsAtomic({ isAtomic: !isAtomic }));
  };

  const setDiscreteStep = (value: string) => {
    if (!canEdit) return;

    // Don't try to edit the submodel configuration if this is not the top level submodel.
    if (submodelInfoReference) {
      return;
    }

    if (value) {
      dispatch(modelActions.setDiscreteStep(value));
    } else {
      dispatch(modelActions.unsetDiscreteStep());
    }
  };

  const toggleDiscreteStep = () => {
    if (!canEdit) return;

    // Don't try to edit the submodel configuration if this is not the top level submodel.
    if (submodelInfoReference) {
      return;
    }

    if (usingDiscreteStep) {
      dispatch(modelActions.unsetDiscreteStep());
    }

    setUsingDiscreteStep(!usingDiscreteStep);
  };

  return (
    <>
      <SectionHeading testId="submodel-simulation-details">
        {t({
          id: 'referenceSubmodelDetails.simulation.heading',
          message: 'Simulation',
        })}
      </SectionHeading>

      <DetailInputRowsSection>
        <DetailsSection vertical>
          <DetailsRow>
            <DetailsLabel
              stretch
              disabled={!canEdit || Boolean(submodelInfoReference)}>
              {t({
                id: 'referenceSubmodelDetails.simulation.atomicCheckbox.label',
                message: 'Force execution as a single unit',
              })}
            </DetailsLabel>
            <Checkbox
              isDisabled={!canEdit}
              value={isAtomic}
              onChange={toggleIsAtomic}
            />
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel
              stretch
              disabled={!canEdit || Boolean(submodelInfoReference)}>
              {t({
                id: 'referenceSubmodelDetails.simulation.usingDiscreteStep.label',
                message: 'Use a discrete step',
              })}
            </DetailsLabel>
            <Checkbox
              value={usingDiscreteStep}
              onChange={toggleDiscreteStep}
              isDisabled={!canEdit || Boolean(submodelInfoReference)}
            />
          </DetailsRow>
          {usingDiscreteStep && (
            <DetailsRow>
              <DetailsLabel
                stretch
                disabled={!canEdit || Boolean(submodelInfoReference)}>
                {t({
                  id: 'referenceSubmodelDetails.simulation.discreteStep.label',
                  message: 'Discrete step',
                })}
              </DetailsLabel>
              <Input
                hasBorder
                disabled={!canEdit || Boolean(submodelInfoReference)}
                onChangeText={(v, isValid) => {
                  if (isValid) {
                    setDiscreteStep(v);
                  }
                }}
                value={discreteStep}
                validationRules={[isPositiveNumberRule]}
                testId="submodel-name-textbox"
              />
            </DetailsRow>
          )}
        </DetailsSection>
      </DetailInputRowsSection>
    </>
  );
};
