import { PortSide } from 'app/common_types/PortTypes';
import * as NVG from 'nanovg-js';
import { LINK_COLORS, LINK_COLOR_DISCONNECTED } from './drawLink';
import { RendererState } from './modelRenderer';
import {
  RasterLoadState,
  getOrInitLoadImageFromStore,
} from './rasterTextureStore';

const d2r = (d: number) => d * (Math.PI / 180);

export function drawPort(
  nvg: NVG.Context,
  rs: RendererState,
  x: number,
  y: number,
  zoom: number,
  side: PortSide,
  portHasLink: boolean,
  signalConnected: boolean,
  hovering: boolean,
  flipped: boolean,
  portConnectedColor: NVG.Color | undefined,
  userDrawingAttachedLink: boolean,
  hollow: boolean,
  hasError: boolean,
): void {
  nvg.beginPath();
  if (side === PortSide.Output) {
    nvg.moveTo(x * zoom, y * zoom);
    nvg.arc(
      x * zoom,
      y * zoom,
      (hovering && !portHasLink ? 6 : 4) * zoom,
      portHasLink ? 0 : d2r(flipped ? 90 : -90),
      portHasLink ? Math.PI * 2 : d2r(flipped ? -90 : 90),
      NVG.NVGwinding.CCW,
    );

    let portColor: NVG.Color;
    if (portHasLink) {
      if (!signalConnected && !userDrawingAttachedLink) {
        portColor = LINK_COLOR_DISCONNECTED;
      } else {
        portColor = portConnectedColor || LINK_COLORS.normal;
      }
    } else if (hovering) {
      portColor = nvg.RGBA(64, 91, 91, 255);
    } else {
      portColor = nvg.RGBA(131, 149, 149, 127);
    }
    nvg.fillColor(portColor);
    nvg.fill();
  }

  if (side === PortSide.Input) {
    let portColor: NVG.NVGcolor = LINK_COLORS.normal;

    const rawScale = Math.round(window.devicePixelRatio * rs.zoom);
    const scale = rawScale > 2 ? 4 : rawScale < 1 ? 1 : rawScale;

    let suffix = '';

    if (!portHasLink && hollow) {
      suffix = '_trigger';
    }

    if (
      (portHasLink && !signalConnected && !userDrawingAttachedLink) ||
      hasError
    ) {
      portColor = LINK_COLOR_DISCONNECTED;
    } else if (portHasLink && portConnectedColor) {
      portColor = portConnectedColor;
    }

    const baseRasterID = `${
      portHasLink || hasError ? 'link_end_input_blank' : 'input_port'
    }${suffix}`;
    const scaledRasterID = `${baseRasterID}_${scale}x`;

    const rasterMeta = getOrInitLoadImageFromStore(
      nvg,
      `${process.env.PUBLIC_URL}/assets/${scaledRasterID}.png`,
      scaledRasterID,
      scale,
    );

    if (rasterMeta?.loadState === RasterLoadState.Loaded) {
      const raw_w = rasterMeta.width / scale;
      const raw_h = rasterMeta.height / scale;
      const raw_x = !portHasLink ? x : flipped ? x + 2 : x - 2;
      const raw_y = y - raw_h / 2;

      const rx = raw_x * rs.zoom;
      const ry = raw_y * rs.zoom;
      const rw = flipped ? -raw_w * rs.zoom : raw_w * rs.zoom;
      const rh = raw_h * rs.zoom;

      const imgPaint = nvg.imagePattern(
        rx,
        ry,
        rw,
        rh,
        0,
        rasterMeta.imageId,
        1,
      );
      (imgPaint.innerColor as NVG.NVGcolor) = portColor;
      (imgPaint.outerColor as NVG.NVGcolor) = nvg.RGBAf(1, 1, 1, 1);

      if (!portHasLink && !hovering && !hasError) {
        nvg.globalAlpha(0.4);
      }
      nvg.beginPath();
      nvg.rect(rx, ry, rw, rh);
      nvg.fillPaint(imgPaint);
      nvg.fill();
      nvg.globalAlpha(1);

      if (portHasLink && !userDrawingAttachedLink) {
        nvg.beginPath();
        nvg.rect(
          (flipped ? x + 1 : x - 2) * rs.zoom,
          (y - 1) * rs.zoom,
          2 * rs.zoom,
          2 * rs.zoom,
        );
        nvg.fillColor(portColor);
        nvg.fill();
      }
    }
  }
}
