import { LinkInstance } from '@collimator/model-schemas-ts';
import { PayloadAction } from '@reduxjs/toolkit';
import { AutolayoutPayload } from 'app/autolayout';
import {
  getCurrentlyEditingModelFromState,
  ModelState,
} from 'app/modelState/ModelState';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { pointToLineDistance } from 'util/pointToLineDistance';
import {
  reifyLink_mut,
  setLinkSourceAndDependentTapSources,
} from './linkMutationUtils';
import { snapNumberToGrid } from './modelDataUtils';

export const eraseModelLayout = (state: ModelState) => {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  for (let i = 0; i < model.nodes.length; i++) {
    const node = model.nodes[i];

    node.uiprops.x = 0;
    node.uiprops.y = 0;
  }

  for (let i = 0; i < model.links.length; i++) {
    const link = model.links[i];

    link.uiprops.segments = [];
  }
};

export const consumeAutoLayout = (
  state: ModelState,
  action: PayloadAction<AutolayoutPayload>,
) => {
  const { nodeCoordinates, linkLayoutLut, tapData, tappedHostLinkIds } =
    action.payload;

  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  for (let i = 0; i < model.nodes.length; i++) {
    const node = model.nodes[i];

    const currentNodeCoord = nodeCoordinates[node.uuid];

    if (currentNodeCoord) {
      node.uiprops.x = currentNodeCoord.x;
      node.uiprops.y = currentNodeCoord.y;
    }
  }

  const linkLut: Record<string, LinkInstance> = {};

  for (let i = 0; i < model.links.length; i++) {
    const link = model.links[i];
    link.uiprops.segments = [];

    // if the link has taps, the generated autolayout segment position data
    // is not valid for this link.
    // this is because we're fudging the existence of tap points, which
    // is a collimator-only idea (and hopefully soon to be replaced with actual node entities)
    if (tappedHostLinkIds.has(link.uuid)) {
      // we override model.nodes because the rendererState inside reifyLink_mut
      // has stale data for where the nodes are
      reifyLink_mut(link, false, true, model.nodes);
    } else if (linkLayoutLut[link.uuid]) {
      link.uiprops.segments = linkLayoutLut[link.uuid];
    }

    linkLut[link.uuid] = link;
  }

  // here, we're processing tap link metadata and placing each tap point on its host link
  // at the closest point possible correlated to the generated autolayout data.
  for (let i = 0; i < tapData.length; i++) {
    const currentTapData = tapData[i];
    const tappingLink = linkLut[currentTapData.tappingLinkUuid];
    const hostLink = linkLut[currentTapData.hostLinkUuid];

    if (!tappingLink || !hostLink) continue;

    let tappedSegmentIndex = 0;
    let tappedSegmentDir: 'horiz' | 'vert' = 'horiz';
    let tappedSegmentCoord = currentTapData.coordinate.x;

    let shortestDist = Number.MAX_SAFE_INTEGER;
    for (let i = 1; i < hostLink.uiprops.segments.length - 1; i++) {
      const isEven = i % 2 === 0;
      const curSeg = hostLink.uiprops.segments[i];
      const prevSeg = hostLink.uiprops.segments[i - 1];
      const nextSeg = hostLink.uiprops.segments[i + 1];

      const vertexOne = isEven
        ? { x: prevSeg.coordinate, y: curSeg.coordinate }
        : { x: curSeg.coordinate, y: prevSeg.coordinate };
      const vertexTwo = isEven
        ? { x: nextSeg.coordinate, y: curSeg.coordinate }
        : { x: curSeg.coordinate, y: nextSeg.coordinate };

      const dist = pointToLineDistance(
        currentTapData.coordinate,
        vertexOne,
        vertexTwo,
      );

      if (dist < shortestDist) {
        shortestDist = dist;
        tappedSegmentIndex = i;
        tappedSegmentDir = isEven ? 'vert' : 'horiz';
        tappedSegmentCoord = isEven
          ? currentTapData.coordinate.y
          : currentTapData.coordinate.x;
      }
    }

    tappingLink.uiprops.link_type = {
      connection_method: 'link_tap',
      tapped_link_uuid: currentTapData.hostLinkUuid,
      tapped_segment: {
        segment_type: 'real',
        tapped_segment_index: tappedSegmentIndex,
        tapped_segment_direction: tappedSegmentDir,
      }, // TODO: unhardcode
      tap_coordinate: snapNumberToGrid(tappedSegmentCoord),
    };

    setLinkSourceAndDependentTapSources(
      tappingLink.uuid,
      hostLink.src,
      model.links,
      rendererState?.refs?.current?.linksIndexLUT,
    );
  }
};
