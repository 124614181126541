import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  ProgressBar,
  notificationsActions,
} from 'app/slices/notificationsSlice';
import React, { ReactElement, useCallback } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Close } from 'ui/common/Icons/Standard';
import { useTimer } from 'ui/common/timer/useTimer';
import { SmallEmphasis } from 'ui/common/typography/Typography';

export const AUTODISMISS_DELAY_IN_MS = 5000;
const PROGRESS_BAR_WIDTH_IN_PX = 110;

const NotificationContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0 ${(props) => props.theme.spacing.large};
  pointer-events: none;
  z-index: 2;
  width: 100%;
  transition: top 0.15s;
  transition-timing-function: ease-in;
  top: ${({ isOpen, theme }) =>
    isOpen
      ? `calc(${theme.sizes.navbarHeight} + ${theme.spacing.xsmall})`
      : '0'};
`;

const NotificationItem = styled.div`
  padding-left: ${({ theme }) => theme.spacing.large};
  padding-top: ${({ theme }) => theme.spacing.small};
  padding-bottom: ${({ theme }) => theme.spacing.small};
  border-radius: 2px;
  color: white;
  background-color: ${({ theme }) => theme.colors.grey[85]};
  pointer-events: all;
  cursor: pointer;
  margin: auto;
  overflow: hidden;
  max-width: 80%;
  display: flex;
  height: 32px;
`;

const NotificationMessage = styled(SmallEmphasis)`
  padding-left: ${({ theme }) => theme.spacing.small};
  padding-right: ${({ theme }) => theme.spacing.small};
  padding-top: ${({ theme }) => theme.spacing.small};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const RightNotificationMessage = styled(NotificationMessage)`
  padding-right: ${({ theme }) => theme.spacing.large};
`;

const ProgressStatus = styled(NotificationMessage)`
  width: 62px;
  padding-right: ${({ theme }) => theme.spacing.small};
`;

const LeftProgressStatus = styled(ProgressStatus)`
  text-align: right;
`;

const NotificationProgressBarRail = styled.div`
  margin-top: 10px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.grey[70]};
  width: ${PROGRESS_BAR_WIDTH_IN_PX}px;
  height: 4px;
`;

const NotificationProgressBarFill = styled.div<{ progressRatio: number }>`
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.grey[50]};
  width: ${({ progressRatio }) =>
    Math.floor(progressRatio * PROGRESS_BAR_WIDTH_IN_PX)}px;
  height: 4px;
`;

const NotificationCloseButtonSpacer = styled.div`
  margin-right: ${({ theme }) => theme.spacing.small};
  height: 24px;
  border-left: 1px solid ${({ theme }) => theme.colors.grey[70]};
`;

const NotificationCloseButton = styled(Button)`
  height: 100%;
  margin-right: ${({ theme }) => theme.spacing.small};
`;

export const Notification: React.FC = (): ReactElement | null => {
  const dispatch = useAppDispatch();

  const { colors } = useTheme();

  // The current message determines whether the notification should be shown or not.
  const {
    currentMessage,
    currentIcon: CurrentIcon,
    progressBar,
    canClose,
  } = useAppSelector((state) => state.notifications);

  // Keep track of the last message so the last message will be showing
  // when the notification animates out of view.
  // This prevents visual flashing.
  const [lastMessage, setLastMessage] = React.useState<string | undefined>(
    undefined,
  );
  const [lastProgressBar, setLastProgressBar] = React.useState<
    ProgressBar | undefined
  >(undefined);

  const [open, setOpen] = React.useState<boolean>(false);

  const { startTimer, stopTimer } = useTimer();

  const clearMessage = useCallback(() => {
    dispatch(notificationsActions.setCurrentMessage(undefined));
  }, [dispatch]);

  React.useEffect(() => {
    const messageUpdated =
      (currentMessage && currentMessage !== lastMessage) ||
      (progressBar &&
        (lastProgressBar?.elapsed !== progressBar.elapsed ||
          lastProgressBar?.total !== progressBar.total ||
          lastProgressBar?.startText !== progressBar.startText ||
          lastProgressBar?.endText !== progressBar.endText));

    if ((currentMessage || progressBar) && (messageUpdated || !open)) {
      // Make sure the notification opens when a new notification is triggered.
      // If a new message is triggered while a current message is shown,
      // make sure the new message replaces the old message and
      // the close timer restarts.
      stopTimer();
      setLastMessage(currentMessage);
      setLastProgressBar(progressBar);
      setOpen(true);
      startTimer(clearMessage, AUTODISMISS_DELAY_IN_MS);
    } else if (!currentMessage && !progressBar && open) {
      // Make sure the notification goes away when the message is cleared.
      stopTimer();
      setOpen(false);
    }
  }, [
    currentMessage,
    open,
    clearMessage,
    startTimer,
    stopTimer,
    setOpen,
    lastMessage,
    progressBar,
    lastProgressBar,
  ]);

  return (
    <NotificationContainer isOpen={open}>
      <NotificationItem>
        {CurrentIcon && <CurrentIcon fill={colors.grey[5]} />}
        {lastMessage && (
          <RightNotificationMessage>{lastMessage}</RightNotificationMessage>
        )}
        {lastProgressBar && (
          <>
            <NotificationMessage>
              {lastProgressBar.startText}
            </NotificationMessage>
            <LeftProgressStatus>
              {lastProgressBar.elapsed.toFixed(2)}
            </LeftProgressStatus>
            <NotificationProgressBarRail>
              <NotificationProgressBarFill
                progressRatio={lastProgressBar.elapsed / lastProgressBar.total}
              />
            </NotificationProgressBarRail>
            <ProgressStatus>{lastProgressBar.total.toFixed(2)}</ProgressStatus>
            <RightNotificationMessage>
              {lastProgressBar.endText}
            </RightNotificationMessage>
          </>
        )}
        {canClose && (
          <>
            <NotificationCloseButtonSpacer />
            <NotificationCloseButton
              variant={ButtonVariants.SmallTertiary}
              Icon={Close}
              onClick={clearMessage}
            />
          </>
        )}
      </NotificationItem>
    </NotificationContainer>
  );
};
