// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const Generic: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Generic",
      "namespace": "core",
      "description": "Generic block.",
      "feature_level": "dev"
    },
    "modes": {
      "time": "any",
      "feedthrough": true
    },
    "ports": {
      "inputs": {
        "dynamic": {
          "min_count": 0,
          "max_count": -1,
          "default_count": 1
        }
      },
      "outputs": {
        "dynamic": {
          "min_count": 0,
          "max_count": -1,
          "default_count": 1
        }
      }
    },
    "parameter_definitions": [
      {
        "name": "a",
        "data_type": "string",
        "default_value": "",
        "description": "Block specific parameter."
      },
      {
        "name": "b",
        "data_type": "string",
        "default_value": "",
        "description": "Block specific parameter."
      },
      {
        "name": "c",
        "data_type": "string",
        "default_value": "",
        "description": "Block specific parameter."
      },
      {
        "name": "d",
        "data_type": "string",
        "default_value": "",
        "description": "Block specific parameter."
      },
      {
        "name": "e",
        "data_type": "string",
        "default_value": "",
        "description": "Block specific parameter."
      },
      {
        "name": "f",
        "data_type": "string",
        "default_value": "",
        "description": "Block specific parameter."
      },
      {
        "name": "g",
        "data_type": "string",
        "default_value": "",
        "description": "Block specific parameter."
      },
      {
        "name": "h",
        "data_type": "string",
        "default_value": "",
        "description": "Block specific parameter."
      }
    ]
  }
);
