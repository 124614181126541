import {
  NodeInstance,
  LinkInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { t } from '@lingui/macro';
import { getSignalDisplayName } from 'util/signalDisplayName';
import { getSubmodelPathName } from 'ui/modelEditor/portPathNameUtils';
import { DiagramVersionFull } from 'app/apiTransformers/convertGetSnapshotReadByUuid';
import { getNodeForTopLevelModelPath, NodeResult } from 'util/modelVersionNode';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import {
  getModelData,
  ModelVersionRequestData,
} from 'app/slices/modelVersionsSlice';
import { getTimeModeFromPortPathName } from 'util/portTypeUtils';
import { TimeModeType } from '../app/slices/compilationAnalysisDataSlice';

export interface PortVectorInfo {
  portName: string;
  vectorIndex?: number;
}

export function getVectorIndex(
  portNameWithVectorIndex: string,
): PortVectorInfo {
  // This makes sure that we can handle ending name sections of the form
  // portName[vectorIndex];
  const portSections = portNameWithVectorIndex.split('[');
  const portName = portSections[0];
  if (portSections[1]) {
    const vectorIndexString = portSections[1].split(']')[0];
    if (vectorIndexString) {
      try {
        return {
          portName,
          vectorIndex: Number(vectorIndexString),
        };
      } catch (error) {
        console.error(
          `Unable to parse port vector index for portNameWithVectorIndex=${portNameWithVectorIndex}`,
          error,
        );
      }
    }
  }
  return {
    portName,
  };
}

interface SignalInfoResult {
  timeMode?: TimeModeType | null;
  portIndex?: number;
  vectorIndex?: number;
  portName?: string;
  signalLeafDisplayName?: string;
  signalDisplayName?: string;

  // If there is a problem finding the signal info, the error message will be set,
  // otherwise this will be undefined.
  errorMessage?: string;
}

export interface SignalPathInfo {
  timeMode?: TimeModeType | null;
  portIndex?: number;
  vectorIndex?: number;
  portName?: string;
  signalLeafDisplayName?: string;
  signalDisplayName?: string;

  // If there is a model (or submodel) version that we need to request,
  // it will be set here, otherwise this will be undefined.
  modelVersionToRequest?: ModelVersionRequestData;

  // If all the model and submodel versions that we needed to access the
  // requested parent node are available, the parent node will be set here,
  // otherwise this will be undefined.
  node?: NodeInstance;

  parentNode?: NodeInstance;

  // Provides the set of links for the parent node (if one was found),
  // otherwise undefined.
  links?: LinkInstance[];

  parentLinks?: LinkInstance[];

  // Provides an array of parent ids associated with the parent node (if one was found),
  // otherwise undefined.
  parentPath?: string[];

  // If there is a problem loading the model versions for this
  // parent name path or parsing the signal info, the error message will be set,
  // otherwise this will be undefined.
  errorMessage?: string;
}

function getSignalInfo(
  topLevelNodes: NodeInstance[],
  topLevelSubmodels: SubmodelsSection,
  parentPath: string[],
  signalPath: string,
  links: LinkInstance[],
  parentLinks: LinkInstance[] | undefined,
  node: NodeInstance,
  parentNode: NodeInstance | undefined,
): SignalInfoResult {
  const nameSections: string[] = signalPath.split('.');
  const portNameWithVectorIndex = nameSections[nameSections.length - 1];
  const { portName, vectorIndex } = getVectorIndex(portNameWithVectorIndex);

  // Find the port associated to the signal.
  // First, check if the port is on the associated node instance.
  let portIndex = node.outputs.findIndex((output) => output.name === portName);
  let nodeWithAssociatedPort = node;
  let linksAssociatedWithPort: LinkInstance[] | undefined = links;
  if (portIndex === -1) {
    // Second, if a parent node is available, check if it is a submodel instance
    // and is the block associated with the relevant port.
    if (parentNode) {
      portIndex = parentNode.outputs.findIndex(
        (output) => output.name === portName,
      );
      nodeWithAssociatedPort = parentNode;
      linksAssociatedWithPort = parentLinks;
    }
  }

  if (portIndex === -1 || !nodeWithAssociatedPort) {
    return {
      errorMessage: t({
        id: 'dataExplorer.signalPathParseError.unableToFindSignalBlockPort',
        message:
          'Unable to parse signal: Unable to find signal block port for portName {portName}.',
        values: { portName },
      }),
    };
  }

  const submodelParentPath = getSubmodelPathName(
    topLevelNodes,
    topLevelSubmodels,
    parentPath,
  );

  const blockName = nameSections[nameSections.length - 2];
  const signalLeafDisplayName = getSignalDisplayName(
    blockName,
    portName,
    portIndex,
    nodeWithAssociatedPort.uuid,
    nodeWithAssociatedPort,
    linksAssociatedWithPort,
  );

  const signalDisplayName = submodelParentPath
    ? `${submodelParentPath}.${signalLeafDisplayName}`
    : signalLeafDisplayName;

  const timeMode = getTimeModeFromPortPathName(signalPath);

  return {
    timeMode,
    portIndex,
    vectorIndex,
    portName,
    signalLeafDisplayName,
    signalDisplayName,
  };
}

function buildNodeInfoForTopLevel(
  topLevelNodes: NodeInstance[],
  topLevelLinks: LinkInstance[],
  topLevelSubmodels: SubmodelsSection,
  signalPath: string,
  isModelEditor: boolean,
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >,
): NodeResult {
  return getNodeForTopLevelModelPath(
    topLevelSubmodels,
    topLevelNodes,
    topLevelLinks,
    signalPath,
    isModelEditor,
    modelIdToVersionIdToModelData,
  );
}

function buildSignalInfoForTopLevel(
  topLevelNodes: NodeInstance[],
  topLevelLinks: LinkInstance[],
  topLevelSubmodels: SubmodelsSection,
  signalPath: string,
  isModelEditor: boolean,
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >,
): SignalPathInfo {
  const {
    modelVersionToRequest,
    errorMessage: nodeErrorMessage,
    links,
    node,
    parentPath,
    parentNode,
    parentLinks,
  }: NodeResult = buildNodeInfoForTopLevel(
    topLevelNodes,
    topLevelLinks,
    topLevelSubmodels,
    signalPath,
    isModelEditor,
    modelIdToVersionIdToModelData,
  );

  if (nodeErrorMessage) {
    return {
      errorMessage: nodeErrorMessage,
    };
  }
  if (modelVersionToRequest) {
    return {
      modelVersionToRequest,
    };
  }
  if (!node || !links || !parentPath) {
    return {
      errorMessage: t({
        id: 'dataExplorer.signalPathParseError.unableToFindSignalBlock',
        message: 'Unable to parse signal: Unable to find signal block.',
      }),
    };
  }

  const {
    timeMode,
    portIndex,
    vectorIndex,
    portName,
    signalLeafDisplayName,
    signalDisplayName,
    errorMessage: signalInfoErrorMessage,
  } = getSignalInfo(
    topLevelNodes,
    topLevelSubmodels,
    parentPath,
    signalPath,
    topLevelLinks,
    parentLinks,
    node,
    parentNode,
  );

  return {
    timeMode,
    portIndex,
    vectorIndex,
    portName,
    signalLeafDisplayName,
    signalDisplayName,
    modelVersionToRequest,
    node,
    parentNode,
    links,
    parentLinks,
    parentPath,
    errorMessage: signalInfoErrorMessage,
  };
}

export function buildSignalInfoForCurrentModel(
  signalPath: string,
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >,
): SignalPathInfo {
  const isModelEditor = true;
  return buildSignalInfoForTopLevel(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().topLevelLinks,
    getCurrentModelRef().submodels,
    signalPath,
    isModelEditor,
    modelIdToVersionIdToModelData,
  );
}

export function buildNodeInfoForModelVersion(
  signalPath: string,
  topLevelModelId: string,
  topLevelModelVersionId: string,
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >,
): NodeResult {
  // Find the top level model to start the search.
  const modelData = getModelData(
    topLevelModelId,
    topLevelModelVersionId,
    modelIdToVersionIdToModelData,
  );

  if (!modelData) {
    // Request is not ready to process yet -- we need to request the top level model.
    return {
      modelVersionToRequest: {
        modelId: topLevelModelId,
        versionId: topLevelModelVersionId,
        kind: 'Model',
      },
    };
  }

  const isModelEditor = false;
  return buildNodeInfoForTopLevel(
    modelData.diagram.nodes,
    modelData.diagram.links,
    modelData.submodelsSection,
    signalPath,
    isModelEditor,
    modelIdToVersionIdToModelData,
  );
}

export function buildSignalInfoForModelVersion(
  signalPath: string,
  topLevelModelId: string,
  topLevelModelVersionId: string,
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >,
): SignalPathInfo {
  // Find the top level model to start the search.
  const modelData = getModelData(
    topLevelModelId,
    topLevelModelVersionId,
    modelIdToVersionIdToModelData,
  );
  if (!modelData) {
    // Request is not ready to process yet -- we need to request the top level model.
    return {
      modelVersionToRequest: {
        modelId: topLevelModelId,
        versionId: topLevelModelVersionId,
        kind: 'Model',
      },
    };
  }

  const isModelEditor = false;
  return buildSignalInfoForTopLevel(
    modelData.diagram.nodes,
    modelData.diagram.links,
    modelData.submodelsSection,
    signalPath,
    isModelEditor,
    modelIdToVersionIdToModelData,
  );
}
