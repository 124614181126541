import { ParameterChange } from '@collimator/model-schemas-ts';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import {
  defaultModelConfiguration,
  ModelConfiguration,
} from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  isPositiveNumberOrNullRuleSet,
  positiveNumberRules,
} from 'ui/common/Input/inputValidation';
import {
  ActionButtonContainer,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import {
  createStringParameterModel,
  StringParameterModel,
} from 'ui/common/parameters/ParameterModel';
import StringParameter from 'ui/common/parameters/StringParameter';
import SelectInput from 'ui/common/SelectInput';
import {
  DetailsDoubleRow,
  DetailsLabel,
} from 'ui/modelEditor/DetailsComponents';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';

const FormContentWrapper = styled.div`
  padding-bottom: ${({ theme }) => `${theme.spacing.large}`};
  padding-top: ${({ theme }) => `${theme.spacing.large}`};
`;

const ParameterWrapper = styled.div`
  margin-bottom: ${({ theme }) => `${theme.spacing.large}`};

  &:not(:nth-child(1)) {
    margin-top: ${({ theme }) => `${theme.spacing.large}`};
  }
`;

export const solverTypeOptions = [
  {
    value: 'variable_step',
    label: 'Variable Step',
  },
  {
    value: 'fixed_step',
    label: 'Fixed Step',
  },
];

interface SimulationSettingsParameters {
  solverSettings: StringParameterModel[];
  variableSolverSettings: StringParameterModel[];
  fixedSolverSettings: StringParameterModel[];
  interpolation: StringParameterModel;
}

const minStepNullDisplayString = 'Auto';
const continuousTimeResultIntervalNullDisplayString = 'None';

const eventSettings = ['normal', 'none'];
const eventSettingsOptions = eventSettings.map((o) => ({
  value: o,
  label: o,
}));
const method = ['RK45', 'BDF'];
const methodOptions = method.map((o) => ({
  value: o,
  label: o,
}));

function createParameterModels(
  configuration: ModelConfiguration,
): SimulationSettingsParameters {
  return {
    solverSettings: [
      createStringParameterModel({
        id: 'absolute_tolerance',
        label: t({
          id: 'modelRenderer.simulationSettingsModal.absoluteErrorTolerance.label',
          message: 'Absolute error tolerance',
        }),
        lastServerValueRaw:
          configuration.solver?.absolute_tolerance ||
          defaultModelConfiguration.solver?.absolute_tolerance,
        validationRules: positiveNumberRules,
      }),
      createStringParameterModel({
        id: 'relative_tolerance',
        label: t({
          id: 'modelRenderer.simulationSettingsModal.relativeErrorTolerance.label',
          message: 'Relative error tolerance',
        }),
        lastServerValueRaw:
          configuration.solver?.relative_tolerance ||
          defaultModelConfiguration.solver?.relative_tolerance,
        validationRules: positiveNumberRules,
      }),
    ],
    variableSolverSettings: [
      createStringParameterModel({
        id: 'first_step',
        label: t({
          id: 'modelRenderer.simulationSettingsModal.initialStepSize.label',
          message: 'Initial step size',
        }),
        lastServerValueRaw:
          configuration.solver?.first_step ||
          defaultModelConfiguration.solver?.first_step,
        validationRules: positiveNumberRules,
      }),
      createStringParameterModel({
        id: 'min_step',
        label: t({
          id: 'modelRenderer.simulationSettingsModal.minimumStepSize.label',
          message: 'Minimum step size',
        }),
        lastServerValueRaw:
          configuration.solver?.min_step ||
          defaultModelConfiguration.solver?.min_step ||
          null, // Prefer to show 'Auto' rather than 0 (which is the current default from the schema)
        nullDisplayString: minStepNullDisplayString,
        validationRules: isPositiveNumberOrNullRuleSet(
          minStepNullDisplayString,
        ),
      }),
      createStringParameterModel({
        id: 'max_step',
        label: t({
          id: 'modelRenderer.simulationSettingsModal.maximumStepSize.label',
          message: 'Maximum step size',
        }),
        lastServerValueRaw:
          configuration.solver?.max_step ||
          defaultModelConfiguration.solver?.max_step,
        validationRules: positiveNumberRules,
      }),
    ],
    fixedSolverSettings: [
      createStringParameterModel({
        id: 'fixed_step',
        label: t({
          id: 'modelRenderer.simulationSettings.fixedStepSize.label',
          message: 'Fixed step size',
        }),
        lastServerValueRaw:
          configuration.solver?.fixed_step ||
          defaultModelConfiguration.solver?.fixed_step,
        validationRules: positiveNumberRules,
      }),
    ],
    interpolation: createStringParameterModel({
      id: 'continuous_time_result_interval',
      label: t({
        id: 'modelRenderer.propertiesSidebar.simulationSettings.interpolation.label',
        message: 'Interpolation',
      }),
      lastServerValueRaw:
        configuration.continuous_time_result_interval ||
        defaultModelConfiguration.continuous_time_result_interval ||
        null, // Prefer to show 'None' rather than 0 (which is the current default from the schema)
      nullDisplayString: continuousTimeResultIntervalNullDisplayString,
      validationRules: isPositiveNumberOrNullRuleSet(
        continuousTimeResultIntervalNullDisplayString,
      ),
    }),
  };
}

export function buildNumericalChange(
  parameterId: string,
  value: string | null,
  serverValue: number | undefined,
): ParameterChange | null {
  const numberValue = parseFloat(value || '');
  if (!isNaN(numberValue) && numberValue > 0 && numberValue !== serverValue) {
    return {
      parameterId,
      value: numberValue,
    };
  }

  return null;
}

function buildChanges(
  parameters: StringParameterModel[],
  parameterId: string,
  serverValue: number | undefined,
): ParameterChange[] {
  const parameter = parameters.find((param) => param.id === parameterId);
  if (!parameter) return [];

  if (
    parameter.nullDisplayString &&
    (!parameter.currentValue ||
      parameter.currentValue.toLowerCase() === parameter.nullDisplayString)
  ) {
    return [
      {
        parameterId,
        value: undefined,
      },
    ];
  }

  const parameterChange = buildNumericalChange(
    parameterId,
    parameter.currentValue,
    serverValue,
  );
  if (parameterChange) {
    return [parameterChange];
  }

  return [];
}

function getConfigurationChanges(
  solverType: string,
  solverMethod: string,
  eventsHandling: string,
  serverConfiguration: ModelConfiguration,
  parameters: SimulationSettingsParameters,
): ParameterChange[] {
  let changes: ParameterChange[] = [];

  // TODO clean up the validation so it happens before this phase.
  if (solverType !== serverConfiguration.solver?.type) {
    changes = [
      ...changes,
      {
        parameterId: 'solver_type',
        value: solverType,
      },
    ];
  }
  changes = [
    ...changes,
    ...buildChanges(
      parameters.solverSettings,
      'absolute_tolerance',
      serverConfiguration.solver?.absolute_tolerance,
    ),
  ];
  if (solverMethod !== serverConfiguration.solver?.method) {
    changes = [
      ...changes,
      {
        parameterId: 'method',
        value: solverMethod,
      },
    ];
  }
  changes = [
    ...changes,
    ...buildChanges(
      parameters.solverSettings,
      'relative_tolerance',
      serverConfiguration.solver?.relative_tolerance,
    ),
  ];
  changes = [
    ...changes,
    ...buildChanges(
      parameters.variableSolverSettings,
      'first_step',
      serverConfiguration.solver?.first_step,
    ),
  ];
  changes = [
    ...changes,
    ...buildChanges(
      parameters.variableSolverSettings,
      'min_step',
      serverConfiguration.solver?.min_step,
    ),
  ];
  changes = [
    ...changes,
    ...buildChanges(
      parameters.variableSolverSettings,
      'max_step',
      serverConfiguration.solver?.max_step,
    ),
  ];
  changes = [
    ...changes,
    ...buildChanges(
      parameters.fixedSolverSettings,
      'fixed_step',
      serverConfiguration.solver?.fixed_step,
    ),
  ];
  changes = [
    ...changes,
    ...buildChanges(
      [parameters.interpolation],
      'continuous_time_result_interval',
      serverConfiguration.continuous_time_result_interval,
    ),
  ];
  if (eventsHandling !== serverConfiguration.events_handling) {
    changes = [
      ...changes,
      {
        parameterId: 'events_handling',
        value: eventsHandling,
      },
    ];
  }

  return changes;
}

const SimulationSettingsModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const { closeModal } = useModal();
  const configuration = useAppSelector(
    (state) => state.model.present.configuration,
  );
  const [parameterModels] = React.useState<SimulationSettingsParameters>(() =>
    createParameterModels(configuration),
  );
  const [solverType, setSolverType] = React.useState<string>(
    configuration.solver?.type ||
      defaultModelConfiguration.solver?.type ||
      'variable_step',
  );
  const [solverMethod, setSolverMethod] = React.useState<string>(
    configuration.solver?.method ||
      defaultModelConfiguration.solver?.method ||
      'RK45',
  );
  const [eventsHandling, setEventsHandling] = React.useState<string>(
    configuration.events_handling ||
      defaultModelConfiguration.events_handling ||
      'none',
  );

  const updateSolverType = (newSolverType: string) => {
    setSolverType(newSolverType);
    if (newSolverType === 'fixed_step' && solverMethod === 'BDF') {
      setSolverMethod('RK45');
    }
  };

  const onSave = () => {
    if (!canEditCurrentModelVersion) return;

    const changes = getConfigurationChanges(
      solverType,
      solverMethod,
      eventsHandling,
      configuration,
      parameterModels,
    );
    dispatch(modelActions.changeModelConfigurationValues(changes));
    closeModal();
  };

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onSave();
      }}>
      <FormContentWrapper>
        <ParameterWrapper>
          <DetailsDoubleRow noMargin>
            <DetailsLabel>
              {t({
                id: 'modelRenderer.simulationSettings.solverType.label',
                message: 'Solver type',
              })}
            </DetailsLabel>
            <SelectInput
              onSelectValue={updateSolverType}
              currentValue={solverType}
              options={solverTypeOptions}
              autoFocus
              isDisabled={!canEditCurrentModelVersion}
            />
          </DetailsDoubleRow>
        </ParameterWrapper>
        <ParameterWrapper>
          <DetailsDoubleRow noMargin>
            <DetailsLabel>
              {t({
                id: 'modelRenderer.simulationSettings.solverMethod.label',
                message: 'Solver method',
              })}
            </DetailsLabel>
            <SelectInput
              onSelectValue={setSolverMethod}
              currentValue={solverMethod}
              options={methodOptions}
              isDisabled={
                !canEditCurrentModelVersion || solverType === 'fixed_step'
              }
            />
          </DetailsDoubleRow>
        </ParameterWrapper>

        {parameterModels.solverSettings.map((param) => (
          <ParameterWrapper key={param.id}>
            <StringParameter
              parameterModel={param}
              disabled={!canEditCurrentModelVersion}
            />
          </ParameterWrapper>
        ))}
        {solverType === 'variable_step'
          ? parameterModels.variableSolverSettings.map((param) => (
              <ParameterWrapper key={param.id}>
                <StringParameter
                  parameterModel={param}
                  disabled={!canEditCurrentModelVersion}
                />
              </ParameterWrapper>
            ))
          : parameterModels.fixedSolverSettings.map((param) => (
              <ParameterWrapper key={param.id}>
                <StringParameter
                  parameterModel={param}
                  disabled={!canEditCurrentModelVersion}
                />
              </ParameterWrapper>
            ))}

        <ParameterWrapper>
          <DetailsDoubleRow noMargin>
            <DetailsLabel>
              {t({
                id: 'modelRenderer.simulationSettings.events_handling.label',
                message: 'Simulation event handling',
              })}
            </DetailsLabel>
            <SelectInput
              onSelectValue={setEventsHandling}
              currentValue={eventsHandling}
              options={eventSettingsOptions}
              isDisabled={!canEditCurrentModelVersion}
            />
          </DetailsDoubleRow>
        </ParameterWrapper>

        <ParameterWrapper key={parameterModels.interpolation.id}>
          <StringParameter
            parameterModel={parameterModels.interpolation}
            disabled={!canEditCurrentModelVersion}
          />
        </ParameterWrapper>
      </FormContentWrapper>

      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}
          testId="simulation-settings-cancel-button">
          {t({
            id: 'simulationSettingsModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Save button */}
        {canEditCurrentModelVersion && (
          <Button
            type="submit"
            variant={ButtonVariants.LargePrimary}
            testId="simulation-settings-save-button">
            {t({
              id: 'simulationSettingsModal.saveButton.label',
              message: 'Save',
            })}
          </Button>
        )}
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default SimulationSettingsModal;
