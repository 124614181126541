/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CreateGroup: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.25 6C4.25 5.0335 5.0335 4.25 6 4.25H17C17.9665 4.25 18.75 5.0335 18.75 6V13C18.75 13.4142 18.4142 13.75 18 13.75C17.5858 13.75 17.25 13.4142 17.25 13V6C17.25 5.86193 17.1381 5.75 17 5.75H6C5.86193 5.75 5.75 5.86193 5.75 6V17C5.75 17.1381 5.86193 17.25 6 17.25H13C13.4142 17.25 13.75 17.5858 13.75 18C13.75 18.4142 13.4142 18.75 13 18.75H6C5.0335 18.75 4.25 17.9665 4.25 17V6ZM18 15.25C18.4142 15.25 18.75 15.5858 18.75 16V17.25H20C20.4142 17.25 20.75 17.5858 20.75 18C20.75 18.4142 20.4142 18.75 20 18.75H18.75V20C18.75 20.4142 18.4142 20.75 18 20.75C17.5858 20.75 17.25 20.4142 17.25 20V18.75H16C15.5858 18.75 15.25 18.4142 15.25 18C15.25 17.5858 15.5858 17.25 16 17.25H17.25V16C17.25 15.5858 17.5858 15.25 18 15.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.5 8.5H8.5L8.5 10.5H11.5V8.5ZM8.5 7.5C7.94772 7.5 7.5 7.94772 7.5 8.5V10.5C7.5 11.0523 7.94771 11.5 8.5 11.5H11.5C12.0523 11.5 12.5 11.0523 12.5 10.5V8.5C12.5 7.94772 12.0523 7.5 11.5 7.5H8.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.5 8.5H14.1296V10.5L14.5 10.5V8.5ZM14.1296 7.5C13.5773 7.5 13.1296 7.94771 13.1296 8.5V10.5C13.1296 11.0523 13.5773 11.5 14.1296 11.5H14.5C15.0523 11.5 15.5 11.0523 15.5 10.5V8.5C15.5 7.94772 15.0523 7.5 14.5 7.5H14.1296Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.2037 10H11.5741V9H13.2037V10Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.44444 13.4991V11.499H10.4444V13.4991C10.4444 13.7753 10.6683 13.9991 10.9444 13.9991H11.7974V14.9991H10.9444C10.116 14.9991 9.44444 14.3276 9.44444 13.4991Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.4853 13.9736H12.5741V14.5H14.4853L14.4853 13.9736ZM12.5741 12.9736C12.0218 12.9736 11.5741 13.4213 11.5741 13.9736V14.5C11.5741 15.0523 12.0218 15.5 12.5741 15.5H14.4853C15.0376 15.5 15.4853 15.0523 15.4853 14.5V13.9736C15.4853 13.4213 15.0376 12.9736 14.4853 12.9736H12.5741Z"
      fill={props.fill}
    />
  </svg>
);

CreateGroup.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CreateGroup;
