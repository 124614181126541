import React from 'react';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import {
  DetailsDoubleRow,
  DetailsInput,
  DetailsLabel,
} from 'ui/modelEditor/DetailsComponents';
import { StringParameterModel } from './ParameterModel';

type Props = {
  parameterModel: StringParameterModel;
  disabled?: boolean;
};

const StringParameter: React.FC<Props> = ({ parameterModel, disabled }) => {
  // Keep track of the current display value so we can tell
  // if we need to switch it back to "Auto".
  const [displayValue, setDisplayValue] = React.useState(
    parameterModel.currentValue ?? parameterModel.nullDisplayString ?? '',
  );

  const onSubmitValue = (updatedValue: string) => {
    if (parameterModel.nullDisplayString) {
      const nullDisplayString = parameterModel.nullDisplayString;
      if (updatedValue === nullDisplayString) {
        parameterModel.currentValue = null;
        setDisplayValue(nullDisplayString);
        return;
      }
      if (updatedValue === '') {
        // If the user cleared out the null display string, we will need to
        // update the display value twice to retrigger the input
        // to replace the empty value with then null display string value.
        parameterModel.currentValue = null;
        setDisplayValue('');
        setTimeout(() => setDisplayValue(nullDisplayString));
        return;
      }
    }

    parameterModel.currentValue = updatedValue;
    setDisplayValue(updatedValue);
  };

  return (
    <DetailsDoubleRow title={parameterModel.tooltip} noMargin>
      <DetailsLabel>{parameterModel.label}</DetailsLabel>
      <DetailsInput
        onSubmitValue={onSubmitValue}
        value={displayValue}
        align={TextInputAlign.Right}
        hasBorder
        validationRules={parameterModel.validationRules}
        disabled={disabled}
      />
    </DetailsDoubleRow>
  );
};

export default StringParameter;
