import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import {
  ModelOverrides,
  ParameterSweep,
} from 'app/apiGenerated/generatedApiTypes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  ParamOverrides,
  ensembleSimsActions,
} from 'app/slices/ensembleSimsSlice';
import { projectActions } from 'app/slices/projectSlice';
import React from 'react';
import { usePythonExecutor } from 'ui/appBottomBar/assistant/PythonHooks';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Reset, RunAll } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import {
  ValidationRule,
  isRequiredRule,
} from 'ui/common/Input/inputValidation';
import { useModal } from 'ui/common/Modal/useModal';
import { useAppParams } from 'util/useAppParams';

const EnsembleSimModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

const InfoLabel = styled.label`
  font-size: ${({ theme }) => theme.typography.font.large.size};
  line-height: ${({ theme }) => theme.typography.font.large.lineHeight};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const BoldInfoLabel = styled.label<{
  red?: boolean;
}>`
  font-size: ${({ theme }) => theme.typography.font.large.size};
  line-height: ${({ theme }) => theme.typography.font.large.lineHeight};
  font-weight: 500;
  color: ${({ theme, red }) =>
    red ? theme.colors.base.red : theme.colors.text.primary};
`;

const ParametersBox = styled.div`
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  overflow-y: scroll;
  max-height: 160px;
  border: 1px solid ${({ theme }) => theme.colors.grey[10]};

  padding: ${({ theme }) => theme.spacing.normal};
  margin-top: ${({ theme }) => theme.spacing.large};
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

const ParamRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const ParamNameLabel = styled.label`
  width: 160px;

  font-weight: 500;
  font-family: ${({ theme }) => theme.typography.font.code.fontFamily};
  font-size: ${({ theme }) => theme.typography.font.standard.size};
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const ParamInput = styled(Input)`
  flex: 1;
`;

// FIXME: this should be inside the input box
const ResetButton = styled(Button)`
  margin-right: -${({ theme }) => theme.spacing.normal};
`;

const BottomBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  > label {
    flex: 1;
  }
`;

const EnsembleSimModal: React.FC = () => {
  const { closeModal } = useModal();
  const dispatch = useAppDispatch();
  const executePython = usePythonExecutor();

  const { maxQueuedSims } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const modelId = useAppParams().modelId || '';
  const savedOverrides = useAppSelector(
    (state) =>
      state.ensembleSims.configsByModelId[modelId]?.paramOverrides ?? {},
  );

  const parameters = useAppSelector((state) => state.model.present.parameters);
  const [paramOverrides, setParamOverrides] =
    React.useState<ParamOverrides>(savedOverrides);

  // This caches the len() for each python expression (key of the map)
  const [lengthOfExpression, setLengthOfExpression] = React.useState<
    Record<string, number>
  >({});
  const [exprErrors, setExprErrors] = React.useState<Record<string, string>>(
    {},
  );

  const isValidExprRule: ValidationRule = React.useMemo(
    () => ({
      predicate: (value: string) => exprErrors[value] === undefined,
      message: 'Not a valid expression',
    }),
    [exprErrors],
  );

  const defaultValues = React.useMemo(() => {
    const values: Record<string, string> = {};
    parameters.forEach((param) => {
      values[param.name] = param.value;
    });
    return values;
  }, [parameters]);

  const isParamOverridden = React.useCallback(
    (name: string) =>
      paramOverrides[name] !== undefined &&
      paramOverrides[name] !== defaultValues[name],
    [paramOverrides, defaultValues],
  );

  const pythonEvalNumberOfValues = React.useMemo(
    () => async (expression: string) => {
      if (!expression) return;

      const code = `
evaluated = eval(inputs.expression)
length = np.size(evaluated)`;

      const { results, error } = await executePython({
        code,
        inputs: { expression },
        importStatements: 'import numpy as np\n',
        returnVariableNames: ['length'],
      });

      const length = results?.get('length');
      if (typeof length === 'number') {
        setLengthOfExpression((prev) => ({ ...prev, [expression]: length }));
      }

      if (typeof error === 'string' && error.length > 0) {
        setExprErrors((prev) => ({ ...prev, [expression]: error }));
      } else if (expression && exprErrors[expression]) {
        setExprErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors[expression];
          return newErrors;
        });
      }
    },
    [executePython, setLengthOfExpression, exprErrors, setExprErrors],
  );

  const hasAnyParamOverride = React.useMemo(
    () => Object.keys(paramOverrides).some((key) => isParamOverridden(key)),
    [paramOverrides, isParamOverridden],
  );

  const totalNumberOfSimulations = React.useMemo(() => {
    let total = 1;
    parameters.forEach((param) => {
      const value = paramOverrides[param.name] ?? defaultValues[param.name];
      if (value) {
        total *= lengthOfExpression[value] ?? 1;
      }
    });
    return total;
  }, [parameters, paramOverrides, defaultValues, lengthOfExpression]);

  const parameterSweeps: ParameterSweep[] = React.useMemo(() => {
    const sweeps: ParameterSweep[] = [];
    parameters.forEach((param) => {
      const parameter_name = param.name;
      const sweep_expression = paramOverrides[param.name];
      const default_value = defaultValues[param.name];
      if (sweep_expression && default_value !== sweep_expression) {
        sweeps.push({ parameter_name, sweep_expression, default_value });
      }
    });
    return sweeps;
  }, [parameters, paramOverrides, defaultValues]);

  const modelOverrides: ModelOverrides = React.useMemo(
    () => ({
      ensemble_config: {
        sweep_strategy: 'all_combinations',
        model_parameter_sweeps: parameterSweeps,
      },
    }),
    [parameterSweeps],
  );

  const onParamChange = (name: string) => (value: string) => {
    const newOverrides = { ...paramOverrides, [name]: value };
    if (value === defaultValues[name]) {
      delete newOverrides[name];
    }
    setParamOverrides(newOverrides);

    dispatch(
      ensembleSimsActions.saveParamOverrides({
        modelId,
        paramOverrides: newOverrides,
      }),
    );

    pythonEvalNumberOfValues(value);
  };

  const onRunAll = React.useCallback(() => {
    dispatch(projectActions.requestRunEnsemble({ modelOverrides }));
    closeModal();
  }, [modelOverrides, dispatch, closeModal]);

  const unacceptableNumberOfSimulations =
    totalNumberOfSimulations > maxQueuedSims || totalNumberOfSimulations < 1;

  const allowedToRun = hasAnyParamOverride && !unacceptableNumberOfSimulations;

  return (
    <EnsembleSimModalContainer>
      <InfoLabel>
        {t`Enter value lists, ranges, or distributions for any model parameters to run another simulation for each value.`}
      </InfoLabel>
      <ParametersBox>
        {parameters.map((param, index) => (
          <ParamRow key={index}>
            <ParamNameLabel>{param.name}</ParamNameLabel>
            <ParamInput
              placeholder="Enter value list, range, or distribution"
              value={paramOverrides[param.name] ?? defaultValues[param.name]}
              hasBorder
              isMonospaced
              isEmphasized={isParamOverridden(param.name)}
              onSubmitValue={onParamChange(param.name)}
              validationRules={[isRequiredRule, isValidExprRule]}
            />
            <ResetButton
              Icon={Reset}
              variant={ButtonVariants.SmallTertiary}
              onClick={() =>
                onParamChange(param.name)(defaultValues[param.name])
              }
              disabled={!isParamOverridden(param.name)}
            />
          </ParamRow>
        ))}
      </ParametersBox>
      <BottomBar>
        <InfoLabel>{t`Total number of simulations:`}</InfoLabel>
        <BoldInfoLabel red={unacceptableNumberOfSimulations}>
          {totalNumberOfSimulations}
        </BoldInfoLabel>
        <Button
          variant={ButtonVariants.LargePrimary}
          Icon={RunAll}
          onClick={onRunAll}
          disabled={!allowedToRun}>
          {t`Run all`}
        </Button>
      </BottomBar>
    </EnsembleSimModalContainer>
  );
};

export default EnsembleSimModal;
