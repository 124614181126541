import { BlockInstance } from '@collimator/model-schemas-ts';
import { callCompletion, extractCodeFromChatGpt } from 'app/openai';
import { ChatMessage, ChatMessageRole } from 'app/third_party_types/chat-types';
import { WebSocketContext } from 'ui/common/WebSocketProvider';

const ChatGptPrompt = `You are a chatbot helping users convert python code to C code.

Example:
\`\`\`Python
# Inputs: x, in_1
# Outputs: out_0, abc, out_2

if time == 0:
    state_var = 0
else:
    state_var = 1

out_0 = in_1
abc = 42
out_2 = x
\`\`\`

\`\`\`C
typedef struct {
  bool initialized;
  int state_var;
} STATE_T;

void UPDATE(INPUT_T const* in, STATE_T* state, OUTPUT_T* out) {
  if (!state->initialized) {
    state->initialized = true;
    state->state_var = 0;
  } else {
    state->state_var = 1;
  }
  state->state_var = true;
  out->out_0 = in->in_1;
  out->abc = 42;
  out->out_2 = in->x;
}
\`\`\`

You MUST follow the below rules:
- Outputs should always be set in the UPDATE function.
- You should only use standard C functions.
- Provide implementation for functions that are undefined in C.
- There should be no python code in the C code.
- Consider INPUT_T and OUTPUT_T as already defined.

Now convert this python code while following the above rules and implement any functions that are not defined in C:\n`;

export const indent = (code: string, i: number) => {
  const lines = code.split('\n');
  return lines.map((line) => ' '.repeat(i) + line).join('\n');
};

export const translatePythonToCforBlock = async (
  codeBlock: BlockInstance,
  callback: (code: string) => void,
  websocketContext: WebSocketContext,
  doneCallback?: (failed: boolean, finishReason: string) => void,
) => {
  const inputNames = codeBlock?.inputs?.map((input) => input.name);
  const outputNames = codeBlock?.outputs?.map((output) => output.name);
  const hints = [
    `# Inputs: ${inputNames?.join(', ')}`,
    `# Outputs: ${outputNames?.join(', ')}`,
  ].join('\n');

  const initCode = codeBlock?.parameters?.init_script?.value
    ? `if time == 0:\n${indent(codeBlock?.parameters?.init_script?.value, 4)}`
    : '';
  const userStatements = codeBlock?.parameters?.user_statements?.value;
  // TODO: add finalizer code

  const pythonCode = [hints, initCode, userStatements].join('\n\n');

  const prompt: ChatMessage = {
    role: ChatMessageRole.User,
    content: `${ChatGptPrompt}\`\`\`Python\n${pythonCode}\n\`\`\``,
  };

  const completionCb = (messages: ChatMessage[]) => {
    const code = extractCodeFromChatGpt(messages);
    callback(code);
  };

  callCompletion(
    [prompt],
    completionCb,
    websocketContext,
    undefined,
    doneCallback,
  );
};
