import { CCode, Code, PythonCode } from 'ui/common/Icons/Small';
import { blockClassLookup } from './generated_blocks';
import { BlockParameterDefinition } from './generated_types/ComputationBlockClass';
import {
  BlockClassName,
  BlockInstance,
  NodeInstance,
  Parameter,
} from './generated_types/SimulationModel';

export type ParameterValueType = string | number | boolean;

// This function is used for linting, checks that switch is exhautive on types
// Must be used after switch () { case ... return; }
// See https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
export const assertUnreachable = (x: never): never => {
  throw new Error(`unexpected value after switch(): ${x}`);
};
export type SubdiagramBlockClassName = Extract<
  BlockClassName,
  | 'core.Iterator'
  | 'core.LinearizedSystem'
  | 'core.Conditional'
  | 'core.Replicator'
  | 'core.Group'
  | 'core.ReferenceSubmodel'
>;

export const nodeTypeIsAdder = (type?: BlockClassName): boolean =>
  Boolean(type && type === 'core.Adder');

export const nodeTypeIsProduct = (type?: BlockClassName): boolean =>
  Boolean(type && type === 'core.Product');

/**
 * FMU and CppFunction block ports and params are automatically determined by the file upload.
 * Bus block ports are determined by the selected reference bus interface.
 * User cannot modify these.
 */
export const nodeTypeIsDynamicBlock = (type?: BlockClassName): boolean => {
  switch (type) {
    case 'core.ModelicaFMU':
    case 'core.CppFunction':
    case 'core.BusCreator':
    case 'core.BusSelector':
      return true;
    default:
      return false;
  }
};

export const nodeTypeIsLocalSubdiagram = (type?: BlockClassName): boolean =>
  Boolean(
    type &&
      (type === 'core.Group' ||
        type === 'core.Submodel' ||
        type === 'core.Replicator' ||
        type === 'core.Conditional' ||
        type === 'core.LinearizedSystem' ||
        type === 'core.Iterator'),
  );

export const nodeTypeIsContainer = (type?: BlockClassName): boolean =>
  Boolean(type && nodeTypeIsLocalSubdiagram(type) && type !== 'core.Submodel');

/**
 * Includes both types of subdiagrams: local and reference
 */
export const nodeTypeIsSubdiagram = (type?: BlockClassName): boolean =>
  Boolean(
    type &&
      (nodeTypeIsLocalSubdiagram(type) || type === 'core.ReferenceSubmodel'),
  );

/** Iterators only allow for stateless foundational blocks */
export const nodeTypeIsIterator = (type?: BlockClassName): boolean =>
  Boolean(type && type === 'core.Iterator');

/** Conditional containers have a static inport `enabled` at the 0th index */
export const nodeTypeIsConditional = (type?: BlockClassName): boolean =>
  Boolean(type && type === 'core.Conditional');

export const nodeTypeIsReferencedSubmodel = (type?: BlockClassName): boolean =>
  Boolean(type && type === 'core.ReferenceSubmodel');

// Please only use this function for printing text and getting the icon name
export const nodeClassToPrintName = (type: BlockClassName): string => {
  // TODO: get rid of this once we have decided on how to
  // generalize our user-facing node name stuff.
  // unfortunately this has to be separate from the other
  // "corrector" in BlockComponent.tsx because the name formatting
  // works differently for the library.
  const corrections: { [k: string]: string } = {
    EdgeDetection: 'EdgeDetector',
    ReferenceSubmodel: 'Submodel',
  };

  if (corrections[type]) return corrections[type].substring(5);
  return type.substring(5);
};

export const printNameToSpacedDisplayName = (s: string) => {
  // TODO: get rid of this once we have decided on how to
  // generalize our user-facing node name stuff.
  // unfortunately this has to be separate from the other
  // "corrector" in helpers.ts because the name formatting
  // works differently for the library.
  const corrections: { [k: string]: string } = {
    ReferenceSubmodel: 'Submodel',
  };
  if (corrections[s]) return corrections[s];

  return s.replaceAll(/([a-z])([A-Z])/g, '$1 $2').replaceAll(/_/g, ' ');
};

export const nodeTypeIsCode = (type?: BlockClassName): boolean =>
  Boolean(
    type &&
      (type === 'core.PythonScript' ||
        type === 'core.CFunction' ||
        type === 'core.CodeBlockDev'),
  );

export const getCodeIcon = (type?: BlockClassName) => {
  switch (type) {
    case 'core.CFunction':
      return CCode;
    case 'core.PythonScript':
      return PythonCode;
    case 'core.CodeBlockDev':
      return Code;
    default:
      return Code;
  }
};

export const nodeTypeIsAPort = (type?: BlockClassName): boolean =>
  Boolean(type && (type === 'core.Inport' || type === 'core.Outport'));

export const makeParameter = (
  value: any,
  def: BlockParameterDefinition,
): Parameter => {
  value = value === undefined || value === null ? def.default_value : value;
  const param: Parameter = { value };
  if (def.data_type === 'string') {
    param.is_string = true;
  }
  return param;
};

export const getSubmodelPortIdOfIoNode = (block: BlockInstance): number =>
  parseInt(block.parameters.port_id?.value || 'NaN');

// Warning: Mutable functions, OOP style. Avoid when possible.

export const setParameterMut = (
  block: BlockInstance,
  name: string,
  value: string,
): void => {
  const classDef = blockClassLookup(block.type);
  const paramDef = (classDef.parameter_definitions || []).find(
    (p) => p.name === name,
  );
  if (!paramDef) return;

  block.parameters = {
    ...block.parameters,
    [name]: makeParameter(value, paramDef),
  };
};

export const setPortIdMut = (block: BlockInstance, id: number): void => {
  setParameterMut(block, 'port_id', `${id}`);
};

export function getCodeBasedBlockParamKey(
  node: NodeInstance | undefined,
): string | null {
  if (!node) {
    return null;
  }

  if (node.type === 'core.PythonScript') {
    return 'user_statements';
  }
  if (node.type === 'core.CFunction') {
    return 'user_statements';
  }
  if (node.type === 'core.CodeBlockDev') {
    return 'user_statements';
  }

  return null;
}
