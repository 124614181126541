import { t } from '@lingui/macro';
import { Step } from 'react-joyride';
import { Standard } from 'ui/common/typography/Typography';

const commonStepConfig = {
  disableBeacon: true,
  spotlightPadding: 0,
};

const steps: Step[] = [
  {
    ...commonStepConfig,
    content: (
      <Standard>
        {t({
          id: 'tour.onboarding.0.description.0',
          message:
            "We've put together a short tutorial to get you up to speed quickly. In exactly ten steps, you'll create a basic model, run a simulation, and plot the output.",
        })}
      </Standard>
    ),
    target: '.tour-onboarding-0',
    placement: 'bottom',
    offset: -10,
    floaterProps: {
      hideArrow: true,
    },
    title: t({ id: 'tour.onboarding.0.title', message: 'Quick Tutorial' }),
  },
  {
    ...commonStepConfig,
    content: (
      <Standard>
        {t({
          id: 'tour.onboarding.1.description.0',
          message: "Let's start by creating a new project",
        })}
        <br />
        {t({
          id: 'tour.onboarding.1.description.1',
          message: 'Click the “New Project” button to create a new one...',
        })}
      </Standard>
    ),
    target: '.tour-onboarding-1',
    placement: 'right-start',
    title: t({
      id: 'tour.onboarding.1.title',
      message: 'Create a new project',
    }),
  },
  {
    ...commonStepConfig,
    content: (
      <Standard>
        {t({
          id: 'tour.onboarding.2.description.0',
          message:
            '...give your project whatever name you like, then click “Create.”',
        })}
      </Standard>
    ),
    target: '.tour-onboarding-2',
    disableOverlay: true,
    placement: 'bottom',
    title: t({
      id: 'tour.onboarding.2.title',
      message: 'Create a new project',
    }),
  },
  {
    ...commonStepConfig,
    content: (
      <Standard>
        {t({
          id: 'tour.onboarding.3.description.0',
          message:
            "You've created your project, and it already contains a model. Go ahead and double click to open it",
        })}
      </Standard>
    ),
    target: '.tour-onboarding-3 > div[role="rowgroup"] div[role="row"]',
    placement: 'bottom',
    title: t({ id: 'tour.onboarding.3.title', message: 'Open your model' }),
  },
  {
    ...commonStepConfig,
    content: (
      <Standard>
        <p>
          {t({
            id: 'tour.onboarding.4.description.0',
            message: 'Ok Welcome to the Model Editor!',
          })}
        </p>
        <br />
        <p>
          {t({
            id: 'tour.onboarding.4.description.1',
            message:
              'On the left is the Library, where you’ll find all the blocks available for you to use.',
          })}
        </p>
        <br />
        <p>
          {t({
            id: 'tour.onboarding.4.description.2',
            message:
              'Go ahead and drag an Adder block from the Library to the Canvas.',
          })}
        </p>
      </Standard>
    ),
    target: '.tour-onboarding-4',
    disableOverlay: true,
    placement: 'right-start',
    title: t({ id: 'tour.onboarding.4.title', message: 'The Model Editor' }),
  },
  {
    ...commonStepConfig,
    content: (
      <Standard>
        <p>
          {t({
            id: 'tour.onboarding.5.description.0',
            message: 'Nicely done!',
          })}
        </p>
        <br />
        <p>
          {t({
            id: 'tour.onboarding.5.description.1',
            message:
              'To view or edit the properties of a block, select it. It’s properties appear in the Properties Panel to the right.',
          })}
        </p>
        <br />
        <p>
          {t({
            id: 'tour.onboarding.5.description.2',
            message:
              'In the “Inputs” section, please add an input to our Adder block',
          })}
        </p>
      </Standard>
    ),
    target: '.tour-onboarding-5',
    disableOverlay: true,
    placement: 'left',
    title: t({ id: 'tour.onboarding.5.title', message: 'Block properties' }),
  },
  {
    ...commonStepConfig,
    content: (
      <Standard>
        <p>
          {t({
            id: 'tour.onboarding.6.description.0',
            message: 'Great!',
          })}
        </p>
        <br />
        <p>
          {t({
            id: 'tour.onboarding.6.description.1',
            message:
              'To create an actual model, you need to connect some blocks.',
          })}
        </p>
        <br />
        <p>
          {t({
            id: 'tour.onboarding.6.description.2',
            message: 'Start by clicking the output of Signal 1...',
          })}
        </p>
      </Standard>
    ),
    target: '.tour-onboarding-6',
    disableOverlay: true,
    placement: 'right',
    title: t({ id: 'tour.onboarding.6.title', message: 'Connecting blocks' }),
  },
  {
    ...commonStepConfig,
    content: (
      <Standard>
        <p>
          {t({
            id: 'tour.onboarding.7.description.0',
            message:
              '...then click an input on the Adder to connect the two blocks.',
          })}
        </p>
      </Standard>
    ),
    target: '.tour-onboarding-7',
    disableOverlay: true,
    placement: 'right',
    title: t({ id: 'tour.onboarding.7.title', message: 'Connecting blocks' }),
  },
  {
    ...commonStepConfig,
    content: (
      <Standard>
        <p>
          {t({
            id: 'tour.onboarding.8.description.0',
            message: 'Easy!',
          })}
        </p>
        <br />
        <p>
          {t({
            id: 'tour.onboarding.8.description.1',
            message:
              'You can also branch lines using taps. Start a tap by double-clicking this line, then connect it to the input of the Gain block.',
          })}
        </p>
      </Standard>
    ),
    target: '.tour-onboarding-8',
    disableOverlay: true,
    placement: 'top',
    title: t({ id: 'tour.onboarding.8.title', message: 'Branching lines' }),
  },
  {
    ...commonStepConfig,
    content: (
      <Standard>
        <p>
          {t({
            id: 'tour.onboarding.9.description.0',
            message: "We're almost there!",
          })}
        </p>
        <br />
        <p>
          {t({
            id: 'tour.onboarding.9.description.1',
            message:
              'In Collimator, we don’t need blocks to view the outputs of other blocks. To view the output of the Adder, just click the Visualizer icon at its top right.',
          })}
        </p>
      </Standard>
    ),
    target: '.tour-onboarding-9',
    disableOverlay: true,
    placement: 'right',
    title: t({ id: 'tour.onboarding.9.title', message: 'Visualizer Toggle' }),
  },
  {
    ...commonStepConfig,
    content: (
      <Standard>
        <p>
          {t({
            id: 'tour.onboarding.10.description.0',
            message: 'Let’s see what we’ve got!',
          })}
        </p>
        <br />
        <p>
          {t({
            id: 'tour.onboarding.10.description.1',
            message:
              'Click the Play button to start your simulation and see the results.',
          })}
        </p>
      </Standard>
    ),
    target: '.tour-onboarding-0',
    disableOverlay: true,
    placement: 'bottom',
    title: t({ id: 'tour.onboarding.10.title', message: 'Run a simulation' }),
  },
  {
    ...commonStepConfig,
    content: (
      <Standard>
        <p>
          {t({
            id: 'tour.onboarding.11.description.0',
            message:
              'Et voilà! Your model has produced some interesting output!',
          })}
        </p>
      </Standard>
    ),
    target: '.tour-onboarding-11',
    disableOverlay: true,
    placement: 'top-start',
    title: t({ id: 'tour.onboarding.11.title', message: 'Visualizer output' }),
  },
  {
    ...commonStepConfig,
    content: (
      <Standard>
        <p>
          {t({
            id: 'tour.onboarding.12.description.0',
            message:
              'You’re all set. Just remember that if you have any questions or comments, we’re just a click away.',
          })}
        </p>
        <br />
        <p>
          {t({
            id: 'tour.onboarding.12.description.1',
            message: 'Happy exploring!',
          })}
        </p>
      </Standard>
    ),
    target: '#launcher',
    disableOverlay: false,
    placement: 'top-start',
    title: t({
      id: 'tour.onboarding.12.title',
      message: 'We’re just a click away',
    }),
  },
];

export default steps;
