import { usePostDocumentationBlockSearchMutation } from 'app/apiGenerated/generatedApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CallbackResult, GptFunction } from 'app/openai';
import { projectActions } from 'app/slices/projectSlice';
import { GptFunctionName } from 'app/third_party_types/chat-types';
import React, { useState } from 'react';
import {
  indent,
  useExecutePythonWithSimResults,
  usePythonModelCallback,
} from './PythonHooks';
import { useSimCompleteListener } from './SimCompleteListenerProvider';
import { BlockDocMap } from './blockDocMap';
import { useModelEditorInfo } from './useModelEditorInfo';
import { usePythonToJsonConverter } from './usePythonToJsonConverter';
import { useUiModelUpdater } from './useUiModelUpdater';

const IMPORT_ANALYSIS_LIBS = `
import pandas as pd
import numpy as np
`;

const IMPORT_PLOT_LIBS = `
import matplotlib
matplotlib.use('agg')
import matplotlib.pyplot as plt
from io import BytesIO
import base64
`;

const SAVE_PLOT = `buf = BytesIO()
plt.savefig(buf, format='png')
buf.seek(0)
results = base64.b64encode(buf.read()).decode()
`;

export const useFunctions = (
  addPlot: (plot: string) => number,
): { [key in GptFunctionName]: GptFunction } => {
  const [compileSubscriberIds, setCompileSubscriberIds] = useState<
    number[] | undefined
  >();
  const [runSubscriberIds, setRunSubscriberIds] = useState<
    number[] | undefined
  >();
  const dispatch = useAppDispatch();

  const { getCurrentModelInPython, validateCurrentPythonModel } =
    usePythonModelCallback();

  const {
    subscribeToNextSimCompileComplete,
    subscribeToNextSimRunComplete,
    unsubscribeToNextSimCompileComplete,
    unsubscribeToNextSimRunComplete,
  } = useSimCompleteListener();

  const { editorMode, isModelReadOnly } = useModelEditorInfo();
  const updateUiModel = useUiModelUpdater();

  const convertPythonToJson = usePythonToJsonConverter();

  const executePythonWithSimResults = useExecutePythonWithSimResults();

  const [searchBlockDocTrigger] = usePostDocumentationBlockSearchMutation();

  const { chatFunctionsV2 } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const runSimCallback = React.useCallback(
    (args: any, onComplete: (r: CallbackResult) => void) => {
      // TODO: show popup to confirm simulation run
      const subscriberId = subscribeToNextSimRunComplete((inputs) => {
        if (!inputs.error) {
          const dfColumns = inputs.columnNames?.join(', ') || '';
          onComplete({
            result: `simulation completed successfully. Available df columns: ${dfColumns}`,
          });
        } else {
          onComplete({ error: inputs.error });
        }
      });
      setRunSubscriberIds((ids) => [...(ids || []), subscriberId]);
      dispatch(
        projectActions.requestRun({ autoSwitchBottomTabOnSimDone: false }),
      );
    },
    [dispatch, subscribeToNextSimRunComplete],
  );

  // unsubscribe on unmount
  React.useEffect(
    () => () => {
      runSubscriberIds?.forEach((id) => {
        unsubscribeToNextSimRunComplete(id);
      });
      compileSubscriberIds?.forEach((id) => {
        unsubscribeToNextSimCompileComplete(id);
      });
    },
    [
      compileSubscriberIds,
      runSubscriberIds,
      unsubscribeToNextSimCompileComplete,
      unsubscribeToNextSimRunComplete,
    ],
  );

  const validateUserModelCallback = React.useCallback(
    async (args: any, onComplete: (r: CallbackResult) => void) => {
      if (isModelReadOnly) {
        onComplete({
          error: "read-only models can't be validated.",
        });
        return;
      }
      const pyError = await validateCurrentPythonModel();
      dispatch(
        projectActions.requestCheck({ autoSwitchBottomTabOnSimDone: false }),
      );
      const id = subscribeToNextSimCompileComplete((inputs) => {
        const compileResult = inputs.error || 'model compiled successfully';
        if (!pyError && !inputs.error) {
          onComplete({
            result:
              'python check: the python code is valid; compilation check: model compiled successfully',
          });
        } else {
          onComplete({
            error: `python check: ${
              pyError || 'The python code is valid'
            }; compilation check: ${compileResult}`,
          });
        }
      });
      setCompileSubscriberIds((ids) => [...(ids || []), id]);
    },
    [
      dispatch,
      isModelReadOnly,
      subscribeToNextSimCompileComplete,
      validateCurrentPythonModel,
    ],
  );

  const executePythonCallback = React.useCallback(
    async (args, onComplete) => {
      executePythonWithSimResults({
        code: args.code,
        onComplete,
        onGetPythonResults: (result) => onComplete({ result }),
        importStatements: IMPORT_ANALYSIS_LIBS,
        finalizeStatements: 'results = str(results)',
        allowDirty: args.allowDirty,
      });
    },
    [executePythonWithSimResults],
  );

  const plotCallback = React.useCallback(
    (args, onComplete) => {
      executePythonWithSimResults({
        code: args.code,
        onComplete,
        onGetPythonResults: (results) => {
          const plotId = addPlot(results);
          onComplete({ result: `[[plot_id:${plotId}]]` });
        },
        importStatements: IMPORT_PLOT_LIBS,
        finalizeStatements: SAVE_PLOT,
        allowDirty: args.allowDirty,
      });
    },
    [executePythonWithSimResults, addPlot],
  );

  const updateModelCallback = React.useCallback(
    async (args: any, onComplete) => {
      if (isModelReadOnly) {
        onComplete({
          error: "read-only models can't be modified.",
        });
        return;
      }
      const { code, is_new } = args;
      try {
        const jsonModel = await convertPythonToJson(code, is_new, editorMode);
        await updateUiModel(jsonModel, editorMode, is_new || chatFunctionsV2);

        onComplete({
          result: 'Model updated.',
        });
      } catch (e: any) {
        onComplete({ error: `${e.message}\nModel was not updated.` });
      }
    },
    [
      chatFunctionsV2,
      convertPythonToJson,
      editorMode,
      isModelReadOnly,
      updateUiModel,
    ],
  );

  const buildGroupCallback = React.useCallback(
    async (args: any, onComplete) => {
      const { name, code } = args;
      const groupModelBuilderCode = `
def make_group_model_builder():
${indent(code, 4)}
model_builder.add_group("${name}", make_group_model_builder())
`;
      updateModelCallback(
        { code: groupModelBuilderCode, is_new: false },
        onComplete,
      );
    },
    [updateModelCallback],
  );

  const buildSubmodelCallback = React.useCallback(
    async (args: any, onComplete) => {
      onComplete({
        error:
          'Building submodel is not implemented yet. ' +
          'However you can build a group and convert it to a submodel through the interface.',
      });
    },
    [],
  );

  const getUserModelCallback = React.useCallback(
    async (args: any, onComplete) => {
      let pyModel;
      try {
        pyModel = await getCurrentModelInPython({
          output_groups: false,
          output_submodels: false,
        });
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          console.error(e);
        }
      }
      if (!pyModel) {
        onComplete({
          error: 'Could not retrieve current model',
        });
        return;
      }
      onComplete({ result: pyModel.pythonStr });
    },
    [getCurrentModelInPython],
  );

  const searchBlocksCallback = React.useCallback(
    async (args: any, onComplete) => {
      const response = await searchBlockDocTrigger({
        documentationBlockSearchRequest: {
          queries: args.queries,
        },
      })
        .unwrap()
        .catch((e: Error) => {
          onComplete({
            error: `An error occured while searching for blocks: ${e}`,
          });
        });
      if (!response) {
        onComplete({
          error: `An error occured while searching for blocks`,
        });
        return;
      }

      const blocksDoc = response.map(
        (blockName) => BlockDocMap[blockName] || 'Not found',
      );
      onComplete({ result: blocksDoc.join('\n---\n') });
    },
    [searchBlockDocTrigger],
  );

  const addParameterCallback = React.useCallback(
    async (args: any, onComplete) => {
      const { name, value } = args;
      const code = `model_builder.add_parameter("${name}", ${value})`;
      updateModelCallback({ code, is_new: false }, onComplete);
    },
    [updateModelCallback],
  );

  const addBlockCallback = React.useCallback(
    async (args: any, onComplete) => {
      const { name, type, parameters } = args;
      let code;
      if (parameters) {
        const parametersArray = Object.entries(parameters).map(
          ([key, value]) => `${key}="${value}"`,
        );
        const parametersStr = parametersArray.join(', ');
        code = `block = core.${type}(name="${name}", ${parametersStr})`;
      } else {
        code = `block = core.${type}(name="${name}")`;
      }
      code = `${code}\nmodel_builder.add_block(block)`;
      updateModelCallback({ code, is_new: false }, onComplete);
    },
    [updateModelCallback],
  );

  const removeBlockCallback = React.useCallback(
    async (args: any, onComplete) => {
      const { name } = args;
      const code = `model_builder.remove_block("${name}")`;
      updateModelCallback({ code, is_new: false }, onComplete);
    },
    [updateModelCallback],
  );

  const addLinkCallback = React.useCallback(
    async (args: any, onComplete) => {
      const { src, dst } = args;
      const code = `model_builder.add_link("${src}", "${dst}")`;
      updateModelCallback({ code, is_new: false }, onComplete);
    },
    [updateModelCallback],
  );

  const removeLinkCallback = React.useCallback(
    async (args: any, onComplete) => {
      const { dst } = args;
      const code = `model_builder.remove_link(${dst})`;
      updateModelCallback({ code, is_new: false }, onComplete);
    },
    [updateModelCallback],
  );

  const clearModelCallback = React.useCallback(
    async (args: any, onComplete) => {
      updateModelCallback({ code: 'print("")', is_new: true }, onComplete);
    },
    [updateModelCallback],
  );

  return {
    run_simulation: {
      callback: runSimCallback,
    },
    validate_user_model: {
      callback: validateUserModelCallback,
    },
    execute_python: {
      callback: executePythonCallback,
    },
    build_model: {
      callback: updateModelCallback,
    },
    build_group: {
      callback: buildGroupCallback,
    },
    build_submodel: {
      callback: buildSubmodelCallback,
    },
    plot: {
      callback: plotCallback,
    },
    get_user_model: {
      callback: getUserModelCallback,
    },
    search_blocks: {
      callback: searchBlocksCallback,
    },
    add_parameter: {
      callback: addParameterCallback,
    },
    add_block: {
      callback: addBlockCallback,
    },
    remove_block: {
      callback: removeBlockCallback,
    },
    add_link: {
      callback: addLinkCallback,
    },
    remove_link: {
      callback: removeLinkCallback,
    },
    clear_model: {
      callback: clearModelCallback,
    },
  };
};
