import { t } from '@lingui/macro';

export type ReadonlyTimeModeValueType =
  | 'agnostic'
  | 'discrete'
  | 'continuous'
  | 'constant';
export const readonlyTimeModes: Array<{
  label: string;
  value: ReadonlyTimeModeValueType;
}> = [
  {
    label: t({
      id: 'blockDetails.TimeModeOption.Agnostic',
      message: 'Agnostic',
    }),
    value: 'agnostic',
  },
  {
    label: t({
      id: 'blockDetails.TimeModeOption.Continuous',
      message: 'Continuous',
    }),
    value: 'continuous',
  },
  {
    label: t({
      id: 'blockDetails.TimeModeOption.Constant',
      message: 'Constant',
    }),
    value: 'constant',
  },
  {
    label: t({
      id: 'blockDetails.TimeModeOption.Discrete',
      message: 'Discrete',
    }),
    value: 'discrete',
  },
];

export type TimeModeValueType = 'agnostic' | 'discrete';
export const inheritedTimeModes: Array<{
  label: string;
  value: TimeModeValueType;
}> = [
  {
    label: t({
      id: 'blockDetails.TimeModeOption.Agnostic',
      message: 'Agnostic',
    }),
    value: 'agnostic',
  },
  {
    label: t({
      id: 'blockDetails.TimeModeOption.Discrete',
      message: 'Discrete',
    }),
    value: 'discrete',
  },
];
export const sourceTimeModes: Array<{
  label: string;
  value: TimeModeValueType;
}> = [
  {
    label: t({
      id: 'blockDetails.TimeModeOption.Continuous',
      message: 'Continuous',
    }),
    value: 'agnostic',
  },
  {
    label: t({
      id: 'blockDetails.TimeModeOption.Discrete',
      message: 'Discrete',
    }),
    value: 'discrete',
  },
];
