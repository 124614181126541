import { ValidationRule } from 'ui/common/Input/inputValidation';

export enum ParameterType {
  String,
}

export interface ParameterModel<TValue = string> {
  parameterType: ParameterType;
  id: string;
  label: string;
  lastServerValue: TValue | null;
  currentValue: TValue | null;
  tooltip?: string;
}

export interface StringParameterModel extends ParameterModel {
  /**
   * Text that the client should display to represent an underlying
   * null value.  If the user types this string value, we will represent
   * the value internally as null.
   */
  nullDisplayString?: string;
  validationRules?: ValidationRule[];
}

export interface StringParameterOptions {
  id: string;
  label: string;
  lastServerValueRaw: any;
  nullDisplayString?: string;
  validationRules?: ValidationRule[];
  tooltip?: string;
}

export function createStringParameterModel({
  id,
  label,
  lastServerValueRaw,
  nullDisplayString,
  validationRules,
  tooltip,
}: StringParameterOptions): StringParameterModel {
  let lastServerValue;
  if (
    nullDisplayString &&
    (lastServerValueRaw === null || lastServerValueRaw === undefined)
  ) {
    lastServerValue = null;
  } else {
    lastServerValue = (lastServerValueRaw ?? '').toString();
  }

  return {
    parameterType: ParameterType.String,
    id,
    label,
    lastServerValue,
    currentValue: lastServerValue,
    nullDisplayString,
    validationRules,
    tooltip,
  };
}
