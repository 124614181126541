import { useEffect } from 'react';

/**
 * Attach `handler` to the `eventName` event of the `echartsInstance`
 * @param handler identity must be stable for cleanup
 */
export function useEChartsHandler(
  echartsInstance: echarts.ECharts | undefined,
  eventName: string,
  // TODO: Clean up these old handler signatures
  handler: (e: any) => void | undefined,
) {
  useEffect(() => {
    if (echartsInstance === undefined || !eventName || handler === undefined)
      return;

    echartsInstance.on(eventName, handler);
    return () => {
      echartsInstance.off(eventName, handler);
    };
  }, [echartsInstance, handler, eventName]);
}
