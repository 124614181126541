/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Dimensions: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 2.93934L3.46967 5.46967C3.17678 5.76256 3.17678 6.23744 3.46967 6.53033C3.76256 6.82322 4.23744 6.82322 4.53033 6.53033L5.25 5.81066V17.5C5.25 18.1904 5.80964 18.75 6.5 18.75H18.1893L17.4697 19.4697C17.1768 19.7626 17.1768 20.2374 17.4697 20.5303C17.7626 20.8232 18.2374 20.8232 18.5303 20.5303L21.0607 18L18.5303 15.4697C18.2374 15.1768 17.7626 15.1768 17.4697 15.4697C17.1768 15.7626 17.1768 16.2374 17.4697 16.5303L18.1893 17.25H6.75V5.81066L7.46967 6.53033C7.76256 6.82322 8.23744 6.82322 8.53033 6.53033C8.82322 6.23744 8.82322 5.76256 8.53033 5.46967L6 2.93934Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.5 8.5C8.5 7.94772 8.94772 7.5 9.5 7.5H15.5C16.0523 7.5 16.5 7.94772 16.5 8.5V14.5C16.5 15.0523 16.0523 15.5 15.5 15.5H9.5C8.94772 15.5 8.5 15.0523 8.5 14.5V8.5ZM9.5 8.5H15.5V14.5H9.5V8.5Z"
      fill={props.fill}
    />
  </svg>
);

Dimensions.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Dimensions;
