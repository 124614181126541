import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React from 'react';
import {
  Row,
  useFlexLayout,
  useRowSelect,
  useRowState,
  useSortBy,
  useTable,
} from 'react-table';

import { GenericTable } from './GenericTable';

const Wrapper = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.text.secondary};

  tbody > tr {
    cursor: pointer;
  }
`;

const THead = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[10]};
  margin-bottom: ${(props) => props.theme.spacing.large};
  padding: 0 ${(props) => props.theme.spacing.xsmall};
`;

const HeadTR = styled.div`
  height: ${(props) => props.theme.spacing.xlarge};
`;

const ColumnHead = styled.div`
  &:not(:first-of-type) {
    margin-left: ${({ theme }) => theme.spacing.normal};
  }
`;

const Header = styled.div<{ isSorted: boolean }>`
  display: flex;
  font-weight: ${({ isSorted }) => (isSorted ? 600 : 'normal')};
`;

const SortArrows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-left: ${(props) => props.theme.spacing.normal};
`;

export const BodyTR = styled.div<{
  isSelected: boolean;
  hasNavigation: boolean;
}>`
  ${({
    isSelected,
    hasNavigation,
    theme: {
      spacing: { xsmall, small, normal },
      colors: {
        tint: { tint1 },
        grey,
      },
    },
  }) => `
  border-radius: ${small};
  cursor: ${hasNavigation ? 'pointer' : 'default'};
  padding: ${normal} ${small};
  margin-bottom: ${xsmall};
  &:hover {
    background-color: ${isSelected ? tint1 : grey[10]};
  }
  background-color: ${isSelected ? tint1 : 'inherit'};
  align-items: top;
  font-weight: ${isSelected ? '600' : 'normal'};
  user-select: ${hasNavigation ? 'none' : 'text'}
  `};
`;

const Cell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  overflow-wrap: break-word;
  word-break: break-all;
  line-height: ${({ theme }) => theme.typography.font.large.lineHeight};
  font-size: ${({ theme }) => theme.typography.font.large.size};
  font-weight: ${({ theme }) => theme.typography.font.large.weight};
  &:not(:first-of-type) {
    margin-left: ${({ theme }) => theme.spacing.normal};
  }
`;

interface Props<T extends Record<string, unknown>> extends GenericTable<T> {
  enableMultiSelect?: boolean; // TODO add support for this
}

const Table = <T extends Record<string, unknown>>({
  columns,
  data,
  initialState,
  getColumnProps,
  className,
  onRowClick,
  onRowDoubleClick,
  testId,
  getRowTestId,
}: Props<T>): React.ReactElement => {
  const theme = useTheme() as Theme;
  const tableInstance = useTable<T>(
    {
      columns,
      data,
      initialState,
    },
    useSortBy,
    useRowSelect,
    useFlexLayout,
    useRowState,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
  } = tableInstance;

  const selectRow = (row: Row<T>) => {
    toggleAllRowsSelected(false);
    row.toggleRowSelected(true);
  };
  return (
    // apply the table props
    <Wrapper {...getTableProps()} className={className} data-test-id={testId}>
      <THead>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <HeadTR {...headerGroup.getHeaderGroupProps()}>
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props

                  <ColumnHead
                    {...column.getHeaderProps([
                      column.getSortByToggleProps(),
                      getColumnProps ? getColumnProps(column) : {},
                    ])}>
                    <Header isSorted={column.isSorted}>
                      {column.render('Header')}
                      {column.canSort ? (
                        <SortArrows>
                          <svg
                            width={6}
                            height={3}
                            fill={
                              column.isSorted && !column.isSortedDesc
                                ? theme.colors.grey[70]
                                : theme.colors.grey[30]
                            }>
                            <path d="M0 3 L3 0 L6 3 L0 3" />
                          </svg>
                          <svg
                            width={6}
                            height={3}
                            fill={
                              column.isSorted && column.isSortedDesc
                                ? theme.colors.grey[70]
                                : theme.colors.grey[30]
                            }>
                            <path d="M0 0 L6 0 L3 3 L0 0" />
                          </svg>
                        </SortArrows>
                      ) : null}
                    </Header>
                  </ColumnHead>
                ))
              }
            </HeadTR>
          ))
        }
      </THead>
      {/* Apply the table body props */}
      <div {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <BodyTR
                onClick={() => selectRow(row)}
                {...row.getRowProps()}
                data-test-id={getRowTestId?.(row)}
                isSelected={row.isSelected}
                hasNavigation={onRowDoubleClick !== undefined}>
                {
                  // Loop over the rows cells
                  row.cells.map((cell) => (
                    // Apply the cell props
                    <Cell
                      {...cell.getCellProps()}
                      onClick={() => {
                        onRowClick?.(row.original);
                      }}
                      onDoubleClick={() => {
                        onRowDoubleClick?.(row.original);
                      }}>
                      {
                        // Render the cell contents
                        cell.render('Cell')
                      }
                    </Cell>
                  ))
                }
              </BodyTR>
            );
          })
        }
      </div>
    </Wrapper>
  );
};

export default Table;
