import React from 'react';
import TermsOfServiceLoader from 'ui/tos/TermsOfServiceLoader';
import { RedirectToSignIn, SignedIn, SignedOut } from './AuthComponents';

const ProtectedRoute: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => (
  <>
    <SignedIn>
      <TermsOfServiceLoader>{children}</TermsOfServiceLoader>
    </SignedIn>
    <SignedOut>
      <RedirectToSignIn />
    </SignedOut>
  </>
);

export default ProtectedRoute;
