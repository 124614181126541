// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const IntegratorDiscrete: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "IntegratorDiscrete",
      "namespace": "core",
      "description": "Integrates a function.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#integrator-discrete",
      "supports_experiments": true,
      "supports_iterator": false
    },
    "modes": {
      "time": "discrete",
      "feedthrough": false
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ],
        "conditional": [
          {
            "name": "reset",
            "order": 1,
            "appearance": "hollow"
          },
          {
            "name": "reset_value",
            "order": 2
          },
          {
            "name": "hold",
            "order": 3
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "initial_states",
        "data_type": "float",
        "default_value": "0.0",
        "description": "initial value of discrete integrator state"
      },
      {
        "name": "enable_reset",
        "data_type": "bool",
        "default_value": "false",
        "description": "enable the reset function"
      },
      {
        "name": "enable_external_reset",
        "data_type": "bool",
        "default_value": "true",
        "description": "source for the value used to reset the integrator state: True=from inport, False=from the initial_state parameter"
      },
      {
        "name": "enable_hold",
        "data_type": "bool",
        "default_value": "false",
        "description": "enable the hold function"
      },
      {
        "name": "enable_limits",
        "data_type": "bool",
        "default_value": "false",
        "description": "enable the limit function"
      },
      {
        "name": "upper_limit",
        "description": "upper limit to impose on the output",
        "data_type": "float",
        "default_value": "1.0"
      },
      {
        "name": "lower_limit",
        "description": "lower limit to impose on the output",
        "data_type": "float",
        "default_value": "-1.0"
      }
    ]
  }
);
