// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const RandomNormal: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "RandomNormal",
      "namespace": "core",
      "description": "Generates normally distributed random numbers",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#random-normal",
      "feature_level": "dev"
    },
    "modes": {
      "time": "discrete"
    },
    "ports": {
      "outputs": {
        "static": [
          {
            "name": "out_0",
            "parameter_definitions": [
              {
                "name": "shape",
                "data_type": "any",
                "default_value": "()",
                "description": "Shape or dimension of the output signal, follows numpy syntax like: (2,3) or ()",
                "feature_level": "dev"
              }
            ]
          }
        ]
      }
    },
    "parameter_definitions": []
  }
);
