import { v4 as uuid } from 'uuid';
import { blockClassLookup } from './generated_blocks';
import {
  BlockInstance,
  BlockClassName,
  Parameters,
  Port,
  SubmodelInstance,
} from './generated_types/SimulationModel';
import { makeParameter } from './helpers';
import { getDefaultPortParameters } from './utils/portUtils';

export const generateInName = (index: number): string => `in_${index}`;

export const generateOutName = (index: number): string => `out_${index}`;

const blockTypeNameToInstanceDefaults = (
  blockClassName: BlockClassName,
  premadeUuid?: string,
  referenceSubmodelId?: string,
): BlockInstance => {
  const blockClassDefaults = blockClassLookup(blockClassName);
  const parameters: Parameters =
    blockClassDefaults.parameter_definitions?.reduce((acc, defaultParam) => {
      if (defaultParam.data_type) {
        return {
          ...acc,
          [defaultParam.name]: makeParameter(
            defaultParam.default_value,
            defaultParam,
          ),
        };
      }
      return acc;
    }, {}) || {};

  // We have 3 kinds of ports on each block:
  // - static ports: can't be added or removed
  // - dynamic ports: added and removed at will by the user
  // - conditional ports: enabled only given a certain condition is met
  // NOTE: We might end up with a 4th kind of port: automatic ports to
  // support Submodel, Demux and similar blocks.

  const inputDefs = blockClassDefaults.ports?.inputs || {};
  const outputDefs = blockClassDefaults.ports?.outputs || {};
  let inputIndex = 0;
  let outputIndex = 0;

  // Static ports
  const staticInputs: Port[] = (inputDefs.static || []).reduce((acc, def) => {
    acc.push({ name: def.name || `in_${inputIndex++}`, kind: 'static' });
    return acc;
  }, [] as Port[]);

  const staticOutputs: Port[] = (outputDefs.static || []).reduce((acc, def) => {
    acc.push({ name: def.name || `out_${outputIndex++}`, kind: 'static' });
    return acc;
  }, [] as Port[]);

  // Conditional ports
  const conditionalInputs: Port[] = (inputDefs.conditional || [])
    .filter((def) => def.default_enabled)
    .map((def) => ({ name: def.name, kind: 'conditional' }));

  const conditionalOutputs: Port[] = (outputDefs.conditional || [])
    .filter((def) => def.default_enabled)
    .map((def) => ({ name: def.name, kind: 'conditional' }));

  // Dynamic ports
  const inputPortParams = getDefaultPortParameters(
    blockClassDefaults.ports.inputs?.dynamic?.parameter_definitions,
  );
  const outputPortParams = getDefaultPortParameters(
    blockClassDefaults.ports.outputs?.dynamic?.parameter_definitions,
  );

  const generateDynamicPorts = (
    count: number,
    pfx: string,
    index: number,
    parameters?: Parameters,
  ): Port[] =>
    [...Array(count)].map(() => ({
      name: `${pfx}_${index++}`,
      kind: 'dynamic',
      parameters,
    }));

  const dynInputCount = inputDefs.dynamic?.default_count || 0;
  const dynOutputCount = outputDefs.dynamic?.default_count || 0;
  const dynamicInputs = referenceSubmodelId
    ? []
    : generateDynamicPorts(dynInputCount, 'in', inputIndex, inputPortParams);
  const dynamicOutputs = referenceSubmodelId
    ? []
    : generateDynamicPorts(
        dynOutputCount,
        'out',
        outputIndex,
        outputPortParams,
      );

  // TODO this logic does not respect `order` for `default_enabled` conditional inputs
  // and it will need to once we add a block type that needs this
  const inputs = [...staticInputs, ...conditionalInputs, ...dynamicInputs];
  const outputs = [...staticOutputs, ...conditionalOutputs, ...dynamicOutputs];

  // Fill in any unspecified port names.
  inputs.forEach((port, index) => {
    if (!port.name) {
      port.name = generateInName(index);
    }
  });
  outputs.forEach((port, index) => {
    if (!port.name) {
      port.name = generateOutName(index);
    }
  });

  // Time mode is only used for blocks with time_mode of 'any' type
  // and always defaults to 'agnostic'.
  // For other blocks, the time mode is determined by the block type
  // rather than the block instance.
  let time_mode: 'discrete' | 'agnostic' | undefined;
  if (blockClassDefaults.modes.time === 'any') {
    time_mode = 'agnostic';
  }

  const uiDefaults = blockClassDefaults.ui_defaults || {};

  const instance: BlockInstance = {
    // $class_version: "0.1",
    uuid: premadeUuid || uuid(),
    type: blockClassName,
    name: '',
    inputs,
    outputs,
    parameters,
    time_mode,
    uiprops: {
      ...uiDefaults,
      x: 0,
      y: 0,
      port_alignment: 'spaced',
    },
    file_outputs: blockClassDefaults.file_outputs,
  };

  if (blockClassName === 'core.ReferenceSubmodel') {
    const submodelInstance = instance as SubmodelInstance;
    submodelInstance.submodel_reference_uuid = referenceSubmodelId;
    submodelInstance.uiprops.show_port_name_labels = true;
  }

  return instance;
};

export default blockTypeNameToInstanceDefaults;
