// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const VideoSource: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "VideoSource",
      "namespace": "core",
      "description": "Loads a video file and outputs it frame by frame. Output is a tensor of shape 3 x W x H in RGB color space.",
      "development_notes": "We need tensor support. We also need to detect width/height automatically.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#videosource"
    },
    "modes": {
      "time": "discrete"
    },
    "ports": {
      "outputs": {
        "static": [
          {
            "name": "out_frame"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "file_name",
        "description": "Name of the image file to load.",
        "data_type": "string",
        "default_value": ""
      }
    ]
  }
);
