const SAVE_FILE = '#save-file';
const SAVE_NOTEBOOK =
  'button[data-jupyter-action="jupyter-notebook:save-notebook"]';
const INSERT_CELL_BUTTON =
  'button[data-jupyter-action="jupyter-notebook:insert-cell-below"]';
const CUT_CELL_BUTTON =
  'button[data-jupyter-action="jupyter-notebook:cut-cell"]';
const COPY_CELL_BUTTON =
  'button[data-jupyter-action="jupyter-notebook:copy-cell"]';
const PASTE_CELL_BUTTON =
  'button[data-jupyter-action="jupyter-notebook:paste-cell-below"]';
const RUN_CELL_BUTTON =
  'button[data-jupyter-action="jupyter-notebook:run-cell-and-select-next"]';
const STOP_KERNEL_BUTTON =
  'button[data-jupyter-action="jupyter-notebook:interrupt-kernel"]';
const RESTART_KERNEL_BUTTON =
  'button[data-jupyter-action="jupyter-notebook:confirm-restart-kernel"]';
const RESTART_RERUN_BUTTON =
  'button[data-jupyter-action="jupyter-notebook:confirm-restart-kernel-and-run-all-cells"]';
const HEADER = '#header';
const LOADER = '#jupyterlab-splash';
const CELL_TYPE_SELECT = '#cell_type';
const ERROR_MESSAGE = '.error > h1';
const MODAL_TITLE = '.modal-title';
const SERVER_MESSAGE = '.text-center > p';

const selectors = {
  saveFileButton: () => SAVE_FILE,
  saveNotebookButton: () => SAVE_NOTEBOOK,
  loader: () => LOADER,
  insertCellButton: () => INSERT_CELL_BUTTON,
  runCellButton: () => RUN_CELL_BUTTON,
  cutCellButton: () => CUT_CELL_BUTTON,
  copyCellButton: () => COPY_CELL_BUTTON,
  pasteCellButton: () => PASTE_CELL_BUTTON,
  stopKernelButton: () => STOP_KERNEL_BUTTON,
  restartKernelButton: () => RESTART_KERNEL_BUTTON,
  restartRerunButton: () => RESTART_RERUN_BUTTON,
  header: () => HEADER,
  cellTypeSelect: () => CELL_TYPE_SELECT,
  errorMessage: () => ERROR_MESSAGE,
  modalTitle: () => MODAL_TITLE,
  serverMessage: () => SERVER_MESSAGE,
};

export default selectors;
