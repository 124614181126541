import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ArrowRight } from 'ui/common/Icons/Standard';
import { useModal } from 'ui/common/Modal/useModal';
import ApiKeysModal from 'ui/navbar/ApiKeysModal';
import {
  AssistantRow,
  AiAvatar,
  MessageBlockDiv,
  SuggestionButton,
  SuggestionButtonRow,
} from './Styles';

const MessageDiv = styled.div`
  flex-direction: column;
  align-items: flex-start;
`;

export enum ProgressStatus {
  None,
  Plotting = 'Creating plots',
  AnalyzingResults = 'Analyzing results',
  Building = 'Building model',
  Fixing = 'An error occured, troubleshooting',
  RunningSimulation = 'Running simulation',
}

export const WelcomeMessage = () => (
  <AssistantRow key={-1}>
    <AiAvatar />
    <MessageDiv>
      <MessageBlockDiv>
        Hi, welcome to Collimator Chat! I&apos;m still a work in progress, but I
        can help you create, explain how something works, and assist you in
        optimizing your models.
      </MessageBlockDiv>
      <MessageBlockDiv>
        Collimator Chat is currently in beta, and may give inaccurate or
        misleading information, generate incorrect diagrams and code, or produce
        offensive language. Before using the output of Chat in a production
        system, you should verify it independently. Chat is subject to our{' '}
        <Link to="/tos">Terms of Service</Link> and{' '}
        <a href="https://www.collimator.ai/company/privacy-policy">
          Privacy Policy
        </a>
        .
      </MessageBlockDiv>
      <MessageBlockDiv>
        Collimator Chat uses OpenAI&apos;s ChatGPT to power it. Please review
        OpenAI&apos;s <a href="https://openai.com/policies">Terms of Service</a>{' '}
        and <a href="https://openai.com/policies">Privacy Policy</a> before
        using Chat.
      </MessageBlockDiv>
    </MessageDiv>
  </AssistantRow>
);

export const CallCountExceededMessage = () => {
  const { triggerModal } = useModal();

  const apiKeysModal = () => {
    triggerModal(<ApiKeysModal tab="openAiKey" />, undefined, {
      preventOverflow: true,
    });
  };

  return (
    <AssistantRow>
      <AiAvatar />
      <MessageDiv>
        <MessageBlockDiv>
          Thank you for trying Collimator Chat. You have now used up your
          complimentary prompts. To continue using this feature, go to{' '}
          <a href="https://platform.openai.com/account/api-keys">
            https://platform.openai.com/account/api-keys
          </a>{' '}
          and follow the instructions to generate an API Key for your account.
          Copy the secret to your clipboard, and return to this window to enter
          it here.
        </MessageBlockDiv>
        <SuggestionButtonRow>
          <SuggestionButton
            variant={ButtonVariants.SmallSecondary}
            Icon={ArrowRight}
            onClick={apiKeysModal}>
            {t`I want to enter my OpenAI API key`}
          </SuggestionButton>
        </SuggestionButtonRow>
      </MessageDiv>
    </AssistantRow>
  );
};

export const ProcessingMessage = ({ status }: { status: ProgressStatus }) => (
  <AssistantRow>
    <AiAvatar spin />
    <MessageDiv>
      <MessageBlockDiv>
        {status === ProgressStatus.None
          ? t`Please hold on while I process your request...`
          : `Please hold on while I process your request... (${status})`}
      </MessageBlockDiv>
    </MessageDiv>
  </AssistantRow>
);
