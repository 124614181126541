import { StateMachineDiagram } from '@collimator/model-schemas-ts';
import { PayloadAction } from '@reduxjs/toolkit';
import { Coordinate } from 'app/common_types/Coordinate';
import {
  StateLinkInstance,
  StateNodeInstance,
} from 'app/generated_types/SimulationModel';
import { ModelState } from 'app/modelState/ModelState';
import { StateNodeSide } from 'state_machine_tempdir/SMETypes';
import { snapNumberToGrid } from './modelDataUtils';

export const addStateNode = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    newStateNode: StateNodeInstance;
  }>,
) => {
  const { stateMachineUuid, newStateNode } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  stateMachine.nodes.push(newStateNode);
};

type PriorityListSortData = { linkId: string; coord?: number };
type PriorityListSortDataList = Array<PriorityListSortData>;

const priorityListSortFunc = (
  a: PriorityListSortData,
  b: PriorityListSortData,
) => {
  const aCoord = a.coord ?? 0;
  const bCoord = b.coord ?? 0;
  if (aCoord < bCoord) return -1;
  if (aCoord > bCoord) return 1;
  return 0;
};

const getJustLinkId = (data: PriorityListSortData): string => data.linkId;

const calculateAndSetStateNodeExitPriority = (
  stateMachine: StateMachineDiagram,
  nodeId: string,
) => {
  const node = stateMachine.nodes.find((n) => n.uuid === nodeId);
  if (!node) return;

  const exitPriorityListTop: PriorityListSortDataList = [];
  const exitPriorityListRight: PriorityListSortDataList = [];
  const exitPriorityListDown: PriorityListSortDataList = [];
  const exitPriorityListLeft: PriorityListSortDataList = [];

  stateMachine.links.forEach((link) => {
    if (link.sourceNodeId === nodeId) {
      switch (link.uiprops.sourceSide) {
        case 'top':
          exitPriorityListTop.push({
            linkId: link.uuid,
            coord: link.uiprops.sourceCoord,
          });
          break;
        case 'right':
          exitPriorityListRight.push({
            linkId: link.uuid,
            coord: link.uiprops.sourceCoord,
          });
          break;
        case 'down':
          exitPriorityListDown.push({
            linkId: link.uuid,
            coord: link.uiprops.sourceCoord,
          });
          break;
        case 'left':
          exitPriorityListLeft.push({
            linkId: link.uuid,
            coord: link.uiprops.sourceCoord,
          });
          break;
      }
    }
  });

  if (node.exit_priority_list) {
    // TODO: remove this check eventually, its for outdated schema compat
    node.exit_priority_list = [
      ...exitPriorityListTop.sort(priorityListSortFunc).map(getJustLinkId),
      ...exitPriorityListRight.sort(priorityListSortFunc).map(getJustLinkId),
      ...exitPriorityListDown
        .sort(priorityListSortFunc)
        .map(getJustLinkId)
        .reverse(),
      ...exitPriorityListLeft
        .sort(priorityListSortFunc)
        .map(getJustLinkId)
        .reverse(),
    ];
  }
};

export const addStateLink = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    newStateLink: StateLinkInstance;
  }>,
) => {
  const { stateMachineUuid, newStateLink } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  stateMachine.links.push(newStateLink);

  if (newStateLink.sourceNodeId) {
    calculateAndSetStateNodeExitPriority(
      stateMachine,
      newStateLink.sourceNodeId,
    );
  }
};

export const repositionLink = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    linkId: string;
    toNodeId: string;
    side: StateNodeSide;
    coord: number;
    forStart: boolean;
  }>,
) => {
  const { stateMachineUuid, linkId, toNodeId, side, coord, forStart } =
    action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  const link = stateMachine.links.find((link) => link.uuid === linkId);
  if (!link) return;

  if (forStart) {
    link.sourceNodeId = toNodeId;
    link.uiprops.sourceSide = side;
    link.uiprops.sourceCoord = coord;
    calculateAndSetStateNodeExitPriority(stateMachine, toNodeId);
  } else {
    link.destNodeId = toNodeId;
    link.uiprops.destSide = side;
    link.uiprops.destCoord = coord;
  }
};

export const setStateMachineEntryPointConnection = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    toNodeId: string;
    side: StateNodeSide;
    coord: number;
  }>,
) => {
  const { stateMachineUuid, toNodeId, side, coord } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  stateMachine.entry_point = {
    dest_id: toNodeId,
    dest_side: side,
    dest_coord: coord,
  };
};

export const moveStateNodesByDelta = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    nodeIds: Array<string>;
    delta: Coordinate;
  }>,
) => {
  const { stateMachineUuid, nodeIds, delta } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  for (let i = 0; i < stateMachine.nodes.length; i++) {
    const node = stateMachine.nodes[i];
    if (nodeIds.includes(node.uuid)) {
      node.uiprops.x += delta.x;
      node.uiprops.y += delta.y;
    }
  }
};

export const snapStateNodesToGrid = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
  }>,
) => {
  const { stateMachineUuid } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  for (let i = 0; i < stateMachine.nodes.length; i++) {
    const node = stateMachine.nodes[i];
    node.uiprops.x = snapNumberToGrid(node.uiprops.x);
    node.uiprops.y = snapNumberToGrid(node.uiprops.y);
  }
};

export const moveStateLinkCurveDeviationByDelta = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    linkId: string;
    delta: Coordinate;
  }>,
) => {
  const { stateMachineUuid, linkId, delta } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  const link = stateMachine.links.find((link) => link.uuid === linkId);
  if (!link) return;

  link.uiprops.curveDeviation.x += delta.x;
  link.uiprops.curveDeviation.y += delta.y;
};

export const setStateLinkGuard = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    linkId: string;
    newGuard: string;
  }>,
) => {
  const { stateMachineUuid, linkId, newGuard } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  const link = stateMachine.links.find((link) => link.uuid === linkId);
  if (!link) return;

  link.guard = newGuard;
};

export const setStateLinkAction = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    linkId: string;
    newAction: string;
  }>,
) => {
  const { stateMachineUuid, linkId, newAction } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  const link = stateMachine.links.find((link) => link.uuid === linkId);
  if (!link) return;

  link.actions = [newAction];
};

export const setStateMachineEntryPointAction = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    newAction: string;
  }>,
) => {
  const { stateMachineUuid, newAction } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  stateMachine.entry_point.actions = [newAction];
};

export const deleteStateMachineEntities = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    stateNodeIdsToDel: Array<string>;
    stateLinkIdsToDel: Array<string>;
  }>,
) => {
  const {
    stateMachineUuid,
    stateLinkIdsToDel: stateLinkIds,
    stateNodeIdsToDel: stateNodeIds,
  } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  stateMachine.links = stateMachine.links.filter(
    (link) => !stateLinkIds.includes(link.uuid),
  );
  stateMachine.nodes = stateMachine.nodes.filter((node) => {
    const keep = !stateNodeIds.includes(node.uuid);

    if (keep) {
      node.exit_priority_list = node.exit_priority_list.filter(
        (prioLinkId) => !stateLinkIds.includes(prioLinkId),
      );
    }

    return keep;
  });
};

export const createNewStateMachineWithUuid = (
  state: ModelState,
  action: PayloadAction<string>,
) => {
  const newStateMachineUuid = action.payload;

  state.stateMachines = {
    ...state.stateMachines,
    [newStateMachineUuid]: {
      uuid: newStateMachineUuid,
      nodes: [],
      links: [],
      entry_point: {},
    },
  };
};

export const changeStateNodeName = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    nodeId: string;
    newName: string;
  }>,
) => {
  const { stateMachineUuid, nodeId, newName } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  const node = stateMachine.nodes.find((node) => node.uuid === nodeId);

  if (node) {
    node.name = newName;
  }
};
