import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React from 'react';
import {
  ExtraSmallCaps,
  ExtraSmallCapsHighlight,
} from 'ui/common/typography/Typography';

const TabButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[10]};
  }
  margin-top: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  margin-right: ${({ theme }) => theme.spacing.small};
  border-radius: 2px;
  cursor: pointer;
`;

const TabTextWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.small};
  padding-right: ${({ theme }) => theme.spacing.small};
`;

const UnselectedTabText = styled(ExtraSmallCaps)`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const SelectedTabText = styled(ExtraSmallCapsHighlight)`
  color: ${({ theme }) => theme.colors.brand.tertiary.darkest};
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  padding: ${({ theme }) => theme.spacing.small};
`;

interface Props {
  Icon?: React.FC<any>;
  displayName: string;
  onClick: React.MouseEventHandler<HTMLElement>;
  isTabSelected: boolean;
  testId: string;
}

const TabButton: React.FC<Props> = ({
  Icon,
  displayName,
  onClick,
  isTabSelected,
  testId,
}) => {
  const theme = useTheme();

  return (
    <TabButtonWrapper onClick={onClick} data-test-id={testId}>
      {Icon && (
        <IconWrapper>
          <Icon
            fill={
              isTabSelected
                ? theme.colors.brand.tertiary.darkest
                : theme.colors.text.secondary
            }
          />
        </IconWrapper>
      )}
      <TabTextWrapper>
        {isTabSelected ? (
          <SelectedTabText testId={`${testId}-text`}>
            {displayName}
          </SelectedTabText>
        ) : (
          <UnselectedTabText testId={`${testId}-text`}>
            {displayName}
          </UnselectedTabText>
        )}
      </TabTextWrapper>
    </TabButtonWrapper>
  );
};

export default TabButton;
