import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { PayloadAction } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import {
  CreateConditional,
  CreateGroup,
  CreateIterator,
  CreateReplicator,
} from 'ui/common/Icons/Standard';
import { NavbarSplitButtonBackground } from './NavbarButtons';

const ListItemWrapper = styled.li`
  ${({ theme: { colors } }) => `
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${colors.brand.primary.darkest};
  }
  `}
  padding: 0 ${(props) => props.theme.spacing.normal};
`;

const ListItemContent = styled.div`
  flex: 1;
  overflow-wrap: break-word;
  word-break: break-all;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.grey[2]};
  padding-top: ${(props) => props.theme.spacing.normal};
  padding-bottom: ${(props) => props.theme.spacing.normal};
`;

const SplitButtonItem: React.FC<{
  children: React.ReactNode;
  Icon: React.FC<any>;
  onClick: () => void;
}> = ({ children, Icon, onClick }) => {
  const {
    spacing: { xlarge, small },
    colors,
  } = useTheme();

  return (
    <ListItemWrapper onClick={onClick}>
      <Icon
        width={xlarge}
        height={xlarge}
        fill={colors.grey[10]}
        style={{ marginRight: small }}
      />
      <ListItemContent>{children}</ListItemContent>
    </ListItemWrapper>
  );
};

interface ContainerItem {
  icon: React.FC;
  label: string;
  action: PayloadAction<unknown>;
  devOnly?: boolean;
}

const CONTAINERS: ContainerItem[] = [
  {
    icon: CreateGroup,
    label: 'Create Group',
    action: modelActions.createSubdiagramFromSelection({
      subdiagramType: 'core.Group',
    }),
  },

  {
    devOnly: true,
    icon: CreateGroup,
    label: 'Create Linearized System',
    action: modelActions.createSubdiagramFromSelection({
      subdiagramType: 'core.LinearizedSystem',
    }),
  },

  {
    icon: CreateIterator,
    label: 'Create Iterator',
    action: modelActions.createSubdiagramFromSelection({
      subdiagramType: 'core.Iterator',
    }),
  },

  {
    icon: CreateReplicator,
    label: 'Create Replicator',
    action: modelActions.createSubdiagramFromSelection({
      subdiagramType: 'core.Replicator',
    }),
  },

  {
    icon: CreateConditional,
    label: 'Create Conditional',
    action: modelActions.createSubdiagramFromSelection({
      subdiagramType: 'core.Conditional',
    }),
  },
];

interface Props {
  triggerClose: () => void;
}

const ContainersMenu: React.FC<Props> = ({ triggerClose }) => {
  const dispatch = useAppDispatch();
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  return (
    <NavbarSplitButtonBackground>
      {CONTAINERS.map(({ icon, label, action, devOnly }) =>
        devOnly && !developerModeEnabled ? null : (
          <SplitButtonItem
            key={label}
            Icon={icon}
            onClick={() => {
              dispatch(action);
              triggerClose();
            }}>
            {label}
          </SplitButtonItem>
        ),
      )}
    </NavbarSplitButtonBackground>
  );
};

export default ContainersMenu;
