import styled from '@emotion/styled';
import React from 'react';
import { useAppParams } from 'util/useAppParams';

import { useJupyter } from 'app/api/useJupyter';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { NotebookFooterTab, uiFlagsActions } from 'app/slices/uiFlagsSlice';

const DEFAULT_JUPYTER_STATUS = 'Idle';

const KernelStatus = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.grey[30]};
    margin-left: ${theme.spacing.normal};
    padding: ${theme.spacing.small} ${theme.spacing.normal};
    border-radius: ${theme.spacing.small};
    background-color: ${theme.colors.brand.tertiary.darkest};
    text-transform: capitalize;
    font-weight: ${theme.typography.font.smallEmphasis.weight};
`}
`;

const NotebookFooterButtons: React.FC = () => {
  const dispatch = useAppDispatch();
  const { fileName, projectId } = useAppParams();
  const { sessions = [] } = useJupyter();
  const notebookFooterTab = useAppSelector(
    (state) => state.uiFlags.notebookFooterTab,
  );
  const isNotebook = fileName?.endsWith('ipynb');
  if (!isNotebook) return null;

  const status =
    sessions.find((session) => session.path === `${projectId}/${fileName}`)
      ?.kernel?.execution_state || DEFAULT_JUPYTER_STATUS;

  const toggleTab = (tabClicked: NotebookFooterTab) => {
    if (notebookFooterTab === tabClicked) {
      // The user is using the tab to close the footer.
      dispatch(uiFlagsActions.setNotebookFooterTab(NotebookFooterTab.None));
    } else {
      dispatch(uiFlagsActions.setNotebookFooterTab(tabClicked));
    }
  };

  return (
    <>
      {/* <FooterButton
        name={t({
          id: 'footer.notebook.variablesTabName',
          message: 'Variables',
        })}
        Icon={VariableInspector}
        isSelected={notebookFooterTab === NotebookFooterTab.Variables}
        onClickHandler={() => toggleTab(NotebookFooterTab.Variables)}
      />
      <FooterButton
        name={t({
          id: 'footer.notebook.consoleTabName',
          message: 'Console',
        })}
        Icon={Console}
        isSelected={notebookFooterTab === NotebookFooterTab.Console}
        onClickHandler={() => toggleTab(NotebookFooterTab.Console)}
      /> */}
      <KernelStatus>{status}</KernelStatus>
    </>
  );
};

export default NotebookFooterButtons;
