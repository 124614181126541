import { Trans } from '@lingui/macro';
import { useJupyter } from 'app/api/useJupyter';
import { useJupyterProject } from 'app/api/useJupyterProject';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Notebook } from 'ui/common/Icons/Standard';

import ListItem from './ListItem';
import ListTitle from './ListTitle';

interface Props {
  projectId: string;
  triggerClose: () => void;
}

const Notebooks: React.FC<Props> = ({ projectId, triggerClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { files } = useJupyterProject(projectId);
  const { saveFile } = useJupyter();
  const notebooks =
    files?.data?.jupyter_files.filter((f) => f.type === 'notebook') || [];

  return (
    <>
      <ListTitle>
        <Trans id="browser.notebooksTitle">Notebooks</Trans>
      </ListTitle>
      {notebooks.map((n) => (
        <ListItem
          key={n.name}
          Icon={Notebook}
          isActive={
            location.pathname ===
            `/projects/${projectId}/notebook/${encodeURIComponent(n.name)}`
          }
          onClick={() => {
            saveFile();
            navigate(`/projects/${projectId}/notebook/${n.name}`);
            triggerClose();
          }}>
          {n.name}
        </ListItem>
      ))}
    </>
  );
};

export default Notebooks;
