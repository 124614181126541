import { useModels } from 'app/api/useModels';
import { useUpdateSubmodel } from 'app/api/useUpdateSubmodel';
import { TagType, enhancedApi } from 'app/enhancedApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelMetadataActions } from 'app/slices/modelMetadataSlice';
import { projectActions } from 'app/slices/projectSlice';
import { RightSidebarSection, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { versionHistoryActions } from 'app/slices/versionHistorySlice';
import React from 'react';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';

const UPDATE_DELAY_IN_MS = 200;

export const UpdateAPIDispatcher = () => {
  const dispatch = useAppDispatch();

  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const { editId, loadedModelId, loadedVersionId } = useAppSelector(
    (state) => state.modelMetadata,
  );

  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  const { forceUpdateModel, modelUpdatingId } = useAppSelector(
    (state) => state.project,
  );

  const sidebarSection: RightSidebarSection = useAppSelector(
    (state) => state.uiFlags.currentRightSidebarSection,
  );

  const canUpdateModel =
    modelId &&
    loadedModelId &&
    modelId === loadedModelId &&
    canEditCurrentModelVersion &&
    editId !== null &&
    !versionId &&
    !loadedVersionId;

  const {
    diagram,
    submodels,
    stateMachines,
    modelName,
    configuration,
    parameterDefinitions,
    submodelConfiguration,
  } = useAppSelector((state) => ({
    diagram: state.model.present.rootModel,
    submodels: state.model.present.submodels,
    stateMachines: state.model.present.stateMachines,
    modelName: state.model.present.name,
    configuration: state.model.present.configuration,
    parameterDefinitions: state.model.present.parameterDefinitions,
    submodelConfiguration: state.model.present.submodelConfiguration,
  }));

  const { updateModelConfiguration, updateModelContent } = useModels();
  const { updateSubmodelContent } = useUpdateSubmodel();

  const timerIdRef = React.useRef<NodeJS.Timeout | null>(null);
  const isInitialModelLoadRef = React.useRef<boolean>(true);
  const isInitialConfigurationLoadRef = React.useRef<boolean>(true);
  const lastModelNameRef = React.useRef<string | null>(null);
  const reQueryProjectsAfterNextUpdate = React.useRef<boolean>(false);

  /**
   * When user is editing the model, set the model editor overlays accordingly.
   */
  const enterEditMode = React.useCallback(() => {
    // TODO: make refs for the relevant UI flags so that gating doesn't trigger the larger useEffect
    dispatch(uiFlagsActions.setUIFlag({ showSignalNamesInModel: false }));
  }, [dispatch]);

  React.useEffect(() => {
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }

    // If we are currently loading a model, reset the load flag.
    if (!canUpdateModel) {
      isInitialModelLoadRef.current = true;
      lastModelNameRef.current = modelName;
      return;
    }

    // For any model load, don't submit an update immediately after the initial load.
    if (isInitialModelLoadRef.current) {
      isInitialModelLoadRef.current = false;
      lastModelNameRef.current = modelName;
      return;
    }

    timerIdRef.current = setTimeout(() => {
      dispatch(projectActions.requestUpdateModel());
    }, UPDATE_DELAY_IN_MS);

    // Prevent model version number mismatch errors when
    // running/checking a model right after the user makes a model change.
    dispatch(projectActions.startWaitingForModelUpdate());
  }, [
    dispatch,
    diagram,
    submodels,
    stateMachines,
    modelName,
    canUpdateModel,
    parameterDefinitions,
    submodelConfiguration,
  ]);

  // Watch for requests to call the actual API (after the appropriate delay has elapsed).
  React.useEffect(() => {
    if (!projectId) return;

    if (forceUpdateModel && !modelUpdatingId) {
      if (canUpdateModel) {
        dispatch(
          modelMetadataActions.logVersionEvent(
            `before send updateModelContent editId=${editId}`,
          ),
        );
        enterEditMode();
        if (
          topLevelModelType === 'Model' ||
          topLevelModelType === 'Experiment'
        ) {
          updateModelContent(
            loadedModelId,
            {
              version: Number(editId),
              name: modelName,
              project_uuid: projectId,
              diagram: diagram as any,
              submodels: submodels as any,
              state_machines: stateMachines,
            },
            reQueryProjectsAfterNextUpdate.current,
          );
        } else {
          updateSubmodelContent({
            projectId,
            submodelId: loadedModelId,
            editId: String(editId),
            name: modelName,
            diagram,
            submodels,
            parameterDefinitions,
            submodelConfiguration,
            stateMachines,
          });
        }

        // Invalidate model contents cache
        dispatch(
          enhancedApi.util.invalidateTags([
            { type: TagType.ModelContents, id: loadedModelId },
          ]),
        );

        reQueryProjectsAfterNextUpdate.current = false;

        // Close version history and invalidate the cache if edits are made to the model.
        if (sidebarSection === RightSidebarSection.VersionHistory) {
          dispatch(
            uiFlagsActions.setRightSidebarTab(RightSidebarSection.Properties),
          );
          dispatch(versionHistoryActions.requestDiagramVersions());
        }
      }
      dispatch(projectActions.clearRequestToUpdateModel());
    }
  }, [
    dispatch,
    forceUpdateModel,
    loadedModelId,
    updateModelContent,
    editId,
    projectId,
    modelName,
    diagram,
    submodels,
    stateMachines,
    parameterDefinitions,
    submodelConfiguration,
    canUpdateModel,
    modelUpdatingId,
    sidebarSection,
    topLevelModelType,
    updateSubmodelContent,
    enterEditMode,
  ]);

  // Make sure we refetch projects if a model name changes.
  React.useEffect(() => {
    if (lastModelNameRef.current && lastModelNameRef.current !== modelName) {
      reQueryProjectsAfterNextUpdate.current = true;
      lastModelNameRef.current = modelName;
    }
  }, [dispatch, modelName]);

  React.useEffect(() => {
    // Submodels currently don't support configuration
    if (topLevelModelType === 'Submodel') {
      return;
    }

    // If we are currently loading a model, reset the load flag.
    if (!canUpdateModel) {
      isInitialConfigurationLoadRef.current = true;
      return;
    }

    // For any model load, don't submit an update immediately after the initial load.
    if (isInitialConfigurationLoadRef.current) {
      isInitialConfigurationLoadRef.current = false;
      return;
    }

    updateModelConfiguration(loadedModelId, configuration);
  }, [
    updateModelConfiguration,
    configuration,
    canUpdateModel,
    loadedModelId,
    topLevelModelType,
  ]);

  // This effect's lifetime matches the lifetime of the component
  // to ensure the timer is cleaned up properly.
  React.useEffect(
    () => () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    },
    [],
  );

  return null;
};
