import {
  Action,
  PreloadedState,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { rtkQueryErrorLogger } from 'app/rtkQueryErrorLogger';
import cameraSlice from 'app/slices/cameraSlice';
import modelSlice, { modelActions } from 'app/slices/modelSlice';
import notificationsSlice from 'app/slices/notificationsSlice';
import projectMetadataSlice from 'app/slices/projectMetadataSlice';
import simResultsSlice from 'app/slices/simResultsSlice';
import userOptionsSlice from 'app/slices/userOptionsSlice';
import undoable, { excludeAction, groupByActionTypes } from 'redux-undo';
import { jupyterApi } from './api/useJupyter';
import { generatedApi } from './apiGenerated/generatedApi';
import authSlice from './slices/authSlice';
import codeGenSlice from './slices/codeGenSlice';
import compilationDataSlice from './slices/compilationAnalysisDataSlice';
import dataExplorerPlotDataSlice from './slices/dataExplorerPlotDataSlice';
import dataExplorerSlice from './slices/dataExplorerSlice';
import ensembleSimsSlice from './slices/ensembleSimsSlice';
import entityPreferencesSlice from './slices/entityPreferencesSlice';
import errorsSlice from './slices/errorsSlice';
import jupyterSlice from './slices/jupyterSlice';
import modelMetadataSlice from './slices/modelMetadataSlice';
import modelVersionsSlice from './slices/modelVersionsSlice';
import navigationSlice from './slices/navigationSlice';
import projectSlice from './slices/projectSlice';
import rcMenuSlice from './slices/rcMenuSlice';
import simulationSignalsSlice from './slices/simulationSignalsSlice';
import submodelsSlice from './slices/submodelsSlice';
import tourSlice from './slices/tourSlice';
import traceDragSlice from './slices/traceDragSlice';
import uiFlagsSlice from './slices/uiFlagsSlice';
import userPreferencesSlice from './slices/userPreferencesSlice';
import userSlice from './slices/userSlice';
import versionHistorySlice from './slices/versionHistorySlice';
import visualizerSlice from './slices/visualizerSlice';

const actionFilter = excludeAction([modelActions.snapEntitiesToGrid.type]);

const configureModelUndo = (reducer: typeof modelSlice.reducer) =>
  undoable(reducer, {
    filter: (...args) => actionFilter(...args),
    groupBy: (action, currentState, previousHistory) => {
      if (action.type === modelActions.resizeNode.type) {
        const payloadNodeUuid = (action.payload || {}).nodeUuid || '';
        return `${action.type}_${payloadNodeUuid}`;
      }
      if (action.type === modelActions.resizeAnnotation.type) {
        const payloadAnnoUuid = (action.payload || {}).uuid || '';
        return `${action.type}_${payloadAnnoUuid}`;
      }
      if (
        action.type ===
          modelActions.confirmReferenceSubmodelCreatedFromSelection.type ||
        action.type === modelActions.createSubdiagramFromSelection.type
      ) {
        const submodelInstanceId =
          (action.payload || {}).submodelInstanceId || '';
        return `${modelActions.createSubdiagramFromSelection.type}_${submodelInstanceId}`;
      }

      return groupByActionTypes([
        modelActions.moveStateNodesByDelta.type,
        modelActions.moveStateLinkCurveDeviationByDelta.type,
        modelActions.snapStateNodesToGrid.type,
        modelActions.moveEntitiesByDelta.type,
        modelActions.moveBlocksTo.type,
        modelActions.changeSegmentCoordinate.type,
        modelActions.setSelections.type,
      ])(action, currentState, previousHistory);
    },
  });

export const reducers = {
  [jupyterApi.reducerPath]: jupyterApi.reducer,
  [generatedApi.reducerPath]: generatedApi.reducer,
  auth: authSlice.reducer,
  camera: cameraSlice.reducer,
  dataExplorer: dataExplorerSlice.reducer,
  dataExplorerPlotData: dataExplorerPlotDataSlice.reducer,
  ensembleSims: ensembleSimsSlice.reducer,
  entityPreferences: entityPreferencesSlice.reducer,
  model: configureModelUndo(modelSlice.reducer),
  modelMetadata: modelMetadataSlice.reducer,
  modelVersions: modelVersionsSlice.reducer,
  notifications: notificationsSlice.reducer,
  navigation: navigationSlice.reducer,
  project: projectSlice.reducer,
  projectMetadata: projectMetadataSlice.reducer,
  rcMenu: rcMenuSlice.reducer,
  simResults: simResultsSlice.reducer,
  submodels: submodelsSlice.reducer,
  simulationSignals: simulationSignalsSlice.reducer,
  uiFlags: uiFlagsSlice.reducer,
  user: userSlice.reducer,
  userOptions: userOptionsSlice.reducer,
  versionHistory: versionHistorySlice.reducer,
  visualizer: visualizerSlice.reducer,
  jupyter: jupyterSlice.reducer,
  compilationData: compilationDataSlice.reducer,
  userPreferences: userPreferencesSlice.reducer,
  codeGen: codeGenSlice.reducer,
  tour: tourSlice.reducer,
  traceDrag: traceDragSlice.reducer,
  errorsSlice: errorsSlice.reducer,
};

const rootReducer = combineReducers(reducers);
export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        generatedApi.middleware,
        jupyterApi.middleware,
        rtkQueryErrorLogger,
      ),
  });

export const store = setupStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
