/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const StarFilled: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.546 4.73058C11.7247 4.34314 12.2754 4.34314 12.4541 4.73058L14.422 8.99715C14.4949 9.15505 14.6445 9.26377 14.8172 9.28425L19.4831 9.83746C19.9068 9.8877 20.077 10.4114 19.7637 10.7011L16.3141 13.8912C16.1864 14.0092 16.1293 14.1852 16.1632 14.3557L17.0789 18.9642C17.162 19.3827 16.7165 19.7063 16.3442 19.4979L12.2443 17.203C12.0925 17.118 11.9076 17.118 11.7558 17.203L7.65587 19.4979C7.28356 19.7063 6.83808 19.3827 6.92124 18.9642L7.83693 14.3557C7.87082 14.1852 7.81367 14.0092 7.686 13.8912L4.23639 10.7011C3.92314 10.4114 4.0933 9.8877 4.51699 9.83746L9.18288 9.28425C9.35556 9.26377 9.5052 9.15505 9.57804 8.99715L11.546 4.73058Z"
      fill={props.fill}
    />
  </svg>
);

StarFilled.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default StarFilled;
