import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TraceDragSource = {
  traceId: string;
  displayName: string;
  color: string;
};

type TraceDragState = {
  // Because of how drag and drop is implemented, the presence of an source candidate != isDragging
  // Must check for `isDragging` of trace externally, which is set `onMove`.
  traceDragSourceCandidate?: TraceDragSource;
  isDraggingTrace: boolean;
};

const initialState: TraceDragState = { isDraggingTrace: false };

const traceDragSlice = createSlice({
  name: 'traceDragSlice',
  initialState,
  reducers: {
    setSourceCandidate(state, action: PayloadAction<TraceDragSource>) {
      state.traceDragSourceCandidate = action.payload;
    },
    startDrag(state) {
      if (state.traceDragSourceCandidate) {
        state.isDraggingTrace = true;
      }
    },
    endDrag: () => initialState,
  },
});

export const traceDragActions = traceDragSlice.actions;

export default traceDragSlice;
