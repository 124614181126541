// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const Replicator: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Replicator",
      "namespace": "core",
      "description": "replcaite a submodel N times.",
      "extra_parameters": true,
      "hidden": true,
      "feature_level": "dev"
    },
    "modes": {
      "feedthrough": true,
      "time": "any"
    },
    "ports": {
      "has_automatic_ports": true
    },
    "parameter_definitions": [
      {
        "name": "instances",
        "data_type": "int",
        "default_value": "2",
        "description": "the number of instances/replicas"
      }
    ]
  }
);
