import { v4 as uuid } from 'uuid';
import { getTracePathsForPort } from 'util/portTypeUtils';
import { DiagramVersionFull } from 'app/apiTransformers/convertGetSnapshotReadByUuid';
import {
  SignalPathInfo,
  buildSignalInfoForCurrentModel,
} from 'util/modelVersionSignal';
import { ModelVersionRequestData } from 'app/slices/modelVersionsSlice';
import { getDefaultChartType } from 'util/timeMode';
import {
  PlotCellMetadata,
  TraceMetadata,
} from '../ui/dataExplorer/dataExplorerTypes';
import { TimeModeType } from '../app/slices/compilationAnalysisDataSlice';

export interface TracesWithVectorSupportResult {
  hasChanges?: boolean;
  idToTrace?: Record<string, TraceMetadata>;
  idToPlotCell?: Record<string, PlotCellMetadata>;

  // If there is a model (or submodel) version that we need to request,
  // it will be set here, otherwise this will be undefined.
  modelVersionToRequest?: ModelVersionRequestData;
}

export function generateTracesThatSupportVectorPorts(
  idToTrace: Record<string, TraceMetadata>,
  idToPlotCell: Record<string, PlotCellMetadata>,
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >,
): TracesWithVectorSupportResult {
  let hasChanges = false;

  const updatedIdToTrace: Record<string, TraceMetadata> = {};
  const signalPathToDisplayName: Record<string, string> = {};
  const signalPathToTimeMode: Record<string, TimeModeType> = {};

  const oldTraceIdToNewTraceIds: Record<string, string[]> = {};
  const traces = Object.values(idToTrace);
  for (let i = 0; i < traces.length; i++) {
    const trace = traces[i];
    if (!signalPathToDisplayName[trace.signalPath]) {
      const {
        signalDisplayName,
        modelVersionToRequest,
        timeMode,
      }: SignalPathInfo = buildSignalInfoForCurrentModel(
        trace.signalPath,
        modelIdToVersionIdToModelData,
      );
      if (modelVersionToRequest) {
        return {
          modelVersionToRequest,
        };
      }
      if (signalDisplayName) {
        signalPathToDisplayName[trace.signalPath] = signalDisplayName;
      }
      if (timeMode) {
        signalPathToTimeMode[trace.signalPath] = timeMode;
      }
    }

    let signalDisplayName = signalPathToDisplayName[trace.signalPath];
    let timeMode = signalPathToTimeMode[trace.signalPath];

    // If the signal path is the same as the trace path,
    // check to see if this is a vector, and if so, add traces
    // for all items in the vector
    if (trace.signalPath === trace.tracePath) {
      const tracePathsResult = getTracePathsForPort(
        trace.signalPath,
        modelIdToVersionIdToModelData,
      );
      if (tracePathsResult.modelVersionToRequest) {
        return {
          modelVersionToRequest: tracePathsResult.modelVersionToRequest,
        };
      }
      const tracePaths = tracePathsResult.tracePaths;
      if (tracePaths) {
        const isVectorResult = tracePathsResult.isVector;
        const traceIds: string[] = [];
        for (
          let vectorIndex = 0;
          vectorIndex < tracePaths.length;
          vectorIndex++
        ) {
          const fullTracePath = tracePaths[vectorIndex];
          const newTraceId = uuid();
          const newDisplayName = isVectorResult
            ? `${signalDisplayName}[${vectorIndex}]`
            : signalDisplayName;
          if (newDisplayName !== trace.displayName) {
            hasChanges = true;
          }
          if (fullTracePath !== trace.tracePath) {
            hasChanges = true;
          }
          traceIds.push(newTraceId);
          updatedIdToTrace[newTraceId] = {
            id: newTraceId,
            signalPath: trace.signalPath,
            tracePath: fullTracePath,
            vectorIndex: isVectorResult ? vectorIndex : undefined,
            displayName: newDisplayName,
            plotType: getDefaultChartType(timeMode),
            color: trace.color,
            modelId: trace.modelId,
            simulationId: trace.simulationId,
          };
        }

        oldTraceIdToNewTraceIds[trace.id] = traceIds;
      }
    } else {
      const newDisplayName =
        trace.vectorIndex === undefined
          ? signalDisplayName
          : `${signalDisplayName}[${trace.vectorIndex}]`;
      if (signalDisplayName && newDisplayName !== trace.displayName) {
        hasChanges = true;
        updatedIdToTrace[trace.id] = {
          ...trace,
          displayName: newDisplayName,
          plotType: getDefaultChartType(timeMode),
        };
      } else {
        updatedIdToTrace[trace.id] = trace;
      }
    }
  }

  let updatedIdToPlotCell: Record<string, PlotCellMetadata> = {};
  if (hasChanges) {
    // If we updated the trace ids to account for vector ports,
    // make sure we fix up the trace id lists in the corresponding plot cell.
    Object.values(idToPlotCell).forEach((plotCell) => {
      const hasTraceIdsToSwapOut = plotCell.traceIds.some(
        (traceId) => oldTraceIdToNewTraceIds[traceId],
      );
      if (!hasTraceIdsToSwapOut) {
        updatedIdToPlotCell[plotCell.id] = plotCell;
      }

      let updatedTraceIds: string[] = [];
      plotCell.traceIds.forEach((traceId) => {
        const newTraceIds = oldTraceIdToNewTraceIds[traceId];
        if (newTraceIds) {
          updatedTraceIds = [...updatedTraceIds, ...newTraceIds];
        } else {
          updatedTraceIds.push(traceId);
        }
      });

      updatedIdToPlotCell[plotCell.id] = {
        id: plotCell.id,
        traceIds: updatedTraceIds,
        initialBounds: plotCell.initialBounds,
        zoomBounds: plotCell.zoomBounds,
      };
    });
  } else {
    updatedIdToPlotCell = idToPlotCell;
  }

  return {
    hasChanges,
    idToTrace: updatedIdToTrace,
    idToPlotCell: updatedIdToPlotCell,
  };
}
