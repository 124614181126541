import type { Coordinate } from 'app/common_types/Coordinate';
import { PortSide } from 'app/common_types/PortTypes';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { renderConstants } from 'app/utils/renderConstants';
import { getVisualNodeHeight } from 'ui/modelRendererInternals/getVisualNodeHeight';
import { getVisualNodeWidth } from 'ui/modelRendererInternals/getVisualNodeWidth';

const getCenteredPortYPos = (
  totalPortSlots: number,
  totalPorts: number,
  portId: number,
) => {
  const centerSlot = Math.floor(totalPortSlots / 2);
  const startSlot = centerSlot - (totalPorts - 1);
  const portSlot = startSlot + 2 * portId + 1;
  const portY = portSlot * renderConstants.GRID_SIZE;

  return portY;
};

const getTopAlignedPortYPos = (portId: number) => {
  const portSlot = 2 * portId + 2;
  const portY = portSlot * renderConstants.GRID_SIZE;

  return portY;
};

const getBottomAlignedPortYPos = (
  totalPortSlots: number,
  totalPorts: number,
  portId: number,
) => {
  const startSlot = Math.max(0, totalPortSlots - totalPorts * 2);
  const portSlot = startSlot + 2 * portId + 1;
  const portY = portSlot * renderConstants.GRID_SIZE;

  return portY;
};

const getSpacedPortYPos = (
  totalPortSlots: number,
  totalPorts: number,
  portId: number,
) => {
  if (totalPorts === 1) {
    return getCenteredPortYPos(totalPortSlots, totalPorts, portId);
  }

  const interval = Math.floor(totalPortSlots / totalPorts);

  let portY = 0;

  if (interval >= 1) {
    const remainingVisualSlots =
      totalPortSlots - 1 - interval * (totalPorts - 1);
    const portSlot =
      interval * portId + 1 + Math.floor(remainingVisualSlots / 2);
    portY = portSlot * renderConstants.GRID_SIZE;
  } else {
    const centerSlot = Math.floor(totalPortSlots / 2);
    const startSlot = centerSlot - (totalPorts - 1);
    const portSlot = startSlot + 2 * portId;
    portY = portSlot * renderConstants.GRID_SIZE;
  }

  return portY;
};

export function getPortNodeLocalCoordinate(
  nodeData: NodeInstance,
  portSide: PortSide,
  portData: { node: string; port: number },
): Coordinate {
  const { port: portId } = portData;
  let portX;
  let portY;

  const nodeIsIOPort =
    nodeData.type === 'core.Inport' || nodeData.type === 'core.Outport';

  const currentNodeHeight = nodeIsIOPort
    ? renderConstants.BLOCK_MIN_HEIGHT
    : getVisualNodeHeight(nodeData);
  const currentNodeWidth = getVisualNodeWidth(nodeData);

  const leftX = 0;
  const rightX = currentNodeWidth;

  const portSlotsCount =
    Math.floor(currentNodeHeight / renderConstants.GRID_SIZE) - 1;

  if (portSide === PortSide.Input) {
    portX = nodeData.uiprops.directionality === 'left' ? rightX : leftX;
  } else {
    portX = nodeData.uiprops.directionality === 'left' ? leftX : rightX;
  }

  const portsCount =
    portSide === PortSide.Input
      ? nodeData.inputs.length
      : nodeData.outputs.length;

  const portAlignMode = nodeData.uiprops.port_alignment || 'center';

  switch (portAlignMode) {
    case 'top':
      portY = getTopAlignedPortYPos(portId);
      break;
    case 'bottom':
      portY = getBottomAlignedPortYPos(portSlotsCount, portsCount, portId);
      break;
    case 'center':
      portY = getCenteredPortYPos(portSlotsCount, portsCount, portId);
      break;
    case 'spaced':
      portY = getSpacedPortYPos(portSlotsCount, portsCount, portId);
      break;
  }

  return {
    x: portX,
    y: portY,
  };
}

export function getPortWorldCoordinateWithMaybeNode(
  nodeData: NodeInstance | undefined,
  portSide: PortSide,
  portData?: { node: string; port: number },
): Coordinate | undefined {
  if (!portData) return undefined;
  if (!nodeData) return undefined;

  const { x: portX, y: portY } = getPortNodeLocalCoordinate(
    nodeData,
    portSide,
    portData,
  );

  return {
    x: portX + nodeData.uiprops.x,
    y: portY + nodeData.uiprops.y,
  };
}

export function getPortWorldCoordinate(
  nodeData: NodeInstance,
  portSide: PortSide,
  portData?: { node: string; port: number },
): Coordinate | undefined {
  if (!portData) return undefined;

  if (portSide === PortSide.Input) {
    if (portData.port >= nodeData.inputs.length) {
      return undefined;
    }
  }

  if (portSide === PortSide.Output) {
    if (portData.port >= nodeData.outputs.length) {
      return undefined;
    }
  }

  return getPortWorldCoordinateWithMaybeNode(nodeData, portSide, portData);
}
