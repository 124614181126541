/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Information: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.036 9.63599C11.764 9.63599 11.536 9.54799 11.352 9.37199C11.176 9.18799 11.088 8.95999 11.088 8.68799C11.088 8.40799 11.176 8.17999 11.352 8.00399C11.528 7.82799 11.756 7.73999 12.036 7.73999C12.316 7.73999 12.544 7.82799 12.72 8.00399C12.896 8.17999 12.984 8.40799 12.984 8.68799C12.984 8.95999 12.892 9.18799 12.708 9.37199C12.532 9.54799 12.308 9.63599 12.036 9.63599Z"
      fill={props.fill}
    />
    <path
      d="M11.316 16C11.276 16 11.24 15.988 11.208 15.964C11.184 15.932 11.172 15.896 11.172 15.856V10.964C11.172 10.924 11.184 10.892 11.208 10.868C11.24 10.836 11.276 10.82 11.316 10.82H12.72C12.76 10.82 12.792 10.836 12.816 10.868C12.848 10.892 12.864 10.924 12.864 10.964V15.856C12.864 15.896 12.848 15.932 12.816 15.964C12.792 15.988 12.76 16 12.72 16H11.316Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM18.5 12C18.5 15.5899 15.5899 18.5 12 18.5C8.41015 18.5 5.5 15.5899 5.5 12C5.5 8.41015 8.41015 5.5 12 5.5C15.5899 5.5 18.5 8.41015 18.5 12Z"
      fill={props.fill}
    />
  </svg>
);

Information.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Information;
