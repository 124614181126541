import { useSubmodelData } from 'app/api/useSubmodelData';
import { useAppDispatch } from 'app/hooks';
import { submodelsActions } from 'app/slices/submodelsSlice';
import React from 'react';

interface Props {
  projectId: string;
  submodelId: string;
}

const CurrentSubmodelLoader: React.FC<Props> = ({ projectId, submodelId }) => {
  const dispatch = useAppDispatch();

  useSubmodelData(projectId, submodelId);

  React.useEffect(
    () =>
      // Clear this model out of the cache when we leave this submodel edit session
      // because the submodel was likely edited and will have likely changed.
      () => {
        dispatch(submodelsActions.clearCacheForSubmodel(submodelId));
      },
    [dispatch, submodelId],
  );

  return null;
};

export default CurrentSubmodelLoader;
