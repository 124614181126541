import {
  ParameterDefinition,
  SubmodelConfiguration,
  SubmodelPortDefinition,
} from 'app/apiGenerated/generatedApiTypes';
import {
  ModelDiagram,
  ModelConfiguration,
  SubmodelsSection,
  SubmodelInstance,
  StateMachineDiagram,
} from 'app/generated_types/SimulationModel';
import {
  ModelParameters,
  getCurrentParentModelDiagram,
  ModelParameter,
} from 'app/utils/modelDataUtils';
import {
  getLocalSubmodelDiagram,
  getNestedNode,
} from 'app/utils/modelDiagramUtils';

export interface ModelState {
  name: string;
  rootModel: ModelDiagram;
  submodels: SubmodelsSection;
  stateMachines?: {
    [k: string]: StateMachineDiagram | undefined;
  };
  selectionParentPath: string[];
  selectedBlockIds: string[];
  selectedLinkIds: string[];
  selectedAnnotationIds: string[];
  currentSubmodelPath: string[];
  configuration: ModelConfiguration;
  parameters: ModelParameters;
  parameterDefinitions: ParameterDefinition[];
  portDefinitionsInputs: SubmodelPortDefinition[];
  portDefinitionsOutputs: SubmodelPortDefinition[];
  submodelConfiguration: SubmodelConfiguration;
}

export const getCurrentlyEditingModelFromState = (
  state: ModelState,
  overridingParentPath?: string[],
): ModelDiagram | null => {
  const parentPath = overridingParentPath || state.currentSubmodelPath;

  if (parentPath.length === 0) {
    return state.rootModel;
  }

  const submodelId = parentPath[parentPath.length - 1];
  const diagram = getLocalSubmodelDiagram(state.submodels, submodelId);
  return diagram;
};

export const getCurrentParentModelDiagramFromState = (
  state: ModelState,
): ModelDiagram | null =>
  getCurrentParentModelDiagram(
    state.rootModel,
    state.submodels,
    state.currentSubmodelPath,
  );

export const getCurrentParentSubmodelNodeFromState = (
  state: ModelState,
): SubmodelInstance | null => {
  if (state.currentSubmodelPath.length === 0) {
    return null;
  }

  const parentDiagram = getCurrentParentModelDiagramFromState(state);
  if (!parentDiagram) {
    return null;
  }
  const parentNode = parentDiagram.nodes.find(
    (node) =>
      node.uuid ===
      state.currentSubmodelPath[state.currentSubmodelPath.length - 1],
  );
  return parentNode as SubmodelInstance;
};

export const getInheritedParametersFromState = (
  state: ModelState,
  path: string[],
): ModelParameters | null => {
  const {
    rootModel: { nodes: topLevelNodes },
    submodels: topLevelSubmodels,
  } = state;

  // wrangle array as a map
  const inheritedParams: { [key: string]: ModelParameter } =
    state.parameters.reduce((acc: { [key: string]: ModelParameter }, param) => {
      acc[param.name] = param;
      return acc;
    }, {});

  let currentNodeParentPath: string[] = [];
  for (let i = 0; i < path.length; i++) {
    const submodelUuid = path[i];
    const submodelNode = getNestedNode(
      topLevelNodes,
      topLevelSubmodels,
      currentNodeParentPath,
      submodelUuid,
    );
    if (!submodelNode) {
      console.error(`Could not find node for submodel ${submodelUuid}`);
      return null;
    }
    Object.keys(submodelNode.parameters || {}).forEach((paramName) => {
      inheritedParams[paramName] = {
        name: paramName,
        value: submodelNode.parameters[paramName]?.value || '',
      };
    });

    currentNodeParentPath.push(submodelUuid);
  }

  // rewrangle it as a list
  return Object.keys(inheritedParams).map(
    (paramName) => inheritedParams[paramName],
  );
};

export const getParameterLinkAndBlockNamesInScope = (
  state: ModelState,
  model: ModelDiagram,
): string[] => {
  const path = state.currentSubmodelPath || [];
  const inheritedParams = getInheritedParametersFromState(state, path) || [];
  const inheritedNames = inheritedParams.map((p) => p.name);
  const nodeNames = model.nodes.map((n) => n.name).filter((n) => n) as string[];
  const linkNames = model.links.map((l) => l.name).filter((n) => n) as string[];
  const existingNames = [...nodeNames, ...linkNames, ...inheritedNames];

  return existingNames;
};
