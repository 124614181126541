import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Uuid } from 'app/apiGenerated/generatedApiTypes';
import { ModelDiagram } from 'app/generated_types/SimulationModel';

export enum ModelRestoreState {
  None,
  Restoring,
  TriggeringOpenRestoredModel,
  OpeningRestoredModel,
}

interface ModelVersionUpdate {
  modelId: Uuid;
  versionId?: string;
  editId: number | string;
  updatedAt: string;
}

interface ModelMetadataState {
  loadedModelId: Uuid;
  loadedVersionId: string;
  lastSimulationEditId: number | null;
  editId: number | string | null;
  loadedVersionUpdatedAt: string;
  modelRestoreState: ModelRestoreState;
  modelVersionLogs: string[];
  currentDiagram: ModelDiagram | null;
  currentDiagramSubmodelInstanceId?: string;
  currentDiagramSubmodelReferenceProjectId?: string;
  currentDiagramSubmodelReferenceId?: string;
}

const initialState: ModelMetadataState = {
  loadedModelId: '',
  loadedVersionId: '',
  lastSimulationEditId: null,
  editId: null,
  loadedVersionUpdatedAt: '',
  modelRestoreState: ModelRestoreState.None,
  modelVersionLogs: [],
  currentDiagram: null,
};

const modelVersionLogLimit = 60;
const modelVersionLogBatchSize = 10;

// TODO remove when we figure out what is causing the version mismatch bugs.
function getNextVersionLogs(
  state: ModelMetadataState,
  {
    modelId,
    versionId,
    editId,
    details,
  }: {
    modelId?: string;
    versionId?: string;
    editId?: number | string;
    details: string;
  },
): string[] {
  // We don't need to log for read-only versions because they can't have the version
  // mismatch issue (they can't be edited)
  if (versionId) return state.modelVersionLogs;
  if (state.loadedVersionId) return state.modelVersionLogs;

  const nextLogLine = `modelId=${modelId || state.loadedModelId} editId=${
    editId || state.editId
  } details=${details}`;

  let nextLogLines;
  if (state.modelVersionLogs.length > modelVersionLogLimit) {
    // Prevent these logs from infinitely accumulating.
    // Make sure to discard the last batch of old logs because they likely are
    // too old to be interesting/relevant for debugging a version mismatch.
    nextLogLines = [
      ...state.modelVersionLogs.slice(modelVersionLogBatchSize),
      nextLogLine,
    ];
  } else {
    nextLogLines = [...state.modelVersionLogs, nextLogLine];
  }

  return nextLogLines;
}

const modelMetadataSlice = createSlice({
  name: 'modelMetadataSlice',
  initialState,
  reducers: {
    resetModelMetadataState: () => initialState,

    updateOpenModel(state, action: PayloadAction<ModelVersionUpdate>) {
      state.loadedModelId = action.payload.modelId;
      state.loadedVersionId = action.payload.versionId || '';
      state.editId = action.payload.editId;
      state.loadedVersionUpdatedAt = action.payload.updatedAt;
      state.modelRestoreState = ModelRestoreState.None;

      // TODO remove when we figure out what is causing the version mismatch bugs.
      state.modelVersionLogs = getNextVersionLogs(state, {
        details: 'updateOpenModel',
        modelId: action.payload.modelId,
        versionId: action.payload.versionId,
        editId: action.payload.editId,
      });
    },

    updateCurrentModelVersion(
      state,
      action: PayloadAction<ModelVersionUpdate>,
    ) {
      if (
        !action.payload ||
        action.payload.modelId !== state.loadedModelId ||
        state.loadedVersionId
      )
        return;
      state.editId = action.payload.editId;
      state.loadedVersionUpdatedAt = action.payload.updatedAt;

      state.modelVersionLogs = getNextVersionLogs(state, {
        details: 'updateCurrentModelVersion',
        editId: action.payload.editId,
      });
    },

    updateCurrentDiagram(
      state,
      action: PayloadAction<{
        currentDiagram: ModelDiagram | null;
        submodelInstanceId?: string;
        submodelReferenceProjectId?: string;
        submodelReferenceId?: string;
      }>,
    ) {
      const {
        currentDiagram,
        submodelReferenceId,
        submodelReferenceProjectId,
        submodelInstanceId,
      } = action.payload;
      state.currentDiagram = currentDiagram;
      state.currentDiagramSubmodelReferenceProjectId =
        submodelReferenceProjectId;
      state.currentDiagramSubmodelReferenceId = submodelReferenceId;
      state.currentDiagramSubmodelInstanceId = submodelInstanceId;
    },

    startRestoreOperation(state) {
      state.modelRestoreState = ModelRestoreState.Restoring;

      state.modelVersionLogs = getNextVersionLogs(state, {
        details: 'startRestoreOperation',
      });
    },

    triggerOpenRestoredModel(state) {
      state.modelRestoreState = ModelRestoreState.TriggeringOpenRestoredModel;

      state.modelVersionLogs = getNextVersionLogs(state, {
        details: 'triggerOpenRestoredModel',
      });
    },

    finishTriggeringOpenRestoredModel(state) {
      state.modelRestoreState = ModelRestoreState.OpeningRestoredModel;

      state.modelVersionLogs = getNextVersionLogs(state, {
        details: 'finishTriggeringOpenRestoredModel',
      });
    },

    // TODO remove when we figure out what is causing the version mismatch bugs.
    logVersionEvent(state, action: PayloadAction<string>) {
      state.modelVersionLogs = getNextVersionLogs(state, {
        modelId: state.loadedModelId,
        versionId: state.loadedVersionId,
        editId: state.editId || 0,
        details: action.payload,
      });
    },

    // TODO remove when we figure out what is causing the version mismatch bugs.
    reportVersionError(
      state,
      action: PayloadAction<{
        message: string;
        originalError: any;
      }>,
    ) {
      const enhancedError = {
        originalError: JSON.stringify(action.payload.originalError, null, 2),
        versionLogs: state.modelVersionLogs,
      };

      // The Sentry API expects a string, not an object,
      // plus, it's an emmer object so really don't want to send it directly.
      const enhancedErrorMessage = `${action.payload.message} ${JSON.stringify(
        enhancedError,
        null,
        2,
      )}`;

      console.error(enhancedErrorMessage);
    },

    setSimulationEditId(
      state,
      action: PayloadAction<{
        editId: number;
      }>,
    ) {
      const { editId } = action.payload;

      state.lastSimulationEditId = editId;
    },
  },
});

export const modelMetadataActions = modelMetadataSlice.actions;

export default modelMetadataSlice;
