import { t } from '@lingui/macro';
import { isInvalidModelVersionError } from 'app/api/useModels';
import { usePostSimulationEventCreateMutation } from 'app/apiGenerated/generatedApi';
import {
  SimulationCreateRequest,
  SimulationSummary,
} from 'app/apiGenerated/generatedApiTypes';
import { usePostSimulationCreateMutation } from 'app/enhancedApi';
import { useAppDispatch } from 'app/hooks';
import { modelMetadataActions } from 'app/slices/modelMetadataSlice';
import { projectActions } from 'app/slices/projectSlice';
import { versionHistoryActions } from 'app/slices/versionHistorySlice';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { v4 as uuid } from 'uuid';

const CANNOT_STOP_FINISHED_SIMULATION_ERROR =
  "can't stop a simulation that's not in progress";

export function useModelSimulationRequests() {
  const dispatch = useAppDispatch();

  const [callStartSimulationApi] = usePostSimulationCreateMutation();
  const [callStopSimulationApi] = usePostSimulationEventCreateMutation();

  const { showInfo, showError } = useNotifications();

  const startSimulation = React.useCallback(
    (modelUuid: string, simulationCreateRequest: SimulationCreateRequest) => {
      const correlationId = uuid();
      if (simulationCreateRequest.version) {
        dispatch(
          modelMetadataActions.setSimulationEditId({
            editId: simulationCreateRequest.version,
          }),
        );
      }

      showInfo(
        t({
          id: 'modelsApi.simulationStarted',
          message: 'Compiling model...',
        }),
      );
      callStartSimulationApi({
        modelUuid,
        simulationCreateRequest,
        'X-Correlation-ID': correlationId,
      })
        .unwrap()
        .then((simulationSummary: SimulationSummary) => {
          dispatch(projectActions.simulationSummaryUpdated(simulationSummary));
        })
        .catch((e) => {
          dispatch(projectActions.checkOrRunCancelled(correlationId));

          if (e && isInvalidModelVersionError(e)) {
            if (simulationCreateRequest.compile_only) {
              showError(
                t({
                  id: 'modelsApi.compileFailedBecauseModelWasOutOfDateError',
                  message:
                    'Unable to compile model because the model was out of date. Reloading model to get latest changes.',
                }),
              );
              dispatch(
                modelMetadataActions.reportVersionError({
                  message:
                    'Unable to compile model because the model was out of date.',
                  originalError: e,
                }),
              );
            } else {
              showError(
                t({
                  id: 'modelsApi.simulationFailedBecauseModelWasOutOfDateError',
                  message:
                    'Unable to run simulation because the model was out of date. Reloading model to get latest changes.',
                }),
              );
              dispatch(
                modelMetadataActions.reportVersionError({
                  message:
                    'Unable to run simulation because the model was out of date.',
                  originalError: e,
                }),
              );
            }

            dispatch(projectActions.requestReloadModel());
            dispatch(modelMetadataActions.reportVersionError(e));
          } else if (simulationCreateRequest.compile_only) {
            showError(
              t({
                id: 'modelsApi.simulationCompilationFailed',
                message: 'Unable to compile model.',
              }),
              e,
            );
          } else {
            showError(
              t({
                id: 'modelsApi.simulationStartFailed',
                message: 'Unable to run simulation.',
              }),
              e,
            );
          }
        })
        .finally(() => {
          // The server will autosave a version when we trigger a check or run,
          // so fetch the latest version graph information once the call is made.
          dispatch(versionHistoryActions.requestDiagramVersions());
        });
      if (simulationCreateRequest.codegen) {
        dispatch(
          projectActions.runModelCodeGenerationSent({
            correlationId,
          }),
        );
      } else if (simulationCreateRequest.fmucosim) {
        // Note: we can exploit the codegen flow for FMU export since almost
        // only the output filename differs.
        dispatch(
          projectActions.runModelCodeGenerationSent({
            correlationId,
          }),
        );
      } else if (simulationCreateRequest.compile_only) {
        dispatch(
          projectActions.checkModelRequestSent({
            correlationId,
          }),
        );
      } else {
        dispatch(
          projectActions.runModelRequestSent({
            correlationId,
          }),
        );
      }
    },
    [dispatch, callStartSimulationApi, showError, showInfo],
  );

  const stopSimulation = React.useCallback(
    (modelUuid: string, simulationUuid: string, correlationId: string) => {
      callStopSimulationApi({
        modelUuid,
        simulationUuid,
        simulationEventCreateRequest: {
          command: 'stop',
        },
        'X-Correlation-ID': correlationId,
      })
        .unwrap()
        .then(() => {
          showInfo(
            t({
              id: 'modelsApi.simulationStopSuccess',
              message: 'Simulation stopping',
            }),
          );
        })
        .catch((e) => {
          if (e.data.message !== CANNOT_STOP_FINISHED_SIMULATION_ERROR) {
            dispatch(projectActions.stopSimulationCancelled(simulationUuid));
            showError(
              t({
                id: 'modelsApi.simulationStopFailed',
                message: 'Unable to stop simulation.',
              }),
              e,
            );
          }
        });
      dispatch(projectActions.stopSimulationRequestSent(simulationUuid));
    },
    [dispatch, callStopSimulationApi, showInfo, showError],
  );

  return {
    startSimulation,
    stopSimulation,
  };
}
