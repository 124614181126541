import { RendererState } from 'ui/modelRendererInternals/modelRenderer';
import { modelActions } from 'app/slices/modelSlice';
import { MouseActions, MouseState } from 'app/common_types/MouseTypes';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { autoInsertNodesIntoLinks } from './autoInsertNodesIntoLinks';

const readOnlyValidStates = [
  MouseActions.Idle,
  MouseActions.Panning,
  MouseActions.MakingSelection,
];

export const transitionMouseState = (
  rs: RendererState,
  newState: MouseState,
) => {
  if (
    !rs.refs.current.canEditModel &&
    !readOnlyValidStates.includes(newState.state)
  ) {
    rs.mouseState = {
      state: MouseActions.Idle,
    };

    return;
  }

  const movingToAnnotations =
    newState.state === MouseActions.DefiningAnnotationBox ||
    newState.state === MouseActions.ReadyToDefineAnnotation;

  const preivouslyInAnnotations =
    rs.mouseState.state === MouseActions.DefiningAnnotationBox ||
    rs.mouseState.state === MouseActions.ReadyToDefineAnnotation;

  if (!movingToAnnotations && preivouslyInAnnotations) {
    rs.dispatch(uiFlagsActions.setUIFlag({ addingAnnotation: false }));
  }

  if (
    rs.mouseState.state === MouseActions.DrawingLinkFromEnd ||
    rs.mouseState.state === MouseActions.DrawingLinkFromStart
  ) {
    rs.dispatch(
      modelActions.removeEntitiesFromModel({
        linkUuids: [rs.mouseState.linkUuid],
      }),
    );
  }

  if (rs.mouseState.state === MouseActions.DraggingLinkSegment) {
    // snap segment to grid
    rs.dispatch(
      modelActions.snapEntitiesToGrid({
        linkUuids: [rs.mouseState.linkUuid],
      }),
    );

    rs.dispatch(
      modelActions.simplifyLinkSegments({ linkUuid: rs.mouseState.linkUuid }),
    );
  }

  if (rs.mouseState.state === MouseActions.DraggingSelected) {
    // snap all to grid
    rs.dispatch(
      modelActions.snapEntitiesToGrid({
        blockUuids: rs.refs.current.selectedNodeIds,
        linkUuids: rs.refs.current.selectedLinkIds,
        annotationUuids: rs.refs.current.selectedAnnotationIds,
      }),
    );

    rs.dispatch(
      modelActions.simplifyBlocksLinkSegments({
        blockUuids: rs.refs.current.selectedNodeIds,
      }),
    );

    autoInsertNodesIntoLinks(rs);
  }

  rs.mouseState = newState;
};
