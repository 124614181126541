// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const SignalDatatypeConversion: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "SignalDatatypeConversion",
      "namespace": "core",
      "description": "This block converts the the input signal to the required data type provided by the user.",
      "feature_level": "dev"
    },
    "modes": {
      "feedthrough": true,
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "convert_to_type",
        "data_type": "string",
        "default_value": "float64",
        "description": "data type of the outputs",
        "value_is_a_type": true
      },
      {
        "name": "rounding_operation",
        "data_type": "string",
        "default_value": "none",
        "description": "Rounding operation when converting the data type to one with less precision",
        "allowed_values": [
          "none",
          "to_nearest",
          "downward",
          "upward",
          "toward_zero"
        ]
      }
    ]
  }
);
