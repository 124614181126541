import { blockClassLookup } from 'app/generated_blocks/';
import { BlockParameterDefinition } from 'app/generated_types/ComputationBlockClass';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { DetailInputRowsSection } from 'ui/modelEditor/DetailsComponents';
import BlockParameter from './BlockParameter';

interface Props {
  parentPath: string[];
  selectedNode: NodeInstance;
  canEdit: boolean;
}

export const CommonBlockParametersDetails: React.FC<Props> = ({
  parentPath,
  selectedNode,
  canEdit,
}) => {
  const { developerModeEnabled, dataIntegrationsEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  if (!selectedNode) return null;

  const blockClass = blockClassLookup(selectedNode.type);
  if (!blockClass || !blockClass.parameter_definitions) {
    return null;
  }

  const isParamVisible = (paramDef?: BlockParameterDefinition): boolean => {
    if (paramDef?.hidden) return false;

    // user option dependent parameters
    if (paramDef?.name === 'data_integration_id') {
      return dataIntegrationsEnabled;
    }

    // TODO: remove this when the rounding operation is supported by the backend
    if (paramDef?.name === 'rounding_operation') {
      return false;
    }

    switch (paramDef?.feature_level) {
      case 'disabled':
        return false;
      case 'dev':
        return developerModeEnabled;
      default:
        return true;
    }
  };

  return (
    <DetailInputRowsSection>
      {blockClass.parameter_definitions.map(
        (param) =>
          isParamVisible(param) && (
            <BlockParameter
              parentPath={parentPath}
              selectedNode={selectedNode}
              paramDef={param}
              key={`row-${param.name}-${selectedNode.uuid}`}
              disabled={!canEdit}
            />
          ),
      )}
    </DetailInputRowsSection>
  );
};
