import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useGetSimulationArtifactsReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import {
  GetSimulationArtifactsReadByUuidApiArg,
  SimulationResultsS3Url,
} from 'app/apiGenerated/generatedApiTypes';
import { useAppSelector } from 'app/hooks';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import ConsoleLogLine, {
  OutputRow,
  OutputRowLevel,
  OutputRowMessage,
  OutputRowText,
  OutputRowTimestamp,
} from 'ui/appBottomBar/OutputLogLine';

const OutputTableWrapper = styled.div`
  overflow: auto;
  height: 100%;
  background-color: #ffffff;
`;

// Note: The bottom padding is to give space for the feedback button
// so it does not cover up content.
const OutputTable = styled.table`
  font-family: monospace;
  font-size: 12px;
  line-height: 1.5;
  color: #000000;
  background-color: #ffffff; // TODO see if we can change this to a theme color
  overflow: scroll;
  padding: 1em;
  width: 100%;
  margin-bottom: 64px;
`;

const S3LinkLog = ({
  url,
  index,
  timestamp,
}: {
  url: SimulationResultsS3Url;
  index: number;
  timestamp: string;
}) => (
  <OutputRow key={index}>
    <OutputRowTimestamp>{timestamp}</OutputRowTimestamp>
    <OutputRowLevel className="inf">INFO</OutputRowLevel>
    <OutputRowText className="inf">
      <OutputRowMessage>
        {url.name.endsWith('.svg') ? (
          // FIXME this is a massive hack to inline images directly
          // Used for preview for EnsembleSims - after redesign, we should
          // remove this special case.
          <>
            {t`Output file:`}{' '}
            <a href={url.url} target="_blank" rel="noreferrer">
              {url.name}
            </a>
            <br />
            <img src={url.url} alt={url.name} width="100%" />
          </>
        ) : (
          <>
            {t`Output file:`}{' '}
            <a href={url.url} target="_blank" rel="noreferrer">
              {url.name}
            </a>
          </>
        )}
      </OutputRowMessage>
    </OutputRowText>
  </OutputRow>
);

export const Output = (): ReactElement => {
  const simulationLogs =
    useAppSelector((state) => state.simResults.simulationLogs) || [];

  const { simulationSummary } = useAppSelector((state) => state.project);

  const requestBody: GetSimulationArtifactsReadByUuidApiArg = {
    modelUuid: simulationSummary?.model_uuid || '',
    simulationUuid: simulationSummary?.uuid || '',
  };
  const { data: artifacts } = useGetSimulationArtifactsReadByUuidQuery(
    requestBody,
    {
      skip:
        !simulationSummary ||
        !['completed', 'failed'].includes(simulationSummary?.status || ''),
    },
  );

  let logLines = simulationLogs;
  let urls = artifacts?.s3_urls.map((s3Url): SimulationResultsS3Url => {
    const basename = s3Url.key?.split('/').reverse()[0] ?? '';
    return { name: basename, url: s3Url.url };
  });

  const timestamp = React.useMemo(() => format(new Date(), 'HH:mm:ss.SSS'), []);

  return (
    <OutputTableWrapper>
      <OutputTable>
        <tbody>
          {logLines.map((rawLog, i) => (
            <ConsoleLogLine rawLog={rawLog} key={i} />
          ))}
          {(urls || [])
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((url, i) => (
              <S3LinkLog url={url} index={i} timestamp={timestamp} />
            ))}
        </tbody>
      </OutputTable>
    </OutputTableWrapper>
  );
};
