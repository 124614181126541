import {
  ClerkLoaded,
  ClerkProvider,
  RedirectToSignIn,
  SignedOut,
} from '@clerk/clerk-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY || '';

// This is a wrapper around ClerkProvider to manage the Auth state after a user
// has authenticated with Clerk. Auth flow itself (sign in, sign up, etc) is
// happening in the LoginProvider component.
const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();

  return (
    <ClerkProvider publishableKey={clerkPubKey} navigate={(to) => navigate(to)}>
      <ClerkLoaded>{children}</ClerkLoaded>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </ClerkProvider>
  );
};

export default AuthProvider;
