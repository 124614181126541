import { PortSide } from 'app/common_types/PortTypes';
import {
  NodeInstance,
  StateMachineBlockInstance,
} from 'app/generated_types/SimulationModel';
import { getSubmodelPortIdOfIoNode, nodeTypeIsSubdiagram } from 'app/helpers';
import {
  ModelState,
  getCurrentParentModelDiagramFromState,
  getCurrentParentSubmodelNodeFromState,
  getCurrentlyEditingModelFromState,
} from 'app/modelState/ModelState';
import { getPortWorldCoordinate } from 'app/utils/getPortOffsetCoordinate';
import {
  connectTwoLinks_mut,
  setLinkSourceAndDependentTapSources,
} from 'app/utils/linkMutationUtils';
import {
  updateModelIOPortBlocksMut,
  updateSubmodelNodeIOPortsMut,
} from 'app/utils/portMutationUtils';
import { unselectNodes } from 'app/utils/selectionUtils';
import { removeSubmodelReferencesRecursive } from 'app/utils/submodelUtils';
import { getLinkTapCoordinate } from 'app/utils/tapLinkToRenderData';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';

export function removeEntitiesFromModel(
  state: ModelState,
  rawBlockUuids?: string[],
  rawLinkUuids?: string[],
  rawAnnotationUuids?: string[],
  autoRemoveBlocksLinks?: boolean,
) {
  const removingBlockUuids = rawBlockUuids || [];
  const removingLinkUuids = rawLinkUuids || [];

  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  // @jp I've added this extra null check because of the following Sentry error:
  // https://sentry.io/organizations/collimator/issues/3687577802/?project=6468400&referrer=slack
  if (!state.currentSubmodelPath) return;

  const currentSubmodelUuid = state.currentSubmodelPath.at(-1);

  const parentSubmodelDiagram = getCurrentParentModelDiagramFromState(state);
  const currentSubmodelNode = parentSubmodelDiagram?.nodes?.find(
    (n) => n.uuid === currentSubmodelUuid,
  );

  const removedNodeLUT: { [k: string]: NodeInstance } = {};
  let removedInportBlocks = 0;
  let removedOutportBlocks = 0;

  model.nodes = model.nodes.filter((node: NodeInstance) => {
    if (!removingBlockUuids.includes(node.uuid)) return true;

    const maybeStateDiagramId = (node as StateMachineBlockInstance)
      .state_machine_diagram_id;
    if (maybeStateDiagramId && state.stateMachines) {
      delete state.stateMachines[maybeStateDiagramId];
    }

    removedNodeLUT[node.uuid] = node;

    // make sure parent block's ports are kept in sync
    // (i am aware that .filter() should usually not have side effects,
    // trying to get this done quickly, so the code isn't beautiful -jackson)
    if (
      currentSubmodelNode &&
      nodeTypeIsSubdiagram(currentSubmodelNode.type) &&
      node.type === 'core.Inport'
    ) {
      const oldPortId = getSubmodelPortIdOfIoNode(node);
      const removingPortID = oldPortId - removedInportBlocks;

      parentSubmodelDiagram?.links?.forEach((link) => {
        if (link.dst && link.dst.node === currentSubmodelNode.uuid) {
          if (
            typeof link.dst.port == 'number' &&
            link.dst.port > removingPortID
          ) {
            link.dst.port -= 1;
          } else if (link.dst.port === removingPortID) {
            link.uiprops.hang_coord_end = getPortWorldCoordinate(
              currentSubmodelNode,
              PortSide.Input,
              link.dst,
            );
            delete link.dst;
          }
        }
      });

      currentSubmodelNode.inputs.pop(); // FIXME @jp not sure about this
      removedInportBlocks += 1;
    } else if (
      currentSubmodelNode &&
      nodeTypeIsSubdiagram(currentSubmodelNode.type) &&
      node.type === 'core.Outport'
    ) {
      const oldPortId = getSubmodelPortIdOfIoNode(node);
      const removingPortID = oldPortId - removedOutportBlocks;

      parentSubmodelDiagram?.links?.forEach((link) => {
        if (link.src && link.src.node === currentSubmodelNode.uuid) {
          if (link.src.port > removingPortID) {
            link.src.port -= 1;
          } else if (link.src.port === removingPortID) {
            link.uiprops.hang_coord_start = getPortWorldCoordinate(
              currentSubmodelNode,
              PortSide.Output,
              link.src,
            );

            setLinkSourceAndDependentTapSources(
              link.uuid,
              undefined,
              parentSubmodelDiagram.links,
            );
          }
        }
      });

      currentSubmodelNode.outputs.pop(); // FIXME @jp not sure about this
      removedOutportBlocks += 1;
    } else if (nodeTypeIsSubdiagram(node.type)) {
      removeSubmodelReferencesRecursive(state.submodels, node.uuid);
    }

    return false;
  });

  // update submodel io ports
  updateModelIOPortBlocksMut(model);
  const parentSubmodelNode = getCurrentParentSubmodelNodeFromState(state);
  if (parentSubmodelNode) {
    updateSubmodelNodeIOPortsMut(parentSubmodelNode, model);
  }

  if (autoRemoveBlocksLinks) {
    model.links = model.links.filter(
      (l) =>
        !(
          (l.src && removingBlockUuids.includes(l.src.node)) ||
          (l.dst && removingBlockUuids.includes(l.dst.node))
        ),
    );
  } else {
    // make sure remaining links hang off at the correct coordinate
    // when losing a port connection
    for (let i = 0; i < model.links.length; i++) {
      const link = model.links[i];

      if (link.src && removingBlockUuids.includes(link.src.node)) {
        link.uiprops.hang_coord_start = getPortWorldCoordinate(
          removedNodeLUT[link.src.node],
          PortSide.Output,
          link.src,
        );

        setLinkSourceAndDependentTapSources(
          link.uuid,
          undefined,
          model.links,
          rendererState?.refs?.current?.linksIndexLUT,
        );
      }
      if (link.dst && removingBlockUuids.includes(link.dst.node)) {
        link.uiprops.hang_coord_end = getPortWorldCoordinate(
          removedNodeLUT[link.dst.node],
          PortSide.Input,
          link.dst,
        );
        delete link.dst;
      }
    }
  }

  unselectNodes(state, { removingBlockUuids });

  if (removingLinkUuids.length > 0) {
    // make sure tap links hang off at the correct position
    // once they no longer have a link to tap
    for (let i = 0; i < model.links.length; i++) {
      const maybeTapLink = model.links[i];

      if (
        maybeTapLink.uiprops.link_type.connection_method === 'link_tap' &&
        removingLinkUuids.includes(
          maybeTapLink.uiprops.link_type.tapped_link_uuid,
        )
      ) {
        const tappedLinkUuid = maybeTapLink.uiprops.link_type.tapped_link_uuid;

        const tappedLink = model.links.find((l) => l.uuid === tappedLinkUuid);

        if (tappedLink && rendererState) {
          setLinkSourceAndDependentTapSources(
            maybeTapLink.uuid,
            undefined,
            model.links,
            rendererState?.refs?.current?.linksIndexLUT,
          );
          maybeTapLink.uiprops.hang_coord_start = getLinkTapCoordinate(
            rendererState,
            maybeTapLink,
          );
          // link_tap style links do not have the ability to "hang off",
          // and if they no longer have a link to tap then
          // they are not technically a tap link anymore anyway.
          maybeTapLink.uiprops.link_type = {
            connection_method: 'direct_to_block',
          };
        }
      }
    }

    model.links = model.links.filter(
      (l) => !removingLinkUuids.includes(l.uuid),
    );

    state.selectedLinkIds = state.selectedLinkIds.filter(
      (selectedId) => !removingLinkUuids.includes(selectedId),
    );
  }

  if (model.annotations) {
    model.annotations = model.annotations.filter(
      (a) => !(rawAnnotationUuids || []).includes(a.uuid),
    );
  }
}

export function disconnectNodeFromAllLinks(
  state: ModelState,
  nodeUuid: string,
  connectSingleIO: boolean,
) {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  const node = model.nodes.find((node) => node.uuid === nodeUuid);
  if (!node) return;

  const outputLinks = model.links.filter(
    (l) =>
      l.uiprops.link_type.connection_method !== 'link_tap' &&
      l.src?.node === nodeUuid,
  );
  const inputLinks = model.links.filter((l) => l.dst?.node === nodeUuid);

  for (let i = 0; i < outputLinks.length; i++) {
    const link = outputLinks[i];

    link.uiprops.hang_coord_start = getPortWorldCoordinate(
      node,
      PortSide.Output,
      link.src,
    );

    setLinkSourceAndDependentTapSources(
      link.uuid,
      undefined,
      model.links,
      rendererState?.refs?.current?.linksIndexLUT,
    );
  }

  for (let i = 0; i < inputLinks.length; i++) {
    const link = inputLinks[i];

    link.uiprops.hang_coord_end = getPortWorldCoordinate(
      node,
      PortSide.Input,
      link.dst,
    );

    link.dst = undefined;
  }

  if (connectSingleIO && inputLinks.length == 1 && outputLinks.length === 1) {
    connectTwoLinks_mut(model, inputLinks[0].uuid, outputLinks[0].uuid);
  }
}
