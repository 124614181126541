import { CmlType, DimExpr } from 'app/generated_types/CmlTypes';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { getSimulationRef } from 'app/sliceRefAccess/SimulationRef';
import { MINIMUM_ZOOM } from 'app/slices/cameraSlice';
import * as NVG from 'nanovg-js';
import { cmlIsMatrix, cmlTypeName } from 'ui/modelEditor/LinkDetails';
import { getPortPathName } from 'ui/modelEditor/portPathNameUtils';
import { RendererState } from 'ui/modelRendererInternals/modelRenderer';
import { calculateTextSize } from 'util/calculateTextSize';
import {
  RasterLoadState,
  getOrInitLoadImageFromStore,
} from './rasterTextureStore';

const LABEL_FONTSIZE = 12;
const LABEL_SCALEDOWN_THRESHOLD = 0.7;
const LABEL_SCALEUP_THRESHOLD = 1.75;
const MIN_LABEL_FONTSIZE = 9;

const labelIconSize = 0; // keeping this in case we want to bring back icons for a label
const labelMarginBottom = 3;
const labelMarginLeft = 4;
const innerPaddingLeft = 4 + labelIconSize;
const innerPaddingRight = 4;
const baseYOffset = 1;

const drawLabel = (
  nvg: NVG.Context,
  rs: RendererState,
  rX: number,
  rY: number,
  offsetCount: number,
  text: string,
  fadeText?: boolean,
  iconId?: string,
  fadeIcon?: boolean,
  flipped?: boolean,
) => {
  const rasterMeta = getOrInitLoadImageFromStore(
    nvg,
    `${process.env.PUBLIC_URL}/assets/signal_icons/${iconId}.png`,
    iconId || '__label_no_image',
    2,
  );

  const scaledDownFontSize = Math.max(
    LABEL_FONTSIZE * (rs.zoom / LABEL_SCALEDOWN_THRESHOLD),
    MIN_LABEL_FONTSIZE,
  );
  const fontSize =
    rs.zoom < LABEL_SCALEDOWN_THRESHOLD
      ? scaledDownFontSize
      : rs.zoom > LABEL_SCALEUP_THRESHOLD
      ? LABEL_FONTSIZE * (rs.zoom / LABEL_SCALEUP_THRESHOLD)
      : LABEL_FONTSIZE;

  const rawLabelOpacity =
    (rs.zoom - MINIMUM_ZOOM * 2) /
    (LABEL_SCALEDOWN_THRESHOLD - MINIMUM_ZOOM * 2);
  const labelOpacity = Math.max(0, Math.min(1, rawLabelOpacity));

  const { width: textWidthRaw } = calculateTextSize(text, {
    font: 'Archivo',
    fontSize: `${fontSize * 0.9}px`,
  });

  const textWidth = textWidthRaw;

  const labelVPadding = 2;
  const labelHeight = fontSize + labelVPadding * 2;
  const labelTextOffset = labelVPadding;

  nvg.fontSize(fontSize);
  nvg.fontFace('archivo');
  nvg.textAlign(NVG.Align.LEFT | NVG.Align.TOP);

  const rectWidth = textWidth + innerPaddingLeft + innerPaddingRight;

  const rectX = flipped
    ? (rX - labelMarginLeft) * rs.zoom - rectWidth
    : (rX + labelMarginLeft) * rs.zoom;
  const rectY =
    (rY - baseYOffset) * rs.zoom -
    (labelHeight + labelMarginBottom) * (offsetCount + 1);

  nvg.beginPath();
  nvg.roundedRect(rectX, rectY, rectWidth, labelHeight, 3);
  nvg.fillColor(nvg.RGBA(241, 243, 243, 255 * labelOpacity));
  nvg.fill();

  const textX = flipped
    ? (rX - labelMarginLeft) * rs.zoom - textWidth - innerPaddingLeft
    : (rX + labelMarginLeft) * rs.zoom + innerPaddingLeft;

  const textY =
    (rY - baseYOffset) * rs.zoom -
    (labelHeight + labelMarginBottom) * (offsetCount + 1) +
    labelTextOffset;

  nvg.fillColor(nvg.RGBA(92, 111, 112, (fadeText ? 128 : 255) * labelOpacity));
  nvg.text(textX, textY, text);

  if (rasterMeta?.loadState === RasterLoadState.Loaded) {
    const imgPaint = nvg.imagePattern(
      rectX,
      rectY,
      labelIconSize,
      labelIconSize,
      0,
      rasterMeta.imageId,
      fadeIcon ? 0.5 : 1,
    );
    nvg.beginPath();
    nvg.rect(rectX, rectY, labelIconSize, labelIconSize);
    nvg.fillPaint(imgPaint);
    nvg.fill();
  }
};

const corrections: { [k: string]: string | undefined } = {
  Float: 'Float64',
};

export const cmlTypeToDisplayTypeAndIcon = (
  cmt?: CmlType,
): { displayType: string; icon: string; fadeIcon: boolean } => {
  if (!cmt) {
    return {
      displayType: 'Unknown',
      icon: 'scalar_signal_type_icon',
      fadeIcon: true,
    };
  }

  const correctedDisplayType = cmlTypeName(cmt);

  switch (cmt.kind) {
    case 'Tuple':
      return {
        displayType: `Tuple ${JSON.stringify(
          cmt.value.map((v) => cmlTypeToDisplayTypeAndIcon(v).displayType),
        )}`,
        icon: 'scalar_signal_type_icon',
        fadeIcon: true,
      };
    case 'Tensor':
      const dimensionsArray = cmt.value[0];
      const isMatrix = cmlIsMatrix(dimensionsArray);
      const icon = isMatrix
        ? 'matrix_signal_type_icon'
        : 'vector_signal_type_icon';

      const mainDisplayType = cmlTypeToDisplayTypeAndIcon(
        cmt.value[1],
      ).displayType;

      let displayDimensions = '';
      if (dimensionsArray instanceof Array) {
        // FIXME: cmlc-v1 support
        displayDimensions = JSON.stringify(dimensionsArray)
          .replace('[', '(')
          .replace(']', ')');
      } else if (dimensionsArray?.kind === 'Dims') {
        const dims: DimExpr[] = dimensionsArray.value;
        const dimsAr = dims.map((d) => (d.kind === 'Int' ? d.value : '?'));
        displayDimensions = `(${dimsAr.join(', ')})`;
      }
      return {
        displayType: `${mainDisplayType} ${displayDimensions}`,
        icon,
        fadeIcon: false,
      };
    case 'UInt':
    case 'Int':
    case 'Float':
    case 'Complex':
      return {
        displayType: correctedDisplayType,
        icon: 'scalar_signal_type_icon',
        fadeIcon: false,
      };
    case 'Python':
      return {
        displayType: `Python<${cmt.value}>`,
        icon: 'scalar_signal_type_icon',
        fadeIcon: true,
      };
  }

  return {
    displayType: cmt.kind,
    icon: 'scalar_signal_type_icon',
    fadeIcon: true,
  };
};

export function drawSignalLabels(
  nvg: NVG.Context,
  rs: RendererState,
  worldX: number,
  worldY: number,
  offsetX: number,
  offsetY: number,
  nodeUuid: string,
  outputId: number,
): void {
  const originNode =
    rs.refs.current.nodes[rs.refs.current.nodesIndexLUT[nodeUuid]];
  if (!originNode) return;

  const portPathName = getPortPathName(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    {
      parentPath: getCurrentModelRef().submodelPath,
      nodeId: nodeUuid,
      portIndex: outputId,
    },
    { includePortNameForNonSubmodels: false },
  );

  const { datatypeAndDimensions } = (portPathName &&
    getSimulationRef().compilationData.signalsData[portPathName]) || {
    datatypeAndDimensions: undefined,
  };

  if (rs.refs.current.uiFlags.showDatatypesInModel) {
    const portDataTypeAndDimensions = (datatypeAndDimensions || [])[outputId];
    const {
      displayType,
      icon: _icon,
      fadeIcon,
    } = cmlTypeToDisplayTypeAndIcon(portDataTypeAndDimensions);

    const flipped = originNode.uiprops.directionality === 'left';

    drawLabel(
      nvg,
      rs,
      worldX + offsetX,
      worldY + offsetY,
      0,
      displayType,
      !datatypeAndDimensions || displayType === 'Unknown',
      undefined,
      fadeIcon,
      flipped,
    );
  }
}
