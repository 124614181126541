import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import DataFileParameter from 'ui/common/parameters/DataFileParameter';
import {
  DetailInputRowsSection,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';

const InitializationScriptDetails = () => {
  const dispatch = useAppDispatch();

  const isSubmodel = useAppSelector(
    (state) => state.submodels.topLevelModelType === 'Submodel',
  );

  const initScriptName = useAppSelector((state) =>
    isSubmodel
      ? state.model.present.submodelConfiguration?.init_script?.value
      : state.model.present.configuration.workspace?.init_scripts?.[0]
          ?.file_name,
  );

  const changeInitScriptName = React.useCallback(
    (value: string) => {
      dispatch(
        modelActions.setInitScriptName({ file_name: value, isSubmodel }),
      );
    },
    [dispatch, isSubmodel],
  );

  return (
    <>
      <SectionHeading testId="model">
        {t({
          id: 'modelRenderer.propertiesSidebar.initializationScript.heading',
          message: 'Initialization script',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        <DetailsSection vertical>
          <DataFileParameter
            datafileType="PythonScript"
            onSelectValue={changeInitScriptName}
            currentValue={initScriptName || ''}
            allowEmpty
          />
        </DetailsSection>
      </DetailInputRowsSection>
    </>
  );
};

export default InitializationScriptDetails;
