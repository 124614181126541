import { useProjectOrRedirect } from 'app/behaviorHooks/useProjectOrRedirect';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import RequirementsManager from './RequirementsManager';

const ValidateRouteRequirements: React.FC = () => {
  // FIXME this type of checking belongs higher up in ProjectPage
  const { isLoadingProject, project } = useProjectOrRedirect();

  const navigate = useNavigate();

  const areUserOptionsLoaded = useAppSelector(
    (state) => state.userOptions.areUserOptionsLoaded,
  );

  if (isLoadingProject || !areUserOptionsLoaded) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (!project) {
    console.error('Unable to load project for requirements editor.');
    return null;
  }

  return <RequirementsManager key={project.uuid} project={project} />;
};

export default ValidateRouteRequirements;
