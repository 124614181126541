import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { SplitButton } from 'ui/common/Button/SplitButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  Experiment as ExperimentIcon,
  Model as ModelIcon,
  Submodel as SubmodelIcon,
} from 'ui/common/Icons/Standard';
import { ItemType, MenuItemConfig } from 'ui/common/Menu/menuItemTypes';
import { useModal } from 'ui/common/Modal/useModal';
import AddReferenceSubmodelModal from 'ui/modelEditor/AddReferenceSubmodelModal';
import AddModelModal from './AddModelModal';

const MenuItemIconGrouper = styled.div`
  display: flex;
  align-items: center;

  > svg:first-of-type {
    margin-right: ${({ theme }) => theme.spacing.normal};
  }
`;

interface Props {
  project: Project;
}

export const ProjectDetailNewItemButtons: React.FC<Props> = ({ project }) => {
  const { experimentsEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const theme = useTheme();

  const { triggerModal } = useModal();

  const openCreateModelModal = () => {
    triggerModal(
      <AddModelModal projectId={project.uuid} />,
      t`Create a new model`,
    );
  };

  const openCreateSubmodelModal = () => {
    triggerModal(
      <AddReferenceSubmodelModal projectId={project.uuid} />,
      t({
        id: 'dashboard.newSubmodelModal.title',
        message: 'Create a new submodel',
      }),
    );
  };

  const openCreateExperimentModal = () => {
    triggerModal(
      <AddModelModal projectId={project.uuid} kind="Experiment" />,
      t`Create a new experiment`,
    );
  };

  const modelCreateMenuItems: MenuItemConfig[] = [
    {
      type: ItemType.Button,
      content: (
        <MenuItemIconGrouper>
          <SubmodelIcon fill={theme.colors.grey[50]} />
          {t({
            id: 'dashboard.projectsDetailHeader.newSubmodelButton.Label',
            message: 'New Submodel',
          })}
        </MenuItemIconGrouper>
      ),
      onClick: openCreateSubmodelModal,
    },
  ];

  if (experimentsEnabled) {
    modelCreateMenuItems.push({
      type: ItemType.Button,
      content: (
        <MenuItemIconGrouper>
          <ExperimentIcon fill={theme.colors.grey[50]} />
          {t({
            id: 'dashboard.projectsDetailHeader.newExperimentButton.Label',
            message: 'New Experiment',
          })}
        </MenuItemIconGrouper>
      ),
      onClick: openCreateExperimentModal,
    });
  }

  return (
    <SplitButton
      mainTestId="project-detail-new-model-button"
      mainLabel={t({
        id: 'dashboard.projectsDetailHeader.newModelButton.Label',
        message: 'New Model',
      })}
      mainButtonProps={{
        Icon: ModelIcon,
        variant: ButtonVariants.SmallPrimary,
        onClick: openCreateModelModal,
      }}
      menuProps={{
        items: modelCreateMenuItems,
      }}
    />
  );
};
