import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { jupyterSelectors, jupyterActions } from 'app/slices/jupyterSlice';
import {
  useGetJupyterProjectReadByUuidQuery,
  usePostJupyterProjectInitByUuidMutation,
} from 'app/apiGenerated/generatedApi';
import { getAdminAssumedUser } from 'app/config/adminAssumedUser';

export function useJupyterProject(projectId: string) {
  const dispatch = useAppDispatch();
  const { hasAccessToJupyter } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const forceRefetchFiles = useAppSelector(
    jupyterSelectors.selectForceRefetchFiles,
  );

  const [callProjectInitApi] = usePostJupyterProjectInitByUuidMutation();

  const files = useGetJupyterProjectReadByUuidQuery(
    {
      projectUuid: projectId,
      userUuid: getAdminAssumedUser(),
    },
    { skip: !hasAccessToJupyter },
  );

  React.useEffect(() => {
    if (forceRefetchFiles) {
      files.refetch();
      dispatch(jupyterActions.clearRequestRefetchFiles());
    }
  }, [forceRefetchFiles, dispatch, files]);

  const initProject = React.useCallback(
    () => callProjectInitApi({ projectUuid: projectId }),
    [callProjectInitApi, projectId],
  );

  React.useEffect(() => {
    if (hasAccessToJupyter) {
      initProject();
    }
  }, [initProject, hasAccessToJupyter]);

  return {
    files,
  };
}
