import { t } from '@lingui/macro';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import {
  TimeModeValueType,
  inheritedTimeModes,
  readonlyTimeModes,
  sourceTimeModes,
} from 'app/common_types/TimeModeTypesData';
import { blockClassLookup } from 'app/generated_blocks/';
import {
  BlockInstance,
  SubmodelInstance,
} from 'app/generated_types/SimulationModel';
import {
  nodeTypeIsLocalSubdiagram,
  nodeTypeIsReferencedSubmodel,
  nodeTypeIsSubdiagram,
  printNameToSpacedDisplayName,
} from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  modelActions,
  selectSubmodelReferenceUuid,
} from 'app/slices/modelSlice';
import {
  findNodeInDiagram,
  getIsCurrentDiagramReadonly,
  getNestedNode,
} from 'app/utils/modelDiagramUtils';
import { isSamePath } from 'app/utils/parentPathUtils';
import { getSpecificReferenceSubmodelByNode } from 'app/utils/submodelUtils';
import React from 'react';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput from 'ui/common/SelectInput';
import { BlockDetailTitle } from 'ui/modelEditor/BlockDetailTitle';
import { BlockParametersDetails } from 'ui/modelEditor/BlockParametersDetails';
import {
  DetailInputRowsSection,
  DetailsLabel,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import SubmodelParameterDetails from 'ui/modelEditor/SubmodelParameterDetails';
import { SubmodelSimulationDetails } from 'ui/modelEditor/SubmodelSimulationDetails';
import VersionPicker from 'ui/modelEditor/VersionPicker';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';
import BlockCodeGenDetails from './BlockCodeGenDetails';
import BlockDisplayDetails from './BlockDisplayDetails';
import BlockInportDetails from './BlockInportDetails';
import { BlockNameField } from './BlockNameField';
import BlockOutportDetails from './BlockOutportDetails';
import CodeBlockParameterDetails from './BlockParameterDetails/CodeBlockParameterDetails';
import CppBlockParameterDetails from './BlockParameterDetails/CppBlock/CppBlockParameterDetails';
import FmuBlockParameterDetails from './BlockParameterDetails/FmuBlockParameterDetails';
import DeveloperOptionsDetails from './DeveloperOptionsDetails';
import ExperimentModelInportDetails from './ExperimentModelInportDetails';
import ExperimentModelOutportDetails from './ExperimentModelOutportDetails';
import LegacySubmodelParametersDetails from './LegacySubmodelParameterDetails';
import RequirementsSelector from './blockDetails/RequirementsSelector';

interface Props {
  parentPath: string[];
  nodeId: string;
  canEditCurrentModelVersion: boolean;
}

export const BlockDetails: React.FC<Props> = ({
  parentPath,
  nodeId,
  canEditCurrentModelVersion,
}) => {
  const dispatch = useAppDispatch();

  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );
  const topLevelSubmodels = useAppSelector(
    (state) => state.model.present.submodels,
  );
  const topLevelNodes = useAppSelector(
    (state) => state.model.present.rootModel.nodes,
  );
  const idToVersionIdToSubmodelInfo = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelInfo,
  );
  const idToVersionIdToSubmodelInfoLite = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelInfoLite,
  );
  const selectedNodeInCurrentDiagramScope = findNodeInDiagram(
    topLevelNodes,
    topLevelSubmodels,
    nodeId,
  );

  // determine read only mode
  const { modelId, projectId, versionId } = useAppParams();

  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  const referenceSubmodelId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelReferenceId,
  );

  // Different from referenceSubmodelId if we're in a submodel with no node selected
  const parentSubmodelReferenceUuid = useAppSelector((state) =>
    selectSubmodelReferenceUuid(state.model.present, parentPath),
  );

  const { arePermissionsLoaded } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const isDiagramReadonly = getIsCurrentDiagramReadonly({
    modelId,
    loadedModelId,
    referenceSubmodelId,
    arePermissionsLoaded,
    canEditCurrentModelVersion,
  });

  const canEdit =
    canEditCurrentModelVersion && !!selectedNodeInCurrentDiagramScope;

  const selectedNode =
    selectedNodeInCurrentDiagramScope ||
    getNestedNode(topLevelNodes, topLevelSubmodels, parentPath, nodeId);

  if (!selectedNode) {
    return null;
  }

  let submodelInfoLiteReference: SubmodelInfoLiteUI | undefined;
  let submodelInfoReference: SubmodelInfoUI | undefined;
  if (nodeTypeIsReferencedSubmodel(selectedNode.type)) {
    const submodelInstance = selectedNode as SubmodelInstance;
    if (submodelInstance && submodelInstance.submodel_reference_uuid) {
      submodelInfoLiteReference = getSpecificReferenceSubmodelByNode(
        submodelInstance,
        idToVersionIdToSubmodelInfoLite,
      );
      submodelInfoReference = getSpecificReferenceSubmodelByNode(
        submodelInstance,
        idToVersionIdToSubmodelInfo,
      );
    }
  }

  const changeTimeMode = (value: string) => {
    dispatch(
      modelActions.changeBlockCommonParameter({
        parentPath,
        blockUuid: nodeId,
        paramPayload: {
          paramName: 'time_mode',
          value: value as TimeModeValueType,
        },
      }),
    );
  };

  const blockClass = blockClassLookup(selectedNode.type);

  return (
    <>
      {/* Block name and type */}
      <BlockDetailTitle
        description={
          submodelInfoLiteReference
            ? submodelInfoLiteReference.description
            : nodeTypeIsLocalSubdiagram(selectedNode.type)
            ? ''
            : blockClass.base.description
        }
        helpUrl={blockClass.base.help_url}
        blockTypeDisplayName={
          submodelInfoLiteReference
            ? submodelInfoLiteReference.name
            : printNameToSpacedDisplayName(blockClass.base.name)
        }
        blockClass={blockClass}
        submodelReference={submodelInfoReference}
      />
      <DetailInputRowsSection>
        <BlockNameField
          parentPath={parentPath}
          blockUuid={selectedNode.uuid}
          title={selectedNode.name}
          canEdit={canEdit}
        />
      </DetailInputRowsSection>

      {nodeTypeIsReferencedSubmodel(selectedNode.type) && (
        <VersionPicker
          parentPath={parentPath}
          submodelNode={selectedNode as SubmodelInstance}
          canEdit={canEdit}
        />
      )}
      {projectId && modelId && (
        <RequirementsSelector
          projectUuid={projectId}
          modelId={parentSubmodelReferenceUuid || modelId}
          blockInstanceUuid={nodeId}
          canEdit={canEdit}
        />
      )}

      {/* Parameters */}
      {selectedNode.type === 'core.Group' ? null : selectedNode.type ===
        'core.Submodel' ? (
        <LegacySubmodelParametersDetails
          parentPath={parentPath}
          submodelNode={selectedNode}
          canEdit={canEdit}
        />
      ) : selectedNode.type === 'core.ReferenceSubmodel' ? (
        <SubmodelParameterDetails
          parentPath={parentPath}
          submodelNode={selectedNode as SubmodelInstance}
          disabled={!canEdit}
        />
      ) : selectedNode.type === 'core.PythonScript' ||
        selectedNode.type === 'core.CFunction' ? (
        <CodeBlockParameterDetails
          parentPath={parentPath}
          node={selectedNode as BlockInstance}
          disabled={!canEdit}
        />
      ) : selectedNode.type === 'core.CppFunction' ? (
        <CppBlockParameterDetails
          parentPath={parentPath}
          node={selectedNode as BlockInstance}
          disabled={!canEdit}
        />
      ) : selectedNode.type === 'core.ModelicaFMU' ? (
        <FmuBlockParameterDetails
          parentPath={parentPath}
          node={selectedNode as BlockInstance}
          disabled={!canEdit}
        />
      ) : (
        <BlockParametersDetails
          parentPath={parentPath}
          selectedNode={selectedNode}
          canEdit={canEdit}
        />
      )}

      {/* Simulation settings */}
      {nodeTypeIsReferencedSubmodel(selectedNode.type) && (
        <SubmodelSimulationDetails
          canEdit={false}
          submodelInfoReference={submodelInfoReference}
        />
      )}

      {!nodeTypeIsSubdiagram(selectedNode.type) && (
        <>
          <SectionHeading testId="simulation">
            {t({
              id: 'modelRenderer.propertiesSidebar.simulationSettingsHeading.label',
              message: 'Simulation',
            })}
          </SectionHeading>
          <DetailInputRowsSection key="sim_params">
            <DetailsSection key={`row-result-time_mode-${selectedNode.uuid}`}>
              <DetailsLabel data-test-id="block-details-time-mode-label">
                {t({
                  id: 'blockDetails.TimeModeLabel',
                  message: 'Time mode',
                })}
              </DetailsLabel>
              {blockClass.modes.time === 'any' ? (
                <SelectInput
                  testId="block-details-time-mode-select"
                  onSelectValue={changeTimeMode}
                  currentValue={
                    (selectedNode as BlockInstance)?.time_mode || 'agnostic'
                  }
                  options={
                    selectedNode.inputs.length === 0
                      ? sourceTimeModes
                      : inheritedTimeModes
                  }
                  isDisabled={!canEdit}
                />
              ) : (
                <SelectInput
                  testId="block-details-time-mode-select"
                  isDisabled
                  currentValue={blockClass.modes.time}
                  options={readonlyTimeModes}
                />
              )}
            </DetailsSection>
          </DetailInputRowsSection>
        </>
      )}

      {/* Inputs */}
      {selectedNode.type === 'core.ExperimentModel' ? (
        <ExperimentModelInportDetails
          parentPath={parentPath}
          selectedNode={selectedNode}
          canEdit={canEdit}
        />
      ) : (
        <BlockInportDetails
          parentPath={parentPath}
          selectedNode={selectedNode}
          canEdit={canEdit}
        />
      )}

      {/* Outputs */}
      {selectedNode.type === 'core.ExperimentModel' ? (
        <ExperimentModelOutportDetails
          parentPath={parentPath}
          selectedNode={selectedNode}
          canEdit={canEdit}
        />
      ) : (
        <BlockOutportDetails
          parentPath={parentPath}
          selectedNode={selectedNode}
          canEdit={canEdit}
        />
      )}

      {/* Settings */}
      {isSamePath(currentSubmodelPath, parentPath) && (
        <BlockDisplayDetails selectedNode={selectedNode} />
      )}

      {selectedNode.type === 'core.ReferenceSubmodel' && !isDiagramReadonly && (
        <BlockCodeGenDetails selectedNode={selectedNode} />
      )}

      <DeveloperOptionsDetails />
    </>
  );
};
