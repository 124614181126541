import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { NotebookFooterTab } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { OpenConsole } from 'ui/notebook/jupyterHelpers/OpenConsole';
import { OpenVariableInspector } from 'ui/notebook/jupyterHelpers/OpenVariableInspector';

const ContentSpaceHolder = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.sizes.footerBarHeight};
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background: transparent;
`;
interface Props {
  isFooterOpen: boolean;
}

const NotebookFooterContent: React.FC<Props> = ({ isFooterOpen }) => {
  const notebookFooterTab = useAppSelector(
    (state) => state.uiFlags.notebookFooterTab,
  );

  const [lastNotebookFooterTab, setLastNotebookFooterTab] =
    React.useState<NotebookFooterTab>(NotebookFooterTab.None);

  React.useEffect(() => {
    // Ensure the footer starts out collapsed.
    OpenVariableInspector(true);
    OpenConsole(true);
  }, []);

  React.useEffect(() => {
    if (notebookFooterTab === lastNotebookFooterTab) {
      return;
    }

    // Close the last tab on Jupyter side.
    if (lastNotebookFooterTab === NotebookFooterTab.Variables) {
      OpenVariableInspector(true);
    }
    if (lastNotebookFooterTab === NotebookFooterTab.Console) {
      OpenConsole(true);
    }

    // Open the last tab on Jupyter side.
    if (notebookFooterTab === NotebookFooterTab.Console) {
      OpenConsole(false);
    }
    if (notebookFooterTab === NotebookFooterTab.Variables) {
      OpenVariableInspector(false);
    }

    setLastNotebookFooterTab(notebookFooterTab);
  }, [notebookFooterTab, lastNotebookFooterTab, setLastNotebookFooterTab]);

  return isFooterOpen ? <ContentSpaceHolder /> : null;
};

export default NotebookFooterContent;
