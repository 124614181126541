import styled from '@emotion/styled';
import { useJupyter } from 'app/api/useJupyter';
import { useNavigateToProjectHome } from 'app/api/useNavigateToProjectHome';
import React from 'react';

const HomeLink = styled.a`
  padding: 0 12px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: radial-gradient(
      122.5% 122.5% at 50% 100%,
      #69e1db 0%,
      #343a70 100%
    );
  }
  &:active {
    background: radial-gradient(
      283.75% 283.75% at 50% 50%,
      #69e1db 0%,
      #343a70 100%
    );
  }
  background: radial-gradient(190% 190% at 50% 190%, #019b99 0%, #343a70 100%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: 16px;
  fill: ${({ theme }) => theme.colors.grey[2]};
`;

const HomeButton: React.FC = () => {
  const { saveFile } = useJupyter();
  const { navigateToProjectHome } = useNavigateToProjectHome();

  const goHome = () => {
    saveFile();
    navigateToProjectHome();
  };
  return (
    <HomeLink onClick={goHome} data-test-id="home-button">
      <Logo
        alt="home"
        src={`${process.env.PUBLIC_URL}/assets/topbar_logo.svg`}
      />
    </HomeLink>
  );
};

export default HomeButton;
