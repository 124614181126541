import { t } from '@lingui/macro';

import { useJupyter } from 'app/api/useJupyter';
import { Kernel } from 'ui/common/Icons/Standard';
import Copy from 'ui/common/Icons/Standard/Copy';
import Cut from 'ui/common/Icons/Standard/Cut';
import Paste from 'ui/common/Icons/Standard/Paste';
import Plus from 'ui/common/Icons/Standard/Plus';
import Save from 'ui/common/Icons/Standard/Save';
import KernelMenu from 'ui/notebook/Project';

import {
  NavbarButton,
  NavbarDropdownButton,
  NavbarSpace,
} from './NavbarButtons';

interface Props {
  isNotebookFile: boolean;
}

const NotebookTools: React.FC<Props> = ({ isNotebookFile }) => {
  const { insertCell, cutCell, saveFile, copyCell, pasteCell, saveNotebook } =
    useJupyter();
  return (
    <>
      {/* Kernel browser */}
      <NavbarDropdownButton
        tooltipText={t({
          id: 'navbar.notebook.kernelBrowserTooltip',
          message: 'Kernel browser',
        })}
        Icon={Kernel}
        DropdownContent={KernelMenu}
        isEnabled
        testId="navbar-kernel-browser-button"
      />

      <NavbarSpace />

      {!isNotebookFile && (
        <NavbarButton
          tooltipText={t({
            id: 'navbar.notebook.saveFileTooltip',
            message: 'Save File',
          })}
          Icon={Save}
          isEnabled
          onClickHandler={saveFile}
          testId="navbar-save-file-button"
        />
      )}
      {isNotebookFile && (
        <>
          <NavbarButton
            tooltipText={t({
              id: 'navbar.notebook.saveNotebookTooltip',
              message: 'Save Notebook',
            })}
            Icon={Save}
            isEnabled
            onClickHandler={saveNotebook}
            testId="navbar-save-notebook-button"
          />
          <NavbarButton
            tooltipText={t({
              id: 'navbar.notebook.insertCellTooltip',
              message: 'Insert Cell',
            })}
            Icon={Plus}
            isEnabled
            onClickHandler={insertCell}
            testId="navbar-insert-cell-button"
          />

          <NavbarButton
            tooltipText={t({
              id: 'navbar.notebook.cutSelectedCellTooltip',
              message: 'Cut Selected Cell',
            })}
            Icon={Cut}
            isEnabled
            onClickHandler={cutCell}
            testId="navbar-cut-selected-cell-button"
          />
          <NavbarButton
            tooltipText={t({
              id: 'navbar.notebook.copySelectedCellTooltip',
              message: 'Copy Selected Cell',
            })}
            Icon={Copy}
            isEnabled
            onClickHandler={copyCell}
            testId="navbar-copy-selected-cell-button"
          />
          <NavbarButton
            tooltipText={t({
              id: 'navbar.notebook.pasteCellFromClipboardTooltip',
              message: 'Paste Cell from Clipboard',
            })}
            Icon={Paste}
            isEnabled
            onClickHandler={pasteCell}
            testId="navbar-paste-cell-button"
          />
        </>
      )}
    </>
  );
};

export default NotebookTools;
