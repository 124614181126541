// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const Demux: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Demux",
      "namespace": "core",
      "description": "Split a vector signal into its component, and pass each component as an output from this block.",
      "development_notes": "FIXME ports should be automatic based on the connected mux",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#demux",
      "supports_experiments": true
    },
    "modes": {
      "feedthrough": true,
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ]
      },
      "outputs": {
        "dynamic": {
          "min_count": 1,
          "max_count": -1,
          "default_count": 2
        }
      },
      "has_automatic_ports": true
    }
  }
);
