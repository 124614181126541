/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Kernel: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8 7.99999H9H11.6667C12.2189 7.99999 12.6766 8.46054 12.5583 8.99999C12.4342 9.56568 12.2052 10.1062 11.8802 10.5927C11.3674 11.3601 10.6386 11.9582 9.78585 12.3114C8.93313 12.6646 7.99482 12.7571 7.08958 12.577C6.18433 12.3969 5.35281 11.9525 4.70017 11.2998C4.04752 10.6472 3.60306 9.81566 3.423 8.91042C3.24293 8.00517 3.33535 7.06686 3.68856 6.21414C4.04177 5.36142 4.63991 4.63258 5.40734 4.1198C5.89374 3.7948 6.43432 3.56583 7 3.44173C7.53945 3.32338 8 3.78104 8 4.33333V6.99999V7.99999ZM8 8.99999H11.5277C11.4237 9.36662 11.2627 9.7168 11.0487 10.0371C10.6458 10.6401 10.0732 11.11 9.40317 11.3876C8.73317 11.6651 7.99593 11.7377 7.28467 11.5962C6.5734 11.4547 5.92006 11.1055 5.40727 10.5927C4.89448 10.0799 4.54526 9.42659 4.40378 8.71533C4.26231 8.00406 4.33492 7.26682 4.61244 6.59682C4.88996 5.92683 5.35993 5.35417 5.96291 4.95127C6.28319 4.73726 6.63337 4.57626 7 4.47233V7.99999V8.99999H8ZM11.7463 6.05772C11.9138 6.46213 12 6.89559 12 7.33333H8.66666V3.99999C9.1044 3.99999 9.53786 4.08621 9.94227 4.25373C10.3467 4.42125 10.7142 4.66678 11.0237 4.97631C11.3332 5.28583 11.5787 5.6533 11.7463 6.05772Z"
      fill={props.fill}
    />
  </svg>
);

Kernel.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Kernel;
