import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Cell, Column, Row, SortByFn, TableState } from 'react-table';
import {
  Bus,
  Code,
  Experiment as ExperimentIcon,
  File,
  Model as ModelIcon,
  PythonFile,
  Submodel,
} from 'ui/common/Icons/Standard';
import NotebookIcon from 'ui/common/Icons/Standard/Notebook';
import ValidationError from 'ui/common/Input/ValidationError';
import { useModal } from 'ui/common/Modal/useModal';
import SpinnerWithTooltip from 'ui/common/SpinnerWithTooltip';
import Table from 'ui/common/Table/Table';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import {
  ProjectItemType,
  ProjectsDetailTableData,
  ProjectsDetailTableData as TableData,
} from 'ui/dashboard/dashboardTypes';
import { formatDate } from 'util/dateUtils';
import { splitFullName } from 'util/fileUtils';
import DownloadFileModal from './DownloadFileModal';
import ProjectItemMenuButton from './ProjectItemMenuButton';

const TYPE_SORT_PRIORITIES = {
  [ProjectItemType.EXPERIMENT]: 7,
  [ProjectItemType.MODEL]: 6,
  [ProjectItemType.SUBMODEL]: 5,
  [ProjectItemType.NOTEBOOK]: 4,
  [ProjectItemType.CODE]: 3,
  [ProjectItemType.BUS_TYPE]: 2,
  [ProjectItemType.NOTEBOOKFILE]: 1,
  [ProjectItemType.PROJECTFILE]: 0,
};

const ErrorIconWrapper = styled.div`
  position: relative;
  height: 20px;
  width: 24px;
  margin-right: -4px;
`;

const DateBlock = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CellIcon = (cell: Cell<TableData>, theme: Theme) => {
  if (cell.row.original.isProcessing) {
    return (
      <SpinnerWithTooltip
        tooltipPlacement={TooltipPlacement.BOTTOM_RIGHT}
        tooltipMessage={t({
          id: 'dashboard.projectDetails.fileItemIcon.isProcessing.tooltip',
          message: 'Processing in progress',
        })}
      />
    );
  }

  if (cell.row.original.error) {
    return (
      <ErrorIconWrapper>
        <ValidationError
          validationError={cell.row.original.error}
          tooltipPlacement={TooltipPlacement.BOTTOM_RIGHT}
          isInFocus={false}
        />
      </ErrorIconWrapper>
    );
  }

  switch (cell.row.original.type) {
    case ProjectItemType.MODEL:
      return <ModelIcon fill={theme.colors.text.secondary} />;
    case ProjectItemType.EXPERIMENT:
      return <ExperimentIcon fill={theme.colors.text.secondary} />;
    case ProjectItemType.SUBMODEL:
      return <Submodel fill={theme.colors.text.secondary} />;
    case ProjectItemType.NOTEBOOK:
      return <NotebookIcon fill={theme.colors.text.secondary} />;
    case ProjectItemType.NOTEBOOKFILE:
    case ProjectItemType.CODE:
      return <Code fill={theme.colors.text.secondary} />;
    case ProjectItemType.BUS_TYPE:
      return <Bus fill={theme.colors.text.secondary} />;
    default:
      if (cell.row.original.name.split('.').pop() === 'py') {
        return <PythonFile fill={theme.colors.text.secondary} />;
      }
      return <File fill={theme.colors.text.secondary} />;
  }
};

const Date = (cell: Cell<TableData>) => (
  <DateBlock title={cell.value}>{formatDate(cell.value)}</DateBlock>
);

interface Props {
  projectId: string;
  projectItems: ProjectsDetailTableData[];
}

export const ProjectDetailTable: React.FC<Props> = ({
  projectId,
  projectItems,
}) => {
  const navigate = useNavigate();
  const { triggerModal } = useModal();
  const theme = useTheme();

  const CellComp = React.useCallback(
    (cell: Cell<TableData>, data: TableData[]) => (
      <ProjectItemMenuButton row={cell.row} data={data} />
    ),
    [],
  );

  const sortType: SortByFn<ProjectsDetailTableData> = React.useCallback(
    (
      rowA: Row<TableData>,
      rowB: Row<TableData>,
      _columnId: string,
      desc?: boolean,
    ) => {
      let a = TYPE_SORT_PRIORITIES[rowA.original.type];
      let b = TYPE_SORT_PRIORITIES[rowB.original.type];
      if (desc) {
        return b > a ? 1 : -1;
      }
      if (a > b) {
        return -1;
      }
      if (a < b) {
        return 1;
      }
      return 0;
    },
    [],
  );

  const columns: Column<TableData>[] = [
    { Header: 'UUID', accessor: 'uuid', minWidth: 0, maxWidth: 0 },
    {
      Header: '',
      id: 'icon',
      minWidth: 24,
      maxWidth: 0,
      Cell: (cell: Cell<TableData>) => CellIcon(cell, theme),
    },
    {
      Header: t`Name`,
      accessor: (row) =>
        row.type === ProjectItemType.NOTEBOOK
          ? splitFullName(row.name)[0]
          : row.name,
      minWidth: 100,
      maxWidth: 1,
    },
    {
      Header: t`Type`,
      accessor: 'type',
      minWidth: 100,
      maxWidth: 0,
      sortType,
    },
    {
      Header: t`Last Edited`,
      accessor: 'updatedAt',
      minWidth: 140,
      maxWidth: 0,
      Cell: Date,
    },
    {
      Header: t`Created`,
      accessor: 'createdAt',
      minWidth: 140,
      maxWidth: 0,
      Cell: Date,
    },
    {
      Header: '',
      id: 'menu',
      minWidth: 20,
      maxWidth: 0,
      Cell: (cell: Cell<TableData>) => CellComp(cell, projectItems),
    },
  ];

  const initialState: Partial<TableState<TableData>> = {
    hiddenColumns: ['uuid'],
  };

  const rowNavigation = React.useCallback(
    (data: TableData) => {
      if (data.isProcessing) return;

      switch (data.type) {
        case ProjectItemType.MODEL:
        case ProjectItemType.SUBMODEL:
        case ProjectItemType.EXPERIMENT:
          navigate(`/projects/${projectId}/models/${data.uuid}`);
          break;
        case ProjectItemType.BUS_TYPE:
          navigate(`/projects/${projectId}/bus_types/${data.uuid}`);
          break;
        case ProjectItemType.PROJECTFILE:
          if (data.name.split('.').pop() === 'py') {
            navigate(`/projects/${projectId}/python_script/${data.uuid}`);
            break;
          }

          triggerModal(
            <DownloadFileModal
              projectId={data.projectUuid}
              fileName={data.name}
              fileUuid={data.uuid}
            />,
            t({ id: 'downloadFileModal.title', message: 'Download file' }),
          );
          break;
        default:
          navigate(
            `/projects/${projectId}/notebook/${encodeURIComponent(data.name)}`,
          );
          break;
      }
    },
    [navigate, triggerModal, projectId],
  );

  return (
    <Table
      columns={columns}
      data={projectItems}
      className="tour-onboarding-3"
      initialState={initialState}
      testId="project-detail-table"
      onRowDoubleClick={rowNavigation}
      getRowTestId={(row: Row<TableData>) => row.original.uuid}
    />
  );
};
