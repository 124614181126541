import { t } from '@lingui/macro';
import { useGetSnapshotReadAllQuery } from 'app/apiGenerated/generatedApi';
import { DiagramVersion } from 'app/apiTransformers/convertGetSnapshotReadAll';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import { SubmodelInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { submodelsActions } from 'app/slices/submodelsSlice';
import React from 'react';
import SelectInput from 'ui/common/SelectInput';
import { Spinner } from 'ui/common/Spinner';
import {
  DetailInputRowsSection,
  DetailsLabel,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import { SingleLineSpinnerWrapper } from 'ui/objectBrowser/sections/ReferenceSubmodelTreeContent';
import VersionPickerOption, {
  VersionPickerOptionData,
} from 'ui/versionHistory/VersionPickerOption';

interface Props {
  parentPath: string[];
  submodelNode: SubmodelInstance;
  canEdit: boolean;
}

const workingVersionOption = {
  value: VersionTagValues.LATEST_VERSION,
  label: t({
    id: 'modelEditor.blockProperties.versionPickerMenu.workingVersionOption.label',
    message: 'Working version',
  }),
};

const VersionPicker: React.FC<Props> = ({
  parentPath,
  submodelNode,
  canEdit,
}) => {
  const dispatch = useAppDispatch();

  const { data: diagramVersions, isLoading } = useGetSnapshotReadAllQuery(
    {
      modelUuid: submodelNode.submodel_reference_uuid || '',
      kind: 'Submodel',
    },
    {
      skip: !submodelNode.submodel_reference_uuid,
    },
  );

  const idToVersionIdToSubmodelInfo = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelInfo,
  );

  const options: VersionPickerOptionData[] = React.useMemo(() => {
    const taggedDiagramVersions: DiagramVersion[] =
      (diagramVersions?.filter(
        (version) => (version as DiagramVersion).isTagged,
      ) as DiagramVersion[]) || [];

    // Don't show "latest tagged" as an option if there are no tagged versions available.
    if (taggedDiagramVersions.length === 0) {
      return [workingVersionOption];
    }

    const latestTaggedVersionOption = {
      value: VersionTagValues.LATEST_TAGGED_VERSION,
      label: t({
        id: 'modelEditor.blockProperties.versionPickerMenu.lastNamedVersionOption.label',
        message: 'Latest tagged version',
      }),
      currentAssociatedVersionName: taggedDiagramVersions[0].name,
      createdAt: taggedDiagramVersions[0].createdAt,
    };

    return [
      workingVersionOption,
      latestTaggedVersionOption,
      ...taggedDiagramVersions.map((version) => ({
        value: version.uuid,
        label: version.name,
        authorProfileImageUrl: version.authorProfileImageUrl,
        authorDisplayName: version.authorDisplayName,
        createdAt: version.createdAt,
      })),
    ];
  }, [diagramVersions]);

  const selectVersion = React.useCallback(
    (versionId: string) => {
      dispatch(
        modelActions.updateSubmodelVersion({
          parentPath,
          submodelInstanceId: submodelNode.uuid,
          submodelReferenceSnapshotId: versionId,
        }),
      );

      dispatch(
        modelActions.updateReferencedSubmodelInstances({
          idToVersionIdToSubmodelInfo,
        }),
      );

      if (submodelNode.submodel_reference_uuid) {
        dispatch(
          submodelsActions.requestLoadSubmodelInfos([
            {
              submodel_uuid: submodelNode.submodel_reference_uuid,
              version: versionId,
            },
          ]),
        );
      }
    },
    [dispatch, parentPath, submodelNode, idToVersionIdToSubmodelInfo],
  );

  const selectedVersion =
    submodelNode.submodel_reference_version || VersionTagValues.LATEST_VERSION;

  return (
    <DetailInputRowsSection>
      <DetailsSection vertical>
        <DetailsLabel data-test-id="version-picker-label">
          {t({
            id: 'modelEditor.blockProperties.versionPicker.label',
            message: 'Version',
          })}
        </DetailsLabel>
        {isLoading ? (
          <SingleLineSpinnerWrapper>
            <Spinner />
          </SingleLineSpinnerWrapper>
        ) : (
          <SelectInput
            testId="version-picker"
            components={{ Option: VersionPickerOption }}
            onSelectValue={selectVersion}
            currentValue={selectedVersion}
            options={options}
            isDisabled={!canEdit}
          />
        )}
      </DetailsSection>
    </DetailInputRowsSection>
  );
};

export default VersionPicker;
