import { NodeInstance } from 'app/generated_types/SimulationModel';
import { useAppSelector } from 'app/hooks';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { isBlockNavState } from 'ui/requirements/blockNav';
import ModelBlockItem from './ModelBlockItem';

interface Props {
  projectId: string;
  searchString: string;
}

export const ModelTreeContent: React.FC<Props> = ({
  projectId,
  searchString,
}: Props) => {
  const { showError } = useNotifications();
  const { state } = useLocation();

  const modelNodes = useAppSelector(
    (state) => state.model.present.rootModel.nodes,
  );
  const modelUuid = useAppSelector(
    (state) => state.model.present.rootModel.uuid,
  );
  const subdiagrams = useAppSelector(
    (state) => state.model.present.submodels.diagrams,
  );

  // If block is not part of document, show error.
  // Unexpected error: should not happen if block cache up to date.
  useEffect(() => {
    if (isBlockNavState(state) && !!state.block_instance_uuid) {
      const block = modelNodes.find(
        (node) => node.uuid === state.block_instance_uuid,
      );
      if (block) {
        return;
      }

      for (const uuid in subdiagrams) {
        if (Object.prototype.hasOwnProperty.call(subdiagrams, uuid)) {
          const subdiagram = subdiagrams[uuid];
          const block = subdiagram?.nodes.find(
            (node) => node.uuid === state.block_instance_uuid,
          );
          if (block) return;
        }
      }

      showError(
        `Block ${state.block_instance_uuid} not found in document ${modelUuid}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {modelNodes.map((modelNode: NodeInstance, index: number) => (
        <ModelBlockItem
          testIdPath={`${index}`}
          key={index}
          searchString={searchString}
          node={modelNode}
          parentPath={[]}
          projectId={projectId}
        />
      ))}
    </>
  );
};
