import React from 'react';
import { MenuConfig } from 'ui/common/Menu/menuItemTypes';
import Tooltip from 'ui/common/Tooltip/Tooltip';
import {
  TooltipPlacement,
  TooltipTriggerMode,
} from 'ui/common/Tooltip/tooltipTypes';
import MenuContent from './MenuContent';

const Menu: React.FC<MenuConfig> = ({
  items,
  className,
  onSubmitValue,
  onClose,
  children = null,
  placement = TooltipPlacement.BOTTOM_RIGHT,
  startOpen = false,
  noTooltipMargin = false,
  onActive,
  disabled,
}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(startOpen);

  const onHideMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
    onClose?.();
  };

  const onShowMenu = (isActive: boolean) => {
    if (isMenuOpen !== isActive) {
      setIsMenuOpen(isActive);
    }
    onActive?.(isActive);
  };

  const MenuContentFunction = React.useCallback(
    ({ closeTooltip }) => (
      <MenuContent
        items={items}
        onSubmitValue={onSubmitValue}
        isMenuOpen={isMenuOpen}
        triggerCloseMenu={closeTooltip}
      />
    ),
    [items, onSubmitValue, isMenuOpen],
  );

  return (
    <Tooltip
      className={className}
      onActive={onShowMenu}
      onClickOutside={(hide) => hide()}
      content={MenuContentFunction}
      showPointer={false}
      noMargin={noTooltipMargin}
      triggerMode={
        disabled ? TooltipTriggerMode.NONE : TooltipTriggerMode.CLICK
      }
      startOpen={startOpen}
      onClose={onHideMenu}
      placement={placement}>
      {children}
    </Tooltip>
  );
};

export default Menu;
