// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const CrossProduct: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "CrossProduct",
      "namespace": "core",
      "description": "This block outputs the cross product of the input vectors.",
      "keywords": [
        "multiply",
        "product"
      ],
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#cross-product",
      "supports_experiments": true
    },
    "modes": {
      "feedthrough": true,
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "a"
          },
          {
            "name": "b"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "s"
          }
        ]
      }
    }
  }
);
