/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Data: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 6H4V12H12V6ZM4 5C3.44772 5 3 5.44772 3 6V12C3 12.5523 3.44772 13 4 13H12C12.5523 13 13 12.5523 13 12V6C13 5.44772 12.5523 5 12 5H4Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4 5H12L12.5 7H10V8H12.5V9H10V10H12.5V11H10V12.5H9V11H7V12.5H6V11H3.5V10H6V9H3.5V8H6V7H3.5L4 5ZM7 7V8H9V7H7ZM9 9H7V10H9V9Z"
      fill={props.fill}
    />
  </svg>
);

Data.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Data;
