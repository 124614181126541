// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import type { BlockClassName } from 'app/generated_types/SimulationModel';
import { computationBlockDefaults } from './computationBlockDefaults';

import { Conditional } from 'app/generated_blocks/core/Conditional';
import { Abs } from 'app/generated_blocks/core/Abs';
import { Adder } from 'app/generated_blocks/core/Adder';
import { BatteryCell } from 'app/generated_blocks/core/BatteryCell';
import { BusCreator } from 'app/generated_blocks/core/BusCreator';
import { BusSelector } from 'app/generated_blocks/core/BusSelector';
import { CFunction } from 'app/generated_blocks/core/CFunction';
import { Chirp } from 'app/generated_blocks/core/Chirp';
import { Clock } from 'app/generated_blocks/core/Clock';
import { CodeBlockDev } from 'app/generated_blocks/core/CodeBlockDev';
import { Comparator } from 'app/generated_blocks/core/Comparator';
import { Constant } from 'app/generated_blocks/core/Constant';
import { CoordinateRotation } from 'app/generated_blocks/core/CoordinateRotation';
import { CoordinateRotationConversion } from 'app/generated_blocks/core/CoordinateRotationConversion';
import { CosineWave } from 'app/generated_blocks/core/CosineWave';
import { CppFunction } from 'app/generated_blocks/core/CppFunction';
import { CrossProduct } from 'app/generated_blocks/core/CrossProduct';
import { DataSource } from 'app/generated_blocks/core/DataSource';
import { DeadZone } from 'app/generated_blocks/core/DeadZone';
import { Delay } from 'app/generated_blocks/core/Delay';
import { Demux } from 'app/generated_blocks/core/Demux';
import { Derivative } from 'app/generated_blocks/core/Derivative';
import { DerivativeDiscrete } from 'app/generated_blocks/core/DerivativeDiscrete';
import { DiscreteInitializer } from 'app/generated_blocks/core/DiscreteInitializer';
import { DotProduct } from 'app/generated_blocks/core/DotProduct';
import { DriveCycle } from 'app/generated_blocks/core/DriveCycle';
import { EdgeDetection } from 'app/generated_blocks/core/EdgeDetection';
import { ExperimentIterator } from 'app/generated_blocks/core/ExperimentIterator';
import { ExperimentModel } from 'app/generated_blocks/core/ExperimentModel';
import { Exponent } from 'app/generated_blocks/core/Exponent';
import { FilterDiscrete } from 'app/generated_blocks/core/FilterDiscrete';
import { Gain } from 'app/generated_blocks/core/Gain';
import { Generic } from 'app/generated_blocks/core/Generic';
import { Group } from 'app/generated_blocks/core/Group';
import { IfThenElse } from 'app/generated_blocks/core/IfThenElse';
import { ImageSegmentation } from 'app/generated_blocks/core/ImageSegmentation';
import { ImageSource } from 'app/generated_blocks/core/ImageSource';
import { Inport } from 'app/generated_blocks/core/Inport';
import { Integrator } from 'app/generated_blocks/core/Integrator';
import { IntegratorDiscrete } from 'app/generated_blocks/core/IntegratorDiscrete';
import { Iterator } from 'app/generated_blocks/core/Iterator';
import { LinearizedSystem } from 'app/generated_blocks/core/LinearizedSystem';
import { Log } from 'app/generated_blocks/core/Log';
import { LogicalOperator } from 'app/generated_blocks/core/LogicalOperator';
import { LookupTable1d } from 'app/generated_blocks/core/LookupTable1d';
import { LookupTable2d } from 'app/generated_blocks/core/LookupTable2d';
import { LoopBreak } from 'app/generated_blocks/core/LoopBreak';
import { LoopCounter } from 'app/generated_blocks/core/LoopCounter';
import { LoopMemory } from 'app/generated_blocks/core/LoopMemory';
import { MatrixConcatenation } from 'app/generated_blocks/core/MatrixConcatenation';
import { MatrixInversion } from 'app/generated_blocks/core/MatrixInversion';
import { MatrixMultiplication } from 'app/generated_blocks/core/MatrixMultiplication';
import { MatrixOperator } from 'app/generated_blocks/core/MatrixOperator';
import { MatrixTransposition } from 'app/generated_blocks/core/MatrixTransposition';
import { MinMax } from 'app/generated_blocks/core/MinMax';
import { ModelicaFMU } from 'app/generated_blocks/core/ModelicaFMU';
import { Mux } from 'app/generated_blocks/core/Mux';
import { ObjectDetection } from 'app/generated_blocks/core/ObjectDetection';
import { Offset } from 'app/generated_blocks/core/Offset';
import { Outport } from 'app/generated_blocks/core/Outport';
import { PID } from 'app/generated_blocks/core/PID';
import { PID_Discrete } from 'app/generated_blocks/core/PID_Discrete';
import { Power } from 'app/generated_blocks/core/Power';
import { Predictor } from 'app/generated_blocks/core/Predictor';
import { Product } from 'app/generated_blocks/core/Product';
import { ProductOfElements } from 'app/generated_blocks/core/ProductOfElements';
import { Pulse } from 'app/generated_blocks/core/Pulse';
import { PythonScript } from 'app/generated_blocks/core/PythonScript';
import { Quantizer } from 'app/generated_blocks/core/Quantizer';
import { Ramp } from 'app/generated_blocks/core/Ramp';
import { RandomNormal } from 'app/generated_blocks/core/RandomNormal';
import { RateLimiter } from 'app/generated_blocks/core/RateLimiter';
import { Reciprocal } from 'app/generated_blocks/core/Reciprocal';
import { ReferenceSubmodel } from 'app/generated_blocks/core/ReferenceSubmodel';
import { Relay } from 'app/generated_blocks/core/Relay';
import { Replicator } from 'app/generated_blocks/core/Replicator';
import { RigidBody } from 'app/generated_blocks/core/RigidBody';
import { Saturate } from 'app/generated_blocks/core/Saturate';
import { Sawtooth } from 'app/generated_blocks/core/Sawtooth';
import { ScalarBroadcast } from 'app/generated_blocks/core/ScalarBroadcast';
import { SignalDatatypeConversion } from 'app/generated_blocks/core/SignalDatatypeConversion';
import { SineWave } from 'app/generated_blocks/core/SineWave';
import { Slice } from 'app/generated_blocks/core/Slice';
import { SquareRoot } from 'app/generated_blocks/core/SquareRoot';
import { Stack } from 'app/generated_blocks/core/Stack';
import { StateMachine } from 'app/generated_blocks/core/StateMachine';
import { StateSpace } from 'app/generated_blocks/core/StateSpace';
import { Step } from 'app/generated_blocks/core/Step';
import { Stop } from 'app/generated_blocks/core/Stop';
import { Submodel } from 'app/generated_blocks/core/Submodel';
import { SumOfElements } from 'app/generated_blocks/core/SumOfElements';
import { TransferFunction } from 'app/generated_blocks/core/TransferFunction';
import { TransferFunctionDiscrete } from 'app/generated_blocks/core/TransferFunctionDiscrete';
import { Trigonometric } from 'app/generated_blocks/core/Trigonometric';
import { UnitDelay } from 'app/generated_blocks/core/UnitDelay';
import { VideoSink } from 'app/generated_blocks/core/VideoSink';
import { VideoSource } from 'app/generated_blocks/core/VideoSource';
import { ZeroOrderHold } from 'app/generated_blocks/core/ZeroOrderHold';

// Do not export this type, only use blockClassLookup().
type blockClassShortNameType =
 | 'Conditional'
 | 'Abs'
 | 'Adder'
 | 'BatteryCell'
 | 'BusCreator'
 | 'BusSelector'
 | 'CFunction'
 | 'Chirp'
 | 'Clock'
 | 'CodeBlockDev'
 | 'Comparator'
 | 'Constant'
 | 'CoordinateRotation'
 | 'CoordinateRotationConversion'
 | 'CosineWave'
 | 'CppFunction'
 | 'CrossProduct'
 | 'DataSource'
 | 'DeadZone'
 | 'Delay'
 | 'Demux'
 | 'Derivative'
 | 'DerivativeDiscrete'
 | 'DiscreteInitializer'
 | 'DotProduct'
 | 'DriveCycle'
 | 'EdgeDetection'
 | 'ExperimentIterator'
 | 'ExperimentModel'
 | 'Exponent'
 | 'FilterDiscrete'
 | 'Gain'
 | 'Generic'
 | 'Group'
 | 'IfThenElse'
 | 'ImageSegmentation'
 | 'ImageSource'
 | 'Inport'
 | 'Integrator'
 | 'IntegratorDiscrete'
 | 'Iterator'
 | 'LinearizedSystem'
 | 'Log'
 | 'LogicalOperator'
 | 'LookupTable1d'
 | 'LookupTable2d'
 | 'LoopBreak'
 | 'LoopCounter'
 | 'LoopMemory'
 | 'MatrixConcatenation'
 | 'MatrixInversion'
 | 'MatrixMultiplication'
 | 'MatrixOperator'
 | 'MatrixTransposition'
 | 'MinMax'
 | 'ModelicaFMU'
 | 'Mux'
 | 'ObjectDetection'
 | 'Offset'
 | 'Outport'
 | 'PID'
 | 'PID_Discrete'
 | 'Power'
 | 'Predictor'
 | 'Product'
 | 'ProductOfElements'
 | 'Pulse'
 | 'PythonScript'
 | 'Quantizer'
 | 'Ramp'
 | 'RandomNormal'
 | 'RateLimiter'
 | 'Reciprocal'
 | 'ReferenceSubmodel'
 | 'Relay'
 | 'Replicator'
 | 'RigidBody'
 | 'Saturate'
 | 'Sawtooth'
 | 'ScalarBroadcast'
 | 'SignalDatatypeConversion'
 | 'SineWave'
 | 'Slice'
 | 'SquareRoot'
 | 'Stack'
 | 'StateMachine'
 | 'StateSpace'
 | 'Step'
 | 'Stop'
 | 'Submodel'
 | 'SumOfElements'
 | 'TransferFunction'
 | 'TransferFunctionDiscrete'
 | 'Trigonometric'
 | 'UnitDelay'
 | 'VideoSink'
 | 'VideoSource'
 | 'ZeroOrderHold';

// Do not export this LUT, only use blockClassLookup().
const blockClassLUT: { [key in blockClassShortNameType]: ComputationBlockClass } = {
  Conditional,
  Abs,
  Adder,
  BatteryCell,
  BusCreator,
  BusSelector,
  CFunction,
  Chirp,
  Clock,
  CodeBlockDev,
  Comparator,
  Constant,
  CoordinateRotation,
  CoordinateRotationConversion,
  CosineWave,
  CppFunction,
  CrossProduct,
  DataSource,
  DeadZone,
  Delay,
  Demux,
  Derivative,
  DerivativeDiscrete,
  DiscreteInitializer,
  DotProduct,
  DriveCycle,
  EdgeDetection,
  ExperimentIterator,
  ExperimentModel,
  Exponent,
  FilterDiscrete,
  Gain,
  Generic,
  Group,
  IfThenElse,
  ImageSegmentation,
  ImageSource,
  Inport,
  Integrator,
  IntegratorDiscrete,
  Iterator,
  LinearizedSystem,
  Log,
  LogicalOperator,
  LookupTable1d,
  LookupTable2d,
  LoopBreak,
  LoopCounter,
  LoopMemory,
  MatrixConcatenation,
  MatrixInversion,
  MatrixMultiplication,
  MatrixOperator,
  MatrixTransposition,
  MinMax,
  ModelicaFMU,
  Mux,
  ObjectDetection,
  Offset,
  Outport,
  PID,
  PID_Discrete,
  Power,
  Predictor,
  Product,
  ProductOfElements,
  Pulse,
  PythonScript,
  Quantizer,
  Ramp,
  RandomNormal,
  RateLimiter,
  Reciprocal,
  ReferenceSubmodel,
  Relay,
  Replicator,
  RigidBody,
  Saturate,
  Sawtooth,
  ScalarBroadcast,
  SignalDatatypeConversion,
  SineWave,
  Slice,
  SquareRoot,
  Stack,
  StateMachine,
  StateSpace,
  Step,
  Stop,
  Submodel,
  SumOfElements,
  TransferFunction,
  TransferFunctionDiscrete,
  Trigonometric,
  UnitDelay,
  VideoSink,
  VideoSource,
  ZeroOrderHold
};

export const blockClassLookup = (name: BlockClassName) => {
  const klass = blockClassLUT[name.substring(5) as blockClassShortNameType];
  if (klass) return klass;

  // Block does not exist, we need to report the error without crashing the app.
  console.error('Unknown block class:', name);
  return computationBlockDefaults;
}

export const blockClassExists = (name: string): boolean => {
  const klass = blockClassLUT[name.substring(5) as blockClassShortNameType];
  return !!klass;
}
