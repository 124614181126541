/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Experiment: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7 9.41666C6.30965 9.41666 5.75 9.9763 5.75 10.6667C5.75 11.357 6.30965 11.9167 7 11.9167C7.69036 11.9167 8.25 11.357 8.25 10.6667C8.25 9.9763 7.69036 9.41666 7 9.41666ZM6.25 10.6667C6.25 10.2524 6.58579 9.91666 7 9.91666C7.41421 9.91666 7.75 10.2524 7.75 10.6667C7.75 11.0809 7.41421 11.4167 7 11.4167C6.58579 11.4167 6.25 11.0809 6.25 10.6667Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.41667 7.83332C7.41667 7.41911 7.75245 7.08332 8.16667 7.08332C8.58088 7.08332 8.91667 7.41911 8.91667 7.83332C8.91667 8.24754 8.58088 8.58332 8.16667 8.58332C7.75245 8.58332 7.41667 8.24754 7.41667 7.83332ZM8.16667 7.58332C8.0286 7.58332 7.91667 7.69525 7.91667 7.83332C7.91667 7.97139 8.0286 8.08332 8.16667 8.08332C8.30474 8.08332 8.41667 7.97139 8.41667 7.83332C8.41667 7.69525 8.30474 7.58332 8.16667 7.58332Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.66667 10.75C9.16041 10.75 8.75 11.1604 8.75 11.6667C8.75 12.1729 9.16041 12.5833 9.66667 12.5833C10.1729 12.5833 10.5833 12.1729 10.5833 11.6667C10.5833 11.1604 10.1729 10.75 9.66667 10.75ZM9.25 11.6667C9.25 11.4365 9.43655 11.25 9.66667 11.25C9.89679 11.25 10.0833 11.4365 10.0833 11.6667C10.0833 11.8968 9.89679 12.0833 9.66667 12.0833C9.43655 12.0833 9.25 11.8968 9.25 11.6667Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.66667 2.16666C5.39053 2.16666 5.16667 2.39051 5.16667 2.66666C5.16667 2.9428 5.39053 3.16666 5.66667 3.16666H6.16667V6.43059C6.16667 6.50821 6.1486 6.58477 6.11388 6.6542L3.60973 11.6625C3.11105 12.6599 3.8363 13.8333 4.95137 13.8333H11.0486C12.1637 13.8333 12.8889 12.6599 12.3903 11.6625L9.88612 6.65419C9.85141 6.58477 9.83333 6.50821 9.83333 6.43059V3.16666H10.3333C10.6095 3.16666 10.8333 2.9428 10.8333 2.66666C10.8333 2.39051 10.6095 2.16666 10.3333 2.16666H5.66667ZM7.16667 6.43059V3.16666H8.83333V6.43059C8.83333 6.66346 8.88755 6.89313 8.99169 7.10141L11.4958 12.1097C11.6621 12.4422 11.4203 12.8333 11.0486 12.8333H4.95137C4.57968 12.8333 4.33793 12.4422 4.50415 12.1097L7.00831 7.10141C7.11245 6.89313 7.16667 6.66346 7.16667 6.43059Z"
      fill={props.fill}
    />
  </svg>
);

Experiment.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Experiment;
