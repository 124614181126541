import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React, { RefObject, useCallback } from 'react';

import { useAppDispatch, useComponentSize } from 'app/hooks';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { Close } from 'ui/common/Icons/Small';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import { useTimer } from 'ui/common/timer/useTimer';
import inputStringCallback from 'util/inputStringCallback';
import ValidationError from './ValidationError';
import { ValidationRule, checkIsValid } from './inputValidation';

const TYPING_TO_VALIDATION_DELAY_IN_MS = 700;

const INPUT_LINE_HEIGHT_IN_PX = 24;

export interface InputSubmitMetadata {
  inputHeight?: string;
}

export interface InputProps {
  className?: string;
  LeftIcon?: React.FC<any>;
  hasBorder?: boolean;
  align?: TextInputAlign;
  disabled?: boolean;
  doubleClickFocus?: boolean;
  testId?: string;
  clearable?: boolean;
  validationRules?: ValidationRule[];
  autoFocus?: boolean;
  placeholder?: string;
  placeholderTextColorDark?: boolean;
  pattern?: string;
  value?: string | number;
  onChangeText?: (newValue: string, isValid: boolean) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onMouseDown?: (e: React.MouseEvent) => void;
  onSubmitValue?: (
    newValue: string,
    submitMetadata: InputSubmitMetadata,
  ) => void;
  onCancel?: () => void;
  allowMultiline?: boolean;
  multilineHeight?: string;
  isMonospaced?: boolean;
  isEmphasized?: boolean;
  externalInputRef?: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | null
  >;
}

const TextInputWrapper = styled.div<{ allowMultiline?: boolean }>`
  ${({ allowMultiline, theme: { typography } }) => `
  display: flex;
  position: relative;
  ${allowMultiline ? '' : `height: ${INPUT_LINE_HEIGHT_IN_PX}px;`}
  font-size: ${typography.font.base.size};
  font-weight: ${typography.font.base.weight};
  min-width: 0;
  & > input {
    line-height: ${typography.font.base.lineHeight};
  }
`}
`;

const FocusGrabber = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
`;

const LeftIconWrapper = styled.div`
  margin-left: ${({ theme: { spacing } }) => spacing.xsmall};
  margin-right: ${({ theme: { spacing } }) => spacing.small};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  left: 0;
  svg path {
    fill: ${({ theme: { colors } }) => colors.grey[30]};
  }
  width: ${({ theme: { spacing } }) => spacing.xlarge};
`;

const StyledInput = styled.input<{
  hasLeftIcon: boolean;
  hasBorder?: boolean;
  align: TextInputAlign;
  disabled?: boolean;
  placeholderTextColorDark?: boolean;
  isMonospaced?: boolean;
  isEmphasized?: boolean;
}>`
  ${({
    align,
    hasBorder,
    hasLeftIcon,
    theme: { colors, spacing },
    disabled,
    placeholderTextColorDark,
    isMonospaced,
    isEmphasized,
  }) => `
    flex: 1;
    max-width: 100%;
    outline: none;
    background: transparent;
    height: ${spacing.xlarge};
    ${align === TextInputAlign.Right ? 'text-align: right;' : ''}
    border: ${hasBorder ? `1px solid ${colors.grey[10]}` : 'none'};
    border-radius: 2px;
    padding-left: ${hasLeftIcon ? spacing.xlarge : spacing.normal};
    padding-right: ${spacing.xlarge};
    color: ${
      disabled
        ? colors.text.tertiary
        : isEmphasized
        ? colors.text.primary
        : colors.text.secondary
    };

    ${isMonospaced ? `font-family: 'Inconsolata', sans-serif;` : ''}

    &.taking-focus {
      caret-color: transparent;
    }

    &::placeholder {
      color: ${
        disabled || !placeholderTextColorDark
          ? colors.text.secondary
          : colors.text.primary
      };
    }

    &:focus {
      background: #ffffff;
      box-shadow: 0px 0px 0px 1px ${colors.brand.primary.lighter};
    }

    ${
      placeholderTextColorDark
        ? `
    &:not(:focus) {
      ::placeholder {
        opacity: 1;
      }
    }`
        : ''
    }
  `}
`;

const StyledTextArea = styled.textarea<{
  hasLeftIcon: boolean;
  hasBorder?: boolean;
  align: TextInputAlign;
  disabled?: boolean;
  placeholderTextColorDark?: boolean;
  multilineHeight: string;
  isMonospaced?: boolean;
  isEmphasized?: boolean;
}>`
  ${({
    align,
    hasBorder,
    hasLeftIcon,
    theme: { colors, spacing },
    disabled,
    placeholderTextColorDark,
    multilineHeight,
    isMonospaced,
    isEmphasized,
  }) => `
    flex: 1;
    width: 100%;
    min-height: ${INPUT_LINE_HEIGHT_IN_PX}px;
    height: ${multilineHeight};
    outline: none;
    background: transparent;
    ${align === TextInputAlign.Right ? 'text-align: right;' : ''}
    border: ${hasBorder ? `1px solid ${colors.grey[10]}` : 'none'};
    border-radius: 2px;
    padding-top: ${spacing.small};
    padding-left: ${hasLeftIcon ? spacing.xlarge : spacing.normal};
    padding-right: ${spacing.xlarge};
    color: ${
      disabled
        ? colors.text.tertiary
        : isEmphasized
        ? colors.text.primary
        : colors.text.secondary
    };

    ${isMonospaced ? `font-family: 'Inconsolata', sans-serif;` : ''}

    &.taking-focus {
      caret-color: transparent;
    }

    ::placeholder {
      color: ${
        disabled || !placeholderTextColorDark
          ? colors.text.tertiary
          : colors.text.primary
      };
    }

    &:focus {
      background: #ffffff26;
      box-shadow: 0px 0px 0px 1px ${colors.brand.primary.lighter};
    }

    ${
      placeholderTextColorDark
        ? `
    &:not(:focus) {
      ::placeholder {
        opacity: 1;
      }
    }`
        : ''
    }
  `}
`;

const ClearContentWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing.small};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 0;
`;

const getValueString = (value?: string | number | undefined): string =>
  `${value !== undefined ? value : ''}`;

const blurInput = (
  inputElRef: RefObject<HTMLInputElement | HTMLTextAreaElement>,
) => {
  if (inputElRef.current) inputElRef.current.blur();
};

const focusInput = (
  inputElRef: RefObject<HTMLInputElement | HTMLTextAreaElement>,
) => {
  if (inputElRef.current) inputElRef.current.focus();
};

const reFocusInput = (
  inputElRef: RefObject<HTMLInputElement | HTMLTextAreaElement>,
) => {
  blurInput(inputElRef);
  focusInput(inputElRef);
};

const Input: React.FC<InputProps> = ({
  className,
  LeftIcon,
  hasBorder,
  align,
  disabled,
  doubleClickFocus,
  testId,
  clearable = false,
  validationRules,
  autoFocus,
  placeholder,
  placeholderTextColorDark,
  pattern,
  value,
  onChangeText,
  onKeyDown,
  onMouseDown,
  onSubmitValue,
  onCancel,
  allowMultiline,
  multilineHeight,
  isMonospaced,
  isEmphasized,
  externalInputRef,
}: InputProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const lastValue = getValueString(value);

  const [stateValue, setStateValue] = React.useState<string>(lastValue);
  const [currentNumberOfLines, setCurrentNumberOfLines] =
    React.useState<number>(1);
  const [focused, setFocused] = React.useState<boolean>(false);
  const [isTakingFocus, setIsTakingFocus] = React.useState<boolean>(false);
  const needsToReleaseAppFocusRef = React.useRef(false);
  const [isMouseDown, setIsMouseDown] = React.useState<boolean>(false);
  const inputElRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const isInitialRenderComplete = React.useRef<boolean>(false);

  if (externalInputRef) {
    externalInputRef.current = inputElRef.current;
  }

  // Detect changes to height and emit submit events if the height changes.
  const { height } = useComponentSize(inputElRef);
  const [lastHeight, setLastHeight] = React.useState<number | null>(null);
  React.useEffect(() => {
    // Don't send a height change event on initial render
    // because this would just store our default height rather than any user intent.
    // Only send a height change event if the user changes the value
    // or changes the height.
    if (!isInitialRenderComplete.current) {
      isInitialRenderComplete.current = true;
      return;
    }

    if (
      allowMultiline &&
      onSubmitValue &&
      lastHeight !== null &&
      lastHeight !== height
    ) {
      if (!checkIsValid(stateValue, validationRules)) {
        const inputMetadata: InputSubmitMetadata = {
          inputHeight: `${height}px`,
        };
        onSubmitValue(stateValue.trim(), inputMetadata);
      }
    }
    if (height !== lastHeight) {
      setLastHeight(height);
    }
  }, [
    height,
    onSubmitValue,
    validationRules,
    allowMultiline,
    stateValue,
    lastHeight,
  ]);

  // Don't show validation errors if the user is currently typing into
  // the field because it feels overly aggressive, i.e.:
  // "Stop yelling at me that I'm doing it wrong, I'm not done yet!!"
  const [isInProgress, setIsInProgress] = React.useState<boolean>(true);
  const { stopTimer: stopInProgressTimer, startTimer: startInProgressTimer } =
    useTimer();

  // Validate the input if the submitted value or the validation rules change.
  const [validationError, setValidationError] = React.useState<string>('');
  React.useEffect(() => {
    if (isInProgress) {
      if (validationError) setValidationError('');
    } else {
      const newValidationError = checkIsValid(stateValue, validationRules);
      if (newValidationError !== validationError) {
        setValidationError(newValidationError);
      }
    }
  }, [isInProgress, stateValue, validationRules, validationError]);

  const updateValueFromUserAction = (newValue: string) => {
    if (newValue !== stateValue) {
      setStateValue(newValue);
      stopInProgressTimer();
      startInProgressTimer(
        () => setIsInProgress(false),
        TYPING_TO_VALIDATION_DELAY_IN_MS,
      );
      setIsInProgress(true);
      const newValidationError = checkIsValid(newValue, validationRules);
      if (onChangeText) onChangeText(newValue, !newValidationError);
    }
  };

  const internalOnChangeText = (newValue: string) => {
    updateValueFromUserAction(newValue);
  };

  const setCursorStartPosition = useCallback(() => {
    if (inputElRef.current) {
      inputElRef.current.selectionStart = 0;
      inputElRef.current.selectionEnd = stateValue.length;
    }
  }, [inputElRef, stateValue.length]);

  // Update value from server or validation fixup.
  React.useEffect(() => {
    setStateValue(lastValue);
  }, [lastValue]);

  // For an input that needs autofocus, we won't have the textInputRef available when
  // the focus event occurs, so this will make sure the proper text selection
  // occurs when we do get that textInputRef.
  React.useEffect(() => {
    if (isTakingFocus && inputElRef && !isMouseDown) {
      setCursorStartPosition();
      setIsTakingFocus(false);
    }
  }, [inputElRef, setCursorStartPosition, isTakingFocus, isMouseDown]);

  const internalOnFocus = () => {
    const trimmedValue = stateValue.trim();
    if (stateValue !== trimmedValue) {
      setStateValue(trimmedValue);
    }
    setFocused(true);
    if (inputElRef.current) {
      setCursorStartPosition();
    } else {
      setIsTakingFocus(true);
    }
    if (isMouseDown) {
      setIsTakingFocus(true);
    }

    dispatch(uiFlagsActions.setUIFlag({ textInputFocused: true }));
    needsToReleaseAppFocusRef.current = true;
  };

  const internalOnBlur = () => {
    if (lastValue !== stateValue) {
      if (!checkIsValid(stateValue, validationRules)) {
        if (onSubmitValue) {
          const inputMetadata: InputSubmitMetadata = {};
          if (allowMultiline) {
            const inputHeightValue = inputElRef.current?.clientHeight;
            if (inputHeightValue !== undefined && !isNaN(inputHeightValue)) {
              inputMetadata.inputHeight = `${inputHeightValue}px`;
            }
          }
          onSubmitValue(stateValue.trim(), inputMetadata);
        }
      }
    } else if (onCancel) {
      onCancel();
    }
    setIsTakingFocus(false);
    setFocused(false);
    setIsInProgress(false);
    dispatch(uiFlagsActions.setUIFlag({ textInputFocused: false }));
    needsToReleaseAppFocusRef.current = false;
  };

  const internalOnKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (onKeyDown) onKeyDown(e);
    if (e.key === 'Enter' && !e.shiftKey) {
      // Make sure the enter key either submits the value
      // or closes the enclosing modal dialog (if applicable)
      // but not both with the same enter key press.
      // I.e. the first enter should submit the value and the
      // second enter can then submit the modal dialog.
      if (lastValue !== stateValue) {
        e.stopPropagation();
        e.preventDefault();
      }

      // Prevent the enter key from replacing selected text for
      // multiline inputs.
      if (allowMultiline) {
        e.preventDefault();
      }
      reFocusInput(inputElRef);
    }
    if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();
      updateValueFromUserAction(lastValue);
      if (onCancel) onCancel();

      // Blur the input on the next event loop so that the stateValue
      // is correctly updated before the blur occurs
      // to ensure that the validation behavior
      // is done correctly.
      setTimeout(() => blurInput(inputElRef));
    }
  };

  const internalOnMouseDown = (e: React.MouseEvent) => {
    if (onMouseDown) onMouseDown(e);
    setIsMouseDown(true);
  };

  const internalOnMouseUp = () => {
    setIsMouseDown(false);

    // If the mouse was down when focus was received,
    // the mouse position will determine the text cursor position
    // until the mouse is released (you can also drag the mouse while it is
    // down to move the text cursor position).
    // To make the cursor positioning feel smooth when the user
    // takes focus using the mouse, we will hide the text cursor
    // until the mouse is released, and, at that point,
    // we position the cursor where it should be and
    // make the text cursor visible again.  We want to make sure the
    // text cursor doesn't "flash" in the wrong position in the textbox.
    if (isTakingFocus) {
      setCursorStartPosition();
      setIsTakingFocus(false);
    }
  };

  const clearContent = () => {
    updateValueFromUserAction('');

    // After clearing, the user is likely wanting to type a new value,
    // so focus the input.
    focusInput(inputElRef);
  };

  React.useEffect(() => {
    if (!allowMultiline || multilineHeight) {
      return;
    }

    const numberOfLines = stateValue.match(/\n/g)?.length || 1;
    if (numberOfLines > currentNumberOfLines) {
      setCurrentNumberOfLines(numberOfLines);
    }
  }, [allowMultiline, currentNumberOfLines, stateValue, multilineHeight]);

  // If the input is destroyed while in focus (or trying to take focus)
  // make sure the focus is still released.
  React.useEffect(
    () => () => {
      if (needsToReleaseAppFocusRef.current) {
        dispatch(uiFlagsActions.setUIFlag({ textInputFocused: false }));
      }
    },
    [dispatch],
  );

  return (
    <TextInputWrapper className={className} allowMultiline={allowMultiline}>
      {doubleClickFocus && !disabled && !focused && (
        <FocusGrabber
          data-test-id={`${testId}-focus-grabber`}
          onDoubleClick={() => focusInput(inputElRef)}
        />
      )}
      <LeftIconWrapper> {LeftIcon && <LeftIcon />}</LeftIconWrapper>
      {allowMultiline ? (
        <StyledTextArea
          className={isTakingFocus ? 'taking-focus' : ''}
          ref={inputElRef as RefObject<HTMLTextAreaElement>}
          onFocus={internalOnFocus}
          onBlur={internalOnBlur}
          onChange={inputStringCallback(internalOnChangeText)}
          onKeyDown={internalOnKeyDown}
          onMouseDown={internalOnMouseDown}
          onMouseUp={internalOnMouseUp}
          value={stateValue}
          hasLeftIcon={Boolean(LeftIcon)}
          hasBorder={hasBorder}
          align={align || TextInputAlign.Left}
          data-test-id={testId}
          placeholder={placeholder}
          placeholderTextColorDark={placeholderTextColorDark}
          disabled={disabled}
          autoFocus={autoFocus}
          multilineHeight={
            multilineHeight ||
            `${currentNumberOfLines * INPUT_LINE_HEIGHT_IN_PX}px`
          }
          isMonospaced={isMonospaced}
          isEmphasized={isEmphasized}
        />
      ) : (
        <StyledInput
          className={isTakingFocus ? 'taking-focus' : ''}
          ref={inputElRef as RefObject<HTMLInputElement>}
          type="text"
          onFocus={internalOnFocus}
          onBlur={internalOnBlur}
          onChange={inputStringCallback(internalOnChangeText)}
          onKeyDown={internalOnKeyDown}
          onMouseDown={internalOnMouseDown}
          onMouseUp={internalOnMouseUp}
          value={stateValue}
          hasLeftIcon={Boolean(LeftIcon)}
          hasBorder={hasBorder}
          align={align || TextInputAlign.Left}
          data-test-id={testId}
          placeholder={placeholder}
          placeholderTextColorDark={placeholderTextColorDark}
          disabled={disabled}
          pattern={pattern}
          autoFocus={autoFocus}
          isMonospaced={isMonospaced}
          isEmphasized={isEmphasized}
        />
      )}

      {clearable && stateValue.length > 0 && (
        <ClearContentWrapper onClick={clearContent}>
          <Close fill={theme.colors.text.tertiary} />
        </ClearContentWrapper>
      )}
      {validationError && !isInProgress && (
        <ValidationError
          validationError={validationError}
          isInFocus={focused}
        />
      )}
    </TextInputWrapper>
  );
};

export default Input;
