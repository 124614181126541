import { useTheme } from '@emotion/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import {
  PARENT_PATH_KEY,
  SELECTION_PARENT_PATH_KEY,
} from 'app/utils/URLParamsUtils';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Model, Submodel } from 'ui/common/Icons/Small';
import {
  ItemSection,
  ModelBlockItemText,
  ModelTreeIcon,
} from 'ui/objectBrowser/ModelTreeParts';

export const ModelRootItem = () => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const modelName = useAppSelector((state) => state.model.present.name);
  const isSelectionEmpty = useAppSelector(
    (state) =>
      state.model.present.selectedBlockIds.length === 0 &&
      state.model.present.selectedLinkIds.length === 0 &&
      state.model.present.selectedAnnotationIds.length === 0,
  );
  const selectionParentPath = useAppSelector(
    (state) => state.model.present?.selectionParentPath,
  );
  const isDiagramAtRootLevel =
    selectionParentPath && selectionParentPath.length === 0;
  const isRootSelected = isDiagramAtRootLevel && isSelectionEmpty;

  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  const selectRootNode = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(
      modelActions.setSelections({
        selectionParentPath: [],
      }),
    );
  };

  const openRootNodeDiagram = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    // Somewhat broken. Opens the diagram but does not move the the camera since there's no node focus specified.
    // Move to the root level.
    searchParams.delete(PARENT_PATH_KEY);
    searchParams.delete(SELECTION_PARENT_PATH_KEY);
    setSearchParams(searchParams);
  };

  return (
    <ItemSection
      nestedLayer={0}
      selected={isRootSelected}
      onDoubleClick={openRootNodeDiagram}
      onClick={selectRootNode}>
      <ModelTreeIcon>
        {(topLevelModelType === 'Model' ||
          topLevelModelType === 'Experiment') && (
          <Model
            fill={
              isRootSelected
                ? theme.colors.text.primary
                : theme.colors.text.secondary
            }
          />
        )}
        {topLevelModelType === 'Submodel' && (
          <Submodel
            fill={
              isRootSelected
                ? theme.colors.text.primary
                : theme.colors.text.secondary
            }
          />
        )}
      </ModelTreeIcon>
      <ModelBlockItemText>{modelName}</ModelBlockItemText>
    </ItemSection>
  );
};
