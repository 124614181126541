import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Coordinate } from 'app/common_types/Coordinate';
import {
  AnnotationInstance,
  LinkInstance,
  NodeInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';

export enum NavbarContext {
  None,
  Dashboard,
  ModelEditor,
  DataExplorer,
  Notebook,
  ScriptEditor,
  BusTypeEditor,
  Requirements,
}

export enum BrowserSection {
  None,
  Project,
}

export enum RightSidebarSection {
  Properties,
  VersionHistory,
}

export enum NotebookSection {
  None,
  Project,
  Kernels,
}

export enum DiagramFooterTab {
  None,
  Visualizer,
  Output,
  Console,
  AiAssistant,
  MediaVisualizer,
}

export enum NotebookFooterTab {
  None,
  Variables,
  Console,
}

export interface UIFlagsState {
  textInputFocused: boolean;
  htmlTextSelected: boolean;
  editingNodeNameUUID: string | null;
  editingAnnotationTextUUID: string | null;
  // this is temporary until actual clipboard support is more reasonable
  inAppClipboard: {
    projectId: string;
    copiedSubmodelsSection: SubmodelsSection;
    nodes: NodeInstance[];
    links: LinkInstance[];
    annotations: AnnotationInstance[];
  };
  hideLibraryDrag: boolean;
  modelEditorSection: BrowserSection;
  isLeftSidebarOpen: boolean;
  currentRightSidebarSection: RightSidebarSection;
  isRightSidebarOpen: boolean;
  notebookSection: NotebookSection;
  notebookFooterTab: NotebookFooterTab;
  diagramFooterTab: DiagramFooterTab;
  // Used in lieu of having react router context
  navbarContext: NavbarContext;
  showSignalNamesInModel: boolean;
  showDatatypesInModel: boolean;
  showRatesInModel: boolean;
  preDblConfirmClickCoordNeedsDeletion: boolean;
  rendererStateInitialized: boolean;
  showDebugKeyInfo: boolean;
  addingAnnotation: boolean;
  allowConsoleTerminal: boolean;
  showingCommandPalette: boolean;
  commandPaletteCoordWorldSpace: Coordinate;
  commandPaletteCoordScreenSpace: Coordinate;

  // Dynamically load pyodide
  requestLoadPython: boolean;
}

const initialState: UIFlagsState = {
  textInputFocused: false,
  htmlTextSelected: false,
  editingNodeNameUUID: null,
  editingAnnotationTextUUID: null,
  inAppClipboard: {
    projectId: '',
    nodes: [],
    links: [],
    annotations: [],
    copiedSubmodelsSection: { references: {}, diagrams: {} },
  },
  hideLibraryDrag: false,
  modelEditorSection: BrowserSection.None,
  isLeftSidebarOpen: true,
  currentRightSidebarSection: RightSidebarSection.Properties,
  isRightSidebarOpen: true,
  notebookSection: NotebookSection.None,
  notebookFooterTab: NotebookFooterTab.None,
  diagramFooterTab: DiagramFooterTab.None,
  navbarContext: NavbarContext.None,
  showSignalNamesInModel: false,
  showDatatypesInModel: false,
  showRatesInModel: false,
  preDblConfirmClickCoordNeedsDeletion: false,
  rendererStateInitialized: false,
  showDebugKeyInfo: false,
  addingAnnotation: false,
  allowConsoleTerminal: false,
  showingCommandPalette: false,
  commandPaletteCoordWorldSpace: { x: 0, y: 0 },
  commandPaletteCoordScreenSpace: { x: 0, y: 0 },
  requestLoadPython: false,
};

type UIFlagUpdatePayload = {
  [key in keyof UIFlagsState]?: UIFlagsState[key];
};

const uiFlagsSlice = createSlice({
  name: 'uiFlags',
  initialState,
  reducers: {
    setUIFlag(state, action: PayloadAction<UIFlagUpdatePayload>) {
      const hasAChange = (
        Object.keys(action.payload) as (keyof UIFlagsState)[]
      ).some((key) => state[key] !== action.payload[key]);

      if (hasAChange) {
        return {
          ...state,
          ...action.payload,
        };
      }
    },
    toggleUIFlag(state, action: PayloadAction<keyof UIFlagsState>) {
      if (typeof state[action.payload] !== 'boolean') return;

      return {
        ...state,
        [action.payload]: !state[action.payload],
      };
    },
    allLayersOff(state) {
      state.showSignalNamesInModel = false;
      state.showRatesInModel = false;
      state.showDatatypesInModel = false;
    },
    setNavbarContext(state, action: PayloadAction<NavbarContext>) {
      if (state.navbarContext !== action.payload) {
        if (state.navbarContext === NavbarContext.Notebook) {
          // Auto close the notebook footer when leaving the notebook
          // as saving the footer open state is not supported.
          state.notebookFooterTab = NotebookFooterTab.None;
        }
        state.navbarContext = action.payload;
      }
    },
    toggleModelEditorSection(state, action: PayloadAction<BrowserSection>) {
      if (state.modelEditorSection === action.payload) {
        state.modelEditorSection = BrowserSection.None;
      } else {
        state.modelEditorSection = action.payload;
      }
    },
    toggleLeftSidebarOpen(state) {
      state.isLeftSidebarOpen = !state.isRightSidebarOpen;
    },
    setRightSidebarTab(state, action: PayloadAction<RightSidebarSection>) {
      state.currentRightSidebarSection = action.payload;
    },
    toggleRightSidebarOpen(state) {
      state.isRightSidebarOpen = !state.isRightSidebarOpen;
    },
    setNotebookSection(state, action: PayloadAction<NotebookSection>) {
      state.notebookSection = action.payload;
    },
    toggleNotebookSection(state, action: PayloadAction<NotebookSection>) {
      if (state.notebookSection === action.payload) {
        state.notebookSection = NotebookSection.None;
      } else {
        state.notebookSection = action.payload;
      }
    },
    closeFooter(state) {
      if (state.navbarContext === NavbarContext.ModelEditor) {
        state.diagramFooterTab = DiagramFooterTab.None;
      }
      if (state.navbarContext === NavbarContext.Notebook) {
        state.notebookFooterTab = NotebookFooterTab.None;
      }
    },
    setNotebookFooterTab(state, action: PayloadAction<NotebookFooterTab>) {
      if (state.notebookFooterTab !== action.payload) {
        state.notebookFooterTab = action.payload;
      }
    },
    setDiagramFooterTab(state, action: PayloadAction<DiagramFooterTab>) {
      if (state.diagramFooterTab !== action.payload) {
        state.diagramFooterTab = action.payload;
      }
    },
    showVisualizerFooterTab(state) {
      if (state.diagramFooterTab === DiagramFooterTab.Output) {
        state.diagramFooterTab = DiagramFooterTab.Visualizer;
      }
    },
    requestLoadPython(state) {
      state.requestLoadPython = true;
    },
  },
});

export const uiFlagsActions = uiFlagsSlice.actions;

export default uiFlagsSlice;
