import { t } from '@lingui/macro';
import React from 'react';
import { SmallHighlight } from 'ui/common/typography/Typography';
import RunSimulationButton from './RunSimulationButton';
import { Message, Title, Wrapper } from './commonStyles';

const SimulationResultsOutOfDateState: React.FC = () => (
  <Wrapper>
    <Message>
      <Title>
        {t({
          id: 'visualizer.simulationResultsOutOfDate.title',
          message: 'Simulation results out of date.',
        })}
      </Title>
      <SmallHighlight>
        {t({
          id: 'visualizer.simulationResultsOutOfDate.hintText',
          message:
            'To see outputs plotted here, re-run the simulation to get the latest results.',
        })}
      </SmallHighlight>
    </Message>
    <RunSimulationButton />
  </Wrapper>
);

export default SimulationResultsOutOfDateState;
