import { MutableRefObject } from 'react';
import { CompilationAnalysisDataState } from 'app/slices/compilationAnalysisDataSlice';

export type SimulationRef = {
  compilationData: CompilationAnalysisDataState;
};

interface SimulationRefWrapper {
  refs: MutableRefObject<SimulationRef>;
}

let simulationRefWrapper: SimulationRefWrapper | null = null;

export function initSimulationRef(refs: MutableRefObject<SimulationRef>) {
  simulationRefWrapper = {
    refs,
  };
}

export function isSimulationlRefAvailable(): boolean {
  return !!simulationRefWrapper;
}

export function getSimulationRef(): SimulationRef {
  if (simulationRefWrapper) {
    return simulationRefWrapper.refs.current;
  }

  throw new Error('Tried to access SimulationRef before it was initialized.');
}
