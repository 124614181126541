import { t } from '@lingui/macro';
import React from 'react';
import { Key } from 'ui/common/Icons/Standard';
import { useModal } from 'ui/common/Modal/useModal';
import ApiKeysModal from './ApiKeysModal';
import { NavbarButton } from './NavbarButtons';

const ApiKeys: React.FC = () => {
  const { triggerModal } = useModal();

  const apiKeysModal = () => {
    triggerModal(<ApiKeysModal />, undefined, { preventOverflow: true });
  };

  return (
    <NavbarButton
      tooltipText={t({
        id: 'navBar.apiKeysTooltip',
        message: 'Manage API Keys',
      })}
      Icon={Key}
      onClickHandler={apiKeysModal}
      isEnabled
      testId="navbar-api-keys-button"
    />
  );
};

export default ApiKeys;
