import * as NVG from 'nanovg-js';
import { PortSide } from 'app/common_types/PortTypes';
import { RendererState } from 'ui/modelRendererInternals/modelRenderer';
import { HoverEntityType } from 'app/common_types/SegmentTypes';
import { LinkRenderData } from 'app/utils/linkToRenderData';
import { MouseActions } from 'app/common_types/MouseTypes';
import { cmlIsMatrix } from 'ui/modelEditor/LinkDetails';
import { getPortPathName } from 'ui/modelEditor/portPathNameUtils';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { getSimulationRef } from 'app/sliceRefAccess/SimulationRef';
import { TimeModeType } from 'app/slices/compilationAnalysisDataSlice';
import { isEntityInteractable } from './clickHandlers/isEntityInteractable';
import { drawPort } from './drawPort';
import {
  getOrInitLoadImageFromStore,
  RasterLoadState,
} from './rasterTextureStore';

export const LINK_COLORS = {
  normal: NVG.RGB(131, 149, 149),
  scalar: NVG.RGB(224, 176, 237),
  vector: NVG.RGB(196, 107, 220),
  matrix: NVG.RGB(152, 56, 176),
  tensor: NVG.RGB(109, 19, 120),
  constant: NVG.RGB(34, 135, 132),
  continuous: NVG.RGB(52, 194, 190),
  discrete_1: NVG.RGB(215, 204, 73),
  discrete_2: NVG.RGB(237, 152, 27),
  discrete_3: NVG.RGB(235, 101, 58),
  discrete_4: NVG.RGB(212, 54, 85),
  discrete_5: NVG.RGB(168, 25, 106),
  iterator: NVG.RGB(155, 141, 17),
};
export const LINK_COLOR_DISCONNECTED = NVG.RGB(230, 67, 77);
const LINK_CURVE_RADIUS = 5;

export const getTimeModeColor = (timeMode: TimeModeType) => {
  if (timeMode.mode === 'Discrete') {
    return (
      [
        LINK_COLORS.discrete_1,
        LINK_COLORS.discrete_2,
        LINK_COLORS.discrete_3,
        LINK_COLORS.discrete_4,
        LINK_COLORS.discrete_5,
      ][timeMode.stepLevel] || LINK_COLORS.normal
    );
    // TODO: what do we do if there are more than 5 levels?
  }

  const modeColor =
    {
      Iterator: LINK_COLORS.iterator,
      Continuous: LINK_COLORS.continuous,
      Constant: LINK_COLORS.constant,
      Unknown: LINK_COLORS.normal,
    }[timeMode.mode] || LINK_COLORS.normal;

  return modeColor;
};

export function drawLink(
  nvg: NVG.Context,
  rs: RendererState,
  linkRenderData: LinkRenderData,
  offsetX: number,
  offsetY: number,
): void {
  const { vertexData, linkUuid } = linkRenderData;

  const link = rs.refs.current.links[rs.refs.current.linksIndexLUT[linkUuid]];
  if (!link) return;

  const linkType = link.uiprops.link_type;
  const tappedLink =
    linkType.connection_method === 'link_tap'
      ? rs.refs.current.links[
          rs.refs.current.linksIndexLUT[linkType.tapped_link_uuid || '']
        ]
      : undefined;

  const selected = rs.refs.current.selectedLinkIds.includes(linkUuid);
  const hovering =
    (rs.mouseState.state === MouseActions.DraggingLinkSegment &&
      rs.mouseState.linkUuid === linkUuid) ||
    (rs.hoveringEntity &&
      (rs.hoveringEntity.entityType === HoverEntityType.Link ||
        rs.hoveringEntity.entityType === HoverEntityType.FakeLinkSegment ||
        rs.hoveringEntity.entityType === HoverEntityType.TapPoint) &&
      rs.hoveringEntity.linkUuid === linkUuid &&
      isEntityInteractable(rs, rs.hoveringEntity));

  const highlight = selected || hovering;

  const userDrawingThisLink =
    (rs.mouseState.state === MouseActions.DrawingLinkFromStart ||
      rs.mouseState.state === MouseActions.DrawingLinkFromEnd) &&
    rs.mouseState.linkUuid === link.uuid;

  const srcNode = link.src
    ? rs.refs.current.nodes[rs.refs.current.nodesIndexLUT[link.src.node]]
    : undefined;
  const dstNode = link.dst
    ? rs.refs.current.nodes[rs.refs.current.nodesIndexLUT[link.dst.node]]
    : undefined;

  const srcDisconnected =
    !link.src ||
    (srcNode && srcNode.outputs.length - 1 < (link.src?.port || -1));
  const dstDisconnected =
    !link.dst ||
    (dstNode && dstNode.inputs.length - 1 < (link.dst?.port || -1));
  const linkIsDisconnected =
    (!userDrawingThisLink && (!link.src || !link.dst)) ||
    srcDisconnected ||
    dstDisconnected;

  if (!vertexData[0]) return;

  const portPathName = getPortPathName(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    {
      parentPath: getCurrentModelRef().submodelPath,
      nodeId: link.src?.node || '',
      portIndex: link.src?.port || 0,
    },
    { includePortNameForNonSubmodels: false },
  );

  const { datatypeAndDimensions, timeMode } = (portPathName &&
    getSimulationRef().compilationData.signalsData[portPathName]) || {
    datatypeAndDimensions: undefined,
    timeMode: undefined,
  };

  let currentLinkConnectedColor = LINK_COLORS.normal;

  if (
    rs.refs.current.uiFlags.showDatatypesInModel &&
    link.src &&
    datatypeAndDimensions
  ) {
    const portDataTypeAndDimensions = datatypeAndDimensions[link.src?.port];

    if (
      portDataTypeAndDimensions &&
      portDataTypeAndDimensions.kind === 'Tensor'
    ) {
      const dimensionsArray = portDataTypeAndDimensions.value[0];
      const isMatrix = cmlIsMatrix(dimensionsArray);
      if (isMatrix) currentLinkConnectedColor = LINK_COLORS.matrix;
      else currentLinkConnectedColor = LINK_COLORS.vector;
    } else {
      currentLinkConnectedColor = LINK_COLORS.scalar;
    }
  }

  if (rs.refs.current.uiFlags.showRatesInModel && link.src) {
    if (rs.refs.current.currentSubdiagramType === 'core.Iterator') {
      // TODO: we might want to rely on the backend for this
      // this is just for now until we're sure how the backend timemode data looks for iterators
      currentLinkConnectedColor = LINK_COLORS.iterator;
    } else if (timeMode) {
      currentLinkConnectedColor = getTimeModeColor(timeMode);
    }
  }

  const [firstX, firstY] = vertexData[0].coordinate;

  if (tappedLink && highlight) {
    nvg.beginPath();
    nvg.fillColor(
      selected ? NVG.RGBA(105, 225, 219, 255) : NVG.RGBA(105, 225, 219, 127),
    );
    nvg.circle(
      (firstX + offsetX) * rs.zoom,
      (firstY + offsetY) * rs.zoom,
      6 * rs.zoom,
    );
    nvg.fill();
  }

  nvg.beginPath();
  if (vertexData.length > 1) {
    nvg.moveTo((firstX + offsetX) * rs.zoom, (firstY + offsetY) * rs.zoom);

    for (let i = 1; i < vertexData.length; i++) {
      const [vX, vY] = vertexData[i].coordinate;

      if (vertexData[i + 1]) {
        const [pVX, pVY] = vertexData[i - 1].coordinate;
        const [nVX, nVY] = vertexData[i + 1].coordinate;

        const prevSegmentDistance =
          vY === pVY ? Math.abs(pVX - vX) : Math.abs(pVY - vY);
        const nextSegmentDistance =
          vY === nVY ? Math.abs(nVX - vX) : Math.abs(nVY - vY);

        const halfSegmentDistance =
          Math.min(prevSegmentDistance, nextSegmentDistance) / 2;

        nvg.arcTo(
          (vX + offsetX) * rs.zoom,
          (vY + offsetY) * rs.zoom,
          (nVX + offsetX) * rs.zoom,
          (nVY + offsetY) * rs.zoom,
          Math.min(halfSegmentDistance, LINK_CURVE_RADIUS) * rs.zoom,
        );
      } else {
        nvg.lineTo((vX + offsetX) * rs.zoom, (vY + offsetY) * rs.zoom);
      }
    }
  }

  if (highlight) {
    nvg.strokeWidth(6 * rs.zoom); // 2pt width outer stroke; 2pt for each side
    nvg.strokeColor(
      selected ? NVG.RGBA(105, 225, 219, 255) : NVG.RGBA(105, 225, 219, 127),
    );
    nvg.stroke();
  }

  nvg.strokeWidth(2 * rs.zoom);
  if (linkIsDisconnected && !userDrawingThisLink) {
    nvg.strokeColor(LINK_COLOR_DISCONNECTED);
  } else {
    nvg.strokeColor(currentLinkConnectedColor);
  }
  nvg.stroke();

  // draw the dot to represent the link tap
  if (tappedLink) {
    nvg.beginPath();
    if (linkIsDisconnected && !userDrawingThisLink) {
      nvg.fillColor(LINK_COLOR_DISCONNECTED);
    } else {
      nvg.fillColor(currentLinkConnectedColor);
    }
    nvg.circle(
      (firstX + offsetX) * rs.zoom,
      (firstY + offsetY) * rs.zoom,
      4 * rs.zoom,
    );
    nvg.fill();

    if (rs.debugMode) {
      nvg.fontSize(10);
      nvg.textLineHeight(16);
      nvg.fontFace('archivo');
      nvg.textAlign(NVG.Align.LEFT | NVG.Align.TOP);
      nvg.fillColor(nvg.RGB(0, 0, 0));
      nvg.text(
        (firstX + offsetX) * rs.zoom + 5,
        (firstY + offsetY) * rs.zoom + 5,
        `src: ${link.src?.node || 'none'}`,
        null,
      );
    }
  }

  if (
    !tappedLink &&
    (srcDisconnected ||
      (rs.mouseState.state === MouseActions.DrawingLinkFromStart &&
        rs.mouseState.linkUuid === link.uuid))
  ) {
    const hanging = srcDisconnected && !userDrawingThisLink;

    nvg.beginPath();
    if (hanging) {
      nvg.fillColor(LINK_COLOR_DISCONNECTED);
    } else {
      nvg.fillColor(currentLinkConnectedColor);
    }
    nvg.circle(
      (firstX + offsetX) * rs.zoom,
      (firstY + offsetY) * rs.zoom,
      4 * rs.zoom,
    );
    nvg.fill();
  }

  if (
    dstDisconnected ||
    (rs.mouseState.state === MouseActions.DrawingLinkFromEnd &&
      rs.mouseState.linkUuid === link.uuid)
  ) {
    const [finalX, finalY] = vertexData[vertexData.length - 1].coordinate;
    const [secondFinalX] = (vertexData[vertexData.length - 2] || {})
      .coordinate || [0];

    const flipped = finalX < secondFinalX;

    const portShapeX = userDrawingThisLink
      ? finalX
      : link.uiprops?.hang_coord_end?.x || finalX;
    const portShapeY = userDrawingThisLink
      ? finalY
      : link.uiprops?.hang_coord_end?.y || finalY;

    const portPathName = getPortPathName(
      getCurrentModelRef().topLevelNodes,
      getCurrentModelRef().submodels,
      {
        parentPath: getCurrentModelRef().submodelPath,
        nodeId: link.src?.node || '',
        portIndex: link.src?.port || 0,
      },
      { includePortNameForNonSubmodels: false },
    );

    const { datatypeAndDimensions } = (portPathName &&
      getSimulationRef().compilationData.signalsData[portPathName]) || {
      datatypeAndDimensions: undefined,
    };

    let currentPortConnectedColor: NVG.NVGcolor | undefined;

    if (
      rs.refs.current.uiFlags.showDatatypesInModel &&
      link.src &&
      datatypeAndDimensions
    ) {
      const portDataTypeAndDimensions = datatypeAndDimensions[link.src.port];

      if (
        portDataTypeAndDimensions &&
        portDataTypeAndDimensions.kind === 'Tensor'
      ) {
        const dimensionsArray = portDataTypeAndDimensions.value[0];
        const isMatrix = cmlIsMatrix(dimensionsArray);
        if (isMatrix) currentPortConnectedColor = LINK_COLORS.matrix;
        else currentPortConnectedColor = LINK_COLORS.vector;
      } else {
        currentPortConnectedColor = LINK_COLORS.scalar;
      }
    }

    drawPort(
      nvg,
      rs,
      portShapeX + offsetX,
      portShapeY + offsetY,
      rs.zoom,
      PortSide.Input,
      true,
      false,
      false,
      flipped,
      currentPortConnectedColor,
      userDrawingThisLink,
      false, // hollow
      false, // hasError
    );
  }

  const occlusionPoints = rs.linksOcclusionPointLUT[linkRenderData.linkUuid];

  if (occlusionPoints) {
    const rawScale = Math.round(window.devicePixelRatio * rs.zoom);
    const scale = rawScale > 2 ? 4 : 2;
    const vertRasterID = `link_occlusion_v_${scale}x`;
    const horizRasterID = `link_occlusion_h_${scale}x`;

    for (let i = 0; i < occlusionPoints.length; i++) {
      const occlusionPoint = occlusionPoints[i];

      const rasterID =
        occlusionPoint.orientation == 'vertical' ? vertRasterID : horizRasterID;

      const rasterMeta = getOrInitLoadImageFromStore(
        nvg,
        `${process.env.PUBLIC_URL}/assets/${rasterID}.png`,
        rasterID,
        scale,
      );

      if (rasterMeta?.loadState === RasterLoadState.Loaded) {
        const rw = (rasterMeta.width / scale) * rs.zoom;
        const rh = (rasterMeta.height / scale) * rs.zoom;
        const midX = occlusionPoint.x + offsetX;
        const midY = occlusionPoint.y + offsetY;
        const rx = midX * rs.zoom - rw / 2;
        const ry = midY * rs.zoom - rh / 2;
        const imgPaint = nvg.imagePattern(
          rx,
          ry,
          rw,
          rh,
          0,
          rasterMeta.imageId,
          1,
        );
        nvg.beginPath();
        nvg.rect(rx, ry, rw, rh);
        nvg.fillPaint(imgPaint);
        nvg.fill();
      }
    }
  }
}
