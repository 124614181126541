import { useTheme } from '@emotion/react';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import React from 'react';
import { Continuous } from 'ui/common/Icons/Small';
import { SignalDragItem } from 'ui/dataExplorer/dataExplorerTypes';
import DraggableSignalTreeItem from 'ui/dataExplorer/drag/signalDrag/DraggableSignalTreeItem';
import { RightPinnedVisualizerButton } from 'ui/dataExplorer/simulationDataSidebar/RightPinnedButton';
import { useSignal } from 'ui/dataExplorer/simulationDataSidebar/useSignal';
import {
  ItemSection,
  ModelBlockItemText,
  ModelTreeIcon,
} from 'ui/objectBrowser/ModelTreeParts';
import { getDefaultChartType } from 'util/timeMode';

interface Props {
  modelId: string;
  modelVersionId: string;
  simulationId: string;
  signalPath: string;
  parentCount: number;
  parentNode: NodeInstance | undefined;
  defaultDisplayName: string;
  hasCsvSupport: boolean;
  canEditProject: boolean;
}

export const ModelSimulationSignalListItem: React.FC<Props> = ({
  modelId,
  modelVersionId,
  simulationId,
  signalPath,
  parentCount,
  parentNode,
  defaultDisplayName,
  hasCsvSupport,
  canEditProject,
}) => {
  const theme = useTheme();

  const {
    timeMode,
    isSupported,
    signalDisplayName,
    isSignalInExploration,
    toggleVisualization,
  } = useSignal({
    modelId,
    modelVersionId,
    simulationId,
    signalPath,
    parentNode,
    defaultDisplayName,
    hasCsvSupport,
    canEditProject,
  });

  const traceSpec: SignalDragItem['traceSpecs'][number] = {
    signalPath,
    tracePath: signalPath,
    displayName: signalDisplayName || signalPath,
    plotType: getDefaultChartType(timeMode),
    modelId,
    simulationId,
  };

  return (
    <ItemSection
      data-test-id={`data-explorer-model-simulation-signal-tree-model-item-${signalPath}`}
      selected={false}
      nestedLayer={parentCount + 2}>
      <ModelTreeIcon />
      <DraggableSignalTreeItem traceSpecs={[traceSpec]}>
        <ModelTreeIcon>
          <Continuous fill={theme.colors.text.secondary} />
        </ModelTreeIcon>
        <div
          data-test-id={`data-explorer-model-simulation-signal-tree-model-item-text-${signalPath}`}>
          <ModelBlockItemText>{signalDisplayName}</ModelBlockItemText>
        </div>
      </DraggableSignalTreeItem>
      {isSupported && (
        <RightPinnedVisualizerButton
          path={signalPath}
          isToggledOn={isSignalInExploration}
          canEditProject={canEditProject}
          toggleVisualization={toggleVisualization}
        />
      )}
    </ItemSection>
  );
};
