import {
  transformBackendModelDiagram,
  transformBackendParameterDefinitions,
  transformBackendSimulationModel,
} from '@collimator/model-schemas-ts';
import { ModelLevelParameters } from 'app/apiData';
import { runAutolayout } from 'app/autolayout';
import { ModelDiagram } from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { scaleCameraToFitModel } from 'ui/modelRendererInternals/shortcutKeyConfig';
import { EditorMode, useModelEditorInfo } from './useModelEditorInfo';

export const useUiModelUpdater = () => {
  const dispatch = useAppDispatch();
  const { showError, showInfo } = useNotifications();

  const { groupBlockUuid, isModelReadOnly } = useModelEditorInfo();
  const modelName = useAppSelector((state) => state.model.present.name);
  const configuration = useAppSelector(
    (state) => state.model.present.configuration,
  );

  const importGroupModelData = React.useCallback(
    (modelData: any) => {
      const simModel = transformBackendModelDiagram(modelData.diagram);
      dispatch(
        modelActions.loadGroupContent({
          diagram: simModel,
          groupBlockUuid,
        }),
      );
      return simModel;
    },
    [dispatch, groupBlockUuid],
  );

  const importSubmodelModelData = React.useCallback(
    (modelData: any) => {
      const parameterDefinitions = transformBackendParameterDefinitions(
        modelData.parameters,
      );

      const rootModel = modelData.diagram || modelData.rootModel;
      if (!rootModel) return;

      const sm = transformBackendSimulationModel({
        uuid: modelData.uuid,
        name: modelName, // Do not change the model name
        diagram: rootModel,
        configuration, // Do not change the model configuration
        submodels: modelData.submodels || { diagrams: {}, references: {} },
      });

      dispatch(
        modelActions.loadSubmodelContent({
          ...sm,
          parameterDefinitions,
        }),
      );
      return sm.diagram;
    },
    [dispatch, modelName, configuration],
  );

  const importModelData = React.useCallback(
    (modelData: any) => {
      if (modelData.unprocessed) {
        modelData = modelData.unprocessed;
      }

      const rootModel = modelData.diagram || modelData.rootModel;
      if (!rootModel) return;

      const sm = transformBackendSimulationModel({
        uuid: rootModel.uuid,
        name: modelName, // Do not change the model name
        diagram: rootModel,
        configuration, // Do not change the model configuration
        submodels: modelData.submodels || { diagrams: {}, references: {} },
      });

      let parameters: ModelLevelParameters = modelData.parameters
        ? Array.isArray(modelData.parameters)
          ? modelData.parameters.reduce(
              (acc: ModelLevelParameters, curr: any) =>
                curr.name && curr.value
                  ? {
                      ...acc,
                      [curr.name]: { value: curr.value },
                    }
                  : acc,
              {},
            )
          : modelData.parameters
        : {};

      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.debug(`loading model content:\n${sm.diagram}`);
      }
      dispatch(
        modelActions.loadModelContent({
          ...sm,
          parameters,
        }),
      );
      return sm.diagram;
    },
    [dispatch, modelName, configuration],
  );

  const updateUiModel = React.useCallback(
    async (jsonModel: string, editorMode: EditorMode, autoLayout?: boolean) => {
      if (isModelReadOnly) {
        showError('The editor is in read-only mode.');
        return;
      }
      try {
        const modelData = JSON.parse(jsonModel);
        let modelDiagram: ModelDiagram | undefined;
        if (editorMode === EditorMode.Group) {
          modelDiagram = importGroupModelData(modelData);
        } else if (editorMode === EditorMode.Submodel) {
          modelDiagram = importSubmodelModelData(modelData);
        } else {
          modelDiagram = importModelData(modelData);
        }
        if (autoLayout && modelDiagram) {
          await runAutolayout(modelDiagram.nodes, modelDiagram.links).then(
            (layoutPayload) => {
              if (layoutPayload) {
                dispatch(modelActions.consumeAutoLayout(layoutPayload));
              }
            },
          );
        }
        if (rendererState) {
          scaleCameraToFitModel(rendererState);
        }
        showInfo('Model imported successfully.');
      } catch (e) {
        showError('Could not import model from the generated code.', e);
        throw e;
      }
    },
    [
      dispatch,
      importGroupModelData,
      importModelData,
      importSubmodelModelData,
      isModelReadOnly,
      showError,
      showInfo,
    ],
  );

  return updateUiModel;
};
