import { useJupyterProject } from 'app/api/useJupyterProject';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import {
  ProjectItemType,
  ProjectsDetailTableData,
} from 'ui/dashboard/dashboardTypes';

/**
 * Get all the items in a project (including models, submodels, files, and notebooks).
 * Note: only the first file that's processing is returned in `fileIdToWatch`.
 */
export function useProjectItems(project?: Project) {
  const submodels = useAppSelector(
    (state) =>
      state.submodels.projectIdToSubmodelInfoLites[project?.uuid || ''],
  );
  const { files: hubFiles } = useJupyterProject(project?.uuid || '');

  const [projectItems, fileIdToWatch] = React.useMemo(() => {
    let modelData: ProjectsDetailTableData[] = [];
    let submodelData: ProjectsDetailTableData[] = [];
    let projectFileData: ProjectsDetailTableData[] = [];
    let busTypeData: ProjectsDetailTableData[] = [];
    let notebookFileData: ProjectsDetailTableData[] = [];

    modelData =
      project?.models.map((m) => ({
        type:
          m.kind == 'Experiment'
            ? ProjectItemType.EXPERIMENT
            : ProjectItemType.MODEL,
        projectUuid: project?.uuid || '',
        uuid: m.uuid,
        name: m.name,
        createdAt: m.created_at,
        updatedAt: m.updated_at,
        error: '',
        isProcessing: false,
      })) || [];

    projectFileData =
      project?.files.map((f) => ({
        type: ProjectItemType.PROJECTFILE,
        projectUuid: project.uuid,
        uuid: f.uuid,
        name: f.name,
        createdAt: f.created_at,
        updatedAt: f.updated_at,
        error: f.error,
        isProcessing: f.status === 'processing_in_progress',
      })) || [];

    submodelData = (submodels || []).map((submodel) => ({
      type: ProjectItemType.SUBMODEL,
      projectUuid: project?.uuid || '',
      uuid: submodel.id,
      name: submodel.name,
      createdAt: submodel.createdAt,
      updatedAt: submodel.updatedAt,
      error: '',
      isProcessing: false,
    }));

    busTypeData = (project?.busTypes || []).map((busType) => ({
      type: ProjectItemType.BUS_TYPE,
      projectUuid: project?.uuid || '',
      uuid: busType.id,
      name: busType.name,
      createdAt: busType.created_at,
      updatedAt: busType.updated_at,
      error: '',
      isProcessing: false,
    }));

    if (hubFiles.isSuccess && !hubFiles.isFetching && hubFiles.data) {
      notebookFileData = hubFiles.data.jupyter_files
        .filter((f) => f.type === 'notebook' || f.type === 'other')
        .map((f) => ({
          type:
            f.type === 'notebook'
              ? ProjectItemType.NOTEBOOK
              : ProjectItemType.NOTEBOOKFILE,
          name: f.name,
          createdAt: '',
          updatedAt: '',
          uuid: '',
          projectUuid: project?.uuid || '',
          error: '',
          isProcessing: false,
        }));
    } else {
      notebookFileData = [];
    }

    const projectItems = modelData.concat(
      submodelData,
      projectFileData,
      busTypeData,
      notebookFileData,
    );

    // FIXME: multiple files can be processing at once, but this only detects one?
    // Elsewhere on the `ProjectDetail` page the `project` is refreshed when a file is done processing,
    // so if there are other files in progress, they will be picked up in the next.
    // Noting the fragmented logic that ensures all files are accounted for in that specific case,
    // but it would not be correct on its own.
    // See: useFileProcessingStatus for the logic that uses this var
    // Even in the case of `ProjectDetail` there is a bug:
    // a file that finishes faster will not show as completed if it's lower on the list.
    const fileIdToWatch =
      projectFileData.find((f) => f.isProcessing)?.uuid || '';

    return [projectItems, fileIdToWatch];
  }, [submodels, hubFiles, project]);

  return {
    projectItems,
    hubFiles,
    fileIdToWatch,
  };
}
