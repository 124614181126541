import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useJupyter } from 'app/api/useJupyter';
import { useNavigateToProjectHome } from 'app/api/useNavigateToProjectHome';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { jupyterActions } from 'app/slices/jupyterSlice';
import { NavbarContext } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { getUserName } from 'ui/auth/utils';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { useTimer } from 'ui/common/timer/useTimer';
import { useAppParams } from 'util/useAppParams';
import NotebookBreadcrumb from './NotebookBreadcrumb';
import domSelectors from './jupyterHelpers/selectors';
import { IframeState } from './notebookTypes';
import { getNotebookElement, getNotebookIFrameContent } from './notebookUtils';

const IframeWrapper = styled.div`
  ${({ theme }) => `
  position: fixed;
  top: ${theme.sizes.navbarHeight};
  bottom: ${theme.sizes.footerBarHeight};
  left: 0;
  right: 0;
  background-color: ${theme.colors.grey[10]};
  `}
`;

const Iframe = styled.iframe`
  border: 0;
  pointer-events: auto;
  width: 900px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
`;

async function cleanUpNotebook(theme: Theme) {
  const addStyle = (styleString: string) => {
    const iframeDoc = getNotebookIFrameContent();
    if (iframeDoc) {
      const style = iframeDoc.createElement('style');
      style.textContent = styleString;
      iframeDoc.head.append(style);
    }
  };

  addStyle(`
  @import url('https://fonts.googleapis.com/css?family=Inconsolata');

  #header-container {
    display: none !important;
  }
  .dropdown:first-child {
    display: none;
  }

  .container {
    width: 100%;
  }

  div#notebook {
    padding: 0;
  }

  .CodeMirror pre.CodeMirror-line {
    font-family: Inconsolata;
    line-height: 26px;
  }
  .CodeMirror {
    --jp-code-font-size: ${theme.typography.font.code.size};
  }
  .CodeMirror-lines {
    padding: ${theme.spacing.normal} ${theme.spacing.large};
  }
  .CodeMirror-linenumber {
    margin-left: -${theme.spacing.large};
    line-height: 26px;
    color: ${theme.colors.text.secondary};
    font-family: Inconsolata;
  }

`);
}

const NotebookIframe: React.FC = () => {
  const { projectId = '', fileName } = useAppParams();
  const dispatch = useAppDispatch();
  const { hasAccessToJupyter } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const theme = useTheme();
  const { setCellFormat, initIframe, startServer } = useJupyter();
  const { navigateToProjectHome } = useNavigateToProjectHome();
  const { showError } = useNotifications();
  const iframeWrapperRef = React.useRef<HTMLDivElement>(null);

  const username = getUserName();

  const isNotebookView = useAppSelector(
    (state) => state.uiFlags.navbarContext === NavbarContext.Notebook,
  );

  React.useEffect(() => {
    if (iframeWrapperRef?.current) {
      // We do this manually here to prevent the iframe to rerender every time we go in and out the dashboard
      if (isNotebookView) {
        iframeWrapperRef.current.style.width = 'auto';
        iframeWrapperRef.current.style.height = '100%';
      } else {
        iframeWrapperRef.current.style.width = '0';
        iframeWrapperRef.current.style.height = '0';
      }
    }
  }, [isNotebookView, theme]);

  React.useEffect(() => {
    if (hasAccessToJupyter) {
      startServer();
    }
  }, [startServer, hasAccessToJupyter]);

  const { startTimer } = useTimer();

  async function readCellFormat() {
    startTimer(() => {
      const jupyterSelector = getNotebookElement(
        domSelectors.cellTypeSelect(),
      ) as HTMLSelectElement;
      if (jupyterSelector) {
        const selectorValue = jupyterSelector.value;
        if (selectorValue === 'code') {
          setCellFormat({ value: 'code', label: 'python3' });
        } else if (selectorValue === 'markdown') {
          setCellFormat({ value: 'markdown', label: 'markdown' });
        } else if (selectorValue === 'raw') {
          setCellFormat({ value: 'raw', label: 'raw' });
        } else {
          setCellFormat({ value: '-', label: '-' });
        }
      }
    }, 1);
  }

  React.useEffect(() => {
    dispatch(jupyterActions.setIframeReady(false));
  }, [dispatch, projectId, fileName]);

  const onLoad = () => {
    cleanUpNotebook(theme);
    const IFrameDoc = getNotebookIFrameContent();
    if (IFrameDoc) {
      IFrameDoc.addEventListener('mouseup', readCellFormat);
    }
    if (fileName) {
      initIframe()
        // We need to wait a bit until Jupyter finishes setting the correct value
        .then(() => startTimer(readCellFormat, 200))
        .catch((state?: IframeState) => {
          switch (state) {
            case IframeState.NOT_FOUND:
              showError(
                t({
                  id: 'notebooks.errors.notFound',
                  message: 'File not found',
                }),
              );
              navigateToProjectHome();
              break;
            case IframeState.UNKNOWN:
              showError(
                t({
                  id: 'notebooks.errors.unknown',
                  message: 'Unknown error occurred. Please try again later',
                }),
              );
              navigateToProjectHome();
              break;
          }
        });
    }
  };
  let src = `/hub/user/${username}/`;
  if (fileName && fileName.endsWith('ipynb')) {
    src += `notebooks/${projectId}/${encodeURIComponent(fileName)}`;
  } else if (fileName) {
    src += `edit/${projectId}/${encodeURIComponent(fileName)}`;
  } else {
    src += 'lab';
  }
  return (
    <IframeWrapper ref={iframeWrapperRef}>
      {fileName && projectId && (
        <>
          <NotebookBreadcrumb />
          <Iframe data-test-id="notebook-frame" onLoad={onLoad} src={src} />
        </>
      )}
    </IframeWrapper>
  );
};

export default NotebookIframe;
