import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';
import { AnnotationDetails } from './AnnotationDetails';
import { BlockDetails } from './BlockDetails';
import { DetailsContainer } from './DetailsComponents';
import { LinkDetails } from './LinkDetails';
import ModelDetails from './ModelDetails';

const DiagramRightSidebar: React.FC = () => {
  const [selectedNodeId] = useAppSelector(
    (state) => state.model.present.selectedBlockIds,
  );
  const [selectedLinkId] = useAppSelector(
    (state) => state.model.present.selectedLinkIds,
  );
  const [selectedAnnotationId] = useAppSelector(
    (state) => state.model.present.selectedAnnotationIds,
  );

  const selectionParentPath = useAppSelector(
    (state) => state.model.present?.selectionParentPath,
  );

  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present?.currentSubmodelPath,
  );

  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );
  const modelIsOpenAndLoaded =
    projectId && modelId && loadedModelId && loadedModelId === modelId;

  const hasAnySelection = !!(
    selectedNodeId ||
    selectedLinkId ||
    selectedAnnotationId
  );
  const showTopLevelModelDetails =
    !hasAnySelection && selectionParentPath?.length === 0;

  if (!modelIsOpenAndLoaded) {
    return null;
  }

  return (
    <DetailsContainer data-test-id="model-right-sidebar-scroller">
      {showTopLevelModelDetails ? (
        <ModelDetails
          modelId={modelId}
          projectId={projectId}
          canEditCurrentModelVersion={canEditCurrentModelVersion}
        />
      ) : selectedLinkId ? (
        <LinkDetails
          modelId={modelId}
          selectionParentPath={selectionParentPath}
          selectedLinkId={selectedLinkId}
          canEditCurrentModelVersion={canEditCurrentModelVersion}
        />
      ) : selectedAnnotationId ? (
        <AnnotationDetails
          canEditCurrentModelVersion={canEditCurrentModelVersion}
          selectedAnnotationId={selectedAnnotationId}
        />
      ) : selectedNodeId ? (
        <BlockDetails
          key={selectedNodeId}
          parentPath={selectionParentPath}
          nodeId={selectedNodeId}
          canEditCurrentModelVersion={canEditCurrentModelVersion}
        />
      ) : (
        <BlockDetails
          parentPath={currentSubmodelPath.slice(0, -1)}
          nodeId={currentSubmodelPath[currentSubmodelPath.length - 1]}
          canEditCurrentModelVersion={canEditCurrentModelVersion}
        />
      )}
    </DetailsContainer>
  );
};

export default DiagramRightSidebar;
