import { t } from '@lingui/macro';
import { PortSide } from 'app/common_types/PortTypes';
import { useGetModelReadByUuidQuery } from 'app/enhancedApi';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput, { SelectInputOption } from 'ui/common/SelectInput';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import BlockPortParameters from './BlockPortParameter';

type Props = {
  parentPath: string[];
  selectedNode: NodeInstance;
  canEdit: boolean;
};

const ExperimentModelInportDetails: React.FC<Props> = ({
  parentPath,
  selectedNode,
  canEdit,
}) => {
  const dispatch = useAppDispatch();

  const [targetModelId, targetVersionId] = React.useMemo(() => {
    const modelParam = selectedNode.parameters?.model;
    const versionParam = selectedNode.parameters?.model_version;
    return [modelParam?.value, versionParam?.value];
  }, [selectedNode.parameters]);

  // FIXME: we use the GET model API instead of a more appropriate API
  // that would only list the outputs and parameters of the model.
  // Such an API could benefit from running a quick compilation
  // of the model, if we want to be sure about the available outputs.
  // For now, we just assume that all top-level blocks in the target model
  // can be observed and we'll throw errors otherwise.
  const {
    data: targetModelData,
    isFetching: isFetchingTargetModelData,
    refetch: refetchTargetModelData,
  } = useGetModelReadByUuidQuery(
    { modelUuid: targetModelId || '' },
    { skip: !targetModelId },
  );

  const alreadyUsedParameters: string[] = React.useMemo(
    () => selectedNode.inputs.map((port) => port.name) || [],
    [selectedNode.inputs],
  );

  const notUsedParameters: string[] = React.useMemo(() => {
    const params = Object.keys(targetModelData?.parameters || {}).filter(
      (paramName) => !alreadyUsedParameters.includes(paramName),
    );
    return params;
  }, [targetModelData?.parameters, alreadyUsedParameters]);

  const addInput = React.useCallback(() => {
    // Find first available parameter
    const availableParameter = notUsedParameters[0];
    if (!availableParameter) return;

    dispatch(
      modelActions.addPort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Input,
        name: availableParameter,
      }),
    );
  }, [dispatch, parentPath, selectedNode, notUsedParameters]);

  const removeInput = (inputId: number) => () => {
    dispatch(
      modelActions.removePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Input,
        portId: inputId,
      }),
    );
  };

  const selectParameterName = (inputId: number, value: string) => {
    dispatch(
      modelActions.renamePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Input,
        portId: inputId,
        newPortName: value,
      }),
    );
  };

  const dropDownContents: SelectInputOption[][] = React.useMemo(() => {
    const valuePairs = selectedNode.inputs.map((input, i) => {
      const available = notUsedParameters.map((v) => ({ value: v, label: v }));
      const current = { value: input.name, label: input.name };
      return [current, ...available];
    });
    return valuePairs;
  }, [selectedNode.inputs, notUsedParameters]);

  return (
    <>
      <SectionHeading
        testId="inputs"
        onButtonClick={addInput}
        isButtonEnabled={canEdit && notUsedParameters.length > 0}
        buttonTooltip={t({
          id: 'blockDetails.addInputButtonTooltip',
          message: 'Add input',
        })}>
        {t({
          id: 'blockDetails.PortInputsTitle',
          message: 'Inputs',
        })}
      </SectionHeading>

      {selectedNode.inputs.length > 0 && (
        <DetailInputRowsSection>
          {selectedNode.inputs.map((input, i) => (
            <div key={input.name || `row-input[${i}]`}>
              <DetailsSection>
                {input.name === 'parameters' ? (
                  <DetailsInput
                    grow
                    testId={`block-input-name-${i}`}
                    value={input.name}
                    disabled
                  />
                ) : (
                  <>
                    <SelectInput
                      onSelectValue={(s) => selectParameterName(i, s)}
                      currentValue={input.name}
                      options={dropDownContents[i]}
                      isDisabled={!canEdit}
                    />
                    <Button
                      testId={`block-input-remove-button-${i}`}
                      variant={ButtonVariants.LargeTertiary}
                      Icon={Remove}
                      onClick={removeInput(i)}
                      disabled={!canEdit}
                    />
                  </>
                )}
              </DetailsSection>
              <BlockPortParameters
                parentPath={parentPath}
                selectedNode={selectedNode}
                canEdit={canEdit}
                port={input}
                index={i}
                paramDefinitions={undefined}
                portSide={PortSide.Input}
              />
            </div>
          ))}
        </DetailInputRowsSection>
      )}
    </>
  );
};

export default ExperimentModelInportDetails;
