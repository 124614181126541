/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Group: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9 8C8.44772 8 8 8.44772 8 9V11C8 11.5523 8.44771 12 9 12H9.94445V13.9991C9.94445 14.8276 10.616 15.4991 11.4444 15.4991H12.2073C12.3801 15.7985 12.7036 16 13.0741 16H14.9853C15.5376 16 15.9853 15.5523 15.9853 15V14.4736C15.9853 13.9213 15.5376 13.4736 14.9853 13.4736H13.0741C12.5218 13.4736 12.0741 13.9213 12.0741 14.4736V14.4991H11.4444C11.1683 14.4991 10.9444 14.2753 10.9444 13.9991V12H12C12.5523 12 13 11.5523 13 11V10.5H13.6296V11C13.6296 11.5523 14.0773 12 14.6296 12H15C15.5523 12 16 11.5523 16 11V9C16 8.44772 15.5523 8 15 8H14.6296C14.0773 8 13.6296 8.44771 13.6296 9V9.5H13V9C13 8.44772 12.5523 8 12 8H9ZM12 9H9L9 11H12V9ZM15 9H14.6296V11L15 11V9ZM13.0741 14.4736H14.9853L14.9853 15H13.0741V14.4736Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4H5ZM5.5 5.5V18.5H18.5V5.5H5.5Z"
      fill={props.fill}
    />
  </svg>
);

Group.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Group;
