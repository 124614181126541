import { useModels } from 'app/api/useModels';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';

export const UpdateParametersAPIDispatcher = () => {
  const { updateModelParameters } = useModels();

  const modelParameters = useAppSelector(
    (state) => state.model.present?.parameters,
  );

  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const [lastParametersPayloadString, setLastParametersPayloadString] =
    React.useState<string | null>(null);

  React.useEffect(() => {
    if (!modelId || !canEditCurrentModelVersion || !modelParameters) return;
    const parametersPayload = modelParameters.reduce(
      (acc, param) => ({
        ...acc,
        [param.name]: { value: param.value },
      }),
      {},
    );
    const parametersPayloadString = JSON.stringify(parametersPayload);

    // Don't do an update if we are initially loading the parameters.
    if (lastParametersPayloadString === null) {
      setLastParametersPayloadString(parametersPayloadString);
      return;
    }

    // Don't do an update if the parameters haven't changed.
    if (parametersPayloadString === lastParametersPayloadString) return;

    updateModelParameters(modelId, parametersPayload);
    setLastParametersPayloadString(parametersPayloadString);
  }, [
    lastParametersPayloadString,
    canEditCurrentModelVersion,
    modelId,
    modelParameters,
    updateModelParameters,
  ]);

  return null;
};
