import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useSubmodel } from 'app/api/useSubmodel';
import { modelActions } from 'app/slices/modelSlice';
import { modelMetadataActions } from 'app/slices/modelMetadataSlice';
import { projectActions } from 'app/slices/projectSlice';
import { clearModelState } from 'app/api/useModelData';
import { ActionCreators as UndoRedoActionCreators } from 'redux-undo';
import { updateModelForSubmodelReferenceChanges } from 'app/utils/modelSubmodelFixupUtils';
import { SubmodelFullUI } from 'app/apiTransformers/convertGetSubmodel';
import {
  transformBackendModelDiagram,
  transformBackendSubmodelConfiguration,
  transformBackendSubmodels,
} from '@collimator/model-schemas-ts';
import { submodelsActions } from 'app/slices/submodelsSlice';
import { SubmodelFetchItem } from 'app/apiGenerated/generatedApiTypes';
import {
  getSubmodelsToFetchFromDiagrams,
  areAllReferencedSubmodelsLoaded,
} from 'app/utils/submodelUtils';

interface SubmodelToProcess {
  submodelFull: SubmodelFullUI;
  referencedSubmodels: SubmodelFetchItem[];
}

const submodelLoadOptions = {
  loadReferencedSubmodelInfos: true,
};

export function useSubmodelData(projectId: string, submodelId: string) {
  const dispatch = useAppDispatch();

  const forceReloadModel = useAppSelector(
    (state) => state.project.forceReloadModel,
  );

  // Store the submodel to process rather than updating the state directly
  // so we can check to make sure the user is still looking at the same
  // submodel after the async delay to retrieve the submodel
  // to make sure we don't update the data if the user has moved on.
  const [submodelToProcess, setSubmodelToProcess] =
    React.useState<SubmodelToProcess | null>(null);

  const idToVersionIdToSubmodelInfo = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelInfo,
  );

  const idToVersionIdToNotFoundReason = useAppSelector(
    (state) => state.submodels.idToVersionIdToNotFoundReason,
  );

  const { submodel, isFetching, refetch } = useSubmodel(
    projectId,
    submodelId,
    submodelLoadOptions,
  );

  const loadLatestModel = React.useCallback(() => {
    clearModelState(dispatch);
    if (refetch) {
      refetch();
    }
  }, [dispatch, refetch]);

  // Reload the submodel if requested.
  React.useEffect(() => {
    if (forceReloadModel) {
      loadLatestModel();
      dispatch(projectActions.clearRequestToReloadModel());
    }
  }, [dispatch, forceReloadModel, loadLatestModel]);

  React.useEffect(() => {
    if (!submodelToProcess) {
      return;
    }

    if (submodelToProcess.submodelFull.id !== submodelId) {
      setSubmodelToProcess(null);
      return;
    }

    const areSubmodelsLoaded = areAllReferencedSubmodelsLoaded(
      idToVersionIdToSubmodelInfo,
      submodelToProcess.referencedSubmodels,
      idToVersionIdToNotFoundReason,
    );
    if (!areSubmodelsLoaded) {
      dispatch(
        submodelsActions.requestLoadSubmodelInfos(
          submodelToProcess.referencedSubmodels,
        ),
      );
      return;
    }

    const transformedDiagram = transformBackendModelDiagram(
      submodelToProcess.submodelFull.diagram,
    );
    const transformedSubmodels = transformBackendSubmodels(
      submodelToProcess.submodelFull.submodels,
    );
    const submodelConfiguration = transformBackendSubmodelConfiguration(
      submodelToProcess.submodelFull.submodelConfiguration,
    );

    // Update reference submodel state for any updates to submodels
    // (ports added or removed, for example)
    updateModelForSubmodelReferenceChanges(
      transformedDiagram,
      transformedSubmodels,
      idToVersionIdToSubmodelInfo,
    );

    // Load new top level submodel state.
    dispatch(
      modelActions.loadSubmodelContent({
        diagram: transformedDiagram,
        parameterDefinitions:
          submodelToProcess.submodelFull.parameterDefinitions,
        portDefinitionsInputs:
          submodelToProcess.submodelFull.portDefinitionsInputs,
        portDefinitionsOutputs:
          submodelToProcess.submodelFull.portDefinitionsOutputs,
        submodels: transformedSubmodels,
        state_machines: submodelToProcess.submodelFull.stateMachines,
        submodelConfiguration,
        name: submodelToProcess.submodelFull.name,
      }),
    );

    dispatch(
      modelMetadataActions.updateOpenModel({
        modelId: submodelToProcess.submodelFull.id,
        editId: submodelToProcess.submodelFull.editId,
        updatedAt: submodelToProcess.submodelFull.updatedAt,
      }),
    );

    // clear out redux undo/redo history when a model is loaded
    // (shouldn't be possible to return to a state before the model is loaded)
    dispatch(UndoRedoActionCreators.clearHistory());

    setSubmodelToProcess(null);
  }, [
    dispatch,
    submodelToProcess,
    submodelId,
    idToVersionIdToSubmodelInfo,
    idToVersionIdToNotFoundReason,
  ]);

  React.useEffect(() => {
    if (!submodel || isFetching) {
      return;
    }

    setSubmodelToProcess({
      submodelFull: submodel,
      referencedSubmodels: getSubmodelsToFetchFromDiagrams(
        submodel.diagram,
        submodel.submodels,
      ),
    });
  }, [dispatch, submodel, submodelId, refetch, isFetching]);

  // Update submodel ports if the underlying reference submodel changes
  // or becomes available.
  React.useEffect(() => {
    if (!idToVersionIdToSubmodelInfo) {
      return;
    }

    dispatch(
      modelActions.updateReferencedSubmodelInstances({
        idToVersionIdToSubmodelInfo,
      }),
    );
  }, [dispatch, idToVersionIdToSubmodelInfo]);
}
