/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Interface: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.00001 2.66666C3.26363 2.66666 2.66667 3.26361 2.66667 3.99999V12C2.66667 12.7364 3.26363 13.3333 4.00001 13.3333H12C12.7364 13.3333 13.3333 12.7364 13.3333 12V3.99999C13.3333 3.26361 12.7364 2.66666 12 2.66666H4.00001ZM12 3.66666H4.00001C3.81591 3.66666 3.66667 3.81589 3.66667 3.99999V5.83332H9.45957L8.97979 5.35354C8.78452 5.15828 8.78452 4.8417 8.97979 4.64644C9.17505 4.45117 9.49163 4.45117 9.68689 4.64644L11.3738 6.33332L9.68689 8.02021C9.49163 8.21547 9.17505 8.21547 8.97979 8.02021C8.78452 7.82495 8.78452 7.50837 8.97979 7.3131L9.45957 6.83332H3.66667V12C3.66667 12.1841 3.81591 12.3333 4.00001 12.3333H12C12.1841 12.3333 12.3333 12.1841 12.3333 12V10.1667H6.54044L7.02023 10.6464C7.21549 10.8417 7.21549 11.1583 7.02023 11.3535C6.82496 11.5488 6.50838 11.5488 6.31312 11.3535L4.62623 9.66666L6.31312 7.97977C6.50838 7.78451 6.82496 7.78451 7.02023 7.97977C7.21549 8.17503 7.21549 8.49161 7.02023 8.68688L6.54044 9.16666H12.3333V3.99999C12.3333 3.81589 12.1841 3.66666 12 3.66666Z"
      fill={props.fill}
    />
  </svg>
);

Interface.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Interface;
