/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const VariableInspector: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.88601 13.18C9.40601 13.18 9.04601 13.03 8.80601 12.73C8.57801 12.418 8.39801 11.968 8.26601 11.38L7.76201 9.328L7.58201 9.04L7.04201 7.204C6.89801 6.7 6.74801 6.31 6.59201 6.034C6.44801 5.758 6.26201 5.566 6.03401 5.458C5.81801 5.35 5.52401 5.296 5.15201 5.296C5.04401 5.296 4.94201 5.302 4.84601 5.314C4.75001 5.326 4.66001 5.338 4.57601 5.35V4.738L7.04201 4.45H7.69001C7.89401 4.702 8.06801 5.008 8.21201 5.368C8.36801 5.716 8.50601 6.136 8.62601 6.628L8.86001 7.744L9.04001 8.122L9.49001 9.598C9.73001 10.342 9.94001 10.882 10.12 11.218C10.312 11.554 10.534 11.722 10.786 11.722C11.026 11.722 11.242 11.632 11.434 11.452C11.626 11.272 11.83 11.032 12.046 10.732L12.478 11.038C12.358 11.242 12.208 11.47 12.028 11.722C11.86 11.962 11.668 12.196 11.452 12.424C11.236 12.64 10.996 12.82 10.732 12.964C10.48 13.108 10.198 13.18 9.88601 13.18ZM4.43201 13.18C4.04801 13.18 3.77201 13.084 3.60401 12.892C3.43601 12.688 3.35201 12.472 3.35201 12.244C3.35201 12.028 3.42401 11.842 3.56801 11.686C3.70001 11.518 3.88001 11.434 4.10801 11.434C4.28801 11.434 4.48001 11.476 4.68401 11.56C4.9 11.632 5.08001 11.668 5.22401 11.668C5.28401 11.668 5.35601 11.65 5.44001 11.614C5.524 11.578 5.63201 11.494 5.76401 11.362C5.90801 11.23 6.07601 11.014 6.26801 10.714L7.63601 8.644L8.03201 9.58H7.72601L6.646 11.434C6.382 11.902 6.08201 12.31 5.74601 12.658C5.422 13.006 4.984 13.18 4.43201 13.18ZM10.174 6.628L8.98601 8.5L8.62601 7.546H8.89601L9.74201 6.088C9.91001 5.788 10.096 5.512 10.3 5.26C10.516 4.996 10.762 4.78 11.038 4.612C11.326 4.444 11.656 4.36 12.028 4.36C12.376 4.36 12.622 4.438 12.766 4.594C12.922 4.738 13 4.942 13 5.206C13 5.482 12.916 5.704 12.748 5.872C12.58 6.028 12.376 6.106 12.136 6.106C11.944 6.106 11.77 6.07 11.614 5.998C11.458 5.926 11.296 5.89 11.128 5.89C10.936 5.89 10.768 5.95 10.624 6.07C10.48 6.19 10.33 6.376 10.174 6.628Z"
      fill={props.fill}
    />
  </svg>
);

VariableInspector.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default VariableInspector;
