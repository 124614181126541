/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Close: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.97979 4.97977C5.17505 4.78451 5.49163 4.78451 5.6869 4.97977L8.00001 7.29289L10.3131 4.97977C10.5084 4.78451 10.825 4.78451 11.0202 4.97977C11.2155 5.17504 11.2155 5.49162 11.0202 5.68688L8.70712 7.99999L11.0202 10.3131C11.2155 10.5084 11.2155 10.825 11.0202 11.0202C10.825 11.2155 10.5084 11.2155 10.3131 11.0202L8.00001 8.7071L5.6869 11.0202C5.49163 11.2155 5.17505 11.2155 4.97979 11.0202C4.78453 10.825 4.78453 10.5084 4.97979 10.3131L7.2929 7.99999L4.97979 5.68688C4.78453 5.49162 4.78453 5.17504 4.97979 4.97977Z"
      fill={props.fill}
    />
  </svg>
);

Close.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Close;
