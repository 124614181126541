import { t } from '@lingui/macro';
import { PortSide } from 'app/common_types/PortTypes';
import { blockClassLookup } from 'app/generated_blocks/';
import { BlockParameterDefinition } from 'app/generated_types/ComputationBlockClass';
import { NodeInstance, Port } from 'app/generated_types/SimulationModel';
import { nodeTypeIsDynamicBlock, nodeTypeIsSubdiagram } from 'app/helpers';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { getPortDisplayName } from 'app/utils/portConditionUtils';
import {
  canAddPort,
  canDeletePort,
  canRenamePort,
} from 'app/utils/portOperationUtils';
import { getDefaultPortParameters } from 'app/utils/portUtils';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import { isValidBlockPortNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import { useVisualizerPrefs } from 'ui/modelEditor/useVisualizerPrefs';
import BlockPortParameters from './BlockPortParameter';
import VisualizerToggler from './Visualizer/VisualizerToggler';

type Props = {
  parentPath: string[];
  selectedNode: NodeInstance;
  canEdit: boolean;
};

const BlockOutportDetails: React.FC<Props> = ({
  parentPath,
  selectedNode,
  canEdit,
}) => {
  const dispatch = useAppDispatch();

  const {
    addPortsToChart,
    removePortsFromChart,
    getAreAllNodePortsInChart,
    getIsPortInChart,
  } = useVisualizerPrefs();
  const blockClass = blockClassLookup(selectedNode.type);

  const minDynamicOutputCount =
    blockClass.ports.outputs?.dynamic?.min_count || 0;

  const canShowAddButton =
    canEdit &&
    !nodeTypeIsSubdiagram(selectedNode.type) &&
    !nodeTypeIsDynamicBlock(selectedNode.type);
  const canAddOutput = canEdit && canAddPort(selectedNode, PortSide.Output);

  // Always show Inputs/Outputs header for Submodels

  const showOutputsHeader =
    canAddOutput ||
    nodeTypeIsSubdiagram(selectedNode.type) ||
    selectedNode.outputs.length > 0;

  const addOutput = React.useCallback(() => {
    const parameters = getDefaultPortParameters(
      blockClass.ports.outputs?.dynamic?.parameter_definitions,
    );
    dispatch(
      modelActions.addPort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Output,
        parameters,
      }),
    );

    // Heuristic to toggle on visualization to this new port if other ports
    // in this block are currently being visualized.
    if (getAreAllNodePortsInChart({ nodeId: selectedNode.uuid, parentPath })) {
      addPortsToChart([
        {
          nodeId: selectedNode.uuid,
          portIndex: selectedNode.outputs.length,
          parentPath,
        },
      ]);
    }
  }, [
    blockClass.ports.outputs?.dynamic?.parameter_definitions,
    dispatch,
    parentPath,
    selectedNode.uuid,
    selectedNode.outputs.length,
    getAreAllNodePortsInChart,
    addPortsToChart,
  ]);

  const removeOutput = (outputId: number) => () => {
    // Stop visualizing the port removing it.
    if (
      getIsPortInChart({
        nodeId: selectedNode.uuid,
        portIndex: outputId,
        parentPath,
      })
    ) {
      removePortsFromChart([
        {
          nodeId: selectedNode.uuid,
          portIndex: outputId,
          parentPath,
        },
      ]);
    }

    // Finally, remove the port itself.
    dispatch(
      modelActions.removePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Output,
        portId: outputId,
      }),
    );
  };

  const renameOutput = (outputId: number, newPortName: string) => {
    dispatch(
      modelActions.renamePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Output,
        portId: outputId,
        newPortName,
      }),
    );
  };

  const getPortKind = (port: Port): 'static' | 'dynamic' | 'conditional' => {
    if (port.kind) return port.kind;
    for (const portDef of blockClass.ports.outputs?.static || []) {
      if (portDef.name === port.name) {
        return 'static';
      }
    }
    for (const portDef of blockClass.ports.outputs?.conditional || []) {
      if (portDef.name === port.name) {
        return 'conditional';
      }
    }
    return 'dynamic';
  };

  const getPortParamDefinitions = (
    port: Port,
    index: number,
  ): BlockParameterDefinition[] | undefined => {
    switch (getPortKind(port)) {
      case 'dynamic':
        return blockClass.ports.outputs?.dynamic?.parameter_definitions;
      case 'conditional':
        return undefined;
      case 'static':
        return blockClass.ports.outputs?.static?.[index]?.parameter_definitions;
    }
  };

  return (
    <>
      {showOutputsHeader && (
        <SectionHeading
          testId="outputs"
          onButtonClick={canShowAddButton ? addOutput : undefined}
          isButtonEnabled={canAddOutput}
          buttonTooltip={t({
            id: 'blockDetails.addOutputButtonTooltip',
            message: 'Add output',
          })}>
          {t({
            id: 'blockDetails.PortOutputsTitle',
            message: 'Outputs',
          })}
        </SectionHeading>
      )}
      {(selectedNode.outputs.length > 0 || canAddOutput) && (
        <DetailInputRowsSection>
          {selectedNode.outputs.map((output, i) => (
            <div key={output.name || `row-output[${i}]`}>
              <DetailsSection>
                <DetailsInput
                  grow
                  testId={`block-output-name-${i}`}
                  value={getPortDisplayName(selectedNode.type, output.name)}
                  placeholder={`Unnamed output ${i}`}
                  onSubmitValue={(newName) => renameOutput(i, newName)}
                  disabled={!canEdit || !canRenamePort(selectedNode)}
                  validationRules={isValidBlockPortNameRuleSet(selectedNode, {
                    id: i,
                    side: PortSide.Output,
                  })}
                />
                <VisualizerToggler
                  nodeId={selectedNode.uuid}
                  parentPath={parentPath}
                  portIndex={i}
                  output={output}
                />
                {canEdit &&
                  canDeletePort(
                    selectedNode,
                    minDynamicOutputCount,
                    output,
                    PortSide.Output,
                  ) && (
                    <Button
                      testId={`block-output-remove-button-${i}`}
                      variant={ButtonVariants.LargeTertiary}
                      Icon={Remove}
                      onClick={removeOutput(i)}
                    />
                  )}
              </DetailsSection>
              <BlockPortParameters
                parentPath={parentPath}
                selectedNode={selectedNode}
                canEdit={canEdit}
                port={output}
                index={i}
                paramDefinitions={getPortParamDefinitions(output, i)}
                portSide={PortSide.Output}
              />
            </div>
          ))}
        </DetailInputRowsSection>
      )}
    </>
  );
};

export default BlockOutportDetails;
