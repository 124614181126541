import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useGetFileReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { BlockInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { cppModelDescription } from 'app/third_party_types/cppBlock-modelDescription';
import React, { useCallback, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { requiredRules } from 'ui/common/Input/inputValidation';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import { SelectInputOption } from 'ui/common/SelectInput';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsLabel,
  DetailsRow,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import { getParameterDisplayName } from 'ui/modelEditor/blockParameterDisplay';
import {
  DynamicVariableCommon,
  orderedExtraParameters,
  updateExtraParameter,
} from 'util/dynamicBlockUtils';
import { useAppParams } from 'util/useAppParams';
import { snakeCaseStringToSentenceCase } from 'util/snakeCaseStringToSentenceCase';
import { CommonBlockParametersDetails } from '../../CommonBlockParameterDetails';
import CppBlockFunctionInput from './CppBlockFunctionInput';

interface Props {
  parentPath: string[];
  node: BlockInstance;
  disabled: boolean;
}

// hard coded, but this param name in the block def should not change.
// we'd still have to select the paramdef based on a string.
const FUNCTION_NAME_PARAM = 'function_name';

/**
 * Quick implementation of a dynamic display for ports and params.
 * Derived from the cpp the user uploads, does not perform extra validation.
 */
const CppBlockParameterDetails: React.FC<Props> = ({
  parentPath,
  node,
  disabled,
}: Props) => {
  const dispatch = useAppDispatch();

  const { projectId } = useAppParams();
  const { project } = useProject();

  // Find file UUID here for now. Existing param dropdown doesn't save file UUID.
  const projectFiles =
    project?.files.filter(
      (file) => !file.error && !(file.status === 'processing_in_progress'),
    ) || [];

  // File selection
  const fileName = node.parameters.file_name?.value || ''; // unselected file has value empty string, but types don't know that.
  const fileUuid = projectFiles.find(
    (projectFile) => projectFile.name === fileName,
  )?.uuid;
  const [prevfileName, setPrevfileName] = useState(fileName);
  // Only fetch the cpp file properties on a selection change. Else, the list of function_names has already been updated.
  const fileSelectionChanged = fileName !== prevfileName;

  // Function selection
  const currentFunction = node.parameters[FUNCTION_NAME_PARAM]?.value || '';
  const { data: fileData, isFetching } = useGetFileReadByUuidQuery(
    {
      projectUuid: projectId || '',
      fileUuid: fileUuid || '',
    },
    {
      skip: !projectId || !fileUuid,
    },
  );
  // Function dropdown from the cpp file properties.
  const blockDefinitions = (
    fileData ? (fileData.properties as cppModelDescription) : undefined
  )?.blockDefinitions;
  const cppFunctionOptions: SelectInputOption[] | undefined =
    blockDefinitions?.map((blockDefinition) => ({
      value: blockDefinition.name,
      label: blockDefinition.name,
    }));

  const extraParameterNames = orderedExtraParameters(node);

  // Reset block on file_name param change since the selected function_name may not be valid anymore.
  useEffect(() => {
    if (fileSelectionChanged) {
      // With the most up to date file info
      if (fileData && !isFetching) {
        setPrevfileName(fileName);

        dispatch(
          modelActions.changeBlockParameter({
            parentPath,
            nodeUuid: node.uuid,
            paramName: FUNCTION_NAME_PARAM,
            value: '',
          }),
        );
        dispatch(modelActions.resetDynamicBlock({ parentPath, node }));
      }
    }
  }, [
    dispatch,
    extraParameterNames,
    fileData,
    fileName,
    fileSelectionChanged,
    isFetching,
    parentPath,
    node,
  ]);

  // Reconfigure the block on function_name param select.
  const reconfigureBlock = useCallback(
    (function_name: string) => {
      if (!blockDefinitions) return;
      const modelVariables =
        blockDefinitions.find(
          (blockDefinition) => blockDefinition.name === function_name,
        )?.modelVariables || [];

      const dynamicVariables: DynamicVariableCommon[] = modelVariables.map(
        ({ cname, causality }) => ({
          id: cname,
          causality,
        }),
      );

      batch(() => {
        dispatch(
          modelActions.changeBlockParameter({
            parentPath,
            nodeUuid: node.uuid,
            paramName: FUNCTION_NAME_PARAM,
            value: function_name,
          }),
        );
        dispatch(modelActions.resetDynamicBlock({ parentPath, node }));
        dispatch(
          modelActions.configureDynamicBlock({
            parentPath,
            node,
            dynamicVariables,
          }),
        );
      });
    },
    [blockDefinitions, dispatch, node, parentPath],
  );

  return (
    <>
      <SectionHeading testId="cpp-parameter-definition-details">
        {t({
          id: 'blockDetails.cppBlockParametersTitle',
          message: 'Parameters',
        })}
      </SectionHeading>
      <CommonBlockParametersDetails
        parentPath={parentPath}
        selectedNode={node}
        canEdit={!disabled}
      />
      {cppFunctionOptions && !isFetching && (
        <CppBlockFunctionInput
          displayName={snakeCaseStringToSentenceCase(FUNCTION_NAME_PARAM)}
          currentValue={currentFunction}
          onSelectValue={reconfigureBlock}
          options={cppFunctionOptions}
        />
      )}
      <DetailInputRowsSection>
        {extraParameterNames.map((paramName) => {
          const param = node.parameters[paramName];
          const valueKey = `extra-param-value-${paramName}`;
          const paramValue = param?.value || '';
          return (
            <DetailsSection key={paramName} vertical>
              <DetailsLabel>{paramName}</DetailsLabel>
              <DetailsRow>
                <DetailsInput
                  grow
                  testId={valueKey}
                  value={paramValue}
                  onSubmitValue={updateExtraParameter(
                    dispatch,
                    parentPath,
                    node,
                    paramName,
                  )}
                  disabled={disabled}
                  hasBorder
                  allowMultiline
                  validationRules={requiredRules}
                />
              </DetailsRow>
            </DetailsSection>
          );
        })}
      </DetailInputRowsSection>
    </>
  );
};

export default CppBlockParameterDetails;
