import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import {
  PythonModel,
  usePythonExecutor,
  usePythonModelCallback,
} from './PythonHooks';
import { EditorMode, useModelEditorInfo } from './useModelEditorInfo';

const IMPORT_PYCOLLIMATOR = `from pycollimator.model_builder import core, to_json
from pycollimator.model_builder.core import *
from pycollimator.model_builder.model import ModelBuilder, OPort, IPort
`;

const GET_JSON_MODEL = `import json
json_model = None
if 'model_builder' in locals() and isinstance(model_builder, ModelBuilder):
    model_builder.set_names_from_locals(locals())
    ids_to_uuids = None
    ids_to_uiprops = None
    if inputs.ids_to_uuids:
        ids_to_uuids = deserialize(inputs.ids_to_uuids)
    if inputs.ids_to_uiprops:
        ids_to_uiprops = deserialize(inputs.ids_to_uiprops)
    json_obj = to_json.render_model(
        model_builder,
        ids_to_uuids=ids_to_uuids,
        uiprops=ids_to_uiprops,
        is_submodel=inputs.is_submodel)
    json_model = json.dumps(json_obj)
    del model_builder
`;

const GET_JSON_MODEL_V2 = `import json
json_model = None
if 'model_builder' in locals() and isinstance(model_builder, ModelBuilder):
    ids_to_uuids = None
    ids_to_uiprops = None
    if inputs.ids_to_uuids:
        ids_to_uuids = deserialize(inputs.ids_to_uuids)
    if inputs.ids_to_uiprops:
        ids_to_uiprops = deserialize(inputs.ids_to_uiprops)
    json_obj = to_json.render_model(
        model_builder,
        ids_to_uuids=ids_to_uuids,
        uiprops=ids_to_uiprops,
        is_submodel=inputs.is_submodel)
    json_model = json.dumps(json_obj)
    del model_builder
`;

export const MODEL_BUILDER = `
model_builder = ModelBuilder()

def add_block(*args, **kwargs):
    return model_builder.add_block(*args, **kwargs)

def remove_block(*args, **kwargs):
    return model_builder.remove_block(*args, **kwargs)

def add_link(*args, **kwargs):
    return model_builder.add_link(*args, **kwargs)

def remove_link(*args, **kwargs):
    return model_builder.remove_link(*args, **kwargs)

def add_parameter(*args, **kwargs):
    return model_builder.add_parameter(*args, **kwargs)

def update_parameter(*args, **kwargs):
    return model_builder.update_parameter(*args, **kwargs)

def add_group(*args, **kwargs):
    return model_builder.add_group(*args, **kwargs)

def add_reference_submodel(*args, **kwargs):
    return model_builder.add_reference_submodel(*args, **kwargs)
`;

export const usePythonToJsonConverter = () => {
  const { getCurrentModelInPython } = usePythonModelCallback();

  const { showError } = useNotifications();
  const executePython = usePythonExecutor();

  const { isModelReadOnly } = useModelEditorInfo();

  const { chatFunctionsV2 } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const convertPythonToJson = React.useCallback(
    async (code: string, isNewModel: boolean, editorMode: EditorMode) => {
      if (isModelReadOnly) {
        throw new Error(
          'The editor is in read-only mode. Switch to edit mode to update the model.',
        );
      }
      if (!code) {
        throw new Error('code is empty');
      }

      let pyModel: PythonModel | undefined;
      let codePrefix = MODEL_BUILDER;
      if (!isNewModel) {
        try {
          pyModel = await getCurrentModelInPython({
            output_groups: true,
            output_submodels: true,
          });
        } catch (e) {
          console.error(e);
          showError(`${e}`);
          throw new Error('Internal error: could not retrieve current model.');
        }

        if (!pyModel?.pythonStr) {
          showError(`Current model is empty.`);
          throw new Error('Internal error: could not retrieve current model.');
        }
        codePrefix = `${codePrefix}\n${pyModel.pythonStr}`;
      }

      const getJsonModel = chatFunctionsV2 ? GET_JSON_MODEL_V2 : GET_JSON_MODEL;

      const { results, error: pyerror } = await executePython({
        code: `${codePrefix}\n${code}\n${getJsonModel}`,
        inputs: {
          ids_to_uuids: pyModel?.ids_to_uuids,
          ids_to_uiprops: pyModel?.ids_to_uiprops,
          is_submodel: editorMode === EditorMode.Submodel,
        },
        importStatements: IMPORT_PYCOLLIMATOR,
        returnVariableNames: ['json_model'],
      });
      if (pyerror) {
        throw new Error(`${pyerror}`);
      }
      if (results) {
        const resultsMap = results as Map<string, unknown>;
        return resultsMap.get('json_model') as string;
      }
      throw new Error('Unexpected error: result could not be found.');
    },
    [
      chatFunctionsV2,
      executePython,
      getCurrentModelInPython,
      isModelReadOnly,
      showError,
    ],
  );

  return convertPythonToJson;
};
