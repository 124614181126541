import { t } from '@lingui/macro';
import { useGetSimulationLogFileReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { readTimeModeList } from 'app/generated_types/CmlTypes';
import { useAppDispatch } from 'app/hooks';
import {
  TimeModeType,
  compilationAnalysisDataActions,
} from 'app/slices/compilationAnalysisDataSlice';
import React from 'react';
import {
  matchesExpectedError,
  useNotifications,
} from 'ui/common/notifications/useNotifications';

const NO_TIME_MODE_DATA_MESSAGE = 'Time mode assignments not generated';

export function useTimeModeData(
  modelId: string,
  correlationId: string,
  simulationId: string,
) {
  const dispatch = useAppDispatch();
  const { showError } = useNotifications();

  const { data: timeData, error: timeDataError } =
    useGetSimulationLogFileReadByUuidQuery({
      modelUuid: modelId,
      simulationUuid: simulationId,
      simulationJsonLogFile: 'time_mode_block',
      'X-Correlation-ID': correlationId,
    });

  React.useEffect(() => {
    if (!timeData) return;

    try {
      const safeTimeData = readTimeModeList(timeData);
      if (safeTimeData instanceof Array) {
        const allDiscreteStepValsSet = new Set<number>();
        for (let i = 0; i < safeTimeData.length; i++) {
          const dataPoint = safeTimeData[i];
          const timeMode = dataPoint.time_mode || '';
          if (timeMode.kind === 'Discrete') {
            allDiscreteStepValsSet.add(dataPoint.discrete_step || 0);
          }
        }

        const discreteStepsOrderedByLevel = Array.from(
          allDiscreteStepValsSet,
        ).sort();
        const discreteStepsLevelMap = discreteStepsOrderedByLevel.reduce<{
          [stepVal: number]: number;
        }>(
          (acc, stepVal, i) => ({
            ...acc,
            [stepVal]: i,
          }),
          {},
        );

        const massagedTimeData: { [blockNamePath: string]: TimeModeType } =
          safeTimeData.reduce((acc, dataPoint) => {
            const pathName = dataPoint.path;
            const rawMode = dataPoint.time_mode.kind;
            const mode =
              rawMode === 'Continuous' ||
              rawMode === 'Discrete' ||
              rawMode === 'Constant'
                ? rawMode
                : 'Unknown';

            const discreteStep = (dataPoint.discrete_step as number) || 0;
            const nodeTimeData: TimeModeType =
              mode === 'Discrete'
                ? {
                    mode,
                    stepLevel: discreteStepsLevelMap[discreteStep] || 0,
                    discreteStep,
                  }
                : { mode };

            return {
              ...acc,
              [pathName]: nodeTimeData,
            };
          }, {});

        dispatch(
          compilationAnalysisDataActions.setDiscreteSteps({
            discreteStepsLevelMap,
            discreteStepsOrderedByLevel,
          }),
        );
        dispatch(
          compilationAnalysisDataActions.setAllNodeTimeModeData({
            simulationId,
            timeData: massagedTimeData,
          }),
        );
      }
    } catch (e) {
      console.error('Problem with simulation time ("data rates") data: ', e);
    }
  }, [dispatch, simulationId, timeData]);

  React.useEffect(() => {
    if (simulationId && timeDataError) {
      if (matchesExpectedError(timeDataError, NO_TIME_MODE_DATA_MESSAGE)) {
        showError(
          t({
            id: 'entityPreferencesApi.noTimeModeDataAvailableError',
            message:
              'No signal data available from simulation. Time mode assignments not generated.',
          }),
          null,
          { isExpectedError: true },
        );
      } else {
        showError(
          t({
            id: 'entityPreferencesApi.getTimeModeDataError',
            message: 'Unable to get time mode data from simulation.',
          }),
          timeDataError,
        );
      }
      dispatch(
        compilationAnalysisDataActions.clearSimulationCache({
          simulationId,
        }),
      );
    }
  }, [dispatch, showError, simulationId, timeDataError]);
}
