import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { printNameToSpacedDisplayName } from 'app/helpers';
import { useAppSelector } from 'app/hooks';
import React, { ReactElement } from 'react';
import { Small } from '../common/typography/Typography';

export type BlockColorOverride = {
  blockBg?: string;
  blockBorder?: string;
};

const BlockComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BlockBody = styled.div<{
  selected: boolean;
  overrideColors?: BlockColorOverride;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 2px;
  background-color: ${({ theme, overrideColors }) =>
    overrideColors?.blockBg || theme.colors.ui.blockBackground};
  font-family: 'Archivo', sans-serif;
  border: 1.5px solid
    ${({ theme, overrideColors }) =>
      overrideColors?.blockBorder || theme.colors.grey[30]};

  > img {
    pointer-events: none;
    user-select: none;
  }
`;

const BlockInnerLabel = styled.div`
  width: 80%;
  max-height: 80%;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
`;

enum PortLabelSide {
  Left,
  Right,
}

const PortLabelContainer = styled.div<{ side: PortLabelSide }>`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  ${({ side, theme }) =>
    side === PortLabelSide.Left
      ? `
      left: ${theme.spacing.normal};
    `
      : `
      right: ${theme.spacing.normal};
      align-items: flex-end;
    `}
`;
const PortLabel = styled.label`
  display: none;
  justify-content: center;
  align-items: center;
  height: 1px;
  font-size: 12px;
`;

const baseHandleStyle = css`
  position: absolute;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 50%;

  > .port-icon {
    position: absolute;
    pointer-events: none;
  }
`;

const inputHandleCss = ({ connected }: { connected?: boolean }) => css`
  ${baseHandleStyle}
  height: 10px;
  width: 5px;
  left: -1px;

  > .port-icon {
    top: 1px;
    left: 1px;
    width: 4px;
    height: 8px;
    background-image: url(${process.env
      .PUBLIC_URL}/assets/block_input_left.svg);

    ${connected
      ? `
        top: 0;
        left: 0;
        width: 5px;
        height: 10px;
        background-image: url(${process.env.PUBLIC_URL}/assets/block_input_left_dark.svg);
      `
      : ''}
  }

  :hover > .port-icon {
    top: 0;
    left: 0;
    width: 5px;
    height: 10px;
    opacity: 0.5;
    background-image: url(${process.env
      .PUBLIC_URL}/assets/block_input_left_dark.svg);

    ${connected ? 'opacity: 1;' : ''}
  }
`;

const FakeInputHandle = styled.div<{ connected?: boolean }>`
  ${inputHandleCss}
  transform: translateY(-50%);
`;

const outputHandleCss = ({ connected }: { connected?: boolean }) => css`
  ${baseHandleStyle}
  height: 8px;
  width: 8px;
  right: -4px;

  > .port-icon {
    width: 4px;
    height: 8px;
    background-image: url(${process.env
      .PUBLIC_URL}/assets/block_output_right.svg);

    ${connected
      ? `
        width: 8px;
        background-image: url(${process.env.PUBLIC_URL}/assets/block_output_dark.svg);
      `
      : ''}
  }

  :hover > .port-icon {
    width: 8px;
    background-image: url(${process.env
      .PUBLIC_URL}/assets/block_output_dark.svg);
  }
`;

const FakeOutputHandle = styled.div<{ connected?: boolean }>`
  ${outputHandleCss}
  transform: translateY(-50%);
`;

const BlockNameFloatingLabel = styled(Small)`
  margin-top: ${(props) => props.theme.spacing.small};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex-shrink: 0;
`;

const topOffset = (offsetScalar: number) => `${100 * offsetScalar}%`;

interface Props {
  nodeId: string;
  incount: number;
  outcount: number;
  nodeTypePrintName: string;
  blockIconId: string;
  connectedIDs?: Set<string>;
  name?: string;
  overrideColors?: BlockColorOverride;
}

const BlockComponent: React.FC<Props> = ({
  nodeId,
  incount,
  outcount,
  nodeTypePrintName,
  blockIconId,
  connectedIDs,
  name,
  overrideColors,
}): ReactElement => {
  const [selectedBlockId] = useAppSelector(
    (state) => state.model.present.selectedBlockIds,
  );

  const thisBlockSelected = nodeId === selectedBlockId;

  const inputHandles = [];
  const outputHandles = [];

  const InputHandle = FakeInputHandle;
  const OutputHandle = FakeOutputHandle;

  for (let i = 0; i < incount; i++) {
    const handleConnected =
      connectedIDs && connectedIDs.has(`${nodeId}_in${i}`);

    inputHandles.push(
      <InputHandle
        key={`input-handle-${i}`}
        id={`${i}`}
        connected={handleConnected}
        style={{
          top: topOffset((i + 1) / (incount + 1)),
        }}>
        <div className="port-icon" />
      </InputHandle>,
    );
  }

  for (let i = 0; i < outcount; i++) {
    const handleConnected =
      connectedIDs && connectedIDs.has(`${nodeId}_out${i}`);
    outputHandles.push(
      <OutputHandle
        key={`output-handle-${i}`}
        id={`${i}`}
        connected={handleConnected}
        style={{
          top: topOffset((i + 1) / (outcount + 1)),
        }}>
        <div className="port-icon" />
      </OutputHandle>,
    );
  }

  const inputLabels = [];
  const outputLabels = [];
  for (let i = 0; i < incount; i++) {
    inputLabels.push(<PortLabel key={`input-label-${i}`}>{i}</PortLabel>);
  }

  for (let i = 0; i < outcount; i++) {
    outputLabels.push(<PortLabel key={`output-label-${i}`}>{i}</PortLabel>);
  }

  const [hasIcon, setHasIcon] = React.useState(true);

  const onError = () => setHasIcon(false);

  return (
    <BlockComponentContainer>
      <BlockBody selected={thisBlockSelected} overrideColors={overrideColors}>
        <img
          alt=""
          onError={onError}
          src={`${process.env.PUBLIC_URL}/assets/block_icon_svgs/${
            hasIcon ? blockIconId : 'generic'
          }.svg`}
        />
        <PortLabelContainer side={PortLabelSide.Left}>
          {inputLabels}
        </PortLabelContainer>
        <PortLabelContainer side={PortLabelSide.Right}>
          {outputLabels}
        </PortLabelContainer>
        {inputHandles}
        {outputHandles}
      </BlockBody>
      <BlockNameFloatingLabel>
        {printNameToSpacedDisplayName(name || nodeTypePrintName)}
      </BlockNameFloatingLabel>
    </BlockComponentContainer>
  );
};

export default BlockComponent;
