import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useGetSimulationProcessResultsReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { SimulationResultsS3Url } from 'app/apiGenerated/generatedApiTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { HoverEntityType } from 'app/common_types/SegmentTypes';
import { ModelDiagram } from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { getSimulationRef } from 'app/sliceRefAccess/SimulationRef';
import {
  modelActions,
  selectCurrentSubdiagramType,
} from 'app/slices/modelSlice';
import {
  DiagramFooterTab,
  NavbarContext,
  uiFlagsActions,
} from 'app/slices/uiFlagsSlice';
import { getLinkTreeParent } from 'app/utils/linkUtils';
import { renderConstants } from 'app/utils/renderConstants';
import React, { useEffect, useMemo, useState } from 'react';
import { getThemeValue } from 'theme/themes';
import { Constant, Continuous, Discrete } from 'ui/common/Icons/Small';
import Input from 'ui/common/Input/Input';
import { isValidBlockNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import { SimpleTextTooltip } from 'ui/common/Tooltip/PortalTextTooltip';
import { ManualTooltip } from 'ui/common/Tooltip/PortalTooltip';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { RCContextMenu } from 'ui/modelEditor/RightClickMenu';
import { getPortPathName } from 'ui/modelEditor/portPathNameUtils';
import { LINK_COLORS } from 'ui/modelRendererInternals/drawLink';
import { getVisualNodeHeight } from 'ui/modelRendererInternals/getVisualNodeHeight';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { nvgColorToHex } from 'util/nvgColorHexConvert';
import { demuxPortForTracePaths } from 'util/portTypeUtils';
import { CommandPalette } from './CommandPalette';
import { SimTutorialPopup } from './SimTutorialPopup';

const RendererOverlayContainer = styled.div`
  isolation: isolate;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
`;

const WorldSpaceOverlayOuterContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
`;
const WorldSpaceOverlayScaler = styled.div<{
  scale: number;
}>`
  ${({ scale }) => `
    transform: scale(${scale});
  `}

  position: absolute;
  pointer-events: none;
`;

const WorldSpaceOverlayPosAnchor = styled.div<{ x: number; y: number }>`
  ${({ x, y }) => `
    transform: translate(${x}px, ${y}px);
  `}

  position: absolute;
  height: 0;
  width: 0;
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
`;

const SignalPreviewWrapper = styled.div`
  height: 80px;
  aspect-ratio: 7/1;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `
  border-radius: ${theme.spacing.xsmall};
  background-color: ${theme.colors.grey[5]};
  color: ${theme.colors.grey[85]};
  margin-bottom: ${theme.spacing.normal};
  `}
`;

const SignalPreviewImage = styled.img`
  ${({ theme }) => `
  border-radius: ${theme.spacing.xsmall};`}
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const SignalTooltipText = styled.div`
  display: flex;
  flex-direction: column;
`;

const SignalTooltipIconContainer = styled.div`
  display: flex;
`;

const SignalTooltipFinalValue = styled.div`
  font-weight: 300;
`;

const SignalTooltipContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EntityNameEditInputWrapper = styled.div<{
  entityX: number;
  entityY: number;
  entityWidth?: number;
  entityHeight: number;
  labelTop: boolean;
}>`
  ${({ entityX: x, entityY: y, entityWidth, entityHeight, labelTop }) => `
    width: ${(entityWidth || renderConstants.BLOCK_MIN_WIDTH) + 20}px;
    transform: translate(${x - 10}px, ${
    labelTop ? y - 28 : y + entityHeight
  }px);
  `}
`;

function getNode(
  currentDiagram: ModelDiagram | null,
  editingNodeNameUUID: string | null,
) {
  if (!editingNodeNameUUID) return null;

  if (!currentDiagram) return null;

  return (
    currentDiagram.nodes.find((n) => n.uuid === editingNodeNameUUID) || null
  );
}

const NodeNameEditInput = ({
  isDiagramReadonly,
}: {
  isDiagramReadonly: boolean;
}) => {
  const dispatch = useAppDispatch();

  const editingNodeNameUUID = useAppSelector(
    (state) => state.uiFlags.editingNodeNameUUID,
  );

  const currentDiagram = useAppSelector(
    (state) => state.modelMetadata.currentDiagram,
  );
  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );

  const node = getNode(currentDiagram, editingNodeNameUUID);
  const currentModelNodes = currentDiagram?.nodes;

  const onSubmitValue = (newName: string) => {
    if (!editingNodeNameUUID || isDiagramReadonly) return;

    // TODO update block name in table
    dispatch(
      modelActions.changeBlockName({
        parentPath: currentSubmodelPath,
        blockUuid: editingNodeNameUUID,
        newName,
      }),
    );

    dispatch(uiFlagsActions.setUIFlag({ editingNodeNameUUID: null }));
  };

  const onCancel = () => {
    dispatch(uiFlagsActions.setUIFlag({ editingNodeNameUUID: null }));
  };

  if (!node) return null;

  return (
    <EntityNameEditInputWrapper
      entityX={node.uiprops.x}
      entityY={node.uiprops.y}
      entityHeight={getVisualNodeHeight(node)}
      labelTop={node.uiprops.label_position === 'top'}>
      <Input
        autoFocus
        value={node.name}
        onSubmitValue={onSubmitValue}
        onCancel={onCancel}
        disabled={isDiagramReadonly}
        validationRules={isValidBlockNameRuleSet(currentModelNodes, node.uuid)}
      />
    </EntityNameEditInputWrapper>
  );
};

function getAnnotation(
  currentDiagram: ModelDiagram | null,
  editingAnnotationTextUUID: string | null,
) {
  if (!editingAnnotationTextUUID) return null;

  if (!currentDiagram) return null;

  return (
    (currentDiagram.annotations || []).find(
      (a) => a.uuid === editingAnnotationTextUUID,
    ) || null
  );
}

function fetchFinalValueFromCsv(url: string): Promise<string> {
  return url
    ? fetch(url)
        .then((response) => response.text())
        .then((data) => {
          // extract final row from csv string
          const csvRows = data.split('\n');
          let finalRow = '';
          for (let i = csvRows.length - 1; i >= 0 && !finalRow; i--) {
            if (csvRows[i].length) {
              finalRow = csvRows[i];
            }
          }
          return finalRow.split(',')[1] ?? '';
        })
    : new Promise((resolve) => {
        resolve('');
      });
}

const AnnoNameEditInput = ({
  isDiagramReadonly,
}: {
  isDiagramReadonly: boolean;
}) => {
  const dispatch = useAppDispatch();

  const editingAnnoNameUUID = useAppSelector(
    (state) => state.uiFlags.editingAnnotationTextUUID,
  );

  const currentDiagram = useAppSelector(
    (state) => state.modelMetadata.currentDiagram,
  );
  const annotation = getAnnotation(currentDiagram, editingAnnoNameUUID);

  const onSubmitValue = (newText: string) => {
    if (!editingAnnoNameUUID || isDiagramReadonly) return;

    dispatch(
      modelActions.setAnnotationText({
        uuid: editingAnnoNameUUID,
        text: newText,
      }),
    );

    dispatch(uiFlagsActions.setUIFlag({ editingAnnotationTextUUID: null }));
  };

  const onCancel = () => {
    dispatch(uiFlagsActions.setUIFlag({ editingAnnotationTextUUID: null }));
  };

  if (!annotation) return null;

  return (
    <EntityNameEditInputWrapper
      entityX={annotation.x}
      entityY={annotation.y}
      entityWidth={annotation.grid_width * renderConstants.GRID_SIZE}
      entityHeight={annotation.grid_height * renderConstants.GRID_SIZE}
      labelTop={annotation.label_position === 'top'}>
      <Input
        autoFocus
        value={annotation.text}
        disabled={isDiagramReadonly}
        onSubmitValue={onSubmitValue}
        onCancel={onCancel}
      />
    </EntityNameEditInputWrapper>
  );
};

const OverlaySignalTooltip = () => {
  const contentEl = React.useRef<HTMLDivElement>(null);
  const contentRect = contentEl?.current?.getBoundingClientRect();
  const theme = useTheme();

  const [[mouseX, mouseY], setMousePos] = React.useState<
    [x: number, y: number]
  >([0, 0]);

  const { showSignalPreview } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const { simulationSummary } = useAppSelector((state) => ({
    simulationSummary: state.project.simulationSummary,
  }));

  React.useEffect(() => {
    let moverRAF = -1;
    const mover = (e: MouseEvent) => {
      window.cancelAnimationFrame(moverRAF);
      window.requestAnimationFrame(() => setMousePos([e.x, e.y]));
    };

    window.addEventListener('mousemove', mover);

    return () => window.removeEventListener('mousemove', mover);
  }, [setMousePos]);

  // for signal thumbnail
  const signalPath = React.useRef<string>();
  const {
    data: files,
    isFetching: isSignalPreviewUrlFetching,
    isSuccess: isSignalPreviewUrlSuccess,
  } = useGetSimulationProcessResultsReadByUuidQuery(
    {
      threshold: 2500,
      downsamplingAlgorithm: 'LTTB',
      modelUuid: simulationSummary?.model_uuid || '',
      simulationUuid: simulationSummary?.uuid || '',
      files: signalPath.current,
      genCharts: true,
    },
    {
      skip:
        !simulationSummary ||
        !simulationSummary.results_available ||
        !signalPath.current,
    },
  );

  // ! FIXME with a real endpoint for preview images.
  let signalPreviewUrl: SimulationResultsS3Url | undefined;
  let signalDataUrlArr: string[] = useMemo(() => [], []);
  if (!isSignalPreviewUrlFetching && isSignalPreviewUrlSuccess) {
    // Check for 'combined' demux preview since only the name differentiates individual vs combined preview.
    const demuxedSignalPreviewUrl = files.s3_urls.find(
      (s3_url) => s3_url.name === 'combined_graph_data.png',
    );
    if (demuxedSignalPreviewUrl) {
      signalPreviewUrl = demuxedSignalPreviewUrl;
    } else {
      // Requested only one signal result, so grab the image url.
      signalPreviewUrl = files.s3_urls.find((s3_url) =>
        s3_url.name.endsWith('png'),
      );
      // assume any other signal result must be csv
      signalDataUrlArr = files.s3_urls
        .filter((s3_url) => !s3_url.name.endsWith('png'))
        .map((urlObj) => urlObj.url ?? '')
        .filter((url) => url);
    }
  }

  const [result, setResult] = useState({ urlHash: '', finalValue: '' });
  useEffect(() => {
    (async () => {
      const newUrlHash = signalDataUrlArr.reduce((url, hash) => url + hash, '');
      if (newUrlHash !== result.urlHash) {
        if (signalDataUrlArr.length > 1) {
          const finalValArr = await Promise.all(
            signalDataUrlArr
              .map((url) => fetchFinalValueFromCsv(url))
              .reverse(),
          );
          const vectorStr = `[${finalValArr
            .reduce((finalVal, acc) => `${acc}, ${finalVal}`, '')
            .slice(0, -2)}]`;
          setResult({ urlHash: newUrlHash, finalValue: vectorStr });
        } else {
          const finalValue = await fetchFinalValueFromCsv(
            signalDataUrlArr?.[0],
          );
          setResult({ urlHash: newUrlHash, finalValue });
        }
      }
    })();
  }, [signalDataUrlArr, result.urlHash]);

  if (
    !rendererState ||
    !(
      rendererState.hoveringEntity?.entityType ===
        HoverEntityType.FakeLinkSegment ||
      rendererState.hoveringEntity?.entityType === HoverEntityType.Link ||
      rendererState.hoveringEntity?.entityType === HoverEntityType.Port ||
      rendererState.hoveringEntity?.entityType === HoverEntityType.TapPoint
    )
  ) {
    signalPath.current = undefined;
    return null;
  }

  let hoveringLinkId: string | undefined;
  let originNodeUuid = '';
  let outputId = -1;

  switch (rendererState.hoveringEntity.entityType) {
    case HoverEntityType.TapPoint:
      const linkUuid = rendererState.hoveringEntity.linkUuid;
      const linkIndex = rendererState.refs.current.linksIndexLUT[linkUuid];
      const link = rendererState.refs.current.links[linkIndex];
      if (link && link.src) {
        originNodeUuid = link.src.node;
        outputId = link.src.port;
      }
      hoveringLinkId = linkUuid;
      break;
    case HoverEntityType.FakeLinkSegment:
    case HoverEntityType.Link:
      if (rendererState.hoveringEntity.link.src) {
        originNodeUuid = rendererState.hoveringEntity.link.src.node;
        outputId = rendererState.hoveringEntity.link.src.port;
      }
      hoveringLinkId = rendererState.hoveringEntity.linkUuid;
      break;
    case HoverEntityType.Port:
      const port = rendererState.hoveringEntity.port;
      const connections =
        rendererState.refs.current.connectedPortLUT[port.blockUuid];

      if (port.side === PortSide.Output && connections) {
        originNodeUuid = port.blockUuid;
        outputId = port.portId;

        const connection = connections.find(
          (p) => p.side === PortSide.Output && p.portId === port.portId,
        );
        const linkIndex =
          rendererState.refs.current.linksIndexLUT[connection?.linkUuid || ''];
        const connectedLink = rendererState.refs.current.links[linkIndex];

        if (connectedLink) {
          hoveringLinkId = connectedLink.uuid;
        }
      }

      if (port.side === PortSide.Input && connections) {
        const connection = connections.find(
          (p) => p.side === PortSide.Input && p.portId === port.portId,
        );
        const linkIndex =
          rendererState.refs.current.linksIndexLUT[connection?.linkUuid || ''];
        const connectedLink = rendererState.refs.current.links[linkIndex];

        if (connectedLink && connectedLink.src) {
          hoveringLinkId = connectedLink.uuid;
          originNodeUuid = connectedLink.src.node;
          outputId = connectedLink.src.port;
        }
      }
      break;
  }

  const hoveringLinkIndex =
    rendererState.refs.current.linksIndexLUT[hoveringLinkId || ''];
  const hoveringLink = rendererState.refs.current.links[hoveringLinkIndex];

  const linkTreeParent = hoveringLink
    ? getLinkTreeParent(hoveringLink.uuid)
    : undefined;

  const originNodeIndex =
    rendererState.refs.current.nodesIndexLUT[originNodeUuid];
  const originNode = rendererState.refs.current.nodes[originNodeIndex];

  if (!originNode) {
    signalPath.current = undefined;
    return null;
  }

  // For displaying to user
  const portPathName = getPortPathName(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    {
      parentPath: getCurrentModelRef().submodelPath,
      nodeId: originNodeUuid,
      portIndex: outputId,
    },
    { includePortNameForNonSubmodels: false },
  );

  // For requesting signal results from backend
  const fullPortPathName =
    getPortPathName(
      getCurrentModelRef().topLevelNodes,
      getCurrentModelRef().submodels,
      {
        parentPath: getCurrentModelRef().submodelPath,
        nodeId: originNodeUuid,
        portIndex: outputId,
      },
      { includePortNameForNonSubmodels: true },
    ) || undefined;

  const { timeMode, datatypeAndDimensions } = (portPathName &&
    getSimulationRef().compilationData.signalsData[portPathName]) || {
    timeMode: undefined,
    datatypeAndDimensions: undefined,
  };
  const signalType = datatypeAndDimensions && datatypeAndDimensions[outputId];

  signalPath.current =
    signalType &&
    fullPortPathName &&
    demuxPortForTracePaths(fullPortPathName, outputId).tracePaths?.join(',');

  const LabelIcon = {
    Discrete,
    Continuous,
    Constant,
    Unknown: Continuous,
  }[timeMode?.mode || 'Unknown'];
  const fade = timeMode?.mode === 'Unknown' || !timeMode;

  const tooltipLabelName =
    linkTreeParent && linkTreeParent.name
      ? linkTreeParent.name
      : originNode.outputs[outputId] !== undefined
      ? `${originNode?.name}.${originNode?.outputs[outputId].name}`
      : 'No name';

  const signalPreviewImg = (
    <SignalPreviewWrapper>
      {isSignalPreviewUrlFetching ? (
        <div>
          {t({
            id: 'rendererOverlay.signalPreview.loading',
            message: 'Loading signal preview...',
          })}
        </div>
      ) : !isSignalPreviewUrlSuccess || !!signalPreviewUrl?.error ? (
        <div>
          {t({
            id: 'rendererOverlay.signalPreview.unavailable',
            message: 'Preview unavailable',
          })}
        </div>
      ) : (
        signalPreviewUrl && <SignalPreviewImage src={signalPreviewUrl.url} />
      )}
    </SignalPreviewWrapper>
  );

  const hasSignalPreview =
    showSignalPreview && !!simulationSummary && !!signalPath.current;

  const tooltipLabelContent = (
    <SignalTooltipContentWrapper>
      {hasSignalPreview && signalPreviewImg}
      <SignalTooltipText>
        <SignalTooltipIconContainer>
          <LabelIcon
            fill={timeMode?.mode === 'Discrete' ? undefined : '#fff'}
            stroke={timeMode?.mode === 'Discrete' ? '#fff' : undefined}
            opacity={fade ? 0.4 : 1}
          />
          {tooltipLabelName}
        </SignalTooltipIconContainer>
        <SignalTooltipFinalValue>
          Final Value:{' '}
          {isSignalPreviewUrlFetching || !result.finalValue
            ? 'is loading...'
            : result.finalValue}
        </SignalTooltipFinalValue>
      </SignalTooltipText>
    </SignalTooltipContentWrapper>
  );

  const tooltipTop =
    mouseY - (contentRect?.height || 0) - getThemeValue(theme.spacing.normal);
  const tooltipLeft = mouseX - (contentRect?.width || 0) / 2;

  return (
    <ManualTooltip
      isHidden={!contentEl.current}
      delayMs={400}
      isShowingPointer
      invertedColor
      uninteractable
      top={tooltipTop}
      left={tooltipLeft}
      contentElRef={contentEl}
      placement={TooltipPlacement.TOP_CENTER}
      triggerRect={contentRect}
      contentRect={contentRect}>
      <SimpleTextTooltip invertedColor>{tooltipLabelContent}</SimpleTextTooltip>
    </ManualTooltip>
  );
};

const ColorLegendContainer = styled.div<{
  sidebarOpen: boolean;
  footerOpen: boolean;
}>(({ theme, sidebarOpen, footerOpen }) => ({
  pointerEvents: 'none',
  position: 'absolute',
  bottom: footerOpen ? '50%' : theme.sizes.footerBarHeight,
  left: sidebarOpen ? theme.sizes.leftSidebarWidth : 0,
  padding: theme.spacing.normal,
  marginLeft: theme.spacing.normal,
  marginBottom: footerOpen
    ? -1 *
      (getThemeValue(theme.sizes.navbarHeight) / 2 -
        getThemeValue(theme.spacing.normal))
    : theme.spacing.normal,
  background: 'rgba(255, 255, 255, 0.4)',
  transition:
    'left 0.15s ease-in, bottom 0.15s ease-in, margin-bottom 0.15s ease-in',
}));

const ColorLegendItem = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 500,
  height: 16,
  marginBottom: theme.spacing.small,
  '&:last-child': { marginBottom: 0 },
}));

const ColorLegendColor = styled.div<{ color: string }>(({ theme, color }) => ({
  borderRadius: 1,
  height: 8,
  width: 24,
  background: `#${color}`,
  marginRight: theme.spacing.normal,
}));

const ColorLegendName = styled.div(({ theme }) => ({
  color: theme.colors.text.secondary,
}));

const discreteLabel = t({ id: 'colorLegend.discrete', message: 'Discrete' });
const getDiscreteLegendColor = (level: number) => {
  const linkColorKey = `discrete_${level + 1}` as keyof typeof LINK_COLORS;
  return LINK_COLORS[linkColorKey] || LINK_COLORS.discrete_1;
};

const iteratorLegendColors = [
  {
    color: nvgColorToHex(LINK_COLORS.iterator),
    name: t({ id: 'colorLegend.iterator', message: 'Iterator' }),
  },
];

const legendColors = (discreteSteps: Array<number>) => ({
  rates: [
    {
      color: nvgColorToHex(LINK_COLORS.constant),
      name: t({ id: 'colorLegend.constant', message: 'Constant' }),
    },
    {
      color: nvgColorToHex(LINK_COLORS.continuous),
      name: t({ id: 'colorLegend.continuous', message: 'Continuous' }),
    },
    ...(discreteSteps.length > 0
      ? discreteSteps.map((stepVal, i) => ({
          color: nvgColorToHex(getDiscreteLegendColor(i)),
          name: `${discreteLabel} ${stepVal}`,
        }))
      : [
          {
            color: nvgColorToHex(LINK_COLORS.discrete_1),
            name: discreteLabel,
          },
        ]),
  ],
  dimensions: [
    {
      color: nvgColorToHex(LINK_COLORS.scalar),
      name: t({ id: 'colorLegend.scalar', message: 'Scalar' }),
    },
    {
      color: nvgColorToHex(LINK_COLORS.vector),
      name: t({ id: 'colorLegend.vector', message: 'Vector' }),
    },
    {
      color: nvgColorToHex(LINK_COLORS.matrix),
      name: t({ id: 'colorLegend.matrix', message: 'Matrix' }),
    },
    {
      color: nvgColorToHex(LINK_COLORS.tensor),
      name: t({ id: 'colorLegend.tensor', message: 'Tensor' }),
    },
  ],
});

const ColorLegend = ({
  legendStyle,
}: {
  legendStyle: 'rates' | 'dimensions';
}) => {
  const { isLeftSidebarOpen, navbarContext } = useAppSelector((state) => ({
    isLeftSidebarOpen: state.uiFlags.isLeftSidebarOpen,
    navbarContext: state.uiFlags.navbarContext,
  }));

  const currentSubdiagramType = useAppSelector((state) =>
    selectCurrentSubdiagramType(state.model.present),
  );
  const isIterator = currentSubdiagramType === 'core.Iterator';

  const discreteSteps = useAppSelector(
    (state) => state.compilationData.discreteStepsOrderedByLevel,
  );

  const isFooterOpen = useAppSelector((state) => {
    if (navbarContext === NavbarContext.ModelEditor) {
      return state.uiFlags.diagramFooterTab !== DiagramFooterTab.None;
    }
    return false;
  });

  const activeLegendColors = isIterator
    ? iteratorLegendColors
    : legendColors(discreteSteps)[legendStyle];

  return (
    <ColorLegendContainer
      sidebarOpen={isLeftSidebarOpen}
      footerOpen={isFooterOpen}>
      {activeLegendColors.map(({ color, name }) => (
        <ColorLegendItem key={name}>
          <ColorLegendColor color={color} />
          <ColorLegendName>{name}</ColorLegendName>
        </ColorLegendItem>
      ))}
    </ColorLegendContainer>
  );
};

export const RendererOverlay = ({
  isDiagramReadonly,
}: {
  isDiagramReadonly: boolean;
}): React.ReactElement => {
  const { zoom, coord } = useAppSelector((state) => state.camera);
  const {
    showSignalNamesInModel,
    showDatatypesInModel,
    showRatesInModel,
    showingCommandPalette,
  } = useAppSelector((state) => ({
    showSignalNamesInModel: state.uiFlags.showSignalNamesInModel,
    showDatatypesInModel: state.uiFlags.showDatatypesInModel,
    showRatesInModel: state.uiFlags.showRatesInModel,
    showingCommandPalette: state.uiFlags.showingCommandPalette,
    commandPaletteCoordScreenSpace:
      state.uiFlags.commandPaletteCoordScreenSpace,
  }));

  return (
    <RendererOverlayContainer>
      <WorldSpaceOverlayOuterContainer>
        <WorldSpaceOverlayScaler scale={zoom}>
          <WorldSpaceOverlayPosAnchor x={coord.x} y={coord.y}>
            <NodeNameEditInput isDiagramReadonly={isDiagramReadonly} />
            <AnnoNameEditInput isDiagramReadonly={isDiagramReadonly} />
          </WorldSpaceOverlayPosAnchor>
        </WorldSpaceOverlayScaler>
      </WorldSpaceOverlayOuterContainer>
      <RCContextMenu />
      {(showRatesInModel || showDatatypesInModel) && (
        <ColorLegend legendStyle={showRatesInModel ? 'rates' : 'dimensions'} />
      )}

      {showSignalNamesInModel && <OverlaySignalTooltip />}

      {showingCommandPalette && <CommandPalette />}
      <SimTutorialPopup />
    </RendererOverlayContainer>
  );
};
