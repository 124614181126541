import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  TraceMetadata,
  CellRow,
  PlotCellMetadata,
  CellMetadata,
  TextCellMetadata,
  ImageCellMetadata,
} from 'ui/dataExplorer/dataExplorerTypes';
import { GLOBAL_ENTITY } from 'ui/userPreferences/globalEntity';
import {
  ModelEditorLayoutPrefsV1,
  MODEL_EDITOR_LAYOUT_PREFS_V1_KEY,
} from 'ui/userPreferences/modelEditorLayoutPrefs';
import { ModelEditorModelPrefsV1 } from 'ui/userPreferences/modelEditorModelPrefs';
import {
  TutorialPrefsV1,
  TUTORIAL_PREFS_V1_KEY,
} from 'ui/userPreferences/tutorialPrefs';
import {
  VisualizerPrefsV1,
  VISUALIZER_PREFS_V1_KEY,
} from 'ui/userPreferences/visualizerPrefs';
import { updateVisualizerPrefs } from 'util/updateVisualizerPrefs';

// Union preference type:
// This type represents the type of the preference that the backend
// will return for a specific matching entity key.
// When we add another entity key, add the corresponding preference type to this union type.
export type EntityPreferences =
  | ModelEditorLayoutPrefsV1
  | ModelEditorModelPrefsV1
  | VisualizerPrefsV1
  | TutorialPrefsV1;

export interface EntityPreferencesState {
  prefKeyToEntityIdToPrefs: Record<string, Record<string, EntityPreferences>>;
  prefKeyToEntityIdToPrefsToSave: Record<string, Record<string, boolean>>;
}

export const initialState: EntityPreferencesState = {
  prefKeyToEntityIdToPrefs: {},
  prefKeyToEntityIdToPrefsToSave: {},
};

function clearPreferenceToSave(
  state: EntityPreferencesState,
  preferencesKey: string,
  entityId: string,
) {
  if (state.prefKeyToEntityIdToPrefsToSave[preferencesKey]) {
    delete state.prefKeyToEntityIdToPrefsToSave[preferencesKey][entityId];
  }
}

function ensurePreferenceKey(
  prefKeyToData: Record<string, Record<string, unknown>>,
  preferencesKey: string,
) {
  if (!prefKeyToData[preferencesKey]) {
    prefKeyToData[preferencesKey] = {};
  }
}

function setPrefsFromUser(
  state: EntityPreferencesState,
  details: {
    preferencesKey: string;
    entityId: string;
    prefs: EntityPreferences;
  },
) {
  const { preferencesKey, entityId, prefs } = details;
  if (!preferencesKey || !entityId || !prefs) {
    return;
  }

  ensurePreferenceKey(state.prefKeyToEntityIdToPrefs, preferencesKey);
  state.prefKeyToEntityIdToPrefs[preferencesKey][entityId] = prefs;

  ensurePreferenceKey(state.prefKeyToEntityIdToPrefsToSave, preferencesKey);
  state.prefKeyToEntityIdToPrefsToSave[preferencesKey][entityId] = true;
}

function markPrefsSetByUser(
  state: EntityPreferencesState,
  details: {
    preferencesKey: string;
    entityId: string;
  },
) {
  const { preferencesKey, entityId } = details;
  if (!preferencesKey || !entityId) {
    return;
  }
  ensurePreferenceKey(state.prefKeyToEntityIdToPrefsToSave, preferencesKey);
  state.prefKeyToEntityIdToPrefsToSave[preferencesKey][entityId] = true;
}

const entityPreferencesSlice = createSlice({
  name: 'entityPreferencesSlice',
  initialState,
  reducers: {
    // Do not use the internal redux actions yourself. Those are for the hook.
    // useEntityPreferences already handles data flow between react, redux, and backend for you.
    // Add your entity preference specific fns to the reducer here instead.
    _setPrefsFromServer(
      state,
      action: PayloadAction<{
        preferencesKey: string;
        entityId: string | undefined;
        prefs: EntityPreferences;
      }>,
    ) {
      const { preferencesKey, entityId, prefs } = action.payload;
      if (!preferencesKey || !entityId) {
        return;
      }
      if (!prefs) {
        console.error(
          'Preferences from server should never be undefined or null. If unset in backend, set as empty object to differentiate from not loaded.',
        );
        return;
      }

      ensurePreferenceKey(state.prefKeyToEntityIdToPrefs, preferencesKey);
      state.prefKeyToEntityIdToPrefs[preferencesKey][entityId] = prefs;

      // Since we are updating to the server value, make sure we don't overwrite the
      // server values with outdated local values.
      clearPreferenceToSave(state, preferencesKey, entityId);
    },
    _onPrefsUpdateSentToServer(
      state,
      action: PayloadAction<{
        preferencesKey: string;
        entityId: string;
      }>,
    ) {
      const { preferencesKey, entityId } = action.payload;
      clearPreferenceToSave(state, preferencesKey, entityId);
    },
    onUserChangedBottomPanelHeight(
      state,
      action: PayloadAction<{ height: number }>,
    ) {
      const { height } = action.payload;
      if (!height) {
        return;
      }
      ensurePreferenceKey(
        state.prefKeyToEntityIdToPrefs,
        MODEL_EDITOR_LAYOUT_PREFS_V1_KEY,
      );
      (
        state.prefKeyToEntityIdToPrefs[MODEL_EDITOR_LAYOUT_PREFS_V1_KEY][
          GLOBAL_ENTITY
        ] as ModelEditorLayoutPrefsV1
      ).bottomPanelHeight = height;

      markPrefsSetByUser(state, {
        preferencesKey: MODEL_EDITOR_LAYOUT_PREFS_V1_KEY,
        entityId: GLOBAL_ENTITY,
      });
    },
    onUserChangedVisualizer(
      state,
      action: PayloadAction<{
        modelId: string;
        cellRowIds: string[];
        idToCellRow: Record<string, CellRow>;
        idToCell: Record<string, CellMetadata>;
        idToPlotCell: Record<string, PlotCellMetadata>;
        idToTextCell: Record<string, TextCellMetadata>;
        idToImageCell: Record<string, ImageCellMetadata>;
        idToTrace: Record<string, TraceMetadata>;
      }>,
    ) {
      const { modelId } = action.payload;
      if (!modelId) {
        return;
      }

      ensurePreferenceKey(
        state.prefKeyToEntityIdToPrefs,
        VISUALIZER_PREFS_V1_KEY,
      );

      const visualizerPrefs = <VisualizerPrefsV1>(
        state.prefKeyToEntityIdToPrefs[VISUALIZER_PREFS_V1_KEY][modelId]
      );

      // Update the prefs based on all the options.
      // FIXME: added if: https://collimator.atlassian.net/browse/UI-1172
      if (visualizerPrefs) {
        updateVisualizerPrefs(visualizerPrefs, action);
      }

      markPrefsSetByUser(state, {
        preferencesKey: VISUALIZER_PREFS_V1_KEY,
        entityId: modelId,
      });
    },
    onUserSawBasicTutorial(state) {
      ensurePreferenceKey(
        state.prefKeyToEntityIdToPrefs,
        TUTORIAL_PREFS_V1_KEY,
      );

      const baseTutorialPrefs =
        state.prefKeyToEntityIdToPrefs[TUTORIAL_PREFS_V1_KEY];

      if (!baseTutorialPrefs[GLOBAL_ENTITY]) {
        (baseTutorialPrefs[GLOBAL_ENTITY] as TutorialPrefsV1) = {
          seenBasicSimTutorial: true,
        };
      } else {
        (
          baseTutorialPrefs[GLOBAL_ENTITY] as TutorialPrefsV1
        ).seenBasicSimTutorial = true;
      }

      markPrefsSetByUser(state, {
        preferencesKey: TUTORIAL_PREFS_V1_KEY,
        entityId: GLOBAL_ENTITY,
      });
    },
    onUserSawInitialTutorial(state) {
      ensurePreferenceKey(
        state.prefKeyToEntityIdToPrefs,
        TUTORIAL_PREFS_V1_KEY,
      );

      const baseTutorialPrefs =
        state.prefKeyToEntityIdToPrefs[TUTORIAL_PREFS_V1_KEY];

      if (!baseTutorialPrefs[GLOBAL_ENTITY]) {
        (baseTutorialPrefs[GLOBAL_ENTITY] as TutorialPrefsV1) = {
          seenInitialSimTutorial: true,
        };
      } else {
        (
          baseTutorialPrefs[GLOBAL_ENTITY] as TutorialPrefsV1
        ).seenInitialSimTutorial = true;
      }

      markPrefsSetByUser(state, {
        preferencesKey: TUTORIAL_PREFS_V1_KEY,
        entityId: GLOBAL_ENTITY,
      });
    },
  },
});

export const entityPreferencesActions = entityPreferencesSlice.actions;

export const selectEntityPrefs = createSelector(
  [
    (state: RootState) => state.entityPreferences.prefKeyToEntityIdToPrefs,
    (state, preferencesKey: string, entityId) => preferencesKey,
    (state, preferencesKey, entityId: string | undefined) => entityId,
  ],
  (prefKeyToEntityIdToPrefs, preferencesKey, entityId) => {
    if (!preferencesKey) {
      console.error('You must provide a non empty preferencesKey.');
      return null;
    }
    if (!entityId) {
      // not initialized
      // component does not have necessary the state to fetch the pref values
      return null;
    }

    const prefs = prefKeyToEntityIdToPrefs[preferencesKey];
    if (prefs) {
      return prefs[entityId];
    }
    // not intialized
    // server fetch has not finished yet, hence the missing entry in the store
    return null;
  },
);

export const _selectEntityPrefNeedsSaving = createSelector(
  [
    (state: RootState) =>
      state.entityPreferences.prefKeyToEntityIdToPrefsToSave,
    (state, preferencesKey: string, entityId) => preferencesKey,
    (state, preferencesKey, entityId: string | undefined) => entityId,
  ],
  (prefKeyToEntityIdToPrefsToSave, preferencesKey, entityId) => {
    if (!preferencesKey || !entityId) {
      return false;
    }

    const prefs = prefKeyToEntityIdToPrefsToSave[preferencesKey];
    if (prefs) {
      return prefs[entityId];
    }
    return false;
  },
);

export default entityPreferencesSlice;
