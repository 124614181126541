import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useAppParams } from 'util/useAppParams';
import { ReadonlyControls } from './ReadonlyControls';
import { SimulationControls } from './SimulationControls';

export const ModelEditorControls: React.FC = () => {
  const { versionId } = useAppParams();

  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  if (!versionId) {
    if (topLevelModelType === 'Model' || topLevelModelType === 'Experiment') {
      return <SimulationControls />;
    }
    return null;
  }

  return <ReadonlyControls />;
};
