import { LogLine, SimulationSummary } from 'app/apiGenerated/generatedApiTypes';
import {
  CompilationErrors,
  readCompilationErrors,
} from 'app/generated_types/CmlTypes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { errorsActions } from 'app/slices/errorsSlice';
import { SimulationResultType, projectActions } from 'app/slices/projectSlice';
import {
  ModelLogLine,
  OutputLogLevel,
  simResultsActions,
} from 'app/slices/simResultsSlice';
import { DiagramFooterTab, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { fetchSimulationLogs } from 'ui/modelEditor/utils';
import { useAppParams } from 'util/useAppParams';

function hasLogErrors(
  simulationLogs?: ModelLogLine[],
  compilationLogs?: ModelLogLine[],
) {
  let hasCompilationErrors = false;
  let hasSimulationErrors = false;
  if (simulationLogs) {
    hasSimulationErrors = simulationLogs.some(
      (log) =>
        log.level === OutputLogLevel.ERR || log.level === OutputLogLevel.FATAL,
    );
  }
  if (compilationLogs) {
    hasCompilationErrors = compilationLogs.some(
      (log) =>
        log.level === OutputLogLevel.ERR || log.level === OutputLogLevel.FATAL,
    );
  }
  return hasCompilationErrors || hasSimulationErrors;
}

export interface SimulationResult {
  modelId?: string;
  correlationId?: string;
  summary: SimulationSummary;
  logs?: ModelLogLine[];
}

function parseAtdErrors(atd_error: any): CompilationErrors {
  if (!atd_error) return [];
  try {
    return readCompilationErrors(atd_error);
  } catch (e) {
    console.error('Error parsing ATD error', e);
    return [];
  }
}

function parseAllAtdErrorsFromLogs(logs: LogLine[]): CompilationErrors {
  const errors: CompilationErrors = [];
  for (let k = 0; k < logs.length; k++) {
    const parsedAtdError = parseAtdErrors(logs[k].atd_error);
    errors.push(...parsedAtdError);
  }
  return errors;
}

export function useModelSimulationResults() {
  const dispatch = useAppDispatch();

  const [simulationResult, setSimulationResult] =
    React.useState<SimulationResult | null>(null);

  const { modelId } = useAppParams() || '';

  const correlationId = useAppSelector((state) => state.project.correlationId);

  const modelSimulationState = useAppSelector(
    (state) => state.project.modelSimulationState,
  );
  const needToRequestSimulationResults = useAppSelector(
    (state) => state.project.needToRequestSimulationResults,
  );
  const { simulationSummary, simulationSummaryType } = useAppSelector(
    (state) => state.project,
  );
  const diagramFooterTab = useAppSelector(
    (state) => state.uiFlags.diagramFooterTab,
  );
  const hasVisualizedSignals = useAppSelector(
    (state) => state.dataExplorer.cellRowIds.length > 0,
  );
  const modelState = useAppSelector((state) => state.model.present);
  const autoSwitchBottomTabOnSimDone = useAppSelector(
    (state) => state.project.autoSwitchBottomTabOnSimDone,
  );

  const { showInfo, createShowError, showError } = useNotifications();

  // If we are looking at the same model when final simulation results arrive,
  // display the simulation results.
  React.useEffect(() => {
    if (simulationResult) {
      if (
        simulationResult.modelId === modelId &&
        simulationResult.correlationId === correlationId
      ) {
        dispatch(simResultsActions.setSimulationResult(simulationResult));
        dispatch(projectActions.checkOrRunFinished(simulationResult.summary));

        const logs =
          simulationResult.logs || simulationResult.summary.compilation_logs;
        const parsedAtdErrors = parseAllAtdErrorsFromLogs(logs || []);
        dispatch(errorsActions.setAtdCompilationErrors(parsedAtdErrors));

        if (!autoSwitchBottomTabOnSimDone) {
          return;
        }

        if (
          parsedAtdErrors.length > 0 ||
          hasLogErrors(
            simulationResult.logs,
            simulationResult.summary.compilation_logs,
          )
        ) {
          // If there are errors, switch to the console if we aren't already there
          // in order to see the error details.
          dispatch(uiFlagsActions.setDiagramFooterTab(DiagramFooterTab.Output));
        } else if (
          simulationSummaryType === SimulationResultType.CompileAndRun &&
          hasVisualizedSignals
        ) {
          // If the simulation is successful, switch to the visualizer if we
          // aren't already there in order to see the results.
          dispatch(
            uiFlagsActions.setDiagramFooterTab(DiagramFooterTab.Visualizer),
          );
        }
      }
      setSimulationResult(null);
    }
  }, [
    dispatch,
    modelId,
    simulationResult,
    correlationId,
    showInfo,
    hasVisualizedSignals,
    diagramFooterTab,
    modelSimulationState,
    simulationSummaryType,
    autoSwitchBottomTabOnSimDone,
  ]);

  // When the simulation completes, retrieve the simulation logs.
  React.useEffect(() => {
    async function fetch() {
      if (needToRequestSimulationResults && modelId) {
        if (simulationSummary && !simulationResult) {
          switch (simulationSummary.status) {
            case 'completed':
            case 'failed':
              let logs: ModelLogLine[] = [];
              try {
                // Fetch logs.txt. It should almost always be present unless there
                // was an early catastrophic failure in simworker.
                logs = await fetchSimulationLogs(simulationSummary);
              } catch (e) {
                console.error(e);
              }

              const simResults: SimulationResult = {
                modelId,
                correlationId,
                logs,
                summary: simulationSummary,
              };
              setSimulationResult(simResults);

              break;
          }
        }
        dispatch(projectActions.clearNeedToRequestSimulationResults());
      }
    }
    fetch();
  }, [
    dispatch,
    showInfo,
    simulationSummary,
    correlationId,
    createShowError,
    showError,
    modelState,
    modelId,
    simulationResult,
    needToRequestSimulationResults,
    simulationSummaryType,
  ]);
}
