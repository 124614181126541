import { Clerk } from '@clerk/types';
import { NavigateFunction } from 'react-router-dom';

declare global {
  interface Window {
    Clerk: Clerk;
  }
}

export function getUserName() {
  if (process.env.REACT_APP_API_ENDPOINT?.startsWith('http://localhost')) {
    // For localhost, we don't have any specific user. Our local jupyterhub is
    // configured with its root at /hub/user/test/. The user name returned
    // by this function will be used in API calls to /hub/user/{user}/ which
    // matches the expected base url from our local jupyterhub.
    return 'test';
  }

  const user = window.Clerk.user;
  let username: string;
  if (!user) {
    return null;
  }
  username = user.id.toLocaleLowerCase();
  return username;
}

export function getUserDisplayName() {
  const user = window.Clerk.user;
  return user?.fullName;
}

export function getUserEmail() {
  const user = window.Clerk.user;
  return user?.primaryEmailAddress?.emailAddress;
}

export function getUserProfileImageUrl() {
  const user = window.Clerk.user;
  return user?.profileImageUrl;
}

// The drift between `isAdminUser` and the `user.has_ops_access` in DB bit me while debugging.
// Migrate to only using backend flag. `DbUserProfileToApiResponse` and `userSlice`.
// - 2023/05/01 jay
// This is cheap "security", not meant to actually protect data,
// only to redirect unwanted users back to where they belong.
export function isAdminUser(): boolean {
  const user = window.Clerk?.user;
  return (
    !!user?.emailAddresses?.find(
      (email) =>
        email.emailAddress.endsWith('@collimator.ai') ||
        email.emailAddress.endsWith('dn789@cairn.edu'),
    ) || false
  );
}

export const PRE_SIGN_IN_PATH_LSTORAGE_KEY = 'pre-sign-in-path';

export function redirectAfterSignin(navigate: NavigateFunction) {
  const path = window.localStorage.getItem(PRE_SIGN_IN_PATH_LSTORAGE_KEY);
  navigate(path || '/');
}
