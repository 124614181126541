import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useUpdateSubmodel } from 'app/api/useUpdateSubmodel';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { isValidModelOrSubmodelName } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import DescriptionField from 'ui/modelEditor/DescriptionField';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsLabel,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';

interface Props {
  projectId: string;
  submodelId: string;
  canEdit: boolean;
}

const ReferenceSubmodelDiagramDetails: React.FC<Props> = ({
  projectId,
  submodelId,
  canEdit,
}) => {
  const dispatch = useAppDispatch();

  const editId = useAppSelector((state) => state.modelMetadata.editId);

  const { updateSubmodelDescription } = useUpdateSubmodel();

  const { project } = useProject();
  const submodels: SubmodelInfoLiteUI[] | undefined = useAppSelector(
    (state) => state.submodels.projectIdToSubmodelInfoLites[projectId],
  );
  const validationRules = React.useMemo(
    () =>
      project ? isValidModelOrSubmodelName(submodelId, project, submodels) : [],
    [submodels, submodelId, project],
  );

  const submodel = submodels?.find((submodel) => submodel.id === submodelId);

  const saveDescription = React.useCallback(
    (newDescription: string) => {
      if (submodel) {
        updateSubmodelDescription({
          projectId,
          submodelId,
          newDescription,
          editId: String(editId),
        });
      }
    },
    [updateSubmodelDescription, submodelId, projectId, editId, submodel],
  );

  const onNameChange = React.useCallback(
    (newName: string) => {
      if (canEdit) {
        dispatch(modelActions.updateOpenModelName(newName));
      }
    },
    [canEdit, dispatch],
  );

  if (!submodel) {
    return null;
  }

  return (
    <>
      <SectionHeading testId="model">
        {t({
          id: 'modelRenderer.propertiesSidebar.referencedSubmodel.heading',
          message: 'Submodel',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        <DetailsSection>
          <DetailsLabel>
            {t({
              id: 'modelRenderer.propertiesSidebar.referenceSubmodelName.label',
              message: 'Name',
            })}
          </DetailsLabel>
          <DetailsInput
            onSubmitValue={onNameChange}
            value={submodel.name}
            disabled={!canEdit}
            validationRules={validationRules}
          />
        </DetailsSection>
        <DescriptionField
          testId="reference-submodel-description-textarea"
          placeholder={t({
            id: 'modelRenderer.propertiesSidebar.referencedSubmodelDescription.placeholder',
            message: 'Describe this submodel and its purpose',
          })}
          savedDescription={submodel.description || ''}
          saveDescription={saveDescription}
          canEdit={canEdit}
        />
      </DetailInputRowsSection>
    </>
  );
};

export default ReferenceSubmodelDiagramDetails;
