import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { simulationSignalsActions } from 'app/slices/simulationSignalsSlice';
import React, { useState } from 'react';

interface Props {
  modelId: string;
}

export const VisualizationTraceSimulationTracker: React.FC<Props> = ({
  modelId,
}) => {
  const dispatch = useAppDispatch();

  const simulationSummary = useAppSelector(
    (state) => state.project.simulationSummary,
  );

  const [prevSimId, setPrevSimId] = useState(simulationSummary?.uuid);

  const modelIdToVersionIdToModelData = useAppSelector(
    (state) => state.modelVersions.modelIdToVersionIdToModelData,
  );

  const signalsData = useAppSelector(
    (state) => state.compilationData.signalsData,
  );

  const modelIdToVersionIdRequestData = useAppSelector(
    (state) => state.modelVersions.modelIdToVersionIdRequestData,
  );
  const submodelsToFetch = useAppSelector(
    (state) => state.submodels.submodelsToFetch,
  );

  // Update the mapping of simulation id to model version id when model version id
  // becomes available.
  React.useEffect(() => {
    if (simulationSummary && simulationSummary.snapshot_uuid) {
      dispatch(
        simulationSignalsActions.onSimulationModelVersionLoaded({
          simulationId: simulationSummary.uuid,
          modelVersionId: simulationSummary.snapshot_uuid,
        }),
      );
    }
  }, [dispatch, simulationSummary]);

  // When a new simulation runs, update the data explorer to show the new simulation data.
  React.useEffect(() => {
    // Wait for model and submodel data to be fetched before fetching traces.
    if (Object.values(modelIdToVersionIdRequestData).length > 0) {
      return;
    }
    if (submodelsToFetch.length > 0) {
      return;
    }

    if (
      modelId &&
      simulationSummary &&
      !simulationSummary.compile_only &&
      simulationSummary.status === 'completed' &&
      signalsData &&
      Object.values(signalsData).length > 0
    ) {
      // Skip if simulation hasn't changed. Means it won't refresh if sim is cached either.
      if (prevSimId === simulationSummary.uuid) {
        return;
      }
      setPrevSimId(simulationSummary.uuid);
      dispatch(
        dataExplorerActions.newSimulationRunCompleted({
          simulationId: simulationSummary.uuid,
          modelIdToVersionIdToModelData,
        }),
      );
    }
  }, [
    dispatch,
    simulationSummary,
    modelId,
    modelIdToVersionIdToModelData,
    signalsData,
    submodelsToFetch,
    modelIdToVersionIdRequestData,
    prevSimId,
  ]);

  return null;
};
