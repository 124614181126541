/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CreateConditional: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.25 6C4.25 5.0335 5.03351 4.25 6 4.25H17C17.9665 4.25 18.75 5.0335 18.75 6V13C18.75 13.4142 18.4142 13.75 18 13.75C17.5858 13.75 17.25 13.4142 17.25 13V6C17.25 5.86193 17.1381 5.75 17 5.75H6C5.86193 5.75 5.75 5.86193 5.75 6V17C5.75 17.1381 5.86193 17.25 6 17.25H13C13.4142 17.25 13.75 17.5858 13.75 18C13.75 18.4142 13.4142 18.75 13 18.75H6C5.03351 18.75 4.25 17.9665 4.25 17V6Z"
      fill={props.fill}
    />
    <path
      d="M18 15.25C18.4142 15.25 18.75 15.5858 18.75 16V17.25H20C20.4142 17.25 20.75 17.5858 20.75 18C20.75 18.4142 20.4142 18.75 20 18.75H18.75V20C18.75 20.4142 18.4142 20.75 18 20.75C17.5858 20.75 17.25 20.4142 17.25 20V18.75H16C15.5858 18.75 15.25 18.4142 15.25 18C15.25 17.5858 15.5858 17.25 16 17.25H17.25V16C17.25 15.5858 17.5858 15.25 18 15.25Z"
      fill={props.fill}
    />
    <path
      d="M7 10.5C7 10.2239 7.22386 10 7.5 10H10.5C10.7761 10 11 10.2239 11 10.5V13C11 13.2761 10.7761 13.5 10.5 13.5H7.5C7.22386 13.5 7 13.2761 7 13V10.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 10.5C12 10.2239 12.2239 10 12.5 10H15.5C15.7761 10 16 10.2239 16 10.5V13C16 13.2761 15.7761 13.5 15.5 13.5H12.5C12.2239 13.5 12 13.2761 12 13V10.5ZM13 12.5V11H15V12.5H13Z"
      fill={props.fill}
    />
  </svg>
);

CreateConditional.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CreateConditional;
