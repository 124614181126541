/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Statemachine: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6C4 4.89543 4.89543 4 6 4C7.10457 4 8 4.89543 8 6Z"
      fill={props.fill}
    />
    <path
      d="M28 26C28 27.1046 27.1046 28 26 28C24.8954 28 24 27.1046 24 26C24 24.8954 24.8954 24 26 24C27.1046 24 28 24.8954 28 26Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.5 11.5V13.5H19.5V11.5H10.5ZM10 10C9.44772 10 9 10.4477 9 11V14C9 14.5523 9.44772 15 10 15H20C20.5523 15 21 14.5523 21 14V11C21 10.4477 20.5523 10 20 10H10Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.5 20.5V22.5H19.5V20.5H10.5ZM10 19C9.44772 19 9 19.4477 9 20V23C9 23.5523 9.44772 24 10 24H20C20.5523 24 21 23.5523 21 23V20C21 19.4477 20.5523 19 20 19H10Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15 14.5001C15.2761 14.5001 15.5 14.724 15.5 15.0001H14.5C14.5 14.724 14.7239 14.5001 15 14.5001ZM15.5 17.2929V15.0001H14.5V17.2929L13.8536 16.6465C13.6583 16.4512 13.3417 16.4512 13.1465 16.6465C12.9512 16.8417 12.9512 17.1583 13.1464 17.3536L14.6464 18.8536C14.7402 18.9474 14.8674 19.0001 15 19.0001C15.1326 19.0001 15.2598 18.9474 15.3536 18.8536L16.8536 17.3536C17.0488 17.1583 17.0488 16.8417 16.8535 16.6465C16.6583 16.4512 16.3417 16.4512 16.1464 16.6465L15.5 17.2929Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.6464 26.3536C14.5527 26.2598 14.5 26.1326 14.5 26V24C14.5 23.7239 14.7239 23.5 15 23.5C15.2761 23.5 15.5 23.7239 15.5 24V25.5H22.2929L21.6464 24.8536C21.4511 24.6583 21.4511 24.3417 21.6464 24.1465C21.8416 23.9512 22.1582 23.9512 22.3535 24.1464L23.8535 25.6464C23.9473 25.7402 24 25.8674 24 26C24 26.1326 23.9473 26.2598 23.8535 26.3536L22.3535 27.8536C22.1582 28.0488 21.8416 28.0488 21.6464 27.8535C21.4511 27.6583 21.4511 27.3417 21.6464 27.1464L22.2929 26.5H15.0007C14.8681 26.5 14.7402 26.4473 14.6464 26.3536Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15.0788 5.5L15.0788 6C15.5788 5.99997 15.5788 5.99998 15.5788 5.99998L15.5789 8.33542L16.3135 7.63753C16.5137 7.44733 16.8302 7.45543 17.0204 7.65563C17.2106 7.85583 17.2025 8.17231 17.0023 8.36251L15.4233 9.86259C15.2303 10.0459 14.9275 10.0459 14.7346 9.86259L13.1556 8.36251C12.9554 8.17231 12.9473 7.85583 13.1375 7.65563C13.3277 7.45543 13.6442 7.44733 13.8444 7.63753L14.5789 8.33537L14.5789 6.5L8 6.50002C7.72386 6.50002 7.5 6.27616 7.5 6.00002C7.5 5.72388 7.72386 5.50002 8 5.50002L15.0788 5.5ZM15.0788 6L15.5788 5.99998C15.5788 5.72385 15.3549 5.5 15.0788 5.5L15.0788 6Z"
      fill={props.fill}
    />
  </svg>
);

Statemachine.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Statemachine;
