import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ChevronRight, Close } from 'ui/common/Icons/Standard';
import { CloseButtonContainer, Header, Title } from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import { ApiKeys3rdPartyModalContents } from './ApiKeys3rdPartyModalContents';
import { ApiKeysCollimatorTokenContents } from './ApiKeysCollimatorTokenContents';
import { ApiKeysGitHubPATModalContents } from './ApiKeysGitHubPATModalContents';
import { ApiKeysOpenAiModalContents } from './ApiKeysOpenAiModalContents';

// Shared styles
export const TableWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: ${(props) => props.theme.spacing.large};
  min-width: 610px;
`;

export const DATE_WIDTH_PX = 100;
export const TOKEN_WIDTH_PX = 270;

// Local styles
const ApiKeysModalOuterContainer = styled.div`
  width: 100%;
  display: flex;
`;

const ApiKeysModalTabList = styled.div`
  display: flex;
  flex-direction: column;
  width: 170px;
  border-right: 1px solid ${({ theme }) => theme.colors.grey[10]};
  background: ${({ theme }) => theme.colors.grey[5]};
  padding-top: ${({ theme }) => theme.spacing.large};
`;

const ApiKeysModalTab = styled.div<{ highlighted?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  padding: 0 8px 0 16px;
  margin-bottom: 12px;
  font-size: 12px;
  cursor: pointer;
  background: ${({ highlighted, theme }) =>
    highlighted ? theme.colors.brand.primary.lighter : 'transparent'};
`;

const ApiKeysModalContent = styled.div`
  padding-left: 16px;
  padding: ${({ theme }) => theme.spacing.large};
`;

const CloseButton = styled(Button)`
  margin-right: 0;
`;

export type TabName = 'apiKeys' | 'openAiKey' | 'githubPat' | 'thirdPartyCreds';

const ApiKeysModal: React.FC<{
  tab?: TabName;
}> = ({ tab }) => {
  const { closeModal } = useModal();
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  type TabObject = {
    contentTitle: string;
    tabTitle: string;
    hidden?: boolean;
  };

  const tabs: Record<TabName, TabObject> = {
    apiKeys: {
      contentTitle: t`Manage API keys`,
      tabTitle: t`Collimator API Keys`,
    },
    openAiKey: {
      contentTitle: t`OpenAI API Key`,
      tabTitle: t`OpenAI API Key`,
    },
    githubPat: {
      contentTitle: t`GitHub PAT`,
      tabTitle: t`GitHub PAT`,
    },

    // Generic 3rd Party Credentials hidden by default since other tabs handle
    // all the use cases. Keep it because it's useful for debugging, and we might
    // need it again anyway. -- @jp, 2023-08-03
    thirdPartyCreds: {
      contentTitle: t`Manage 3rd Party Credentials`,
      tabTitle: t`3rd Party Credentials`,
      hidden: !developerModeEnabled,
    },
  };

  const [currentTab, setCurrentTab] = React.useState<TabName>(tab || 'apiKeys');

  return (
    <ApiKeysModalOuterContainer>
      <ApiKeysModalTabList>
        {(Object.keys(tabs) as Array<TabName>)
          .filter((tabKey) => !tabs[tabKey].hidden)
          .map((tabKey) => (
            <ApiKeysModalTab
              key={tabKey}
              highlighted={currentTab === tabKey}
              onClick={() => setCurrentTab(tabKey)}>
              {tabs[tabKey].tabTitle}
              <ChevronRight />
            </ApiKeysModalTab>
          ))}
      </ApiKeysModalTabList>
      <ApiKeysModalContent>
        <Header>
          <Title>{tabs[currentTab].contentTitle}</Title>
          <Button
            Icon={Close}
            onClick={closeModal}
            variant={ButtonVariants.LargeTertiary}
          />
        </Header>
        <form
          onSubmit={(e) => {
            e?.preventDefault();
            closeModal();
          }}>
          {currentTab === 'apiKeys' && <ApiKeysCollimatorTokenContents />}
          {currentTab === 'githubPat' && <ApiKeysGitHubPATModalContents />}
          {currentTab === 'openAiKey' && <ApiKeysOpenAiModalContents />}
          {currentTab === 'thirdPartyCreds' && <ApiKeys3rdPartyModalContents />}
          <CloseButtonContainer>
            <CloseButton
              type="submit"
              variant={ButtonVariants.LargePrimary}
              testId="api-keys-modal-close-button">
              {t({
                id: 'authTokensModal.closeButton.label',
                message: 'Close',
              })}
            </CloseButton>
          </CloseButtonContainer>
        </form>
      </ApiKeysModalContent>
    </ApiKeysModalOuterContainer>
  );
};

export default ApiKeysModal;
