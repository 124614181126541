import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useParams } from 'react-router-dom';
import { SidebarScrollContainer } from 'ui/common/layout/appLayout';
import { ModelRootItem } from 'ui/objectBrowser/ModelRootItem';
import { ModelTreeContent } from './ModelTreeContent';
import SectionSearch from './SectionSearch';

const ModelTreeScrollContainer = styled(SidebarScrollContainer)`
  overflow-x: auto;
  flex-direction: row;
`;

const ModelTreeContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

interface Props {
  projectId: string;
}

// Why is this scoped at the project level when it's used to display a single (sub)model's tree?
export const ProjectSection: React.FC<Props> = ({ projectId }) => {
  const [searchString, setSearchString] = React.useState('');

  const { loadedModelId: loadedDocumentId } = useAppSelector(
    (state) => state.modelMetadata,
  );
  const { modelId: documentId } = useParams<{ modelId: string }>();
  if (loadedDocumentId !== documentId) {
    return null;
  }

  return (
    <>
      <SectionSearch
        placeholder={t({
          id: 'modelRenderer.blockList.blockSearchPlaceholder',
          message: 'Search for blocks',
        })}
        onChangeText={setSearchString}
      />
      <ModelTreeScrollContainer>
        <ModelTreeContentContainer>
          <ModelRootItem />
          <ModelTreeContent searchString={searchString} projectId={projectId} />
        </ModelTreeContentContainer>
      </ModelTreeScrollContainer>
    </>
  );
};
