import { t } from '@lingui/macro';
import {
  useDeleteEntityPreferencesMutation,
  usePutEntityPreferencesMutation,
} from 'app/apiGenerated/generatedApi';
import { useAppDispatch } from 'app/hooks';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { IS_FAVOURITE_V1 } from 'ui/userPreferences/projectFavoritesPrefs';
import { projectMetadataActions } from '../../app/slices/projectMetadataSlice';

export function useEditProjectFavorites() {
  const dispatch = useAppDispatch();
  const { showError } = useNotifications();

  const [callPutEntityPreferences] = usePutEntityPreferencesMutation();
  const [callDeleteEntityPreferences] = useDeleteEntityPreferencesMutation();

  const setAsFavorite = React.useCallback(
    (projectId: string) => {
      callPutEntityPreferences({
        entityUuid: projectId,
        preferencesKey: IS_FAVOURITE_V1,
        entityPreferencesContainer: {
          data: {},
        },
      })
        .unwrap()
        .catch((error) => {
          // Clear optimistic update.
          dispatch(
            projectMetadataActions.clearFavoriteOptimisticUpdate({ projectId }),
          );
          showError(
            error,
            t({
              id: 'projectFavoriteApi.setAsFavoriteError',
              message: 'Unable to favorite project.',
            }),
          );
        });

      // Set optimistic update.
      dispatch(
        projectMetadataActions.addFavoriteOptimisticUpdate({
          projectId,
          isFavorite: true,
        }),
      );
    },
    [callPutEntityPreferences, dispatch, showError],
  );

  const removeFavorite = React.useCallback(
    (projectId: string) => {
      callDeleteEntityPreferences({
        entityUuid: projectId,
        preferencesKey: IS_FAVOURITE_V1,
      })
        .unwrap()
        .catch((error) => {
          // Clear optimistic update.
          dispatch(
            projectMetadataActions.clearFavoriteOptimisticUpdate({ projectId }),
          );
          showError(
            error,
            t({
              id: 'projectFavoriteApi.removeFavoriteError',
              message: 'Unable to unfavorite project.',
            }),
          );
        });

      // Set optimistic update.
      dispatch(
        projectMetadataActions.addFavoriteOptimisticUpdate({
          projectId,
          isFavorite: false,
        }),
      );
    },
    [callDeleteEntityPreferences, dispatch, showError],
  );

  return {
    setAsFavorite,
    removeFavorite,
  };
}
