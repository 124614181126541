import { t } from '@lingui/macro';
import { useJupyter } from 'app/api/useJupyter';
import { useJupyterProject } from 'app/api/useJupyterProject';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Input from 'ui/common/Input/Input';
import { isDuplicatedRuleSet } from 'ui/common/Input/inputValidation';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import { FileType, NOTEBOOK_FILES } from 'ui/notebook/notebookTypes';

interface Props {
  projectId: string;
}

const AddNotebookModal: React.FC<Props> = ({ projectId }) => {
  const [isValid, setIsValid] = React.useState(false);
  const [name, setName] = React.useState('');
  const { closeModal } = useModal();
  const navigate = useNavigate();
  const { createFile } = useJupyter();
  const { files } = useJupyterProject(projectId);

  const createNew = async () => {
    if (!isValid) return;

    const fileData = NOTEBOOK_FILES[FileType.NOTEBOOK];
    const fullName = `${name}.${fileData.extension}`;
    await createFile(projectId, fileData.extension, name);
    closeModal();
    navigate(`/projects/${projectId}/notebook/${fullName}`);
  };
  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        createNew();
      }}>
      <ModalInputGroup>
        <Label testId="new-notebook-name-label">
          {t({
            id: 'addNotebookModal.nameInput.label',
            message: 'Name',
          })}
        </Label>
        <Input
          testId="new-notebook-name-input"
          hasBorder
          autoFocus
          value={name}
          onChangeText={(v, isValid) => {
            setName(v);
            setIsValid(isValid);
          }}
          validationRules={isDuplicatedRuleSet(
            files?.data?.jupyter_files || [],
            (f) => f.name.split('.')[0],
          )}
        />
      </ModalInputGroup>
      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}
          testId="cancel-new-notebook-button">
          {t({
            id: 'addNotebookModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Create button */}
        <Button
          type="submit"
          variant={ButtonVariants.LargePrimary}
          testId="add-notebook-button"
          disabled={!isValid}>
          {t({
            id: 'addNotebookModal.createButton.label',
            message: 'Create',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default AddNotebookModal;
