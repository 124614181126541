import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useJupyter } from 'app/api/useJupyter';
import { useAppSelector } from 'app/hooks';
import { NotebookFooterTab } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Kernel } from 'ui/common/Icons/Small';
import { Remove } from 'ui/common/Icons/Standard';
import { H5 } from 'ui/common/typography/Typography';
import { NavbarMenuBackground } from 'ui/navbar/NavbarButtons';
import { useAppParams } from 'util/useAppParams';
import ListItem from '../Browser/ListItem';
import { HubSession } from './notebookTypes';

const KernelMenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-bottom: ${({ theme }) => theme.spacing.small};
  padding-left: ${({ theme }) => theme.spacing.large};
  padding-right: ${({ theme }) => theme.spacing.normal};
  width: 100%;
`;

const TitleWrapper = styled(H5)`
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

const KernelMenu: React.FC = () => {
  const navigate = useNavigate();
  const { projectId = '', fileName } = useAppParams();
  const { deleteKernels, sessions = [] } = useJupyter();
  const notebookFooterTab = useAppSelector(
    (state) => state.uiFlags.notebookFooterTab,
  );

  const closeKernels = async () => {
    if (notebookFooterTab === NotebookFooterTab.Console) {
      document.querySelector('#bottom-bar-button')?.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      );
    }
    deleteKernels(sessions.map((session) => session.kernel.id));
  };

  const IconButton = React.useCallback(
    (session: HubSession) => (
      <Button
        Icon={Remove}
        onClick={() => deleteKernels([session.kernel.id])}
        variant={ButtonVariants.LargeTertiary}
      />
    ),
    [deleteKernels],
  );

  return (
    <NavbarMenuBackground>
      <KernelMenuHeader>
        <TitleWrapper>
          {t({
            id: 'navBar.kernelMenu.title',
            message: 'Kernels',
          })}
        </TitleWrapper>
        <Button
          Icon={Remove}
          onClick={closeKernels}
          variant={ButtonVariants.LargeTertiary}
        />
      </KernelMenuHeader>
      {sessions
        .filter((session) => session.path.startsWith(projectId))
        .map((session) => (
          <ListItem
            Icon={Kernel}
            key={session.kernel.id}
            isActive={session.path.split('/')[1] === fileName}
            onClick={() =>
              navigate(
                `/projects/${projectId}/notebook/${session.path.split('/')[1]}`,
              )
            }
            IconButton={() => IconButton(session)}>
            {session.path.split('/')[1]}
          </ListItem>
        ))}
    </NavbarMenuBackground>
  );
};

export default KernelMenu;
