/* eslint-disable @typescript-eslint/ban-types */
/*
This file is on the path to deprecation. You should use the hooks generated by RTK Query instead.
For manual calls, check out https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#uselazyquery
See src/services/frontend/src/app/apiGenerated.ts and enhancedApi.ts.
*/
import { v4 as uuid } from 'uuid';

import { transformBackendSimulationModel } from '@collimator/model-schemas-ts';
import { ModelKind } from './apiGenerated/generatedApiTypes';
import { API_BASE_URL } from './config/globalApplicationConfig';
import {
  BlockInstance,
  LinkInstance,
  Parameter,
  SimulationModel,
} from './generated_types/SimulationModel';

export enum RequestMethodType {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

export type RequestOptionsType<T> =
  | {
      method: RequestMethodType.DELETE;
      body?: T;
      correlationId?: string;
      headers?: object;
    }
  | {
      method: RequestMethodType.GET;
      correlationId?: string;
      headers?: object;
    }
  | {
      method: RequestMethodType.POST;
      body: T;
      correlationId?: string;
      headers?: object;
    }
  | {
      method: RequestMethodType.PUT;
      body: T;
      correlationId?: string;
      headers?: object;
    }
  | {
      method: RequestMethodType.PATCH;
      body: T;
      correlationId?: string;
      headers?: object;
    };

export interface RequestError {
  parsed?: ParsedApiError;
  status?: number;
  message?: string;
  rawBody?: any;
}

export interface ParsedApiError {
  code?: number;
  message?: string;
  cause?: string;
}

export type ModelData = {
  blocks: BlockInstance[];
  links: LinkInstance[];
};

export function isAPIError(e: any): e is RequestError {
  return 'rawBody' in e;
}

const safeJsonParse = <T>(json: string): T | undefined => {
  try {
    return JSON.parse(json) as T;
  } catch (e) {
    console.error('API response is not valid JSON', e);
  }
};

const parseApiError = async (resp: Response): Promise<RequestError> => {
  const rawBody = await resp.text();
  let status: number = resp.status;

  // Don't parse 504, it's a timeout from the gateway.
  const parsed = status !== 504 ? safeJsonParse<ParsedApiError>(rawBody) : null;
  const message = parsed?.message;

  if (Number.isInteger(parsed?.code)) {
    const code = parsed?.code as number;
    // Ignore 418 "I'm a teapot" and assume it means 403, otherwise warn.
    if (code !== status && status !== 418) {
      console.warn(
        `mismatch in response code, got status=${status} and json code=${code}`,
      );
    }
    status = code;
  }

  return { parsed, message, status, rawBody } as RequestError;
};

const getRawJsonData = async <A, B>(
  url: string,
  requestOptions: RequestOptionsType<B>,
  retry = 0,
): Promise<A> => {
  try {
    const finalHeaders: { [key: string]: string } = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Request-ID': uuid(),
    };

    if (requestOptions.correlationId) {
      finalHeaders['X-Correlation-ID'] = requestOptions.correlationId;
    }

    const method = requestOptions.method;
    const body =
      requestOptions.method === 'GET'
        ? undefined
        : JSON.stringify(requestOptions.body);

    const rawResponse = await fetch(url, {
      method,
      headers: { ...finalHeaders, ...(requestOptions.headers || {}) },
      body,
    });

    if (!rawResponse.ok) {
      // Work around some '504 API Gateway timeout' spurious errors on localhost/macOS
      // @jp I'm making this specific to localhost but a proper retry strategy needs
      // to be implemented "cleanly" (for all 5xx and maybe some 4xx errors).
      if (
        rawResponse.status === 504 &&
        rawResponse.url &&
        rawResponse.url.startsWith('http://localhost') &&
        retry < 3
      ) {
        console.warn('API Gateway timeout, retrying');
        return await getRawJsonData(url, requestOptions, retry + 1);
      }

      return await Promise.reject(await parseApiError(rawResponse));
    }

    if (rawResponse.status === 204) {
      return await Promise.resolve({} as A);
    }

    const content = await rawResponse.text();
    const parsed = JSON.parse(content);
    return await Promise.resolve(parsed);
  } catch (e) {
    console.error('API call failed', e);
    return Promise.reject(e);
  }
};

const getSafeData = <B, R>(
  url: string,
  requestOptions: RequestOptionsType<B>,
  transformer: (res: any) => R,
  success: (r: R) => void,
  failure: (e: Error) => void,
) =>
  getRawJsonData(url, requestOptions).then(
    (res: any) => success(transformer(res)),
    (e: any) => {
      if (e.status === 401) {
        // @jp disabled this redirect because it causes lots of flashing
        // window.location.assign('/login/sign-in');
        console.error('Unauthorized request (401)');
      } else {
        failure(e);
      }
    },
  );

const getStreamApi = async (url: string, retry = 0): Promise<Response> => {
  const response = await fetch(url);

  if (!response.ok) {
    if (
      response.status === 504 &&
      response.url &&
      response.url.startsWith('http://localhost') &&
      retry < 3
    ) {
      return getStreamApi(url, retry + 1);
    }
  }

  return response;
};

export const getSimulationLogsStream = async (
  modelUuid: string,
  simulationUuid: string,
): Promise<Response> =>
  getStreamApi(
    `${API_BASE_URL}/models/${modelUuid}/simulations/${simulationUuid}/logs`,
  );

export type ModelLevelParameters = { [k: string]: Parameter | undefined };

export interface ModelGetResponse extends SimulationModel {
  created_at?: string;
  updated_at?: string;
  project_uuid: string;
  needs_autolayout: boolean;
  version: number;
  kind?: 'Model' | 'Experiment';
}

export const getModel = (
  modelUuid: string,
  success: (r: any) => void,
  failure: (e: Error) => void,
): Promise<any> =>
  getSafeData<{}, ModelGetResponse>(
    `${API_BASE_URL}/models/${modelUuid}`,
    { method: RequestMethodType.GET },
    (r) => ({
      ...transformBackendSimulationModel(r),
      // NOTE: this of course applies to any document where the first node is missing uiprops.
      // this should be sufficient for the case that we want to check for,
      // which is a document where none of the nodes have uiprops.
      needs_autolayout: !(r.diagram?.nodes || [])[0]?.uiprops,
      created_at: r.created_at,
      updated_at: r.updated_at,
      version: r.version,
      project_uuid: r.project_uuid,
      kind: r.kind,
    }),
    success,
    failure,
  );

export const registerClerkUser = (
  is_sign_up: boolean,
  success: (r: any) => void,
  failure: (e: Error) => void,
): Promise<any> =>
  getSafeData<{}, any>(
    `${API_BASE_URL}/user/register`,
    {
      method: RequestMethodType.POST,
      body: { is_sign_up },
    },
    (r) => r,
    success,
    failure,
  );

export interface Model {
  kind: ModelKind;
  uuid: string;
  version: number;
  name: string;
  description?: string;
  created_at: string;
  updated_at: string;
}

// Projects

export const apiS3UploadDataFile = async (
  presignedUrl: string,
  contentType: string,
  file: File,
): Promise<Response> => {
  const method = 'PUT';
  const headers: { [key: string]: string } = {
    'Content-Type': contentType,
  };
  return fetch(presignedUrl, {
    method,
    headers,
    body: file,
  });
};

// Clerk Whitelist
export type ClerkWhitelistIdentifier = {
  identifier: string;
  id: string;
  created_at?: string;
  updated_at?: string;
  display_name?: string;
  signed_beta_tos?: boolean;
  last_signed_in_at?: string;
  uuid?: string;
  organization?: string;
  is_disabled: boolean;
};

export const apiPostClerkWhitelistNew = (
  identifier: string,
  notify: boolean,
  success: (whitelistIdentifier: ClerkWhitelistIdentifier) => void,
  failure: (e: Error) => void,
): Promise<void> =>
  getSafeData<{}, ClerkWhitelistIdentifier>(
    `${API_BASE_URL}/user/clerk_whitelist`,
    {
      method: RequestMethodType.POST,
      body: {
        identifier,
        notify,
      },
    },
    (r) => r,
    success,
    failure,
  );

export const apiDeleteClerkWhitelistById = (
  identifierId: string,
  success: (projects: ClerkWhitelistIdentifier) => void,
  failure: (e: Error) => void,
): Promise<void> =>
  getSafeData<{}, any>(
    `${API_BASE_URL}/user/clerk_whitelist/${identifierId}`,
    {
      method: RequestMethodType.DELETE,
    },
    (r) => r,
    success,
    failure,
  );
