import type { Coordinate } from 'app/common_types/Coordinate';
import {
  LinkInstance,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import { renderConstants } from 'app/utils/renderConstants';
import { getPortWorldCoordinate } from 'app/utils/getPortOffsetCoordinate';
import {
  BEND_SPACING,
  LinkRenderData,
  LinkVertex,
  VertexSegmentType,
} from 'app/utils/linkToRenderData';
import { PortSide } from 'app/common_types/PortTypes';
import { RendererState } from 'ui/modelRendererInternals/modelRenderer';
import { snapNumberToGrid } from 'app/utils/modelDataUtils';
import {
  FakeSegmentType,
  HoverEntityType,
} from 'app/common_types/SegmentTypes';
import { MouseActions } from 'app/common_types/MouseTypes';
import { getVisualNodeHeight } from 'ui/modelRendererInternals/getVisualNodeHeight';
import { getVisualNodeWidth } from 'ui/modelRendererInternals/getVisualNodeWidth';

const GRID_SIZE = renderConstants.GRID_SIZE;

export const getLinkTapCoordinate = (
  rs: RendererState,
  link: LinkInstance,
): Coordinate => {
  const tapCoordinate = { x: 0, y: 0 };

  if (link.uiprops.link_type.connection_method == 'link_tap') {
    const tappedLinkUuid = link.uiprops.link_type.tapped_link_uuid;
    const tappedLink =
      rs.refs.current.links[rs.refs.current.linksIndexLUT[tappedLinkUuid]];
    const renderDataIndex = rs.linksRenderFrameDataIndexLUT[tappedLinkUuid];

    if (!tappedLink || renderDataIndex === undefined) return tapCoordinate;

    const tappedLinkRenderData = rs.linksRenderFrameData[renderDataIndex];

    if (tappedLink && tappedLinkRenderData) {
      const { vertexData } = tappedLinkRenderData;
      const tapCoordTarget = link.uiprops.link_type.tap_coordinate;

      for (let i = 0; i < vertexData.length - 1; i++) {
        const curVertexData = vertexData[i];
        const nextVertexData = vertexData[i + 1];
        const nextNextVertexData = vertexData[i + 2];

        if (
          link.uiprops.link_type.tapped_segment.segment_type === 'real' &&
          curVertexData.segmentType === VertexSegmentType.Real
        ) {
          const tappedSegmentIndex =
            link.uiprops.link_type.tapped_segment.tapped_segment_index;
          const vertexSegment = curVertexData.segmentIndex;
          if (vertexSegment !== tappedSegmentIndex) {
            continue;
          }

          const nextVertexSegment =
            nextVertexData.segmentType === VertexSegmentType.Real
              ? nextVertexData.segmentIndex
              : -1;

          const segmentDirection =
            tappedLink.uiprops.segments[vertexSegment]?.segment_direction;
          const tappedSegIsVertical = segmentDirection === 'vert';

          if (
            nextNextVertexData &&
            nextNextVertexData.segmentType === VertexSegmentType.Real &&
            nextNextVertexData.segmentIndex === tappedSegmentIndex
          ) {
            const [vcX, vcY] = curVertexData.coordinate;
            const [nnvcX, nnvcY] = nextNextVertexData.coordinate;

            const vertexCoord = tappedSegIsVertical ? vcY : vcX;
            const nextNextVertexCoord = tappedSegIsVertical ? nnvcY : nnvcX;

            if (vertexCoord < nextNextVertexCoord) {
              if (nextNextVertexCoord < tapCoordTarget) continue;
            } else if (nextNextVertexCoord > tapCoordTarget) continue;
          }

          const [vcX, vcY] = curVertexData.coordinate;
          const [nvcX, nvcY] = nextVertexData.coordinate;

          const vertexCoord = tappedSegIsVertical ? vcY : vcX;
          const nextVertexCoord = tappedSegIsVertical ? nvcY : nvcX;

          const smallerVertexCoord =
            vertexCoord < nextVertexCoord ? vertexCoord : nextVertexCoord;
          const adjSmallerVertexCoord = smallerVertexCoord;
          const largerVertexCoord =
            vertexCoord > nextVertexCoord ? vertexCoord : nextVertexCoord;
          const adjLargerVertexCoord = largerVertexCoord;

          const withinBounds =
            tapCoordTarget > adjSmallerVertexCoord &&
            tapCoordTarget < adjLargerVertexCoord;

          const ignoreBounds =
            adjLargerVertexCoord - adjSmallerVertexCoord <= 0;

          if (ignoreBounds) {
            const middlePoint =
              smallerVertexCoord + (largerVertexCoord - smallerVertexCoord) / 2;
            return tappedSegIsVertical
              ? { x: vcX, y: middlePoint }
              : { x: middlePoint, y: vcY };
          }
          if (withinBounds) {
            return tappedSegIsVertical
              ? { x: vcX, y: tapCoordTarget }
              : { x: tapCoordTarget, y: vcY };
          }
          if (tapCoordTarget <= adjSmallerVertexCoord) {
            return tappedSegIsVertical
              ? { x: vcX, y: adjSmallerVertexCoord }
              : { x: adjSmallerVertexCoord, y: vcY };
          }
          if (
            tapCoordTarget >= adjLargerVertexCoord &&
            nextVertexSegment !== tappedSegmentIndex
          ) {
            return tappedSegIsVertical
              ? { x: vcX, y: adjLargerVertexCoord }
              : { x: adjLargerVertexCoord, y: vcY };
          }
        }
      }
    }
  }

  return tapCoordinate;
};

export const lShapedLinkVertices = (
  linkStartCoord: Coordinate,
  linkEndCoord: Coordinate,
): LinkVertex[] => [
  {
    segmentType: VertexSegmentType.Irrelevant,
    coordinate: [linkStartCoord.x, linkStartCoord.y],
  },
  {
    segmentType: VertexSegmentType.Irrelevant,
    coordinate: [linkStartCoord.x, linkEndCoord.y],
  },
  {
    segmentType: VertexSegmentType.Irrelevant,
    coordinate: [linkEndCoord.x, linkEndCoord.y],
  },
];

export const zeroSegTapLinkToRenderData = (
  rs: RendererState,
  cursorX: number,
  cursorY: number,
  dstBlock: NodeInstance,
  linkStartCoord: Coordinate,
  linkEndCoord: Coordinate | undefined,
  userDrawingThisLink: boolean,
  tappedSegIsVertical: boolean,
): LinkVertex[] => {
  let resultVertices: LinkVertex[] = [];

  if (
    userDrawingThisLink &&
    rs.mouseState.state === MouseActions.DrawingLinkFromEnd
  ) {
    const midX = snapNumberToGrid(
      (cursorX - linkStartCoord.x) / 2 + linkStartCoord.x,
    );

    const drawL = !tappedSegIsVertical;

    if (drawL) {
      resultVertices = lShapedLinkVertices(linkStartCoord, {
        x: cursorX,
        y: cursorY,
      });
    } else {
      resultVertices = [
        {
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [linkStartCoord.x, linkStartCoord.y],
        },
        {
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [midX, linkStartCoord.y],
        },
        {
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [midX, cursorY],
        },
        {
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [cursorX, cursorY],
        },
      ];
    }
  } else if (
    userDrawingThisLink &&
    rs.mouseState.state === MouseActions.DrawingLinkFromStart &&
    linkEndCoord
  ) {
    const drawL =
      rs.hoveringEntity &&
      ((rs.hoveringEntity.entityType === HoverEntityType.Link &&
        rs.hoveringEntity.link &&
        rs.hoveringEntity.link.uiprops.segments[rs.hoveringEntity.segmentId]
          ?.segment_direction === 'horiz') ||
        (rs.hoveringEntity.entityType === HoverEntityType.FakeLinkSegment &&
          rs.hoveringEntity.fakeSegmentType !== FakeSegmentType.SMiddle));

    const midX = snapNumberToGrid((linkEndCoord.x - cursorX) / 2 + cursorX);

    if (drawL) {
      resultVertices = [
        {
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [cursorX, cursorY],
        },
        {
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [cursorX, linkEndCoord.y],
        },
        {
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [linkEndCoord.x, linkEndCoord.y],
        },
      ];
    } else {
      resultVertices = [
        {
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [cursorX, cursorY],
        },
        {
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [midX, cursorY],
        },
        {
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [midX, linkEndCoord.y],
        },
        {
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [linkEndCoord.x, linkEndCoord.y],
        },
      ];
    }
  } else if (linkStartCoord && linkEndCoord) {
    let midX = 0;

    if (tappedSegIsVertical) {
      midX = snapNumberToGrid(
        (linkEndCoord.x - linkStartCoord.x) / 2 + linkStartCoord.x,
      );

      resultVertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.SStart,
        coordinate: [linkStartCoord.x, linkStartCoord.y],
        reifiedSegmentInteractedIndex: 1,
        reificationData: [
          {
            coordinate: linkStartCoord.x,
            segment_direction: 'vert',
          },
          {
            coordinate: linkStartCoord.y,
            segment_direction: 'horiz',
          },
        ],
      });

      resultVertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.SMiddle,
        coordinate: [midX, linkStartCoord.y],
        reifiedSegmentInteractedIndex: 0,
        reificationData: [
          {
            coordinate: midX,
            segment_direction: 'vert',
          },
        ],
      });
    } else {
      midX = linkStartCoord.x;

      const bendY =
        linkEndCoord.y > linkStartCoord.y
          ? linkStartCoord.y + GRID_SIZE
          : linkStartCoord.y - GRID_SIZE;

      resultVertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.SStart,
        coordinate: [linkStartCoord.x, linkStartCoord.y],
        reifiedSegmentInteractedIndex: 1,
        reificationData: [
          {
            coordinate: bendY,
            segment_direction: 'horiz',
          },
          {
            coordinate: midX,
            segment_direction: 'vert',
          },
        ],
      });
    }

    if (dstBlock) {
      const dstBlockWidth = getVisualNodeWidth(dstBlock);
      const blockReversed = dstBlock.uiprops.directionality === 'left';
      const endX = blockReversed
        ? dstBlock.uiprops.x + dstBlockWidth + GRID_SIZE
        : dstBlock.uiprops.x - GRID_SIZE;

      if ((blockReversed && endX > midX) || (!blockReversed && endX < midX)) {
        const bendY =
          linkStartCoord.y > linkEndCoord.y
            ? dstBlock.uiprops.y + getVisualNodeHeight(dstBlock) + BEND_SPACING
            : dstBlock.uiprops.y - GRID_SIZE * 3;
        resultVertices.push({
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SMiddle,
          coordinate: [midX, bendY],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: bendY,
              segment_direction: 'horiz',
            },
          ],
        });
        resultVertices.push({
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SMiddle,
          coordinate: [endX, bendY],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: endX,
              segment_direction: 'vert',
            },
          ],
        });
        resultVertices.push({
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SEnd,
          coordinate: [endX, linkEndCoord.y],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: linkEndCoord.y,
              segment_direction: 'horiz',
            },
            {
              coordinate: linkEndCoord.x,
              segment_direction: 'vert',
            },
          ],
        });
      } else {
        resultVertices.push({
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SEnd,
          coordinate: [midX, linkEndCoord.y],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: linkEndCoord.y,
              segment_direction: 'horiz',
            },
            {
              coordinate: linkEndCoord.x,
              segment_direction: 'vert',
            },
          ],
        });
      }
    } else {
      resultVertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.SEnd,
        coordinate: [midX, linkEndCoord.y],
        reifiedSegmentInteractedIndex: 0,
        reificationData: [
          {
            coordinate: linkEndCoord.y,
            segment_direction: 'horiz',
          },
          {
            coordinate: linkEndCoord.x,
            segment_direction: 'vert',
          },
        ],
      });
    }

    resultVertices.push({
      segmentType: VertexSegmentType.Fake,
      fakeSegmentType: FakeSegmentType.SEnd,
      coordinate: [linkEndCoord.x, linkEndCoord.y],
      reifiedSegmentInteractedIndex: 0,
      reificationData: [],
    });
  }

  return resultVertices;
};

export const singleSegTapLinkToRenderData = (
  rs: RendererState,
  cursorX: number,
  cursorY: number,
  segments: { segment_direction: 'horiz' | 'vert'; coordinate: number }[],
  dstBlock: NodeInstance,
  linkStartCoord: Coordinate,
  linkEndCoord: Coordinate | undefined,
  userDrawingThisLink: boolean,
  tappedSegIsVertical: boolean,
): LinkVertex[] => {
  let resultVertices: LinkVertex[] = [];

  const onlySegmentCoord = segments[0].coordinate;

  if (
    userDrawingThisLink &&
    rs.mouseState.state === MouseActions.DrawingLinkFromEnd &&
    linkStartCoord
  ) {
    resultVertices = [
      {
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [linkStartCoord.x, linkStartCoord.y],
      },
      {
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [onlySegmentCoord, linkStartCoord.y],
      },
      {
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [onlySegmentCoord, cursorY],
      },
      {
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [cursorX, cursorY],
      },
    ];
  } else if (
    userDrawingThisLink &&
    rs.mouseState.state === MouseActions.DrawingLinkFromStart &&
    linkEndCoord
  ) {
    resultVertices = [
      {
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [cursorX, cursorY],
      },
      {
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [onlySegmentCoord, cursorY],
      },
      {
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [onlySegmentCoord, linkEndCoord.y],
      },
      {
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [linkEndCoord.x, linkEndCoord.y],
      },
    ];
  } else if (linkStartCoord && linkEndCoord) {
    resultVertices.push({
      segmentType: VertexSegmentType.Fake,
      fakeSegmentType: FakeSegmentType.Start,
      coordinate: [linkStartCoord.x, linkStartCoord.y],
      reifiedSegmentInteractedIndex: 1,
      reificationData: [
        {
          coordinate: linkStartCoord.x,
          segment_direction: 'vert',
        },
        {
          coordinate: linkStartCoord.y,
          segment_direction: 'horiz',
        },
      ],
    });

    if (!tappedSegIsVertical) {
      const tapSegBendY =
        linkStartCoord.y < linkEndCoord.y
          ? linkStartCoord.y + GRID_SIZE
          : linkStartCoord.y - GRID_SIZE;
      resultVertices.push({
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [linkStartCoord.x, tapSegBendY],
      });
      resultVertices.push({
        segmentType: VertexSegmentType.Real,
        segmentIndex: 0,
        coordinate: [onlySegmentCoord, tapSegBendY],
      });
    } else {
      resultVertices.push({
        segmentType: VertexSegmentType.Real,
        segmentIndex: 0,
        coordinate: [onlySegmentCoord, linkStartCoord.y],
      });
    }

    if (dstBlock) {
      const dstBlockWidth = getVisualNodeWidth(dstBlock);
      const blockReversed = dstBlock.uiprops.directionality === 'left';
      const endX = blockReversed
        ? dstBlock.uiprops.x + dstBlockWidth + GRID_SIZE
        : dstBlock.uiprops.x - GRID_SIZE;

      if (
        (blockReversed && endX > onlySegmentCoord) ||
        (!blockReversed && endX < onlySegmentCoord)
      ) {
        const bendY =
          linkStartCoord.y > linkEndCoord.y
            ? dstBlock.uiprops.y + getVisualNodeHeight(dstBlock) + BEND_SPACING
            : dstBlock.uiprops.y - GRID_SIZE * 3;
        resultVertices.push({
          segmentType: VertexSegmentType.Real,
          segmentIndex: 0,
          coordinate: [onlySegmentCoord, bendY],
        });
        resultVertices.push({
          segmentType: VertexSegmentType.Real,
          segmentIndex: 0,
          coordinate: [endX, bendY],
        });
        resultVertices.push({
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.End,
          coordinate: [endX, linkEndCoord.y],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: linkEndCoord.y,
              segment_direction: 'horiz',
            },
            {
              coordinate: linkEndCoord.x,
              segment_direction: 'vert',
            },
          ],
        });
      } else {
        resultVertices.push({
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.End,
          coordinate: [onlySegmentCoord, linkEndCoord.y],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: linkEndCoord.y,
              segment_direction: 'horiz',
            },
            {
              coordinate: linkEndCoord.x,
              segment_direction: 'vert',
            },
          ],
        });
      }
    } else {
      resultVertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.End,
        coordinate: [onlySegmentCoord, linkEndCoord.y],
        reifiedSegmentInteractedIndex: 0,
        reificationData: [
          {
            coordinate: linkEndCoord.y,
            segment_direction: 'horiz',
          },
          {
            coordinate: linkEndCoord.x,
            segment_direction: 'vert',
          },
        ],
      });
    }

    resultVertices.push({
      segmentType: VertexSegmentType.Fake,
      fakeSegmentType: FakeSegmentType.End,
      coordinate: [linkEndCoord.x, linkEndCoord.y],
      reifiedSegmentInteractedIndex: 0,
      reificationData: [],
    });
  }

  return resultVertices;
};

export const multiSegTapLinkToRenderData = (
  rs: RendererState,
  cursorX: number,
  cursorY: number,
  segments: { segment_direction: 'horiz' | 'vert'; coordinate: number }[],
  dstBlock: NodeInstance,
  linkStartCoord: Coordinate,
  linkEndCoord: Coordinate | undefined,
  userDrawingThisLink: boolean,
  tappedSegIsVertical: boolean,
): LinkVertex[] => {
  let resultVertices: LinkVertex[] = [];

  const firstSegment = segments[0];
  const secondSegment = segments[1];

  const firstSegVert = firstSegment.segment_direction == 'vert';

  if (
    userDrawingThisLink &&
    rs.mouseState.state === MouseActions.DrawingLinkFromStart
  ) {
    resultVertices.push({
      segmentType: VertexSegmentType.Irrelevant,
      coordinate: [cursorX, cursorY],
    });
    if (firstSegment.segment_direction === 'vert') {
      resultVertices.push({
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [firstSegment.coordinate, cursorY],
      });
    } else {
      resultVertices.push({
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [cursorX, firstSegment.coordinate],
      });
    }
  } else if (linkStartCoord) {
    if (firstSegVert && !tappedSegIsVertical) {
      const tapSegBendY =
        linkStartCoord.y <= secondSegment.coordinate
          ? linkStartCoord.y + GRID_SIZE
          : linkStartCoord.y - GRID_SIZE;
      resultVertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.Start,
        coordinate: [linkStartCoord.x, linkStartCoord.y],
        reifiedSegmentInteractedIndex: 0,
        reificationData: [],
      });
      resultVertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.Start,
        coordinate: [linkStartCoord.x, tapSegBendY],
        reifiedSegmentInteractedIndex: 0,
        reificationData: [
          {
            coordinate: tapSegBendY,
            segment_direction: 'horiz',
          },
        ],
      });
      resultVertices.push({
        segmentType: VertexSegmentType.Real,
        segmentIndex: 0,
        coordinate: [firstSegment.coordinate, tapSegBendY],
      });
    } else if (!firstSegVert && !tappedSegIsVertical) {
      resultVertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.Start,
        coordinate: [linkStartCoord.x, linkStartCoord.y],
        reifiedSegmentInteractedIndex: 0,
        reificationData: [
          {
            coordinate: linkStartCoord.x,
            segment_direction: 'vert',
          },
        ],
      });
      resultVertices.push({
        segmentType: VertexSegmentType.Real,
        segmentIndex: 0,
        coordinate: [linkStartCoord.x, firstSegment.coordinate],
      });
    } else {
      resultVertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.Start,
        coordinate: [linkStartCoord.x, linkStartCoord.y],
        reifiedSegmentInteractedIndex: 1,
        reificationData: [
          {
            coordinate: linkStartCoord.x,
            segment_direction: 'vert',
          },
          {
            coordinate: linkStartCoord.y,
            segment_direction: 'horiz',
          },
        ],
      });
      resultVertices.push({
        segmentType: VertexSegmentType.Real,
        segmentIndex: 0,
        coordinate: [firstSegment.coordinate, linkStartCoord.y],
      });
    }
  }

  for (let i = 1; i < segments.length; i++) {
    const segment = segments[i];
    const previousVertex =
      resultVertices[resultVertices.length - 1]?.coordinate;
    const [pVX, pVY] = previousVertex || [0, 0];
    let coordinate: [x: number, y: number] = [0, 0];

    if (segment.segment_direction === 'vert') {
      coordinate = [segment.coordinate, pVY];
    } else {
      coordinate = [pVX, segment.coordinate];
    }

    resultVertices.push({
      segmentType: VertexSegmentType.Real,
      segmentIndex: i,
      coordinate,
    });
  }

  const lastSegmentIndex = segments.length - 1;
  const lastSegment = segments[lastSegmentIndex];

  if (
    userDrawingThisLink &&
    rs.mouseState.state === MouseActions.DrawingLinkFromEnd
  ) {
    if (lastSegment.segment_direction === 'vert') {
      resultVertices.push({
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [lastSegment.coordinate, cursorY],
      });
    } else {
      resultVertices.push({
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [cursorX, lastSegment.coordinate],
      });
    }
    resultVertices.push({
      segmentType: VertexSegmentType.Irrelevant,
      coordinate: [cursorX, cursorY],
    });
  } else if (linkEndCoord) {
    let lastVertices: LinkVertex[] = [];

    if (lastSegment.segment_direction === 'vert') {
      // vertices.push([lastSegment.coordinate, linkEndCoord.y]);

      // if we're connected to a destination, check to see if we should
      // "bend" the first segment around the destination block
      // and then add more vertices if that is the case
      let bent = false;

      if (dstBlock) {
        const dstBlockWidth = getVisualNodeWidth(dstBlock);
        const { x: bX, y: bY, directionality: dir } = dstBlock.uiprops;
        const blockReversed = dir === 'left';
        const endX = blockReversed
          ? bX + dstBlockWidth + GRID_SIZE
          : bX - GRID_SIZE;
        const topY = bY - GRID_SIZE * 3;
        const bottomY = bY + getVisualNodeHeight(dstBlock) + BEND_SPACING;

        if (
          (blockReversed && lastSegment.coordinate < endX) ||
          (!blockReversed && lastSegment.coordinate > endX)
        ) {
          bent = true;
          lastVertices.unshift({
            segmentType: VertexSegmentType.Real,
            segmentIndex: lastSegmentIndex,
            coordinate: [endX, linkEndCoord.y],
          });

          const prevSegment = segments[segments.length - 2];
          if (prevSegment && prevSegment.coordinate < topY) {
            lastVertices.unshift({
              segmentType: VertexSegmentType.Real,
              segmentIndex: lastSegmentIndex,
              coordinate: [endX, topY],
            });
            lastVertices.unshift({
              segmentType: VertexSegmentType.Real,
              segmentIndex: lastSegmentIndex,
              coordinate: [lastSegment.coordinate, topY],
            });
          } else if (prevSegment && prevSegment.coordinate > bottomY) {
            lastVertices.unshift({
              segmentType: VertexSegmentType.Real,
              segmentIndex: lastSegmentIndex,
              coordinate: [endX, bottomY],
            });
            lastVertices.unshift({
              segmentType: VertexSegmentType.Real,
              segmentIndex: lastSegmentIndex,
              coordinate: [lastSegment.coordinate, bottomY],
            });
          } else {
            // the final vertex's X has already been computed as past the maximum X,
            // so we need to correct it here so that it isn't rendered under
            resultVertices[resultVertices.length - 1].coordinate[0] = endX;
          }
        }
      }

      if (!bent) {
        lastVertices = [
          {
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.End,
            coordinate: [lastSegment.coordinate, linkEndCoord.y],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [
              {
                coordinate: linkEndCoord.y,
                segment_direction: 'horiz',
              },
              {
                coordinate: linkEndCoord.x,
                segment_direction: 'vert',
              },
            ],
          },
        ];
      }
    } else {
      lastVertices = [
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.End,
          coordinate: [linkEndCoord.x, lastSegment.coordinate],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: linkEndCoord.y,
              segment_direction: 'horiz',
            },
            {
              coordinate: linkEndCoord.x,
              segment_direction: 'vert',
            },
          ],
        },
      ];
    }
    resultVertices = resultVertices.concat(lastVertices);
    resultVertices.push({
      segmentType: VertexSegmentType.Fake,
      fakeSegmentType: FakeSegmentType.End,
      coordinate: [linkEndCoord.x, linkEndCoord.y],
      reifiedSegmentInteractedIndex: 0,
      reificationData: [],
    });
  }

  return resultVertices;
};

export const tapLinkToRenderData = (
  rs: RendererState,
  link: LinkInstance,
  nodes: NodeInstance[],
  blocksIndexLUT: { [k: string]: number },
  offsetX: number,
  offsetY: number,
  userDrawingThisLink: boolean,
): LinkRenderData => {
  if (link.uiprops.link_type.connection_method !== 'link_tap') {
    return {
      linkUuid: '',
      vertexData: [],
      boundingBox: { x1: 0, y1: 0, x2: 0, y2: 0 },
    };
  }

  const tappedLink =
    rs.refs.current.links[
      rs.refs.current.linksIndexLUT[link.uiprops.link_type.tapped_link_uuid]
    ];
  const tappedSegmentIndex =
    link.uiprops.link_type.tapped_segment.segment_type === 'real'
      ? link.uiprops.link_type.tapped_segment.tapped_segment_index
      : -1;
  const tappedSegmentType = link.uiprops.link_type.tapped_segment.segment_type;
  const tappedSegIsVertical =
    tappedSegmentType === 'smiddle' ||
    (tappedSegmentType === 'real' &&
      tappedLink &&
      tappedLink.uiprops.segments[tappedSegmentIndex] &&
      tappedLink.uiprops.segments[tappedSegmentIndex].segment_direction ===
        'vert');

  let resultVertices: LinkVertex[] = [];

  let segments = JSON.parse(JSON.stringify(link.uiprops.segments));

  // we insert a temporary segment into the function-local data while waiting for a double-click
  // to reduce the associated visual lag
  if (
    rs.mouseState.preDblConfirmClickWorldCoord &&
    rs.mouseState.state === MouseActions.DrawingLinkFromEnd &&
    rs.mouseState.linkUuid === link.uuid &&
    segments.length > 0
  ) {
    const snappedX = snapNumberToGrid(
      rs.mouseState.preDblConfirmClickWorldCoord.x,
    );
    const snappedY = snapNumberToGrid(
      rs.mouseState.preDblConfirmClickWorldCoord.y,
    );
    let appendingSegment: {
      segment_direction: 'vert' | 'horiz';
      coordinate: number;
    } = {
      segment_direction: 'vert',
      coordinate: snappedX,
    };

    if (segments[segments.length - 1].segment_direction === 'vert') {
      appendingSegment = {
        segment_direction: 'horiz',
        coordinate: snappedY,
      };
    }

    segments.push(appendingSegment);
  }

  const dstBlock = nodes[blocksIndexLUT[link.dst?.node || '']];

  const linkStartCoord = getLinkTapCoordinate(rs, link);
  const linkEndCoord =
    getPortWorldCoordinate(dstBlock, PortSide.Input, link.dst) ||
    link.uiprops.hang_coord_end;

  const cursorX = rs.screenCursorZoomed.x - offsetX;
  const cursorY = rs.screenCursorZoomed.y - offsetY;

  if (segments.length === 0) {
    resultVertices = zeroSegTapLinkToRenderData(
      rs,
      cursorX,
      cursorY,
      dstBlock,
      linkStartCoord,
      linkEndCoord,
      userDrawingThisLink,
      tappedSegIsVertical,
    );
  } else if (segments.length === 1) {
    resultVertices = singleSegTapLinkToRenderData(
      rs,
      cursorX,
      cursorY,
      segments,
      dstBlock,
      linkStartCoord,
      linkEndCoord,
      userDrawingThisLink,
      tappedSegIsVertical,
    );
  } else if (segments.length > 1) {
    resultVertices = multiSegTapLinkToRenderData(
      rs,
      cursorX,
      cursorY,
      segments,
      dstBlock,
      linkStartCoord,
      linkEndCoord,
      userDrawingThisLink,
      tappedSegIsVertical,
    );
  }

  return {
    linkUuid: link.uuid,
    vertexData: resultVertices,
    boundingBox: { x1: 0, y1: 0, x2: 0, y2: 0 },
  };
};
