import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useDataIntegrations } from 'app/api/useDataIntegrations';
import {
  DataIntegrationKind,
  DataIntegrationSummary,
} from 'app/apiGenerated/generatedApiTypes';
import React from 'react';
import { Cell, Column, Row, TableState } from 'react-table';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import {
  isDuplicatedRuleSet,
  isRequiredRule,
} from 'ui/common/Input/inputValidation';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  LargeFormContainer,
  ModalInputGroup,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import SelectInput from 'ui/common/SelectInput';
import Table from 'ui/common/Table/Table';
import { formatDate } from 'util/dateUtils';

interface Props {
  projectId: string;
}

interface TableData extends Record<string, string> {
  uuid: string;
  name: string;
  kind: string;
  createdAt: string;
}

interface S3IntegrationConfig {
  access_key_id: string;
  secret_access_key: string;
  region: string;
  bucket_name: string;
}

const TableWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: ${(props) => props.theme.spacing.large};
`;

const DateBlock = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Date = (cell: Cell<TableData>) => (
  <DateBlock title={cell.value}>{formatDate(cell.value)}</DateBlock>
);

const ManageIntegrationsModal: React.FC<Props> = ({ projectId }) => {
  const { closeModal } = useModal();
  const {
    createDataIntegration,
    dataIntegrations,
    refetchDataIntegrations,
    isFetchingDataIntegrations,
    deleteDataIntegration,
  } = useDataIntegrations();
  const [integrationName, setIntegrationName] = React.useState('');
  const [isIntegrationNameValid, setIsIntegrationNameValid] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [integrationKind, setIntegrationKind] = React.useState('');
  const options = [{ value: 'amazon_s3', label: 'Amazon S3' }];

  const [accessKeyId, setAccessKeyId] = React.useState('');
  const [secretAccessKey, setSecretAccessKey] = React.useState('');
  const [region, setRegion] = React.useState('');
  const [bucketName, setBucketName] = React.useState('');

  const [integrationsTableData, setIntegrationsTableData] = React.useState<
    TableData[]
  >([]);

  const RemoveCell = React.useCallback(
    (cell: Cell<TableData>) => {
      const onDelete = (integrationUuid: string) => {
        deleteDataIntegration(projectId, integrationUuid);
      };

      return (
        <Button
          Icon={Remove}
          variant={ButtonVariants.LargeTertiary}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            onDelete(cell.row.original.uuid);
          }}
        />
      );
    },
    [deleteDataIntegration, projectId],
  );

  const columns: Column<TableData>[] = [
    { Header: 'UUID', accessor: 'uuid', minWidth: 0, maxWidth: 0 },
    {
      Header: t({
        id: 'manageIntegrations.table.name.label',
        message: 'Name',
      }),
      accessor: 'name',
      minWidth: 200,
      maxWidth: 0,
    },
    {
      Header: t({
        id: 'manageIntegrations.table.kind.label',
        message: 'Kind',
      }),
      accessor: 'kind',
      minWidth: 150,
      maxWidth: 0,
    },
    {
      Header: t({
        id: 'manageIntegrations.table.createdAt.label',
        message: 'Created',
      }),
      accessor: 'createdAt',
      minWidth: 250,
      maxWidth: 0,
      Cell: Date,
    },
    {
      Header: '',
      id: 'remove',
      disableSortBy: true,
      minWidth: 20,
      maxWidth: 0,
      disableResizing: true,
      Cell: RemoveCell,
    },
  ];

  const initialState: Partial<TableState<TableData>> = {
    hiddenColumns: ['uuid'],
  };

  const onCancel = () => {
    closeModal();
  };

  const resetForm = () => {
    setIntegrationName('');
    setIntegrationKind('');
    setAccessKeyId('');
    setSecretAccessKey('');
    setRegion('');
    setBucketName('');
  };

  const nameValidationRules = React.useMemo(() => {
    if (!dataIntegrations || !dataIntegrations.data_integrations)
      return [isRequiredRule];

    return isDuplicatedRuleSet(
      dataIntegrations.data_integrations,
      (integration) => integration.name,
    );
  }, [dataIntegrations]);

  React.useEffect(() => {
    if (
      dataIntegrations &&
      dataIntegrations.data_integrations &&
      !isFetchingDataIntegrations
    ) {
      setIntegrationsTableData(
        dataIntegrations.data_integrations.map(
          (summary: DataIntegrationSummary) => ({
            uuid: summary.uuid || '',
            name: summary.name || '',
            kind: summary.kind || '',
            createdAt: summary.created_at,
          }),
        ),
      );
    }
  }, [dataIntegrations, isFetchingDataIntegrations, setIntegrationsTableData]);

  return (
    <LargeFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        setIsLoading(true);

        const config: S3IntegrationConfig = {
          access_key_id: accessKeyId,
          secret_access_key: secretAccessKey,
          region,
          bucket_name: bucketName,
        };

        const kind: DataIntegrationKind =
          integrationKind as DataIntegrationKind;

        createDataIntegration(projectId, {
          name: integrationName,
          kind,
          config,
        }).then(() => {
          refetchDataIntegrations();
          resetForm();
          setIsLoading(false);
        });
      }}>
      <TableWrapper>
        <Table
          columns={columns}
          data={integrationsTableData}
          initialState={initialState}
          testId="data-integrations-table"
          getRowTestId={(row: Row<TableData>) => row.original.uuid}
        />
      </TableWrapper>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'manageIntegrations.name.label',
            message: 'Enter a name for your integration',
          })}
        </Label>
        <Input
          hasBorder
          autoFocus
          onChangeText={(v, isValid) => {
            setIntegrationName(v);
            setIsIntegrationNameValid(isValid);
          }}
          value={integrationName}
          validationRules={nameValidationRules}
        />
      </ModalInputGroup>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'manageIntegrations.integrationKind.label',
            message: 'Choose a supported type of integration',
          })}
        </Label>
        <SelectInput
          onSelectValue={setIntegrationKind}
          currentValue={integrationKind}
          options={options}
        />
      </ModalInputGroup>
      {integrationKind === 'amazon_s3' && (
        <>
          <ModalInputGroup>
            <Label>
              {t({
                id: 'manageIntegrations.s3.accessKeyId.label',
                message: 'Access Key ID for accessing S3 bucket',
              })}
            </Label>
            <Input
              hasBorder
              autoFocus
              onChangeText={(v) => {
                setAccessKeyId(v);
              }}
              value={accessKeyId}
              validationRules={[isRequiredRule]}
            />
          </ModalInputGroup>
          <ModalInputGroup>
            <Label>
              {t({
                id: 'manageIntegrations.s3.secretAccessKey.label',
                message: 'Secret Access Key for accessing S3 bucket',
              })}
            </Label>
            <Input
              hasBorder
              autoFocus
              onChangeText={(v) => {
                setSecretAccessKey(v);
              }}
              value={secretAccessKey}
              validationRules={[isRequiredRule]}
            />
          </ModalInputGroup>
          <ModalInputGroup>
            <Label>
              {t({
                id: 'manageIntegrations.s3.region.label',
                message: 'Region where the S3 bucket is located',
              })}
            </Label>
            <Input
              hasBorder
              autoFocus
              onChangeText={(v) => {
                setRegion(v);
              }}
              value={region}
              validationRules={[isRequiredRule]}
            />
          </ModalInputGroup>
          <ModalInputGroup>
            <Label>
              {t({
                id: 'manageIntegrations.s3.bucketName.label',
                message: 'Enter name for the S3 bucket to integrate with',
              })}
            </Label>
            <Input
              hasBorder
              autoFocus
              onChangeText={(v) => {
                setBucketName(v);
              }}
              value={bucketName}
              validationRules={[isRequiredRule]}
            />
          </ModalInputGroup>
        </>
      )}
      <ActionButtonContainer>
        <Button
          type="button"
          onClick={onCancel}
          variant={ButtonVariants.LargeSecondary}>
          {t({
            id: 'manageIntegrations.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>
        <Button
          type="submit"
          loading={isLoading}
          variant={ButtonVariants.LargePrimary}
          disabled={!isIntegrationNameValid || !integrationKind}>
          {t({
            id: 'manageIntegrations.addButton.label',
            message: 'Add Integration',
          })}
        </Button>
      </ActionButtonContainer>
    </LargeFormContainer>
  );
};

export default ManageIntegrationsModal;
