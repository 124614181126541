// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const LoopMemory: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "LoopMemory",
      "namespace": "core",
      "description": "Memory in an Iterator loop.",
      "supports_iterator": true,
      "supports_models": false,
      "supports_experiments": false,
      "supports_submodels": false,
      "feature_level": "dev"
    },
    "modes": {
      "time": "any",
      "feedthrough": false
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "initial_value",
        "data_type": "float",
        "default_value": "0.0",
        "description": "initial value of memory."
      }
    ]
  }
);
