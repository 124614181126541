import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export type CellFormat = { value: string; label: string };
export enum SERVER_STATUS {
  NOT_LOADED,
  LOADING,
  LOADED,
}
interface AuthState {
  iframeReady: boolean;
  serverStatus: SERVER_STATUS;
  cellFormat: CellFormat;
  forceRefetchFiles: boolean;
}

const initialState: AuthState = {
  iframeReady: false,
  serverStatus: SERVER_STATUS.NOT_LOADED,
  cellFormat: { value: '', label: '-' },
  forceRefetchFiles: false,
};

const jupyterSlice = createSlice({
  name: 'jupyterSlice',
  initialState,
  reducers: {
    setCellFormat(state, action: PayloadAction<CellFormat>) {
      state.cellFormat = action.payload;
    },
    setIframeReady(state, action: PayloadAction<boolean>) {
      state.iframeReady = action.payload;
    },
    setserverStatus(state, action: PayloadAction<SERVER_STATUS>) {
      state.serverStatus = action.payload;
    },
    requestRefetchFiles(state) {
      state.forceRefetchFiles = true;
    },
    clearRequestRefetchFiles(state) {
      state.forceRefetchFiles = false;
    },
  },
});

export const jupyterActions = jupyterSlice.actions;

export const jupyterSelectors = {
  selectCellFormat: (state: RootState) => state.jupyter.cellFormat,
  selectIframeReady: (state: RootState) => state.jupyter.iframeReady,
  selectServerStatus: (state: RootState) => state.jupyter.serverStatus,
  selectForceRefetchFiles: (state: RootState) =>
    state.jupyter.forceRefetchFiles,
};

export default jupyterSlice;
