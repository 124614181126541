import { useAppSelector } from 'app/hooks';
import React from 'react';
import ModelDiagramDetails from 'ui/modelEditor/ModelDiagramDetails';
import ReferenceSubmodelDiagramDetails from 'ui/modelEditor/ReferenceSubmodelDiagramDetails';
import SubmodelPortDetails from 'ui/modelEditor/SubmodelPortDetails';
import { SubmodelSimulationDetails } from 'ui/modelEditor/SubmodelSimulationDetails';
import DeveloperOptionsDetails from './DeveloperOptionsDetails';
import InitializationScriptDetails from './InitializationScriptDetails';
import ModelConfigurationDetails from './ModelConfigurationDetails';
import ModelParameterDetails from './ModelParameterDetails';
import ParameterDefinitionDetails from './ParameterDefinitionDetails';

interface Props {
  projectId: string;
  modelId: string;
  canEditCurrentModelVersion: boolean;
}

const ModelDetails: React.FC<Props> = ({
  modelId,
  projectId,
  canEditCurrentModelVersion,
}) => {
  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  return (
    <>
      {(topLevelModelType === 'Model' ||
        topLevelModelType === 'Experiment') && (
        <>
          <ModelDiagramDetails projectId={projectId} modelId={modelId} />
          <ModelParameterDetails />
          <ModelConfigurationDetails />
          <InitializationScriptDetails />
        </>
      )}
      {topLevelModelType === 'Submodel' && (
        <>
          <ReferenceSubmodelDiagramDetails
            projectId={projectId}
            submodelId={modelId}
            canEdit={canEditCurrentModelVersion}
          />
          <ParameterDefinitionDetails canEdit={canEditCurrentModelVersion} />
          <SubmodelSimulationDetails canEdit={canEditCurrentModelVersion} />
          <SubmodelPortDetails
            canEditCurrentModelVersion={canEditCurrentModelVersion}
          />
          <InitializationScriptDetails />
        </>
      )}
      <DeveloperOptionsDetails />
    </>
  );
};

export default ModelDetails;
