import {
  getCurrentlyEditingModelFromState,
  ModelState,
} from 'app/modelState/ModelState';
import { snapNumberToGrid } from 'app/utils/modelDataUtils';

export function moveBlocksTo(
  state: ModelState,
  blockUuids: string[],
  x: number,
  y: number,
) {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  for (let i = 0; i < model.nodes.length; i++) {
    const node = model.nodes[i];

    if (!blockUuids.includes(node.uuid)) continue;

    node.uiprops.x = x;
    node.uiprops.y = y;
  }
}

export function moveAnnotationsTo(
  state: ModelState,
  uuids: string[],
  x: number,
  y: number,
) {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model || !model.annotations) return;

  for (let i = 0; i < model.annotations.length; i++) {
    const anno = model.annotations[i];

    if (!uuids.includes(anno.uuid)) continue;

    anno.x = x;
    anno.y = y;
  }
}

export function moveBlocksAndLinksByDelta(
  state: ModelState,

  deltaX: number,
  deltaY: number,
  blockUuids?: string[],
  linkUuids?: string[],
  annotationUuids?: string[],
) {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  if (blockUuids) {
    for (let i = 0; i < model.nodes.length; i++) {
      const node = model.nodes[i];

      if (!blockUuids.includes(node.uuid)) continue;

      node.uiprops.x += deltaX;
      node.uiprops.y += deltaY;
    }
  }

  if (annotationUuids && model.annotations) {
    for (let i = 0; i < model.annotations.length; i++) {
      const annotation = model.annotations[i];

      if (!annotationUuids.includes(annotation.uuid)) continue;

      annotation.x += deltaX;
      annotation.y += deltaY;
    }
  }

  if (linkUuids) {
    for (let i = 0; i < model.links.length; i++) {
      const link = model.links[i];

      if (!link || !linkUuids.includes(link.uuid)) continue;

      if (link.uiprops.link_type.connection_method === 'link_tap') {
        if (
          link.uiprops.link_type.tapped_segment.tapped_segment_direction ===
          'horiz'
        ) {
          link.uiprops.link_type.tap_coordinate += deltaX;
        } else {
          link.uiprops.link_type.tap_coordinate += deltaY;
        }
      }

      const segments = link.uiprops.segments;

      for (let j = 0; j < segments.length; j++) {
        const segment = segments[j];

        if (segment.segment_direction === 'horiz') {
          segment.coordinate += deltaY;
        } else {
          segment.coordinate += deltaX;
        }
      }

      if (link.uiprops.hang_coord_start) {
        link.uiprops.hang_coord_start.x += deltaX;
        link.uiprops.hang_coord_start.y += deltaY;
      }
      if (link.uiprops.hang_coord_end) {
        link.uiprops.hang_coord_end.x += deltaX;
        link.uiprops.hang_coord_end.y += deltaY;
      }
    }
  }
}

export function snapEntitiesToGrid(
  state: ModelState,
  blockUuids?: string[],
  linkUuids?: string[],
  annotationUuids?: string[],
) {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  if (blockUuids) {
    for (let i = 0; i < model.nodes.length; i++) {
      const node = model.nodes[i];

      if (!blockUuids.includes(node.uuid)) continue;

      node.uiprops.x = snapNumberToGrid(node.uiprops.x);
      node.uiprops.y = snapNumberToGrid(node.uiprops.y);
    }
  }

  if (annotationUuids) {
    const annotations = model.annotations || [];
    for (let i = 0; i < annotations.length; i++) {
      const annotation = annotations[i];

      if (!annotationUuids.includes(annotation.uuid)) continue;

      annotation.x = snapNumberToGrid(annotation.x);
      annotation.y = snapNumberToGrid(annotation.y);
    }
  }

  if (linkUuids) {
    for (let i = 0; i < model.links.length; i++) {
      const link = model.links[i];

      if (!linkUuids.includes(link.uuid)) continue;

      const segments = link.uiprops.segments;

      for (let j = 0; j < segments.length; j++) {
        const segment = segments[j];
        segment.coordinate = snapNumberToGrid(segment.coordinate);
      }

      if (link.uiprops.hang_coord_start) {
        link.uiprops.hang_coord_start.x = snapNumberToGrid(
          link.uiprops.hang_coord_start.x,
        );
        link.uiprops.hang_coord_start.y = snapNumberToGrid(
          link.uiprops.hang_coord_start.y,
        );
      }
      if (link.uiprops.hang_coord_end) {
        link.uiprops.hang_coord_end.x = snapNumberToGrid(
          link.uiprops.hang_coord_end.x,
        );
        link.uiprops.hang_coord_end.y = snapNumberToGrid(
          link.uiprops.hang_coord_end.y,
        );
      }
    }
  }
}

export function changeSegmentCoordinate(
  state: ModelState,
  linkUuid: string,
  segmentIndex: number,
  newCoordinate: number,
) {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  const link = model.links.find((l) => l.uuid === linkUuid);
  if (link) {
    const segment = link.uiprops.segments[segmentIndex];

    if (segment) segment.coordinate = newCoordinate;
  }
}
