// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const ImageSource: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "ImageSource",
      "namespace": "core",
      "description": "Loads an image (png, jpeg) as a set of matrices.",
      "development_notes": "We could decide to return a MxNx3 tensor, but this does not seem to be supported yet by cmlc.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#imagesource"
    },
    "modes": {
      "time": "constant"
    },
    "ports": {
      "outputs": {
        "static": [
          {
            "name": "image"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "file_name",
        "description": "Name of the image file to load.",
        "data_type": "string",
        "default_value": ""
      },
      {
        "name": "normalize",
        "description": "Controls the range of pixel values. If true: [0,1], else: [0,255].",
        "data_type": "bool",
        "default_value": "false"
      }
    ]
  }
);
