import { t } from '@lingui/macro';
import React from 'react';

import { useCodeGeneration } from 'app/api/useCodeGeneration';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Code } from 'ui/common/Icons/Small';
import SectionHeading from 'ui/common/Inputs/SectionHeading';

import { DetailInputRowsSection, DetailsSection } from './DetailsComponents';

interface Props {
  selectedNode: NodeInstance;
}

const BlockCodeGenDetails: React.FC<Props> = ({ selectedNode }) => {
  const { startCodeGeneration } = useCodeGeneration();
  return (
    <>
      <SectionHeading testId="codegen">
        {t({
          id: 'blockDetails.CodeGenTitle',
          message: 'code generation',
        })}
      </SectionHeading>
      <DetailInputRowsSection key="code_gen_options">
        <DetailsSection>
          <Button
            variant={ButtonVariants.SmallTertiary}
            Icon={Code}
            onClick={() =>
              startCodeGeneration('code_export', selectedNode.name)
            }>
            {t({
              id: 'blockDetails.CodeGenGenerateButton',
              message: 'Generate code from this Submodel',
            })}
          </Button>
        </DetailsSection>
        <DetailsSection>
          <Button
            variant={ButtonVariants.SmallTertiary}
            Icon={Code}
            onClick={() =>
              startCodeGeneration('fmu_export', selectedNode.name)
            }>
            {t({
              id: 'blockDetails.CodeGenGenerateFMUButton',
              message: 'Export this submodel as FMU',
            })}
          </Button>
        </DetailsSection>
      </DetailInputRowsSection>
    </>
  );
};

export default BlockCodeGenDetails;
