import { useModelData } from 'app/api/useModelData';
import React from 'react';

interface Props {
  projectId: string;
  modelId: string;
}

const CurrentModelLoader: React.FC<Props> = ({ projectId, modelId }) => {
  useModelData(projectId, modelId);

  return null;
};

export default CurrentModelLoader;
