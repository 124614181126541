import {
  FakeTappedSegmentType,
  ModelDiagram,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { TimeModeValueType } from 'app/common_types/TimeModeTypesData';
import { FakeSegmentType } from 'app/common_types/SegmentTypes';
import { getLocalSubmodelDiagram } from 'app/utils/modelDiagramUtils';
import { Coordinate } from 'app/common_types/Coordinate';
import { renderConstants } from 'app/utils/renderConstants';

export interface LinkPayload {
  source?: {
    node: string;
    port: number;
  };
  destination?: {
    node: string;
    port: number;
  };
}

export type ModelConfigurationType =
  | 'stop_time'
  | 'sample_time'
  | 'continuous_time_result_interval'
  | 'events_handling';

export interface ModelParameter {
  name: string;
  value: string;
}

export type ModelParameters = ModelParameter[];

export type CommonParamNameType = 'time_mode';
interface TimeModePayload {
  paramName: 'time_mode';
  value: TimeModeValueType;
}

export type CommonParamPayload = TimeModePayload;

export const fakeSegmentStringMap: {
  [k in FakeSegmentType]: FakeTappedSegmentType;
} = {
  [FakeSegmentType.Start]: 'start',
  [FakeSegmentType.SStart]: 'sstart',
  [FakeSegmentType.End]: 'end',
  [FakeSegmentType.SEnd]: 'send',
  [FakeSegmentType.SMiddle]: 'smiddle',
};

export const getCurrentParentModelDiagram = (
  rootModel: ModelDiagram,
  submodels: SubmodelsSection,
  currentSubmodelPath: string[],
): ModelDiagram | null => {
  if (currentSubmodelPath.length === 0) {
    return null;
  }
  if (currentSubmodelPath.length === 1) {
    return rootModel;
  }
  const parentUuid = currentSubmodelPath[currentSubmodelPath.length - 2];
  return getLocalSubmodelDiagram(submodels, parentUuid);
};

export const snapNumberToGrid = (number: number) =>
  Math.round(number / renderConstants.GRID_SIZE) * renderConstants.GRID_SIZE;

export const snapCoordinateToGrid = (coord: Coordinate): Coordinate => ({
  x: snapNumberToGrid(coord.x),
  y: snapNumberToGrid(coord.y),
});
