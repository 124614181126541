import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PortSide } from 'app/common_types/PortTypes';
import {
  CompilationErrors,
  Location as EntityLocation,
} from 'app/generated_types/CmlTypes';
import { modelActions } from './modelSlice';

export type ErrorTreeNode = {
  errorKind?: string;
  inputPorts?: number[];
  outputPorts?: number[];
  children: {
    [blockUuid: string]: ErrorTreeNode | undefined;
  };
};

export interface ErrorsState {
  rootNode: ErrorTreeNode; // A fake node for a clean tree structure
}

const initialState: ErrorsState = { rootNode: { children: {} } };

const assembleErrorTreeAndGetFinalNode = (
  blockUuidPath: string[],
  rootNodeMut: ErrorTreeNode,
) => {
  // BEWARE! This function mutates the rootNode

  let currentNode: ErrorTreeNode = rootNodeMut;
  for (let nodeID of blockUuidPath) {
    const nextNode = currentNode.children[nodeID] || { children: {} };
    currentNode.children[nodeID] = nextNode;
    currentNode = nextNode;
  }

  return currentNode;
};

const removeErrorNodeFromTree = (
  blockUuidPath: string[],
  rootNodeMut: ErrorTreeNode,
) => {
  // BEWARE! This function mutates the rootNode

  for (let pathLen = blockUuidPath.length; pathLen > 0; pathLen--) {
    for (let currentNode = rootNodeMut, i = 0; i < pathLen; i++) {
      const nextNode = currentNode.children[blockUuidPath[i]];
      if (!nextNode) break;

      if (i === blockUuidPath.length - 1) {
        delete currentNode.children[blockUuidPath[i]];
      } else if (i === pathLen - 1) {
        // Clean up parent group error if no children
        if (Object.keys(nextNode.children).length === 0) {
          delete currentNode.children[blockUuidPath[i]];
        }
      }

      currentNode = nextNode;
    }
  }
};

const removeErrorPortFromTree = (
  blockUuidPath: string[],
  rootNodeMut: ErrorTreeNode,
  portSide: PortSide,
  portIndex: number,
) => {
  // BEWARE! This function mutates the rootNode

  for (let pathLen = blockUuidPath.length; pathLen > 0; pathLen--) {
    for (let currentNode = rootNodeMut, i = 0; i < pathLen; i++) {
      const nextNode = currentNode.children[blockUuidPath[i]];
      if (!nextNode) break;

      if (i === blockUuidPath.length - 1) {
        if (portSide === PortSide.Input) {
          nextNode.inputPorts = nextNode.inputPorts?.filter(
            (port) => port !== portIndex,
          );
          if (nextNode.inputPorts?.length === 0) {
            delete currentNode.children[blockUuidPath[i]];
          }
        } else {
          nextNode.outputPorts = nextNode.outputPorts?.filter(
            (port) => port !== portIndex,
          );
          if (nextNode.outputPorts?.length === 0) {
            delete currentNode.children[blockUuidPath[i]];
          }
        }
      } else if (i === pathLen - 1) {
        // Clean up parent group error if no children
        if (Object.keys(nextNode.children).length === 0) {
          delete currentNode.children[blockUuidPath[i]];
        }
      }

      currentNode = nextNode;
    }
  }
};

const errorsSlice = createSlice({
  name: 'errorsSlice',
  initialState,
  reducers: {
    setAtdCompilationErrors(state, action: PayloadAction<CompilationErrors>) {
      const errors = action.payload;

      const newRootNode: ErrorTreeNode = { children: {} };

      for (let i = 0; i < errors.length; i++) {
        const error = errors[i];

        let locations: EntityLocation[] = [];
        switch (error.kind) {
          case 'AlgebraicLoop':
            locations = error.value.locations;
            break;
          case 'UnconnectedInput':
          case 'InvalidParam':
            locations = [error.value];
            break;
          default:
            console.error('Received unknown ATD error kind:', error);
            break;
        }

        for (let j = 0; j < locations.length; j++) {
          const currentErrorLocValue = locations[j];
          if (!currentErrorLocValue.block_id) continue;

          const blockUuidPath = currentErrorLocValue.block_id.uuid_path;
          let newInports: number[] = [];
          let newOutports: number[] = [];

          if (currentErrorLocValue.port_id) {
            newInports =
              currentErrorLocValue.port_id.direction.kind === 'In'
                ? [currentErrorLocValue.port_id.index]
                : [];
            newOutports =
              currentErrorLocValue.port_id.direction.kind === 'Out'
                ? [currentErrorLocValue.port_id.index]
                : [];
          }

          const node = assembleErrorTreeAndGetFinalNode(
            blockUuidPath,
            newRootNode,
          );

          if (node) {
            node.errorKind = error.kind;
            node.inputPorts = [...(node.inputPorts || []), ...newInports];
            node.outputPorts = [...(node.outputPorts || []), ...newOutports];
          }
        }
      }

      state.rootNode = newRootNode;
    },
  },

  extraReducers: (builder) => {
    // Generic error cleanup
    const modelActionMatcher = (action: AnyAction): boolean => {
      const allMatchers = [
        modelActions.loadModelContent,
        modelActions.loadSubmodelContent,

        modelActions.addPremadeEntitiesToModel,
        modelActions.removeEntitiesFromModel,

        modelActions.createSubdiagramFromSelection,
        modelActions.confirmReferenceSubmodelCreatedFromSelection,

        modelActions.disconnectNodeFromAllLinks,
        modelActions.insertNodeIntoLink,
        modelActions.disconnectLinkFromSourceOrDest,
        modelActions.connectTwoLinks,
        modelActions.insertNodeIntoLink,
        modelActions.disconnectLinkFromSourceOrDest,
      ];

      return allMatchers.find((matcher) => matcher.match(action)) !== undefined;
    };

    builder.addMatcher(modelActionMatcher, (state, action) => {
      state.rootNode = { children: {} };
      return state;
    });

    // Fine-grained error cleanup
    // 1. Ports
    builder.addMatcher(
      modelActions.connectNodesPorts.match,
      (state, action) => {
        const { parentPath, destNodeUuid, destNodeInputPortIDs } =
          action.payload;
        const blockUuidPath = [...parentPath, destNodeUuid];
        for (const index of destNodeInputPortIDs) {
          removeErrorPortFromTree(
            blockUuidPath,
            state.rootNode,
            PortSide.Input,
            index,
          );
        }
        return state;
      },
    );

    builder.addMatcher(
      modelActions.connectLinkToNode.match,
      (state, action) => {
        const { parentPath, linkPayload } = action.payload;
        if (!linkPayload.destination) return state;
        const blockUuidPath = [...parentPath, linkPayload.destination.node];
        removeErrorPortFromTree(
          blockUuidPath,
          state.rootNode,
          PortSide.Input,
          linkPayload.destination.port,
        );
        return state;
      },
    );

    builder.addMatcher(modelActions.removePort.match, (state, action) => {
      const { parentPath, nodeUuid, portSide } = action.payload;
      if (portSide !== PortSide.Input) return state;
      const blockUuidPath = [...parentPath, nodeUuid];
      // Note: because port indices are reassigned when removing ports other
      // than the last, we can't easily just call removeErrorPortFromTree.
      removeErrorNodeFromTree(blockUuidPath, state.rootNode);
      return state;
    });
  },
});

export const errorsActions = errorsSlice.actions;

export default errorsSlice;
