import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import {
  ModelVersionRequestData,
  modelVersionsActions,
} from 'app/slices/modelVersionsSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import { SmallHighlight, Title } from 'ui/common/typography/Typography';
import SimulationResultsOutOfDateState from 'ui/modelEditor/Visualizer/states/SimulationResultsOutOfDateState';
import {
  Message,
  Wrapper,
} from 'ui/modelEditor/Visualizer/states/commonStyles';
import {
  buildNodeInfoForModelVersion,
  buildSignalInfoForModelVersion,
} from 'util/modelVersionSignal';
import { getIsSignalSupported } from 'util/portTypeUtils';
import { MODEL_EDITOR_VISUALIZER_EXPLORATION_ID } from '../../userPreferences/visualizerPrefs';

const PlotCellErrorWrapper = styled(Wrapper)`
  padding-top: ${({ theme }) => theme.spacing.large};
  padding-bottom: ${({ theme }) => theme.spacing.large};
`;

interface Props {
  traceId: string;
  tracePath: string;
  errorMessage: string;
}

export const DataExplorerPlotCellError: React.FC<Props> = ({
  traceId,
  tracePath,
  errorMessage,
}) => {
  const dispatch = useAppDispatch();

  let errorToDisplay: string = errorMessage;

  const trace = useAppSelector(
    (state) => state.dataExplorer.idToTrace[traceId],
  );

  const simulationIdToModelVersionId = useAppSelector(
    (state) => state.simulationSignals.simulationIdToModelVersionId,
  );

  const explorationId = useAppSelector(
    (state) => state.dataExplorer.explorationId,
  );
  const lastSimulationEditId = useAppSelector(
    (state) => state.modelMetadata.lastSimulationEditId,
  );
  const currentEditId = useAppSelector((state) => state.modelMetadata.editId);

  const modelVersionId =
    trace &&
    trace.simulationId &&
    simulationIdToModelVersionId[trace.simulationId];

  const modelIdToVersionIdToModelData = useAppSelector(
    (state) => state.modelVersions.modelIdToVersionIdToModelData,
  );

  let isSignalSupported = true;
  if (trace && modelVersionId) {
    const { portIndex } = buildSignalInfoForModelVersion(
      trace.signalPath,
      trace.modelId,
      modelVersionId,
      modelIdToVersionIdToModelData,
    );
    if (portIndex !== undefined) {
      isSignalSupported = getIsSignalSupported(trace.signalPath, portIndex);
    }
  }

  let modelVersionToRequest: ModelVersionRequestData | undefined;
  if (trace && modelVersionId) {
    const { errorMessage, modelVersionToRequest, node } =
      buildNodeInfoForModelVersion(
        trace.signalPath,
        trace.modelId,
        modelVersionId,
        modelIdToVersionIdToModelData,
      );
    if (errorMessage) {
      errorToDisplay = errorMessage;
    } else if (!modelVersionToRequest && !node) {
      errorToDisplay = t({
        id: 'dataExplorer.signalPathNotFoundInModel.errorMessage',
        message: 'Signal not found in model.',
      });
    }
  }

  React.useEffect(() => {
    if (modelVersionToRequest) {
      dispatch(modelVersionsActions.requestModelVersion(modelVersionToRequest));
    }
  }, [dispatch, modelVersionToRequest]);

  const messageTitle = isSignalSupported
    ? t({
        id: 'dataExplorer.generalTraceDataLoadError.title',
        message: 'Failed to load data for signal trace',
      })
    : t({
        id: 'dataExplorer.signalTypeIsNotSupported.title',
        message: 'Signal type is unsupported.',
      });

  const messageBody = isSignalSupported ? errorToDisplay : '';

  if (
    isSignalSupported &&
    explorationId === MODEL_EDITOR_VISUALIZER_EXPLORATION_ID &&
    lastSimulationEditId !== currentEditId
  ) {
    return (
      <PlotCellErrorWrapper>
        <SimulationResultsOutOfDateState />
      </PlotCellErrorWrapper>
    );
  }

  return (
    <PlotCellErrorWrapper>
      <Message>
        <Title>{messageTitle}</Title>
        <SmallHighlight>
          {t({
            id: 'dataExplorer.generalTraceDataLoadError.signalTraceLabel',
            message: 'Signal trace',
          })}
          : {tracePath}
        </SmallHighlight>
        <br />
        {messageBody && <SmallHighlight>{errorToDisplay}</SmallHighlight>}
      </Message>
      <Button
        Icon={Remove}
        variant={ButtonVariants.SmallSecondary}
        onClick={() =>
          dispatch(
            dataExplorerActions.removeTraces({
              traceIds: [traceId],
            }),
          )
        }>
        {t({
          id: 'dataExplorer.signalTraceError.removeTraceButtonText',
          message: 'Remove signal trace',
        })}
      </Button>
    </PlotCellErrorWrapper>
  );
};
