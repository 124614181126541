import React from 'react';
import { Download } from 'ui/common/Icons/Standard';
import { Spinner } from 'ui/common/Spinner';
import { t } from '@lingui/macro';
import { downloadBlobFile } from 'util/fileUtils';
import { formatFileNameDate } from 'util/dateUtils';
import { FetchError } from 'app/errors';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { useVisualizerAllData } from 'app/api/useVisualizerData';

export function getDownloadDataButtonIcon(
  enableButton: boolean,
  isRunning: boolean,
): React.FC {
  if (isRunning) {
    return Spinner;
  }

  if (enableButton) {
    return Download;
  }

  return Download;
}

export function useDownloadResults() {
  const { showError } = useNotifications();

  const [dataDownloadLoading, setDataDownloadLoading] = React.useState(false);

  const { fetchAllData } = useVisualizerAllData();

  const downloadData = React.useCallback(
    async ({
      simulationId,
      modelId,
      modelName,
    }: {
      simulationId: string;
      modelId: string;
      modelName: string;
    }) => {
      if (simulationId && modelId) {
        setDataDownloadLoading(true);
        try {
          const now = new Date();
          const fileName = `${modelName}_${formatFileNameDate(now)}.csv`;
          const text = await fetchAllData({
            simulationId,
            modelId,
          });

          // This splits the first occurrence of \n
          const [headers, data] = text.split(/\n(.*)/s);
          let finalHeaders;
          finalHeaders = headers;
          downloadBlobFile(
            new Blob([finalHeaders, '\n', data], { type: 'text/plain' }),
            fileName,
          );
        } catch (e: any) {
          let msg;
          if (e.response) {
            switch ((e as FetchError)?.response?.status) {
              case 413:
                msg = 'File too large';
                break;
              default:
                msg = (e as FetchError)?.response?.statusText;
                break;
            }
          } else {
            msg = e.message;
          }
          showError(
            t({
              id: 'navBar.downloadData.error',
              message: 'Failed to download data.',
            }),
            msg,
          );
        }
        setDataDownloadLoading(false);
      }
    },
    [showError, fetchAllData],
  );

  return {
    downloadData,
    dataDownloadLoading,
  };
}
