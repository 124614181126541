import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useDataIntegrationObjects } from 'app/api/useDataIntegrationObjects';
import { useProject } from 'app/api/useProject';
import { useProjectActions } from 'app/api/useProjectActions';
import {
  FileProcessorType,
  FileSummary,
} from 'app/apiGenerated/generatedApiTypes';
import { BlockClassName } from 'app/generated_types/SimulationModel';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SelectInput, { SelectInputOption } from 'ui/common/SelectInput';
import { CreateScriptModal } from 'ui/dashboard/projectDetail/CreateScriptModal';
import { useAppParams } from 'util/useAppParams';
import { useModal } from '../Modal/useModal';

type Props = {
  datafileType: BlockClassName | 'PythonScript';
  dataIntegrationId?: string;
  currentValue: string;
  onSelectValue: (newValue: string) => void;
  allowEmpty?: boolean;
};

const hasExtension = (value: string, extension: string) =>
  value.toLowerCase().endsWith(extension);

const dataSourceExtensions = ['.csv'];
const imageSourceExtensions = ['.png', '.jpg', '.jpeg'];
const videoSourceExtensions = ['.mp4', '.mpeg4', '.avi'];
const pythonExtensions = ['.py'];
const mlModelsExtensions = ['.zip', '.pt'];

const expectedExtensions: { [key: string]: string[] } = {
  'core.DataSource': dataSourceExtensions,
  'core.ImageSource': imageSourceExtensions,
  'core.VideoSource': videoSourceExtensions,
  'core.Predictor': mlModelsExtensions,

  // Any kind of python script, not only for the block:
  PythonScript: pythonExtensions,
};

const isSupportedExtension = (value: string, datafileType: string) => {
  const extensions = expectedExtensions[datafileType];
  return extensions.some((extension) => hasExtension(value, extension));
};

/**
 * Use processor type to be certain a file has been preprocessed as necessary by the backend.
 */
const expectedProcessorType: { [key: string]: FileProcessorType } = {
  'core.ModelicaFMU': 'zip_fmu',
  'core.Predictor': 'ml_model',
  'core.CppFunction': 'zip_cpp',
};

const shouldFilterOnProcessorType = (dataFileType: string) =>
  Object.keys(expectedProcessorType).includes(dataFileType);

const UploadFileInput = styled.input`
  visibility: hidden;
  position: absolute;
  z-index: -100;
  width: 10px;
`;

const DataFileParameter: React.FC<Props> = ({
  datafileType,
  dataIntegrationId = '',
  currentValue,
  onSelectValue,
  allowEmpty = false,
}) => {
  const { project } = useProject();
  const { projectId } = useAppParams();
  const { integrationObjects } = useDataIntegrationObjects(
    projectId,
    dataIntegrationId,
  );

  const navigate = useNavigate();
  const { triggerModal } = useModal();

  const { createFile } = useProjectActions();
  const uploadScriptFileRef = React.useRef<HTMLInputElement>(null);
  const uploadScriptFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!projectId || !file) {
      return null;
    }

    createFile(
      {
        projectUuid: projectId,
        fileCreateRequest: {
          name: file.name,
          content_type: file.type,
          size: file.size,
        },
      },
      file,
    ).then((fileSummary) => {
      if (fileSummary) {
        onSelectValue(fileSummary.name);
      }
    });
  };

  const onScriptCreated = React.useCallback(
    (fileUuid: string) => {
      navigate(`/projects/${projectId}/python_script/${fileUuid}`);
    },
    [navigate, projectId],
  );

  const selectActionsMap = React.useMemo(
    () => ({
      __upload_script: {
        label: t({
          id: 'modelRenderer.parameters.uploadScript.label',
          message: 'Upload script...',
        }),
        action: () => {
          uploadScriptFileRef?.current?.click?.();
        },
      },
      __create_script: {
        label: t({
          id: 'modelRenderer.parameters.newScript.label',
          message: 'Create new script...',
        }),
        action: () => {
          if (!projectId) return;

          triggerModal(
            <CreateScriptModal
              projectId={projectId}
              onCreated={onScriptCreated}
            />,
            t({
              id: 'createScriptModal.title',
              message: 'Create new Python script',
            }),
          );
        },
      },
    }),
    [uploadScriptFileRef, triggerModal, projectId, onScriptCreated],
  );

  const internalOnSelect = (newVal: string) => {
    const actionKeys = Object.keys(selectActionsMap);

    if (actionKeys.includes(newVal)) {
      selectActionsMap[newVal as keyof typeof selectActionsMap].action();
      return;
    }

    onSelectValue(newVal);
  };

  const [isSelectedOptionValidOrEmpty, setIsSelectedOptionValidOrEmpty] =
    React.useState<boolean>(true);

  // Options for data file selection can either be sourced from files that have been uploaded
  // to Collimator environment through /files APIs, or a user might select an external integration.
  // Upon usage of external integration, we cannot guarantee file's extension or type, so
  // no filtering is being done on option selections.
  const options = React.useMemo(() => {
    let options: SelectInputOption[] = [];

    if (!dataIntegrationId) {
      const projectFiles =
        project?.files.filter(
          (file) => !file.error && file.status === 'processing_completed',
        ) || [];

      let filteredFiles: FileSummary[];
      if (shouldFilterOnProcessorType(datafileType)) {
        filteredFiles = projectFiles.filter(
          (file) => file.processor === expectedProcessorType[datafileType],
        );
      } else {
        const extensions = expectedExtensions[datafileType];
        filteredFiles = extensions
          ? projectFiles.filter((file) =>
              isSupportedExtension(file.name, datafileType),
            )
          : projectFiles;
      }
      options = filteredFiles.map((file) => ({
        value: file.name,
        label: file.name,
      }));
    } else if (dataIntegrationId && integrationObjects) {
      options = integrationObjects.objects.map((object) => ({
        value: object.name,
        label: object.name,
      }));
    }

    if (currentValue) {
      const currentValueIsValid = options.some(
        (option) => option.value === currentValue,
      );
      if (currentValueIsValid) {
        setIsSelectedOptionValidOrEmpty(true);
      } else {
        options.push({
          value: currentValue,
          label: t({
            id: 'modelRenderer.parameters.dataFileNotFound.label',
            message: '{filename} (Not found)',
            values: {
              filename: currentValue,
            },
          }),
        });
        setIsSelectedOptionValidOrEmpty(false);
      }
    } else {
      // The current value is empty so we should not show an error.
      setIsSelectedOptionValidOrEmpty(true);
    }

    const actionOptions =
      datafileType === 'PythonScript'
        ? Object.keys(selectActionsMap).map((val) => ({
            value: val,
            label: selectActionsMap[val as keyof typeof selectActionsMap].label,
          }))
        : [];

    if (allowEmpty) {
      return [
        {
          value: '',
          label: t({
            id: 'modelRenderer.parameters.noDataFile.label',
            message: '(None)',
          }),
        },
        ...options,
        ...actionOptions,
      ];
    }

    return options;
  }, [
    datafileType,
    dataIntegrationId,
    allowEmpty,
    project,
    currentValue,
    integrationObjects,
    selectActionsMap,
  ]);

  return (
    <>
      <SelectInput
        currentValue={currentValue}
        options={options}
        onSelectValue={internalOnSelect}
        isInvalid={!isSelectedOptionValidOrEmpty}
      />
      <UploadFileInput
        type="file"
        ref={uploadScriptFileRef}
        onChange={(e) => {
          uploadScriptFile(e);
          e.target.value = '';
        }}
      />
    </>
  );
};

export default DataFileParameter;
