import { t } from '@lingui/macro';
import { PortSide } from 'app/common_types/PortTypes';
import { blockClassLookup } from 'app/generated_blocks/';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import {
  nodeTypeIsAdder,
  nodeTypeIsDynamicBlock,
  nodeTypeIsProduct,
  nodeTypeIsSubdiagram,
} from 'app/helpers';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { getPortDisplayName } from 'app/utils/portConditionUtils';
import {
  canAddPort,
  canDeletePort,
  canRenamePort,
} from 'app/utils/portOperationUtils';
import { getDefaultPortParameters } from 'app/utils/portUtils';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import { isValidBlockPortNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import BlockInportSwitch from './BlockInportSwitch';
import BlockPortParameters from './BlockPortParameter';

type Props = {
  parentPath: string[];
  selectedNode: NodeInstance;
  canEdit: boolean;
};

const BlockInportDetails: React.FC<Props> = ({
  parentPath,
  selectedNode,
  canEdit,
}) => {
  const dispatch = useAppDispatch();

  const blockClass = blockClassLookup(selectedNode.type);

  const minDynamicInputCount = blockClass.ports.inputs?.dynamic?.min_count || 0;

  const canShowAddButton =
    canEdit &&
    !nodeTypeIsSubdiagram(selectedNode.type) &&
    !nodeTypeIsDynamicBlock(selectedNode.type);
  const canAddInput = canEdit && canAddPort(selectedNode, PortSide.Input);

  const isAdderBlock = nodeTypeIsAdder(selectedNode.type);
  const isProductBlock = nodeTypeIsProduct(selectedNode.type);

  // Always show Inputs/Outputs header for Submodels
  const showInputsHeader =
    canAddInput ||
    nodeTypeIsSubdiagram(selectedNode.type) ||
    selectedNode.inputs.length > 0;

  const addInput = React.useCallback(() => {
    const parameters = getDefaultPortParameters(
      blockClass.ports.inputs?.dynamic?.parameter_definitions,
    );
    dispatch(
      modelActions.addPort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Input,
        parameters,
      }),
    );

    // TODO refactor these operations so that the hidden operator
    // parameter change can be changed atomically with the add port change
    if (isAdderBlock) {
      const operatorsParameter = selectedNode.parameters.operators;
      if (!operatorsParameter) {
        console.error('no operators parameter found for block', selectedNode);
        return;
      }
      const newOperators = `${operatorsParameter.value}+`;
      dispatch(
        modelActions.changeBlockParameter({
          parentPath,
          nodeUuid: selectedNode.uuid,
          paramName: 'operators',
          value: newOperators,
        }),
      );
    }
    if (isProductBlock) {
      const operatorsParameter = selectedNode.parameters.operators;
      if (!operatorsParameter) {
        console.error('no operators parameter found for block', selectedNode);
        return;
      }
      const newOperators = `${operatorsParameter.value}*`;
      dispatch(
        modelActions.changeBlockParameter({
          parentPath,
          nodeUuid: selectedNode.uuid,
          paramName: 'operators',
          value: newOperators,
        }),
      );
    }
  }, [
    blockClass.ports.inputs?.dynamic?.parameter_definitions,
    dispatch,
    parentPath,
    selectedNode,
    isAdderBlock,
    isProductBlock,
  ]);

  const removeInput = (inputId: number) => () => {
    dispatch(
      modelActions.removePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Input,
        portId: inputId,
      }),
    );

    // TODO refactor these operations so that the hidden operator
    // parameter change can be changed atomically with the add port change
    if (isAdderBlock || isProductBlock) {
      const operatorsParameter = selectedNode.parameters.operators;
      if (!operatorsParameter) {
        console.error('no operators parameter found for block', selectedNode);
        return;
      }
      const newOperators =
        operatorsParameter.value.slice(0, inputId) +
        operatorsParameter.value.slice(inputId + 1);
      dispatch(
        modelActions.changeBlockParameter({
          parentPath,
          nodeUuid: selectedNode.uuid,
          paramName: 'operators',
          value: newOperators,
        }),
      );
    }
  };

  const renameInput = (inputId: number, newPortName: string) => {
    dispatch(
      modelActions.renamePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Input,
        portId: inputId,
        newPortName,
      }),
    );
  };

  return (
    <>
      {showInputsHeader && (
        <SectionHeading
          testId="inputs"
          onButtonClick={canShowAddButton ? addInput : undefined}
          isButtonEnabled={canAddInput && canEdit}
          buttonTooltip={t({
            id: 'blockDetails.addInputButtonTooltip',
            message: 'Add input',
          })}>
          {t({
            id: 'blockDetails.PortInputsTitle',
            message: 'Inputs',
          })}
        </SectionHeading>
      )}
      {(selectedNode.inputs.length > 0 || canAddInput) && (
        <DetailInputRowsSection>
          {selectedNode.inputs.map((input, i) => (
            <div key={input.name || `row-input[${i}]`}>
              <DetailsSection>
                <DetailsInput
                  grow
                  testId={`block-input-name-${i}`}
                  value={getPortDisplayName(selectedNode.type, input.name)}
                  placeholder={`Unnamed input ${i}`}
                  onSubmitValue={(newName) => renameInput(i, newName)}
                  disabled={!canEdit || !canRenamePort(selectedNode)}
                  validationRules={isValidBlockPortNameRuleSet(selectedNode, {
                    id: i,
                    side: PortSide.Input,
                  })}
                />
                {(isAdderBlock || isProductBlock) && (
                  <BlockInportSwitch
                    parentPath={parentPath}
                    selectedNode={selectedNode}
                    portIndex={i}
                    disabled={!canEdit}
                  />
                )}
                {canEdit &&
                  canDeletePort(
                    selectedNode,
                    minDynamicInputCount,
                    input,
                    PortSide.Input,
                  ) && (
                    <Button
                      testId={`block-input-remove-button-${i}`}
                      variant={ButtonVariants.LargeTertiary}
                      Icon={Remove}
                      onClick={removeInput(i)}
                      disabled={!canEdit}
                    />
                  )}
              </DetailsSection>
              <BlockPortParameters
                parentPath={parentPath}
                selectedNode={selectedNode}
                canEdit={canEdit}
                port={input}
                index={i}
                paramDefinitions={
                  blockClass.ports.inputs?.dynamic?.parameter_definitions
                }
                portSide={PortSide.Input}
              />
            </div>
          ))}
        </DetailInputRowsSection>
      )}
    </>
  );
};

export default BlockInportDetails;
