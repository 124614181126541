import { t } from '@lingui/macro';
import { useProjectActions } from 'app/api/useProjectActions';
import { useTemplateProjects } from 'app/api/useTemplateProjects';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Input from 'ui/common/Input/Input';
import { isRequiredRule } from 'ui/common/Input/inputValidation';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import SelectInput from 'ui/common/SelectInput';
import Textarea from 'ui/common/Textarea';

export type LabelOption = {
  readonly label: string;
  readonly value: string;
};

const initialTemplateOptions: { value: string; label: string }[] = [
  {
    value: '',
    label: 'Blank (no template)',
  },
];

const NewProjectModal: React.FC = () => {
  const onboardingTourStatus = useAppSelector(
    (state) => state.tour.statuses.onboarding,
  );
  const [isValid, setIsValid] = React.useState(false);
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const { templateProjects, copyFromTemplateProject } = useTemplateProjects();

  const [templateUuid, setTemplateUuid] = React.useState('');
  const [templateOptions, setTemplateOptions] = React.useState(
    initialTemplateOptions,
  );
  const [loading, setLoading] = React.useState(false);
  const { closeModal } = useModal();
  const navigate = useNavigate();

  const { createProject } = useProjectActions();

  React.useEffect(() => {
    if (onboardingTourStatus === 'active' && templateProjects) {
      const tutorialProject = templateProjects.template_projects.find(
        (tp) => tp.project.title === 'Tutorial Project',
      );
      if (tutorialProject) {
        const templateOptions = [
          { value: tutorialProject.uuid, label: tutorialProject.project.title },
        ];
        setTemplateUuid(templateOptions[0].value);
        setTemplateOptions(templateOptions);
      }
    } else if (templateProjects) {
      let allTemplateOptions: { value: string; label: string }[] =
        templateProjects.template_projects.map((tp) => ({
          value: tp.uuid,
          label: tp.project.title,
        }));

      allTemplateOptions = allTemplateOptions.concat(initialTemplateOptions);
      setTemplateOptions(allTemplateOptions);
    }
  }, [onboardingTourStatus, templateProjects]);

  const onCreate = async () => {
    if (!isValid) return;
    if (name) {
      setLoading(true);
      let project;
      // If a template has been selected, then use template projects API to copy the selected template project
      if (templateUuid && templateUuid !== '') {
        project = await copyFromTemplateProject({
          templateProjectUuid: templateUuid,
          projectCreateRequest: { title: name, description },
        });
      } else {
        project = await createProject({
          projectCreateRequest: { title: name, description },
        });
      }
      setLoading(false);
      closeModal();
      if (project) {
        navigate(`/projects/${project.uuid}`);
      }
    }
  };

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onCreate();
      }}>
      <fieldset>
        <legend>Create new project</legend>
        <ModalInputGroup>
          <Label testId="project-name-label">
            {t({
              id: 'newProjectModal.nameInput.label',
              message: 'Give your project a name',
            })}
          </Label>
          <Input
            value={name}
            onChangeText={(v, isValid) => {
              setName(v);
              setIsValid(isValid);
            }}
            hasBorder
            testId="project-name-textbox"
            autoFocus
            validationRules={[isRequiredRule]}
          />
        </ModalInputGroup>
        <ModalInputGroup>
          <Label testId="project-template-label">
            {t({
              id: 'newProjectModal.templateInput.label',
              message: 'Choose a template',
            })}
          </Label>
          <SelectInput
            options={templateOptions}
            onSelectValue={(value: string) => setTemplateUuid(value)}
            currentValue={templateUuid}
          />
        </ModalInputGroup>
        <ModalInputGroup>
          <Label testId="project-description-label">
            {t({
              id: 'newProjectModal.descriptionInput.label',
              message: 'Briefly describe the project (optional)',
            })}
          </Label>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            resizable={false}
            rows={3}
            testId="project-description-textarea"
          />
        </ModalInputGroup>
      </fieldset>
      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}
          testId="cancel-new-project-button">
          {t({
            id: 'newProjectModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Create button */}
        <Button
          type="submit"
          loading={loading}
          variant={ButtonVariants.LargePrimary}
          disabled={!isValid}
          className="tour-onboarding-2"
          testId="create-new-project-button">
          {t({
            id: 'newProjectModal.createButton.label',
            message: 'Create',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default NewProjectModal;
