import styled from '@emotion/styled/macro';
import { Small } from 'ui/common/typography/Typography';

export const ItemSection = styled.div<{
  selected: boolean;
  nestedLayer: number;
}>`
  height: ${({ theme }) => theme.spacing.xlarge};
  display: flex;
  align-items: center;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  background: ${({ theme, selected }) =>
    selected ? theme.colors.tint.tint1 : 'transparent'};
  padding-left: ${({ nestedLayer }) => 22 * nestedLayer}px;
  cursor: pointer;
  white-space: nowrap;
  margin-top: ${({ theme }) => theme.spacing.small};
  position: relative;
`;

export const ModelTreeIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const ModelBlockItemText = styled(Small)`
  white-space: nowrap;
  user-select: none;
`;
