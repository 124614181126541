import styled from '@emotion/styled';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { useTheme } from '@emotion/react';
import { useAdminApis } from 'app/api/useAdminApis';
import { ProjectKind } from 'app/apiGenerated/generatedApiTypes';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { BackArrow, Reload, Remove } from 'ui/common/Icons/Standard';
import Table from 'ui/common/Table/Table';
import * as Headings from 'ui/common/typography/Typography';
import { formatDate } from 'util/dateUtils';

const Head = styled.header(({ theme }) => ({
  display: 'flex',
  width: ' 100%',
  padding: 10,
  columnGap: 8,
}));

const Title = styled(Headings.H2)(({ theme }) => ({
  width: '100%',
}));

const GlobalProjectsWrapper = styled.div(({ theme }) => ({
  width: `calc(100% - ${theme.spacing.xxlarge} - ${theme.spacing.xxlarge})`,
  height: `calc(100% - ${theme.spacing.xxlarge} - ${theme.spacing.xxlarge})`,
  margin: theme.spacing.xlarge,
  padding: theme.spacing.large,
  background: theme.colors.grey[5],
  borderRadius: theme.spacing.small,
  overflowX: 'auto',
  overflowY: 'auto',
}));

const GlobalProjectsActions = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '24px',
});

const ActionSection = styled.div(({ theme }) => ({
  alignItems: 'center',
}));

const SubHead = styled.header(({ theme }) => ({
  display: 'flex',
  width: ' 100%',
}));

const SubTitle = styled(Headings.H3)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing.xlarge,
}));

const TemplateProjectsWrapper = styled.div(({ theme }) => ({
  padding: 10,
  marginBottom: theme.spacing.xlarge,
}));

const DefaultProjectsWrapper = styled.div(({ theme }) => ({
  padding: 10,
  marginBottom: theme.spacing.xlarge,
}));

interface GlobalProjectsTableData extends Record<string, string> {
  created_at: string;
  updated_at: string;
  uuid: string;
  title: string;
  owner: string;
  kind: ProjectKind;
}

const ActionCell = (cell: Cell<GlobalProjectsTableData>) => {
  const theme = useTheme();
  const { deleteGlobalProject } = useAdminApis();
  const { uuid, title, owner, kind } = cell.row.original;
  return (
    <GlobalProjectsActions>
      <Button
        variant={ButtonVariants.SmallTertiary}
        title="Delete global project"
        Icon={Remove}
        tint={theme.colors.ui.error}
        onClick={() => {
          if (
            confirm(`Delete global project ${title} simulation by ${owner}?`)
          ) {
            deleteGlobalProject(uuid, kind);
          }
        }}
      />
    </GlobalProjectsActions>
  );
};

const DateCell: React.FC<{ value: string }> = ({ value }) => (
  <span title={value}>{formatDate(value)}</span>
);

const GlobalProjects: React.FC = () => {
  const {
    templateGlobalProjects,
    refetchTemplateGlobalProjects,
    defaultGlobalProjects,
    refetchDefaultGlobalProjects,
    createGlobalProject,
  } = useAdminApis();
  const navigate = useNavigate();

  if (!templateGlobalProjects || !defaultGlobalProjects)
    return <span>Loading</span>;
  const templateProjectsTableData: GlobalProjectsTableData[] =
    templateGlobalProjects.projects.map((p) => ({
      created_at: p.created_at,
      updated_at: p.updated_at,
      title: p.title,
      uuid: p.uuid,
      owner: p.owner?.display_name || 'Unknown',
      kind: 'Template',
    }));

  const defaultProjectsTableData: GlobalProjectsTableData[] =
    defaultGlobalProjects.projects.map((p) => ({
      created_at: p.created_at,
      updated_at: p.updated_at,
      title: p.title,
      uuid: p.uuid,
      owner: p.owner?.display_name || 'Unknown',
      kind: 'Default',
    }));

  const tableColumns: Column<GlobalProjectsTableData>[] = [
    {
      id: 'title',
      Header: 'Project Title',
      accessor: 'title',
    },
    {
      id: 'uuid',
      Header: 'UUID',
      accessor: 'uuid',
    },
    {
      id: 'owner',
      Header: 'Owner',
      accessor: 'owner',
    },
    {
      id: 'action',
      Header: 'Actions',
      accessor: 'status',
      maxWidth: 50,
      Cell: ActionCell,
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      Cell: DateCell,
      minWidth: 150,
      maxWidth: 0,
    },
    {
      Header: 'Updated At',
      accessor: 'updated_at',
      Cell: DateCell,
      minWidth: 150,
      maxWidth: 0,
    },
  ];

  return (
    <GlobalProjectsWrapper>
      <ActionSection>
        <Head>
          <Title>Global Projects</Title>
          <Button
            variant={ButtonVariants.LargeSecondary}
            Icon={BackArrow}
            onClick={() => navigate('/admin')}>
            Admin portal
          </Button>
          <Button
            variant={ButtonVariants.LargeSecondary}
            Icon={Reload}
            onClick={() => {
              refetchTemplateGlobalProjects();
              refetchDefaultGlobalProjects();
            }}>
            Refresh
          </Button>
        </Head>
      </ActionSection>
      <TemplateProjectsWrapper>
        <SubHead>
          <SubTitle>Template Projects</SubTitle>
          <Button
            onClick={() => {
              const projectUuid = prompt(
                'Project UUID to make it a template project',
              );
              if (projectUuid) createGlobalProject(projectUuid, 'Template');
            }}>
            Add new template project
          </Button>
        </SubHead>
        <Table data={templateProjectsTableData} columns={tableColumns} />
      </TemplateProjectsWrapper>
      <DefaultProjectsWrapper>
        <SubHead>
          <SubTitle>Default Projects</SubTitle>
          <Button
            onClick={() => {
              const projectUuid = prompt(
                'Project UUID to make it a default project',
              );
              if (projectUuid) createGlobalProject(projectUuid, 'Default');
            }}>
            Add new default project
          </Button>
        </SubHead>
        <Table data={defaultProjectsTableData} columns={tableColumns} />
      </DefaultProjectsWrapper>
    </GlobalProjectsWrapper>
  );
};

export default GlobalProjects;
