import styled from '@emotion/styled';
import { SimulationRefAccess } from 'app/sliceRefAccess/SimulationRefAccess';
import { SubmodelRefAccess } from 'app/sliceRefAccess/SubmodelRefAccess';
import AppRouter from 'AppRouter';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HashRouter } from 'react-router-dom';
import { Theme, ThemeStore } from 'theme';
import Onboarding from 'tours/onboarding/Onboarding';
import UserSessionTracker from 'ui/auth/UserSessionTracker';
import { ModalProvider } from 'ui/common/Modal/ModalProvider';
import { Notification } from 'ui/common/notifications/Notification';
import { Navbar } from 'ui/navbar/Navbar';
import NotebookIframe from 'ui/notebook/NotebookIframe';
import UserPreferencesLoader from 'ui/userPreferences/UserPreferencesLoader';

const AppLayoutWrapper = styled.div`
  isolation: isolate;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const AppContentWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
`;

const AppOfflineLayout: React.FC = () => (
  <ThemeStore themeValue="base">
    <Theme>
      <HashRouter>
        <ModalProvider>
          <AppLayoutWrapper>
            <Onboarding />
            {/* Non-visual components that should be created exactly once when the user is signed in and whose lifetime should match the user session. */}
            <NotebookIframe />
            <UserPreferencesLoader />
            <UserSessionTracker />
            <SubmodelRefAccess />
            <SimulationRefAccess />

            {/* App navbar */}
            <Navbar />

            {/* App content */}
            <AppContentWrapper>
              <DndProvider backend={HTML5Backend}>
                <AppRouter />
              </DndProvider>
            </AppContentWrapper>

            {/* Notifications live below the navbar and above the content. */}
            <Notification />
          </AppLayoutWrapper>
        </ModalProvider>
      </HashRouter>
    </Theme>
  </ThemeStore>
);

export default AppOfflineLayout;
