import { NVGcolor } from 'nanovg-js/bind-nanovg';

export const nvgColorToHex = (color: NVGcolor): string =>
  [color.r, color.g, color.b]
    .map((n) => {
      const hex = Math.floor(n * 255).toString(16);
      if (hex.length === 1) return `0${hex}`;
      return hex;
    })
    .join('');
