import { t } from '@lingui/macro';
import { useJupyter } from 'app/api/useJupyter';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Notebook } from 'ui/common/Icons/Standard';
import { useModal } from 'ui/common/Modal/useModal';
import AddNotebookModal from './AddNotebookModal';

interface Props {
  project: Project;
  hubFiles: any;
}

export const ProjectDetailJupyterButton: React.FC<Props> = ({ project }) => {
  const { triggerModal } = useModal();
  const { status: hubStatus } = useJupyter();

  const isJupyterReady = hubStatus?.status === 'online';

  const openCreateNotebookModal = () => {
    triggerModal(
      <AddNotebookModal projectId={project.uuid} />,
      t`Create a new notebook`,
    );
  };

  return (
    <Button
      variant={ButtonVariants.SmallSecondary}
      disabled={!isJupyterReady}
      testId="project-detail-new-notebook-button"
      Icon={Notebook}
      onClick={openCreateNotebookModal}>
      {t({
        id: 'dashboard.projectsDetailHeader.newNotebookButton.Label',
        message: 'New Notebook',
      })}
    </Button>
  );
};
