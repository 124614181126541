import React from 'react';
import { matchPath, PathMatch, useLocation } from 'react-router-dom';
import {
  FixedProjectPath,
  PROJECT_FILTER_SUBPATHS,
} from './projectFilterUtils';

interface UrlIds {
  projectId?: string;
  modelId?: string;
  explorationId?: string;
  versionId?: string;
  fileName?: string;
  scriptFileUuid?: string;
  userId?: string;
  optionId?: string;
  busTypeId?: string;
  chatSessionId?: string;
}

const UUID_ZERO = '00000000-0000-0000-0000-000000000000';
const UUID_V4_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[8|9|aA|bB][0-9a-f]{3}-[0-9a-f]{12}$/i;

export type PROJECT_PATH_TYPE = 'public' | 'sharedWithMe';

export const uuidV4OrNull = (str: string | undefined): string | undefined => {
  if ((!!str && UUID_V4_REGEX.test(str)) || str === UUID_ZERO) {
    return str;
  }

  console.error('Parameter %s is not a valid UUID', str);
  return undefined;
};

/**
 * ! Read this before adding more params to this hook. It probably doesn't belong here.
 *
 * This hook has taken over the role of `useParams`, but it's not a good replacement.
 * It's a special case, not the default.
 *
 * Instead of using the current route context, it duplicates `AppRouter` logic, including the ordering
 * of ambiguous routes, in order to expose nested route params to components higher up in the tree.
 * (Is there a better way than to duplicate the router logic?)
 *
 * For components in nested routes, this is just extra code and extra matching.
 * `useParams` works perfectly fine to match the current route only, and return the correct params.
 *
 * Ideally we pare this hook down to only include special params that need that type of global exposure
 * and go back to using `useParams` and smaller, case-specific validators for other components.
 */
export function useAppParams(): UrlIds {
  const location = useLocation();
  const urlParams = React.useMemo(() => {
    // Check for project/model/version route.
    const modelVersionMatch: PathMatch<
      'projectId' | 'modelId' | 'versionId'
    > | null = matchPath(
      '/projects/:projectId/models/:modelId/versions/:versionId',
      location.pathname,
    );
    if (modelVersionMatch) {
      return {
        projectId: uuidV4OrNull(modelVersionMatch.params.projectId),
        modelId: uuidV4OrNull(modelVersionMatch.params.modelId),
        versionId: uuidV4OrNull(modelVersionMatch.params.versionId),
      };
    }

    // Check for project/model route.
    const modelMatch: PathMatch<'projectId' | 'modelId'> | null = matchPath(
      '/projects/:projectId/models/:modelId',
      location.pathname,
    );
    if (modelMatch) {
      return {
        projectId: uuidV4OrNull(modelMatch.params.projectId),
        modelId: uuidV4OrNull(modelMatch.params.modelId),
      };
    }

    // Check for data integrations (git, s3, ...) route.
    const integrationsMatch: PathMatch<'projectId'> | null = matchPath(
      '/projects/:projectId/integrations',
      location.pathname,
    );
    if (integrationsMatch) {
      return {
        projectId: uuidV4OrNull(integrationsMatch.params.projectId),
      };
    }

    // Check for project/exploration route.
    const explorationMatch: PathMatch<'projectId' | 'explorationId'> | null =
      matchPath(
        '/projects/:projectId/explorations/:explorationId',
        location.pathname,
      );
    if (explorationMatch) {
      return {
        projectId: uuidV4OrNull(explorationMatch.params.projectId),
        explorationId: uuidV4OrNull(explorationMatch.params.explorationId),
      };
    }

    // Check for project/notebook route.
    const notebookMatch: PathMatch<'projectId' | 'fileName'> | null = matchPath(
      '/projects/:projectId/notebook/:fileName',
      location.pathname,
    );
    if (notebookMatch) {
      return {
        projectId: uuidV4OrNull(notebookMatch.params.projectId),
        fileName: notebookMatch.params.fileName,
      };
    }

    // Check for python script editor route.
    const scriptEditMatch: PathMatch<'projectId' | 'fileUuid'> | null =
      matchPath(
        '/projects/:projectId/python_script/:fileUuid',
        location.pathname,
      );
    if (scriptEditMatch) {
      return {
        projectId: uuidV4OrNull(scriptEditMatch.params.projectId),
        scriptFileUuid: uuidV4OrNull(scriptEditMatch.params.fileUuid),
      };
    }

    // Check for project requirements editor routes.
    const requirementsMatch: PathMatch<'projectId'> | null = matchPath(
      '/projects/:projectId/requirements',
      location.pathname,
    );
    if (requirementsMatch) {
      return {
        projectId: uuidV4OrNull(requirementsMatch.params.projectId),
      };
    }

    // Check for project bus type editor routes.
    const busTypesMatch: PathMatch<'projectId' | 'busTypeId'> | null =
      matchPath('/projects/:projectId/bus_types/:busTypeId', location.pathname);
    if (busTypesMatch) {
      return {
        projectId: uuidV4OrNull(busTypesMatch.params.projectId),
        busTypeId: uuidV4OrNull(busTypesMatch.params.busTypeId),
      };
    }

    // Check for project only (dashboard) route.
    const projectMatch: PathMatch<'projectId'> | null = matchPath(
      '/projects/:projectId',
      location.pathname,
    );
    if (projectMatch && projectMatch.params.projectId) {
      if (
        PROJECT_FILTER_SUBPATHS.includes(
          projectMatch.params.projectId as FixedProjectPath, // Cast the string to the union type since we only check for membership.
        )
      ) {
        return {};
      }
      return {
        projectId: uuidV4OrNull(projectMatch.params.projectId),
      };
    }

    const adminUserMatch: PathMatch<'userId'> | null = matchPath(
      '/admin/users/:userId',
      location.pathname,
    );
    if (adminUserMatch) {
      return {
        userId: uuidV4OrNull(adminUserMatch.params.userId),
      };
    }

    const adminUserOptionMatch: PathMatch<'optionId'> | null = matchPath(
      '/admin/user_options/:optionId',
      location.pathname,
    );
    if (adminUserOptionMatch) {
      return {
        optionId: uuidV4OrNull(adminUserOptionMatch.params.optionId),
      };
    }

    const chatSessionIdMatch: PathMatch<'chatSessionId'> | null = matchPath(
      '/admin/chat_session/:chatSessionId',
      location.pathname,
    );
    if (chatSessionIdMatch) {
      return {
        chatSessionId: uuidV4OrNull(chatSessionIdMatch.params.chatSessionId),
      };
    }

    // Other routes don't have any url parameters.
    return {};
  }, [location.pathname]);

  return urlParams;
}
