import styled from '@emotion/styled/macro';
import { useJupyter } from 'app/api/useJupyter';
import { CellFormat } from 'app/slices/jupyterSlice';
import Select from 'react-select';

interface Props {
  isNotebookFile: boolean;
}

const SelectWrapper = styled(Select)`
  > * {
    width: 100px;
    min-width: 100px;
    flex: 1;
  }

  .react-select__container {
    background: transparent;
    height: ${(props) => props.theme.spacing.xlarge};
  }

  .react-select__control {
    background-color: transparent !important;
    border-color: #405b5b;
    table-layout: fixed;
    height: ${(props) => props.theme.spacing.xlarge};
    min-height: ${(props) => props.theme.spacing.xlarge};
    width: 100%;
  }

  .react-select__menu {
    color: black;
  }

  .react-select__indicators {
    height: ${(props) => props.theme.spacing.xlarge};
    width: 20px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicators {
    justify-content: center;
    align-items: center;
    background: transparent;
  }

  .react-select__dropdown-indicator {
    padding: 0;
    position: absolute;
  }

  .react-select__value-container {
    height: ${(props) => props.theme.spacing.xlarge};
    line-height: ${(props) => props.theme.spacing.xlarge};
    padding: 0 0 0 ${({ theme }) => theme.spacing.normal};
  }

  .react-select__single-value {
    margin: 0;
    height: ${(props) => props.theme.spacing.xlarge};
    line-height: ${(props) => props.theme.spacing.xlarge};
    color: white !important;
  }

  .react-select__input-container {
    padding: 0;
    margin: 0;
    line-height: ${(props) => props.theme.spacing.xlarge};
  }

  .react-select__indicator {
    color: #adb8b8 !important;
  }
`;

const CellFormatMenu: React.FC<Props> = ({ isNotebookFile }) => {
  const { cellFormat, setCellFormat, changeIframeCellFormat } = useJupyter();

  const options = [
    { value: '-', label: '-' },
    { value: 'code', label: 'python3' },
    { value: 'markdown', label: 'markdown' },
    { value: 'raw', label: 'raw' },
  ];

  if (!isNotebookFile) {
    return null;
  }

  return (
    <SelectWrapper
      classNamePrefix="react-select"
      options={options}
      value={cellFormat}
      onChange={(newValue: unknown) => {
        const existValue = newValue as CellFormat;
        setCellFormat(existValue);
        changeIframeCellFormat(existValue);
      }}
    />
  );
};

export default CellFormatMenu;
