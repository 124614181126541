/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Link: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2426 5.63604L18.364 7.75736C18.5592 7.95262 18.5592 8.26921 18.364 8.46447L15.5355 11.2929C15.3403 11.4882 15.0237 11.4882 14.8284 11.2929L13.7678 12.3536C14.5488 13.1346 15.8151 13.1346 16.5962 12.3536L19.4246 9.52513C20.2057 8.74408 20.2057 7.47775 19.4246 6.6967L17.3033 4.57538C16.5222 3.79433 15.2559 3.79433 14.4749 4.57538L11.6464 7.40381C10.8654 8.18486 10.8654 9.45119 11.6464 10.2322L12.7071 9.17157C12.5118 8.97631 12.5118 8.65973 12.7071 8.46447L15.5355 5.63604C15.7308 5.44078 16.0474 5.44078 16.2426 5.63604Z"
      fill={props.fill}
    />
    <path
      d="M15.3588 8.64125C15.6517 8.93414 15.6517 9.40902 15.3588 9.70191L9.7019 15.3588C9.40901 15.6517 8.93414 15.6517 8.64124 15.3588C8.34835 15.0659 8.34835 14.591 8.64124 14.2981L14.2981 8.64125C14.591 8.34836 15.0659 8.34836 15.3588 8.64125Z"
      fill={props.fill}
    />
    <path
      d="M7.40381 11.6465C8.18486 10.8654 9.45119 10.8654 10.2322 11.6465L9.17157 12.7071C8.97631 12.5119 8.65973 12.5119 8.46447 12.7071L5.63604 15.5355C5.44078 15.7308 5.44078 16.0474 5.63604 16.2426L7.75736 18.364C7.95262 18.5592 8.26921 18.5592 8.46447 18.364L11.2929 15.5355C11.4882 15.3403 11.4882 15.0237 11.2929 14.8284L12.3536 13.7678C13.1346 14.5488 13.1346 15.8152 12.3536 16.5962L9.52513 19.4246C8.74408 20.2057 7.47775 20.2057 6.6967 19.4246L4.57538 17.3033C3.79433 16.5223 3.79433 15.2559 4.57538 14.4749L7.40381 11.6465Z"
      fill={props.fill}
    />
  </svg>
);

Link.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Link;
