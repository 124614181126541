import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { getIsCurrentDiagramReadonly } from 'app/utils/modelDiagramUtils';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { CODE_EDITOR_BLOCK_QUERY_PARAM } from 'ui/codeEditor/CodeEditor';
import { AddAnnotation, CreateGroup } from 'ui/common/Icons/Standard';
import CreateSubmodel from 'ui/common/Icons/Standard/CreateSubmodel';
import { useModal } from 'ui/common/Modal/useModal';
import AddReferenceSubmodelModal from 'ui/modelEditor/AddReferenceSubmodelModal';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';
import ContainersMenu from './ContainersMenu';
import { NavbarButton, NavbarSpace, NavbarSplitButton } from './NavbarButtons';

export const DiagramTools: React.FC = () => {
  const dispatch = useAppDispatch();

  const { triggerModal } = useModal();

  const { areUserOptionsLoaded } = useAppSelector((state) => state.userOptions);

  const [searchParams] = useSearchParams();
  const codeEditorOpen = !!searchParams.get(CODE_EDITOR_BLOCK_QUERY_PARAM);

  const { projectId, modelId, versionId } = useAppParams();
  const { arePermissionsLoaded, canEditCurrentModelVersion } =
    useModelPermission(projectId, modelId, versionId);

  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  const referenceSubmodelId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelReferenceId,
  );

  const isDiagramReadonly = getIsCurrentDiagramReadonly({
    modelId,
    loadedModelId,
    referenceSubmodelId,
    arePermissionsLoaded,
    canEditCurrentModelVersion,
  });

  const selectedBlockCount = useAppSelector(
    (state) => state.model.present.selectedBlockIds.length,
  );

  const showSubmodelButtons = canEditCurrentModelVersion && !isDiagramReadonly;

  const openCreateReferenceSubmodelModal = () => {
    if (!projectId) return;

    triggerModal(
      <AddReferenceSubmodelModal projectId={projectId} />,
      t({
        id: 'addReferenceSubmodelModal.title',
        message: 'Create a submodel',
      }),
    );
  };

  const showGroupButton = canEditCurrentModelVersion && !isDiagramReadonly;

  // TODO add extra checks here for allowed ports if needed
  const iteratorButtonEnabled = selectedBlockCount > 0;

  const addingAnnotation = useAppSelector(
    (state) => state.uiFlags.addingAnnotation,
  );

  const initiateAddingAnnotation = () => {
    dispatch(uiFlagsActions.toggleUIFlag('addingAnnotation'));
  };

  const showAnnotationButton = canEditCurrentModelVersion && !isDiagramReadonly;

  if (!areUserOptionsLoaded || codeEditorOpen) {
    return null;
  }

  return (
    <>
      {/* Create submodel button */}
      {showSubmodelButtons && (
        <NavbarButton
          tooltipText={t({
            id: 'navBar.createSubmodelTooltip',
            message: 'Create submodel',
          })}
          Icon={CreateSubmodel}
          isEnabled={selectedBlockCount === 0}
          onClickHandler={openCreateReferenceSubmodelModal}
          testId="navbar-create-submodel-button"
        />
      )}

      {showGroupButton ? (
        <NavbarSplitButton
          tooltipText={t({
            id: 'navBar.createGroupTooltip',
            message: 'Create group',
          })}
          Icon={CreateGroup}
          isEnabled
          onClickHandler={() =>
            dispatch(
              modelActions.createSubdiagramFromSelection({
                subdiagramType: 'core.Group',
              }),
            )
          }
          testId="navbar-create-group-split-button"
          DropdownContent={ContainersMenu}
          dropdownOffset={-32}
        />
      ) : (
        <NavbarButton
          tooltipText={t({
            id: 'navBar.createGroupTooltip',
            message: 'Create group',
          })}
          Icon={CreateGroup}
          isEnabled={selectedBlockCount > 1}
          onClickHandler={() =>
            dispatch(
              modelActions.createSubdiagramFromSelection({
                subdiagramType: 'core.Group',
              }),
            )
          }
          testId="navbar-create-group-split-button"
        />
      )}

      <NavbarSpace />

      {showAnnotationButton && (
        <NavbarButton
          highlighted={addingAnnotation}
          tooltipText={t({
            id: 'navBar.addAnnotationButton.label',
            message: 'Add Annotation',
          })}
          Icon={AddAnnotation}
          isEnabled
          onClickHandler={initiateAddingAnnotation}
          testId="navbar-add-annotation-button"
        />
      )}
    </>
  );
};
