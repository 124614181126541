// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const ExperimentModel: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "ExperimentModel",
      "namespace": "core",
      "description": "This is a stub to allow a model to render as a block inside an Experiment model",
      "feature_level": "dev",
      "supports_experiments": false,
      "supports_submodels": false,
      "supports_models": false
    },
    "modes": {
      "feedthrough": false,
      "time": "discrete"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "parameters",
            "appearance": "hollow"
          }
        ],
        "dynamic": {
          "max_count": 999
        }
      },
      "outputs": {
        "dynamic": {
          "max_count": 999
        }
      }
    },
    "parameter_definitions": [
      {
        "name": "model",
        "description": "The model to run simulations with",
        "display_full_width": true,
        "data_type": "string",
        "default_value": ""
      },
      {
        "name": "model_version",
        "description": "UUID of the model version to run the experiment with (defaults to latest)",
        "display_full_width": true,
        "data_type": "string",
        "default_value": "latest"
      }
    ]
  }
);
