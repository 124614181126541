import { PortSide } from 'app/common_types/PortTypes';
import { blockClassLookup } from 'app/generated_blocks/';
import { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import {
  BlockInstance,
  ModelDiagram,
  SubmodelInstance,
} from 'app/generated_types/SimulationModel';
import {
  getSubmodelPortIdOfIoNode,
  nodeTypeIsConditional,
  setPortIdMut,
} from 'app/helpers';
import { PortCondition, getPortConditions } from 'app/utils/portConditionUtils';

export interface UpdatedPortInfo {
  nodeUuid: string;
  side: PortSide;
  index: number;
  oldIndex?: number; // used for reindexedPorts
}

interface UpdatedPorts {
  removedPorts?: UpdatedPortInfo[];
  reindexedPorts?: UpdatedPortInfo[];
}

interface ConditionalPortDefinition {
  name: string;
  order?: number;
  default_enabled?: boolean;
}

const getConditionalPortDefinition = (
  blockClass: ComputationBlockClass,
  side: PortSide,
  portName: string,
): ConditionalPortDefinition | null => {
  if (!portName) return null;

  if (side === PortSide.Input) {
    return (
      blockClass.ports.inputs?.conditional?.find(
        (port) => port.name === portName,
      ) || null
    );
  }
  if (side === PortSide.Output) {
    return (
      blockClass.ports.outputs?.conditional?.find(
        (port) => port.name === portName,
      ) || null
    );
  }
  return null;
};

export function updateBlockPorts(block: BlockInstance): UpdatedPorts {
  const blockClass = blockClassLookup(block.type);
  if (!blockClass?.base) return {};

  const portConditions: PortCondition[] | null = getPortConditions(block.type);
  if (!portConditions) return {};

  const oldInputs = [...block.inputs];
  const oldOutputs = [...block.outputs];

  let conditionalInputsOffset = 0;
  let conditionalOutputsOffset = 0;
  portConditions.forEach((portCondition) => {
    if (!portCondition) return;

    const shouldHavePort = portCondition.shouldHavePort(block);

    const conditionalPort = portCondition.portName
      ? getConditionalPortDefinition(
          blockClass,
          portCondition.side,
          portCondition.portName,
        )
      : null;

    if (portCondition.side === PortSide.Input) {
      // Update conditional inports.
      if (conditionalPort && conditionalPort.order !== undefined) {
        const orderWithOffset = conditionalPort.order - conditionalInputsOffset;
        const hasPort =
          block.inputs[orderWithOffset]?.name === conditionalPort.name;
        if (shouldHavePort !== hasPort) {
          if (shouldHavePort) {
            block.inputs.splice(orderWithOffset, 0, {
              name: conditionalPort.name,
              kind: 'conditional',
            });
          } else {
            block.inputs.splice(orderWithOffset, 1);
          }
        }

        if (!shouldHavePort) {
          conditionalInputsOffset++;
        }
      }

      // Update dynamic inports.
      if (!shouldHavePort && portCondition.appliesToDynamicPorts) {
        const dynamicPortStartIndex = block.inputs.findIndex(
          (port) => port.kind === 'dynamic',
        );
        if (dynamicPortStartIndex !== -1) {
          block.inputs.splice(dynamicPortStartIndex);
        }
      }
    }

    if (portCondition.side === PortSide.Output) {
      // Update conditional outports.
      if (conditionalPort && conditionalPort.order !== undefined) {
        const orderWithOffset =
          conditionalPort.order - conditionalOutputsOffset;
        const hasPort =
          block.outputs[orderWithOffset]?.name === conditionalPort.name;
        if (shouldHavePort !== hasPort) {
          if (shouldHavePort) {
            block.outputs.splice(orderWithOffset, 0, {
              name: conditionalPort.name,
              kind: 'conditional',
            });
          } else {
            block.outputs.splice(orderWithOffset, 1);
          }
        }

        if (!shouldHavePort) {
          conditionalOutputsOffset++;
        }
      }

      // Update dynamic outports.
      if (!shouldHavePort && portCondition.appliesToDynamicPorts) {
        const dynamicPortStartIndex = block.inputs.findIndex(
          (port) => port.kind === 'dynamic',
        );
        if (dynamicPortStartIndex !== -1) {
          block.inputs.splice(dynamicPortStartIndex);
        }
      }
    }
  });

  const removedPorts: UpdatedPortInfo[] = [];
  const reindexedPorts: UpdatedPortInfo[] = [];

  // Find removed and reindexed inports.
  for (let portIdx = 0; portIdx < oldInputs.length; portIdx++) {
    const oldPort = oldInputs[portIdx];
    const newIdx = block.inputs.findIndex((port) => port.name === oldPort.name);
    if (newIdx === -1) {
      removedPorts.push({
        nodeUuid: block.uuid,
        index: portIdx,
        side: PortSide.Input,
      });
    } else if (newIdx !== portIdx) {
      reindexedPorts.push({
        nodeUuid: block.uuid,
        oldIndex: portIdx,
        index: newIdx,
        side: PortSide.Input,
      });
    }
  }

  // Find removed and reindexed outports.
  for (let portIdx = 0; portIdx < oldOutputs.length; portIdx++) {
    const oldPort = oldOutputs[portIdx];
    const newIdx = block.outputs.findIndex(
      (port) => port.name === oldPort.name,
    );
    if (newIdx === -1) {
      removedPorts.push({
        nodeUuid: block.uuid,
        index: portIdx,
        side: PortSide.Output,
      });
    } else if (newIdx !== portIdx) {
      reindexedPorts.push({
        nodeUuid: block.uuid,
        oldIndex: portIdx,
        index: newIdx,
        side: PortSide.Output,
      });
    }
  }

  return {
    removedPorts,
    reindexedPorts,
  };
}

// Handle insertion and removal of Inport/Outport blocks
// This is very simple in order to "make it work" without having
// to think too much about any kind of edge cases.
// This function works best when port_id has already been set to a valid integer
export function updateModelIOPortBlocksMut(model: ModelDiagram) {
  const inports = model.nodes.filter((n) => n.type === 'core.Inport');
  const sortedInports = inports.sort(
    (a, b) => getSubmodelPortIdOfIoNode(a) - getSubmodelPortIdOfIoNode(b),
  );
  sortedInports.forEach((n, k) => setPortIdMut(n, k));

  const outports = model.nodes.filter((n) => n.type === 'core.Outport');
  const sortedOutports = outports.sort(
    (a, b) => getSubmodelPortIdOfIoNode(a) - getSubmodelPortIdOfIoNode(b),
  );
  sortedOutports.forEach((n, k) => setPortIdMut(n, k));
}

export function updateSubmodelNodeIOPortsMut(
  submodelNode: SubmodelInstance,
  submodelDiagram: ModelDiagram,
) {
  submodelNode.inputs = [];
  submodelNode.outputs = [];

  // Conditional blocks have an `enable` input port at the 0th index.
  if (nodeTypeIsConditional(submodelNode.type)) {
    submodelNode.inputs.push({ name: 'enable', kind: 'static' });
  }

  for (let i = 0; i < submodelDiagram.nodes.length; i++) {
    const node = submodelDiagram.nodes[i];
    if (node.type === 'core.Inport') {
      setPortIdMut(node, submodelNode.inputs.length);
      submodelNode.inputs.push({ name: node.name, kind: 'dynamic' });
    } else if (node.type === 'core.Outport') {
      setPortIdMut(node, submodelNode.outputs.length);
      submodelNode.outputs.push({ name: node.name, kind: 'dynamic' });
    }
  }
}
