import { NodeInstance } from '@collimator/model-schemas-ts';
import { Global, useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import Editor, { useMonaco } from '@monaco-editor/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { notificationsActions } from 'app/slices/notificationsSlice';
import { editor as monacoEditor } from 'monaco-editor/esm/vs/editor/editor.api';
import React, { ReactElement } from 'react';
import { getThemeValue } from 'theme/themes';
import {
  setupAutocomplete,
  setupAutocompleteShortcut,
} from 'ui/codeEditor/AutocompleteHandler';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Code } from 'ui/common/Icons/Small';
import {
  CFile,
  TreeArrowCollapsed,
  TreeArrowExpanded,
} from 'ui/common/Icons/Standard';
import { useWebSocket } from 'ui/common/WebSocketProvider';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import ModelEditorBreadcrumb from 'ui/modelEditor/ModelEditorBreadcrumb';
import { translatePythonToCforBlock } from './LanguageTranslation';

export const CODE_EDITOR_BLOCK_QUERY_PARAM = 'code_editor_path';

export type CodeLanguage = 'python' | 'c' | 'cpp' | 'text';

const EditorScreen = styled.div<{ noWidthSet?: boolean }>`
  position: relative;
  height: 100%;
  pointer-events: auto;
  background: ${({ theme }) => theme.colors.grey[5]};
  ${({ noWidthSet }) => (noWidthSet ? '' : 'width: 40px;')}
  flex-grow: 1;
  border: 1px solid ${({ theme }) => theme.colors.grey[10]};
`;

const EditorSection = styled.div<{ noTopMargin?: boolean }>`
  flex: 1;
  min-height: 0;
  height: 100%;
  max-height: 100%;
  padding-top: 48px;
  position: relative;
`;

const CGenerateButtonAnchor = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const CodePanesContainer = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
`;
const CodePaneTitleContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 99;
  width: 100%;
  height: 30px;
  background: white;
  border: 1px solid #e3e7e7;
  border-left: none;
  border-right: none;
  cursor: pointer;
  user-selection: none;
`;
const CodePane = styled.div<{ open: boolean }>`
  overflow: hidden;

  ${({ open }) =>
    open
      ? `
    flex: 1;
  `
      : `
    flex: 0;
    min-height: 30px;
  `}
`;

const CodePaneTitle = ({
  onToggle,
  open,
  title,
}: {
  onToggle?: () => void;
  open: boolean;
  title: string;
}) => (
  <CodePaneTitleContainer onClick={onToggle}>
    {open ? (
      <TreeArrowExpanded fill="#ABB0B0" />
    ) : (
      <TreeArrowCollapsed fill="#ABB0B0" />
    )}
    <Code fill="#5C6F70" />
    {title}
  </CodePaneTitleContainer>
);

interface CodeEditorWrapperProps {
  language: CodeLanguage;
  editorOptions: monacoEditor.IStandaloneEditorConstructionOptions;
  path?: string;
  title?: string;
  defaultValue?: string;
  inPane?: boolean;

  onChangeCode: (code?: string) => void;
  onFocused: (editor: monacoEditor.IStandaloneCodeEditor | null) => void;
}

const CodeEditorWrapper = ({
  path,
  title,
  defaultValue,
  language,
  editorOptions,
  inPane,
  onChangeCode,
  onFocused,
}: CodeEditorWrapperProps): ReactElement => {
  const editorRef = React.useRef<monacoEditor.IStandaloneCodeEditor | null>(
    null,
  );
  const [open, setOpen] = React.useState<boolean>(true);

  const handleEditorDidMount = React.useCallback((editor) => {
    setupAutocompleteShortcut(editor);
    editorRef.current = editor;
  }, []);

  if (inPane) {
    return (
      <CodePane open={open} onFocus={() => onFocused(editorRef.current)}>
        <CodePaneTitle
          title={title || path || 'Untitled'}
          onToggle={() => setOpen((open) => !open)}
          open={open}
        />
        <Editor
          language={language || 'python'}
          theme="collimator"
          path={path}
          defaultValue={defaultValue}
          onChange={(val: string | undefined) => onChangeCode(val)}
          onMount={handleEditorDidMount}
          options={editorOptions}
        />
      </CodePane>
    );
  }
  return (
    <Editor
      language={language || 'python'}
      theme="collimator"
      path={path}
      defaultValue={defaultValue}
      onChange={(val: string | undefined) => onChangeCode(val)}
      onMount={handleEditorDidMount}
      options={editorOptions}
    />
  );
};

interface CodeEditorProps {
  language: CodeLanguage;
  editorDisplayPath: string;
  onChangeCode: (v: string | undefined, paramKey?: string) => void;

  defaultValue?: string;
  noTopMargin?: boolean;
  noBreadcrumb?: boolean;
  editingDisabled?: boolean;
  multiPaneData?: Array<{
    paneTitle: string;
    paramKey: string;
    defaultValue?: string;
  }>;
  codeBlockForCompletions?: NodeInstance;
}

export const CodeEditor = ({
  defaultValue,
  onChangeCode,
  editorDisplayPath,
  noTopMargin,
  noBreadcrumb,
  editingDisabled,
  language,
  multiPaneData,
  codeBlockForCompletions,
}: CodeEditorProps): ReactElement => {
  const [editorInFocus, setEditorInFocus] =
    React.useState<monacoEditor.IStandaloneCodeEditor | null>(null);

  const theme = useTheme();
  const { showInfo, showError } = useNotifications();
  const dispatch = useAppDispatch();
  const webSocket = useWebSocket();
  const { experimentalPythonToC, openAiKey } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const monaco = useMonaco();
  const timerId = React.useRef<NodeJS.Timeout | null>(null);

  const { codexAutocompleteEnabled, codexAutocompleteModel } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const showExperimentalPythonToC = experimentalPythonToC;

  React.useEffect(() => {
    const showAutosaveMessage = () => {
      dispatch(
        notificationsActions.setCurrentMessage({
          message: t({
            message: 'Your file is saved automatically.',
            id: 'pythonEditor.autoSavePseudoNag',
          }),
          icon: undefined,
          canClose: true,
        }),
      );
    };

    const checkKeydownForSave = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 's') {
        e.preventDefault();
        showAutosaveMessage();
      }
    };

    document.addEventListener('keydown', checkKeydownForSave);

    return () => {
      document.removeEventListener('keydown', checkKeydownForSave);
    };
  }, [dispatch]);

  const onClickExperimentalPythonToC = React.useCallback(() => {
    if (!codeBlockForCompletions) return;
    if (!editorInFocus) {
      showError('Select the code editor first.');
      return;
    }
    if (!openAiKey || openAiKey === '') {
      showError("User option 'openai_key' is empty, can't run translation!");
      return;
    }

    showInfo('Starting translation of Python code to C code with ChatGPT...');
    translatePythonToCforBlock(
      codeBlockForCompletions,
      (code: string) => editorInFocus?.getModel()?.setValue(code),
      webSocket,
      (failed: boolean, finishReason: string) => {
        if (failed || (finishReason && finishReason !== 'stop')) {
          showError(`ChatGPT finished early: ${finishReason}`);
        } else {
          showInfo('Translation complete!');
        }
      },
    );
  }, [
    codeBlockForCompletions,
    openAiKey,
    showInfo,
    webSocket,
    showError,
    editorInFocus,
  ]);

  const editorOptions: monacoEditor.IStandaloneEditorConstructionOptions =
    React.useMemo(
      () => ({
        readOnly: editingDisabled,
        fontSize: getThemeValue(theme.typography.font.code.size),
        lineHeight: 26,
        // FIXME: This font seems to mess up the cursor alignment in chrome
        // when zoom is at 100%
        // fontFamily: 'Inconsolata',
        renderWhitespace: 'none',
        lineNumbersMinChars: 3,
        folding: false,
        lineDecorationsWidth: getThemeValue(theme.spacing.normal),
        minimap: {
          enabled: false,
        },
        hideCursorInOverviewRuler: true,
        overviewRulerLanes: 0,
        inlineSuggest: {
          enabled: true,
          mode: 'prefix',
        },
      }),
      [theme, editingDisabled],
    );

  // setup monaco - note that there can be multiple editors but only one monaco instance
  // the `monaco.editor` is a namespace and does not point to a specific editor.
  React.useEffect(() => {
    if (!monaco) return;
    monaco.editor.defineTheme('collimator', {
      base: 'vs',
      inherit: true,
      rules: [
        { token: 'namespace', foreground: '#212121' },
        { token: 'comment', foreground: '#408080', fontStyle: 'italic' },
        { token: 'string', foreground: '#ba2121' },
        { token: 'keyword', foreground: '#008000', fontStyle: 'bold' },
        { token: '', foreground: '#0000ff' },
      ],
      colors: {
        'editorGutter.background': theme.colors.grey[10],
        'editor.background': theme.colors.grey[2],
        'editor.lineHighlightBorder': theme.colors.grey[2],
      },
    });
    monaco.editor.setTheme('collimator');
    if (codexAutocompleteEnabled) {
      setupAutocomplete(
        monaco,
        dispatch,
        timerId,
        codexAutocompleteModel,
        language,
      );
    }
  }, [
    codexAutocompleteEnabled,
    codexAutocompleteModel,
    dispatch,
    language,
    monaco,
    theme.colors.grey,
  ]);

  return (
    <EditorScreen noWidthSet={noTopMargin}>
      {!noBreadcrumb && <ModelEditorBreadcrumb />}
      <EditorSection data-codeeditor noTopMargin={noTopMargin}>
        {showExperimentalPythonToC ? (
          // Purely experimental feature to convert Python code to C code
          <CGenerateButtonAnchor>
            <Button
              variant={ButtonVariants.SmallSecondary}
              onClick={onClickExperimentalPythonToC}
              Icon={CFile}>
              Generate C (beta)
            </Button>
          </CGenerateButtonAnchor>
        ) : undefined}
        <Global
          styles={{
            '.monaco-editor .lines-content': {
              paddingLeft: getThemeValue(theme.spacing.large),
            },
            '.monaco-editor .margin-view-overlays .line-numbers': {
              color: theme.colors.text.secondary,
            },
          }}
        />
        {multiPaneData ? (
          <CodePanesContainer>
            {multiPaneData.map(
              ({ paneTitle, paramKey, defaultValue: paneDefault }, index) => (
                <CodeEditorWrapper
                  key={`code-editor-${index}`}
                  path={`${editorDisplayPath}_${paramKey}`}
                  title={paneTitle}
                  language={language || 'python'}
                  defaultValue={paneDefault}
                  onChangeCode={(v) => onChangeCode(v, paramKey)}
                  editorOptions={editorOptions}
                  onFocused={(editor) => setEditorInFocus(editor)}
                  inPane
                />
              ),
            )}
          </CodePanesContainer>
        ) : (
          <CodeEditorWrapper
            language={language || 'python'}
            defaultValue={defaultValue}
            onChangeCode={(v) => onChangeCode(v)}
            editorOptions={editorOptions}
            onFocused={(editor) => setEditorInFocus(editor)}
          />
        )}
      </EditorSection>
    </EditorScreen>
  );
};
