import { NodeInstance } from 'app/generated_types/SimulationModel';
import { useAppSelector } from 'app/hooks';
import { getDiagramForPath } from 'app/utils/modelDiagramUtils';
import React from 'react';
import DiagramTreeContent from 'ui/objectBrowser/sections/DiagramTreeContent';

interface Props {
  projectId: string;
  testIdPath: string;
  node: NodeInstance;
  parentPath: string[];
  searchString?: string;
}

const LocalSubmodelTreeContent: React.FC<Props> = ({
  projectId,
  testIdPath,
  node,
  parentPath,
  searchString,
}) => {
  // TODO: match to state to automatically navigate to the correct diagram
  const nextParentPath = [...parentPath, node.uuid];

  const topLevelDiagram = useAppSelector(
    (state) => state.model.present.rootModel,
  );
  const topLevelSubmodels = useAppSelector(
    (state) => state.model.present.submodels,
  );
  const idToVersionIdToSubmodelFull = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelFull,
  );
  const idToLatestTaggedVersionId = useAppSelector(
    (state) => state.submodels.idToLatestTaggedVersionId,
  );

  const { diagram } = getDiagramForPath(
    nextParentPath,
    topLevelDiagram,
    topLevelSubmodels,
    idToVersionIdToSubmodelFull,
    idToLatestTaggedVersionId,
  );

  return (
    <DiagramTreeContent
      projectId={projectId}
      testIdPath={testIdPath}
      parentPath={nextParentPath}
      searchString={searchString}
      diagram={diagram}
    />
  );
};

export default LocalSubmodelTreeContent;
