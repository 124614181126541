import type { Coordinate } from 'app/common_types/Coordinate';
import {
  HoverEdgeSide,
  HoverEntity,
  MouseActions,
  MouseState,
} from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { HoverEntityType } from 'app/common_types/SegmentTypes';
import {
  AnnotationInstance,
  LinkInstance,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { modelActions } from 'app/slices/modelSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { AppDispatch } from 'app/store';
import { LinkRenderData, VertexSegmentType } from 'app/utils/linkToRenderData';
import { renderConstants } from 'app/utils/renderConstants';
import { TransformFunc } from 'ui/modelEditor/ModelRendererWrapper';
import { useVisualizerPrefs } from 'ui/modelEditor/useVisualizerPrefs';
import {
  RendererState,
  rendererState,
} from 'ui/modelRendererInternals/modelRenderer';
import { getIsNodeSupported } from 'util/portTypeUtils';
import { pythagoreanDistance } from 'util/pythagoreanDistance';
import { convertZoomedScreenToWorldCoordinates } from './convertScreenToWorldCoordinates';
import { getVertexHitboxForIndex } from './getVertexHitboxForIndex';
import {
  PORT_BLOCK_YOFFSET,
  getMinimumVisualNodeHeight,
  getVisualNodeHeight,
} from './getVisualNodeHeight';
import {
  getMinimumVisualNodeWidth,
  getVisualNodeWidth,
} from './getVisualNodeWidth';

const HANG_COORD_COLLISION_DISTANCE = 10;

const NODE_EDGEHANDLE_SIZE = renderConstants.GRID_SIZE / 2;

const broadMargin = renderConstants.PORT_SIZE;
const doubleBroadMargin = broadMargin * 2;

const getPortsCollisionSize = (
  portAlignment: 'top' | 'center' | 'bottom' | 'spaced',
  currentNodeHeight: number,
  portsCount: number,
  nodeIsSmall: boolean,
) => {
  if (nodeIsSmall) {
    return {
      portColHeight: currentNodeHeight,
      portColY: 0,
    };
  }

  const doubleGrid = renderConstants.GRID_SIZE * 2;
  let portColHeight = 0;
  let portColY = 0;

  switch (portAlignment) {
    case 'spaced':
      portColHeight = currentNodeHeight - doubleGrid * 2;
      portColY = doubleGrid;
      break;
    case 'top':
      portColHeight = portsCount * doubleGrid;
      portColY = renderConstants.GRID_SIZE;
      break;
    case 'bottom':
      portColHeight = portsCount * doubleGrid;
      portColY =
        currentNodeHeight -
        renderConstants.GRID_SIZE -
        Math.floor(portsCount * 2) * renderConstants.GRID_SIZE;
      break;
    case 'center':
      portColHeight = portsCount * doubleGrid;
      portColY =
        (Math.floor(currentNodeHeight / renderConstants.GRID_SIZE / 2) -
          portsCount) *
        renderConstants.GRID_SIZE;
      break;
  }

  return { portColHeight, portColY };
};

export const getHoveringEntity = (
  mouseState: MouseState,
  worldCursor: Coordinate,
  _camera: Coordinate,
  nodes: NodeInstance[],
  links: LinkInstance[],
  annotations: AnnotationInstance[],
  linksIndexLUT: { [k: string]: number },
  visualizerPrefs: ReturnType<typeof useVisualizerPrefs>,
  linksRenderFrameData: LinkRenderData[],
  parentPath: string[],
): HoverEntity | undefined => {
  // Blocks & ports mouse collisions.
  for (let i = nodes.length - 1; i >= 0; i--) {
    const currentNode = nodes[i];
    const currentNodeHeight = getVisualNodeHeight(currentNode);
    const currentNodeWidth = getVisualNodeWidth(currentNode);
    const nodeReversed = currentNode.uiprops.directionality === 'left';
    const nodeIsIOPort =
      currentNode.type === 'core.Inport' || currentNode.type === 'core.Outport';
    const nodeIsSmall =
      nodeIsIOPort ||
      (currentNode.type === 'core.Constant' &&
        currentNodeHeight < renderConstants.GRID_SIZE * 5);

    const broadBoxX = currentNode.uiprops.x - broadMargin;
    const broadBoxXWithSignalToggle = nodeReversed
      ? currentNode.uiprops.x -
        renderConstants.SIGNAL_PLOTTER_WIDTH -
        renderConstants.SIGNAL_PLOTTER_COLLISION_OFFSET_X
      : broadBoxX;
    const fixedNodeY = nodeIsIOPort
      ? currentNode.uiprops.y + PORT_BLOCK_YOFFSET
      : currentNode.uiprops.y;
    const broadBoxY =
      currentNode.uiprops.label_position === 'top'
        ? fixedNodeY - 20
        : fixedNodeY - renderConstants.SIGNAL_PLOTTER_COLLISION_OFFSET_Y;
    const broadBoxWidth = currentNodeWidth + doubleBroadMargin;
    const broadBoxWidthWithSignalToggle =
      broadBoxWidth +
      renderConstants.SIGNAL_PLOTTER_WIDTH +
      renderConstants.SIGNAL_PLOTTER_COLLISION_OFFSET_X;
    // broad height not currently affected by ports
    const broadBoxHeight =
      currentNodeHeight +
      renderConstants.SIGNAL_PLOTTER_COLLISION_OFFSET_Y +
      20;
    const inputPortCollisionMargin = broadMargin * 1.5;
    const outputPortCollisionMargin = broadMargin * 1.5;

    // Broad box check which includes block & ports
    // to see if we should even bother checking against all ports.
    if (
      worldCursor.x > broadBoxXWithSignalToggle &&
      worldCursor.y > broadBoxY &&
      worldCursor.x < broadBoxX + broadBoxWidthWithSignalToggle &&
      worldCursor.y < broadBoxY + broadBoxHeight
    ) {
      // Check for signal plotter collision
      const signalPlotterX = nodeReversed
        ? broadBoxXWithSignalToggle
        : currentNode.uiprops.x +
          currentNodeWidth +
          renderConstants.SIGNAL_PLOTTER_COLLISION_OFFSET_X;
      const signalPlotterY =
        fixedNodeY - renderConstants.SIGNAL_PLOTTER_COLLISION_OFFSET_Y;
      const canSignalPlot = getIsNodeSupported(currentNode.uuid, parentPath);
      if (
        canSignalPlot &&
        worldCursor.x > signalPlotterX &&
        worldCursor.y > signalPlotterY &&
        worldCursor.x < signalPlotterX + renderConstants.SIGNAL_PLOTTER_WIDTH &&
        worldCursor.y < signalPlotterY + renderConstants.SIGNAL_PLOTTER_HEIGHT
      ) {
        return {
          entityType: HoverEntityType.SignalPlotter,
          block: currentNode,
        };
      }

      // port collisions
      const localY = worldCursor.y - fixedNodeY;
      const portAlignment = currentNode.uiprops.port_alignment || 'center';

      // Check for left port collision.
      const leftPortsCount = nodeReversed
        ? currentNode.outputs.length
        : currentNode.inputs.length;
      const leftPortType = nodeReversed ? PortSide.Output : PortSide.Input;

      if (
        leftPortsCount &&
        localY > 0 &&
        localY < currentNodeHeight &&
        worldCursor.x < broadBoxX + inputPortCollisionMargin
      ) {
        const { portColHeight: leftPortColHeight, portColY: leftPortColY } =
          getPortsCollisionSize(
            portAlignment,
            currentNodeHeight,
            leftPortsCount,
            nodeIsSmall,
          );

        if (
          localY > leftPortColY &&
          localY < leftPortColY + leftPortColHeight
        ) {
          const portLocalY = localY - leftPortColY;
          const portId = Math.floor(
            (portLocalY / leftPortColHeight) * leftPortsCount,
          );

          return {
            entityType: HoverEntityType.Port,
            port: {
              side: leftPortType,
              blockUuid: currentNode.uuid,
              portId,
            },
          };
        }
      }

      // Check for right port collision.
      const rightPortsCount = nodeReversed
        ? currentNode.inputs.length
        : currentNode.outputs.length;
      const rightPortType = nodeReversed ? PortSide.Input : PortSide.Output;

      if (
        rightPortsCount &&
        localY > 0 &&
        localY < currentNodeHeight &&
        worldCursor.x > broadBoxX + broadBoxWidth - outputPortCollisionMargin &&
        worldCursor.x < broadBoxX + broadBoxWidth
      ) {
        const { portColHeight: rightPortColHeight, portColY: rightPortColY } =
          getPortsCollisionSize(
            portAlignment,
            currentNodeHeight,
            rightPortsCount,
            nodeIsSmall,
          );

        if (
          localY > rightPortColY &&
          localY < rightPortColY + rightPortColHeight
        ) {
          const portLocalY = localY - rightPortColY;
          const portId = Math.floor(
            (portLocalY / rightPortColHeight) * rightPortsCount,
          );

          return {
            entityType: HoverEntityType.Port,
            port: {
              side: rightPortType,
              blockUuid: currentNode.uuid,
              portId,
            },
          };
        }
      }

      // Check for name collision.
      if (
        worldCursor.x > currentNode.uiprops.x &&
        worldCursor.x < currentNode.uiprops.x + currentNodeWidth &&
        ((currentNode.uiprops.label_position === 'top' &&
          worldCursor.y > broadBoxY &&
          worldCursor.y < fixedNodeY) ||
          (currentNode.uiprops.label_position !== 'top' &&
            worldCursor.y < fixedNodeY + broadBoxHeight &&
            worldCursor.y > fixedNodeY + currentNodeHeight))
      ) {
        return {
          entityType: HoverEntityType.NodeName,
          uuid: currentNode.uuid,
        };
      }

      // Check for direct block box collision.
      const blockRightEdge = currentNode.uiprops.x + currentNodeWidth;
      const blockBottomEdge = fixedNodeY + currentNodeHeight;
      if (
        worldCursor.x > currentNode.uiprops.x &&
        worldCursor.y > fixedNodeY &&
        worldCursor.x < blockRightEdge &&
        worldCursor.y < blockBottomEdge
      ) {
        let onEdge = false;
        // placeholder to simplify logic
        let handleSides: HoverEdgeSide[] = [];
        if (
          currentNode.type !== 'core.Inport' &&
          currentNode.type !== 'core.Outport'
        ) {
          if (worldCursor.x < currentNode.uiprops.x + NODE_EDGEHANDLE_SIZE) {
            onEdge = true;
            handleSides.push(HoverEdgeSide.Left);
          }
          if (worldCursor.x > blockRightEdge - NODE_EDGEHANDLE_SIZE) {
            onEdge = true;
            handleSides.push(HoverEdgeSide.Right);
          }
          if (worldCursor.y < fixedNodeY + NODE_EDGEHANDLE_SIZE) {
            onEdge = true;
            handleSides.push(HoverEdgeSide.Top);
          }
          if (worldCursor.y > blockBottomEdge - NODE_EDGEHANDLE_SIZE) {
            onEdge = true;
            handleSides.push(HoverEdgeSide.Bottom);
          }

          if (
            onEdge &&
            handleSides[0] !== undefined &&
            (handleSides.length === 1 || handleSides.length === 2)
          ) {
            return {
              entityType: HoverEntityType.NodeResizeEdge,
              nodeUuid: currentNode.uuid,
              handleSides: handleSides as
                | [HoverEdgeSide]
                | [HoverEdgeSide, HoverEdgeSide],
            };
          }
        }

        return { entityType: HoverEntityType.Node, block: currentNode };
      }
    }
  }

  if (!linksRenderFrameData) return undefined;

  // Link mouse collisions.
  // Iterate backwards because we want what's rendered on top
  // to be clickable first.
  for (let ci = linksRenderFrameData.length - 1; ci >= 0; ci--) {
    const linkRenderData = linksRenderFrameData[ci];
    if (!linkRenderData) continue;
    const realLinkIndex = linksIndexLUT[linkRenderData.linkUuid];
    const { vertexData } = linkRenderData;
    const currentLink = links[realLinkIndex];
    if (!currentLink) continue;

    if (
      (mouseState.state === MouseActions.DrawingLinkFromEnd ||
        mouseState.state === MouseActions.DrawingLinkFromStart) &&
      mouseState.linkUuid === currentLink.uuid
    ) {
      continue;
    }

    const isTappingLink =
      currentLink.uiprops.link_type.connection_method === 'link_tap';
    // if this is a tapping link, we first check for tap point collision
    // to shortcut the rest of the segment checks if we hit it
    if (isTappingLink) {
      const firstVertex = vertexData[0];
      const [fvX, fvY] = firstVertex ? firstVertex.coordinate : [0, 0];

      if (
        firstVertex &&
        fvX - 10 < worldCursor.x &&
        fvX + 10 > worldCursor.x &&
        fvY - 10 < worldCursor.y &&
        fvY + 10 > worldCursor.y
      ) {
        const tappedLinkUuid =
          currentLink.uiprops.link_type.connection_method == 'link_tap'
            ? currentLink.uiprops.link_type.tapped_link_uuid
            : '';
        const tappedSegmentId =
          currentLink.uiprops.link_type.connection_method == 'link_tap' &&
          currentLink.uiprops.link_type.tapped_segment.segment_type === 'real'
            ? currentLink.uiprops.link_type.tapped_segment.tapped_segment_index
            : 0;

        return {
          entityType: HoverEntityType.TapPoint,
          linkUuid: currentLink.uuid,
          tappedLinkUuid,
          tappedSegmentId,
        };
      }
    }

    const rs = rendererState;
    const srcNode = currentLink.src
      ? rs?.refs.current.nodes[
          rs.refs.current.nodesIndexLUT[currentLink.src.node]
        ]
      : undefined;
    const dstNode = currentLink.dst
      ? rs?.refs.current.nodes[
          rs?.refs.current.nodesIndexLUT[currentLink.dst.node]
        ]
      : undefined;
    const srcDisconnected =
      !currentLink.src ||
      (srcNode && srcNode.outputs.length - 1 < (currentLink.src?.port || -1));
    const dstDisconnected =
      !currentLink.dst ||
      (dstNode && dstNode.inputs.length - 1 < (currentLink.dst?.port || -1));

    const currentLinkRenderDataIdx =
      rs?.linksRenderFrameDataIndexLUT[currentLink.uuid];
    const currentLinkRenderData =
      rs?.linksRenderFrameData[currentLinkRenderDataIdx ?? -1];

    if (!isTappingLink && srcDisconnected && currentLinkRenderData) {
      const startVertex = currentLinkRenderData.vertexData[0];
      const startCoord =
        currentLink.uiprops.hang_coord_start || startVertex
          ? { x: startVertex.coordinate[0], y: startVertex.coordinate[1] }
          : { x: 0, y: 0 };
      const distance = pythagoreanDistance(worldCursor, startCoord);

      if (distance < HANG_COORD_COLLISION_DISTANCE) {
        return {
          entityType: HoverEntityType.HangingStartPoint,
          linkUuid: linkRenderData.linkUuid,
        };
      }
    }

    if (dstDisconnected && currentLinkRenderData) {
      const endVertex =
        currentLinkRenderData.vertexData[
          currentLinkRenderData.vertexData.length - 1
        ];
      const endCoord =
        currentLink.uiprops.hang_coord_start || endVertex
          ? { x: endVertex.coordinate[0], y: endVertex.coordinate[1] }
          : { x: 0, y: 0 };

      const distance = pythagoreanDistance(worldCursor, endCoord);

      if (distance < HANG_COORD_COLLISION_DISTANCE) {
        return {
          entityType: HoverEntityType.HangingEndPoint,
          linkUuid: linkRenderData.linkUuid,
        };
      }
    }

    // final vertex's hitbox does not exist
    // so we skip it (subtract 1 from length)
    for (let j = 0; j < vertexData.length - 1; j++) {
      const hitbox = getVertexHitboxForIndex(vertexData, j);
      const vertex = vertexData[j];

      if (
        hitbox.x1 < worldCursor.x &&
        hitbox.x2 > worldCursor.x &&
        hitbox.y1 < worldCursor.y &&
        hitbox.y2 > worldCursor.y
      ) {
        if (!currentLink) return undefined;

        switch (vertex.segmentType) {
          case VertexSegmentType.Fake:
            return {
              entityType: HoverEntityType.FakeLinkSegment,
              link: currentLink,
              linkUuid: linkRenderData.linkUuid,
              fakeSegmentType: vertex.fakeSegmentType,
              vertexDataIndex: j,
            };
          case VertexSegmentType.Real:
            return {
              entityType: HoverEntityType.Link,
              link: currentLink,
              linkUuid: linkRenderData.linkUuid,
              segmentId: vertex.segmentIndex,
            };
        }
      }
    }
  }

  for (let i = 0; i < annotations.length; i++) {
    const currentAnnotation = annotations[i];

    const labelTop = currentAnnotation.label_position === 'top';

    const annoRightEdge =
      currentAnnotation.x +
      currentAnnotation.grid_width * renderConstants.GRID_SIZE;
    const annoHeight =
      currentAnnotation.grid_height * renderConstants.GRID_SIZE;
    const textHeight = renderConstants.GRID_SIZE * 3;
    const annoBottomEdge = currentAnnotation.y + annoHeight;
    const broadBoxY = labelTop
      ? currentAnnotation.y - textHeight
      : currentAnnotation.y;
    const broadBoxHeight = annoHeight + textHeight;

    if (
      worldCursor.x > currentAnnotation.x &&
      worldCursor.y > broadBoxY &&
      worldCursor.x < annoRightEdge &&
      worldCursor.y < broadBoxY + broadBoxHeight
    ) {
      if (
        (labelTop && worldCursor.y < currentAnnotation.y) ||
        (!labelTop && worldCursor.y > annoBottomEdge)
      ) {
        return {
          entityType: HoverEntityType.AnnotationText,
          uuid: currentAnnotation.uuid,
        };
      }

      let onEdge = false;
      // placeholder to simplify logic
      let handleSides: HoverEdgeSide[] = [];
      if (worldCursor.x < currentAnnotation.x + renderConstants.GRID_SIZE) {
        onEdge = true;
        handleSides.push(HoverEdgeSide.Left);
      }
      if (worldCursor.x > annoRightEdge - renderConstants.GRID_SIZE) {
        onEdge = true;
        handleSides.push(HoverEdgeSide.Right);
      }
      if (worldCursor.y < currentAnnotation.y + renderConstants.GRID_SIZE) {
        onEdge = true;
        handleSides.push(HoverEdgeSide.Top);
      }
      if (worldCursor.y > annoBottomEdge - renderConstants.GRID_SIZE) {
        onEdge = true;
        handleSides.push(HoverEdgeSide.Bottom);
      }

      if (
        onEdge &&
        handleSides[0] !== undefined &&
        (handleSides.length === 1 || handleSides.length === 2)
      ) {
        return {
          entityType: HoverEntityType.AnnotationResizeEdge,
          uuid: currentAnnotation.uuid,
          handleSides: handleSides as
            | [HoverEdgeSide]
            | [HoverEdgeSide, HoverEdgeSide],
        };
      }

      return {
        entityType: HoverEntityType.Annotation,
        uuid: currentAnnotation.uuid,
      };
    }
  }

  return undefined;
};

export const mouseInput = (
  rs: RendererState,
  dispatch: AppDispatch,
  setTransform: TransformFunc,
): void => {
  const worldCursor = convertZoomedScreenToWorldCoordinates(
    rs.camera,
    rs.screenCursorZoomed,
  );

  // this is to make sure that we don't experience any jitter with line state getting.
  // this ensures that this "pre confirmed click coordinate" flag/data deletion is in sync with other redux data.
  // a bit clunky, but it should work wellfor every double-click lag case that we could possibly compensate for.
  if (rs.refs.current.uiFlags.preDblConfirmClickCoordNeedsDeletion) {
    rs.mouseState.preDblConfirmClickWorldCoord = undefined;
    rs.dispatch(
      uiFlagsActions.setUIFlag({ preDblConfirmClickCoordNeedsDeletion: false }),
    );
  }

  if (
    rs.mouseState.state !== MouseActions.MakingSelection &&
    rs.mouseState.state !== MouseActions.DefiningAnnotationBox
  ) {
    rs.hoveringEntity = getHoveringEntity(
      rs.mouseState,
      worldCursor,
      rs.camera,
      rs.refs.current.nodes,
      rs.refs.current.links,
      rs.refs.current.annotations,
      rs.refs.current.linksIndexLUT,
      rs.refs.current.visualizerPrefs,
      rs.linksRenderFrameData,
      getCurrentModelRef().submodelPath,
    );
  }

  // mouse actions
  // this takes the current tick's mouse input/state and
  // applies the effects of that state/input to the scene or model data
  switch (rs.mouseState.state) {
    case MouseActions.Panning:
      rs.camera.x =
        rs.mouseState.cameraStartX -
        (rs.mouseState.cursorStartX - rs.screenCursorZoomed.x);
      rs.camera.y =
        rs.mouseState.cameraStartY -
        (rs.mouseState.cursorStartY - rs.screenCursorZoomed.y);
      setTransform({ x: rs.camera.x, y: rs.camera.y, zoom: rs.zoom });
      break;
    case MouseActions.DraggingSelected:
      const deltaX = rs.screenCursorZoomed.x - rs.mouseState.previousCursorX;
      const deltaY = rs.screenCursorZoomed.y - rs.mouseState.previousCursorY;

      rs.mouseState.previousCursorX = rs.screenCursorZoomed.x;
      rs.mouseState.previousCursorY = rs.screenCursorZoomed.y;

      dispatch(
        modelActions.moveEntitiesByDelta({
          blockUuids: rs.mouseState.selectionOverride
            ? rs.mouseState.selectionOverride.nodeUuids || []
            : rs.refs.current.selectedNodeIds,
          linkUuids: rs.mouseState.selectionOverride
            ? rs.mouseState.selectionOverride.linkUuids || []
            : rs.refs.current.selectedLinkIds,
          annotationUuids: rs.mouseState.selectionOverride
            ? rs.mouseState.selectionOverride.annotationUuids || []
            : rs.refs.current.selectedAnnotationIds,
          deltaX,
          deltaY,
        }),
      );
      break;
    case MouseActions.DraggingLinkSegment: {
      const worldCursorX = -rs.camera.x + rs.screenCursorZoomed.x;
      const worldCursorY = -rs.camera.y + rs.screenCursorZoomed.y;

      const linkIndex = rs.refs.current.linksIndexLUT[rs.mouseState.linkUuid];
      const link = rs.refs.current.links[linkIndex];
      if (!link) break;

      const segment = link.uiprops.segments[rs.mouseState.segmentId];
      if (!segment) break;

      dispatch(
        modelActions.changeSegmentCoordinate({
          linkUuid: rs.mouseState.linkUuid,
          segmentIndex: rs.mouseState.segmentId,
          newCoordinate:
            segment.segment_direction === 'horiz' ? worldCursorY : worldCursorX,
        }),
      );
      break;
    }
    case MouseActions.ResizeNodeManually: {
      const worldCursorX = -rs.camera.x + rs.screenCursorZoomed.x;
      const worldCursorY = -rs.camera.y + rs.screenCursorZoomed.y;

      const nodeIndex = rs.refs.current.nodesIndexLUT[rs.mouseState.nodeUuid];
      const node = rs.refs.current.nodes[nodeIndex];

      let newX;
      let newY;

      let newGridWidth = rs.mouseState.startingGridWidth;
      let newGridHeight = rs.mouseState.startingGridHeight;

      if (rs.mouseState.handleSides.includes(HoverEdgeSide.Top)) {
        const minGridHeight =
          getMinimumVisualNodeHeight(node) / renderConstants.GRID_SIZE;
        const mouseGridY = Math.floor(worldCursorY / renderConstants.GRID_SIZE);
        const gridYDif = rs.mouseState.startingGridY - mouseGridY;
        const proposedNewGridHeight =
          rs.mouseState.startingGridHeight + gridYDif;
        if (proposedNewGridHeight >= minGridHeight) {
          newGridHeight = proposedNewGridHeight;
          newY = mouseGridY * renderConstants.GRID_SIZE;
        } else {
          newGridHeight = minGridHeight;
          const newGridY =
            rs.mouseState.startingGridY +
            (rs.mouseState.startingGridHeight - minGridHeight);
          newY = newGridY * renderConstants.GRID_SIZE;
        }
      } else if (rs.mouseState.handleSides.includes(HoverEdgeSide.Bottom)) {
        const mouseGridY = Math.floor(worldCursorY / renderConstants.GRID_SIZE);
        const gridYDif = mouseGridY - rs.mouseState.startingGridY;
        newGridHeight = gridYDif;
      }

      if (rs.mouseState.handleSides.includes(HoverEdgeSide.Left)) {
        const minGridWidth =
          getMinimumVisualNodeWidth(node) / renderConstants.GRID_SIZE;
        const mouseGridX = Math.floor(worldCursorX / renderConstants.GRID_SIZE);
        const gridXDif = rs.mouseState.startingGridX - mouseGridX;
        const proposedNewGridWidth = rs.mouseState.startingGridWidth + gridXDif;
        if (proposedNewGridWidth >= minGridWidth) {
          newGridWidth = proposedNewGridWidth;
          newX = mouseGridX * renderConstants.GRID_SIZE;
        } else {
          newGridWidth = minGridWidth;
          const newGridX =
            rs.mouseState.startingGridX +
            (rs.mouseState.startingGridWidth - minGridWidth);
          newX = newGridX * renderConstants.GRID_SIZE;
        }
      } else if (rs.mouseState.handleSides.includes(HoverEdgeSide.Right)) {
        const mouseGridX = Math.floor(worldCursorX / renderConstants.GRID_SIZE);
        const gridXDif = mouseGridX - rs.mouseState.startingGridX;
        newGridWidth = gridXDif;
      }

      dispatch(
        modelActions.resizeNode({
          nodeUuid: rs.mouseState.nodeUuid,
          gridWidth: newGridWidth,
          gridHeight: newGridHeight,
          x: newX,
          y: newY,
        }),
      );
      break;
    }

    case MouseActions.ResizeAnnotationManually: {
      const worldCursorX = -rs.camera.x + rs.screenCursorZoomed.x;
      const worldCursorY = -rs.camera.y + rs.screenCursorZoomed.y;

      const annoIndex = rs.refs.current.annotationsIndexLUT[rs.mouseState.uuid];
      const annotation = rs.refs.current.annotations[annoIndex];

      let newX;
      let newY;

      let newGridWidth = rs.mouseState.startingGridWidth;
      let newGridHeight = rs.mouseState.startingGridHeight;

      const hovTop = rs.mouseState.handleSides.includes(HoverEdgeSide.Top);
      const hovBtm = rs.mouseState.handleSides.includes(HoverEdgeSide.Bottom);
      const hovLeft = rs.mouseState.handleSides.includes(HoverEdgeSide.Left);
      const hovRight = rs.mouseState.handleSides.includes(HoverEdgeSide.Right);

      if (hovTop || hovBtm) {
        const minGridHeight = 2;
        const mouseGridY = Math.floor(worldCursorY / renderConstants.GRID_SIZE);
        const gridYDif = hovTop
          ? rs.mouseState.startingGridY - mouseGridY
          : mouseGridY -
            (rs.mouseState.startingGridY + rs.mouseState.startingGridHeight);
        const proposedNewGridHeight =
          rs.mouseState.startingGridHeight + gridYDif;

        if (proposedNewGridHeight >= minGridHeight) {
          newGridHeight = proposedNewGridHeight;

          if (hovTop) {
            newY = mouseGridY * renderConstants.GRID_SIZE;
          }
        } else {
          newGridHeight = minGridHeight;

          if (hovTop) {
            const newGridY =
              rs.mouseState.startingGridY +
              (rs.mouseState.startingGridHeight - minGridHeight);
            newY = newGridY * renderConstants.GRID_SIZE;
          }
        }
      }

      if (hovLeft || hovRight) {
        const minGridWidth = 2;
        const mouseGridX = Math.floor(worldCursorX / renderConstants.GRID_SIZE);
        const gridXDif = hovLeft
          ? rs.mouseState.startingGridX - mouseGridX
          : mouseGridX -
            (rs.mouseState.startingGridX + rs.mouseState.startingGridWidth);
        const proposedNewGridWidth = rs.mouseState.startingGridWidth + gridXDif;

        if (proposedNewGridWidth >= minGridWidth) {
          newGridWidth = proposedNewGridWidth;

          if (hovLeft) {
            newX = mouseGridX * renderConstants.GRID_SIZE;
          }
        } else {
          newGridWidth = minGridWidth;

          if (hovLeft) {
            const newGridX =
              rs.mouseState.startingGridX +
              (rs.mouseState.startingGridWidth - minGridWidth);
            newX = newGridX * renderConstants.GRID_SIZE;
          }
        }
      }

      dispatch(
        modelActions.resizeAnnotation({
          uuid: rs.mouseState.uuid,
          gridWidth: newGridWidth,
          gridHeight: newGridHeight,
          x: newX,
          y: newY,
        }),
      );
      break;
    }
  }
};
