import { useAppDispatch } from 'app/hooks';
import { tourActions } from 'app/slices/tourSlice';
import React from 'react';
import { isAdminUser } from 'ui/auth/utils';
import Button from 'ui/common/Button/Button';
import { ActionButtonGroup } from 'ui/common/Button/actionButtonStyles';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ProjectFilter } from 'util/projectFilterUtils';
import { NewProjectButton } from '../common/NewProjectButton';

type Props = {
  projectFilter: ProjectFilter;
};

export const ProjectListButtons: React.FC<Props> = ({
  projectFilter,
}: Props) => {
  const dispatch = useAppDispatch();

  return (
    <ActionButtonGroup>
      {projectFilter === 'mine' && <NewProjectButton />}

      {isAdminUser() && (
        <Button
          variant={ButtonVariants.LargePrimary}
          onClick={() => {
            dispatch(
              tourActions.setTrigger({
                tour: 'onboarding',
                value: true,
              }),
            );
          }}>
          Start tour
        </Button>
      )}
    </ActionButtonGroup>
  );
};
