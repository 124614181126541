import React from 'react';
import { usePortalPopup } from './usePortalPopup';

const BUTTON_TOOLTIP_OPEN_DELAY_IN_MS = 1000;

/**
 * Note: Do not use a portal tooltip directly on a button or any other
 * element that can be disabled because disabled elements don't emit
 * mouse events, resulting in tooltips that can get stuck open.  If an element will
 * be disabled, wrap it in another element that will not be disabled,
 * and this wrapper element can provide reliable mouse events.
 * See TooltipButton component as an example.
 */
export function usePortalTooltip<TElement extends HTMLElement>() {
  const { triggerElRef, isPopupOpen, setIsPopupOpen } =
    usePortalPopup<TElement>();

  const [timer, setTimer] = React.useState<any>(null);

  React.useEffect(() => {
    if (triggerElRef && triggerElRef.current) {
      const triggerEl: TElement = triggerElRef.current;

      // If the timer is triggered or we are showing the tooltip,
      // make sure to wire up a mouseleave handler to hide the tooltip.
      if (timer || isPopupOpen) {
        const hideTooltip = () => {
          if (timer) {
            clearTimeout(timer);
            setTimer(null);
          }
          if (isPopupOpen) {
            setIsPopupOpen(false);
          }
        };

        triggerEl.addEventListener('mouseleave', hideTooltip);
        return () => {
          triggerEl.removeEventListener('mouseleave', hideTooltip);

          // Handle cases where the mouseleave event gets missed.
          if (!triggerEl.matches(':hover')) hideTooltip();
        };
      }

      // If there is no current timer and we are not showing the tooltip,
      // make sure to wire up a mouseenter handler that can show the tooltip.
      const showTooltip = () => {
        // If the user mouses over a button tooltip quickly, it
        // should not flash, so set a delay before opening the tooltip.
        const newTimer = setTimeout(() => {
          setIsPopupOpen(true);
          setTimer(null);
        }, BUTTON_TOOLTIP_OPEN_DELAY_IN_MS);
        setTimer(newTimer);
      };

      triggerEl.addEventListener('mouseenter', showTooltip);
      return () => {
        triggerEl.removeEventListener('mouseenter', showTooltip);
      };
    }
    console.warn('Unable to find trigger element for tooltip.');
  }, [triggerElRef, setIsPopupOpen, isPopupOpen, timer]);

  return {
    triggerElRef,
    isTooltipOpen: isPopupOpen,
    setIsTooltipOpen: setIsPopupOpen,
  };
}
