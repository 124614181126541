import { getWriteableDeepCopy } from '@collimator/model-schemas-ts';
import { PayloadAction } from '@reduxjs/toolkit';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import blockTypeNameToInstanceDefaults from 'app/blockClassNameToInstanceDefaults';
import { Coordinate } from 'app/common_types/Coordinate';
import { PortSide } from 'app/common_types/PortTypes';
import {
  AnnotationInstance,
  BlockInstance,
  LinkInstance,
  ModelDiagram,
  NodeInstance,
  NodeUIProps,
  StateMachineBlockInstance,
  SubmodelInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import {
  SubdiagramBlockClassName,
  getSubmodelPortIdOfIoNode,
  nodeClassToPrintName,
  nodeTypeIsContainer,
  nodeTypeIsSubdiagram,
  setPortIdMut,
} from 'app/helpers';
import {
  ModelState,
  getCurrentParentSubmodelNodeFromState,
  getCurrentlyEditingModelFromState,
} from 'app/modelState/ModelState';
import { selectCurrentSubdiagramType } from 'app/slices/modelSlice';
import {
  getUniqueIdentifier,
  getUniqueNodeName,
  getValidNodeName,
} from 'app/transformers/uniqueNameGenerators';
import { setLinkSourceAndDependentTapSources } from 'app/utils/linkMutationUtils';
import {
  snapCoordinateToGrid,
  snapNumberToGrid,
} from 'app/utils/modelDataUtils';
import { getNestedNode, getNode } from 'app/utils/modelDiagramUtils';
import {
  updateModelForSubmodelReferenceChanges,
  updateSubmodelInstanceForReferenceChanges,
} from 'app/utils/modelSubmodelFixupUtils';
import {
  updateBlockPorts,
  updateModelIOPortBlocksMut,
  updateSubmodelNodeIOPortsMut,
} from 'app/utils/portMutationUtils';
import { renderConstants } from 'app/utils/renderConstants';
import {
  createDefaultSubdiagram,
  inportMinIndexForSubdiagram,
} from 'app/utils/subdiagramUtils';
import { copySubmodelReferencesRecursive_mut } from 'app/utils/submodelUtils';
import { getVisualNodeWidth } from 'ui/modelRendererInternals/getVisualNodeWidth';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { v4 as makeUuid } from 'uuid';
import { SubmodelInfoLiteUI } from '../apiTransformers/convertGetSubmodelsList';
import { SubmodelInfoUI } from '../apiTransformers/convertGetSubmodelsListForModelParent';
import { getPortWorldCoordinate } from './getPortOffsetCoordinate';
import { unselectAll } from './selectionUtils';

// helper to print out objects even if they are immer "Proxy" drafts
const dump = (a: any) => JSON.parse(JSON.stringify(a));

const BLOCK_WIDTH = renderConstants.BLOCK_MIN_WIDTH;
const BLOCK_HEIGHT = renderConstants.BLOCK_MIN_HEIGHT;
const LEFT_MARGIN = renderConstants.BLOCK_MIN_WIDTH / 2;
const TOP_MARGIN = renderConstants.BLOCK_MIN_HEIGHT / 2;
const INTER_BLOCK_MARGIN_X = renderConstants.BLOCK_MIN_WIDTH * 1.5;
const INTER_BLOCK_MARGIN_Y = renderConstants.BLOCK_MIN_WIDTH / 2;
const MIN_BLOCK_X = LEFT_MARGIN + BLOCK_WIDTH + INTER_BLOCK_MARGIN_X;

// HACK: this is the simplest way to add a special param (not a user-editable parameter)
// to a node instance given how our current schema and types work.
export const specialSetStateMachineNodeDiagramId = (
  stateMachineNode: NodeInstance,
  stateMachineDiagramId: string,
) => {
  if (stateMachineNode.type === 'core.StateMachine') {
    (stateMachineNode as StateMachineBlockInstance).state_machine_diagram_id =
      stateMachineDiagramId;
  }
};
export const specialGetStateMachineNodeInstanceId = (
  stateMachineNode: NodeInstance,
): string | undefined => {
  if (stateMachineNode.type === 'core.StateMachine') {
    return (stateMachineNode as StateMachineBlockInstance)
      .state_machine_diagram_id;
  }
};

export const createDirectLink = (
  src?: { node: string; port: number },
  dst?: { node: string; port: number },
): LinkInstance => ({
  uuid: makeUuid(),
  src,
  dst,
  uiprops: {
    link_type: { connection_method: 'direct_to_block' },
    segments: [],
  },
});

/**
 * Go through each submodel reference and see if the submodel is part of the
 * new submodel or the original diagram and move the ones that belong to the new submodel.
 * Call this method to get the SubmodelsSection for creating the new reference submodel.
 */
export function findSubmodelsForNewReferenceSubmodel(
  submodelDiagram: ModelDiagram,
  hostingDiagramSubmodelSubmodels: SubmodelsSection,
): SubmodelsSection {
  const { references, diagrams } = hostingDiagramSubmodelSubmodels;

  const submodelSubmodels: SubmodelsSection = {
    references: {},
    diagrams: {},
  };

  const diagramsToWalk: ModelDiagram[] = [];
  let nextDiagram: ModelDiagram | undefined = submodelDiagram;

  while (nextDiagram) {
    nextDiagram.nodes.forEach((node) => {
      const submodelReference = references[node.uuid];
      if (submodelReference) {
        const diagram = diagrams[submodelReference.diagram_uuid];
        if (diagram) {
          submodelSubmodels.references[node.uuid] = submodelReference;
          submodelSubmodels.diagrams[submodelReference.diagram_uuid] = diagram;
          diagramsToWalk.push(diagram);
        }
      }
    });

    nextDiagram = diagramsToWalk.pop();
  }

  return submodelSubmodels;
}

/**
 * Go through each submodel reference and see if the submodel is part of the
 * new submodel or the original diagram and remove the ones that belong to the
 * new submodel from its hosting diagram.
 * Call this method once the new reference submodel has been created to
 * remove the duplicate entries from the hosting diagram.
 */
export function removeSubmodelsForNewReferenceSubmodel(
  submodelDiagram: ModelDiagram,
  hostingDiagramSubmodelSubmodels: SubmodelsSection,
): void {
  const { references, diagrams } = hostingDiagramSubmodelSubmodels;

  const diagramsToWalk: ModelDiagram[] = [];
  let nextDiagram: ModelDiagram | undefined = submodelDiagram;

  while (nextDiagram) {
    nextDiagram.nodes.forEach((node) => {
      const submodelReference = references[node.uuid];
      if (submodelReference) {
        const diagram = diagrams[submodelReference.diagram_uuid];
        if (diagram) {
          delete hostingDiagramSubmodelSubmodels.references[node.uuid];
          delete hostingDiagramSubmodelSubmodels.diagrams[
            submodelReference.diagram_uuid
          ];
          diagramsToWalk.push(diagram);
        }
      }
    });
    nextDiagram = diagramsToWalk.pop();
  }
}

export function confirmReferenceSubmodelCreatedFromSelection(
  state: ModelState,
  submodelInstanceId: string,
  referenceSubmodelId: string,
  submodel: SubmodelInfoUI,
) {
  const diagramReference = state.submodels.references[submodelInstanceId];
  if (diagramReference) {
    const submodelDiagram =
      state.submodels.diagrams[diagramReference.diagram_uuid];
    if (submodelDiagram) {
      removeSubmodelsForNewReferenceSubmodel(submodelDiagram, state.submodels);
    }

    delete state.submodels.references[submodelInstanceId];
    delete state.submodels.diagrams[diagramReference.diagram_uuid];
  }

  const node = getNode(state, submodelInstanceId);
  const submodelInstance = node as SubmodelInstance;
  if (submodelInstance) {
    submodelInstance.submodel_reference_uuid = referenceSubmodelId;
    submodelInstance.type = 'core.ReferenceSubmodel';
    submodelInstance.uiprops.show_port_name_labels = true;
  }

  updateModelForSubmodelReferenceChanges(state.rootModel, state.submodels, {
    [submodel.id]: { [VersionTagValues.LATEST_VERSION]: submodel },
  });
}

// TODO: this needs extreme cleanup asap - and it just got worse lol
export function createSubdiagramFromSelection(
  state: ModelState,
  payload: {
    subdiagramType: SubdiagramBlockClassName;
    submodelInstanceId?: string;
    referenceSubmodelName?: string;
  },
) {
  const { subdiagramType, submodelInstanceId, referenceSubmodelName } = payload;

  if (subdiagramType === 'core.ReferenceSubmodel' && !referenceSubmodelName) {
    console.error('Cannot create a reference submodel without a name');
    return;
  }
  const inportMinIndex = inportMinIndexForSubdiagram(subdiagramType);

  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  // some definitions used in this function
  // preserved = the entity will be kept unchanged in the same diagram
  // extracted = the entity will be entirely moved to the new submodel diagram
  // external = the link is an interface between inside and outside the submodel
  // tapped = some other links are attached to this link, at a tap point on this link
  // tapping = this link taps another link

  let minX = Number.MAX_SAFE_INTEGER;
  let maxX = -Number.MAX_SAFE_INTEGER;
  let minY = Number.MAX_SAFE_INTEGER;
  let maxY = -Number.MAX_SAFE_INTEGER;

  // facilitate lookups
  const isExtractedNodeById = state.selectedBlockIds.reduce((acc, uuid) => {
    acc[uuid] = true;
    return acc;
  }, {} as { [uuid: string]: boolean });

  const preservedNodes: NodeInstance[] = [];
  let extractedNodes: NodeInstance[] = [];
  const submodelEntityNames: string[] = [];

  // filter preserved and extracted nodes (don't use .filter with side effects)
  for (const node of model.nodes) {
    if (isExtractedNodeById[node.uuid]) {
      extractedNodes.push(node);
      submodelEntityNames.push(node.name || ''); // FIXME name should be required
      const nodeWidth = getVisualNodeWidth(node);
      const nodeHeight = getVisualNodeWidth(node);
      minX = Math.min(minX, node.uiprops.x);
      minY = Math.min(minY, node.uiprops.y);
      maxX = Math.max(maxX, node.uiprops.x + nodeWidth);
      maxY = Math.max(maxY, node.uiprops.y + nodeHeight);
    } else {
      preservedNodes.push(node);
    }
  }
  model.nodes = preservedNodes;

  // ensure a reasonable level of visual consistency with port positioning
  extractedNodes.sort((aNode, bNode) => {
    if (aNode.uiprops.y > bNode.uiprops.y) return 1;
    if (aNode.uiprops.y < bNode.uiprops.y) return -1;
    if (aNode.uiprops.x > bNode.uiprops.x) return 1;
    if (aNode.uiprops.x < bNode.uiprops.x) return -1;
    return 0;
  });

  // basic geometry
  let subdiagramHousingNodeX = 0;
  let subdiagramHousingNodeY = 0;
  let subdiagramContentWidth = 0;
  let subdiagramContentHeight = 0;
  let offsetX = 0;
  let offsetY = 0;

  let centerX = 0;
  let centerY = 0;

  // Both submodels and containers have a default size, so here we find the center coordinates to place them at.
  if (extractedNodes.length > 0) {
    offsetX = MIN_BLOCK_X - minX;
    offsetY = TOP_MARGIN - minY;
    centerX = (minX + maxX) / 2;
    centerY = (minY + maxY) / 2;
  } else if (rendererState && rendererState.refs.current.canvas) {
    centerX =
      rendererState.refs.current.canvas.clientWidth / rendererState.zoom / 2 -
      rendererState.camera.x;
    centerY =
      rendererState.refs.current.canvas.clientHeight / rendererState.zoom / 2 -
      rendererState.camera.y;
  }

  // Find the starting coords and block size for the subdiagram depending on the type
  if (nodeTypeIsContainer(subdiagramType)) {
    subdiagramContentWidth =
      renderConstants.CONTAINER_DEFAULT_GRID_WIDTH * renderConstants.GRID_SIZE;
    subdiagramContentHeight =
      renderConstants.CONTAINER_DEFAULT_GRID_HEIGHT * renderConstants.GRID_SIZE;
  } else {
    subdiagramContentWidth =
      renderConstants.REFSUBMODEL_DEFAULT_GRID_WIDTH *
      renderConstants.GRID_SIZE;
    subdiagramContentHeight =
      renderConstants.REFSUBMODEL_DEFAULT_GRID_HEIGHT *
      renderConstants.GRID_SIZE;
  }
  subdiagramHousingNodeX = centerX - subdiagramContentWidth / 2;
  subdiagramHousingNodeY = centerY - subdiagramContentHeight / 2;

  // make nodes visible right away in the new submodel
  // (we have to do this in a second pass because we don't know
  // the final min/max coordinates until the first pass is done)
  extractedNodes.forEach((node) => {
    node.uiprops.x = snapNumberToGrid(node.uiprops.x + offsetX);
    node.uiprops.y = snapNumberToGrid(node.uiprops.y + offsetY);
  });

  const preservedLinks: LinkInstance[] = [];
  const extractedLinks: LinkInstance[] = [];
  const extractedLinksLinkIdLUT: { [uuid: string]: LinkInstance } = {};
  const externalInputLinks: LinkInstance[] = [];
  const externalOutputLinks: LinkInstance[] = [];
  const externalInputLinksNodePortMap: {
    [node: string]: { [port: number]: LinkInstance };
  } = {};

  // "normal" as in not a *tapping* link (tapped links are also "normal")
  const externalNormalOutputLinksNodePortMap: {
    [node: string]: { [port: number]: LinkInstance };
  } = {};
  const extractedLinksNodePortMap: {
    [node: string]: {
      in: { [port: number]: LinkInstance };
      out: { [port: number]: LinkInstance };
    };
  } = {};

  const newSubmodelUuid = submodelInstanceId || makeUuid();
  const newSubmodelInportBlocks: BlockInstance[] = [];
  const newSubmodelOutportBlocks: BlockInstance[] = [];
  const newSubmodelInternalLinks: LinkInstance[] = [];

  // tapping links from a fully extracted link to an external node
  const tappingExtractedTapLinks: LinkInstance[] = [];

  // tapping links from an external link to an external node
  const tappingExternalTapLinks: LinkInstance[] = [];

  // set all links that will eventually be part of the top-level model
  // since we reuse and transform some links, this list is a superset of
  // preservedLinks that also includes externalInputLinks and externalOutputLinks
  const allPreservedModelLinksLUT: { [uuid: string]: LinkInstance } = {};

  // filter links (don't use .filter with side effects)
  for (const link of model.links) {
    const connectedToSelectedSrc =
      link.src && isExtractedNodeById[link.src.node];
    const connectedToSelectedDst =
      link.dst && isExtractedNodeById[link.dst.node];

    if (!connectedToSelectedSrc && !connectedToSelectedDst) {
      preservedLinks.push(link);
      allPreservedModelLinksLUT[link.uuid] = link;
    } else if (connectedToSelectedSrc && connectedToSelectedDst) {
      extractedLinks.push(link);
      extractedLinksLinkIdLUT[link.uuid] = link;

      const srcNodeKey = link.src?.node ?? '_invalid';
      const srcPortKey = link.src?.port ?? -1;
      const dstNodeKey = link.dst?.node ?? '_invalid';
      const dstPortKey = link.dst?.port ?? -1;

      extractedLinksNodePortMap[srcNodeKey] = extractedLinksNodePortMap[
        srcNodeKey
      ] || { in: {}, out: {} };
      extractedLinksNodePortMap[dstNodeKey] = extractedLinksNodePortMap[
        dstNodeKey
      ] || { in: {}, out: {} };

      extractedLinksNodePortMap[srcNodeKey].out = {
        ...extractedLinksNodePortMap[srcNodeKey]?.out,
        [srcPortKey]: link,
      };
      extractedLinksNodePortMap[dstNodeKey].in = {
        ...extractedLinksNodePortMap[dstNodeKey].in,
        [dstPortKey]: link,
      };
    } else if (connectedToSelectedDst && !connectedToSelectedSrc) {
      externalInputLinks.push(link);
      const nodeKey = link.dst?.node ?? '_invalid';
      const portKey = link.dst?.port ?? -1;
      externalInputLinksNodePortMap[nodeKey] = {
        ...externalInputLinksNodePortMap[nodeKey],
        [portKey]: link,
      };

      allPreservedModelLinksLUT[link.uuid] = link;
    } else if (connectedToSelectedSrc && !connectedToSelectedDst) {
      externalOutputLinks.push(link);

      if (link.uiprops.link_type.connection_method !== 'link_tap') {
        const nodeKey = link.src?.node ?? '_invalid';
        const portKey = link.src?.port ?? -1;
        externalNormalOutputLinksNodePortMap[nodeKey] = {
          ...externalNormalOutputLinksNodePortMap[nodeKey],
          [portKey]: link,
        };
      }

      allPreservedModelLinksLUT[link.uuid] = link;
    } else {
      console.error('impossible condition reached');
    }
  }

  // we can already override the model links
  model.links = preservedLinks;

  // find out which external links are tapping links attached to extracted OR external links
  for (const link of externalOutputLinks) {
    if (extractedLinksLinkIdLUT[link.uuid]) continue;

    if (link.uiprops.link_type.connection_method === 'link_tap') {
      if (extractedLinksLinkIdLUT[link.uiprops.link_type.tapped_link_uuid]) {
        tappingExtractedTapLinks.push(link);
      } else {
        tappingExternalTapLinks.push(link);
      }
    }
  }

  // this helper function moves links points by the submodel offset
  const moveLinkByOffset = (link: LinkInstance) => {
    for (const segment of link.uiprops.segments) {
      const vert = segment.segment_direction === 'vert';
      segment.coordinate = snapNumberToGrid(
        segment.coordinate + (vert ? offsetX : offsetY),
      );
    }

    if (link.uiprops.link_type.connection_method === 'link_tap') {
      const vert =
        link.uiprops.link_type.tapped_segment.tapped_segment_direction ===
        'vert';
      link.uiprops.link_type.tap_coordinate = snapNumberToGrid(
        link.uiprops.link_type.tap_coordinate + (vert ? offsetY : offsetX),
      );
    }
  };

  // for fully extracted links, offset their segments and tap points
  extractedLinks.forEach((link) => moveLinkByOffset(link));

  const addNewInportBlock = (
    overrideCoord?: Coordinate,
  ): [BlockInstance, number] => {
    const port = newSubmodelInportBlocks.length;
    const coord = snapCoordinateToGrid({
      x: overrideCoord?.x ?? LEFT_MARGIN,
      y:
        overrideCoord?.y ??
        TOP_MARGIN + port * (BLOCK_HEIGHT + INTER_BLOCK_MARGIN_Y),
    });
    const base = blockTypeNameToInstanceDefaults('core.Inport');
    // HACK: non-refsubmodel blocks choke on this parameter - should be fixed on sim side
    // (also present in subdiagramUtils.ts)
    if (subdiagramType !== 'core.ReferenceSubmodel') {
      delete base.parameters.default_value;
    }

    const name = getUniqueIdentifier(
      `Inport_${inportMinIndex}`,
      submodelEntityNames,
      inportMinIndex,
    );
    const block: BlockInstance = { ...base, name, uiprops: { ...coord } };
    setPortIdMut(block, port);
    newSubmodelInportBlocks.push(block);
    submodelEntityNames.push(name);
    return [block, port];
  };

  const addNewOutportBlock = (
    overrideCoord?: Coordinate,
  ): [BlockInstance, number] => {
    const port = newSubmodelOutportBlocks.length;
    const coord = snapCoordinateToGrid({
      x:
        overrideCoord?.x ??
        MIN_BLOCK_X + subdiagramContentWidth + INTER_BLOCK_MARGIN_X,
      y:
        overrideCoord?.y ??
        TOP_MARGIN + port * (BLOCK_HEIGHT + INTER_BLOCK_MARGIN_Y),
    });
    const base = blockTypeNameToInstanceDefaults('core.Outport');
    const name = getUniqueIdentifier('Outport_0', submodelEntityNames);
    const block: BlockInstance = { ...base, name, uiprops: { ...coord } };
    setPortIdMut(block, port);
    newSubmodelOutportBlocks.push(block);
    submodelEntityNames.push(name);
    return [block, port];
  };

  for (let i = 0; i < extractedNodes.length; i++) {
    const currentNode = extractedNodes[i];

    // create links incoming into the submodel:
    // external incoming link, Inport block and internal link
    for (let j = 0; j < currentNode.inputs.length; j++) {
      const extractedLink = (extractedLinksNodePortMap[currentNode.uuid]?.in ||
        {})[j];
      if (extractedLink) continue;

      const portCoord = getPortWorldCoordinate(currentNode, PortSide.Input, {
        node: currentNode.uuid,
        port: j,
      });
      const [newInportBlock, newInportExtPort] = addNewInportBlock({
        x: (portCoord?.x ?? 0) - renderConstants.GRID_SIZE * 8,
        y: (portCoord?.y ?? 0) - renderConstants.GRID_SIZE * 4,
      });
      const newInternalLink = createDirectLink(
        { node: newInportBlock.uuid, port: 0 },
        { node: currentNode.uuid, port: j },
      );
      newSubmodelInternalLinks.push(newInternalLink);

      const externalLink = (externalInputLinksNodePortMap[currentNode.uuid] ||
        {})[j];
      if (externalLink) {
        const newExternalInputLink = {
          ...externalLink,
          dst: {
            node: newSubmodelUuid,
            port: newInportExtPort + inportMinIndex,
          },
        };
        model.links.push(newExternalInputLink);
      }
    }

    // create links going out of the submodel:
    // external outgoing link, Outport block and internal link
    for (let j = 0; j < currentNode.outputs.length; j++) {
      const extractedLink = (extractedLinksNodePortMap[currentNode.uuid]?.out ||
        {})[j];
      if (extractedLink) continue;

      const portCoord = getPortWorldCoordinate(currentNode, PortSide.Output, {
        node: currentNode.uuid,
        port: j,
      });
      const [newOutportBlock, newOutportExtPort] = addNewOutportBlock({
        x: (portCoord?.x ?? 0) + renderConstants.GRID_SIZE * 3,
        y: (portCoord?.y ?? 0) - renderConstants.GRID_SIZE * 4,
      });
      const newInternalLink = createDirectLink(
        { node: currentNode.uuid, port: j },
        { node: newOutportBlock.uuid, port: 0 },
      );
      newSubmodelInternalLinks.push(newInternalLink);

      const externalLink = (externalNormalOutputLinksNodePortMap[
        currentNode.uuid
      ] || {})[j];

      // this skips tapping links that require special handling
      if (
        externalLink &&
        externalLink.uiprops.link_type.connection_method !== 'link_tap'
      ) {
        const newExternalOutputLink = {
          ...externalLink,
          src: { node: newSubmodelUuid, port: newOutportExtPort },
        };
        model.links.push(newExternalOutputLink);
      }
    }
  }

  // reconnect tapping links that are connected to an external output link
  for (const externalLink of tappingExternalTapLinks) {
    if (externalLink.uiprops.link_type.connection_method !== 'link_tap')
      continue;

    const tappedLinkUuid = externalLink.uiprops.link_type.tapped_link_uuid;
    if (allPreservedModelLinksLUT[tappedLinkUuid]) {
      // the tapped link is already in the model, so we can just reuse this
      // tapping link as-is
      model.links.push(externalLink);
      continue;
    }

    // look for which outport the tapped link should be connected to
    const connectedOutport = newSubmodelOutportBlocks.find((outport) => {
      // FIXME this seems inefficient - could we build a LUT?
      for (let i = 0; i < newSubmodelInternalLinks.length; i++) {
        const internalLink = newSubmodelInternalLinks[i];
        if (
          internalLink.dst?.node === outport.uuid &&
          internalLink.src?.node === externalLink.src?.node &&
          internalLink.src?.port === externalLink.src?.port
        ) {
          return true;
        }
      }
      return false;
    });

    const port = connectedOutport
      ? getSubmodelPortIdOfIoNode(connectedOutport)
      : NaN;
    if (isNaN(port)) {
      console.error(
        'could not find connected outport for tapping link',
        dump(externalLink),
      );
      continue;
    }

    // reuse existing link and add it back to the model, update uuid & port
    // based on the Outport we found
    const newExternalTappedLink = {
      ...externalLink,
      src: { node: newSubmodelUuid, port },
    };
    model.links.push(newExternalTappedLink);
    setLinkSourceAndDependentTapSources(
      externalLink.uuid,
      { node: newSubmodelUuid, port },
      model.links,
      undefined, // the LUT is not valid in this context
    );
  }

  // reconnect tapping links that are connected to an extracted link
  // create new outport, internal link and external link
  for (const externalLink of tappingExtractedTapLinks) {
    if (externalLink.uiprops.link_type.connection_method !== 'link_tap')
      continue;

    const [newOutportBlock, port] = addNewOutportBlock();

    // create an internal tap link that connects to the new outport
    const newInternalLink: LinkInstance = {
      uuid: makeUuid(),
      src: externalLink.src,
      dst: { node: newOutportBlock.uuid, port: 0 },
      uiprops: getWriteableDeepCopy(externalLink.uiprops),
    };
    moveLinkByOffset(newInternalLink);
    newSubmodelInternalLinks.push(newInternalLink);

    // reuse external link but transform into a segment-less direct link
    // this will probably change the appearance of the link but can't find
    // a simpler solution right now
    const newExternalTappedLink: LinkInstance = {
      ...externalLink,
      src: { node: newSubmodelUuid, port },
      uiprops: {
        link_type: { connection_method: 'direct_to_block' },
        segments: [],
      },
    };
    model.links.push(newExternalTappedLink);
    setLinkSourceAndDependentTapSources(
      externalLink.uuid,
      { node: newSubmodelUuid, port },
      model.links,
      undefined, // the LUT is not valid in this context
    );
  }

  const diagram_uuid = makeUuid();
  const newNodes = [
    ...newSubmodelInportBlocks,
    ...newSubmodelOutportBlocks,
    ...extractedNodes,
  ];
  const newLinks = [...newSubmodelInternalLinks, ...extractedLinks];

  const hasNodesOrLinks = newNodes.length > 0 || newLinks.length > 0;
  const diagram: ModelDiagram = hasNodesOrLinks
    ? {
        uuid: diagram_uuid,
        nodes: newNodes,
        links: newLinks,
      }
    : createDefaultSubdiagram(subdiagramType);

  const uiprops: NodeUIProps = {
    x: subdiagramHousingNodeX,
    y: subdiagramHousingNodeY,
    grid_width: subdiagramContentWidth / renderConstants.GRID_SIZE,
    grid_height: subdiagramContentHeight / renderConstants.GRID_SIZE,
  };

  const nodeTypeName =
    referenceSubmodelName || nodeClassToPrintName(subdiagramType);
  const newSubmodelNode: SubmodelInstance = {
    uuid: newSubmodelUuid,
    type: subdiagramType,
    name: getUniqueNodeName(model, nodeTypeName, newSubmodelUuid),
    parameters: {},
    inputs: [],
    outputs: [],
    uiprops,
  };

  if (referenceSubmodelName) {
    newSubmodelNode.uiprops.show_port_name_labels = true;
  }

  const submodels = state.submodels;
  submodels.references[newSubmodelUuid] = { diagram_uuid };
  submodels.diagrams[diagram_uuid] = diagram;

  model.nodes.push(newSubmodelNode);

  unselectAll(state);
  updateModelIOPortBlocksMut(model);
  updateModelIOPortBlocksMut(diagram);
  updateSubmodelNodeIOPortsMut(newSubmodelNode, diagram);
}

export function addPremadeEntitiesToModel(
  state: ModelState,
  action: PayloadAction<{
    nodes: NodeInstance[];
    links: LinkInstance[];
    annotations?: AnnotationInstance[];
    referenceSubmodelIdToSubmodel: Record<string, SubmodelInfoLiteUI>;
    copiedEntitiesOldToNewMap?: { [newUuid: string]: string };
    overrideSubmodelsSection?: SubmodelsSection;
  }>,
) {
  const {
    nodes,
    links,
    annotations,
    referenceSubmodelIdToSubmodel,
    copiedEntitiesOldToNewMap,
    overrideSubmodelsSection,
  } = action.payload;

  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  model.links = model.links.concat(links);

  if (annotations) {
    if (model.annotations) {
      model.annotations = model.annotations.concat(annotations);
    } else {
      model.annotations = annotations;
    }
  }

  let hasSubmodels = false;

  const currentSubdiagramType = selectCurrentSubdiagramType(state);

  const parentPath = state.selectionParentPath;

  for (let i = 0; i < nodes.length; i++) {
    let minIndex =
      nodes[i].type === 'core.Inport'
        ? inportMinIndexForSubdiagram(currentSubdiagramType)
        : undefined;

    // HACK: non-refsubmodel blocks choke on this parameter - should be fixed on sim side
    // (also similarly present in subdiagramUtils.ts)
    // (if parentPath is longer than 0, then we're not editing a reference submodel's root directly.
    // it's also impossible to add an inport to anything other than a subdiagram or a ref-submodel's
    // root, so we can safely assume that if parentPath's length is 0,
    // and we're adding an inport, we are editing a reference submodel.)
    if (nodes[i].type === 'core.Inport' && parentPath.length > 0) {
      delete nodes[i].parameters.default_value;
    }

    nodes[i].name = getValidNodeName(
      model,
      nodes[i],
      referenceSubmodelIdToSubmodel,
      minIndex,
    );
    updateBlockPorts(nodes[i]);

    // If we have enough information to set the ports of the submodel instance
    // set the ports here.  Otherwise we will update the ports when we load
    // the reference submodel information later.
    const submodelInstance = nodes[i] as SubmodelInstance;
    if (submodelInstance) {
      const referenceSubmodelId = submodelInstance.submodel_reference_uuid;
      if (referenceSubmodelId && referenceSubmodelIdToSubmodel) {
        const referenceSubmodel = referenceSubmodelIdToSubmodel[
          referenceSubmodelId
        ] as SubmodelInfoUI;
        if (referenceSubmodel?.portDefinitionsInputs) {
          updateSubmodelInstanceForReferenceChanges(
            submodelInstance,
            referenceSubmodel,
          );
        }
      }
    }

    model.nodes.push(nodes[i]);

    if (nodeTypeIsSubdiagram(nodes[i].type)) {
      hasSubmodels = true;
    }
  }

  // reverse map from old-to-new to new-to-old uuids
  const copiedEntitiesNewToOldMap = copiedEntitiesOldToNewMap
    ? Object.keys(copiedEntitiesOldToNewMap).reduce((acc, cur) => {
        acc[copiedEntitiesOldToNewMap[cur]] = cur;
        return acc;
      }, {} as { [newUuid: string]: string })
    : {};

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (node.type === 'core.Inport' || node.type === 'core.Outport') {
      // preserve ordering of port_id when copy/pasting io port blocks
      const oldPortId = isNaN(getSubmodelPortIdOfIoNode(node))
        ? Infinity
        : getSubmodelPortIdOfIoNode(node);
      setPortIdMut(nodes[i], model.nodes.length + oldPortId);
    } else if (node.type === 'core.StateMachine') {
      const oldNodeUuid = copiedEntitiesNewToOldMap[node.uuid];
      const newStateMachines = state.stateMachines || {};

      // for checking whether we should copy a state machine for this model node,
      // if it's a copy-pasted model node
      const oldStateMachineId = specialGetStateMachineNodeInstanceId(node);
      if (oldNodeUuid && state.stateMachines && oldStateMachineId) {
        const newStateMachineUuid = makeUuid();
        const newCopy = getWriteableDeepCopy(
          state.stateMachines[oldStateMachineId],
        );
        if (newCopy) {
          newCopy.uuid = newStateMachineUuid;
          newStateMachines[newStateMachineUuid] = newCopy;
        }
        specialSetStateMachineNodeDiagramId(node, newStateMachineUuid);
      } else {
        const newStateMachineUuid = makeUuid();
        newStateMachines[newStateMachineUuid] = {
          uuid: newStateMachineUuid,
          nodes: [],
          links: [],
          entry_point: {},
        };
        specialSetStateMachineNodeDiagramId(node, newStateMachineUuid);
      }

      state.stateMachines = newStateMachines;
    } else if (nodeTypeIsSubdiagram(node.type)) {
      const oldNodeUuid = copiedEntitiesNewToOldMap[node.uuid];
      const submodelInstance = node as SubmodelInstance;
      copySubmodelReferencesRecursive_mut(
        state.submodels,
        overrideSubmodelsSection,
        oldNodeUuid,
        node.uuid,
        submodelInstance?.submodel_reference_uuid,
      );
    }
  }

  // update submodel io ports
  updateModelIOPortBlocksMut(model);
  const parentSubmodelNode = getCurrentParentSubmodelNodeFromState(state);
  if (parentSubmodelNode) {
    updateSubmodelNodeIOPortsMut(parentSubmodelNode, model);
  }
}
