import React from 'react';

import { t } from '@lingui/macro';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { renderConstants } from 'app/utils/renderConstants';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import { numberRules } from 'ui/common/Input/inputValidation';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput from 'ui/common/SelectInput';
import { BlockDisplayDetailsGraphic } from 'ui/modelEditor/BlockDisplayDetailsGraphic';
import {
  DetailInputRowsSection,
  DetailsDoubleRow,
  DetailsInput,
  DetailsLabel,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import { getMinimumVisualNodeHeight } from 'ui/modelRendererInternals/getVisualNodeHeight';
import { getMinimumVisualNodeWidth } from 'ui/modelRendererInternals/getVisualNodeWidth';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';

type Props = {
  selectedNode: NodeInstance;
};

const BlockDisplayDetails: React.FC<Props> = ({ selectedNode }) => {
  const dispatch = useAppDispatch();

  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const changeBlockCoordinates = (rawX: string, rawY: string) => {
    if (!canEditCurrentModelVersion) return;

    const x = parseFloat(rawX);
    const y = parseFloat(rawY);

    if (isNaN(x) || isNaN(y)) {
      console.warn(
        'Something went wrong with the coordinate input, x:',
        x,
        'y: ',
        y,
      );
    } else {
      dispatch(
        modelActions.moveBlocksTo({
          blockUuids: [selectedNode.uuid],
          x,
          y,
        }),
      );
    }
  };

  const changeBlockLabelPos = (newPosition: 'bottom' | 'top') => {
    if (!canEditCurrentModelVersion) return;

    dispatch(
      modelActions.changeNodeLabelPosition({
        blockUuid: selectedNode.uuid,
        newPosition,
      }),
    );
  };

  const changeBlockDirectionality = (newDirectionality: 'left' | 'right') => {
    if (!canEditCurrentModelVersion) return;

    dispatch(
      modelActions.changeNodeDirectionality({
        blockUuid: selectedNode.uuid,
        newDirectionality,
      }),
    );
  };

  const changeBlockPortSpacing = (
    newAlign: 'spaced' | 'top' | 'center' | 'bottom',
  ) => {
    if (!canEditCurrentModelVersion) return;

    dispatch(
      modelActions.changePortAlignment({
        blockUuid: selectedNode.uuid,
        newAlign,
      }),
    );
  };

  const xValue = React.useMemo(
    () => (selectedNode.uiprops.x || 0).toFixed(1),
    [selectedNode.uiprops.x],
  );
  const yValue = React.useMemo(
    () => (selectedNode.uiprops.y || 0).toFixed(1),
    [selectedNode.uiprops.y],
  );

  const changeNodeSize = (rawW: string, rawH: string) => {
    if (!canEditCurrentModelVersion || !selectedNode) return;

    const w = parseFloat(rawW);
    const h = parseFloat(rawH);

    if (isNaN(w) || isNaN(h)) {
      console.error(
        'Something went wrong with the coordinate input, w:',
        w,
        'h: ',
        h,
      );
    } else {
      dispatch(
        modelActions.resizeNode({
          nodeUuid: selectedNode.uuid,
          gridWidth: Math.round(w / renderConstants.GRID_SIZE),
          gridHeight: Math.round(h / renderConstants.GRID_SIZE),
        }),
      );
    }
  };

  const nodeHeight = React.useMemo(() => {
    const minHeight = Math.floor(
      getMinimumVisualNodeHeight(selectedNode) / renderConstants.GRID_SIZE,
    );
    return (
      Math.max(selectedNode?.uiprops.grid_height || 0, minHeight) *
      renderConstants.GRID_SIZE
    );
  }, [selectedNode]);

  const nodeWidth = React.useMemo(() => {
    const minWidth = Math.floor(
      getMinimumVisualNodeWidth(selectedNode) / renderConstants.GRID_SIZE,
    );
    return (
      Math.max(selectedNode?.uiprops.grid_width || 0, minWidth) *
      renderConstants.GRID_SIZE
    );
  }, [selectedNode]);

  return (
    <>
      <SectionHeading testId="display">
        {t({
          id: 'modelRenderer.propertiesSidebar.displayHeading.label',
          message: 'Display',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        <DetailsDoubleRow>
          <DetailsSection>
            <DetailsLabel shrink data-test-id="block-display-x-coord-label">
              X
            </DetailsLabel>
            <DetailsInput
              testId="block-display-x-coord-textbox"
              grow
              align={TextInputAlign.Right}
              value={xValue}
              onSubmitValue={(newX: string) =>
                changeBlockCoordinates(newX, `${selectedNode.uiprops.y}`)
              }
              validationRules={numberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSection>
          <DetailsSection>
            <DetailsLabel shrink data-test-id="block-display-y-coord-label">
              Y
            </DetailsLabel>
            <DetailsInput
              testId="block-display-y-coord-textbox"
              grow
              align={TextInputAlign.Right}
              value={yValue}
              onSubmitValue={(newY: string) =>
                changeBlockCoordinates(`${selectedNode.uiprops.x}`, newY)
              }
              validationRules={numberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSection>
        </DetailsDoubleRow>
        <DetailsDoubleRow>
          <DetailsSection>
            <DetailsLabel shrink data-test-id="block-display-width-label">
              W
            </DetailsLabel>
            <DetailsInput
              testId="block-display-width-textbox"
              grow
              align={TextInputAlign.Right}
              value={nodeWidth}
              onSubmitValue={(newW: string) =>
                changeNodeSize(newW, `${nodeHeight}`)
              }
              validationRules={numberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSection>
          <DetailsSection>
            <DetailsLabel shrink data-test-id="block-display-height-label">
              H
            </DetailsLabel>
            <DetailsInput
              testId="block-display-height-textbox"
              grow
              align={TextInputAlign.Right}
              value={nodeHeight}
              onSubmitValue={(newH: string) =>
                changeNodeSize(`${nodeWidth}`, newH)
              }
              validationRules={numberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSection>
        </DetailsDoubleRow>
        <DetailsDoubleRow noMargin>
          <BlockDisplayDetailsGraphic
            directionality={selectedNode.uiprops.directionality}
            labelPosition={selectedNode.uiprops.label_position}
            portAlignment={selectedNode.uiprops.port_alignment}
            changeDirectionality={changeBlockDirectionality}
            changeLabelPos={changeBlockLabelPos}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 16,
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 8,
              }}>
              <DetailsLabel
                stretch
                data-test-id="block-display-port-option-label">
                {t({
                  id: 'blockDisplayDetails.showPortsOptionLabel',
                  message: 'Port alignment',
                })}
              </DetailsLabel>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}>
              <SelectInput
                options={[
                  {
                    value: 'spaced',
                    label: t`Spaced apart`,
                  },
                  {
                    value: 'top',
                    label: t`Top aligned`,
                  },
                  {
                    value: 'center',
                    label: t`Middle aligned`,
                  },
                  {
                    value: 'bottom',
                    label: t`Bottom aligned`,
                  },
                ]}
                currentValue={selectedNode.uiprops.port_alignment || 'center'}
                onSelectValue={(newAlign: string) => {
                  if (
                    newAlign === 'spaced' ||
                    newAlign === 'top' ||
                    newAlign === 'center' ||
                    newAlign === 'bottom'
                  ) {
                    changeBlockPortSpacing(newAlign);
                  }
                }}
                isDisabled={!canEditCurrentModelVersion}
              />
            </div>
          </div>
        </DetailsDoubleRow>
      </DetailInputRowsSection>
    </>
  );
};

export default BlockDisplayDetails;
