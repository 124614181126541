import styled from '@emotion/styled/macro';
import { t, Trans } from '@lingui/macro';
import { useAppDispatch } from 'app/hooks';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Input from 'ui/common/Input/Input';
import { validNameRules } from 'ui/common/Input/inputValidation';
import { ActionButtonContainer, ModalInputGroup } from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import { useTos } from './useTos';

const TosModalContents = styled.div`
  width: 250px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[10]};
  padding-top: ${({ theme }) => theme.spacing.normal};

  > p {
    line-height: 1.6em;
    margin-bottom: ${({ theme }) => theme.spacing.normal};
  }
`;

const TermsOfServiceModal = ({ closeModal }: { closeModal: () => void }) => {
  const dispatch = useAppDispatch();

  const [sentAccept, setSentAccept] = React.useState(false);
  const { setAgreedTos } = useTos();
  const [organizationName, setOrganizationName] = React.useState('');

  const goToHomepage = () => {
    window.location.assign('https://www.collimator.ai/');
  };

  const accept = () => {
    setSentAccept(true);
    setAgreedTos(organizationName)
      .then(() => {
        closeModal();
      })
      .catch(() => {
        setSentAccept(false);
      });
  };

  return (
    <TosModalContents>
      <p>
        <Trans id="tosModal.beforeYouStart">
          Before you get started, we just need to let you know that your use of
          this application and associated services means you agree to our
        </Trans>
        &nbsp;
        <Link to="/tos" onClick={closeModal}>
          <Trans id="tosModal.tosLink">Terms of Service</Trans>
        </Link>
        .
      </p>
      <p>
        {/* this is the function macro to avoid needing to escape the apostrophe */}
        {t({
          id: 'tos.questionsOrIssues',
          message: `If you ever have any questions or issues, we're always available at `,
        })}
        &nbsp;
        <a href="mailto:help@collimator.ai" rel="noreferrer" target="_blank">
          help@collimator.ai
        </a>
        .
      </p>
      <br />
      <p>
        <Trans id="tosModal.enjoy">Enjoy!</Trans>
      </p>
      <p>
        <i>
          <Trans id="tosModal.collimatorTeam">The Collimator Team</Trans>
        </i>
      </p>
      <ModalInputGroup>
        <Input
          autoFocus
          hasBorder
          placeholder={t({
            message: 'Your Organization Name',
            id: 'tosModal.organizationNamePlaceholder',
          })}
          onChangeText={setOrganizationName}
          value={organizationName}
          validationRules={validNameRules}
        />
      </ModalInputGroup>
      <ActionButtonContainer>
        <Button variant={ButtonVariants.LargeSecondary} onClick={goToHomepage}>
          <Trans id="tosModal.cancel">Cancel</Trans>
        </Button>
        <Button
          variant={ButtonVariants.LargePrimary}
          onClick={accept}
          disabled={sentAccept || !organizationName.trim()}>
          <Trans id="tosModal.continue">Continue</Trans>
        </Button>
      </ActionButtonContainer>
    </TosModalContents>
  );
};

const TermsOfServiceLoader: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const { isFetching, hasAgreedToTermsOfService } = useTos();
  const { triggerModal, closeModal } = useModal();

  React.useEffect(() => {
    if (!isFetching && !hasAgreedToTermsOfService) {
      triggerModal(
        <TermsOfServiceModal closeModal={closeModal} />,
        'Welcome to Collimator!',
      );
    }
  }, [isFetching, hasAgreedToTermsOfService, triggerModal, closeModal]);

  if (isFetching || !hasAgreedToTermsOfService) {
    return null;
  }

  return children;
};

export default TermsOfServiceLoader;
