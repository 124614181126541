import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import { isValidBlockNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import {
  DetailsInput,
  DetailsLabel,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';

type Props = {
  parentPath: string[];
  blockUuid: string;
  title?: string;
  canEdit: boolean;
};

export const BlockNameField: React.FC<Props> = ({
  parentPath,
  blockUuid,
  title,
  canEdit,
}: Props) => {
  const dispatch = useAppDispatch();

  const nodes = useAppSelector(
    (state) => state.modelMetadata.currentDiagram?.nodes,
  );

  const onChangeBlockName = (newName: string) => {
    if (!canEdit) return;

    // TODO update block name in table

    dispatch(
      modelActions.changeBlockName({
        parentPath,
        blockUuid,
        newName: newName.trim(),
      }),
    );
  };

  return (
    <DetailsSection>
      <DetailsLabel shrink data-test-id="block-properties-block-name-label">
        {t({
          id: 'blockDetails.BlockName.Label',
          message: 'Block name',
        })}
      </DetailsLabel>
      <DetailsInput
        testId="block-properties-block-name-textbox"
        grow
        value={title}
        onSubmitValue={onChangeBlockName}
        align={TextInputAlign.Right}
        validationRules={isValidBlockNameRuleSet(nodes, blockUuid)}
        disabled={!canEdit}
      />
    </DetailsSection>
  );
};
