import { NodeInstance } from 'app/generated_types/SimulationModel';
import type { Coordinate } from 'app/common_types/Coordinate';
import { PortSide } from 'app/common_types/PortTypes';
import { PortIdentifier } from 'app/common_types/SegmentTypes';
import { getPortWorldCoordinate } from 'app/utils/getPortOffsetCoordinate';
import { PortConnLUTType } from './modelRenderer';

export type PortIdentifierWithCoord = {
  port: PortIdentifier;
  coord?: Coordinate;
};

export const getUnconnectedPortsWithCoordinatesForNodes = (
  nodes: NodeInstance[],
  connectedPortsLUT: PortConnLUTType,
): PortIdentifierWithCoord[] =>
  nodes.reduce<PortIdentifierWithCoord[]>((acc, node, _i, _arr) => {
    const newPorts: PortIdentifierWithCoord[] = [];
    const takenBlockPorts = connectedPortsLUT[node.uuid];

    for (let i = 0; i < node.inputs.length; i++) {
      const portFinder = ({
        side,
        portId,
      }: {
        side: PortSide;
        portId: number;
      }) => side === PortSide.Input && portId === i;

      if (
        !takenBlockPorts ||
        (takenBlockPorts && !takenBlockPorts.find(portFinder))
      ) {
        newPorts.push({
          port: {
            side: PortSide.Input,
            blockUuid: node.uuid,
            portId: i,
          },
          coord: getPortWorldCoordinate(node, PortSide.Input, {
            node: node.uuid,
            port: i,
          }),
        });
      }
    }

    for (let i = 0; i < node.outputs.length; i++) {
      const portFinder = ({
        side,
        portId,
      }: {
        side: PortSide;
        portId: number;
      }) => side === PortSide.Output && portId === i;

      if (
        !takenBlockPorts ||
        (takenBlockPorts && !takenBlockPorts.find(portFinder))
      ) {
        newPorts.push({
          port: {
            side: PortSide.Output,
            blockUuid: node.uuid,
            portId: i,
          },
          coord: getPortWorldCoordinate(node, PortSide.Output, {
            node: node.uuid,
            port: i,
          }),
        });
      }
    }

    return acc.concat(newPorts);
  }, []);
