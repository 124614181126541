// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const ModelicaFMU: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "ModelicaFMU",
      "namespace": "core",
      "description": "Instantiates a cosimulation exported according to the Modelica Association's FMI specification",
      "extra_parameters": true,
      "keywords": [
        "code"
      ],
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#modelica_fmu"
    },
    "modes": {
      "time": "discrete",
      "feedthrough": false
    },
    "ports": {
      "has_automatic_ports": true
    },
    "parameter_definitions": [
      {
        "name": "file_name",
        "description": "Name of the .fmu file to use",
        "data_type": "string",
        "default_value": ""
      },
      {
        "name": "fmu_guid",
        "data_type": "string",
        "default_value": "",
        "description": "guid from the .fmu's modelDescription.xml",
        "hidden": true
      }
    ]
  }
);
