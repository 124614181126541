import { v4 as uuid } from 'uuid';
import { PortIdentifier } from 'app/common_types/SegmentTypes';
import { modelActions } from 'app/slices/modelSlice';
import { PortSide } from 'app/common_types/PortTypes';
import { MouseActions } from 'app/common_types/MouseTypes';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { RendererState } from '../modelRenderer';
import { transitionMouseState } from '../transitionMouseState';

export const drawNewLinkFromPort = (
  rs: RendererState,
  hoverPort: PortIdentifier,
  draggingMode?: boolean,
) => {
  const newLinkUuid = uuid();

  rs.dispatch(
    modelActions.setSelections({
      selectionParentPath: getCurrentModelRef().submodelPath,
      selectedBlockIds: [hoverPort.blockUuid],
      selectedLinkIds: [],
      selectedAnnotationIds: [],
    }),
  );

  rs.dispatch(
    modelActions.addNewLinkToModel({
      uuid: newLinkUuid,
      linkPayload:
        hoverPort.side === PortSide.Output
          ? {
              source: {
                node: hoverPort.blockUuid,
                port: hoverPort.portId,
              },
            }
          : {
              destination: {
                node: hoverPort.blockUuid,
                port: hoverPort.portId,
              },
            },
      linkType: {
        connection_method: 'direct_to_block',
      },
    }),
  );

  transitionMouseState(rs, {
    state:
      hoverPort.side === PortSide.Output
        ? MouseActions.DrawingLinkFromEnd
        : MouseActions.DrawingLinkFromStart,
    linkUuid: newLinkUuid,
    draggingMode,
  });
};
