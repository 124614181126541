/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeLinecap?: any;
  x?: any;
  y?: any;
  rx?: any;
  strokeWidth?: any;
  strokeDasharray?: any;
  className?: string;
}

const Objectdetection: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5018 26.0012C12.4307 26.0346 14.5012 24.079 14.5012 16.8708C14.4996 15.5478 14.0785 14.2249 13.2366 13.6446C13.1155 13.5611 13.066 13.3978 13.1618 13.2861C13.2529 13.1798 13.3736 13.0668 13.4997 13.0264C13.6335 12.9835 14.0958 13.0026 14.3421 13.0165C14.4383 13.0219 14.5216 13.0837 14.5574 13.1732C15.0451 14.3923 15.6145 15.4291 15.999 15.4291C16.4397 15.4291 16.8803 14.3088 16.9785 14.0434C16.9922 14.0064 17.0133 13.9732 17.0445 13.9491C17.1678 13.8541 17.5226 13.6344 17.8965 13.9022C17.964 13.9506 17.99 14.0365 17.9759 14.1183L17.504 16.8496C17.5015 16.8637 17.5003 16.8785 17.5003 16.8928C17.5022 23.1522 18.3246 24.3657 19.2228 25.6123C19.3408 25.776 19.2326 25.9366 19.0627 25.8277C18.7975 25.6577 18.5309 25.4912 18.0004 26.0012L17.5727 26.4122C17.5262 26.4569 17.4622 26.4863 17.4022 26.4626C16.9704 26.2922 16.7444 25.0401 15.999 25.0401C14.9993 25.0401 15.2164 26.6904 14.9993 26.4818C13.9995 25.5207 13.5062 25.5298 12.5018 26.0012Z"
      stroke={props.stroke}
    />
    <path
      d="M14.1176 16.7917C11.8588 17.169 11.451 16.9489 11.2941 16.7917L10.3631 16.0452C9.48947 15.3446 9.0787 14.2158 9.29784 13.1176L9.41176 12.5467M9.41176 12.5467L9.36154 12.4963C8.53209 11.665 8.26399 10.4261 8.67551 9.32617L9.04501 8.3386C9.28249 7.70385 9.72791 7.16821 10.3087 6.81894L11.7647 5.94333L11.8385 5.89402C12.6742 5.33563 13.7339 5.2328 14.6613 5.62012L18.8235 7.35833M9.41176 12.5467L10.8235 13.0183M18.8235 7.35833L19.9297 7.13659C20.7229 6.97759 21.5463 7.16464 22.1929 7.65074V7.65074C22.7506 8.06994 23.1343 8.67985 23.2708 9.36401L23.5294 10.66L23.727 11.6502C23.9001 12.5177 23.6815 13.4173 23.1297 14.1087L22.5289 14.8614C22.258 15.2009 21.9166 15.4774 21.5283 15.672L19.9286 16.4737C19.5114 16.6828 19.0511 16.7917 18.5845 16.7917H17.8824M18.8235 7.35833C17.8824 7.83 17.4118 8.77333 17.4118 9.71666"
      stroke={props.stroke}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M14 21L12.1573 20.6291C11.7348 20.544 11.299 20.551 10.8794 20.6495L9.86299 20.8882C9.55224 20.9612 9.22709 20.9436 8.92603 20.8376L8.61854 20.7293C8.21874 20.5884 7.77378 20.6822 7.46484 20.9724V20.9724C7.12583 21.2909 6.62678 21.3702 6.20578 21.1724L6.10196 21.1236C5.92859 21.0422 5.73941 21 5.54786 21H5.30645C4.95592 21 4.6097 21.0773 4.29243 21.2263L4.24194 21.25C3.89141 21.4146 3.5089 21.5 3.12163 21.5H3"
      stroke={props.stroke}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M18 21.5L21.7044 21.9378C22.0526 21.9789 22.4053 21.9587 22.7466 21.8781L26.1212 21.0804C26.3473 21.027 26.5789 21 26.8113 21H29"
      stroke={props.stroke}
      strokeLinecap={props.strokeLinecap}
    />
    <rect
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
      rx={props.rx}
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeDasharray={props.strokeDasharray}
    />
  </svg>
);

Objectdetection.defaultProps = {
  width: '20',
  height: '26',
  viewBox: '0 0 32 32',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#1D3131',
  strokeLinecap: 'round',
  x: '6',
  y: '3',
  rx: '1',
  strokeWidth: '1.5',
  strokeDasharray: '4 4',
  className: '',
};

export default Objectdetection;
