import { useAppSelector } from 'app/hooks';
import React from 'react';
import CurrentModelLoader from 'ui/modelEditor/CurrentModelLoader';
import CurrentSubmodelLoader from 'ui/modelEditor/CurrentSubmodelLoader';
import { useAppParams } from 'util/useAppParams';

const CurrentModelOrSubmodelLoader: React.FC = () => {
  const { modelId, projectId } = useAppParams();

  const { topLevelModelId } = useAppSelector((state) => state.submodels);
  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  if (
    !modelId ||
    !projectId ||
    !topLevelModelId ||
    topLevelModelId !== modelId ||
    topLevelModelType === null
  ) {
    return null;
  }

  if (topLevelModelType === 'Model' || topLevelModelType === 'Experiment') {
    return <CurrentModelLoader projectId={projectId} modelId={modelId} />;
  }

  if (topLevelModelType === 'Submodel') {
    return <CurrentSubmodelLoader projectId={projectId} submodelId={modelId} />;
  }

  console.error('Invalid top-level model kind:', topLevelModelType);
  return null;
};

export default CurrentModelOrSubmodelLoader;
