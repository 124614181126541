import { useProjectOrRedirect } from 'app/behaviorHooks/useProjectOrRedirect';
import { useAppDispatch } from 'app/hooks';
import { NavbarContext, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import {
  AppContentWithFooterWrapper,
  AppContentWrapper,
} from 'ui/common/layout/appLayout';
import DashboardLeftSidebar from 'ui/dashboard/sidebar/Sidebar';
import ProjectDetail, { ProjectDetailContent } from './ProjectDetail';

/**
 * FIXME: this should only represent the inner section of the dashboard since
 * the Projects List and Project Details page share a common layout.
 * Would have helped avoid loading and flashing issues in the first place.
 */
const ProjectDetailLoader: React.FC = () => {
  const { isLoadingProject, project } = useProjectOrRedirect();
  const dispatch = useAppDispatch();

  // FIXME: Another example of why navbar should be a part of the app router.
  // We must set this here since the user is already on a dashboard route, or else the buttons flash.
  React.useEffect(() => {
    dispatch(uiFlagsActions.setNavbarContext(NavbarContext.Dashboard));
    return () => {
      dispatch(uiFlagsActions.setNavbarContext(NavbarContext.None));
    };
  }, [dispatch]);

  // Technically this should also check for isFetching.
  // Loading screen isn't great so showing stale data until it finishes works for now.
  if (isLoadingProject) {
    return (
      <AppContentWithFooterWrapper>
        <AppContentWrapper>
          <DashboardLeftSidebar />
          <ProjectDetailContent>
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          </ProjectDetailContent>
        </AppContentWrapper>
      </AppContentWithFooterWrapper>
    );
  }

  if (!project) {
    return null;
  }

  return <ProjectDetail project={project} />;
};

export default ProjectDetailLoader;
