import { PortSide } from 'app/common_types/PortTypes';

export interface PortIdentifier {
  side: PortSide;
  blockUuid: string;
  portId: number;
}

export enum FakeSegmentType {
  SStart,
  SMiddle,
  SEnd,
  Start,
  End,
}

export enum HoverEntityType {
  Node,
  NodeName,
  Port,
  Link,
  TapPoint,
  HangingStartPoint,
  HangingEndPoint,
  FakeLinkSegment,
  SignalPlotter,
  Annotation,
  AnnotationText,
  NodeResizeEdge,
  AnnotationResizeEdge,
}
