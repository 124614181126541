import { SubmodelFullUI } from 'app/apiTransformers/convertGetSubmodel';
import {
  ModelDiagram,
  NodeInstance,
  SubmodelInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { ModelState } from 'app/modelState/ModelState';
import {
  getReferenceSubmodelByNode,
  getSpecificReferenceSubmodelByNode,
} from 'app/utils/submodelUtils';

/**
 * Only use for editing the current diagram.
 * This method does not walk through
 * reference submodels boundaries.
 */
export const getLocalSubmodelDiagram = (
  submodels: SubmodelsSection,
  submodelNodeUuid: string,
): ModelDiagram | null => {
  const reference = submodels?.references[submodelNodeUuid];
  if (!reference?.diagram_uuid) {
    return null;
  }

  const diagram = submodels.diagrams[reference.diagram_uuid];
  if (!diagram) {
    console.error('could not find diagram for submodel', submodelNodeUuid);
    return null;
  }

  return diagram;
};

/**
 * Given a node ID (that represents a block inside a local submodel), finds and returns the parent local submodel node
 */
export function findLocalSubmodelParentBlock(
  rootNodes: NodeInstance[],
  submodels: SubmodelsSection,
  nodeId: string,
) {
  let nodeDiagramId;
  // First, we find the submodel this block belongs to
  for (const [diagramId, diagram] of Object.entries(submodels.diagrams)) {
    for (const node of diagram?.nodes || []) {
      if (node.uuid === nodeId) {
        nodeDiagramId = diagramId;
        break;
      }
    }
    if (nodeDiagramId) break;
  }
  if (!nodeDiagramId) return;
  // Now we look for that submodel ID on the list of submodel references
  let referenceId;
  for (const [refId, diagramReference] of Object.entries(
    submodels.references,
  )) {
    if (diagramReference?.diagram_uuid === nodeDiagramId) {
      referenceId = refId;
    }
  }
  if (!referenceId) return;
  // Once we have the reference ID, we need to find the node with that ID. It can be in the root model or in the submodel list
  for (const node of rootNodes) {
    if (node.uuid === referenceId) {
      return node;
    }
  }
  for (const diagram of Object.values(submodels.diagrams)) {
    for (const node of diagram?.nodes || []) {
      if (node.uuid === referenceId) {
        return node;
      }
    }
  }
}

export function findNodeInDiagram(
  nodes: NodeInstance[],
  submodels: SubmodelsSection,
  nodeId: string,
) {
  let node = nodes.find((node) => node.uuid === nodeId);
  if (node) {
    return node;
  }

  for (const diagram of Object.values(submodels.diagrams)) {
    if (!diagram) return;
    node = diagram.nodes.find((node) => node.uuid === nodeId);
    if (node) {
      return node;
    }
  }
}

/**
 * Only use for editing the current diagram.
 * This method does not walk through
 * reference submodels boundaries.
 */
export function getNode(model: ModelState, nodeId: string) {
  const {
    submodels,
    rootModel: { nodes },
  } = model;

  return findNodeInDiagram(nodes, submodels, nodeId);
}

/**
 * Finds the node information from the model or nested submodel,
 * including loaded reference submodels.
 */
export function getNestedNode(
  topLevelNodes: NodeInstance[],
  topLevelSubmodels: SubmodelsSection,
  parentPath?: string[],
  nodeId?: string,
  idToVersionIdToSubmodel?: Record<string, Record<string, SubmodelFullUI>>,
  idToLatestTaggedVersionId?: Record<string, string>,
) {
  if (!topLevelNodes || !topLevelSubmodels || !parentPath || !nodeId) {
    return;
  }

  let submodels: SubmodelsSection = topLevelSubmodels;
  let nodes: NodeInstance[] = topLevelNodes;

  for (let i = 0; i < parentPath.length; i += 1) {
    const parentId = parentPath[i];

    // Find the next parent in the current diagram.
    const parentNode = findNodeInDiagram(nodes, submodels, parentId);
    if (!parentNode) {
      return;
    }

    // If the next parent node is a reference submodel, update the diagram
    // we are using to search for the next node.
    const submodelFull = getReferenceSubmodelByNode(
      parentNode as SubmodelInstance,
      idToVersionIdToSubmodel,
      idToLatestTaggedVersionId,
    );
    if (submodelFull) {
      submodels = submodelFull.submodels;
      nodes = submodelFull.diagram.nodes;
    }
  }

  return findNodeInDiagram(nodes, submodels, nodeId);
}

/**
 * Finds the diagram associated with any parent path,
 * including walking any reference submodels whose diagrams are
 * loaded and available.
 */
export function getDiagramForPath(
  parentPath: string[],
  topLevelDiagram: ModelDiagram,
  topLevelSubmodels: SubmodelsSection,
  idToVersionIdToSubmodelFull: Record<string, Record<string, SubmodelFullUI>>,
  idToLatestTaggedVersionId: Record<string, string>,
): {
  diagram: ModelDiagram | null;
  submodelInstanceId?: string;
  submodelReferenceProjectId?: string;
  submodelReferenceId?: string;
} {
  let submodels: SubmodelsSection = topLevelSubmodels;
  let diagram: ModelDiagram = topLevelDiagram;
  let submodelInstanceId: string | undefined;
  let submodelReferenceProjectId: string | undefined;
  let submodelReferenceId: string | undefined;

  for (let i = 0; i < parentPath.length; i += 1) {
    const parentId = parentPath[i];

    // Find the next parent in the current diagram.
    let parentNode = diagram.nodes.find((node) => node.uuid === parentId);

    // The node was not found.
    // It might be missing because a reference submodel is loading.
    if (!parentNode) {
      return {
        diagram: null,
      };
    }

    // After finding the parent node in the current diagram,
    // find the diagram that contains this parent's children.
    const submodelFull = getSpecificReferenceSubmodelByNode(
      parentNode as SubmodelInstance,
      idToVersionIdToSubmodelFull,
      idToLatestTaggedVersionId,
    );
    if (submodelFull) {
      // The parent node is a reference submodel.
      submodelInstanceId = parentNode.uuid;
      submodelReferenceProjectId = submodelFull.projectId;
      submodelReferenceId = submodelFull.id;
      submodels = submodelFull.submodels;
      diagram = submodelFull.diagram;
    } else {
      // The parent node is a reference submodel being created, a local submodel or a code block.
      const nextDiagram = getLocalSubmodelDiagram(submodels, parentId);
      if (nextDiagram) {
        diagram = nextDiagram;
      } else {
        // The node was not found.
        // It might be missing because a reference submodel is loading.
        return {
          diagram: null,
        };
      }
    }
  }

  return {
    diagram: diagram || null,
    submodelInstanceId,
    submodelReferenceProjectId,
    submodelReferenceId,
  };
}

export function getIsViewingReferenceSubmodel({
  modelId,
  referenceSubmodelId,
}: {
  modelId: string | undefined;
  referenceSubmodelId: string | undefined;
}) {
  return referenceSubmodelId && referenceSubmodelId !== modelId;
}

export function getIsCurrentDiagramReadonly({
  modelId,
  loadedModelId,
  referenceSubmodelId,
  arePermissionsLoaded,
  canEditCurrentModelVersion,
}: {
  modelId: string | undefined;
  loadedModelId: string;
  referenceSubmodelId: string | undefined;
  arePermissionsLoaded: boolean;
  canEditCurrentModelVersion: boolean;
}) {
  const isViewingReferenceSubmodel =
    referenceSubmodelId && referenceSubmodelId !== modelId;
  const isDiagramReadonly = !!(
    loadedModelId &&
    modelId === loadedModelId &&
    arePermissionsLoaded &&
    (isViewingReferenceSubmodel || !canEditCurrentModelVersion)
  );
  return isDiagramReadonly;
}
