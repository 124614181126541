import { useTimeModeData } from 'app/api/useTimeModeData';
import React from 'react';

interface Props {
  modelId: string;
  correlationId: string;
  simulationId: string;
}

export const SignalTimeModePoller: React.FC<Props> = ({
  modelId,
  correlationId,
  simulationId,
}) => {
  useTimeModeData(modelId, correlationId, simulationId);

  return null;
};
