import styled from '@emotion/styled/macro';
import * as Sentry from '@sentry/react';
import { getAdminAssumedUser } from 'app/config/adminAssumedUser';
import { DISABLE_SENTRY } from 'app/config/globalApplicationConfig';
import React from 'react';
import { SignedIn, UserButton, useUser } from 'ui/auth/AuthComponents';

const ClerkButtonDiv = styled.div<{ isAssumedUser: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  &:hover {
    background-color: ${({ isAssumedUser, theme }) =>
      isAssumedUser
        ? theme.colors.brand.secondary.lighter
        : theme.colors.brand.tertiary.darkest};
  }
  ${({ isAssumedUser, theme }) =>
    isAssumedUser
      ? `background-color: ${theme.colors.brand.secondary.base};`
      : ''}

  > div.cl-user-button {
    width: ${({ theme }) => theme.spacing.xlarge};
    height: ${({ theme }) => theme.spacing.xlarge};
  }

  > div.cl-user-button > .cl-user-button-trigger > img {
    width: 100%;
    height: 100%;
  }

  > button {
    display: flex;
    align-items: center;
    padding: ${(props) => props.theme.spacing.normal};
    background: rgba(247, 247, 248, 0.1);
    border-radius: 2px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: ${(props) => props.theme.typography.font.base.size};

    &:hover {
      background: rgba(247, 247, 248, 0.8);
      color: #363c40;
    }

    &:disabled {
      opacity: 0.4;
      cursor: wait;
    }
  }
`;

const ClerkButton: React.FC = () => {
  const isAssumedUser = !!getAdminAssumedUser();
  const { user } = useUser();

  if (!DISABLE_SENTRY) {
    Sentry.setUser({
      email: user?.primaryEmailAddress?.emailAddress,
      username: user?.fullName || undefined,
    });
  }

  return (
    <ClerkButtonDiv
      data-test-id="user-profile-navbar-button"
      isAssumedUser={isAssumedUser}>
      <SignedIn>
        <UserButton />
      </SignedIn>
    </ClerkButtonDiv>
  );
};

export default ClerkButton;
