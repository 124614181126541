import styled from '@emotion/styled/macro';
import React from 'react';

const CheckboxAnchor = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing.small};
`;

const CheckboxWrapper = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 18px;
  height: 18px;
  ${({ isDisabled }) => (isDisabled ? 'opacity: 0.2' : '')};
`;

type Props = {
  isDisabled?: boolean;
  value?: boolean;
  onChange?: (b: boolean) => void;
  testId?: string;
};

export const Checkbox: React.FC<Props> = ({
  isDisabled,
  value,
  onChange,
  testId,
}: Props): React.ReactElement => {
  const clickHandler = React.useCallback(() => {
    if (isDisabled) return;
    if (onChange) onChange(!value);
  }, [value, onChange, isDisabled]);

  return (
    <CheckboxAnchor>
      <CheckboxWrapper
        onClick={clickHandler}
        isDisabled={isDisabled}
        data-test-id={testId}>
        {value ? (
          <img src={`${process.env.PUBLIC_URL}/assets/checkbox_checked.svg`} />
        ) : (
          <img
            src={`${process.env.PUBLIC_URL}/assets/checkbox_unchecked.svg`}
          />
        )}
      </CheckboxWrapper>
    </CheckboxAnchor>
  );
};
