/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Play: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.75 4.35319V11.6468L11.5849 8.00001L5.75 4.35319ZM4.25 3.90213C4.25 2.92034 5.32993 2.32178 6.1625 2.84213L12.7191 6.94001C13.5024 7.42959 13.5024 8.57042 12.7191 9.06L6.1625 13.1579C5.32994 13.6782 4.25 13.0797 4.25 12.0979V3.90213Z"
      fill={props.fill}
    />
  </svg>
);

Play.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Play;
