/*
  Generated by atdts from type definitions in 'type.atd'.

  Type-safe translations from/to JSON

  For each type 'Foo', there is a pair of functions:
  - 'writeFoo': convert a 'Foo' value into a JSON-compatible value.
  - 'readFoo': convert a JSON-compatible value into a TypeScript value
    of type 'Foo'.
*/

/* tslint:disable */
/* eslint-disable */

export type Name = string

export type CmlType =
| { kind: 'TypeVar'; value: number /*int*/ }
| { kind: 'Unit' }
| { kind: 'Bool' }
| { kind: 'String' }
| { kind: 'UInt'; value: number /*int*/ }
| { kind: 'Int'; value: number /*int*/ }
| { kind: 'Float'; value: number /*int*/ }
| { kind: 'Complex'; value: number /*int*/ }
| { kind: 'Tuple'; value: CmlType[] }
| { kind: 'Struct'; value: NameMap }
| { kind: 'Tensor'; value: [Rank, CmlType] }
| { kind: 'Python'; value: string }

export type Rank =
| { kind: 'Var'; value: [string, number /*int*/] }
| { kind: 'Dims'; value: DimExpr[] }

export type DimExpr =
| { kind: 'Int'; value: number /*int*/ }
| { kind: 'Var'; value: [string, number /*int*/] }

export type NameMap = [Name, CmlType][]

export type Uuid = string

export type Path = string

export type UuidPath = string[]

export type Port = [Uuid, number /*int*/]

export type TypeMap = [Port, CmlType][]

export type TypeMapRecord = {
  path: Path;
  port_index: number /*int*/;
  cml_type: CmlType;
  is_outport?: boolean;
}

export type TypeMapStruct = TypeMapRecord[]

export type TimeMode =
| { kind: 'Discrete' }
| { kind: 'Continuous' }
| { kind: 'Agnostic' }
| { kind: 'Constant' }
| { kind: 'Hybrid' }
| { kind: 'Iterator' }

export type TimeModeAssignmentMap = [Path, TimeMode][]

export type TimeModeRecord = {
  path: Path;
  time_mode: TimeMode;
  discrete_step: number;
}

export type TimeModeList = TimeModeRecord[]

export type BlockExecutionIndex = {
  path: Path;
  index: number /*int*/;
}

export type BlockExecutionOrder = BlockExecutionIndex[]

export type NamePathToUuidPathRecord = {
  name_path: Path;
  uuid_path: UuidPath;
}

export type NamePathToUuidPath = NamePathToUuidPathRecord[]

export type ResultMessage = {
  exit_code: number /*int*/;
  level: string;
  time: number;
  timestamp: number;
  model_json: string;
  c_file?: string;
  exe_file?: string;
  x_atd_error?: CompilationErrors;
  message?: string;
  simulation_uuid?: string;
}

export type CompilationErrors = CompilationError[]

export type CompilationError =
| { kind: 'UnconnectedInput'; value: Location }
| { kind: 'AlgebraicLoop'; value: Cycle }
| { kind: 'InvalidParam'; value: Location }

export type Cycle = {
  locations: Location[];
}

export type Location = {
  block_id?: BlockId;
  port_id?: PortId;
  parameter_name?: string;
  message?: string;
}

export type BlockId = {
  name: string;
  name_path: string[];
  uuid_path: Uuid[];
}

export type PortId = {
  direction: Direction;
  index: number /*int*/;
  name?: string;
}

export type EndPt = {
  block_id: BlockId;
  port_id: PortId;
}

export type EndPtList = EndPt[]

export type Direction =
| { kind: 'In' }
| { kind: 'Out' }

export type Toc = {
  header: Header;
  clocks: Clocks;
  signals: Signals;
}

export type Header = {
  version: [number /*int*/, number /*int*/, number /*int*/];
  source_model: Uuid;
  executable: string;
}

export type Clock = {
  name: string;
  clock_spec: ClockSpec;
}

export type Clocks = Clock[]

export type Signal = {
  signal_id: SignalId;
  signal_spec: SignalSpec;
}

export type Signals = Signal[]

export type SignalId = string[]

export type SignalSpec = {
  signal_type: CmlType;
  clock_name: string;
  signal_data_file: string;
}

export type ClockSpec =
| { kind: 'Continuous'; value: string }

export type Q = [string, string]

export function writeName(x: Name, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readName(x: any, context: any = x): Name {
  return _atd_read_string(x, context);
}

export function writeCmlType(x: CmlType, context: any = x): any {
  switch (x.kind) {
    case 'TypeVar':
      return ['TypeVar', _atd_write_int(x.value, x)]
    case 'Unit':
      return 'Unit'
    case 'Bool':
      return 'Bool'
    case 'String':
      return 'String'
    case 'UInt':
      return ['UInt', _atd_write_int(x.value, x)]
    case 'Int':
      return ['Int', _atd_write_int(x.value, x)]
    case 'Float':
      return ['Float', _atd_write_int(x.value, x)]
    case 'Complex':
      return ['Complex', _atd_write_int(x.value, x)]
    case 'Tuple':
      return ['Tuple', _atd_write_array(writeCmlType)(x.value, x)]
    case 'Struct':
      return ['Struct', writeNameMap(x.value, x)]
    case 'Tensor':
      return ['Tensor', ((x: any, context) => [writeRank(x[0], x), writeCmlType(x[1], x)])(x.value, x)]
    case 'Python':
      return ['Python', _atd_write_string(x.value, x)]
  }
}

export function readCmlType(x: any, context: any = x): CmlType {
  if (typeof x === 'string') {
    switch (x) {
      case 'Unit':
        return { kind: 'Unit' }
      case 'Bool':
        return { kind: 'Bool' }
      case 'String':
        return { kind: 'String' }
      default:
        _atd_bad_json('CmlType', x, context)
        throw new Error('impossible')
    }
  }
  else {
    _atd_check_json_tuple(2, x, context)
    switch (x[0]) {
      case 'TypeVar':
        return { kind: 'TypeVar', value: _atd_read_int(x[1], x) }
      case 'UInt':
        return { kind: 'UInt', value: _atd_read_int(x[1], x) }
      case 'Int':
        return { kind: 'Int', value: _atd_read_int(x[1], x) }
      case 'Float':
        return { kind: 'Float', value: _atd_read_int(x[1], x) }
      case 'Complex':
        return { kind: 'Complex', value: _atd_read_int(x[1], x) }
      case 'Tuple':
        return { kind: 'Tuple', value: _atd_read_array(readCmlType)(x[1], x) }
      case 'Struct':
        return { kind: 'Struct', value: readNameMap(x[1], x) }
      case 'Tensor':
        return { kind: 'Tensor', value: ((x, context): [Rank, CmlType] => { _atd_check_json_tuple(2, x, context); return [readRank(x[0], x), readCmlType(x[1], x)] })(x[1], x) }
      case 'Python':
        return { kind: 'Python', value: _atd_read_string(x[1], x) }
      default:
        _atd_bad_json('CmlType', x, context)
        throw new Error('impossible')
    }
  }
}

export function writeRank(x: Rank, context: any = x): any {
  switch (x.kind) {
    case 'Var':
      return ['Var', ((x: any, context) => [_atd_write_string(x[0], x), _atd_write_int(x[1], x)])(x.value, x)]
    case 'Dims':
      return ['Dims', _atd_write_array(writeDimExpr)(x.value, x)]
  }
}

export function readRank(x: any, context: any = x): Rank {
  _atd_check_json_tuple(2, x, context)
  switch (x[0]) {
    case 'Var':
      return { kind: 'Var', value: ((x, context): [string, number /*int*/] => { _atd_check_json_tuple(2, x, context); return [_atd_read_string(x[0], x), _atd_read_int(x[1], x)] })(x[1], x) }
    case 'Dims':
      return { kind: 'Dims', value: _atd_read_array(readDimExpr)(x[1], x) }
    default:
      _atd_bad_json('Rank', x, context)
      throw new Error('impossible')
  }
}

export function writeDimExpr(x: DimExpr, context: any = x): any {
  switch (x.kind) {
    case 'Int':
      return ['Int', _atd_write_int(x.value, x)]
    case 'Var':
      return ['Var', ((x: any, context) => [_atd_write_string(x[0], x), _atd_write_int(x[1], x)])(x.value, x)]
  }
}

export function readDimExpr(x: any, context: any = x): DimExpr {
  _atd_check_json_tuple(2, x, context)
  switch (x[0]) {
    case 'Int':
      return { kind: 'Int', value: _atd_read_int(x[1], x) }
    case 'Var':
      return { kind: 'Var', value: ((x, context): [string, number /*int*/] => { _atd_check_json_tuple(2, x, context); return [_atd_read_string(x[0], x), _atd_read_int(x[1], x)] })(x[1], x) }
    default:
      _atd_bad_json('DimExpr', x, context)
      throw new Error('impossible')
  }
}

export function writeNameMap(x: NameMap, context: any = x): any {
  return _atd_write_array(((x: any, context) => [writeName(x[0], x), writeCmlType(x[1], x)]))(x, context);
}

export function readNameMap(x: any, context: any = x): NameMap {
  return _atd_read_array(((x, context): [Name, CmlType] => { _atd_check_json_tuple(2, x, context); return [readName(x[0], x), readCmlType(x[1], x)] }))(x, context);
}

export function writeUuid(x: Uuid, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readUuid(x: any, context: any = x): Uuid {
  return _atd_read_string(x, context);
}

export function writePath(x: Path, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readPath(x: any, context: any = x): Path {
  return _atd_read_string(x, context);
}

export function writeUuidPath(x: UuidPath, context: any = x): any {
  return _atd_write_array(_atd_write_string)(x, context);
}

export function readUuidPath(x: any, context: any = x): UuidPath {
  return _atd_read_array(_atd_read_string)(x, context);
}

export function writePort(x: Port, context: any = x): any {
  return ((x: any, context) => [writeUuid(x[0], x), _atd_write_int(x[1], x)])(x, context);
}

export function readPort(x: any, context: any = x): Port {
  return ((x, context): [Uuid, number /*int*/] => { _atd_check_json_tuple(2, x, context); return [readUuid(x[0], x), _atd_read_int(x[1], x)] })(x, context);
}

export function writeTypeMap(x: TypeMap, context: any = x): any {
  return _atd_write_array(((x: any, context) => [writePort(x[0], x), writeCmlType(x[1], x)]))(x, context);
}

export function readTypeMap(x: any, context: any = x): TypeMap {
  return _atd_read_array(((x, context): [Port, CmlType] => { _atd_check_json_tuple(2, x, context); return [readPort(x[0], x), readCmlType(x[1], x)] }))(x, context);
}

export function writeTypeMapRecord(x: TypeMapRecord, context: any = x): any {
  return {
    'path': _atd_write_required_field('TypeMapRecord', 'path', writePath, x.path, x),
    'port_index': _atd_write_required_field('TypeMapRecord', 'port_index', _atd_write_int, x.port_index, x),
    'cml_type': _atd_write_required_field('TypeMapRecord', 'cml_type', writeCmlType, x.cml_type, x),
    'is_outport': _atd_write_optional_field(_atd_write_bool, x.is_outport, x),
  };
}

export function readTypeMapRecord(x: any, context: any = x): TypeMapRecord {
  return {
    path: _atd_read_required_field('TypeMapRecord', 'path', readPath, x['path'], x),
    port_index: _atd_read_required_field('TypeMapRecord', 'port_index', _atd_read_int, x['port_index'], x),
    cml_type: _atd_read_required_field('TypeMapRecord', 'cml_type', readCmlType, x['cml_type'], x),
    is_outport: _atd_read_optional_field(_atd_read_bool, x['is_outport'], x),
  };
}

export function writeTypeMapStruct(x: TypeMapStruct, context: any = x): any {
  return _atd_write_array(writeTypeMapRecord)(x, context);
}

export function readTypeMapStruct(x: any, context: any = x): TypeMapStruct {
  return _atd_read_array(readTypeMapRecord)(x, context);
}

export function writeTimeMode(x: TimeMode, context: any = x): any {
  switch (x.kind) {
    case 'Discrete':
      return 'Discrete'
    case 'Continuous':
      return 'Continuous'
    case 'Agnostic':
      return 'Agnostic'
    case 'Constant':
      return 'Constant'
    case 'Hybrid':
      return 'Hybrid'
    case 'Iterator':
      return 'Iterator'
  }
}

export function readTimeMode(x: any, context: any = x): TimeMode {
  switch (x) {
    case 'Discrete':
      return { kind: 'Discrete' }
    case 'Continuous':
      return { kind: 'Continuous' }
    case 'Agnostic':
      return { kind: 'Agnostic' }
    case 'Constant':
      return { kind: 'Constant' }
    case 'Hybrid':
      return { kind: 'Hybrid' }
    case 'Iterator':
      return { kind: 'Iterator' }
    default:
      _atd_bad_json('TimeMode', x, context)
      throw new Error('impossible')
  }
}

export function writeTimeModeAssignmentMap(x: TimeModeAssignmentMap, context: any = x): any {
  return _atd_write_array(((x: any, context) => [writePath(x[0], x), writeTimeMode(x[1], x)]))(x, context);
}

export function readTimeModeAssignmentMap(x: any, context: any = x): TimeModeAssignmentMap {
  return _atd_read_array(((x, context): [Path, TimeMode] => { _atd_check_json_tuple(2, x, context); return [readPath(x[0], x), readTimeMode(x[1], x)] }))(x, context);
}

export function writeTimeModeRecord(x: TimeModeRecord, context: any = x): any {
  return {
    'path': _atd_write_required_field('TimeModeRecord', 'path', writePath, x.path, x),
    'time_mode': _atd_write_required_field('TimeModeRecord', 'time_mode', writeTimeMode, x.time_mode, x),
    'discrete_step': _atd_write_required_field('TimeModeRecord', 'discrete_step', _atd_write_float, x.discrete_step, x),
  };
}

export function readTimeModeRecord(x: any, context: any = x): TimeModeRecord {
  return {
    path: _atd_read_required_field('TimeModeRecord', 'path', readPath, x['path'], x),
    time_mode: _atd_read_required_field('TimeModeRecord', 'time_mode', readTimeMode, x['time_mode'], x),
    discrete_step: _atd_read_required_field('TimeModeRecord', 'discrete_step', _atd_read_float, x['discrete_step'], x),
  };
}

export function writeTimeModeList(x: TimeModeList, context: any = x): any {
  return _atd_write_array(writeTimeModeRecord)(x, context);
}

export function readTimeModeList(x: any, context: any = x): TimeModeList {
  return _atd_read_array(readTimeModeRecord)(x, context);
}

export function writeBlockExecutionIndex(x: BlockExecutionIndex, context: any = x): any {
  return {
    'path': _atd_write_required_field('BlockExecutionIndex', 'path', writePath, x.path, x),
    'index': _atd_write_required_field('BlockExecutionIndex', 'index', _atd_write_int, x.index, x),
  };
}

export function readBlockExecutionIndex(x: any, context: any = x): BlockExecutionIndex {
  return {
    path: _atd_read_required_field('BlockExecutionIndex', 'path', readPath, x['path'], x),
    index: _atd_read_required_field('BlockExecutionIndex', 'index', _atd_read_int, x['index'], x),
  };
}

export function writeBlockExecutionOrder(x: BlockExecutionOrder, context: any = x): any {
  return _atd_write_array(writeBlockExecutionIndex)(x, context);
}

export function readBlockExecutionOrder(x: any, context: any = x): BlockExecutionOrder {
  return _atd_read_array(readBlockExecutionIndex)(x, context);
}

export function writeNamePathToUuidPathRecord(x: NamePathToUuidPathRecord, context: any = x): any {
  return {
    'name_path': _atd_write_required_field('NamePathToUuidPathRecord', 'name_path', writePath, x.name_path, x),
    'uuid_path': _atd_write_required_field('NamePathToUuidPathRecord', 'uuid_path', writeUuidPath, x.uuid_path, x),
  };
}

export function readNamePathToUuidPathRecord(x: any, context: any = x): NamePathToUuidPathRecord {
  return {
    name_path: _atd_read_required_field('NamePathToUuidPathRecord', 'name_path', readPath, x['name_path'], x),
    uuid_path: _atd_read_required_field('NamePathToUuidPathRecord', 'uuid_path', readUuidPath, x['uuid_path'], x),
  };
}

export function writeNamePathToUuidPath(x: NamePathToUuidPath, context: any = x): any {
  return _atd_write_array(writeNamePathToUuidPathRecord)(x, context);
}

export function readNamePathToUuidPath(x: any, context: any = x): NamePathToUuidPath {
  return _atd_read_array(readNamePathToUuidPathRecord)(x, context);
}

export function writeResultMessage(x: ResultMessage, context: any = x): any {
  return {
    'exit_code': _atd_write_required_field('ResultMessage', 'exit_code', _atd_write_int, x.exit_code, x),
    'level': _atd_write_required_field('ResultMessage', 'level', _atd_write_string, x.level, x),
    'time': _atd_write_required_field('ResultMessage', 'time', _atd_write_float, x.time, x),
    'timestamp': _atd_write_required_field('ResultMessage', 'timestamp', _atd_write_float, x.timestamp, x),
    'model_json': _atd_write_required_field('ResultMessage', 'model_json', _atd_write_string, x.model_json, x),
    'c_file': _atd_write_optional_field(_atd_write_string, x.c_file, x),
    'exe_file': _atd_write_optional_field(_atd_write_string, x.exe_file, x),
    'atd_error': _atd_write_optional_field(writeCompilationErrors, x.x_atd_error, x),
    'message': _atd_write_optional_field(_atd_write_string, x.message, x),
    'simulation_uuid': _atd_write_optional_field(_atd_write_string, x.simulation_uuid, x),
  };
}

export function readResultMessage(x: any, context: any = x): ResultMessage {
  return {
    exit_code: _atd_read_required_field('ResultMessage', 'exit_code', _atd_read_int, x['exit_code'], x),
    level: _atd_read_required_field('ResultMessage', 'level', _atd_read_string, x['level'], x),
    time: _atd_read_required_field('ResultMessage', 'time', _atd_read_float, x['time'], x),
    timestamp: _atd_read_required_field('ResultMessage', 'timestamp', _atd_read_float, x['timestamp'], x),
    model_json: _atd_read_required_field('ResultMessage', 'model_json', _atd_read_string, x['model_json'], x),
    c_file: _atd_read_optional_field(_atd_read_string, x['c_file'], x),
    exe_file: _atd_read_optional_field(_atd_read_string, x['exe_file'], x),
    x_atd_error: _atd_read_optional_field(readCompilationErrors, x['atd_error'], x),
    message: _atd_read_optional_field(_atd_read_string, x['message'], x),
    simulation_uuid: _atd_read_optional_field(_atd_read_string, x['simulation_uuid'], x),
  };
}

export function writeCompilationErrors(x: CompilationErrors, context: any = x): any {
  return _atd_write_array(writeCompilationError)(x, context);
}

export function readCompilationErrors(x: any, context: any = x): CompilationErrors {
  return _atd_read_array(readCompilationError)(x, context);
}

export function writeCompilationError(x: CompilationError, context: any = x): any {
  switch (x.kind) {
    case 'UnconnectedInput':
      return ['UnconnectedInput', writeLocation(x.value, x)]
    case 'AlgebraicLoop':
      return ['AlgebraicLoop', writeCycle(x.value, x)]
    case 'InvalidParam':
      return ['InvalidParam', writeLocation(x.value, x)]
  }
}

export function readCompilationError(x: any, context: any = x): CompilationError {
  _atd_check_json_tuple(2, x, context)
  switch (x[0]) {
    case 'UnconnectedInput':
      return { kind: 'UnconnectedInput', value: readLocation(x[1], x) }
    case 'AlgebraicLoop':
      return { kind: 'AlgebraicLoop', value: readCycle(x[1], x) }
    case 'InvalidParam':
      return { kind: 'InvalidParam', value: readLocation(x[1], x) }
    default:
      _atd_bad_json('CompilationError', x, context)
      throw new Error('impossible')
  }
}

export function writeCycle(x: Cycle, context: any = x): any {
  return {
    'locations': _atd_write_required_field('Cycle', 'locations', _atd_write_array(writeLocation), x.locations, x),
  };
}

export function readCycle(x: any, context: any = x): Cycle {
  return {
    locations: _atd_read_required_field('Cycle', 'locations', _atd_read_array(readLocation), x['locations'], x),
  };
}

export function writeLocation(x: Location, context: any = x): any {
  return {
    'block_id': _atd_write_optional_field(writeBlockId, x.block_id, x),
    'port_id': _atd_write_optional_field(writePortId, x.port_id, x),
    'parameter_name': _atd_write_optional_field(_atd_write_string, x.parameter_name, x),
    'message': _atd_write_optional_field(_atd_write_string, x.message, x),
  };
}

export function readLocation(x: any, context: any = x): Location {
  return {
    block_id: _atd_read_optional_field(readBlockId, x['block_id'], x),
    port_id: _atd_read_optional_field(readPortId, x['port_id'], x),
    parameter_name: _atd_read_optional_field(_atd_read_string, x['parameter_name'], x),
    message: _atd_read_optional_field(_atd_read_string, x['message'], x),
  };
}

export function writeBlockId(x: BlockId, context: any = x): any {
  return {
    'name': _atd_write_required_field('BlockId', 'name', _atd_write_string, x.name, x),
    'name_path': _atd_write_required_field('BlockId', 'name_path', _atd_write_array(_atd_write_string), x.name_path, x),
    'uuid_path': _atd_write_required_field('BlockId', 'uuid_path', _atd_write_array(writeUuid), x.uuid_path, x),
  };
}

export function readBlockId(x: any, context: any = x): BlockId {
  return {
    name: _atd_read_required_field('BlockId', 'name', _atd_read_string, x['name'], x),
    name_path: _atd_read_required_field('BlockId', 'name_path', _atd_read_array(_atd_read_string), x['name_path'], x),
    uuid_path: _atd_read_required_field('BlockId', 'uuid_path', _atd_read_array(readUuid), x['uuid_path'], x),
  };
}

export function writePortId(x: PortId, context: any = x): any {
  return {
    'direction': _atd_write_required_field('PortId', 'direction', writeDirection, x.direction, x),
    'index': _atd_write_required_field('PortId', 'index', _atd_write_int, x.index, x),
    'name': _atd_write_optional_field(_atd_write_string, x.name, x),
  };
}

export function readPortId(x: any, context: any = x): PortId {
  return {
    direction: _atd_read_required_field('PortId', 'direction', readDirection, x['direction'], x),
    index: _atd_read_required_field('PortId', 'index', _atd_read_int, x['index'], x),
    name: _atd_read_optional_field(_atd_read_string, x['name'], x),
  };
}

export function writeEndPt(x: EndPt, context: any = x): any {
  return {
    'block_id': _atd_write_required_field('EndPt', 'block_id', writeBlockId, x.block_id, x),
    'port_id': _atd_write_required_field('EndPt', 'port_id', writePortId, x.port_id, x),
  };
}

export function readEndPt(x: any, context: any = x): EndPt {
  return {
    block_id: _atd_read_required_field('EndPt', 'block_id', readBlockId, x['block_id'], x),
    port_id: _atd_read_required_field('EndPt', 'port_id', readPortId, x['port_id'], x),
  };
}

export function writeEndPtList(x: EndPtList, context: any = x): any {
  return _atd_write_array(writeEndPt)(x, context);
}

export function readEndPtList(x: any, context: any = x): EndPtList {
  return _atd_read_array(readEndPt)(x, context);
}

export function writeDirection(x: Direction, context: any = x): any {
  switch (x.kind) {
    case 'In':
      return 'In'
    case 'Out':
      return 'Out'
  }
}

export function readDirection(x: any, context: any = x): Direction {
  switch (x) {
    case 'In':
      return { kind: 'In' }
    case 'Out':
      return { kind: 'Out' }
    default:
      _atd_bad_json('Direction', x, context)
      throw new Error('impossible')
  }
}

export function writeToc(x: Toc, context: any = x): any {
  return {
    'header': _atd_write_required_field('Toc', 'header', writeHeader, x.header, x),
    'clocks': _atd_write_required_field('Toc', 'clocks', writeClocks, x.clocks, x),
    'signals': _atd_write_required_field('Toc', 'signals', writeSignals, x.signals, x),
  };
}

export function readToc(x: any, context: any = x): Toc {
  return {
    header: _atd_read_required_field('Toc', 'header', readHeader, x['header'], x),
    clocks: _atd_read_required_field('Toc', 'clocks', readClocks, x['clocks'], x),
    signals: _atd_read_required_field('Toc', 'signals', readSignals, x['signals'], x),
  };
}

export function writeHeader(x: Header, context: any = x): any {
  return {
    'version': _atd_write_required_field('Header', 'version', ((x: any, context) => [_atd_write_int(x[0], x), _atd_write_int(x[1], x), _atd_write_int(x[2], x)]), x.version, x),
    'source_model': _atd_write_required_field('Header', 'source_model', writeUuid, x.source_model, x),
    'executable': _atd_write_required_field('Header', 'executable', _atd_write_string, x.executable, x),
  };
}

export function readHeader(x: any, context: any = x): Header {
  return {
    version: _atd_read_required_field('Header', 'version', ((x, context): [number /*int*/, number /*int*/, number /*int*/] => { _atd_check_json_tuple(3, x, context); return [_atd_read_int(x[0], x), _atd_read_int(x[1], x), _atd_read_int(x[2], x)] }), x['version'], x),
    source_model: _atd_read_required_field('Header', 'source_model', readUuid, x['source_model'], x),
    executable: _atd_read_required_field('Header', 'executable', _atd_read_string, x['executable'], x),
  };
}

export function writeClock(x: Clock, context: any = x): any {
  return {
    'name': _atd_write_required_field('Clock', 'name', _atd_write_string, x.name, x),
    'clock_spec': _atd_write_required_field('Clock', 'clock_spec', writeClockSpec, x.clock_spec, x),
  };
}

export function readClock(x: any, context: any = x): Clock {
  return {
    name: _atd_read_required_field('Clock', 'name', _atd_read_string, x['name'], x),
    clock_spec: _atd_read_required_field('Clock', 'clock_spec', readClockSpec, x['clock_spec'], x),
  };
}

export function writeClocks(x: Clocks, context: any = x): any {
  return _atd_write_array(writeClock)(x, context);
}

export function readClocks(x: any, context: any = x): Clocks {
  return _atd_read_array(readClock)(x, context);
}

export function writeSignal(x: Signal, context: any = x): any {
  return {
    'signal_id': _atd_write_required_field('Signal', 'signal_id', writeSignalId, x.signal_id, x),
    'signal_spec': _atd_write_required_field('Signal', 'signal_spec', writeSignalSpec, x.signal_spec, x),
  };
}

export function readSignal(x: any, context: any = x): Signal {
  return {
    signal_id: _atd_read_required_field('Signal', 'signal_id', readSignalId, x['signal_id'], x),
    signal_spec: _atd_read_required_field('Signal', 'signal_spec', readSignalSpec, x['signal_spec'], x),
  };
}

export function writeSignals(x: Signals, context: any = x): any {
  return _atd_write_array(writeSignal)(x, context);
}

export function readSignals(x: any, context: any = x): Signals {
  return _atd_read_array(readSignal)(x, context);
}

export function writeSignalId(x: SignalId, context: any = x): any {
  return _atd_write_array(_atd_write_string)(x, context);
}

export function readSignalId(x: any, context: any = x): SignalId {
  return _atd_read_array(_atd_read_string)(x, context);
}

export function writeSignalSpec(x: SignalSpec, context: any = x): any {
  return {
    'signal_type': _atd_write_required_field('SignalSpec', 'signal_type', writeCmlType, x.signal_type, x),
    'clock_name': _atd_write_required_field('SignalSpec', 'clock_name', _atd_write_string, x.clock_name, x),
    'signal_data_file': _atd_write_required_field('SignalSpec', 'signal_data_file', _atd_write_string, x.signal_data_file, x),
  };
}

export function readSignalSpec(x: any, context: any = x): SignalSpec {
  return {
    signal_type: _atd_read_required_field('SignalSpec', 'signal_type', readCmlType, x['signal_type'], x),
    clock_name: _atd_read_required_field('SignalSpec', 'clock_name', _atd_read_string, x['clock_name'], x),
    signal_data_file: _atd_read_required_field('SignalSpec', 'signal_data_file', _atd_read_string, x['signal_data_file'], x),
  };
}

export function writeClockSpec(x: ClockSpec, context: any = x): any {
  switch (x.kind) {
    case 'Continuous':
      return ['Continuous', _atd_write_string(x.value, x)]
  }
}

export function readClockSpec(x: any, context: any = x): ClockSpec {
  _atd_check_json_tuple(2, x, context)
  switch (x[0]) {
    case 'Continuous':
      return { kind: 'Continuous', value: _atd_read_string(x[1], x) }
    default:
      _atd_bad_json('ClockSpec', x, context)
      throw new Error('impossible')
  }
}

export function writeQ(x: Q, context: any = x): any {
  return ((x: any, context) => [_atd_write_string(x[0], x), _atd_write_string(x[1], x)])(x, context);
}

export function readQ(x: any, context: any = x): Q {
  return ((x, context): [string, string] => { _atd_check_json_tuple(2, x, context); return [_atd_read_string(x[0], x), _atd_read_string(x[1], x)] })(x, context);
}


/////////////////////////////////////////////////////////////////////
// Runtime library
/////////////////////////////////////////////////////////////////////

export type Option<T> = null | { value: T }

function _atd_missing_json_field(type_name: string, json_field_name: string) {
    throw new Error(`missing field '${json_field_name}'` +
                    ` in JSON object of type '${type_name}'`)
}

function _atd_missing_ts_field(type_name: string, ts_field_name: string) {
    throw new Error(`missing field '${ts_field_name}'` +
                    ` in TypeScript object of type '${type_name}'`)
}

function _atd_bad_json(expected_type: string, json_value: any, context: any) {
  let value_str = JSON.stringify(json_value)
  if (value_str.length > 200)
    value_str = value_str.substring(0, 200) + '…';

  throw new Error(`incompatible JSON value where` +
                  ` type '${expected_type}' was expected: '${value_str}'.` +
                  ` Occurs in '${JSON.stringify(context)}'.`)
}

function _atd_bad_ts(expected_type: string, ts_value: any, context: any) {
  let value_str = JSON.stringify(ts_value)
  if (value_str.length > 200)
    value_str = value_str.substring(0, 200) + '…';

  throw new Error(`incompatible TypeScript value where` +
                  ` type '${expected_type}' was expected: '${value_str}'.` +
                  ` Occurs in '${JSON.stringify(context)}'.`)
}

function _atd_check_json_tuple(len: number /*int*/, x: any, context: any) {
  if (! Array.isArray(x) || x.length !== len)
    _atd_bad_json('tuple of length ' + len, x, context);
}

function _atd_read_unit(x: any, context: any): null {
  if (x === null)
    return null
  else {
    _atd_bad_json('null', x, context)
    throw new Error('impossible')
  }
}

function _atd_read_bool(x: any, context: any): boolean {
  if (typeof x === 'boolean')
    return x
  else {
    _atd_bad_json('boolean', x, context)
    throw new Error('impossible')
  }
}

function _atd_read_int(x: any, context: any): number /*int*/ {
  if (Number.isInteger(x))
    return x
  else {
    _atd_bad_json('integer', x, context)
    throw new Error('impossible')
  }
}

function _atd_read_float(x: any, context: any): number {
  if (isFinite(x))
    return x
  else {
    _atd_bad_json('number', x, context)
    throw new Error('impossible')
  }
}

function _atd_read_string(x: any, context: any): string {
  if (typeof x === 'string')
    return x
  else {
    _atd_bad_json('string', x, context)
    throw new Error('impossible')
  }
}

function _atd_read_required_field<T>(type_name: string,
                                     field_name: string,
                                     read_elt: (x: any, context: any) => T,
                                     x: any,
                                     context: any): T {
  if (x === undefined) {
    _atd_missing_json_field(type_name, field_name)
    throw new Error('impossible')
  }
  else
    return read_elt(x, context)
}

function _atd_read_optional_field<T>(read_elt: (x: any, context: any) => T,
                                     x: any,
                                     context: any): T {
  if (x === undefined || x === null)
    return x
  else
    return read_elt(x, context)
}

function _atd_read_field_with_default<T>(read_elt: (x: any, context: any) => T,
                                         default_: T,
                                         x: any,
                                         context: any): T {
  if (x === undefined || x === null)
    return default_
  else
    return read_elt(x, context)
}

function _atd_read_option<T>(read_elt: (x: any, context: any) => T):
  (x: any, context: any) => Option<T> {
  function read_option(x: any, context: any): Option<T> {
    if (x === 'None')
      return null
    else {
      _atd_check_json_tuple(2, x, context);
      switch (x[0]) {
        case 'Some':
          return { value: read_elt(x[1], context) }
        default:
          _atd_bad_json('option', x, context)
          throw new Error('impossible')
      }
    }
  }
  return read_option
}

function _atd_read_nullable<T>(read_elt: (x: any, context: any) => T):
  (x: any, context: any) => T | null {
  function read_nullable(x: any, context: any): T | null {
    if (x === null)
      return null
    else
      return read_elt(x, context)
  }
  return read_nullable
}

function _atd_read_array<T>(read_elt: (x: any, context: any) => T):
  (elts: any, context: any) => T[] {
  function read_array(elts: any, context: any): T[] {
    if (Array.isArray(elts))
      return elts.map((x) => read_elt(x, elts))
    else {
      _atd_bad_json('array', elts, context)
      throw new Error('impossible')
    }
  }
  return read_array
}

function _atd_read_assoc_array_into_map<K, V>(
    read_key: (key: any, context: any) => K,
    read_value: (value: any, context: any) => V
  ): (x: any, context: any) => Map<K, V> {
  function read_assoc(elts: any, context: any): Map<K, V> {
    if (Array.isArray(elts)) {
      const res = new Map<K, V>([])
      for (const x of elts) {
        if (Array.isArray(x) && x.length === 2)
          res.set(read_key(x[0], x), read_value(x[1], x))
        else {
          _atd_bad_json('pair', x, elts)
          throw new Error('impossible')
        }
      }
      return res
    }
    else {
      _atd_bad_json('array', elts, context)
      throw new Error('impossible')
    }
  }
  return read_assoc
}

function _atd_read_assoc_object_into_map<T>(
    read_value: (value: any, context: any) => T
  ): (x: any, context: any) => Map<string, T> {
  function read_assoc(elts: any, context: any): Map<string, T> {
    if (typeof elts === 'object') {
      const res = new Map<string, T>([])
      for (const [key, value] of Object.entries(elts))
        res.set(key, read_value(value, elts))
      return res
    }
    else {
      _atd_bad_json('object', elts, context)
      throw new Error('impossible')
    }
  }
  return read_assoc
}

function _atd_read_assoc_object_into_array<T>(
    read_value: (value: any, context: any) => T
  ): (x: any, context: any) => [string, T][] {
  function read_assoc(elts: any, context: any): [string, T][] {
    if (typeof elts === 'object') {
      const res: [string, T][] = []
      for (const [key, value] of Object.entries(elts))
        res.push([key, read_value(value, elts)])
      return res
    }
    else {
      _atd_bad_json('object', elts, context)
      throw new Error('impossible')
    }
  }
  return read_assoc
}

function _atd_write_unit(x: any, context: any) {
  if (x === null)
    return x
  else {
    _atd_bad_ts('null', x, context)
    throw new Error('impossible')
  }
}

function _atd_write_bool(x: any, context: any): boolean {
  if (typeof x === 'boolean')
    return x
  else {
    _atd_bad_ts('boolean', x, context)
    throw new Error('impossible')
  }
}

function _atd_write_int(x: any, context: any): number /*int*/ {
  if (Number.isInteger(x))
    return x
  else {
    _atd_bad_ts('integer', x, context)
    throw new Error('impossible')
  }
}

function _atd_write_float(x: any, context: any): number {
  if (isFinite(x))
    return x
  else {
    _atd_bad_ts('number', x, context)
    throw new Error('impossible')
  }
}

function _atd_write_string(x: any, context: any): string {
  if (typeof x === 'string')
    return x
  else {
    _atd_bad_ts('string', x, context)
    throw new Error('impossible')
  }
}

function _atd_write_option<T>(write_elt: (x: T, context: any) => any):
   (elts: Option<T>, context: any) => any {
  function write_option(x: Option<T>, context: any): any {
    if (x === null)
      return 'None'
    else
      return ['Some', write_elt(x.value, context)]
  }
  return write_option
}

function _atd_write_nullable<T>(write_elt: (x: T, context: any) => any):
  (x: T | null, context: any) => any {
  function write_option(x: T | null, context: any): any {
    if (x === null)
      return null
    else
      return write_elt(x, context)
  }
  return write_option
}

function _atd_write_array<T>(write_elt: (elt: T, context: any) => any):
  (elts: T[], context: any) => any {
  return ((elts: T[], context: any): any =>
    elts.map((x) => write_elt(x, elts))
  )
}

function _atd_write_assoc_map_to_array<K, V>(
    write_key: (key: K, context: any) => any,
    write_value: (value: V, context: any) => any
  ): (elts: Map<K, V>, context: any) => any {
  function write_assoc(elts: Map<K, V>, context: any): any {
    const res: any = []
    elts.forEach((value: V, key: K) =>
      res.push([write_key(key, elts), write_value(value, elts)])
    )
    return res
  }
  return write_assoc
}

function _atd_write_assoc_map_to_object<T>(
    write_value: (value: T, context: any) => any
  ): (elts: Map<string, T>, context: any) => any {
  function write_assoc(elts: Map<string, T>, context: any): any {
    const res: any = {}
    elts.forEach((value: T, key: string) =>
      res[key] = write_value(value, elts)
    )
    return res
  }
  return write_assoc
}

function _atd_write_assoc_array_to_object<T>(
    write_value: (value: T, context: any) => any
  ): (elts: [string, T][], context: any) => any {
  function write_assoc(elts: [string, T][], context: any): any {
    const res: any = {}
    for (const [key, value] of elts)
      res[key] = write_value(value, elts)
    return res
  }
  return write_assoc
}

function _atd_write_required_field<T>(type_name: string,
                                      field_name: string,
                                      write_elt: (x: T, context: any) => any,
                                      x: T,
                                      context: any): any {
  if (x === undefined) {
    _atd_missing_ts_field(type_name, field_name)
    throw new Error('impossible')
  }
  else
    return write_elt(x, context)
}

function _atd_write_optional_field<T>(write_elt: (x: T, context: any) => any,
                                      x: T | undefined,
                                      context: any): any {
  if (x === undefined || x === null)
    return x
  else
    return write_elt(x, context)
}

function _atd_write_field_with_default<T>(
  write_elt: (x: T, context: any) => any,
  default_: T,
  x: T,
  context: any
): T {
  const value = (x === undefined || x === null) ? default_ : x
  return write_elt(value, context)
}
