/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const TrigSinh: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8973 16.25L12.8353 18.4198L12.8303 18.4251L11.48 19.8742C11.4739 19.8808 11.4679 19.8875 11.462 19.8942L10.3664 21.1578C10.3598 21.1653 10.3534 21.1731 10.3472 21.1809L9.37897 22.3925C9.37239 22.4008 9.36598 22.4091 9.35975 22.4177L8.468 23.6356C8.46177 23.6441 8.45572 23.6527 8.44985 23.6615L7.63454 24.8797C7.62886 24.8882 7.62335 24.8968 7.61802 24.9055L6.85366 26.1551C6.8485 26.1635 6.84351 26.1721 6.83869 26.1807L6.46925 26.8421C6.26725 27.2037 6.39665 27.6606 6.75828 27.8626C7.1199 28.0646 7.5768 27.9352 7.7788 27.5736L8.141 26.9251L8.88963 25.7012L9.6876 24.509L10.5607 23.3166L11.5096 22.129L12.5865 20.887L13.9252 19.4504L15.7419 17.5387L15.7356 27.9999L16.2356 28.0002L16.2422 17.0122L16.9665 16.25L32 16.25V15.75L17.4417 15.75L18.9165 14.198C18.9283 14.1856 18.9398 14.1727 18.9507 14.1594L19.9954 12.8965C20.0022 12.8882 20.0088 12.8799 20.0153 12.8713L20.958 11.6268C20.9645 11.6182 20.9708 11.6096 20.9769 11.6007L21.8177 10.3858C21.8236 10.3773 21.8293 10.3686 21.8349 10.3598L22.6247 9.11036C22.6301 9.10189 22.6353 9.09332 22.6403 9.08465L23.3791 7.80508C23.3839 7.79682 23.3885 7.78846 23.393 7.78001L24.1064 6.42784C24.1106 6.41981 24.1147 6.4117 24.1187 6.40351L24.7227 5.15041C24.9025 4.77728 24.7458 4.329 24.3727 4.14915C23.9996 3.9693 23.5513 4.12598 23.3714 4.49911L22.7734 5.73995L22.073 7.0674L21.3488 8.32152L20.5753 9.54505L19.7526 10.7339L18.8293 11.9528L17.8113 13.1835L16.2435 14.8334L16.25 4.00018L15.75 3.99988L15.7432 15.3598L15.3724 15.75L0 15.75V16.25L14.8973 16.25Z"
      fill={props.fill}
    />
  </svg>
);

TrigSinh.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default TrigSinh;
