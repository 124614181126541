import type { Coordinate } from 'app/common_types/Coordinate';
import { MouseActions } from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { HoverEntityType } from 'app/common_types/SegmentTypes';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { modelActions } from 'app/slices/modelSlice';
import { renderConstants } from 'app/utils/renderConstants';
import { convertZoomedScreenToWorldCoordinates } from '../convertScreenToWorldCoordinates';
import { PastedEntityUuids, pasteEntities } from '../copyPaste';
import { getSelectedEntitiesFromRendererState } from '../getSelectedNodesAndLinksFromRendererState';
import { getVisualNodeHeight } from '../getVisualNodeHeight';
import { getVisualNodeWidth } from '../getVisualNodeWidth';
import { RendererState } from '../modelRenderer';
import { getHoveringEntity } from '../mouseInput';
import { clickModifierConfig } from '../shortcutKeyConfig';
import { transitionMouseState } from '../transitionMouseState';
import { drawNewLinkFromPort } from './drawNewLinkFromPort';
import { reifyAndGetSegmentData } from './reifyLinkAndGetNewSegmentData';

const dragDuplicateSelected = (
  rs: RendererState,
  overrideNodeIds?: string[],
): PastedEntityUuids => {
  const { selectedNodes, selectedLinks, selectedAnnotations } =
    getSelectedEntitiesFromRendererState(rs, overrideNodeIds);

  const pastedUuids = pasteEntities(
    0,
    0,
    true,
    selectedNodes,
    selectedLinks,
    selectedAnnotations,
    undefined,
    rs.refs.current.nodes,
    rs.dispatch,
  );

  rs.dispatch(
    modelActions.setSelections({
      selectionParentPath: getCurrentModelRef().submodelPath,
      selectedBlockIds: pastedUuids.nodeUuids,
      selectedLinkIds: pastedUuids.linkUuids,
      selectedAnnotationIds: pastedUuids.annotationUuids,
    }),
  );

  return pastedUuids;
};

export const mouseInputClickHold = (
  rs: RendererState,
  zoomedClickCoord: Coordinate,
  rawScreenCoord: Coordinate,
  keys: { [k: string]: boolean },
): void => {
  const worldCursor = convertZoomedScreenToWorldCoordinates(
    rs.camera,
    zoomedClickCoord,
  );
  const hoveringEntity = getHoveringEntity(
    rs.mouseState,
    worldCursor,
    rs.camera,
    rs.refs.current.nodes,
    rs.refs.current.links,
    rs.refs.current.annotations,
    rs.refs.current.linksIndexLUT,
    rs.refs.current.visualizerPrefs,
    rs.linksRenderFrameData,
    getCurrentModelRef().submodelPath,
  );

  const drawingLink =
    rs.mouseState.state === MouseActions.DrawingLinkFromStart ||
    rs.mouseState.state === MouseActions.DrawingLinkFromEnd;

  if (!hoveringEntity && !drawingLink) {
    // Hovering nothing, mouse is now held down on the background.
    if (rs.mouseState.state === MouseActions.ReadyToDefineAnnotation) {
      transitionMouseState(rs, {
        state: MouseActions.DefiningAnnotationBox,
        rawScreenCursorStartX: rawScreenCoord.x,
        rawScreenCursorStartY: rawScreenCoord.y,
      });
    } else {
      transitionMouseState(rs, {
        state: MouseActions.MakingSelection,
        rawScreenCursorStartX: rawScreenCoord.x,
        rawScreenCursorStartY: rawScreenCoord.y,
      });
    }
  } else if (hoveringEntity && !drawingLink && rs.refs.current.canEditModel) {
    let linkAlreadySelected = false;
    let selectionOverride;

    switch (hoveringEntity.entityType) {
      case HoverEntityType.Port:
        if (drawingLink) {
          break;
        }

        const portConnections =
          rs.refs.current.connectedPortLUT[hoveringEntity.port.blockUuid];
        const hoveringPortConnection = portConnections
          ? portConnections.find(
              (p) =>
                p.side === hoveringEntity.port.side &&
                p.portId === hoveringEntity.port.portId,
            )
          : undefined;

        if (hoveringPortConnection) {
          rs.dispatch(
            modelActions.disconnectLinkFromSourceOrDest({
              side: hoveringPortConnection.side,
              linkUuid: hoveringPortConnection.linkUuid,
            }),
          );
          transitionMouseState(rs, {
            state:
              hoveringPortConnection.side === PortSide.Output
                ? MouseActions.DrawingLinkFromStart
                : MouseActions.DrawingLinkFromEnd,
            linkUuid: hoveringPortConnection.linkUuid,
            draggingMode: true,
          });
        } else if (rs.mouseState.state === MouseActions.Idle) {
          drawNewLinkFromPort(rs, hoveringEntity.port, true);
        }

        break;
      case HoverEntityType.HangingStartPoint:
      case HoverEntityType.HangingEndPoint:
        if (drawingLink) {
          break;
        }

        transitionMouseState(rs, {
          state:
            hoveringEntity.entityType === HoverEntityType.HangingStartPoint
              ? MouseActions.DrawingLinkFromStart
              : MouseActions.DrawingLinkFromEnd,
          linkUuid: hoveringEntity.linkUuid,
          draggingMode: true,
        });
        break;
      case HoverEntityType.Link:
        if (drawingLink) {
          break;
        }

        linkAlreadySelected = rs.refs.current.selectedLinkIds.includes(
          hoveringEntity.linkUuid,
        );

        if (
          linkAlreadySelected &&
          (rs.refs.current.selectedNodeIds.length > 0 ||
            rs.refs.current.selectedAnnotationIds.length > 0 ||
            rs.refs.current.selectedLinkIds.length > 1)
        ) {
          if (keys[clickModifierConfig.dragCopy]) {
            selectionOverride = dragDuplicateSelected(rs);
          }

          transitionMouseState(rs, {
            state: MouseActions.DraggingSelected,
            previousCursorX: zoomedClickCoord.x,
            previousCursorY: zoomedClickCoord.y,
            selectionOverride,
          });
        } else {
          rs.dispatch(
            modelActions.setSelections({
              selectionParentPath: getCurrentModelRef().submodelPath,
              selectedBlockIds: [],
              selectedLinkIds: [hoveringEntity.linkUuid],
              selectedAnnotationIds: [],
            }),
          );

          transitionMouseState(rs, {
            state: MouseActions.DraggingLinkSegment,
            linkUuid: hoveringEntity.linkUuid,
            segmentId: hoveringEntity.segmentId,
          });
        }

        break;

      case HoverEntityType.FakeLinkSegment:
        if (drawingLink) {
          break;
        }

        linkAlreadySelected = rs.refs.current.selectedLinkIds.includes(
          hoveringEntity.linkUuid,
        );

        if (
          linkAlreadySelected &&
          (rs.refs.current.selectedNodeIds.length > 0 ||
            rs.refs.current.selectedAnnotationIds.length > 0 ||
            rs.refs.current.selectedLinkIds.length > 1)
        ) {
          if (keys[clickModifierConfig.dragCopy]) {
            selectionOverride = dragDuplicateSelected(rs);
          }

          transitionMouseState(rs, {
            state: MouseActions.DraggingSelected,
            previousCursorX: zoomedClickCoord.x,
            previousCursorY: zoomedClickCoord.y,
            selectionOverride,
          });

          break;
        }

        const hoveringLinkIndex =
          rs.refs.current.linksIndexLUT[hoveringEntity.linkUuid];
        const hoveringLink = rs.refs.current.links[hoveringLinkIndex];
        const renderDataIndex =
          rs.linksRenderFrameDataIndexLUT[hoveringEntity.linkUuid];
        const renderData = rs.linksRenderFrameData[renderDataIndex];

        if (!hoveringLink || !renderData) {
          break;
        }

        const reifiedLinkData = reifyAndGetSegmentData(
          hoveringEntity,
          renderData,
        );

        if (reifiedLinkData) {
          rs.dispatch(
            modelActions.addSegmentsToLink({
              prepend: reifiedLinkData.shouldPrepend,
              linkUuid: hoveringEntity.linkUuid,
              segmentsData: reifiedLinkData.newSegments,
            }),
          );

          transitionMouseState(rs, {
            state: MouseActions.DraggingLinkSegment,
            linkUuid: hoveringEntity.linkUuid,
            segmentId: reifiedLinkData.newInteractedIndex,
          });
        }

        /*
          rs.dispatch(
            modelActions.moveFakeSegmentTapsToRealSegment({
              tappedLinkUuid: hoveringEntity.linkUuid,
              fakeSegmentType: hoveringEntity.fakeSegmentType,
              realSegmentIndex: hoveringLink.uiprops.segments.length,
            }),
          );
        */

        break;

      case HoverEntityType.TapPoint:
        if (drawingLink) {
          break;
        }

        rs.dispatch(
          modelActions.disconnectLinkFromSourceOrDest({
            side: PortSide.Output,
            linkUuid: hoveringEntity.linkUuid,
          }),
        );
        transitionMouseState(rs, {
          state: MouseActions.DrawingLinkFromStart,
          linkUuid: hoveringEntity.linkUuid,
          draggingMode: true,
        });

        break;

      case HoverEntityType.NodeResizeEdge:
        const resizingNodeIndex =
          rs.refs.current.nodesIndexLUT[hoveringEntity.nodeUuid];
        const resizingNode = rs.refs.current.nodes[resizingNodeIndex];

        transitionMouseState(rs, {
          state: MouseActions.ResizeNodeManually,
          startingGridHeight:
            getVisualNodeHeight(resizingNode) / renderConstants.GRID_SIZE,
          startingGridWidth:
            getVisualNodeWidth(resizingNode) / renderConstants.GRID_SIZE,
          startingGridX: Math.floor(
            resizingNode.uiprops.x / renderConstants.GRID_SIZE,
          ),
          startingGridY: Math.floor(
            resizingNode.uiprops.y / renderConstants.GRID_SIZE,
          ),
          nodeUuid: hoveringEntity.nodeUuid,
          handleSides: hoveringEntity.handleSides,
        });
        break;
      case HoverEntityType.Node:
        const hoveringBlockUuid = hoveringEntity.block.uuid;
        const blockAlreadySelected =
          !rs.refs.current.selectedNodeIds.includes(hoveringBlockUuid);

        if (blockAlreadySelected) {
          rs.dispatch(
            modelActions.setSelections({
              selectionParentPath: getCurrentModelRef().submodelPath,
              selectedBlockIds: [hoveringEntity.block.uuid],
              selectedLinkIds: [],
              selectedAnnotationIds: [],
            }),
          );

          selectionOverride = {
            nodeUuids: [hoveringEntity.block.uuid],
          };
        }

        if (keys[clickModifierConfig.dragCopy]) {
          selectionOverride = dragDuplicateSelected(
            rs,
            blockAlreadySelected ? [hoveringEntity.block.uuid] : undefined,
          );
        } else if (keys[clickModifierConfig.insertRemoveNode]) {
          // NOTE: TODO: temporary! want to ship improved link-tap placement consistency
          // disabling shift-removing blocks which is buggy after the new (eoy 2022) link-taps fix
          /*
          rs.dispatch(
            modelActions.disconnectNodeFromAllLinks({
              nodeUuid: hoveringEntity.block.uuid,
              connectSingleIO: true,
            }),
          );
          */
        }

        transitionMouseState(rs, {
          state: MouseActions.DraggingSelected,
          previousCursorX: zoomedClickCoord.x,
          previousCursorY: zoomedClickCoord.y,
          selectionOverride,
        });

        break;

      case HoverEntityType.Annotation:
        const hoveringAnnoUuid = hoveringEntity.uuid;
        const annoAlreadySelected =
          rs.refs.current.selectedAnnotationIds.includes(hoveringAnnoUuid);

        if (!annoAlreadySelected) {
          rs.dispatch(
            modelActions.setSelections({
              selectionParentPath: getCurrentModelRef().submodelPath,
              selectedBlockIds: [],
              selectedLinkIds: [],
              selectedAnnotationIds: [hoveringAnnoUuid],
            }),
          );

          selectionOverride = {
            annotationUuids: [hoveringAnnoUuid],
          };
        } else if (keys[clickModifierConfig.dragCopy]) {
          selectionOverride = dragDuplicateSelected(rs);
        }

        transitionMouseState(rs, {
          state: MouseActions.DraggingSelected,
          previousCursorX: zoomedClickCoord.x,
          previousCursorY: zoomedClickCoord.y,
          selectionOverride,
        });

        break;
      case HoverEntityType.AnnotationResizeEdge: {
        const resizingAnnoIndex =
          rs.refs.current.annotationsIndexLUT[hoveringEntity.uuid];
        const resizingAnno = rs.refs.current.annotations[resizingAnnoIndex];

        transitionMouseState(rs, {
          state: MouseActions.ResizeAnnotationManually,
          startingGridHeight: resizingAnno.grid_height,
          startingGridWidth: resizingAnno.grid_width,
          startingGridX: Math.floor(resizingAnno.x / renderConstants.GRID_SIZE),
          startingGridY: Math.floor(resizingAnno.y / renderConstants.GRID_SIZE),
          uuid: hoveringEntity.uuid,
          handleSides: hoveringEntity.handleSides,
        });
        break;
      }
    }
  }
};
