import styled from '@emotion/styled';
import React from 'react';
import { ChevronRight } from 'ui/common/Icons/Standard';
import Link from './Link';

const NavSectionWrapper = styled(Link)<{ isNestedProjectOpen: boolean }>`
  ${({ theme: { spacing, typography, colors }, isNestedProjectOpen }) => `
    &:first-of-type{
      margin-top: ${spacing.large};
    }
    &:not(:first-of-type) {
      margin-top: ${spacing.xxlarge};
    }

    text-transform: uppercase;

    font-weight: ${typography.font.heading4.weight};
    line-height: ${typography.font.heading4.lineHeight};
    padding: 0 ${spacing.normal} 0 ${spacing.normal};
    &.root {
      padding-left: calc(${spacing.normal});
    }
    &:not(.active.root) {
      .chevron-right, .chevron-right path {
          fill: ${colors.text.tertiary};
      }
      .nav-left-icon path {
        fill: ${colors.text.secondary};
      }

      &:hover {
        background-color: ${colors.grey[10]};

        .chevron-right path {
          fill: ${colors.text.secondary};
        }
        .nav-left-icon path {
          fill: ${colors.text.primary};
        }
      }
    }

    &.active:not(.root) {
      background-color: transparent;
    }

    ${isNestedProjectOpen ? `border: solid 2px ${colors.ui.tint1};` : ''}
  `}
`;

const Name = styled.div`
  padding-left: ${({ theme }) => theme.spacing.normal};
  color: ${({ theme }) => theme.colors.text.secondary};
  flex-grow: 1;
  letter-spacing: 0.07em;
`;

type Props = {
  icon: React.FC<any>;
  children: React.ReactChild;
  to: string;
  testId: string;
  isNestedProjectOpen: boolean;
};

const NavSection: React.FC<Props> = ({
  to,
  icon: Icon,
  children,
  testId,
  isNestedProjectOpen,
}) => (
  <NavSectionWrapper to={to} isNestedProjectOpen={isNestedProjectOpen}>
    {Icon && <Icon className="nav-left-icon" />}
    <Name data-test-id={testId}>{children}</Name>
    <ChevronRight className="chevron-right" />
  </NavSectionWrapper>
);

export default NavSection;
