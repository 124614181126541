/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  className?: string;
}

const Label: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.58579 3.5H4.5C3.94772 3.5 3.5 3.94772 3.5 4.5V7.58579C3.5 7.851 3.60536 8.10536 3.79289 8.29289L7.79289 12.2929C8.18342 12.6834 8.81658 12.6834 9.20711 12.2929L12.2929 9.20711C12.6834 8.81658 12.6834 8.18342 12.2929 7.79289L8.29289 3.79289C8.10536 3.60536 7.851 3.5 7.58579 3.5Z"
      stroke={props.stroke}
    />
    <path
      d="M7 6C7 6.55228 6.55228 7 6 7C5.44772 7 5 6.55228 5 6C5 5.44772 5.44772 5 6 5C6.55228 5 7 5.44772 7 6Z"
      stroke={props.stroke}
    />
  </svg>
);

Label.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#020606',
  className: '',
};

export default Label;
