import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useJupyter } from 'app/api/useJupyter';
import { useJupyterProject } from 'app/api/useJupyterProject';
import { useProjectActions } from 'app/api/useProjectActions';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { getNextValidIdentifier } from 'app/transformers/uniqueNameGenerators';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Bus, File } from 'ui/common/Icons/Standard';
import { ItemType, MenuItemConfig } from 'ui/common/Menu/menuItemTypes';
import { FileType, NOTEBOOK_FILES } from 'ui/notebook/notebookTypes';
import ListItem from './ListItem';
import ListTitle from './ListTitle';

interface Props {
  project: Project;
  triggerClose: () => void;
}

enum OtherFileType {
  NOTEBOOK = 'NOTEBOOK',
  PROJECT = 'PROJECT',
  BUS_TYPE = 'BUS_TYPE',
}

interface OtherFile {
  name: string;
  type: OtherFileType;
  uuid?: string;
}

const UploadFileInput = styled.input`
  visibility: hidden;
  position: absolute;
  z-index: -100;
  width: 10px;
`;

const Other: React.FC<Props> = ({ project, triggerClose }) => {
  const uploadDataRef = React.useRef<HTMLInputElement>(null);
  const uploadJupyterRef = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { files } = useJupyterProject(project.uuid);
  const {
    createFile: createHubFile,
    uploadFile: uploadHubFile,
    saveFile,
  } = useJupyter();
  const hubFiles = files?.data?.jupyter_files || [];
  const { createFile, downloadFile } = useProjectActions();

  let otherFiles: OtherFile[] = project.files.map((f) => ({
    name: f.name,
    type: OtherFileType.PROJECT,
    uuid: f.uuid,
  }));
  let busTypes: OtherFile[] =
    project.busTypes?.map((f) => ({
      name: f.name,
      type: OtherFileType.BUS_TYPE,
      uuid: f.id,
    })) || [];
  const nonDiagramFiles = [...otherFiles, ...busTypes];

  for (const file of hubFiles) {
    if (file.type === 'other') {
      otherFiles.push({ name: file.name, type: OtherFileType.NOTEBOOK });
    }
  }

  const uploadDataFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const projectId = project?.uuid;
    const file = e.target?.files?.[0];
    if (!projectId || !file) {
      return null;
    }

    createFile(
      {
        projectUuid: projectId,
        fileCreateRequest: {
          name: file.name,
          content_type: file.type,
          size: file.size,
        },
      },
      file,
    );
  };

  const uploadJupyterFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    uploadHubFile(project.uuid, e.target.files?.[0]);
  };

  let otherFilesMenu: MenuItemConfig[] = Object.entries(NOTEBOOK_FILES)
    .filter(([_, data]) => data.hubType === 'file')
    .map(([type, data]) => ({
      type: ItemType.Button,
      content: data.name,
      onClick: async () => {
        const fileData = NOTEBOOK_FILES[type as FileType];
        await createHubFile(
          project.uuid,
          fileData.extension,
          getNextValidIdentifier(
            `${fileData.emptyFileName} 0`,
            hubFiles.map((f) => f.name.substring(0, f.name.lastIndexOf('.'))),
            0,
            ' ',
          ),
        );

        navigate(
          `/projects/${project.uuid}/notebook/${fileData.emptyFileName} 0.${fileData.extension}`,
        );
      },
    }));
  otherFilesMenu.push({ type: ItemType.Separator });
  otherFilesMenu.push({
    type: ItemType.Button,
    content: t({
      id: 'browser.uploadDataFile',
      message: 'Upload a data file...',
    }),
    onClick: () => uploadDataRef?.current?.click?.(),
  });
  otherFilesMenu.push({
    type: ItemType.Button,
    content: t({
      id: 'browser.uploadJupyterFile',
      message: 'Upload a Jupyter file...',
    }),
    onClick: () => uploadJupyterRef?.current?.click?.(),
  });

  const fileAction = (file: OtherFile) => {
    if (file.type === OtherFileType.NOTEBOOK) {
      saveFile();
      navigate(`/projects/${project.uuid}/notebook/${file.name}`);
    } else if (file.type === OtherFileType.BUS_TYPE) {
      navigate(`/projects/${project.uuid}/bus_types/${file.uuid}`);
    } else if (file.uuid) {
      const isPythonFile = file.name.split('.').pop() === 'py';
      if (isPythonFile) {
        navigate(`/projects/${project.uuid}/python_script/${file.uuid}`);
      } else {
        downloadFile(file.name, {
          fileUuid: file.uuid,
          projectUuid: project.uuid,
        });
      }
    }
  };

  return (
    <>
      <ListTitle>Other</ListTitle>
      {nonDiagramFiles.map((f: OtherFile) => (
        <ListItem
          key={f.name}
          Icon={f.type === OtherFileType.BUS_TYPE ? Bus : File}
          isActive={
            location.pathname ===
            `/projects/${project.uuid}/notebook/${encodeURIComponent(f.name)}`
          }
          onClick={() => {
            fileAction(f);
            triggerClose();
          }}>
          {f.name}
        </ListItem>
      ))}
      <UploadFileInput
        type="file"
        style={{ visibility: 'hidden' }}
        ref={uploadDataRef}
        onChange={(e) => {
          uploadDataFile(e);
          e.target.value = '';
        }}
      />
      <UploadFileInput
        type="file"
        style={{ visibility: 'hidden' }}
        ref={uploadJupyterRef}
        onChange={(e) => {
          uploadJupyterFile(e);
          e.target.value = '';
        }}
      />
    </>
  );
};

export default Other;
