import { BlockParameterDefinition } from 'app/generated_types/ComputationBlockClass';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { DetailsLabel, DetailsSection } from 'ui/modelEditor/DetailsComponents';
import BlockParameterValue from './BlockParameterValue';
import {
  BooleanDisplayType,
  getBooleanDisplayType,
  getParameterDisplayName,
  isDataFileDropdown,
  isParameterHidden,
  isParameterReadOnly,
} from './blockParameterDisplay';

interface Props {
  parentPath: string[];
  selectedNode: NodeInstance;
  paramDef: BlockParameterDefinition;
  disabled: boolean;
}

const BlockParameter: React.FC<Props> = ({
  parentPath,
  selectedNode,
  paramDef,
  disabled,
}: Props) => {
  const userOptions = useAppSelector((state) => state.userOptions.options);

  if (!selectedNode || !paramDef) return null;
  if (isParameterHidden(selectedNode, paramDef, userOptions)) return null;

  const parameterDisplayName = getParameterDisplayName(
    selectedNode.type,
    paramDef,
  );
  const isReadOnly = disabled || isParameterReadOnly(selectedNode, paramDef);

  const booleanDisplayType = getBooleanDisplayType(paramDef);
  const isMultiRowSection =
    isDataFileDropdown(selectedNode, paramDef) ||
    paramDef.display_full_width ||
    booleanDisplayType === BooleanDisplayType.None ||
    booleanDisplayType === BooleanDisplayType.List;

  const stretchLabel =
    isMultiRowSection ||
    getBooleanDisplayType(paramDef) === BooleanDisplayType.Checkbox;

  return (
    <DetailsSection title={paramDef.description} vertical={isMultiRowSection}>
      <DetailsLabel stretch={stretchLabel} disabled={isReadOnly}>
        {parameterDisplayName}
      </DetailsLabel>
      <BlockParameterValue
        parentPath={parentPath}
        selectedNode={selectedNode}
        paramDef={paramDef}
        isReadOnly={isReadOnly}
      />
    </DetailsSection>
  );
};

export default BlockParameter;
