// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const Predictor: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Predictor",
      "namespace": "core",
      "description": "Prediction with machine learning model."
    },
    "modes": {
      "time": "discrete",
      "feedthrough": true
    },
    "ports": {
      "inputs": {
        "dynamic": {
          "min_count": 1,
          "default_count": 1,
          "max_count": -1,
          "parameter_definitions": [
            {
              "name": "dtype",
              "data_type": "string",
              "default_value": "float32",
              "description": "Input dtype. Must be a dtype supported by the selected model_format. Example values: 'float32', 'int32', 'uint8'."
            }
          ]
        }
      },
      "outputs": {
        "dynamic": {
          "min_count": 1,
          "default_count": 1,
          "max_count": -1,
          "parameter_definitions": [
            {
              "name": "dtype",
              "data_type": "string",
              "default_value": "double",
              "allowed_values": [
                "double",
                "bool",
                "object"
              ],
              "description": "Output dtype. Must be a dtype supported by collimator."
            },
            {
              "name": "shape",
              "data_type": "any",
              "default_value": "()",
              "description": "Shape or dimension of the output, follows numpy syntax like: (2,3) or ()"
            }
          ]
        }
      }
    },
    "parameter_definitions": [
      {
        "name": "file_name",
        "data_type": "string",
        "default_value": "",
        "description": "Name of the ML model to load."
      },
      {
        "name": "model_format",
        "data_type": "string",
        "default_value": "TorchScript",
        "allowed_values": [
          "TorchScript",
          "SavedModel"
        ],
        "description": "Format of the model."
      }
    ]
  }
);
