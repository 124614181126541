import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { ProjectPermissionRole } from 'app/apiGenerated/generatedApiTypes';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Account, Remove } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import { emailRules } from 'ui/common/Input/inputValidation';
import SelectInput from 'ui/common/SelectInput';
import PortalTextTooltip from 'ui/common/Tooltip/PortalTextTooltip';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { usePortalTooltip } from 'ui/common/Tooltip/usePortalTooltip';
import { ExtraSmall, Small } from 'ui/common/typography/Typography';
import {
  AuthorAvatar,
  AuthorAvatarContainer,
} from 'ui/versionHistory/DiagramVersionItemParts';

const MemberItemWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.large};
  padding-left: ${({ theme }) => `${theme.spacing.normal}`};
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const MemberTooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MemberSpacer = styled.div`
  flex-grow: 1;
`;

const DisplayName = styled(Small)`
  margin-left: ${({ theme }) => `${theme.spacing.normal}`};
  margin-top: ${({ theme }) => theme.spacing.small};
  flex: 1 1 50px;
  overflow-wrap: break-word;
  word-break: break-all;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MemberName = styled(DisplayName)`
  color: ${({ theme }) => theme.colors.text.primary};
`;

const InvitedEmail = styled(DisplayName)`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const EmailInputWrapper = styled.div`
  width: 100px;
  flex-grow: 1;
`;

const RoleDisplayName = styled(ExtraSmall)`
  color: ${({ theme }) => theme.colors.text.tertiary};
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  margin-left: ${({ theme }) => `${theme.spacing.normal}`};
`;

const RoleSelectInputWrapper = styled.div`
  margin-left: ${({ theme }) => `${theme.spacing.small}`};

  flex: 0 0 92px;
`;

const RemoveButtonContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.small};
  width: 24px;
`;

const InvitedMemberContainer = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.brand.tertiary.base};
`;

const roleOptions: Array<{ label: string; value: ProjectPermissionRole }> = [
  {
    label: t({
      id: 'manageMembers.roleOptions.owner',
      message: 'Admin',
    }),
    value: 'admin',
  },
  {
    label: t({
      id: 'manageMembers.roleOptions.editor',
      message: 'Editor',
    }),
    value: 'editor',
  },
  {
    label: t({
      id: 'manageMembers.roleOptions.read_only',
      message: 'Read only',
    }),
    value: 'read_only',
  },
];

interface Props {
  memberProfileImageUrl?: string;
  memberRole: ProjectPermissionRole;
  memberName?: string;
  email?: string;
  canChangeEmail?: boolean;
  canChangeRole?: boolean;
  canRemove?: boolean;
  onChangeRole?: (role: ProjectPermissionRole) => void;
  onChangeEmail?: (email: string, isValid: boolean) => void;
  onRemove?: () => void;
}

const MemberItem: React.FC<Props> = ({
  memberProfileImageUrl,
  memberRole,
  memberName,
  email,
  canChangeEmail,
  canChangeRole,
  canRemove,
  onChangeRole,
  onChangeEmail,
  onRemove,
}: Props) => {
  const theme = useTheme();

  const { triggerElRef, isTooltipOpen } = usePortalTooltip<HTMLDivElement>();

  const changeRole = (newRoleString: string) => {
    const newRole = newRoleString as ProjectPermissionRole;
    if (onChangeRole) onChangeRole(newRole);
  };

  return (
    <MemberItemWrapper>
      <MemberTooltipWrapper ref={triggerElRef}>
        {memberProfileImageUrl ? (
          <>
            <AuthorAvatarContainer>
              <AuthorAvatar src={memberProfileImageUrl} />
            </AuthorAvatarContainer>
            <MemberName>{memberName}</MemberName>
          </>
        ) : canChangeEmail && onChangeEmail ? (
          <EmailInputWrapper>
            <Input
              value={email || ''}
              onChangeText={onChangeEmail}
              hasBorder
              testId="new-member-email-textbox"
              autoFocus
              validationRules={emailRules}
            />
          </EmailInputWrapper>
        ) : (
          <>
            <InvitedMemberContainer>
              <Account fill={theme.colors.grey[2]} />
            </InvitedMemberContainer>
            <InvitedEmail>{email}</InvitedEmail>
          </>
        )}
      </MemberTooltipWrapper>
      <MemberSpacer />

      {!canChangeRole && memberRole === 'admin' && (
        <RoleDisplayName>
          {t({
            id: 'dashboard.rightSidebar.members.adminRole.label',
            message: 'Admin',
          })}
        </RoleDisplayName>
      )}

      {canChangeRole && (
        <RoleSelectInputWrapper>
          <SelectInput
            currentValue={memberRole}
            options={roleOptions}
            onSelectValue={changeRole}
          />
        </RoleSelectInputWrapper>
      )}

      {onRemove && (
        <RemoveButtonContainer>
          {canRemove && (
            <Button
              type="button"
              variant={ButtonVariants.LargeTertiary}
              Icon={Remove}
              onClick={onRemove}
            />
          )}
        </RemoveButtonContainer>
      )}
      {isTooltipOpen && triggerElRef.current && email && (
        <PortalTextTooltip
          triggerEl={triggerElRef.current}
          contentText={email}
          placement={TooltipPlacement.BOTTOM_CENTER}
        />
      )}
    </MemberItemWrapper>
  );
};

export default MemberItem;
