import { PortSide } from 'app/common_types/PortTypes';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { nodeTypeIsLocalSubdiagram } from 'app/helpers';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { modelActions } from 'app/slices/modelSlice';
import { NOWHERE } from 'app/utils/GeneralConstants';
import { LinkVertex } from 'app/utils/linkToRenderData';
import { snapNumberToGrid } from 'app/utils/modelDataUtils';
import { getVisualNodeWidth } from 'ui/modelRendererInternals/getVisualNodeWidth';
import { pythagoreanDistance } from 'util/pythagoreanDistance';
import {
  getUnconnectedPortsWithCoordinatesForNodes,
  PortIdentifierWithCoord,
} from './getUnconnectedPortsWithCoordinatesForNodes';
import { RendererState } from './modelRenderer';

const attemptNodeInsertionBetweenVertices = (
  rs: RendererState,
  vertexOne: LinkVertex,
  vertexTwo: LinkVertex,
  nodeX: number,
  nodeY: number,
  nodeWidth: number,
  nodeFlipped: boolean,
  nodeUuid: string,
  linkUuid: string,
): boolean => {
  const [leftVertX, leftVertY] = nodeFlipped
    ? vertexTwo.coordinate
    : vertexOne.coordinate;
  const [rightVertX, rightVertY] = nodeFlipped
    ? vertexOne.coordinate
    : vertexTwo.coordinate;

  if (
    leftVertY === rightVertY &&
    leftVertY === nodeY + 32 &&
    leftVertX < nodeX &&
    rightVertX > nodeX + nodeWidth
  ) {
    rs.dispatch(
      modelActions.insertNodeIntoLink({
        linkUuid,
        nodeUuid,
        vertexData: vertexOne,
        nextVertexData: vertexTwo,
      }),
    );

    return true;
  }

  return false;
};

export const autoInsertNodesIntoLinks = (
  rs: RendererState,
  overrideAutoConnectBlocks?: NodeInstance[],
): void => {
  // 1-input-1-output (unoccupied) block insertion into an existing link
  if (
    (rs.refs.current.selectedNodeIds.length > 0 ||
      (overrideAutoConnectBlocks && overrideAutoConnectBlocks.length > 0)) &&
    rs.refs.current.selectedLinkIds.length === 0
  ) {
    const nodeUuid = overrideAutoConnectBlocks
      ? overrideAutoConnectBlocks[0].uuid
      : rs.refs.current.selectedNodeIds[0];
    const selectedNode = overrideAutoConnectBlocks
      ? overrideAutoConnectBlocks[0]
      : rs.refs.current.nodes[rs.refs.current.nodesIndexLUT[nodeUuid]];
    const connectedPorts = overrideAutoConnectBlocks
      ? undefined
      : rs.refs.current.connectedPortLUT[nodeUuid];
    const nodeX = selectedNode.uiprops.x;
    const nodeY = snapNumberToGrid(selectedNode.uiprops.y);

    if (
      selectedNode &&
      !nodeTypeIsLocalSubdiagram(selectedNode.type) &&
      selectedNode.inputs.length === 1 &&
      selectedNode.outputs.length === 1 &&
      (!connectedPorts || connectedPorts.length === 0)
    ) {
      for (let i = 0; i < rs.linksRenderFrameData.length; i++) {
        const linkRenderData = rs.linksRenderFrameData[i];
        const actualLink =
          rs.refs.current.links[
            rs.refs.current.linksIndexLUT[linkRenderData.linkUuid]
          ];
        const linkIsTap =
          actualLink.uiprops.link_type.connection_method === 'link_tap';
        const nodeFlipped = selectedNode.uiprops.directionality === 'left';

        if (linkIsTap && linkRenderData.vertexData.length === 3) {
          const vertexOne = linkRenderData.vertexData[1];
          const vertexTwo = linkRenderData.vertexData[2];

          const nodeWidth = getVisualNodeWidth(selectedNode);

          const inserted = attemptNodeInsertionBetweenVertices(
            rs,
            vertexOne,
            vertexTwo,
            nodeX,
            nodeY,
            nodeWidth,
            nodeFlipped,
            nodeUuid,
            linkRenderData.linkUuid,
          );

          if (inserted) return;
        }

        if (linkRenderData.vertexData.length < 4) continue;

        const alignedDirect =
          actualLink.uiprops.segments.length === 0 &&
          linkRenderData.vertexData[0]?.coordinate[1] ===
            linkRenderData.vertexData[3]?.coordinate[1];

        for (let j = 0; j < linkRenderData.vertexData.length - 1; j++) {
          if (alignedDirect && j > 0) break;

          const startVertex = alignedDirect
            ? linkRenderData.vertexData[0]
            : linkRenderData.vertexData[j];
          const endVertex = alignedDirect
            ? linkRenderData.vertexData[3]
            : linkRenderData.vertexData[j + 1];

          const nodeWidth = getVisualNodeWidth(selectedNode);

          const inserted = attemptNodeInsertionBetweenVertices(
            rs,
            startVertex,
            endVertex,
            nodeX,
            nodeY,
            nodeWidth,
            nodeFlipped,
            nodeUuid,
            linkRenderData.linkUuid,
          );

          if (inserted) return;
        }
      }
    }
  }

  const autoConnectBlocks: NodeInstance[] =
    overrideAutoConnectBlocks ||
    rs.refs.current.selectedNodeIds.reduce<NodeInstance[]>((acc, nodeUuid) => {
      const node =
        rs.refs.current.nodes[rs.refs.current.nodesIndexLUT[nodeUuid]];

      if (!node) return acc;

      if (node && !nodeTypeIsLocalSubdiagram(node.type)) {
        if (!node) return acc;
        return [...acc, node];
      }

      return acc;
    }, []);

  // here we want to assemble a list of unoccupied ports
  // for the block(s) that we just finished dragging.
  // if any of these ports are close enough to an unconnected link's start/end,
  // we'll automatically connect it
  const connectedPortsLUT = rs.refs.current.connectedPortLUT;
  const portsToAttemptConnection: PortIdentifierWithCoord[] =
    getUnconnectedPortsWithCoordinatesForNodes(
      autoConnectBlocks,
      connectedPortsLUT,
    );
  const connectedIndexLUT: { [k: number]: boolean } = {};

  const links = rs.refs.current.links;
  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    const { segments } = link.uiprops;

    for (let j = 0; j < portsToAttemptConnection.length; j++) {
      if (connectedIndexLUT[j]) continue;

      const attemptingPort = portsToAttemptConnection[j];

      if (
        attemptingPort.port.side === PortSide.Output &&
        !link.src &&
        link.uiprops.link_type.connection_method !== 'link_tap' &&
        link.uiprops.hang_coord_start
      ) {
        const distance = pythagoreanDistance(
          link.uiprops.hang_coord_start,
          attemptingPort.coord || NOWHERE,
        );

        if (distance < 10) {
          connectedIndexLUT[j] = true;

          // ensure that the first segment (before connection)
          // is a vertical segment
          if (
            segments.length > 0 &&
            segments[0].segment_direction === 'horiz'
          ) {
            rs.dispatch(
              modelActions.addSegmentsToLink({
                linkUuid: link.uuid,
                segmentsData: [
                  {
                    segment_direction: 'vert',
                    coordinate: snapNumberToGrid(attemptingPort.coord?.x || 0),
                  },
                ],
                prepend: false,
              }),
            );
          }

          rs.dispatch(
            modelActions.connectLinkToNode({
              parentPath: getCurrentModelRef().submodelPath,
              linkUuid: link.uuid,
              linkPayload: {
                source: {
                  node: attemptingPort.port.blockUuid,
                  port: attemptingPort.port.portId,
                },
                destination: link.dst,
              },
              connectedPortSide: PortSide.Output,
            }),
          );
        }
      }

      if (
        attemptingPort.port.side === PortSide.Input &&
        !link.dst &&
        link.uiprops.hang_coord_end
      ) {
        const distance = pythagoreanDistance(
          link.uiprops.hang_coord_end,
          attemptingPort.coord || NOWHERE,
        );

        if (distance < 10) {
          connectedIndexLUT[j] = true;

          // ensure that the final segment (before connection)
          // is a vertical segment
          if (
            segments.length > 0 &&
            segments[segments.length - 1].segment_direction === 'horiz'
          ) {
            rs.dispatch(
              modelActions.addSegmentsToLink({
                linkUuid: link.uuid,
                segmentsData: [
                  {
                    segment_direction: 'vert',
                    coordinate: snapNumberToGrid(attemptingPort.coord?.x || 0),
                  },
                ],
                prepend: false,
              }),
            );
          }

          rs.dispatch(
            modelActions.connectLinkToNode({
              parentPath: getCurrentModelRef().submodelPath,
              linkUuid: link.uuid,
              linkPayload: {
                destination: {
                  node: attemptingPort.port.blockUuid,
                  port: attemptingPort.port.portId,
                },
                source: link.src,
              },
              connectedPortSide: PortSide.Input,
            }),
          );
        }
      }
    }
  }
};
