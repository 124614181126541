/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Console: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.46964 6.53036C4.17675 6.23747 4.17675 5.76259 4.46964 5.4697C4.76253 5.17681 5.23741 5.17681 5.5303 5.4697L11.5303 11.4697C11.8232 11.7626 11.8232 12.2375 11.5303 12.5304L5.5303 18.5304C5.23741 18.8233 4.76253 18.8233 4.46964 18.5304C4.17675 18.2375 4.17675 17.7626 4.46964 17.4697L9.93931 12L4.46964 6.53036Z"
      fill={props.fill}
    />
    <path
      d="M10.75 17.5C10.3358 17.5 9.99997 17.8358 9.99997 18.25C9.99997 18.6642 10.3358 19 10.75 19H19.25C19.6642 19 20 18.6642 20 18.25C20 17.8358 19.6642 17.5 19.25 17.5H10.75Z"
      fill={props.fill}
    />
  </svg>
);

Console.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Console;
