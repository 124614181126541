import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useAuthTokens } from 'app/api/useAuthTokens';
import {
  AuthToken,
  CreateAuthTokenRequest,
} from 'app/apiGenerated/generatedApiTypes';
import React from 'react';
import { Cell, Column, Row, TableState } from 'react-table';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Plus, Remove } from 'ui/common/Icons/Standard';
import Table from 'ui/common/Table/Table';
import { formatDate } from 'util/dateUtils';
import { DATE_WIDTH_PX, TOKEN_WIDTH_PX, TableWrapper } from './ApiKeysModal';

const ONE_YEAR_IN_SECONDS = 365 * 24 * 3600;
const CENSORED_TOKEN = '*****-*****-*****-*****-*****';

const ApiKeysModalContent = styled.div`
  padding-left: 16px;
  padding: ${({ theme }) => theme.spacing.large};
`;

const NewKeyButtonWrapper = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.large};
`;

const DateBlock = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface ApiKeysTableData extends Record<string, string> {
  token: string;
  uuid: string;
  scope: string;
  createdAt: string;
  expiry: string;
}

const mapTokenToTableData = (token: AuthToken): ApiKeysTableData => ({
  token: CENSORED_TOKEN,
  uuid: token.uuid,
  scope: token.scope.charAt(0).toUpperCase() + token.scope.slice(1),
  createdAt: token.created_at,
  expiry: token.expiry,
});

const Date = (cell: Cell<ApiKeysTableData>) => (
  <DateBlock title={cell.value}>{formatDate(cell.value)}</DateBlock>
);

export const ApiKeysCollimatorTokenContents: React.FC = () => {
  const { authTokens, createAuthToken, deleteAuthToken } = useAuthTokens();

  const [newToken, setNewToken] = React.useState<AuthToken>();

  const initialState: Partial<TableState<ApiKeysTableData>> = {
    hiddenColumns: ['uuid'],
  };

  const RemoveCell = React.useCallback(
    (cell: Cell<ApiKeysTableData>) => {
      const onDelete = (tokenUuid: string) => {
        setNewToken(undefined);
        deleteAuthToken(tokenUuid);
      };

      return (
        <Button
          Icon={Remove}
          variant={ButtonVariants.LargeTertiary}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            onDelete(cell.row.original.uuid);
          }}
        />
      );
    },
    [deleteAuthToken],
  );

  const apiKeysColumns: Column<ApiKeysTableData>[] = [
    { Header: 'UUID', accessor: 'uuid', minWidth: 0, maxWidth: 0 },
    {
      Header: t({
        id: 'dashboard.authTokensModal.table.token.label',
        message: 'Token',
      }),
      accessor: 'token',
      minWidth: TOKEN_WIDTH_PX,
      maxWidth: 0,
    },
    {
      Header: t({
        id: 'dashboard.authTokensModal.table.scope.label',
        message: 'Scope',
      }),
      accessor: 'scope',
      minWidth: 80,
      maxWidth: 0,
    },
    {
      Header: t({
        id: 'dashboard.authTokensModal.table.expiry.label',
        message: 'Expires',
      }),
      accessor: 'expiry',
      minWidth: DATE_WIDTH_PX,
      maxWidth: 0,
      Cell: Date,
    },
    {
      Header: t({
        id: 'dashboard.authTokensModal.table.createdAt.label',
        message: 'Created',
      }),
      accessor: 'createdAt',
      minWidth: DATE_WIDTH_PX,
      maxWidth: 0,
      Cell: Date,
    },
    {
      Header: '',
      id: 'remove',
      disableSortBy: true,
      minWidth: 20,
      maxWidth: 0,
      disableResizing: true,
      Cell: RemoveCell,
    },
  ];

  const apiTokensTableData: ApiKeysTableData[] = React.useMemo(() => {
    if (!(authTokens && authTokens.auth_tokens)) {
      return [];
    }

    const mappedTokens: ApiKeysTableData[] = authTokens.auth_tokens.map(
      (auth_token) => mapTokenToTableData(auth_token),
    );

    if (newToken && newToken.token) {
      const newTokenIndex = mappedTokens.findIndex(
        (token) => token.uuid === newToken.uuid,
      );
      if (newTokenIndex !== -1) {
        mappedTokens[newTokenIndex].token = newToken.token;
      }
    }

    return mappedTokens;
  }, [authTokens, newToken]);

  const onCreateAuthToken = async () => {
    const createAuthTokenRequest: CreateAuthTokenRequest = {
      scope: 'developer',
      duration: ONE_YEAR_IN_SECONDS,
    };
    const token = await createAuthToken({ createAuthTokenRequest });

    if (token) {
      setNewToken(token);
    }
  };

  return (
    <>
      <TableWrapper>
        <Table
          key="apiKeys"
          columns={apiKeysColumns}
          data={apiTokensTableData}
          initialState={initialState}
          testId="auth-tokens-table"
          getRowTestId={(row: Row<ApiKeysTableData>) => row.original.uuid}
        />
      </TableWrapper>
      {apiTokensTableData?.length < 3 && (
        <NewKeyButtonWrapper>
          <Button
            type="button"
            variant={ButtonVariants.SmallTertiary}
            Icon={Plus}
            onClick={onCreateAuthToken}>
            {t({
              id: 'authTokensModal.newAuthTokenButton.label',
              message: 'Generate New API Key',
            })}
          </Button>
        </NewKeyButtonWrapper>
      )}
    </>
  );
};
