import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AutocompleteModel } from 'ui/codeEditor/AutocompleteHandler';

export interface UserOptions {
  developerModeEnabled: boolean;
  clerkWhitelistEnabled: boolean;
  hasAccessToJupyter: boolean;
  recordModeOptionEnabled: boolean;
  simIgnoreCache: boolean;
  maxQueuedSims: number;
  workerTypeSelectionEnabled: boolean;
  pythonConsoleEnabled: boolean;
  aiAssistantEnabled: boolean;
  dataIntegrationsEnabled: boolean;
  fmuBlocksEnabled: boolean;
  cppBlocksEnabled: boolean;
  showSignalPreview: boolean;
  mediaVisualizerEnabled: boolean;
  dataExplorerEnabled: boolean;
  experimentsEnabled: boolean;
  codexAutocompleteEnabled: boolean;
  codexAutocompleteModel: AutocompleteModel;
  uploadMlModelsEnabled: boolean;
  experimentalPythonToC: boolean;
  openAiKey: string;
  gitEnabled: boolean;
  chatCommonKeyMaxCallCount: number;
  chatFunctionsV2: boolean;
}

interface UserOptionsState {
  areUserOptionsLoaded: boolean;
  options: UserOptions;
}

export const initialState: UserOptionsState = {
  areUserOptionsLoaded: false,

  // Set all options to false until the options are loaded
  // to make code that uses these values easier to write
  // (as compared to using a value like "null" to represent an option
  // before it is loaded).  Since all values are loaded at once,
  // use the `areUserOptionsLoaded` value to explicitly determine user
  // option load state where appropriate (usually at the top level for
  // a general load indicator).
  options: {
    developerModeEnabled: false,
    clerkWhitelistEnabled: false,
    hasAccessToJupyter: false,
    recordModeOptionEnabled: false,
    simIgnoreCache: false,
    maxQueuedSims: 25,
    workerTypeSelectionEnabled: false,
    pythonConsoleEnabled: false,
    aiAssistantEnabled: false,
    dataIntegrationsEnabled: false,
    fmuBlocksEnabled: false,
    cppBlocksEnabled: false,
    showSignalPreview: false,
    mediaVisualizerEnabled: false,
    dataExplorerEnabled: false,
    experimentsEnabled: false,
    codexAutocompleteEnabled: false,
    codexAutocompleteModel: 'GPT3Dot5Turbo',
    uploadMlModelsEnabled: false,
    experimentalPythonToC: false,
    openAiKey: '',
    gitEnabled: false,
    chatCommonKeyMaxCallCount: 10,
    chatFunctionsV2: false,
  },
};

const userOptionsSlice = createSlice({
  name: 'UserOptionsSlice',
  initialState,
  reducers: {
    setUserOptions(
      state,
      action: PayloadAction<{
        options: UserOptions;
      }>,
    ) {
      const { options } = action.payload;

      state.options = options;
      state.areUserOptionsLoaded = true;
    },
  },
});

export const userOptionsActions = userOptionsSlice.actions;

export default userOptionsSlice;
