import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import React from 'react';
import { OptionProps } from 'react-select';
import { getUserDisplayName, getUserProfileImageUrl } from 'ui/auth/utils';
import { Edit, Tag } from 'ui/common/Icons/Standard';
import {
  AuthorAvatar,
  AuthorAvatarContainer,
  LatestTaggedVersionName,
  VersionAuthor,
  VersionDate,
  VersionInfoSection,
  VersionMetadata,
  VersionPickerVersionName,
  VersionSegment,
} from 'ui/versionHistory/DiagramVersionItemParts';
import { formatVersionTimestamp } from 'util/dateUtils';

const VersionPickerOptionWrapper = styled.div<{
  props: OptionProps<VersionPickerOptionData>;
}>`
  ${({ props }) => props.getStyles('option', props)}
  margin: 0;
  padding: 0;
`;

export interface VersionPickerOptionData {
  value: string;
  label: string;
  currentAssociatedVersionName?: string; // Used to show the latest tagged version
  authorProfileImageUrl?: string;
  authorDisplayName?: string;
  createdAt?: string;
}

const VersionPickerOption: React.FC<OptionProps<VersionPickerOptionData>> = (
  props,
) => {
  const theme = useTheme();

  const { isSelected, innerRef, innerProps, label, data } = props;

  const userDisplayName = getUserDisplayName();
  const userProfileImageUrl = getUserProfileImageUrl();

  const isAVersionId =
    data.value &&
    data.value !== VersionTagValues.LATEST_VERSION &&
    data.value !== VersionTagValues.LATEST_TAGGED_VERSION;

  return (
    <VersionPickerOptionWrapper props={props} ref={innerRef} {...innerProps}>
      <VersionSegment isSelected={isSelected}>
        {/* Version metadata section */}
        <VersionInfoSection>
          <AuthorAvatarContainer>
            {isAVersionId ? (
              <AuthorAvatar
                src={data.authorProfileImageUrl || userProfileImageUrl}
              />
            ) : data.value ? (
              <Tag fill={theme.colors.text.secondary} />
            ) : (
              <Edit fill={theme.colors.text.secondary} />
            )}
          </AuthorAvatarContainer>

          <VersionMetadata>
            <VersionPickerVersionName>{label}</VersionPickerVersionName>

            {data.value === VersionTagValues.LATEST_TAGGED_VERSION && (
              <LatestTaggedVersionName>
                {data.currentAssociatedVersionName}
              </LatestTaggedVersionName>
            )}

            {isAVersionId && (
              <VersionAuthor>
                {data.authorDisplayName || userDisplayName}
              </VersionAuthor>
            )}
            {data.value && (
              <VersionDate>
                {formatVersionTimestamp(data.createdAt)}
              </VersionDate>
            )}
          </VersionMetadata>
        </VersionInfoSection>
      </VersionSegment>
    </VersionPickerOptionWrapper>
  );
};

export default VersionPickerOption;
