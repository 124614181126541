import React from 'react';
import ReactDOM from 'react-dom';

import { i18n, I18n } from '@lingui/core';
import {
  fromNavigator,
  fromStorage,
  fromUrl,
  multipleDetect as detectLanguage,
} from '@lingui/detect-locale';
import { I18nProvider } from '@lingui/react';
import allMessages from 'locales';
import { en } from 'make-plural/plurals';
import { Provider } from 'react-redux';

import AppLayout from 'AppLayout';
import AppOfflineLayout from 'AppOfflineLayout';
import { store } from './app/store';
import { initErrorTracking } from './errorTracking';
// eslint-disable-next-line import/no-unassigned-import
import './index.css';
import * as serviceWorker from './serviceWorker';

initErrorTracking();

const setupTranslation = (): I18n => {
  const requestedLanguages = detectLanguage(
    fromUrl('lang'),
    fromStorage('lang'),
    fromNavigator(),
  );

  Object.keys(allMessages).forEach((language) => {
    const messages = allMessages[language];

    // Fallback to English when translation was not found
    if (language !== 'en') {
      const en = allMessages.en;
      for (const k in messages) {
        if (messages[k] === k) {
          messages[k] = en[k];
        }
      }
    }

    i18n.load(language, messages);
  });

  for (const lang of requestedLanguages) {
    if (Object.keys(allMessages).includes(lang)) {
      i18n.activate(lang, requestedLanguages);
      return i18n;
    }
  }

  i18n.loadLocaleData('en', { plurals: en });

  i18n.activate('en', requestedLanguages);
  return i18n;
};

ReactDOM.render(
  <React.StrictMode>
    <I18nProvider i18n={setupTranslation()}>
      <Provider store={store}>
        {process.env.REACT_APP_IS_OFFLINE === 'true' ? (
          <AppOfflineLayout />
        ) : (
          <AppLayout />
        )}
      </Provider>
    </I18nProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
