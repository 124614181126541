/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Requirements: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.43592 5.8897C8.77298 6.13045 8.85105 6.59887 8.61029 6.93593L6.39168 10.042C5.99954 10.591 5.2425 10.7297 4.68114 10.3555L3.58396 9.62403C3.23932 9.39427 3.14619 8.92862 3.37595 8.58397C3.60571 8.23933 4.07137 8.1462 4.41601 8.37596L5.31178 8.97314L7.38969 6.06407C7.63045 5.72701 8.09886 5.64894 8.43592 5.8897Z"
      fill={props.fill}
    />
    <path
      d="M19.75 8.75C19.75 9.16421 19.4142 9.5 19 9.5L10.75 9.5C10.3358 9.5 9.99999 9.16421 9.99999 8.75C9.99999 8.33578 10.3358 8 10.75 8L19 8C19.4142 8 19.75 8.33578 19.75 8.75Z"
      fill={props.fill}
    />
    <path
      d="M19.75 15.75C19.75 16.1642 19.4142 16.5 19 16.5L10.75 16.5C10.3358 16.5 9.99999 16.1642 9.99999 15.75C9.99999 15.3358 10.3358 15 10.75 15H19C19.4142 15 19.75 15.3358 19.75 15.75Z"
      fill={props.fill}
    />
    <path
      d="M8.61029 13.9359C8.85105 13.5989 8.77298 13.1305 8.43592 12.8897C8.09886 12.6489 7.63045 12.727 7.38969 13.0641L5.31178 15.9731L4.41601 15.376C4.07137 15.1462 3.60571 15.2393 3.37595 15.584C3.14619 15.9286 3.23932 16.3943 3.58396 16.624L4.68114 17.3555C5.2425 17.7297 5.99954 17.591 6.39168 17.042L8.61029 13.9359Z"
      fill={props.fill}
    />
  </svg>
);

Requirements.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Requirements;
