import React from 'react';
import { v4 as uuid } from 'uuid';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  NodeLocation,
  PortLocation,
  getPortPathName,
} from 'ui/modelEditor/portPathNameUtils';
import {
  getAreAllNodePortsInVisualizer,
  getAreAnyNodePortsInVisualizer,
  getIsPortInVisualizer,
} from 'util/signalVisualization';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { TraceMetadata } from 'ui/dataExplorer/dataExplorerTypes';
import { getNestedNode } from 'app/utils/modelDiagramUtils';
import { getTracePathsForPort } from 'util/portTypeUtils';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { NewPlotRequest } from 'util/plotCellUtils';
import {
  SignalPathInfo,
  buildSignalInfoForCurrentModel,
} from 'util/modelVersionSignal';
import { getDefaultChartType } from 'util/timeMode';

export function useVisualizerPrefs() {
  const dispatch = useAppDispatch();

  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  const simulationId = useAppSelector(
    (state) => state.project.simulationSummary?.uuid,
  );

  const idToTrace = useAppSelector((state) => state.dataExplorer.idToTrace);

  const modelIdToVersionIdToModelData = useAppSelector(
    (state) => state.modelVersions.modelIdToVersionIdToModelData,
  );

  const getAreAnyNodePortsInChart = React.useCallback(
    ({ nodeId, parentPath }: NodeLocation) =>
      getAreAnyNodePortsInVisualizer({
        nodeId,
        parentPath,
        idToTrace,
      }),
    [idToTrace],
  );

  const getAreAllNodePortsInChart = React.useCallback(
    ({ nodeId, parentPath }: NodeLocation) =>
      getAreAllNodePortsInVisualizer({
        nodeId,
        parentPath,
        idToTrace,
      }),
    [idToTrace],
  );

  const getIsPortInChart = React.useCallback(
    ({ nodeId, parentPath, portIndex }: PortLocation) =>
      getIsPortInVisualizer({
        nodeId,
        parentPath,
        portIndex,
        idToTrace,
      }),
    [idToTrace],
  );

  const addPortsToChart = React.useCallback(
    (portLocations: PortLocation[]) => {
      const newPlots: NewPlotRequest[] = [];

      portLocations.forEach((portLocation) => {
        let tracesToAdd: TraceMetadata[] = [];

        const signalPath = getPortPathName(
          getCurrentModelRef().topLevelNodes,
          getCurrentModelRef().submodels,
          portLocation,
          {
            includePortNameForNonSubmodels: true,
          },
        );

        if (!signalPath) return;

        // Find display name for signal.
        const {
          signalDisplayName: foundSignalDisplayName,
          timeMode,
        }: SignalPathInfo = buildSignalInfoForCurrentModel(
          signalPath,
          modelIdToVersionIdToModelData,
        );

        const signalDisplayName = foundSignalDisplayName || signalPath;

        const { tracePaths, isVector, modelVersionToRequest } =
          getTracePathsForPort(signalPath, modelIdToVersionIdToModelData);
        // FIXME and remove error statements in UI-1140
        if (modelVersionToRequest) {
          console.error(`Insufficient model info to add signals:`);
          console.error(modelVersionToRequest);
        }
        const traces: TraceMetadata[] =
          tracePaths?.map((tracePath, vectorIndex) => ({
            id: uuid(),
            signalPath,
            tracePath,
            displayName: isVector
              ? `${signalDisplayName}[${vectorIndex}]`
              : signalDisplayName,
            plotType: getDefaultChartType(timeMode),
            vectorIndex: isVector ? vectorIndex : undefined,
            portIndex: portLocation.portIndex,
            modelId: loadedModelId,
            simulationId,
          })) || [];

        tracesToAdd = [...tracesToAdd, ...traces];

        // Add all traces for a single signal into
        // their own plot cells.
        newPlots.push({ traces: tracesToAdd });
      });

      if (newPlots.length > 0) {
        dispatch(
          dataExplorerActions.addTracesInNewPlotCells({
            newPlots,
          }),
        );
      }

      dispatch(uiFlagsActions.showVisualizerFooterTab());
    },
    [dispatch, loadedModelId, simulationId, modelIdToVersionIdToModelData],
  );

  const removePortsFromChart = React.useCallback(
    (portLocations: PortLocation[]) => {
      const traceIdsToRemove: string[] = [];
      portLocations.forEach((portLocation) => {
        const signalPath = getPortPathName(
          getCurrentModelRef().topLevelNodes,
          getCurrentModelRef().submodels,
          portLocation,
          {
            includePortNameForNonSubmodels: true,
          },
        );

        if (!signalPath) return;

        Object.values(idToTrace).forEach((trace) => {
          if (trace.signalPath === signalPath) {
            traceIdsToRemove.push(trace.id);
          }
        });
      });

      dispatch(
        dataExplorerActions.removeTraces({
          traceIds: traceIdsToRemove,
        }),
      );
    },
    [dispatch, idToTrace],
  );

  const removeAllBlockPortsFromChart = React.useCallback(
    (nodeLocations: NodeLocation[]) => {
      const portsToRemove: PortLocation[] = [];
      nodeLocations.forEach(({ parentPath, nodeId }) => {
        const node = getNestedNode(
          getCurrentModelRef().topLevelNodes,
          getCurrentModelRef().submodels,
          parentPath,
          nodeId,
        );
        if (node) {
          for (
            let portIndex = 0;
            portIndex < node.outputs.length;
            portIndex++
          ) {
            portsToRemove.push({
              nodeId,
              parentPath,
              portIndex,
            });
          }
        }
      });

      removePortsFromChart(portsToRemove);
    },
    [removePortsFromChart],
  );

  const toggleAllBlockPortsInChart = React.useCallback(
    ({ nodeId, parentPath }: NodeLocation) => {
      const areAnyNodePortsInChart = getAreAnyNodePortsInChart({
        nodeId,
        parentPath,
      });
      if (areAnyNodePortsInChart) {
        removeAllBlockPortsFromChart([{ nodeId, parentPath }]);
      } else {
        const node = getNestedNode(
          getCurrentModelRef().topLevelNodes,
          getCurrentModelRef().submodels,
          parentPath,
          nodeId,
        );
        if (node) {
          const nodeDetails: PortLocation[] = [];
          for (
            let portIndex = 0;
            portIndex < node.outputs.length;
            portIndex++
          ) {
            nodeDetails.push({
              nodeId,
              parentPath,
              portIndex,
            });
          }

          addPortsToChart(nodeDetails);
        }
      }
    },
    [getAreAnyNodePortsInChart, removeAllBlockPortsFromChart, addPortsToChart],
  );

  return {
    getAreAnyNodePortsInChart,
    getAreAllNodePortsInChart,
    getIsPortInChart,
    addPortsToChart,
    removePortsFromChart,
    removeAllBlockPortsFromChart,
    toggleAllBlockPortsInChart,
  };
}
