import styled from '@emotion/styled';
import { useAppDispatch } from 'app/hooks';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { ReactElement, useEffect, useLayoutEffect, useRef } from 'react';
import { usePython } from 'ui/common/PythonProvider';

const ConsoleWrapper = styled.div`
  overflow: auto;
  height: 100%;
  background-color: #ffffff;
  font-family: monospace;
  padding: 10px;
`;

const Output = styled.div`
  white-space: pre-wrap;
  padding: 0 5px;
  font-family: monospace;
`;

const Code = styled.textarea`
  width: 100%;
  padding: 3px;
  margin: 4px 0;
  outline: none;
  font-family: monospace;
`;

const StayScrolledToBottom = () => {
  const elementRef = useRef<null | HTMLDivElement>(null);
  useLayoutEffect(() => elementRef.current?.scrollIntoView());
  return <div ref={elementRef} />;
};

type ConsoleProps = {
  input: string;
  setInput: (i: string) => void;
  output: string;
  setOutput: (o: string) => void;
};

const Console = ({
  input,
  setInput,
  output,
  setOutput,
}: ConsoleProps): ReactElement => {
  const dispatch = useAppDispatch();
  const python = usePython();
  const pyodide = python.pyodide;

  useEffect(() => {
    dispatch(uiFlagsActions.requestLoadPython());
  }, [dispatch]);

  const runPython = async () => {
    if (!pyodide) return;
    const inputs = input
      .split('\n')
      .map((s) => `>>> ${s}`)
      .join('\n');
    let newOutput = `${output}${inputs}\n`;
    try {
      const out = await pyodide.runPythonAsync(input);
      newOutput += python.readStdout();
      if (out) {
        newOutput += `${out}\n`;
      }
    } catch (e: any) {
      if (e.constructor.name === 'PythonError') {
        newOutput += `${e.message}\n`;
      }
    } finally {
      setOutput(newOutput);
      setInput('');
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      e.key === 'Enter' &&
      input.length > 0 &&
      input[input.length - 1] !== '\\' &&
      !e.shiftKey
    ) {
      runPython();
      e.preventDefault();
    }
  };

  return (
    <ConsoleWrapper>
      <Output>{output}</Output>
      <Code
        value={input}
        onFocus={() =>
          dispatch(uiFlagsActions.setUIFlag({ textInputFocused: true }))
        }
        onBlur={() =>
          dispatch(uiFlagsActions.setUIFlag({ textInputFocused: false }))
        }
        onChange={(e) => {
          setInput(e.target.value);
        }}
        onKeyDown={handleEnter}
      />
      <StayScrolledToBottom />
    </ConsoleWrapper>
  );
};

export default Console;
