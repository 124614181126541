import { t } from '@lingui/macro';
import { useAdminApis } from 'app/api/useAdminApis';
import { useProjectActions } from 'app/api/useProjectActions';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { getAdminAssumedUser } from 'app/config/adminAssumedUser';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isAdminUser } from 'ui/auth/utils';
import MenuContent from 'ui/common/Menu/MenuContent';
import { PortalMenuBackground } from 'ui/common/Menu/PortalMenu';
import { ItemType, MenuItemConfig } from 'ui/common/Menu/menuItemTypes';
import { useModal } from 'ui/common/Modal/useModal';
import { useProjectPermission } from 'ui/permission/useProjectPermission';
import { getProjectFilter } from 'util/projectFilterUtils';
import ManageMembersModal from '../members/ManageMembersModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';

interface Props {
  project: Project;
  isViewingProject: boolean;
  triggerClose: () => void;
  excludeDeleteMenuOption: boolean;
}

const ProjectMenu: React.FC<Props> = ({
  project,
  isViewingProject,
  triggerClose,
  excludeDeleteMenuOption,
}) => {
  const navigate = useNavigate();
  const { triggerModal, closeModal } = useModal();
  const { deleteProject, duplicateProject } = useProjectActions();

  const { canAdministrateProject } = useProjectPermission(project.uuid);

  const { createGlobalProject, deleteGlobalProject } = useAdminApis();

  const userId = useAppSelector((state) => state.user.userId);
  const currentUserId = getAdminAssumedUser() ?? userId;
  const projectFilter =
    !!currentUserId && getProjectFilter(project, currentUserId);

  const onDeleteProject = React.useCallback(
    async (projectId: string) => {
      await deleteProject(projectId);
      closeModal();
    },
    [deleteProject, closeModal],
  );

  const deleteModal = React.useCallback(
    (project: Project) => {
      triggerModal(
        <DeleteConfirmationModal
          onDelete={() => onDeleteProject(project.uuid)}
          message={t({
            id: 'deleteProjectModal.messageContent.text',
            message:
              'Are you sure you want to delete this project? All files and settings will be removed.',
          })}
        />,
        t({
          id: 'deleteProjectModal.messageTitle.text',
          message: 'Delete Project?',
        }),
      );
    },
    [triggerModal, onDeleteProject],
  );

  const manageMembersModal = React.useCallback(
    (p: Project) => {
      triggerModal(
        <ManageMembersModal projectId={p.uuid} />,
        t({
          id: 'projects.manageMembersModalDialog.title',
          message: 'Manage members',
        }),
      );
    },
    [triggerModal],
  );

  const onDuplicateProject = React.useCallback(
    async (p: Project) => {
      const newTitle = `${p.title} - Copy - ${new Date().toISOString()}`;
      const projectCopy = await duplicateProject({
        projectUuid: p.uuid,
        projectCreateRequest: {
          title: newTitle,
          description: p.description,
        },
      });
      if (projectCopy) {
        if (isViewingProject) {
          // Take them to the new project
          navigate(`/projects/${projectCopy.uuid}`);
        } else {
          // If in list view, take them to 'My Projects' so that they see the new copy.
          navigate(`/projects`);
        }
      }
    },
    [duplicateProject, isViewingProject, navigate],
  );

  const onTogglePublishProject = React.useCallback(
    async (p: Project) => {
      if (p.isPublic) {
        await deleteGlobalProject(p.uuid, 'Default');
      } else {
        await createGlobalProject(p.uuid, 'Default');
      }
    },
    [createGlobalProject, deleteGlobalProject],
  );

  // Composition is more appropriate here, where each button is a component.
  // This function only gets more and more complicated as we try to accomodate more types of menus that depend on various non-mutual conditions.
  const items: MenuItemConfig[] = React.useMemo(() => {
    const itemsBuilder: MenuItemConfig[] = [
      {
        type: ItemType.Button,
        content: 'Duplicate',
        disabled: projectFilter && projectFilter === 'public',
        onClick: () => onDuplicateProject(project),
      },
      {
        type: ItemType.Button,
        content: 'Manage members',
        onClick: () => manageMembersModal(project),
      },
    ];

    if (isAdminUser()) {
      itemsBuilder.push({
        type: ItemType.Separator,
      });
      itemsBuilder.push({
        type: ItemType.Button,
        content: project.isPublic ? 'Unpublish' : 'Publish',
        onClick: () => onTogglePublishProject(project),
      });
    }

    if (!excludeDeleteMenuOption && canAdministrateProject) {
      itemsBuilder.push({
        type: ItemType.Separator,
      });
      itemsBuilder.push({
        type: ItemType.Button,
        content: 'Delete',
        onClick: () => deleteModal(project),
        shortcut: {
          keyStrings: ['Del'],
          code: 'Delete',
        },
      });
    }

    return itemsBuilder;
  }, [
    excludeDeleteMenuOption,
    deleteModal,
    onDuplicateProject,
    onTogglePublishProject,
    manageMembersModal,
    project,
    canAdministrateProject,
    projectFilter,
  ]);

  return (
    <PortalMenuBackground>
      <MenuContent items={items} isMenuOpen triggerCloseMenu={triggerClose} />
    </PortalMenuBackground>
  );
};

export default ProjectMenu;
