import { t } from '@lingui/macro';
import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import {
  BlockClassName,
  BlockClassNamesArray,
} from 'app/generated_types/SimulationModel';
import {
  nodeClassToPrintName,
  printNameToSpacedDisplayName,
} from 'app/helpers';
import { useAppSelector } from 'app/hooks';
import { PseudoBlockType } from 'app/pseudo_blocks/StateMachine';
import { allPseudoBlocksData } from 'app/pseudo_blocks/allPseudoBlocksData';
import React from 'react';
import {
  getIsSearchMatch,
  shouldShowBlock,
} from 'ui/objectBrowser/librarySearch';
import { BlockLibraryDragItem } from 'ui/objectBrowser/sections/BlockLibraryDragItem';
import LibrarySubsection from 'ui/objectBrowser/sections/LibrarySubsection';
import { getStringSorter } from 'util/getStringSorter';

export enum LibItemDisplayType {
  Base,
  Pseudo,
}
type LibItemDisplayUnion =
  | {
      type: LibItemDisplayType.Base;
      sortName: string;
      blockClassName: BlockClassName;
    }
  | {
      type: LibItemDisplayType.Pseudo;
      sortName: string;
      pseudoData: PseudoBlockType;
    };

// typescript compiler gets angry here if we are less verbose than this
const baseDisplayItems: LibItemDisplayUnion[] = BlockClassNamesArray.map(
  (name) => ({
    type: LibItemDisplayType.Base,
    sortName: name,
    blockClassName: name,
  }),
);
const pseudoDisplayItems: LibItemDisplayUnion[] = allPseudoBlocksData.map(
  (pseudoData) => ({
    type: LibItemDisplayType.Pseudo,
    sortName: `core.${pseudoData.displayName.trim()}`,
    pseudoData,
  }),
);

const libraryDisplayItems: LibItemDisplayUnion[] = [
  ...baseDisplayItems,
  ...pseudoDisplayItems,
].sort(getStringSorter('sortName'));

interface Props {
  lowercaseSearchString: string;
  showCategories: boolean;
  currentSubdiagramType?: BlockClassName;
}

export const searchFoundationalBlocks = (
  lowercaseSearchString: string,
  developerModeEnabled: boolean,
  topLevelModelKind: ModelKind,
  currentSubdiagramType?: BlockClassName,
) =>
  libraryDisplayItems.filter((block) => {
    const blockClassName =
      block.type === LibItemDisplayType.Base
        ? block.blockClassName
        : block.pseudoData.baseBlockClassName;
    if (
      !shouldShowBlock(
        blockClassName,
        developerModeEnabled,
        topLevelModelKind,
        currentSubdiagramType,
      )
    ) {
      return false;
    }

    if (lowercaseSearchString) {
      const searchMatch = getIsSearchMatch(
        printNameToSpacedDisplayName(nodeClassToPrintName(blockClassName)),
        lowercaseSearchString,
      );

      if (block.type === LibItemDisplayType.Pseudo) {
        return (
          searchMatch ||
          getIsSearchMatch(block.pseudoData.displayName, lowercaseSearchString)
        );
      }

      if (!searchMatch) {
        return false;
      }
    }

    return true;
  });

export const FoundationalBlocks: React.FC<Props> = ({
  lowercaseSearchString,
  showCategories,
  currentSubdiagramType,
}) => {
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const modelKind = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );
  const parentPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );

  const filteredBlocks = modelKind
    ? searchFoundationalBlocks(
        lowercaseSearchString,
        developerModeEnabled,
        modelKind,
        currentSubdiagramType,
      )
    : [];

  if (filteredBlocks.length === 0) {
    return null;
  }

  return (
    <LibrarySubsection
      title={
        (showCategories &&
          t({
            id: 'modelEditor.library.foundationalBlocks',
            message: 'Foundational Blocks',
          })) ||
        undefined
      }
      count={filteredBlocks.length}>
      {filteredBlocks.map((displayItem) =>
        displayItem.type === LibItemDisplayType.Base ? (
          <BlockLibraryDragItem
            blockClassName={displayItem.blockClassName}
            key={displayItem.blockClassName}
          />
        ) : (
          <BlockLibraryDragItem
            blockClassName={displayItem.pseudoData.baseBlockClassName}
            overrideDisplayName={displayItem.pseudoData.displayName}
            overridePropDefaults={displayItem.pseudoData.overrideDefaults}
            key={`pseudo_${displayItem.pseudoData.baseBlockClassName}-${displayItem.pseudoData.overrideDefaults.name}`}
          />
        ),
      )}
    </LibrarySubsection>
  );
};
