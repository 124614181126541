import styled from '@emotion/styled/macro';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import {
  BlockClassName,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import React from 'react';
import { Draggable } from 'ui/dragdrop/Draggable';
import { LINK_COLORS } from 'ui/modelRendererInternals/drawLink';
import { BLOCK_FILLS } from 'ui/modelRendererInternals/drawNode';
import BlockComponent, {
  BlockColorOverride,
} from 'ui/objectBrowser/BlockComponent';
import {
  getBuiltInBlockDisplayData,
  getReferenceSubmodelBlockDisplayData,
} from 'ui/objectBrowser/BlockDisplayData';
import { nvgColorToHex } from 'util/nvgColorHexConvert';
import { iteratorOnlyBlocks } from './IteratorBlocks';

interface Props {
  blockClassName: BlockClassName;
  submodel?: SubmodelInfoLiteUI;
  overrideDisplayName?: string;
  overridePropDefaults?: Partial<NodeInstance>;
}

const blockLibraryItemColorOverrides: {
  [k: string]: BlockColorOverride | undefined;
} = {
  iteratorOnly: {
    blockBg: `#${nvgColorToHex(BLOCK_FILLS.inside_iterator)}`,
    blockBorder: `#${nvgColorToHex(LINK_COLORS.iterator)}`,
  },
};

const StyledDraggable = styled(Draggable)`
  height: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.normal};
`;

export const BlockLibraryDragItem: React.FC<Props> = ({
  blockClassName,
  submodel,
  overrideDisplayName,
  overridePropDefaults,
}) => {
  const blockDisplayData = React.useMemo(() => {
    const overrideColors = iteratorOnlyBlocks.includes(blockClassName)
      ? blockLibraryItemColorOverrides.iteratorOnly
      : undefined;
    if (submodel) {
      return getReferenceSubmodelBlockDisplayData(submodel);
    }
    const builtInDisplayData = getBuiltInBlockDisplayData(blockClassName);
    return {
      ...builtInDisplayData,
      nodeTypePrintName:
        overrideDisplayName || builtInDisplayData.nodeTypePrintName,
      overridePropDefaults,
      overrideColors,
    };
  }, [blockClassName, submodel, overrideDisplayName, overridePropDefaults]);

  return (
    <StyledDraggable
      testId={`library-block-for-${blockDisplayData.nodeTypePrintName.replaceAll(
        ' ',
        '',
      )}`}
      category="LibraryBlock"
      dragPreviewComp={BlockComponent}
      dragPreviewCompProps={blockDisplayData}>
      <BlockComponent {...blockDisplayData} />
    </StyledDraggable>
  );
};
