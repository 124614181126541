import { useTheme } from '@emotion/react';
import { t } from '@lingui/macro';
import { ChatMessage, ChatMessageRole } from 'app/third_party_types/chat-types';
import React, { ReactElement, memo, useState } from 'react';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Eye, EyeCrossed, Remove } from 'ui/common/Icons/Standard';
import Label from 'ui/common/Label';

import {
  MessageBlock,
  MessageBlockCodeType,
  MessageContents,
  parseGptMessage,
} from './ChatGptMessageParser';
import PythonCode from './PythonCode';
import { useSimCompleteListener } from './SimCompleteListenerProvider';
import {
  AssistantRow,
  UserAvatar,
  AiAvatar,
  ChatContentDiv,
  MessageDiv,
  PythonCodeInfoDiv,
  RemoveButton,
  ShowContextButton,
  UserRow,
} from './Styles';
import {
  CallCountExceededMessage,
  ProcessingMessage,
  ProgressStatus,
  WelcomeMessage,
} from './SystemMessages';
import { useAuthorizationCheck } from './useAuthorizationCheck';
import { useFunctions } from './GptFunctions';

const visibleGptFunctions = new Set([
  'plot',
  'execute_python',
  'build_model',
  'build_group',
]);

const showPythonStdOut = new Set([MessageBlockCodeType.AnalyzeResult]);

const tryFixMessage = [
  {
    content:
      'I apologize. It seems like an error occured while processing your request. Would you like me to try to fix it?',
    isCode: false,
  },
];
interface ChatGptUiMessage {
  role: ChatMessageRole;
  blocks: MessageContents;
  hasContext: boolean;
  indexInOutput: number;
}

const messageNotEmpty = (visibleBlocks: MessageBlock[]) =>
  visibleBlocks.length > 0 &&
  visibleBlocks.some(
    (b) =>
      ('content' in b && b.content.length > 0) ||
      ('code' in b && b.code.length > 0) ||
      ('plot' in b && b.plot.length > 0),
  );

// A self debug message is an assistant message that comes right after
// a function error.
export const isAssistantSelfDebug = (index: number, messages: ChatMessage[]) =>
  messages[index].role === ChatMessageRole.Assistant &&
  index < messages.length &&
  index > 0 &&
  messages[index - 1].role === ChatMessageRole.Function &&
  messages[index - 1].functionHasError;

// Show self-debug message if it's the last message
// ie. it stopped trying to self-debug.
const isLastAssistantSelfDebugMessage = (
  messages: ChatMessage[],
  index: number,
) =>
  index <= messages.length - 1 &&
  messages[index].role === ChatMessageRole.Assistant &&
  (!isAssistantSelfDebug(index, messages) ||
    index === messages.length - 1 ||
    (index < messages.length - 1 &&
      messages[index + 1].role === ChatMessageRole.User));

const shouldShowFunction = (
  messages: ChatMessage[],
  index: number,
  isCurrentlyCompleting: boolean,
) => {
  if (!visibleGptFunctions.has(messages[index].functionName || ''))
    return false;
  if (index === messages.length - 1 && isCurrentlyCompleting) return false;

  if (!messages[index].functionHasError) return true;

  // function with error is shown if it's the last message before
  // the assistant gives up trying to self-debug.
  return (
    index < messages.length - 1 &&
    isLastAssistantSelfDebugMessage(messages, index + 1) &&
    !(index === messages.length - 2 && isCurrentlyCompleting)
  );
};

const convertToUiMessages = (
  messages: ChatMessage[],
  plots: string[],
  showAll: boolean,
  isCurrentlyCompleting: boolean,
): ChatGptUiMessage[] =>
  messages
    .map((msg, realIndex) => ({
      msg,
      realIndex,
    }))
    .filter(
      (msgWithRealIndex, index) =>
        showAll ||
        shouldShowFunction(messages, index, isCurrentlyCompleting) ||
        isLastAssistantSelfDebugMessage(messages, index) ||
        msgWithRealIndex.msg.role === ChatMessageRole.User,
    )
    .map((msgWithRealIndex) => {
      const blocks = parseGptMessage(msgWithRealIndex.msg, plots);

      if (isAssistantSelfDebug(msgWithRealIndex.realIndex, messages)) {
        if (
          isCurrentlyCompleting &&
          msgWithRealIndex.realIndex === messages.length - 1
        ) {
          blocks.hiddenBlocks = blocks.visibleBlocks;
          blocks.visibleBlocks = [];
        } else {
          blocks.hiddenBlocks = blocks.visibleBlocks;
          blocks.visibleBlocks = [{ content: tryFixMessage[0].content }];
        }
      }

      return {
        role: msgWithRealIndex.msg.role,
        blocks,
        hasContext: (blocks.hiddenBlocks?.length || 0) > 0,
        indexInOutput: msgWithRealIndex.realIndex,
      };
    });

export interface MessageEdit {
  content?: string;
  functionArgs?: object;
  functionResult?: string;
}

interface ContentProps {
  hiddenBlocks?: MessageBlock[];
  visibleBlocks: MessageBlock[];
  index: number;
  onMessageEdit?: (edit: MessageEdit) => void;
  onPromptSuggestion?: (suggestion: string) => void;
  readOnly?: boolean;
  showContext?: boolean;
  addPlot: (plot: string) => number;
}

const CodeTypeNeedsSimResults = new Set([
  MessageBlockCodeType.Plot,
  MessageBlockCodeType.AnalyzeResult,
]);

interface CodeContentBlockProps {
  content: string;
  codeType: MessageBlockCodeType;
  result: string;
  hasError: boolean;
  id: string;

  readOnly?: boolean;
  onMessageEdit?: (edit: MessageEdit) => void;
  addPlot: (plot: string) => number;
}

const CodeContentBlock = ({
  content,
  readOnly,
  codeType,
  result,
  hasError,
  id,
  onMessageEdit,
  addPlot,
}: CodeContentBlockProps): ReactElement => {
  const { lastResultsColumnNames, lastResultsIsDirty } =
    useSimCompleteListener();
  const functions = useFunctions(addPlot);

  const lastResultsColumnNamesStr = lastResultsColumnNames?.current.join(', ');

  const onChange = React.useCallback(
    (code, stdout, stderr) => {
      if (!readOnly)
        onMessageEdit?.({
          functionResult: stdout ?? stderr,
          functionArgs: { code },
        });
    },
    [onMessageEdit, readOnly],
  );

  return (
    <div>
      {CodeTypeNeedsSimResults.has(codeType) && (
        <PythonCodeInfoDiv>
          {lastResultsIsDirty?.current && (
            <div>
              {t`The model has been updated. Please re-run a simulation to get the latest results.`}
            </div>
          )}
          {lastResultsColumnNames && lastResultsColumnNames.current.length > 0
            ? `Available column names in df: ${lastResultsColumnNamesStr}`
            : t`Simulation results are not available.`}
        </PythonCodeInfoDiv>
      )}
      <PythonCode
        id={id}
        readOnly={readOnly}
        value={content}
        onChange={onChange}
        codeType={codeType}
        executePython={functions.execute_python}
        plot={functions.plot}
        enableExecution={
          lastResultsColumnNames && lastResultsColumnNames.current.length > 0
        }
        showStdOut={showPythonStdOut.has(codeType)}
        error={hasError ? result : undefined}
        stdout={hasError ? undefined : result}
      />
    </div>
  );
};

const Content = ({
  hiddenBlocks,
  visibleBlocks,
  index: baseIndex,
  onMessageEdit,
  readOnly,
  showContext,
  addPlot,
}: ContentProps): ReactElement => {
  const messageBlock = React.useCallback(
    (block: MessageBlock, index: number) => {
      if ('codeType' in block) {
        return (
          <CodeContentBlock
            id={`code-${baseIndex}-${index}`}
            content={block.code}
            readOnly={readOnly}
            key={`${baseIndex}-${index}`}
            codeType={block.codeType}
            onMessageEdit={onMessageEdit}
            addPlot={addPlot}
            result={block.result}
            hasError={block.hasError}
          />
        );
      }
      if ('plot' in block) {
        return (
          <img
            src={`data:image/png;base64, ${block.plot}`}
            data-test-id={`plot-${baseIndex}-${index}`}
            key={`img-${baseIndex}-${index}`}
            style={{ width: '700px' }}
          />
        );
      }
      return <div key={`msg-${baseIndex}-${index}`}>{block.content}</div>;
    },
    [addPlot, baseIndex, onMessageEdit, readOnly],
  );

  const numHiddenBlocks = hiddenBlocks ? hiddenBlocks.length : 0;

  return (
    <MessageDiv>
      {hiddenBlocks &&
        numHiddenBlocks > 0 &&
        showContext &&
        hiddenBlocks.map(messageBlock)}
      {visibleBlocks.map((block, index) =>
        messageBlock(block, index + numHiddenBlocks),
      )}
    </MessageDiv>
  );
};

interface DialogTurnProps {
  role: ChatMessageRole;
  index: number;
  onMessageEdit?: (edit: MessageEdit, index: number) => void;
  onRemoveButtonClick?: (index: number) => void;
  isCurrentlyCompleting: boolean;
  addPlot: (plot: string) => number;
  showAdvancedOptions: boolean;
  spin: boolean;

  hiddenBlocks?: MessageBlock[];
  visibleBlocks: MessageBlock[];
  indexInOutput: number;
}

const messageBlocksEqual = (
  prevBlocks: MessageBlock[],
  nextBlocks: MessageBlock[],
) => {
  if (prevBlocks.length !== nextBlocks.length) return false;
  for (let i = 0; i < prevBlocks.length; i++) {
    const prevBlock = prevBlocks[i] as any;
    const nextBlock = nextBlocks[i] as any;
    for (const key in prevBlock) {
      if (key in prevBlock) {
        if (prevBlock[key] !== nextBlock[key]) {
          return false;
        }
      }
    }
  }
  return true;
};

const dialogTurnPropsEqual = (
  prevProps: DialogTurnProps,
  nextProps: DialogTurnProps,
) => {
  const keys = Object.keys(prevProps) as Array<keyof DialogTurnProps>;
  for (const key of keys) {
    if (key === 'hiddenBlocks' || key === 'visibleBlocks') continue;
    if (prevProps[key] !== nextProps[key]) {
      return false;
    }
  }

  if (!!prevProps.hiddenBlocks !== !!nextProps.hiddenBlocks) {
    return false;
  }
  return (
    messageBlocksEqual(prevProps.visibleBlocks, nextProps.visibleBlocks) &&
    (prevProps.hiddenBlocks === undefined ||
      nextProps.hiddenBlocks === undefined ||
      messageBlocksEqual(prevProps.hiddenBlocks, nextProps.hiddenBlocks))
  );
};

const DialogTurn = memo(
  ({
    role,
    index,
    onMessageEdit,
    onRemoveButtonClick,
    isCurrentlyCompleting,
    addPlot,
    showAdvancedOptions,
    spin,
    hiddenBlocks,
    visibleBlocks,
    indexInOutput,
  }: DialogTurnProps) => {
    const [showContext, setShowContext] = useState<boolean>(true);
    const theme = useTheme();

    return role === ChatMessageRole.User ? (
      <UserRow key={index}>
        <UserAvatar />
        <Content
          index={index}
          hiddenBlocks={hiddenBlocks}
          visibleBlocks={visibleBlocks}
          showContext={showContext}
          onMessageEdit={(edit) => onMessageEdit?.(edit, indexInOutput)}
          addPlot={addPlot}
        />
        <RemoveButton
          Icon={Remove}
          variant={ButtonVariants.SmallTertiary}
          disabled={isCurrentlyCompleting}
          tint={theme.colors.grey[10]}
          onClick={() => onRemoveButtonClick?.(indexInOutput)}
        />
      </UserRow>
    ) : showAdvancedOptions || messageNotEmpty(visibleBlocks) ? (
      <AssistantRow key={index}>
        {hiddenBlocks && showAdvancedOptions && (
          <ShowContextButton
            Icon={showContext ? EyeCrossed : Eye}
            onClick={() => setShowContext(!showContext)}
            variant={ButtonVariants.SmallTertiary}>
            <Label>{showContext ? t`Hide progress` : t`Show progress`}</Label>
          </ShowContextButton>
        )}
        <AiAvatar spin={spin} />
        <Content
          index={index}
          hiddenBlocks={hiddenBlocks}
          visibleBlocks={visibleBlocks}
          onMessageEdit={(edit) => onMessageEdit?.(edit, indexInOutput)}
          readOnly={isCurrentlyCompleting}
          showContext={showContext && showAdvancedOptions}
          addPlot={addPlot}
        />
        <RemoveButton
          Icon={Remove}
          variant={ButtonVariants.SmallTertiary}
          disabled={isCurrentlyCompleting}
          tint={theme.colors.grey[10]}
          onClick={() => onRemoveButtonClick?.(indexInOutput)}
        />
      </AssistantRow>
    ) : null;
  },
  dialogTurnPropsEqual,
);

const getProgressStatus = (messages: ChatMessage[]) => {
  if (messages.length === 0) return ProgressStatus.None;
  if (isAssistantSelfDebug(messages.length - 1, messages)) {
    return ProgressStatus.Fixing;
  }
  const lastMessage = messages[messages.length - 1];
  if (lastMessage.role === ChatMessageRole.Function) {
    switch (lastMessage.functionName) {
      case 'build_model':
        return ProgressStatus.Building;
      case 'build_group':
        return ProgressStatus.Building;
      case 'execute_python':
        return ProgressStatus.AnalyzingResults;
      case 'plot':
        return ProgressStatus.Plotting;
      case 'run_simulation':
        return ProgressStatus.RunningSimulation;
    }
  }
  return ProgressStatus.None;
};

interface ChatContentProps {
  showAdvancedOptions: boolean;
  isCurrentlyCompleting: boolean;
  onRemoveButtonClick?: (index: number) => void;
  onMessageEdit?: (edit: MessageEdit, index: number) => void;
  output: ChatMessage[];
  plots: string[];
  addPlot: (plot: string) => number;
}

const ChatContent = ({
  showAdvancedOptions,
  isCurrentlyCompleting,
  onRemoveButtonClick,
  onMessageEdit,
  output,
  plots,
  addPlot,
}: ChatContentProps): ReactElement => {
  const { isAuthorized } = useAuthorizationCheck();

  const messageBlocks = React.useMemo(
    () =>
      convertToUiMessages(
        output,
        plots,
        showAdvancedOptions,
        isCurrentlyCompleting,
      ),
    [isCurrentlyCompleting, output, plots, showAdvancedOptions],
  );

  const progressStatus = getProgressStatus(output);

  const functionInProgress =
    output.length > 0 &&
    output[output.length - 1].role === ChatMessageRole.Function &&
    isCurrentlyCompleting;
  const replyingToFunctionError =
    output.length > 2 &&
    output[output.length - 1].role === ChatMessageRole.Assistant &&
    output[output.length - 2].role === ChatMessageRole.Function &&
    output[output.length - 2].functionHasError;

  const isProcessing =
    functionInProgress || (replyingToFunctionError && isCurrentlyCompleting);

  return (
    <ChatContentDiv data-test-id="chat-content-div">
      <WelcomeMessage />
      {messageBlocks.map((msg, index) => (
        <DialogTurn
          key={index}
          role={msg.role}
          index={index}
          hiddenBlocks={msg.blocks.hiddenBlocks}
          visibleBlocks={msg.blocks.visibleBlocks}
          indexInOutput={msg.indexInOutput}
          onMessageEdit={onMessageEdit}
          onRemoveButtonClick={onRemoveButtonClick}
          showAdvancedOptions={showAdvancedOptions}
          isCurrentlyCompleting={isCurrentlyCompleting}
          addPlot={addPlot}
          spin={
            isCurrentlyCompleting &&
            index === messageBlocks.length - 1 &&
            !isProcessing
          }
        />
      ))}
      {!isAuthorized && <CallCountExceededMessage />}
      {isProcessing && <ProcessingMessage status={progressStatus} />}
    </ChatContentDiv>
  );
};

export default memo(ChatContent);
