type CellFormatType = {
  value: string;
  label: string;
};

export enum FileType {
  PYTHON = 'python',
  TEXT = 'text',
  CSV = 'csv',
  NOTEBOOK = 'notebook',
  JSON = 'json',
  XML = 'xml',
}

export enum IframeState {
  LOADING,
  SERVER_STARTING,
  SERVER_STOPPED,
  READY,
  UNKNOWN,
  CONNECTION_FAILED,
  NOT_FOUND,
}

export const NOTEBOOK_FILES: {
  [type in FileType]: {
    name: string;
    hubType: string;
    extension: string;
    emptyFileName: string;
  };
} = {
  [FileType.CSV]: {
    name: 'CSV',
    hubType: 'file',
    extension: 'csv',
    emptyFileName: 'Blank CSV',
  },
  [FileType.JSON]: {
    name: 'JSON',
    hubType: 'file',
    extension: 'json',
    emptyFileName: 'Blank JSON',
  },
  [FileType.NOTEBOOK]: {
    name: 'Notebook',
    hubType: 'notebook',
    extension: 'ipynb',
    emptyFileName: 'Blank Notebook',
  },
  [FileType.PYTHON]: {
    name: 'Python',
    hubType: 'file',
    extension: 'py',
    emptyFileName: 'Blank Code',
  },
  [FileType.TEXT]: {
    name: 'Text',
    hubType: 'file',
    extension: 'txt',
    emptyFileName: 'Blank Text',
  },
  [FileType.XML]: {
    name: 'XML',
    hubType: 'file',
    extension: 'xml',
    emptyFileName: 'Blank XML',
  },
};

export type SingleFiledata = {
  el: Element;
  name: string;
  type: FileType;
  createdAt?: string;
  updatedAt?: string;
  open: () => void;
};

export type Filedata = {
  [k: string]: SingleFiledata;
};

export type notebookStateType = {
  cellFormat: CellFormatType;
  setCellFormat: (newCellFormat: CellFormatType) => void;
};

export type Notebookdata = {
  name: string;
  type: string;
};

export enum HubContentType {
  FILE = 'file',
  NOTEBOOK = 'notebook',
  DIRECTORY = 'directory',
}
enum HubContentFormat {
  TEXT = 'text',
  BASE64 = 'base64',
  JSON = 'json',
}

export interface HubContent {
  content?: HubContent[];
  created: string;
  format: HubContentFormat;
  last_modified: string;
  mimetype?: string;
  name: string;
  path: string;
  size?: string;
  type: HubContentType;
}

export interface Kernel {
  connections: number;
  execution_state: string;
  id: string;
  last_activity: string;
  name: string;
}

export interface HubSession {
  id: string;
  kernel: Kernel;
  name: string;
  path: string;
  type: string;
}
