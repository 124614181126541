/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Layers: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.7663 5.63823C11.9125 5.56096 12.0875 5.56096 12.2336 5.63823L19.649 9.55796C20.0041 9.74567 20.0041 10.2543 19.649 10.442L12.2336 14.3618C12.0875 14.439 11.9125 14.439 11.7663 14.3618L4.35096 10.442C3.99585 10.2543 3.99585 9.74567 4.35096 9.55796L11.7663 5.63823ZM6.72447 10L12 7.21139L17.2755 10L12 12.7886L6.72447 10Z"
      fill={props.fill}
    />
    <path
      d="M6.45377 12.4317C6.59996 12.3544 6.7749 12.3544 6.92109 12.4317L7.45605 12.7145C7.81117 12.9022 7.81117 13.4109 7.45605 13.5986L6.72447 13.9853L12 16.7739L17.2755 13.9853L16.5439 13.5986C16.1888 13.4109 16.1888 12.9022 16.5439 12.7145L17.0789 12.4317C17.2251 12.3544 17.4 12.3544 17.5462 12.4317L19.649 13.5432C20.0041 13.731 20.0041 14.2396 19.649 14.4273L12.2336 18.3471C12.0875 18.4243 11.9125 18.4243 11.7663 18.3471L4.35096 14.4273C3.99585 14.2396 3.99585 13.731 4.35096 13.5432L6.45377 12.4317Z"
      fill={props.fill}
    />
  </svg>
);

Layers.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Layers;
