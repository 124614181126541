// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * A unique ID to represent a specific resource of any kind
 */
export type UUID = string;
/**
 * Either a block or a submodel nodes
 */
export type NodeInstance =
  | BlockInstance
  | StateMachineBlockInstance
  | SubmodelInstance;
/**
 * Fully classified class of a block, including namespace
 */
export type BlockClassName =
  | 'core.Abs'
  | 'core.Adder'
  | 'core.BatteryCell'
  | 'core.BusCreator'
  | 'core.BusSelector'
  | 'core.CFunction'
  | 'core.Chirp'
  | 'core.Clock'
  | 'core.CodeBlockDev'
  | 'core.Comparator'
  | 'core.Conditional'
  | 'core.Constant'
  | 'core.CoordinateRotation'
  | 'core.CoordinateRotationConversion'
  | 'core.CosineWave'
  | 'core.CppFunction'
  | 'core.CrossProduct'
  | 'core.DataSource'
  | 'core.DeadZone'
  | 'core.Delay'
  | 'core.Demux'
  | 'core.Derivative'
  | 'core.DerivativeDiscrete'
  | 'core.DiscreteInitializer'
  | 'core.DotProduct'
  | 'core.DriveCycle'
  | 'core.EdgeDetection'
  | 'core.ExperimentIterator'
  | 'core.ExperimentModel'
  | 'core.Exponent'
  | 'core.FilterDiscrete'
  | 'core.Gain'
  | 'core.Generic'
  | 'core.Group'
  | 'core.IfThenElse'
  | 'core.ImageSegmentation'
  | 'core.ImageSource'
  | 'core.Inport'
  | 'core.Integrator'
  | 'core.IntegratorDiscrete'
  | 'core.Iterator'
  | 'core.LinearizedSystem'
  | 'core.Log'
  | 'core.LogicalOperator'
  | 'core.LookupTable1d'
  | 'core.LookupTable2d'
  | 'core.LoopBreak'
  | 'core.LoopCounter'
  | 'core.LoopMemory'
  | 'core.MatrixConcatenation'
  | 'core.MatrixInversion'
  | 'core.MatrixMultiplication'
  | 'core.MatrixOperator'
  | 'core.MatrixTransposition'
  | 'core.MinMax'
  | 'core.ModelicaFMU'
  | 'core.Mux'
  | 'core.ObjectDetection'
  | 'core.Offset'
  | 'core.Outport'
  | 'core.PID'
  | 'core.PID_Discrete'
  | 'core.Power'
  | 'core.Predictor'
  | 'core.Product'
  | 'core.ProductOfElements'
  | 'core.Pulse'
  | 'core.PythonScript'
  | 'core.Quantizer'
  | 'core.Ramp'
  | 'core.RandomNormal'
  | 'core.RateLimiter'
  | 'core.Reciprocal'
  | 'core.ReferenceSubmodel'
  | 'core.Relay'
  | 'core.Replicator'
  | 'core.RigidBody'
  | 'core.Saturate'
  | 'core.Sawtooth'
  | 'core.ScalarBroadcast'
  | 'core.SignalDatatypeConversion'
  | 'core.SineWave'
  | 'core.Slice'
  | 'core.SquareRoot'
  | 'core.Stack'
  | 'core.StateMachine'
  | 'core.StateSpace'
  | 'core.Step'
  | 'core.Stop'
  | 'core.Submodel'
  | 'core.SumOfElements'
  | 'core.TransferFunction'
  | 'core.TransferFunctionDiscrete'
  | 'core.Trigonometric'
  | 'core.UnitDelay'
  | 'core.VideoSink'
  | 'core.VideoSource'
  | 'core.ZeroOrderHold';
/**
 * Parameters to configure this instance. Allowed properties depend on the class of the block.
 */
export type ParameterList = Param[];
/**
 * A unique ID to represent a specific resource of any kind
 */
export type UUID1 = string;
/**
 * A unique ID to represent a specific resource of any kind
 */
export type UUID2 = string;
export type VersionTag = 'latest' | 'latest_tagged';
/**
 * A unique ID to represent a specific resource of any kind
 */
export type UUID3 = string;
/**
 * Describes the type of link in terms of whether it's connected directly to a node or tapped from a link. Link UUID is specified in the case of being (visually) tapped from an orphaned (unconnected) link in the model.
 */
export type LinkTypeType =
  | {
      connection_method: 'direct_to_block';
    }
  | {
      connection_method: 'link_tap';
      /**
       * The UUID of the tapped link.
       */
      tapped_link_uuid: string;
      /**
       * Descriptor for the semantic visual type of link
       */
      tapped_segment:
        | {
            segment_type: FakeTappedSegmentType;
            tapped_segment_direction?: 'horiz' | 'vert';
          }
        | {
            segment_type: 'real';
            /**
             * The index of the segment in the tapped link.
             */
            tapped_segment_index: number;
            tapped_segment_direction?: 'horiz' | 'vert';
          };
      /**
       * The visual location coordinate for the tap's starting point (Y if vertical segment, X if horizontal segment). The other coordinate will be derived from the segment's defined location.
       */
      tap_coordinate: number;
    };
export type FakeTappedSegmentType =
  | 'sstart'
  | 'smiddle'
  | 'send'
  | 'start'
  | 'end';
/**
 * Block parameter value override by block id and param name, only valid for a specific simulation run
 */
export type BlockParameterOverrides = {
  block_uuid: UUID;
  /**
   * Block parameter name
   */
  parameter_name: string;
  /**
   * Block parameter expression (currently only supports hashed files)
   */
  expression: string;
  /**
   * Set to true to intepret this value as a string, without any evaluation
   */
  is_string?: boolean;
}[];
/**
 * A unique ID to represent a specific resource of any kind
 */
export type UUID4 = string;
/**
 * A unique ID to represent a specific resource of any kind
 */
export type UUID5 = string;
/**
 * A unique ID to represent a specific resource of any kind
 */
export type UUID6 = string;
/**
 * A unique ID to represent a specific resource of any kind
 */
export type UUID7 = string;
/**
 * List of parameter definitions.
 */
export type ParameterDefinitions = ParameterDefinition[];

/**
 * Top-level simulation model
 */
export interface SimulationModel {
  /**
   * Schema version
   */
  $schema_version: '0.1';
  uuid: UUID;
  /**
   * Name of this model
   */
  name: string;
  diagram: ModelDiagram;
  configuration: ModelConfiguration;
  parameters: Parameters;
  parameter_list?: ParameterList;
  overrides?: BlockParameterOverrides;
  linearization?: LinearizationConfig;
  codegen?: CodeGenerationConfig;
  fmucosim?: FMUExportConfig;
  submodels: SubmodelsSection;
  state_machines?: StateMachines;
  reference_submodels?: ReferenceSubmodels;
}
/**
 * Model diagram with nodes (blocks or submodels) and links, this represents a single plane but can point to submodels using a recursive definition
 */
export interface ModelDiagram {
  /**
   * List of nodes in this model, which are either blocks or submodels
   */
  nodes: NodeInstance[];
  /**
   * List of directional links in this model
   */
  links: LinkInstance[];
  /**
   * List of annotations in this model
   */
  annotations?: AnnotationInstance[];
  /**
   * Unique ID of this model diagram, optional
   */
  uuid?: string;
}
/**
 * Representation of a computation block instance and its parameters
 */
export interface BlockInstance {
  /**
   * Block class version to use
   */
  $class_version?: string;
  uuid: UUID;
  original_uuid?: UUID;
  /**
   * Name of this block
   */
  name: string;
  type: BlockClassName;
  inputs: Port[];
  outputs: Port[];
  file_outputs?: FileOutput[];
  parameters: Parameters;
  parameter_list?: ParameterList;
  /**
   * For (source) blocks where `modes.time` is `any`, selects whether to force discrete output.
   */
  time_mode?: 'agnostic' | 'discrete';
  uiprops: NodeUIProps;
}
/**
 * A single I/O port on a node
 */
export interface Port {
  /**
   * Builtin or user-given name of the port
   */
  name: string;
  /**
   * How the port was created, affects various behaviors
   */
  kind?: 'static' | 'dynamic' | 'conditional';
  parameters?:
    | Parameters
    | {
        [k: string]: string | undefined;
      };
  /**
   * When the global record_mode is not 'all', this enables this output to be recorded.
   */
  record?: boolean;
}
/**
 * Parameters to configure this instance. Allowed properties depend on the class of the block.
 */
export interface Parameters {
  [k: string]: Parameter | undefined;
}
/**
 * A single parameters and its value
 *
 * This interface was referenced by `Parameters`'s JSON-Schema definition
 * via the `patternProperty` "[a-z][a-z0-9_]*".
 */
export interface Parameter {
  /**
   * Value of the parameter, following python-like syntax
   */
  value: string;
  /**
   * Set to true to intepret this value as a string, without any evaluation
   */
  is_string?: boolean;
  /**
   * Where the parameter comes from: the block class, or added as extra by the user
   */
  source?: 'class' | 'extra';
  /**
   * Order of the parameter in the block, optional
   */
  order?: number;
}
/**
 * Description of a file output
 */
export interface FileOutput {
  /**
   * Filename which may contain identifier {{block_name}} that will be replaced by the block's name. Files should be saved in artifacts folder of the simulation folder by the block.
   */
  filename?: string;
  /**
   * MIME type.
   */
  type?: string;
}
/**
 * A single parameters and its value for use in ordered lists
 */
export interface Param {
  /**
   * Name of the parameter, following python-like syntax
   */
  name: string;
  /**
   * Value of the parameter, following python-like syntax
   */
  value: string;
  /**
   * Set to true to intepret this value as a string, without any evaluation
   */
  is_string?: boolean;
}
/**
 * Node UI-specific properties that need to be persisted (eg. color, shape, style, etc...)
 */
export interface NodeUIProps {
  /**
   * Visual X coordinate of this block in the model
   */
  x: number;
  /**
   * Visual Y coordinate of this block in the model
   */
  y: number;
  /**
   * Manually defined visual height in grid-space units (unused if smaller than the minimum height based on other factors).
   */
  grid_height?: number;
  /**
   * Manually defined visual width in grid-space units (unused if smaller than the minimum width based on other factors).
   */
  grid_width?: number;
  /**
   * Visual location of the block's label
   */
  label_position?: 'bottom' | 'top';
  /**
   * Visual location of the block's label
   */
  directionality?: 'left' | 'right';
  /**
   * Flag that determines whether the block's port names will show within the block
   */
  show_port_name_labels?: boolean;
  /**
   * Visual spacing option for the block's ports
   */
  port_alignment?: 'spaced' | 'top' | 'center' | 'bottom';
  /**
   * Height of the parameter value textarea for each parameter name
   */
  parameter_heights?: {
    [k: string]: string | undefined;
  };
}
/**
 * Representation of a state machine block instance and its parameters
 */
export interface StateMachineBlockInstance {
  /**
   * Block class version to use
   */
  $class_version?: string;
  uuid: UUID;
  original_uuid?: UUID;
  /**
   * Name of this block
   */
  name: string;
  /**
   * Fully classified class of this block, including namespace
   */
  type: 'core.StateMachine';
  inputs: Port[];
  outputs: Port[];
  file_outputs?: FileOutput[];
  state_machine_diagram_id?: string;
  parameters: Parameters;
  parameter_list?: ParameterList;
  /**
   * For (source) blocks where `modes.time` is `any`, selects whether to force discrete output.
   */
  time_mode?: 'agnostic' | 'discrete';
  uiprops: NodeUIProps;
}
/**
 * Representation of a submodel instance and its parameters
 */
export interface SubmodelInstance {
  uuid: UUID;
  original_uuid?: UUID;
  submodel_reference_uuid?: UUID1;
  submodel_reference_version?: UUID2 | VersionTag;
  /**
   * User given name of this submodel
   */
  name: string;
  /**
   * Fully classified class of this block, including namespace
   */
  type:
    | 'core.Submodel'
    | 'core.ReferenceSubmodel'
    | 'core.Group'
    | 'core.Iterator'
    | 'core.Replicator'
    | 'core.Conditional'
    | 'core.LinearizedSystem';
  inputs: Port[];
  outputs: Port[];
  parameters: Parameters;
  configuration?: SubmodelConfiguration;
  uiprops: NodeUIProps;
}
/**
 * Submodel properties like init scripts, fixed discrete step; but not parameters.
 */
export interface SubmodelConfiguration {
  /**
   * Init script to run before the model is used.
   */
  init_script?: Parameter;
  /**
   * Simulation time interval between discrete steps.
   */
  discrete_step?: Parameter;
  /**
   * Whether this submodel should be scheduled for computation as if it was a single (atomic) operation.
   */
  atomic?: Parameter;
}
/**
 * Connection between two nodes
 */
export interface LinkInstance {
  uuid: UUID;
  name?: string;
  src?: PortConnection;
  dst?: PortConnection1;
  uiprops: LinkUIProps;
}
/**
 * Points to a node's I/O port
 */
export interface PortConnection {
  node: UUID3;
  /**
   * Port ID for sequentially numbered ports
   */
  port: number;
}
/**
 * Points to a node's I/O port
 */
export interface PortConnection1 {
  node: UUID3;
  /**
   * Port ID for sequentially numbered ports
   */
  port: number;
}
/**
 * Link UI-specific properties that need to be persisted (eg. color, shape, style, etc...)
 */
export interface LinkUIProps {
  link_type: LinkTypeType;
  segments: LinkSegmentType[];
  /**
   * The coordinate of the visual beginning of the link when it does not have a source port connection.
   */
  hang_coord_start?: {
    x: number;
    y: number;
  };
  /**
   * The coordinate of the visual ending of the link when it does not have a source port connection.
   */
  hang_coord_end?: {
    x: number;
    y: number;
  };
}
export interface LinkSegmentType {
  /**
   * The type (direction) of line segment to draw
   */
  segment_direction: 'horiz' | 'vert';
  /**
   * The X (if vert) or Y (if horiz) coordinate of this segment.
   */
  coordinate: number;
}
/**
 * Representation of an annotation instance and its parameters
 */
export interface AnnotationInstance {
  uuid: UUID;
  /**
   * Text caption for this annotation.
   */
  text: string;
  /**
   * Visual X coordinate of this annotation in the model
   */
  x: number;
  /**
   * Visual Y coordinate of this annotation in the model
   */
  y: number;
  /**
   * Manually defined visual height in grid-space units.
   */
  grid_height: number;
  /**
   * Manually defined visual width in grid-space units.
   */
  grid_width: number;
  /**
   * ID corresponding to a color definition for the background of the annotation.
   */
  color_id?: string;
  /**
   * Visual location of the annotation's label
   */
  label_position?: 'bottom' | 'top' | 'inside';
}
/**
 * Default simulation configuration for a given model
 */
export interface ModelConfiguration {
  /**
   * Time at which the simulation stops.
   */
  stop_time: number;
  /**
   * The discrete step or update interval for all discrete blocks in the model.
   */
  sample_time?: number;
  /**
   * The time interval at which results samples are computed for blocks with time_mode=continuous (Optional)
   */
  continuous_time_result_interval?: number;
  /**
   * Configure handling of events. none=globally disable for all blocks. normal=enables events and zeno handling.
   */
  events_handling?: 'normal' | 'none';
  solver?: {
    /**
     * The ODE solver to use for continuous blocks in the model.
     */
    method?: 'RK45' | 'BDF';
    /**
     * The mechanism by which steps size is determined by the ODE solver.
     */
    type?: 'fixed_step' | 'variable_step';
    /**
     * Size of the first step taken by ODE solver.
     */
    first_step?: number;
    /**
     * Maximum size of an ODE solver step.
     */
    max_step?: number;
    /**
     * Minimum size of an ODE solver step.
     */
    min_step?: number;
    /**
     * Fixed size of an ODE solver step.
     */
    fixed_step?: number;
    /**
     * Relative tolerance for the ODE solver error estimation.
     */
    relative_tolerance?: number;
    /**
     * Absolute tolerance for the ODE solver error estimation.
     */
    absolute_tolerance?: number;
  };
  /**
   * Whether to record outputs of all blocks, or only the outputs of selected blocks.
   */
  record_mode?: 'all' | 'selected';
  workspace?: WorkspaceConfig;
  /**
   * Instance type of the worker to run the simulation on.
   */
  worker_type?: 'any' | 'cpu' | 'gpu';
  __developer_options?: {
    disable_firejail?: boolean;
    use_lynx?: boolean;
  };
}
/**
 * Defines the environment within which the simulation will run
 */
export interface WorkspaceConfig {
  /**
   * A list of initialization script files to run.
   */
  init_scripts?: DataFile[];
}
/**
 * Represents a file used at simulation time
 */
export interface DataFile {
  /**
   * Name of the file
   */
  file_name: string;
}
/**
 * Configuration required for linearizing a submodel
 */
export interface LinearizationConfig {
  submodel_uuid: UUID;
}
/**
 * Configuration required for generating C code from a submodel
 */
export interface CodeGenerationConfig {
  submodel_uuid?: UUID;
  submodel_uuid_path?: UUID[];
  /**
   * Name of the submodel form which code is to be generated
   */
  name: string;
}
/**
 * Configuration required for export an FMU from a submodel
 */
export interface FMUExportConfig {
  submodel_uuid?: UUID;
  submodel_uuid_path?: UUID[];
  /**
   * Name of the submodel form which FMU is to be generated
   */
  name: string;
}
/**
 * Section of SimulationModel that contains references and diagrams of submodels
 */
export interface SubmodelsSection {
  /**
   * Map of submodel UUID to their hierarchical info and other metadata
   */
  references: {
    [k: string]: SubmodelReference | undefined;
  };
  /**
   * Map of diagram UUID to their contents
   */
  diagrams: {
    [k: string]: ModelDiagram | undefined;
  };
}
/**
 * Reference to a separately-stored submodel
 */
export interface SubmodelReference {
  diagram_uuid: UUID4;
}
/**
 * Map of state machine diagram UUIDs to their hierarchical info and other metadata
 */
export interface StateMachines {
  [k: string]: StateMachineDiagram | undefined;
}
/**
 * The description of a state machine document
 */
export interface StateMachineDiagram {
  uuid: UUID5;
  nodes: StateNodeInstance[];
  links: StateLinkInstance[];
  entry_point: {
    actions?: string[];
    dest_id?: string;
    dest_side?: 'top' | 'right' | 'down' | 'left';
    dest_coord?: number;
  };
}
export interface StateNodeInstance {
  uuid: UUID6;
  /**
   * The name of the state node
   */
  name: string;
  /**
   * The action to perform when the state is entered
   */
  actions?: string;
  subdiagram_uuid?: string;
  /**
   * A list of IDs of exit transitions, in order visually (clockwise)
   */
  exit_priority_list: string[];
  uiprops: {
    x: number;
    y: number;
  };
}
export interface StateLinkInstance {
  uuid: UUID7;
  /**
   * The actions to perform when transitioning from a state
   */
  actions?: string[];
  /**
   * The guard to check when transitioning from a state
   */
  guard?: string;
  /**
   * The ID of the source state node
   */
  sourceNodeId?: string;
  /**
   * The ID of the destination state node
   */
  destNodeId?: string;
  uiprops: {
    sourceSide?: 'top' | 'right' | 'down' | 'left';
    destSide?: 'top' | 'right' | 'down' | 'left';
    sourceCoord?: number;
    destCoord?: number;
    /**
     * The point where the link will start if there is no source node associated
     */
    startPoint?: {
      x: number;
      y: number;
    };
    /**
     * The point where the link will end if there is no dest node associated
     */
    endPoint?: {
      x: number;
      y: number;
    };
    /**
     * The deviation of the link's internal bezier curve control points (used for visually routing links around things)
     */
    curveDeviation: {
      x: number;
      y: number;
    };
  };
}
/**
 * Map of submodel UUID to their hierarchical info and other metadata
 */
export interface ReferenceSubmodels {
  [k: string]: ReferenceSubmodel | undefined;
}
/**
 * Reference submodel
 */
export interface ReferenceSubmodel {
  /**
   * Schema version
   */
  $schema_version: '0.1';
  uuid: UUID;
  /**
   * Name of this model
   */
  name: string;
  diagram: ModelDiagram;
  parameters: ParameterDefinitions;
  submodels: SubmodelsSection;
}
/**
 * Definition for a single parameter, including everything needed to properly display the parameter in the UI, such as the name and the default value.
 */
export interface ParameterDefinition {
  uuid: UUID;
  /**
   * Name of the parameter
   */
  name: string;
  /**
   * Optional display name of the parameter
   */
  display_name?: string;
  /**
   * Default value of this parameter (pseudo-python syntax)
   */
  default_value: string;
  /**
   * Description of the parameter
   */
  description?: string;
  uiprops: ParameterDefinitionUIProps;
}
/**
 * Parameter Definition UI-specific properties that need to be persisted (eg. color, shape, style, etc...)
 */
export interface ParameterDefinitionUIProps {
  /**
   * Height of the default value textarea
   */
  default_value_height?: string;
  /**
   * Height of the description textarea
   */
  description_height?: string;
}

export const defaultModelConfiguration: ModelConfiguration = {
  stop_time: 10,
  sample_time: 0.1,
  continuous_time_result_interval: 0,
  events_handling: 'normal',
  solver: {
    method: 'RK45',
    type: 'variable_step',
    first_step: 0.0001,
    max_step: 1000000,
    min_step: 0,
    fixed_step: 0.01,
    relative_tolerance: 0.001,
    absolute_tolerance: 0.000001,
  },
  record_mode: 'all',
  worker_type: 'any',
  __developer_options: {
    disable_firejail: false,
    use_lynx: false,
  },
};

// Because TS types aren't runtime iterable, we create a corresponding array:
export const BlockClassNamesArray: BlockClassName[] = [
  'core.Conditional',
  'core.Abs',
  'core.Adder',
  'core.BatteryCell',
  'core.BusCreator',
  'core.BusSelector',
  'core.CFunction',
  'core.Chirp',
  'core.Clock',
  'core.CodeBlockDev',
  'core.Comparator',
  'core.Constant',
  'core.CoordinateRotation',
  'core.CoordinateRotationConversion',
  'core.CosineWave',
  'core.CppFunction',
  'core.CrossProduct',
  'core.DataSource',
  'core.DeadZone',
  'core.Delay',
  'core.Demux',
  'core.Derivative',
  'core.DerivativeDiscrete',
  'core.DiscreteInitializer',
  'core.DotProduct',
  'core.DriveCycle',
  'core.EdgeDetection',
  'core.ExperimentIterator',
  'core.ExperimentModel',
  'core.Exponent',
  'core.FilterDiscrete',
  'core.Gain',
  'core.Generic',
  'core.Group',
  'core.IfThenElse',
  'core.ImageSegmentation',
  'core.ImageSource',
  'core.Inport',
  'core.Integrator',
  'core.IntegratorDiscrete',
  'core.Iterator',
  'core.LinearizedSystem',
  'core.Log',
  'core.LogicalOperator',
  'core.LookupTable1d',
  'core.LookupTable2d',
  'core.LoopBreak',
  'core.LoopCounter',
  'core.LoopMemory',
  'core.MatrixConcatenation',
  'core.MatrixInversion',
  'core.MatrixMultiplication',
  'core.MatrixOperator',
  'core.MatrixTransposition',
  'core.MinMax',
  'core.ModelicaFMU',
  'core.Mux',
  'core.ObjectDetection',
  'core.Offset',
  'core.Outport',
  'core.PID',
  'core.PID_Discrete',
  'core.Power',
  'core.Predictor',
  'core.Product',
  'core.ProductOfElements',
  'core.Pulse',
  'core.PythonScript',
  'core.Quantizer',
  'core.Ramp',
  'core.RandomNormal',
  'core.RateLimiter',
  'core.Reciprocal',
  'core.ReferenceSubmodel',
  'core.Relay',
  'core.Replicator',
  'core.RigidBody',
  'core.Saturate',
  'core.Sawtooth',
  'core.ScalarBroadcast',
  'core.SignalDatatypeConversion',
  'core.SineWave',
  'core.Slice',
  'core.SquareRoot',
  'core.Stack',
  'core.StateMachine',
  'core.StateSpace',
  'core.Step',
  'core.Stop',
  'core.Submodel',
  'core.SumOfElements',
  'core.TransferFunction',
  'core.TransferFunctionDiscrete',
  'core.Trigonometric',
  'core.UnitDelay',
  'core.VideoSink',
  'core.VideoSource',
  'core.ZeroOrderHold',
];
