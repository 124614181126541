import { useAppDispatch } from 'app/hooks';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import Textarea from 'ui/common/Textarea';
import { DetailsSection } from 'ui/modelEditor/DetailsComponents';
import inputStringCallback from 'util/inputStringCallback';

interface Props {
  testId: string;
  placeholder: string;
  savedDescription: string;
  saveDescription: (newDescription: string) => void;
  canEdit: boolean;
  className?: string;
}

const DescriptionField: React.FC<Props> = ({
  testId,
  placeholder,
  savedDescription,
  saveDescription,
  canEdit,
  className,
}) => {
  const dispatch = useAppDispatch();

  const [currentDescription, setCurrentDescription] =
    React.useState<string>(savedDescription);

  React.useEffect(() => {
    setCurrentDescription(savedDescription);
  }, [savedDescription]);

  const sendDescriptionUpdate = React.useCallback(() => {
    dispatch(uiFlagsActions.setUIFlag({ textInputFocused: false }));

    if (savedDescription !== currentDescription) {
      saveDescription(currentDescription);
    }
  }, [dispatch, saveDescription, currentDescription, savedDescription]);

  return (
    <DetailsSection vertical>
      <Textarea
        resizable={false}
        testId={testId}
        rows={4}
        placeholder={placeholder}
        value={currentDescription}
        onChange={inputStringCallback(setCurrentDescription)}
        onBlur={sendDescriptionUpdate}
        onFocus={() =>
          dispatch(uiFlagsActions.setUIFlag({ textInputFocused: true }))
        }
        disabled={!canEdit}
        className={className}
      />
    </DetailsSection>
  );
};

export default DescriptionField;
