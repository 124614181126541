import React from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ChevronLeft, ChevronRight } from 'ui/common/Icons/Small';
import {
  LeftExpandCollapseButton,
  LeftSidebar,
} from 'ui/common/layout/appLayout';
import { ModelLeftSidebarContent } from 'ui/modelEditor/ModelLeftSidebarContent';

const ModelLeftSidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const isExpanded = useAppSelector((state) => state.uiFlags.isLeftSidebarOpen);
  const setIsExpanded = (isExpanded: boolean) => {
    dispatch(uiFlagsActions.setUIFlag({ isLeftSidebarOpen: isExpanded }));
  };

  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  return (
    <>
      <LeftSidebar isExpanded={isExpanded} className="tour-onboarding-4">
        {loadedModelId && <ModelLeftSidebarContent />}
      </LeftSidebar>
      <LeftExpandCollapseButton
        testId="toggle-model-left-sidebar-button"
        Icon={isExpanded ? ChevronLeft : ChevronRight}
        variant={ButtonVariants.LargeSecondary}
        onClick={() => setIsExpanded(!isExpanded)}
      />
    </>
  );
};

export default ModelLeftSidebar;
