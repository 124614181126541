import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useProject } from 'app/api/useProject';
import { useAppSelector } from 'app/hooks';
import { NavbarContext } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { Requirements } from 'ui/common/Icons/Small';
import { Small } from 'ui/common/typography/Typography';
import { HelpButton } from 'ui/navbar/HelpButton';
import { useProjectPermission } from 'ui/permission/useProjectPermission';
import RequirementsImportButton from 'ui/requirements/RequirementsImportButton';
import { useAppParams } from 'util/useAppParams';
import ClerkButton from './ClerkButton';
import HomeButton from './HomeButton';
import { NavbarSpace } from './NavbarButtons';
import {
  LeftRegion,
  NavbarRegion,
  NavbarWrapper,
  RightRegion,
} from './NavbarCommon';

const RequirementsBreadcrumbText = styled(Small)`
  color: ${({ theme }) => theme.colors.grey[5]};
`;
const RequirementsBreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RequirementsBreadcrumb: React.FC = () => {
  const theme = useTheme();

  return (
    <RequirementsBreadcrumbWrapper>
      <Requirements fill={theme.colors.grey[5]} />;
      <RequirementsBreadcrumbText>Requirements</RequirementsBreadcrumbText>;
    </RequirementsBreadcrumbWrapper>
  );
};

// TODO: refactor in UI-1226
export const RequirementsNavbar: React.FC = () => {
  const navbarContext = useAppSelector((state) => state.uiFlags.navbarContext);

  const { projectId } = useAppParams();
  const { project } = useProject();

  // Still need to do validate project bc it's not under `ValidateRequirements`.. yet.
  const { canEditProject } = useProjectPermission(projectId);

  return (
    <NavbarWrapper className="tour-onboarding-0">
      {/* Left Navbar Region */}
      <LeftRegion>
        <HomeButton />
        <NavbarSpace />
        <RequirementsBreadcrumb />
      </LeftRegion>

      {/* Center Navbar Region */}
      <NavbarRegion>
        {navbarContext === NavbarContext.Requirements && project && (
          <RequirementsImportButton
            projectUuid={project.uuid}
            enabled={canEditProject}
          />
        )}
      </NavbarRegion>

      {/* Right Navbar Region */}
      <RightRegion>
        <HelpButton />
        <ClerkButton />
      </RightRegion>
    </NavbarWrapper>
  );
};
