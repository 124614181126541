import styled from '@emotion/styled/macro';
import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import { MouseActions } from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import {
  AnnotationInstance,
  BlockClassName,
  LinkInstance,
  ModelDiagram,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cameraActions } from 'app/slices/cameraSlice';
import { ErrorsState } from 'app/slices/errorsSlice';
import { selectCurrentSubdiagramType } from 'app/slices/modelSlice';
import { UIFlagsState, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { UserOptions } from 'app/slices/userOptionsSlice';
import React, { MutableRefObject, ReactElement } from 'react';
import {
  NavigateFunction,
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { ActionCreators as UndoRedoActionCreators } from 'redux-undo';
import { CODE_EDITOR_BLOCK_QUERY_PARAM } from 'ui/codeEditor/CodeEditor';
import { DragContext } from 'ui/dragdrop/DragProvider';
import { RendererOverlay } from 'ui/modelEditor/RendererOverlay';
import { useVisualizerPrefs } from 'ui/modelEditor/useVisualizerPrefs';
import {
  PortConnLUTType,
  endNanovg,
  externallySetRendererTransform,
  initModelRenderer,
  rendererState,
  unregisterRendererEvents,
} from 'ui/modelRendererInternals/modelRenderer';
import { transitionMouseState } from 'ui/modelRendererInternals/transitionMouseState';
import { BlockDisplayData } from 'ui/objectBrowser/BlockDisplayData';

const ModelRenderer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(240, 242, 242, 1);
  pointer-events: auto;

  > canvas {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

export type TransformFunc = (transform: {
  x: number;
  y: number;
  zoom: number;
}) => void;

type SetSearchParamsFunctionSig = (
  nextInit: URLSearchParamsInit,
  navigateOptions?: { replace?: boolean | undefined; state?: any } | undefined,
) => void;

export type RendererRefsType = {
  parent: HTMLDivElement | null;
  canvas: HTMLCanvasElement | null;
  externalOverlay: HTMLDivElement | null;
  undoFunc: () => void;
  redoFunc: () => void;
  nodes: NodeInstance[];
  links: LinkInstance[];
  annotations: AnnotationInstance[];
  selectedNodeIds: string[];
  selectedLinkIds: string[];
  selectedAnnotationIds: string[];
  nodesIndexLUT: { [k: string]: number };
  linksIndexLUT: { [k: string]: number };
  annotationsIndexLUT: { [k: string]: number };
  connectedPortLUT: PortConnLUTType;
  linksRenderingDependencyTree: {
    [uuid: string]: string[];
  };
  codeEditorOpen: boolean;
  visualizerPrefs: ReturnType<typeof useVisualizerPrefs>;
  uiFlags: UIFlagsState;
  canEditModel: boolean;
  userOptions: UserOptions;
  modelKind: ModelKind | null;
  searchParams: URLSearchParams;
  setSearchParams: SetSearchParamsFunctionSig;
  navigate: NavigateFunction;
  errorsState: ErrorsState;
  currentSubdiagramType: BlockClassName | undefined;
  constantBlockDisplayValues: { [blockUuid: string]: string };
};

const constBlockValueMemory: { [nodeUuid: string]: string } = {};
const constBlockCleanedValueMemory: { [nodeUuid: string]: string } = {};

const cleanConstantBlockValue = (val: string) => val.replaceAll('\n', '');

const memoizedCleanedConstBlockValue = (node: NodeInstance) => {
  const paramVal = node.parameters.value?.value;
  if (node.type === 'core.Constant' && paramVal) {
    if (paramVal == constBlockValueMemory[node.uuid]) {
      return constBlockCleanedValueMemory[node.uuid] || '';
    }

    const cleanVal = cleanConstantBlockValue(paramVal);
    constBlockValueMemory[node.uuid] = paramVal;
    constBlockCleanedValueMemory[node.uuid] = cleanVal;
    return cleanVal;
  }

  return '';
};

export const ModelRendererWrapper = ({
  setTransform,
  currentDiagram,
  isDiagramReadonly,
  externalOverlayRef,
}: {
  setTransform: TransformFunc;
  currentDiagram: ModelDiagram | null;
  isDiagramReadonly: boolean;
  externalOverlayRef: MutableRefObject<HTMLDivElement | null>;
}): ReactElement => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const visualizerPrefs = useVisualizerPrefs();

  const dragContextData = React.useContext(DragContext);

  const uiFlags = useAppSelector((state) => state.uiFlags);

  const [searchParams, setSearchParams] = useSearchParams();
  // just FYI this component is currently not even mounted when the code editor is open.
  // this is future-proofing for if we end up keeping the model editor mounted
  // underneath the code editor (to eventually save on re-instancing webgl etc.)
  const codeEditorOpen = !!searchParams.get(CODE_EDITOR_BLOCK_QUERY_PARAM);

  const errorsState = useAppSelector((state) => state.errorsSlice);

  // TODO figure out a way to do this without useMemo
  const rawModelNodes = React.useMemo(
    () => currentDiagram?.nodes || [],
    [currentDiagram?.nodes],
  );
  const rawModelLinks = React.useMemo(
    () => currentDiagram?.links || [],
    [currentDiagram?.links],
  );
  const rawModelAnnotations = React.useMemo(
    () => currentDiagram?.annotations || [],
    [currentDiagram?.annotations],
  );

  const selectedNodeIds = useAppSelector(
    (state) => state.model.present.selectedBlockIds,
  );

  const selectedLinkIds = useAppSelector(
    (state) => state.model.present.selectedLinkIds,
  );

  const selectedAnnotationIds = useAppSelector(
    (state) => state.model.present.selectedAnnotationIds,
  );

  const userOptions = useAppSelector((state) => state.userOptions.options);
  const modelKind = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  const currentSubdiagramType = useAppSelector((state) =>
    selectCurrentSubdiagramType(state.model.present),
  );

  const refsObject = React.useRef<RendererRefsType>({
    parent: null,
    canvas: null,
    externalOverlay: null,
    undoFunc: () => {},
    redoFunc: () => {},
    nodes: rawModelNodes,
    links: rawModelLinks,
    annotations: rawModelAnnotations,
    selectedNodeIds,
    selectedLinkIds,
    selectedAnnotationIds,
    nodesIndexLUT: {},
    linksIndexLUT: {},
    annotationsIndexLUT: {},
    connectedPortLUT: {},
    linksRenderingDependencyTree: {},
    codeEditorOpen,
    visualizerPrefs,
    uiFlags,
    canEditModel: !isDiagramReadonly,
    userOptions,
    modelKind,
    searchParams,
    setSearchParams,
    navigate,
    errorsState: { rootNode: { children: {} } },
    currentSubdiagramType,
    constantBlockDisplayValues: {},
  });

  // FIXME: should be stable after v6.11 https://github.com/remix-run/react-router/issues/7634
  React.useEffect(() => {
    refsObject.current.navigate = navigate;
  }, [navigate]);

  React.useEffect(() => {
    refsObject.current.currentSubdiagramType = currentSubdiagramType;
  }, [currentSubdiagramType]);

  React.useEffect(() => {
    refsObject.current.setSearchParams = setSearchParams;
  }, [setSearchParams]);

  React.useEffect(() => {
    refsObject.current.searchParams = searchParams;
  }, [searchParams]);

  React.useEffect(() => {
    refsObject.current.errorsState = errorsState;
  }, [errorsState]);

  React.useEffect(() => {
    refsObject.current.modelKind = modelKind;
  }, [modelKind]);

  React.useEffect(() => {
    refsObject.current.visualizerPrefs = visualizerPrefs;
  }, [visualizerPrefs]);

  React.useEffect(() => {
    refsObject.current.userOptions = userOptions;
  }, [userOptions]);

  React.useEffect(() => {
    refsObject.current.codeEditorOpen = codeEditorOpen;
  }, [codeEditorOpen]);

  React.useEffect(() => {
    refsObject.current.selectedNodeIds = selectedNodeIds;
  }, [selectedNodeIds]);

  React.useEffect(() => {
    refsObject.current.selectedLinkIds = selectedLinkIds;
  }, [selectedLinkIds]);

  React.useEffect(() => {
    refsObject.current.selectedAnnotationIds = selectedAnnotationIds;
  }, [selectedAnnotationIds]);

  React.useEffect(() => {
    refsObject.current.uiFlags = uiFlags;
  }, [uiFlags]);

  React.useEffect(() => {
    if (!rendererState) return;

    if (uiFlags.addingAnnotation) {
      transitionMouseState(rendererState, {
        state: MouseActions.ReadyToDefineAnnotation,
      });
    } else {
      // remember that this only fires when 'addingAnnotation' changes,
      // so it's fine to do this here to ensure we return to 'Idle'
      // when the user manually toggles off the annotation mode.
      transitionMouseState(rendererState, {
        state: MouseActions.Idle,
      });
    }
  }, [uiFlags.addingAnnotation]);

  React.useEffect(() => {
    refsObject.current.canEditModel = !isDiagramReadonly;
  }, [isDiagramReadonly]);

  React.useEffect(() => {
    refsObject.current.nodes = rawModelNodes;

    refsObject.current.nodesIndexLUT = rawModelNodes.reduce((acc, node, i) => {
      if (node.type === 'core.Constant') {
        refsObject.current.constantBlockDisplayValues[node.uuid] =
          memoizedCleanedConstBlockValue(node);
      }
      return { ...acc, [node.uuid]: i };
    }, {});
  }, [rawModelNodes]);

  React.useEffect(() => {
    refsObject.current.annotations = rawModelAnnotations;

    refsObject.current.annotationsIndexLUT = rawModelAnnotations.reduce(
      (acc, b, i) => ({ ...acc, [b.uuid]: i }),
      {},
    );
  }, [rawModelAnnotations]);

  React.useEffect(() => {
    refsObject.current.links = rawModelLinks;
    refsObject.current.linksIndexLUT = {};
    refsObject.current.connectedPortLUT = {};
    refsObject.current.linksRenderingDependencyTree = {};

    for (let i = 0; i < rawModelLinks.length; i++) {
      const l = rawModelLinks[i];

      if (l.uiprops.link_type.connection_method == 'link_tap') {
        if (
          !refsObject.current.linksRenderingDependencyTree[
            l.uiprops.link_type.tapped_link_uuid
          ]
        ) {
          refsObject.current.linksRenderingDependencyTree[
            l.uiprops.link_type.tapped_link_uuid
          ] = [l.uuid];
        } else {
          refsObject.current.linksRenderingDependencyTree[
            l.uiprops.link_type.tapped_link_uuid
          ].push(l.uuid);
        }
      } else {
        if (!refsObject.current.linksRenderingDependencyTree.__no_dependency) {
          refsObject.current.linksRenderingDependencyTree.__no_dependency = [];
        }
        refsObject.current.linksRenderingDependencyTree.__no_dependency.push(
          l.uuid,
        );
      }

      if (
        l.src &&
        l.uiprops.link_type.connection_method === 'direct_to_block'
      ) {
        if (refsObject.current.connectedPortLUT[l.src.node]) {
          refsObject.current.connectedPortLUT[l.src.node].push({
            fullyConnected: Boolean(l.src && l.dst),
            side: PortSide.Output,
            portId: l.src.port,
            linkUuid: l.uuid,
          });
        } else {
          refsObject.current.connectedPortLUT[l.src.node] = [
            {
              fullyConnected: Boolean(l.src && l.dst),
              side: PortSide.Output,
              portId: l.src.port,
              linkUuid: l.uuid,
            },
          ];
        }
      }

      if (l.dst) {
        if (refsObject.current.connectedPortLUT[l.dst.node]) {
          refsObject.current.connectedPortLUT[l.dst.node].push({
            fullyConnected: Boolean(l.src && l.dst),
            side: PortSide.Input,
            portId: l.dst.port,
            linkUuid: l.uuid,
          });
        } else {
          refsObject.current.connectedPortLUT[l.dst.node] = [
            {
              fullyConnected: Boolean(l.src && l.dst),
              side: PortSide.Input,
              portId: l.dst.port,
              linkUuid: l.uuid,
            },
          ];
        }
      }

      refsObject.current.linksIndexLUT[l.uuid] = i;
    }
  }, [rawModelLinks]);

  const { pastLength, futureLength } = useAppSelector((state) => ({
    pastLength: state.model.past.length,
    futureLength: state.model.future.length,
  }));

  React.useEffect(() => {
    refsObject.current.undoFunc = () => {
      if (pastLength > 0) {
        dispatch(UndoRedoActionCreators.undo());
      }
    };
  }, [pastLength, dispatch]);

  React.useEffect(() => {
    refsObject.current.redoFunc = () => {
      if (futureLength > 0) {
        dispatch(UndoRedoActionCreators.redo());
      }
    };
  }, [futureLength, dispatch]);

  React.useEffect(() => {
    if (refsObject.current.parent && refsObject.current.canvas) {
      initModelRenderer(refsObject, setTransform, dispatch);
    }

    return () => {
      unregisterRendererEvents();
      dispatch(uiFlagsActions.setUIFlag({ rendererStateInitialized: false }));
      endNanovg();
    };
  }, [refsObject, setTransform, dispatch]);

  const { coord, zoom, rerenderTransformData } = useAppSelector(
    (state) => state.camera,
  );

  React.useEffect(() => {
    if (rerenderTransformData) {
      externallySetRendererTransform(coord.x, coord.y, zoom);
      dispatch(cameraActions.unsetRerender());
    }
  }, [rerenderTransformData, coord, zoom, dispatch]);

  const setParentRef = React.useCallback(
    (el) => (refsObject.current.parent = el),
    [],
  );
  const setCanvasRef = React.useCallback(
    (el) => (refsObject.current.canvas = el),
    [],
  );

  React.useEffect(() => {
    refsObject.current.externalOverlay = externalOverlayRef.current;
  }, [externalOverlayRef]);

  const handleMouseEnter = () => {
    dispatch(uiFlagsActions.setUIFlag({ hideLibraryDrag: true }));

    if (!rendererState) return;
    if (!dragContextData.state.dragging) return;

    const blockDisplayData: BlockDisplayData =
      dragContextData.state.dragPreviewCompProps;
    if (!blockDisplayData) return;

    if (rendererState.mouseState.state !== MouseActions.DragDropLibraryBlock) {
      transitionMouseState(rendererState, {
        state: MouseActions.DragDropLibraryBlock,
        blockClassName: blockDisplayData.blockClassName,
        referenceSubmodel: blockDisplayData.submodel,
        overridePropDefaults: blockDisplayData.overridePropDefaults,
        cursorOffset: dragContextData.state.cursorElementOffset,
      });
    }
  };

  const handleMouseLeave = () => {
    dispatch(uiFlagsActions.setUIFlag({ hideLibraryDrag: false }));

    if (!rendererState) return;
    if (!dragContextData.state.dragging) return;

    if (rendererState.mouseState.state === MouseActions.DragDropLibraryBlock) {
      transitionMouseState(rendererState, { state: MouseActions.Idle });
    }
  };

  return (
    <ModelRenderer
      data-test-id="model-renderer"
      ref={setParentRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <canvas
        data-test-id="model-renderer-canvas"
        style={{
          userSelect: 'none',
          outline: 'none',
        }}
        ref={setCanvasRef}
      />
      <RendererOverlay isDiagramReadonly={isDiagramReadonly} />
    </ModelRenderer>
  );
};
