import styled from '@emotion/styled';
import { DISABLE_GA4 } from 'app/config/globalApplicationConfig';
import { SimulationRefAccess } from 'app/sliceRefAccess/SimulationRefAccess';
import { SubmodelRefAccess } from 'app/sliceRefAccess/SubmodelRefAccess';
import { WebSocketProvider } from 'ui/common/WebSocketProvider';
import AppRouter from 'AppRouter';
import { googleAnalyticsTracking } from 'googleAnalyticsTracking';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Theme, ThemeStore } from 'theme';
import Onboarding from 'tours/onboarding/Onboarding';
import { SignedIn } from 'ui/auth/AuthComponents';
import AuthProvider from 'ui/auth/AuthProvider';
import LoginProvider from 'ui/auth/LoginProvider';
import UserSessionTracker from 'ui/auth/UserSessionTracker';
import { ModalProvider } from 'ui/common/Modal/ModalProvider';
import { Notification } from 'ui/common/notifications/Notification';
import { PythonProvider } from 'ui/common/PythonProvider';
import { Navbar } from 'ui/navbar/Navbar';
import NotebookIframe from 'ui/notebook/NotebookIframe';
import UserPreferencesLoader from 'ui/userPreferences/UserPreferencesLoader';

const AppLayoutWrapper = styled.div`
  isolation: isolate;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const AppContentWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
`;

// necessary because this needs to be a part of the top-level AppLayout,
// but it also needs to be inside of the BrowserRouter component
const GATracker = () => {
  const { pathname, search } = useLocation();

  React.useEffect(() => {
    if (DISABLE_GA4) return;
    if (process.env.NODE_ENV !== 'production') return;

    googleAnalyticsTracking({
      path: pathname,
      search,
      title: pathname.split('/')[1],
    });
  }, [pathname, search]);

  return null;
};

const AppLayout: React.FC = () => (
  <ThemeStore themeValue="base">
    <Theme>
      <BrowserRouter>
        <GATracker />
        <Routes>
          <Route path="/login/*" element={<LoginProvider />} />
          <Route
            path="*"
            element={
              <AuthProvider>
                <WebSocketProvider>
                  <PythonProvider>
                    <ModalProvider>
                      <AppLayoutWrapper>
                        <SignedIn>
                          <Onboarding />
                          {/* Non-visual components that should be created exactly once when the user is signed in and whose lifetime should match the user session. */}
                          <NotebookIframe />
                          <UserPreferencesLoader />
                          <UserSessionTracker />
                          <SubmodelRefAccess />
                          <SimulationRefAccess />

                          {/* App navbar */}
                          <Navbar />
                        </SignedIn>

                        {/* App content */}
                        <AppContentWrapper>
                          <DndProvider backend={HTML5Backend}>
                            <AppRouter />
                          </DndProvider>
                        </AppContentWrapper>

                        {/* Notifications live below the navbar and above the content. */}
                        <SignedIn>
                          <Notification />
                        </SignedIn>
                      </AppLayoutWrapper>
                    </ModalProvider>
                  </PythonProvider>
                </WebSocketProvider>
              </AuthProvider>
            }
          />
        </Routes>
      </BrowserRouter>
    </Theme>
  </ThemeStore>
);

export default AppLayout;
