import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import { BlockClassName } from 'app/generated_types/SimulationModel';
import { useAppSelector } from 'app/hooks';
import { selectCurrentSubdiagramType } from 'app/slices/modelSlice';
import { getSpecificReferenceSubmodelById } from 'app/utils/submodelUtils';
import React from 'react';
import {
  getIsSearchMatch,
  getIsSubmodelSearchMatch,
  shouldShowBlock,
} from 'ui/objectBrowser/librarySearch';
import { BlockLibraryDragItem } from 'ui/objectBrowser/sections/BlockLibraryDragItem';
import LibrarySubsection from 'ui/objectBrowser/sections/LibrarySubsection';

interface Props {
  lowercaseSearchString: string;
  submodels: SubmodelInfoLiteUI[];
  sectionTitle: string;
}

export const searchSubmodelBlocks = (
  submodels: SubmodelInfoLiteUI[],
  lowercaseSearchString: string,
  developerModeEnabled: boolean,
  topLevelModelKind: ModelKind,
  currentSubdiagramType?: BlockClassName,
) => {
  const blockClassName = 'core.ReferenceSubmodel';

  return submodels.filter((submodel) => {
    if (
      !submodel &&
      !shouldShowBlock(
        blockClassName,
        developerModeEnabled,
        topLevelModelKind,
        currentSubdiagramType,
      )
    ) {
      return false;
    }

    if (lowercaseSearchString) {
      const searchMatch = submodel
        ? getIsSubmodelSearchMatch(submodel.name, lowercaseSearchString)
        : getIsSearchMatch(blockClassName, lowercaseSearchString);
      if (!searchMatch) {
        return false;
      }
    }

    return true;
  });
};

const blockClassName = 'core.ReferenceSubmodel';

export const ReferenceSubmodelBlocks: React.FC<Props> = ({
  lowercaseSearchString,
  submodels,
  sectionTitle,
}) => {
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const idToVersionIdToSubmodelInfo = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelInfo,
  );

  const topLevelModelKind =
    useAppSelector((state) => state.submodels.topLevelModelType) || 'Model';
  const currentSubdiagramType = useAppSelector((state) =>
    selectCurrentSubdiagramType(state.model.present),
  );

  const filteredSubmodels = searchSubmodelBlocks(
    submodels,
    lowercaseSearchString,
    developerModeEnabled,
    topLevelModelKind,
    currentSubdiagramType,
  );

  if (filteredSubmodels.length === 0) {
    return null;
  }

  return (
    <LibrarySubsection title={sectionTitle} count={filteredSubmodels.length}>
      {filteredSubmodels.map((submodel) => (
        <BlockLibraryDragItem
          blockClassName={blockClassName}
          submodel={
            getSpecificReferenceSubmodelById(
              submodel.id,
              VersionTagValues.LATEST_VERSION,
              idToVersionIdToSubmodelInfo,
            ) || submodel
          }
          key={submodel.id}
        />
      ))}
    </LibrarySubsection>
  );
};
