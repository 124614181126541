import { t } from '@lingui/macro';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { isValidSubmodelPortNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import {
  DetailInputRowsSection,
  DetailsDarkGroup,
  DetailsDarkGroupLabel,
  DetailsInput,
  DetailsRow,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';

interface Props {
  canEditCurrentModelVersion: boolean;
}

const SubmodelPortDetails: React.FC<Props> = ({
  canEditCurrentModelVersion,
}) => {
  const dispatch = useAppDispatch();

  const topLevelNodes = useAppSelector(
    (state) => state.model.present.rootModel.nodes,
  );

  const parameterDefinitions = useAppSelector(
    (state) => state.model.present.parameterDefinitions,
  );

  const inputNodes = topLevelNodes?.filter(
    (node) => node.type === 'core.Inport',
  );
  const outputNodes = topLevelNodes?.filter(
    (node) => node.type === 'core.Outport',
  );

  const updatePortName = (portNode: NodeInstance, newName: string) => {
    dispatch(
      modelActions.changeBlockName({
        blockUuid: portNode.uuid,
        parentPath: [],
        newName,
      }),
    );
  };

  const updatePortDescription = (
    portNodeId: string,
    newDescription: string,
    inputHeight?: string,
  ) => {
    dispatch(
      modelActions.changeBlockParameter({
        parentPath: [],
        nodeUuid: portNodeId,
        paramName: 'description',
        value: newDescription,
        inputHeight,
      }),
    );
  };

  const updatePortDefaultVal = (portNodeId: string, newVal: string) => {
    dispatch(
      modelActions.changeBlockParameter({
        parentPath: [],
        nodeUuid: portNodeId,
        paramName: 'default_value',
        value: newVal,
      }),
    );
  };

  return (
    <>
      {/* Inputs */}
      <SectionHeading testId="inputs">
        {t({
          id: 'blockDetails.PortInputsTitle',
          message: 'Inputs',
        })}
      </SectionHeading>

      <DetailInputRowsSection>
        {inputNodes &&
          inputNodes.map((inputNode, i) => (
            <DetailsSection key={inputNode.uuid} vertical>
              <DetailsRow>
                <DetailsInput
                  grow
                  testId={`block-input-name-${i}`}
                  value={inputNode.name}
                  placeholder={`Unnamed input ${i}`}
                  onSubmitValue={(newName) =>
                    updatePortName(inputNode, newName)
                  }
                  disabled={!canEditCurrentModelVersion}
                  validationRules={isValidSubmodelPortNameRuleSet(
                    parameterDefinitions,
                    inputNode.uuid,
                    inputNodes,
                    outputNodes,
                  )}
                />
              </DetailsRow>
              <DetailsDarkGroup>
                <DetailsDarkGroupLabel>
                  {t({
                    id: 'referenceSubmodelPortDetails.parameter.defaultValue.label',
                    message: 'Default value',
                  })}
                </DetailsDarkGroupLabel>
                <DetailsRow>
                  <DetailsInput
                    grow
                    placeholder={t({
                      id: 'referenceSubmodelPortDetails.parameter.defaultValue.noValue',
                      message: 'None',
                    })}
                    testId={`submodel-input-defaultvalue-${inputNode.uuid}`}
                    value={inputNode.parameters.default_value?.value}
                    onSubmitValue={(newVal) =>
                      updatePortDefaultVal(inputNode.uuid, newVal)
                    }
                    disabled={!canEditCurrentModelVersion}
                    hasBorder
                  />
                </DetailsRow>
                <DetailsDarkGroupLabel>
                  {t({
                    id: 'referenceSubmodelPortDetails.parameter.description.label',
                    message: 'Description',
                  })}
                </DetailsDarkGroupLabel>
                <DetailsRow>
                  <DetailsInput
                    grow
                    testId={`submodel-input-description-${inputNode.uuid}`}
                    value={inputNode.parameters.description?.value}
                    onSubmitValue={(description, { inputHeight }) =>
                      updatePortDescription(
                        inputNode.uuid,
                        description,
                        inputHeight,
                      )
                    }
                    multilineHeight={
                      inputNode.uiprops?.parameter_heights?.description
                    }
                    disabled={!canEditCurrentModelVersion}
                    hasBorder
                    allowMultiline
                  />
                </DetailsRow>
              </DetailsDarkGroup>
            </DetailsSection>
          ))}
      </DetailInputRowsSection>

      {/* Outputs */}
      <SectionHeading testId="outputs">
        {t({
          id: 'blockDetails.PortOutputsTitle',
          message: 'Outputs',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        {outputNodes &&
          outputNodes.map((outputNode, i) => (
            <DetailsSection key={outputNode.uuid} vertical>
              <DetailsInput
                grow
                testId={`block-output-name-${i}`}
                value={outputNode.name}
                placeholder={`Unnamed output ${i}`}
                onSubmitValue={(newName) => updatePortName(outputNode, newName)}
                disabled={!canEditCurrentModelVersion}
                validationRules={isValidSubmodelPortNameRuleSet(
                  parameterDefinitions,
                  outputNode.uuid,
                  inputNodes,
                  outputNodes,
                )}
              />
              <DetailsInput
                grow
                placeholder={t({
                  id: 'referenceSubmodelDetails.parameter.description.placeholder',
                  message: 'Description',
                })}
                testId={`submodel-output-description-${outputNode.uuid}`}
                value={outputNode.parameters.description?.value}
                onSubmitValue={(description, { inputHeight }) =>
                  updatePortDescription(
                    outputNode.uuid,
                    description,
                    inputHeight,
                  )
                }
                multilineHeight={
                  outputNode.uiprops?.parameter_heights?.description
                }
                disabled={!canEditCurrentModelVersion}
                hasBorder
                allowMultiline
              />
            </DetailsSection>
          ))}
      </DetailInputRowsSection>
    </>
  );
};

export default SubmodelPortDetails;
