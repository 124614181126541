import { validate as isValidUUID, v4 as uuidv4 } from 'uuid';
import {
  ParameterDefinition,
  ParameterDefinitions,
} from './schemas/ParameterDefinitions';
import {
  AnnotationInstance,
  BlockClassName,
  BlockInstance,
  LinkInstance,
  LinkSegmentType,
  LinkTypeType,
  ModelConfiguration,
  ModelDiagram,
  NodeInstance,
  NodeUIProps,
  Parameter,
  Parameters,
  Port,
  SimulationModel,
  StateMachines,
  SubmodelConfiguration,
  SubmodelInstance,
  StateMachineBlockInstance,
  SubmodelsSection,
  WorkspaceConfig,
  defaultModelConfiguration,
} from './schemas/SimulationModel';
import { ParameterChange } from './types';
import { getWriteableDeepCopy } from './util';

/*
This file contains a bunch of functions that transform the data coming from
the backend and make sure that the resulting data used by the frontend is
valid. Some errors may be unacceptable and throw exceptions while others
may be silently ignored and some data may be lost.

We might want to improve on this later, but this should already help us a bit.

@jp I did not try to look for any automatic type conversion, because I think this
manual code lets us more easily handle corner cases the way we want (eg. drop invalid
links, set valid default values, etc...).

This will also allow us to actually transform the data more easily (eg. move submodels,
upgrade a block class, etc...).
*/

const LatestSimulationModelVersion = '0.1';

// ----------------------------------------------------------------------------
// Helpers

const isObject = (o: any): boolean =>
  o !== undefined && o !== null && typeof o === 'object';

const isEmptyObject = (o: any): boolean =>
  isObject(o) && Object.keys(o).length === 0;

const isInt = (value: any): boolean =>
  !isNaN(value) && parseInt(value) === value && !isNaN(parseInt(value, 10));

const isNumber = (value: any): boolean =>
  !isNaN(value) && !isNaN(parseFloat(value)) && isFinite(value);

const hasKey = (o: any, key: string): boolean =>
  o !== undefined && o !== null && Object.prototype.hasOwnProperty.call(o, key);

const isPositive = (value: any): boolean =>
  typeof value === 'number' && value > 0 && isFinite(value);

const ensurePositive = (value: any, defaultValue = 0): number =>
  isPositive(value) ? value : defaultValue;

const ensureUUID = (data: any): string => {
  if (typeof data === 'string' && isValidUUID(data)) {
    return data;
  }
  return uuidv4();
};

const ensureString = (value: any): string => `${value}`;

const isEnumValue = <T>(value: any, enumValues: T[]): boolean =>
  typeof value === 'string' && enumValues.indexOf(value as any) >= 0;

const ensureEnumValue = <T>(
  value: any,
  enumValues: T[],
  defaultValue: T | undefined,
): T => (isEnumValue(value, enumValues) ? value : defaultValue);

// ----------------------------------------------------------------------------
// Transformers

const transformWorkspaceInternal = (data: any): WorkspaceConfig => {
  if (!data?.init_scripts) return {};
  const file_name = data.init_scripts[0]?.file_name as string;
  return { init_scripts: [{ file_name }] };
};

const transformConfiguration = (data: any): ModelConfiguration => {
  if (!isEmptyObject(data) && !hasKey(data, 'stop_time')) {
    console.error('Invalid payload for Configuration', data);
    data = {};
  }

  // Note: We could probably autogen this entirely, or use a library to do it
  const cfg: ModelConfiguration = {
    ...defaultModelConfiguration,
    stop_time: ensurePositive(
      data?.stop_time,
      defaultModelConfiguration.stop_time,
    ),
    sample_time: ensurePositive(
      data?.sample_time,
      defaultModelConfiguration.sample_time,
    ),
    continuous_time_result_interval: ensurePositive(
      data?.continuous_time_result_interval,
      defaultModelConfiguration.continuous_time_result_interval,
    ),
    events_handling: ensureEnumValue(
      data?.events_handling,
      ['normal', 'none'],
      defaultModelConfiguration.events_handling,
    ),
    solver: {
      method: ensureEnumValue(
        data?.solver?.method,
        ['RK45', 'BDF'],
        defaultModelConfiguration.solver?.method,
      ),
      type: ensureEnumValue(
        data?.solver?.type,
        ['fixed_step', 'variable_step'],
        defaultModelConfiguration.solver?.type,
      ),
      first_step: ensurePositive(
        data?.solver?.first_step,
        defaultModelConfiguration.solver?.first_step,
      ),
      max_step: ensurePositive(
        data?.solver?.max_step,
        defaultModelConfiguration.solver?.max_step,
      ),
      min_step: ensurePositive(
        data?.solver?.min_step,
        defaultModelConfiguration.solver?.min_step,
      ),
      fixed_step: ensurePositive(
        data?.solver?.fixed_step,
        defaultModelConfiguration.solver?.fixed_step,
      ),
      relative_tolerance: ensurePositive(
        data?.solver?.relative_tolerance,
        defaultModelConfiguration.solver?.relative_tolerance,
      ),
      absolute_tolerance: ensurePositive(
        data?.solver?.absolute_tolerance,
        defaultModelConfiguration.solver?.absolute_tolerance,
      ),
    },
    record_mode: ensureEnumValue(
      data?.record_mode,
      ['all', 'selected'],
      undefined, // defaultModelConfiguration.record_mode
    ),
  };

  cfg.workspace = transformWorkspaceInternal(data.workspace);

  if (data.__developer_options) {
    cfg.__developer_options = data.__developer_options;
  }

  return cfg;
};

/**
 * TODO consolidate this with transformConfiguration and update
 * validation handling.  This is some simple hacky code just to get
 * the simulation settings dialog functionally working.
 */
const updateBackendConfiguration = (
  changes: ParameterChange[],
  configuration: ModelConfiguration,
): void => {
  changes.forEach(({ parameterId, value }) => {
    // TODO remove this (it doesn't actually useful)
    // This is only here so that typescript will allow me
    // to write configuration.solver.paramId = paramValue
    // without complaining that `solver` will be falsy
    if (!configuration.solver) {
      configuration.solver = {};
    }

    switch (parameterId) {
      case 'solver_type':
        configuration.solver.type = ensureEnumValue(
          value,
          ['fixed_step', 'variable_step'],
          defaultModelConfiguration.solver?.type,
        );
        return;
      case 'method':
        configuration.solver.method = ensureEnumValue(
          value,
          ['RK45', 'BDF'],
          defaultModelConfiguration.solver?.method,
        );
        return;
      case 'absolute_tolerance':
        configuration.solver.absolute_tolerance = ensurePositive(
          value,
          defaultModelConfiguration.solver?.absolute_tolerance,
        );
        return;
      case 'relative_tolerance':
        configuration.solver.relative_tolerance = ensurePositive(
          value,
          defaultModelConfiguration.solver?.relative_tolerance,
        );
        return;
      case 'first_step':
        configuration.solver.first_step = ensurePositive(
          value,
          defaultModelConfiguration.solver?.first_step,
        );
        return;
      case 'min_step':
        configuration.solver.min_step = value; // Can be undefined to represent 'Auto' value
        return;
      case 'max_step':
        configuration.solver.max_step = ensurePositive(
          value,
          defaultModelConfiguration.solver?.max_step,
        );
        return;
      case 'fixed_step':
        configuration.solver.fixed_step = ensurePositive(
          value,
          defaultModelConfiguration.solver?.fixed_step,
        );
        return;
      case 'sample_time':
        configuration.sample_time = ensurePositive(
          value,
          defaultModelConfiguration.sample_time,
        );
        return;
      case 'continuous_time_result_interval':
        configuration.continuous_time_result_interval = value; // Can be undefined to represent 'None' value
        return;
      case 'events_handling':
        configuration.events_handling = ensureEnumValue(
          value,
          ['normal', 'none'],
          undefined,
        );
        return;
      case 'record_mode':
        configuration.record_mode = ensureEnumValue(
          value,
          ['all', 'selected'],
          undefined, // defaultModelConfiguration.record_mode,
        );
        return;
      case 'worker_type':
        configuration.worker_type = ensureEnumValue(
          value,
          ['any', 'cpu', 'gpu'],
          undefined, // defaultModelConfiguration.record_mode,
        );
        return;
      default:
        console.error('Expected parameter not found', parameterId);
    }
  });
};

const transformBackendParameter = (data: any): Parameter | null => {
  if (!isObject(data) || !hasKey(data, 'value')) {
    console.error('Invalid payload for Parameter', data);
    return null;
  }

  const p: Parameter = {
    value: `${data.value}`, // make sure we have a string
  };

  if (data.is_string) {
    p.is_string = true;
  }

  return p;
};

const transformParameters = (data: any): Parameters => {
  if (!isObject(data)) {
    console.error('Invalid payload for Parameters', data);
    return {};
  }

  const parameters: Parameters = Object.keys(data).reduce((acc, key) => {
    const p = transformBackendParameter(data[key]);
    if (p) {
      return { ...acc, [key]: p };
    }
    return acc;
  }, {});

  return parameters;
};

// Submodel parameter definitions
const transformBackendParameterDefinition = (
  data: any,
): ParameterDefinition | null => {
  if (!isObject(data) || !hasKey(data, 'name')) {
    console.error('Invalid payload for ParameterDefinition', data);
    return null;
  }

  const pd: ParameterDefinition = {
    uuid: ensureUUID(data.uuid),
    name: ensureString(data.name),
    default_value: ensureString(data.default_value),
    description: data.description ? ensureString(data.description) : undefined,
    uiprops: data.uiprops,
  };

  return pd;
};

const transformParameterDefinitions = (data: any): ParameterDefinitions => {
  const parameterDefinitions: ParameterDefinitions = [];
  for (const pd of data) {
    const p = transformBackendParameterDefinition(pd);
    if (p) {
      parameterDefinitions.push(p);
    }
  }
  return parameterDefinitions;
};

const transformPortParameters = (port_parameters: any): Parameters => {
  if (!port_parameters) return {};

  const parameters: Parameters = {};
  for (const key in port_parameters) {
    if (typeof port_parameters[key] === 'string') {
      // Transform old port parameters into standard parameters:
      // { [name]: "value" } becomes { [name]: { value: "value", is_string: true } }
      const param: Parameter = {
        value: port_parameters[key],
      };
      if (key === 'dtype') {
        param.is_string = true;
      }
      parameters[key] = param;
    } else {
      const param = transformBackendParameter(port_parameters[key]);
      if (param) {
        parameters[key] = param;
      }
    }
  }

  return parameters;
};

const transformNodePorts = (
  blockType: BlockClassName,
  data: any,
  dir: 'in' | 'out',
): Port[] =>
  ((data as any[]) || []).map((port, idx) => {
    const p: Port = {
      name: port.name || `${dir}_${idx}`,
      kind: port.kind,
      parameters: transformPortParameters(port.parameters),
    };
    if (port.record === true) {
      p.record = true;
    }

    return p;
  });

const transformSubmodelPorts = (data: any, dir: 'in' | 'out'): Port[] =>
  ((data as any[]) || []).map((port, idx) => {
    const p: Port = {
      name: port.name || `${dir}_${idx}`,
      kind: 'dynamic',
      parameters: transformPortParameters(port.parameters),
    };
    if (port.record === true) {
      p.record = true;
    }
    return p;
  });

const transformSubmodelConfiguration = (data: any): SubmodelConfiguration =>
  transformBackendParameters(data || {});

const transformBackendSubmodelInstance = (
  data: any,
): SubmodelInstance | null => {
  // Submodel nodes have parameters that must be ordered. They are a bit special
  // because this is the only case where we have "extra" parameters, that aren't
  // builtin in the class definition. But since they are stored as a map, we
  // must add extra information to keep them ordered. Upon loading, we make sure
  // there is a valid order. If not, reorder everything :) All new parameters
  // will have an order value, so this is very much just safety code.
  const parameters = data.parameters || {};
  const parameterNames = Object.keys(parameters);
  const missingOrders = parameterNames.find(
    (key) => parameters[key]?.order === undefined,
  );
  if (missingOrders) {
    for (let order = 0; order < parameterNames.length; order++) {
      const key = parameterNames[order];
      parameters[key] = { ...parameters[key], order };
    }
  }

  const configuration = transformBackendSubmodelConfiguration(
    data.configuration,
  );

  const submodel: SubmodelInstance = {
    uuid: ensureUUID(data.uuid),
    name: data.name,
    submodel_reference_uuid: data.submodel_reference_uuid,
    submodel_reference_version: data.submodel_reference_version,
    // ignore path
    type: data.type || 'core.Submodel',
    inputs: transformSubmodelPorts(data.inputs, 'in'),
    outputs: transformSubmodelPorts(data.outputs, 'out'),
    parameters,
    uiprops: transformNodeUiprops(data.uiprops),
  };

  // TODO: Validate submodel references and diagrams
  return submodel;
};

const migrateBlockInstance = (block: BlockInstance): BlockInstance => {
  switch (block.type as string) {
    case 'core.DataSource':
      // Migrate old DataSource parameters to the new version
      if (block.parameters.header_as_first_row !== undefined) {
        return block;
      }
      const is_string = true;
      const oldColumn = block.parameters.column?.value || '0';
      const isOldColumnHeaderName = isNaN(parseFloat(oldColumn));
      const parameters: Parameters = {
        file_name: block.parameters.file_name || { value: '', is_string },
        // old blocks did not explicitly support headers, thus assuming false,
        // unless the column is a non-numerical string like "XYZ"
        header_as_first_row: {
          value: isOldColumnHeaderName ? 'true' : 'false',
        },
        // time_samples_as_column: the new default is true but old DataSource blocks
        // were kinda not supposed to have a time column, so we assume false here
        time_samples_as_column: { value: 'false' },
        time_column: { value: '0', is_string },
        sampling_interval: block.parameters.sampling_rate || { value: '0.1' },
        // the new default column is '1' but since the old block wasn't
        // supposed to have a time column, their default column was '0'
        data_columns: { value: oldColumn.trim(), is_string },
        extrapolation: { value: 'hold', is_string },
        interpolation: { value: 'zero_order_hold', is_string },
      };
      console.warn(
        'migrated DataSource block parameters from/to',
        block.parameters,
        parameters,
      );
      return { ...block, parameters };
    case 'core.DataSourceDev':
      return { ...block, type: 'core.DataSource' };

    case 'core.IfThenElse':
      if (block.inputs.length !== 3 || block.outputs.length !== 1) {
        console.error('Invalid IfThenElse block: ' + JSON.stringify(block));
        return block;
      }

      const inputs = [...block.inputs];
      inputs[0].name = 'test';
      inputs[1].name = 'if_true';
      inputs[2].name = 'if_false';
      const outputs = [...block.outputs];
      outputs[0].name = 'out_0';
      return { ...block, inputs };

    default:
      return block;
  }
};

const transformNodeUiprops = (uiprops: any): NodeUIProps => {
  if (!uiprops) {
    console.error(
      'Model transformers: found invalid node uiprops, generating random x,y',
    );
    return {
      x: Math.random() * 600,
      y: Math.random() * 600,
    };
  }

  return {
    x: uiprops.x as number,
    y: uiprops.y as number,
    grid_height: uiprops.grid_height
      ? (uiprops.grid_height as number)
      : undefined,
    grid_width: uiprops.grid_width ? (uiprops.grid_width as number) : undefined,
    label_position: ensureEnumValue(
      uiprops.label_position,
      ['bottom', 'top'],
      undefined,
    ),
    directionality: ensureEnumValue(
      uiprops.directionality,
      ['right', 'left'],
      undefined,
    ),
    port_alignment: ensureEnumValue(
      uiprops.port_alignment,
      ['bottom', 'top', 'spaced', 'center'],
      undefined,
    ),
    show_port_name_labels: uiprops.show_port_name_labels ?? undefined,
  };
};

const transformBackendBlockInstance = (data: any): BlockInstance | null => {
  // Convert to "agnostic": https://collimator.atlassian.net/browse/SIM-406
  const data_time_mode =
    data.time_mode === 'inherit' ? 'agnostic' : data.time_mode;
  const time_mode: 'agnostic' | 'discrete' = ensureEnumValue(
    data_time_mode,
    ['agnostic', 'discrete'],
    undefined,
  );

  const block: BlockInstance = {
    // "$class_version"
    uuid: ensureUUID(data.uuid),
    name: data.name,
    type: data.type,
    inputs: transformNodePorts(data.type, data.inputs, 'in'),
    outputs: transformNodePorts(data.type, data.outputs, 'out'),
    file_outputs: data.file_outputs,
    parameters: transformParameters(data.parameters),
    time_mode,
    uiprops: transformNodeUiprops(data.uiprops),
  };

  return block;
};

const transformBackendStateMachineBlockInstance = (
  data: any,
): StateMachineBlockInstance | null => {
  const transformedBlock = transformBackendBlockInstance(data);
  if (!transformedBlock) return null;

  const block: StateMachineBlockInstance = {
    ...transformedBlock,
    type: 'core.StateMachine',
    state_machine_diagram_id: data.state_machine_diagram_id,
  };

  return block;
};

const transformBackendNodeInstance = (data: any): NodeInstance | null => {
  if (!hasKey(data, 'uuid') || !hasKey(data, 'type')) {
    return null;
  }

  const klass = data.type as BlockClassName;
  if (
    klass === 'core.Submodel' ||
    klass === 'core.Group' ||
    klass === 'core.ReferenceSubmodel'
  ) {
    return transformBackendSubmodelInstance(data);
  }

  if (klass === 'core.StateMachine') {
    return transformBackendStateMachineBlockInstance(data);
  }

  const transformed = transformBackendBlockInstance(data);
  if (!transformed) return null;

  const migrated = migrateBlockInstance(transformed);
  return migrated;
};

const transformBackendLinkSegments = (data: any): LinkSegmentType | null => {
  if (!isObject(data)) {
    console.error('Invalid payload for segment', data);
    return null;
  }

  if (
    parseFloat(data.coordinate) !== data.coordinate ||
    !isFinite(data.coordinate)
  ) {
    console.error('Invalid payload for segment', data);
    return null;
  }

  return {
    segment_direction: ensureEnumValue(
      data.segment_direction,
      ['horiz', 'vert'],
      undefined,
    ),
    coordinate: data.coordinate,
  };
};

const transformBackendLinkType = (data: any): LinkTypeType => {
  if (!isObject(data)) {
    console.error(
      "Model transformers: found invalid link, defaulting to 'direct_to_block'",
    );
    return { connection_method: 'direct_to_block' };
  }

  if (data.connection_method === 'direct_to_block') {
    return { connection_method: 'direct_to_block' };
  }

  // NOTE: We don't actually check the validity of "link_tap" links.
  return data;
};

const transformBackendLinkInstance = (
  data: any,
  nodes: NodeInstance[],
): LinkInstance | null => {
  if (!isObject(data)) {
    console.error('Invalid payload for LinkInstance', data);
    return null;
  }

  const srcNode = isInt(data.src?.port)
    ? nodes.find((n) => n.uuid === data.src.node)
    : undefined;
  const dstNode = isInt(data.dst?.port)
    ? nodes.find((n) => n.uuid === data.dst.node)
    : undefined;

  // drop links that have neither src nor dst
  // a single hanging point is acceptable but not both
  if (!srcNode && !dstNode) {
    return null;
  }

  const src = srcNode
    ? { node: srcNode.uuid, port: isInt(data.src.port) ? data.src.port : 0 }
    : undefined;
  const dst = dstNode
    ? { node: dstNode.uuid, port: isInt(data.dst.port) ? data.dst.port : 0 }
    : undefined;

  const hang_coord_start =
    isNumber(data.uiprops?.hang_coord_start?.x) &&
    isNumber(data.uiprops?.hang_coord_start?.y)
      ? {
          x: data.uiprops?.hang_coord_start.x as number,
          y: data.uiprops?.hang_coord_start.y as number,
        }
      : undefined;

  const hang_coord_end =
    isNumber(data.uiprops?.hang_coord_end?.x) &&
    isNumber(data.uiprops?.hang_coord_end?.y)
      ? {
          x: data.uiprops?.hang_coord_end.x as number,
          y: data.uiprops?.hang_coord_end.y as number,
        }
      : undefined;

  const link_type = transformBackendLinkType(data.uiprops?.link_type);
  const segments = (
    Array.isArray(data.uiprops?.segments) ? data.uiprops.segments : []
  )
    .map((s: any) => transformBackendLinkSegments(s))
    .filter((s: LinkSegmentType | null) => s !== null);

  const uiprops = {
    link_type,
    segments,
    hang_coord_start,
    hang_coord_end,
  };

  const link: LinkInstance = {
    // link uuid are not used so we can ignore invalid values
    uuid: ensureUUID(data.uuid),
    name: data.name ? (data.name as string) : undefined,
    src,
    dst,
    uiprops,
  };

  return link;
};

const transformAnnotations = (data: any): AnnotationInstance[] => {
  if (!(data instanceof Array)) {
    return [];
  }

  return data.reduce(
    (acc: AnnotationInstance[], item: any) =>
      item.uuid
        ? [
            ...acc,
            {
              uuid: item.uuid,
              text: item.text,
              x: item.x || 0,
              y: item.y || 0,
              grid_height: item.grid_height || 2,
              grid_width: item.grid_width || 2,
              color_id: item.color_id || 'gray',
              label_position: ensureEnumValue(
                item.label_position,
                ['top', 'bottom', 'inside'],
                'bottom',
              ),
            },
          ]
        : acc,
    [],
  );
};

const transformModelDiagram = (data: any): ModelDiagram => {
  if (
    !isEmptyObject(data) &&
    (!isObject(data) || !hasKey(data, 'nodes') || !hasKey(data, 'links'))
  ) {
    console.error('Invalid payload for ModelDiagram:', data);
  }

  const nodes = (data?.nodes || [])
    .map((node: any) => transformBackendNodeInstance(node))
    .filter((n: NodeInstance | null) => n !== null);

  const links = (data?.links || [])
    .map((l: any) => transformBackendLinkInstance(l, nodes))
    .filter((l: LinkInstance | null) => l !== null);

  const annotations = transformAnnotations(data?.annotations || []);

  const diagram: ModelDiagram = {
    nodes,
    links,
    annotations,
    uuid: ensureUUID(data.uuid),
  };

  return diagram;
};

const transformSubmodels = (data: any): SubmodelsSection => {
  if (
    !isEmptyObject(data) &&
    (!hasKey(data, 'diagrams') || !hasKey(data, 'references'))
  ) {
    console.error('Invalid payload for Submodels', data);
    return { diagrams: {}, references: {} };
  }

  const dataSection: SubmodelsSection = {
    diagrams: data?.diagrams || {},
    references: data?.references || {},
  };

  const diagrams = Object.keys(dataSection.diagrams).reduce((acc, key) => {
    const diagram = transformModelDiagram(dataSection.diagrams[key]);
    if (diagram) {
      return { ...acc, [key]: diagram };
    }

    console.error('could not transform a submodel diagram:', key);
    return acc;
  }, {});

  const references = Object.keys(dataSection.references).reduce((acc, key) => {
    const diagram_uuid = dataSection.references[key]?.diagram_uuid;
    if (diagram_uuid) {
      const reference = { diagram_uuid };
      return { ...acc, [key]: reference };
    }

    console.error('could not transform a submodel reference:', key);
    return acc;
  }, {});

  return { diagrams, references };
};

const transformStateMachines = (data: any): StateMachines => {
  if (typeof data !== 'object') return {};

  return data as StateMachines;
};

// ----------------------------------------------------------------------------
// Main entry points

const transformSimulationModelInternal = (data: any): SimulationModel => {
  if (!hasKey(data, 'diagram')) {
    throw new Error(
      'Invalid payload for SimulationModel: ' + Object.keys(data),
    );
  }

  const sm: SimulationModel = {
    $schema_version: LatestSimulationModelVersion,
    uuid: data.uuid || uuidv4(),
    name: data.name || 'Untitled',
    diagram: transformModelDiagram(data.diagram),
    configuration: transformConfiguration(data.configuration || {}),
    parameters: transformParameters(data.parameters || {}),
    submodels: transformSubmodels(data.submodels || {}),
    state_machines: transformStateMachines(data.state_machines || {}),
  };

  return sm;
};

const transformSimulationModel = (data: any): SimulationModel => {
  // Make a copy in case we're in an immutable environment
  data = getWriteableDeepCopy(data);

  try {
    return transformSimulationModelInternal(data);
  } catch (e) {
    console.error('model data validation failed:', e);

    // We could crash here, but we don't handle the case nicely in
    // the callers of this function. Better to just notify Sentry via
    // the above call to console.error().
    // throw e;
  }

  const diagram = {
    nodes: (data.diagram?.nodes as NodeInstance[]) || [],
    links: (data.diagram?.links as LinkInstance[]) || [],
    annotations: (data.diagram?.annotations as AnnotationInstance[]) || [],
  };

  const model: SimulationModel = {
    $schema_version: LatestSimulationModelVersion,
    uuid: data.uuid,
    name: data.name,
    diagram,
    configuration: data.configuration || defaultModelConfiguration,
    parameters: data.parameters || {},
    submodels: {
      diagrams: data.submodels?.diagrams || {},
      references: data.submodels?.references || {},
    },
    state_machines: data.state_machines || {},
  };

  return model;
};

// Public exports
// Declared here for better readability, also wrapping all calls with a deep
// copy to avoid crashes when called from immutable places in React.

export function transformBackendAnnotations(data: any): AnnotationInstance[] {
  data = getWriteableDeepCopy(data);
  return transformAnnotations(data);
}

export function transformBackendConfiguration(data: any): ModelConfiguration {
  data = getWriteableDeepCopy(data);
  return transformConfiguration(data);
}

export function transformBackendModelDiagram(data: any): ModelDiagram {
  data = getWriteableDeepCopy(data);
  return transformModelDiagram(data);
}

export function transformBackendParameters(data: any): Parameters {
  data = getWriteableDeepCopy(data);
  return transformParameters(data);
}

export function transformBackendParameterDefinitions(
  data: any,
): ParameterDefinitions {
  data = getWriteableDeepCopy(data);
  return transformParameterDefinitions(data);
}

export function transformBackendSimulationModel(data: any): SimulationModel {
  data = getWriteableDeepCopy(data);
  return transformSimulationModel(data);
}

export function transformBackendSubmodels(data: any): SubmodelsSection {
  data = getWriteableDeepCopy(data);
  return transformSubmodels(data);
}

export function transformBackendSubmodelConfiguration(
  data: any,
): SubmodelConfiguration {
  data = getWriteableDeepCopy(data);
  return transformSubmodelConfiguration(data);
}

export function transformWorkspace(data: any): WorkspaceConfig {
  data = getWriteableDeepCopy(data);
  return transformWorkspaceInternal(data);
}

export function transformBackendStateMachines(data: any): StateMachines {
  data = getWriteableDeepCopy(data);
  return transformStateMachines(data);
}

// FIXME: updateBackendConfiguration doesn't fit here and mutates the input
export { updateBackendConfiguration };
