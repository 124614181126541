/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Model: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.65826 2.09223C7.87901 2.01195 8.12099 2.01195 8.34174 2.09223L13.3417 3.91041C13.7369 4.05411 14 4.4297 14 4.8502V11.1498C14 11.5703 13.7369 11.9459 13.3417 12.0896L8.34174 13.9078C8.12099 13.988 7.87901 13.988 7.65826 13.9078L2.65826 12.0896C2.26307 11.9459 2 11.5703 2 11.1498V4.8502C2 4.4297 2.26307 4.05411 2.65826 3.91041L7.65826 2.09223ZM3 5.34226L4.75 6.2968V9.61546L7.5 10.8262V12.7861L3 11.1498V5.34226ZM8.5 12.7861L13 11.1498V5.34226L11.25 6.2968V9.61546L8.5 10.8262V12.7861ZM8 9.95368L9.54161 9.27497L8 8.55221L6.45839 9.27497L8 9.95368ZM5.75 8.50264V6.3502L7.5 5.71384V7.68218L5.75 8.50264ZM8.5 7.68218L10.25 8.50264V6.3502L8.5 5.71384V7.68218ZM10.7087 5.45295L12.2883 4.59139L8 3.03202L3.71173 4.59139L5.29126 5.45295L8 4.46796L10.7087 5.45295Z"
      fill={props.fill}
    />
  </svg>
);

Model.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Model;
