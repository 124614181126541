import { v4 as uuid } from 'uuid';
import { nodeClassToPrintName } from 'app/helpers';
import blockTypeNameToInstanceDefaults from 'app/blockClassNameToInstanceDefaults';
import { BlockClassName } from 'app/generated_types/SimulationModel';
import { getBlockIconID } from 'util/getBlockIconID';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';

export interface BlockDisplayData {
  nodeId: string;
  incount: number;
  outcount: number;
  nodeTypePrintName: string;
  blockClassName: BlockClassName;
  overrideDisplayName?: string;
  overridePropDefaults?: any;
  blockIconId: string;
  submodel?: SubmodelInfoLiteUI;
  connectedIDs?: Set<string>;
}

export function getBuiltInBlockDisplayData(
  blockClassName: BlockClassName,
): BlockDisplayData {
  const shortName = blockClassName.split('.')[1];

  // TODO this is copied from elsewhere --
  // do we really need to build a whole instance just to get these couple bits of data?
  const node = blockTypeNameToInstanceDefaults(blockClassName);

  const nodeTypePrintName = nodeClassToPrintName(node.type);
  const incount = node.inputs.length;
  const outcount = node.outputs.length;

  return {
    nodeId: node.uuid,
    incount,
    outcount,
    nodeTypePrintName,
    blockClassName,
    blockIconId: getBlockIconID(shortName),
  };
}

export function getReferenceSubmodelBlockDisplayData(
  submodel: SubmodelInfoLiteUI,
): BlockDisplayData {
  return {
    nodeId: uuid(),
    incount: submodel.inputCount,
    outcount: submodel.outputCount,
    nodeTypePrintName: submodel.name,
    blockIconId: 'submodel',
    blockClassName: 'core.ReferenceSubmodel',
    submodel,
  };
}
