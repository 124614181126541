import { useTheme } from '@emotion/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { TimeModeType } from 'app/slices/compilationAnalysisDataSlice';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import React from 'react';
import { Continuous } from 'ui/common/Icons/Small';
import {
  SignalDragItem,
  TraceMetadata,
} from 'ui/dataExplorer/dataExplorerTypes';
import DraggableSignalTreeItem from 'ui/dataExplorer/drag/signalDrag/DraggableSignalTreeItem';
import { RightPinnedVisualizerButton } from 'ui/dataExplorer/simulationDataSidebar/RightPinnedButton';
import {
  ItemSection,
  ModelBlockItemText,
  ModelTreeIcon,
} from 'ui/objectBrowser/ModelTreeParts';
import { getDefaultChartType } from 'util/timeMode';
import { getTraceIdsForSimulationTrace } from 'util/traceLookup';
import { v4 as uuid } from 'uuid';

interface Props {
  modelId: string;
  simulationId: string;
  signalPath: string;
  addSignalRequestId?: string;
  vectorIndex: number;
  parentCount: number;
  parentPath?: string[];
  portIndex?: number;
  nodeId?: string;
  signalDisplayName: string;
  canEditProject: boolean;
  isSupported: boolean;
  timeMode?: TimeModeType;
}

export const ModelSimulationSignalTraceListItem: React.FC<Props> = ({
  modelId,
  simulationId,
  signalPath,
  addSignalRequestId,
  vectorIndex,
  parentCount,
  parentPath,
  nodeId,
  portIndex,
  signalDisplayName,
  canEditProject,
  isSupported,
  timeMode,
}) => {
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const tracePath = `${signalPath}[${vectorIndex}]`;
  const traceDisplayName = `${signalDisplayName}[${vectorIndex}]`;
  const traceIds = useAppSelector((state) =>
    getTraceIdsForSimulationTrace(
      simulationId,
      tracePath,
      state.dataExplorer.idToTrace,
    ),
  );

  const isTraceToggledOn: boolean = !!traceIds.length || !!addSignalRequestId;

  const toggleVisualization = React.useCallback(() => {
    if (!isSupported) {
      return;
    }

    // Don't allow adding/removing this trace if we have a higher
    // level request from the parent signal pending.
    if (addSignalRequestId) {
      return;
    }

    if (isTraceToggledOn && traceIds.length) {
      dispatch(
        dataExplorerActions.removeTraces({
          traceIds,
        }),
      );
    } else if (parentPath && nodeId && portIndex !== undefined) {
      const trace: TraceMetadata = {
        id: uuid(),
        signalPath,
        tracePath,
        vectorIndex,
        displayName: traceDisplayName,
        plotType: getDefaultChartType(timeMode),
        modelId,
        simulationId,
      };

      dispatch(
        dataExplorerActions.addTracesInNewPlotCells({
          newPlots: [
            {
              traces: [trace],
            },
          ],
        }),
      );
    }
  }, [
    dispatch,
    signalPath,
    parentPath,
    nodeId,
    portIndex,
    addSignalRequestId,
    isSupported,
    vectorIndex,
    traceIds,
    simulationId,
    modelId,
    isTraceToggledOn,
    tracePath,
    traceDisplayName,
    timeMode,
  ]);

  const traceSpec: SignalDragItem['traceSpecs'][number] = {
    signalPath,
    tracePath,
    vectorIndex,
    displayName: traceDisplayName,
    plotType: getDefaultChartType(timeMode),
    modelId,
    simulationId,
  };

  return (
    <ItemSection
      data-test-id={`data-explorer-model-simulation-signal-tree-model-vector-item-${traceDisplayName}`}
      selected={false}
      nestedLayer={parentCount + 3}>
      <ModelTreeIcon />
      <DraggableSignalTreeItem traceSpecs={[traceSpec]}>
        <ModelTreeIcon>
          <Continuous fill={theme.colors.text.secondary} />
        </ModelTreeIcon>
        <div
          data-test-id={`data-explorer-model-simulation-signal-tree-model-vector-item-text-${traceDisplayName}`}>
          <ModelBlockItemText>{vectorIndex}</ModelBlockItemText>
        </div>
      </DraggableSignalTreeItem>
      {isSupported && (
        <RightPinnedVisualizerButton
          path={tracePath}
          isToggledOn={isTraceToggledOn}
          canEditProject={canEditProject}
          toggleVisualization={toggleVisualization}
        />
      )}
    </ItemSection>
  );
};
