import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useProjectItems } from 'app/api/useProjectItems';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import React from 'react';
import { ActionsSpacer } from 'ui/common/Button/actionButtonStyles';
import { Search } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import { ActionButtonContainer } from 'ui/common/Modal/Modal';
import {
  AppContentWithFooterWrapper,
  AppContentWrapper,
} from 'ui/common/layout/appLayout';
import { ProjectsDetailTableData } from 'ui/dashboard/dashboardTypes';
import InProgressFileWatcher from 'ui/dashboard/projectDetail/InProgressFileWatcher';
import { ProjectDetailHeader } from 'ui/dashboard/projectDetail/ProjectDetailHeader';
import { ProjectDetailTable } from 'ui/dashboard/projectDetail/ProjectDetailTable';
import DashboardLeftSidebar from 'ui/dashboard/sidebar/Sidebar';
import { ProjectDetailActionButtons } from './ProjectDetailActionButtons';
import ProjectRightSidebar from './rightSidebar/ProjectRightSidebar';

export const ProjectDetailContent = styled.div`
  padding: ${(props) => props.theme.spacing.xlarge};
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.grey[5]};
  pointer-events: auto;
`;

// TODO pull out as an action row container, and clean up the multiple Header/Head components.
// This type of search + buttons component is used both in the projects list and project detail pages since they're a table view.
export const ProjectDetailHeadButtonsContainer = styled(ActionButtonContainer)`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
  width: 100%;
`;

const SearchBoxWrapper = styled.div`
  width: 256px;
  max-width: 100%;
`;

const shouldSeeProjectItem = (
  projectItem: ProjectsDetailTableData,
  searchString?: string,
) =>
  projectItem.name.toLowerCase().indexOf((searchString || '').toLowerCase()) >
  -1;

interface Props {
  project: Project;
}

const ProjectDetail: React.FC<Props> = ({ project }) => {
  const [searchString, setSearchString] = React.useState('');

  const { projectItems, hubFiles, fileIdToWatch } = useProjectItems(project);

  const filteredProjectItems = React.useMemo(() => {
    if (!projectItems) {
      return [];
    }
    if (searchString) {
      return projectItems.filter((projectItem) =>
        shouldSeeProjectItem(projectItem, searchString),
      );
    }
    return projectItems;
  }, [projectItems, searchString]);

  return (
    <AppContentWithFooterWrapper>
      <AppContentWrapper>
        <DashboardLeftSidebar />
        <ProjectDetailContent>
          <ProjectDetailHeader project={project} />
          <ProjectDetailHeadButtonsContainer>
            <SearchBoxWrapper>
              <Input
                hasBorder
                LeftIcon={Search}
                placeholder={t({
                  id: 'projectDetail.projectItemList.projectItemPlaceholder',
                  message: 'Search',
                })}
                onChangeText={setSearchString}
                clearable
              />
            </SearchBoxWrapper>
            <ActionsSpacer />
            <ProjectDetailActionButtons project={project} hubFiles={hubFiles} />
          </ProjectDetailHeadButtonsContainer>

          <ProjectDetailTable
            projectId={project.uuid}
            projectItems={filteredProjectItems}
          />
        </ProjectDetailContent>
        <ProjectRightSidebar project={project} />
      </AppContentWrapper>
      {fileIdToWatch && (
        <InProgressFileWatcher
          projectId={project.uuid}
          fileIdToWatch={fileIdToWatch}
        />
      )}
    </AppContentWithFooterWrapper>
  );
};

export default ProjectDetail;
