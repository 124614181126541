import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RightSidebarSection, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  ChevronLeft,
  ChevronRight,
  History,
  Properties,
} from 'ui/common/Icons/Small';
import {
  RightExpandCollapseButton,
  RightSidebar,
  SidebarTabGroupWrapper,
} from 'ui/common/layout/appLayout';
import TabGroup from 'ui/common/tabs/TabGroup';
import { TabInfo } from 'ui/common/tabs/tabTypes';
import PropertiesSidebar from 'ui/modelEditor/PropertiesSidebar';
import { DiagramVersionsPane } from 'ui/versionHistory/DiagramVersionsPane';

const DiagramRightSidebar: React.FC = () => {
  const dispatch = useAppDispatch();

  const isExpanded = useAppSelector(
    (state) => state.uiFlags.isRightSidebarOpen,
  );
  const setIsExpanded = (isExpanded: boolean) => {
    dispatch(uiFlagsActions.setUIFlag({ isRightSidebarOpen: isExpanded }));
  };

  const section: RightSidebarSection = useAppSelector(
    (state) => state.uiFlags.currentRightSidebarSection,
  );

  const renderSection = () => {
    switch (section) {
      case RightSidebarSection.Properties:
        return <PropertiesSidebar />;
      case RightSidebarSection.VersionHistory:
        return <DiagramVersionsPane />;
    }
  };

  const tabs = React.useMemo(() => {
    const tabBuilder: TabInfo[] = [
      {
        id: 'properties',
        Icon: Properties,
        displayName: t({
          id: 'modelEditor.rightSidebarTab.properties',
          message: 'Properties',
        }),
        onClick: () => {
          dispatch(
            uiFlagsActions.setRightSidebarTab(RightSidebarSection.Properties),
          );
        },
        isSelected: () => section === RightSidebarSection.Properties,
      },
      {
        id: 'versionHistory',
        Icon: History,
        displayName: t({
          id: 'modelEditor.rightSidebarTab.versionHistory',
          message: 'Version History',
        }),
        onClick: () => {
          dispatch(
            uiFlagsActions.setRightSidebarTab(
              RightSidebarSection.VersionHistory,
            ),
          );
        },
        isSelected: () => section === RightSidebarSection.VersionHistory,
      },
    ];

    return tabBuilder;
  }, [dispatch, section]);

  return (
    <>
      <RightSidebar isExpanded={isExpanded} className="tour-onboarding-5">
        <SidebarTabGroupWrapper>
          <TabGroup tabs={tabs} testId="model-right-sidebar-tabs" />
        </SidebarTabGroupWrapper>
        {renderSection()}
      </RightSidebar>
      <RightExpandCollapseButton
        testId="toggle-model-right-sidebar-button"
        Icon={isExpanded ? ChevronRight : ChevronLeft}
        variant={ButtonVariants.LargeSecondary}
        onClick={() => setIsExpanded(!isExpanded)}
      />
    </>
  );
};

export default DiagramRightSidebar;
