import { useTheme } from '@emotion/react';
import { useProject } from 'app/api/useProject';
import { useSubmodel } from 'app/api/useSubmodel';
import { getAdminAssumedUser } from 'app/config/adminAssumedUser';
import { useAppSelector } from 'app/hooks';
import { NavbarContext } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { useMatch } from 'react-router-dom';
import BrandLogo from 'ui/navbar/BrandLogo';
import { HelpButton } from 'ui/navbar/HelpButton';
import { SimulationTools } from 'ui/navbar/SimulationTools';
import { SubmodelTools } from 'ui/navbar/SubmodelTools';
import CopyPublicProjectButton from 'ui/navbar/actionButtons/CopyPublicProjectButton';
import { getProjectFilter } from 'util/projectFilterUtils';
import AdminButton from './AdminButton';
import ApiKeys from './ApiKeys';
import CellFormatMenu from './CellFormatMenu';
import ClerkButton from './ClerkButton';
import { DiagramTools } from './DiagramTools';
import HomeButton from './HomeButton';
import { ModelEditorControls } from './ModelEditorControls';
import { NavbarSpace } from './NavbarButtons';
import {
  LeftRegion,
  NavbarRegion,
  NavbarWrapper,
  RightRegion,
} from './NavbarCommon';
import NotebookControls from './NotebookControls';
import NotebookTools from './NotebookTools';
import ProjectTools from './ProjectTools';
import { RequirementsNavbar } from './RequirementsNavbar';
import DuplicateHighLevelBlockButton from './actionButtons/DuplicateHighLevelBlockButton';

// TODO: refactor in UI-1226
export const Navbar: React.FC = () => {
  const navbarContext = useAppSelector((state) => state.uiFlags.navbarContext);

  // Determine project status
  const { project } = useProject();
  const userId = useAppSelector((state) => state.user.userId);
  const currentUserId = getAdminAssumedUser() ?? userId;
  const projectFilter =
    !!currentUserId && !!project && getProjectFilter(project, currentUserId);
  const isPublicProject = !!project && projectFilter === 'public';
  const showCopyProject =
    navbarContext === NavbarContext.ModelEditor && isPublicProject;

  // Determine if HLB
  const referenceSubmodelId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelReferenceId,
  );
  const { submodel, isFetching } = useSubmodel(
    project?.uuid,
    referenceSubmodelId,
    {
      skip: !project || !referenceSubmodelId,
    },
  );

  // If looking at an HLB from a public project, don't show button to copy HLB.
  // They should copy the public project first, then copy the HLB from their own project.
  const isHLB = !!referenceSubmodelId && !isFetching && submodel?.isGlobal;
  const showCopyHLB =
    navbarContext === NavbarContext.ModelEditor && isHLB && !isPublicProject;

  const theme = useTheme();

  const fileName =
    useMatch('/projects/:projectId/notebook/:fileName')?.params?.fileName || '';

  const isNotebookFile = fileName.endsWith('.ipynb');

  const isOnHomeRoute =
    navbarContext === NavbarContext.None ||
    navbarContext === NavbarContext.Dashboard;

  const isOnProjectSubRoute =
    navbarContext === NavbarContext.ModelEditor ||
    navbarContext === NavbarContext.Notebook ||
    navbarContext === NavbarContext.DataExplorer ||
    navbarContext === NavbarContext.BusTypeEditor ||
    navbarContext === NavbarContext.ScriptEditor;

  if (navbarContext === NavbarContext.Requirements) {
    return <RequirementsNavbar />;
  }

  return (
    <NavbarWrapper className="tour-onboarding-0">
      {/* Left Navbar Region */}
      <LeftRegion>
        {isOnHomeRoute ? <BrandLogo /> : <HomeButton />}
        {isOnProjectSubRoute && <ProjectTools />}
        {navbarContext === NavbarContext.ModelEditor && <DiagramTools />}
        {navbarContext === NavbarContext.Notebook && (
          <NotebookTools isNotebookFile={isNotebookFile} />
        )}
      </LeftRegion>

      {/* Center Navbar Region */}
      <NavbarRegion>
        {navbarContext === NavbarContext.ModelEditor && <ModelEditorControls />}
        {navbarContext === NavbarContext.Notebook && (
          <NotebookControls isNotebookFile={isNotebookFile} />
        )}
      </NavbarRegion>

      {/* Right Navbar Region */}
      <RightRegion>
        {showCopyProject && (
          <>
            <CopyPublicProjectButton
              project={project}
              tint={theme.colors.brand.tertiary.darker}
            />
            <NavbarSpace />
          </>
        )}
        {showCopyHLB && !!project && (
          <>
            <DuplicateHighLevelBlockButton
              project={project}
              hlb={submodel}
              tint={theme.colors.brand.tertiary.darker}
            />
            <NavbarSpace />
          </>
        )}
        <SimulationTools />
        {navbarContext === NavbarContext.ModelEditor && !isHLB && (
          <SubmodelTools />
        )}
        {navbarContext === NavbarContext.Notebook && (
          <CellFormatMenu isNotebookFile={isNotebookFile} />
        )}
        {navbarContext === NavbarContext.Dashboard && (
          <>
            <AdminButton />
            <ApiKeys />
          </>
        )}
        <HelpButton />
        <ClerkButton />
      </RightRegion>
    </NavbarWrapper>
  );
};
