/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Labels: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M17.5 8.25C17.5 9.2165 16.7165 10 15.75 10C14.7835 10 14 9.2165 14 8.25C14 7.2835 14.7835 6.5 15.75 6.5C16.7165 6.5 17.5 7.2835 17.5 8.25ZM16.5 8.25C16.5 8.66421 16.1642 9 15.75 9C15.3358 9 15 8.66421 15 8.25C15 7.83579 15.3358 7.5 15.75 7.5C16.1642 7.5 16.5 7.83579 16.5 8.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.0809 4.25C12.6168 4.25 12.1716 4.43437 11.8434 4.76256L4.17678 12.4292C3.49336 13.1126 3.49336 14.2207 4.17678 14.9041L9.0959 19.8232C9.77932 20.5066 10.8874 20.5066 11.5708 19.8232L19.2374 12.1566C19.5656 11.8284 19.75 11.3832 19.75 10.9191V6C19.75 5.0335 18.9665 4.25 18 4.25H13.0809ZM12.9041 5.82322C12.951 5.77634 13.0146 5.75 13.0809 5.75H18C18.1381 5.75 18.25 5.86193 18.25 6V10.9191C18.25 10.9854 18.2237 11.049 18.1768 11.0959L10.5101 18.7626C10.4125 18.8602 10.2542 18.8602 10.1566 18.7626L5.23744 13.8434C5.13981 13.7458 5.13981 13.5875 5.23744 13.4899L12.9041 5.82322Z"
      fill={props.fill}
    />
  </svg>
);

Labels.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Labels;
