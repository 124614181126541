import { useTheme } from '@emotion/react';
import { Port } from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { LogOutput, WatchInVisualizer } from 'ui/common/Icons/Standard';
import { useVisualizerPrefs } from 'ui/modelEditor/useVisualizerPrefs';
import { getIsPortSupported } from 'util/portTypeUtils';

interface Props {
  nodeId: string;
  parentPath: string[];
  portIndex: number;
  output: Port;
}

const VisualizerToggler: React.FC<Props> = ({
  nodeId,
  parentPath,
  portIndex,
  output,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { recordModeOptionEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const { getIsPortInChart, removePortsFromChart, addPortsToChart } =
    useVisualizerPrefs();
  const portSupported = getIsPortSupported({ nodeId, parentPath, portIndex });
  const isPortInChart = getIsPortInChart({ nodeId, portIndex, parentPath });

  const recordOnlySelectedOutputs = useAppSelector(
    (state) =>
      state.userOptions.options.recordModeOptionEnabled &&
      state.model.present.configuration.record_mode === 'selected',
  );

  const toggleRecord = () => {
    if (recordModeOptionEnabled && output) {
      // This is the other place output record is changed outside of VisualizationTraceRecordTracker
      // since turning it off it isn't derived from visualizer toggles.
      dispatch(
        modelActions.setOutputPortRecord({
          parentPath,
          nodeUuid: nodeId,
          portId: portIndex,
          record: !output.record,
        }),
      );

      if (output.record) {
        removePortsFromChart([
          {
            nodeId,
            portIndex,
            parentPath,
          },
        ]);
      }
    }
  };

  const toggleVisualizer = () => {
    if (!isPortInChart) {
      addPortsToChart([{ nodeId, portIndex, parentPath }]);
    } else {
      removePortsFromChart([
        {
          nodeId,
          portIndex,
          parentPath,
        },
      ]);
    }
  };

  return (
    <>
      {output && recordModeOptionEnabled && (
        <Button
          testId={`block-output-visualizer-record-button-${portIndex}`}
          disabled={!recordOnlySelectedOutputs}
          variant={ButtonVariants.LargeTertiary}
          tint={
            ((output.record || !recordOnlySelectedOutputs) &&
              theme.colors.brand.primary.darker) ||
            undefined
          }
          Icon={LogOutput}
          onClick={toggleRecord}
        />
      )}
      {portSupported && (
        <Button
          testId={`block-output-visualizer-button-${portIndex}`}
          tint={isPortInChart ? theme.colors.brand.primary.base : undefined}
          onClick={toggleVisualizer}
          variant={ButtonVariants.LargeTertiary}
          Icon={WatchInVisualizer}
        />
      )}
    </>
  );
};

export default VisualizerToggler;
