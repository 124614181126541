import { t } from '@lingui/macro';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings } from 'ui/common/Icons/Standard';
import { NavbarButton } from './NavbarButtons';

const AdminButton: React.FC = () => {
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const navigate = useNavigate();

  if (!developerModeEnabled) return null;

  return (
    <NavbarButton
      tooltipText={t({
        id: 'navBar.adminPortal',
        message: 'Admin portal',
      })}
      Icon={Settings}
      onClickHandler={() => navigate('/admin')}
      isEnabled
      testId="navbar-admin-button"
    />
  );
};

export default AdminButton;
