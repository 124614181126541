import {
  BlockInstance,
  BlockClassName,
} from 'app/generated_types/SimulationModel';
import { PortSide } from 'app/common_types/PortTypes';
import { t } from '@lingui/macro';

export interface PortCondition {
  shouldHavePort: (block: BlockInstance) => boolean;
  side: PortSide;

  /**
   * Name of conditional port
   */
  portName?: string;

  /**
   * Whether the condition applies to dynamic ports in addition to any
   * named conditional ports.
   */
  appliesToDynamicPorts?: boolean;
}

const logicalOperatorPortConditions: PortCondition[] = [
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.function?.value !== 'not',
    side: PortSide.Input,
    portName: 'in_1',
    appliesToDynamicPorts: true,
  },
];

const matrixOperatorPortConditions: PortCondition[] = [
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.function?.value === 'multiply' ||
      block.parameters.function?.value === 'concatenation',
    side: PortSide.Input,
    portName: 'in_1',
  },
];

const integratorPortConditions: PortCondition[] = [
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_reset?.value === 'true',
    side: PortSide.Input,
    portName: 'reset',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_reset?.value === 'true' &&
      block.parameters.enable_external_reset?.value === 'true',
    side: PortSide.Input,
    portName: 'reset_value',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_hold?.value === 'true',
    side: PortSide.Input,
    portName: 'hold',
  },
];

const pidPortConditions: PortCondition[] = [
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_external_initial_state?.value === 'true',
    side: PortSide.Input,
    portName: 'initial_state',
  },
];

const limitPortConditions: PortCondition[] = [
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_dynamic_upper_limit?.value === 'true',
    side: PortSide.Input,
    portName: 'upper_limit',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_dynamic_lower_limit?.value === 'true',
    side: PortSide.Input,
    portName: 'lower_limit',
  },
];

const rigidBodyPortConditions: PortCondition[] = [
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_external_mass?.value === 'true',
    side: PortSide.Input,
    portName: 'mass',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_rotational_DoFs?.value === 'true',
    side: PortSide.Input,
    portName: 'torque_vector',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_rotational_DoFs?.value === 'true' &&
      block.parameters.enable_external_inertia_matrix?.value === 'true',
    side: PortSide.Input,
    portName: 'inertia_matrix',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_rotational_DoFs?.value === 'true',
    side: PortSide.Output,
    portName: 'rotational_states',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_output_state_derivatives?.value === 'true',
    side: PortSide.Output,
    portName: 'state_derivatives',
  },
];

const coordinateRotationPortConditions: PortCondition[] = [
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.rotation_type?.value === 'quaternion' &&
      block.parameters.enable_external_rotation_definition?.value === 'true',
    side: PortSide.Input,
    portName: 'quaternion_w',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.rotation_type?.value === 'quaternion' &&
      block.parameters.enable_external_rotation_definition?.value === 'true',
    side: PortSide.Input,
    portName: 'quaternion_x',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.rotation_type?.value === 'quaternion' &&
      block.parameters.enable_external_rotation_definition?.value === 'true',
    side: PortSide.Input,
    portName: 'quaternion_y',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.rotation_type?.value === 'quaternion' &&
      block.parameters.enable_external_rotation_definition?.value === 'true',
    side: PortSide.Input,
    portName: 'quaternion_z',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.rotation_type?.value === 'roll_pitch_yaw' &&
      block.parameters.enable_external_rotation_definition?.value === 'true',
    side: PortSide.Input,
    portName: 'roll',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.rotation_type?.value === 'roll_pitch_yaw' &&
      block.parameters.enable_external_rotation_definition?.value === 'true',
    side: PortSide.Input,
    portName: 'pitch',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.rotation_type?.value === 'roll_pitch_yaw' &&
      block.parameters.enable_external_rotation_definition?.value === 'true',
    side: PortSide.Input,
    portName: 'yaw',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.rotation_type?.value === 'DCM' &&
      block.parameters.enable_external_rotation_definition?.value === 'true',
    side: PortSide.Input,
    portName: 'DCM',
  },
];

const coordinateRotationConversionPortConditions: PortCondition[] = [
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'quaternion_to_DCM' ||
      block.parameters.conversion_type?.value === 'quaternion_to_RPY',
    side: PortSide.Input,
    portName: 'quaternion_w',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'quaternion_to_DCM' ||
      block.parameters.conversion_type?.value === 'quaternion_to_RPY',
    side: PortSide.Input,
    portName: 'quaternion_x',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'quaternion_to_DCM' ||
      block.parameters.conversion_type?.value === 'quaternion_to_RPY',
    side: PortSide.Input,
    portName: 'quaternion_y',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'quaternion_to_DCM' ||
      block.parameters.conversion_type?.value === 'quaternion_to_RPY',
    side: PortSide.Input,
    portName: 'quaternion_z',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'RPY_to_quaternion' ||
      block.parameters.conversion_type?.value === 'RPY_to_DCM',
    side: PortSide.Input,
    portName: 'roll',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'RPY_to_quaternion' ||
      block.parameters.conversion_type?.value === 'RPY_to_DCM',
    side: PortSide.Input,
    portName: 'pitch',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'RPY_to_quaternion' ||
      block.parameters.conversion_type?.value === 'RPY_to_DCM',
    side: PortSide.Input,
    portName: 'yaw',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'DCM_to_quaternion' ||
      block.parameters.conversion_type?.value === 'DCM_to_RPY',
    side: PortSide.Input,
    portName: 'DCM',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'RPY_to_quaternion' ||
      block.parameters.conversion_type?.value === 'DCM_to_quaternion',
    side: PortSide.Output,
    portName: 'quaternion_w',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'RPY_to_quaternion' ||
      block.parameters.conversion_type?.value === 'DCM_to_quaternion',
    side: PortSide.Output,
    portName: 'quaternion_x',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'RPY_to_quaternion' ||
      block.parameters.conversion_type?.value === 'DCM_to_quaternion',
    side: PortSide.Output,
    portName: 'quaternion_y',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'RPY_to_quaternion' ||
      block.parameters.conversion_type?.value === 'DCM_to_quaternion',
    side: PortSide.Output,
    portName: 'quaternion_z',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'quaternion_to_RPY' ||
      block.parameters.conversion_type?.value === 'DCM_to_RPY',
    side: PortSide.Output,
    portName: 'roll',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'quaternion_to_RPY' ||
      block.parameters.conversion_type?.value === 'DCM_to_RPY',
    side: PortSide.Output,
    portName: 'pitch',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'quaternion_to_RPY' ||
      block.parameters.conversion_type?.value === 'DCM_to_RPY',
    side: PortSide.Output,
    portName: 'yaw',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.conversion_type?.value === 'quaternion_to_DCM' ||
      block.parameters.conversion_type?.value === 'RPY_to_DCM',
    side: PortSide.Output,
    portName: 'DCM',
  },
];

/**
 * Note: Provides port conditions in an order such that for each port side, the
 * conditions are ordered so that the ports with smallest 'order' property come first.
 * Ensure that all port conditions are specified in this specific order
 * so that the conditional ports are added/removed correctly.
 */
export function getPortConditions(
  blockType: BlockClassName,
): PortCondition[] | null {
  switch (blockType) {
    case 'core.LogicalOperator':
      return logicalOperatorPortConditions;

    case 'core.MatrixOperator':
      return matrixOperatorPortConditions;

    case 'core.Integrator':
    case 'core.IntegratorDiscrete':
      return integratorPortConditions;

    case 'core.Saturate':
    case 'core.RateLimiter':
      return limitPortConditions;

    case 'core.RigidBody':
      return rigidBodyPortConditions;

    case 'core.PID':
    case 'core.PID_Discrete':
      return pidPortConditions;

    case 'core.CoordinateRotation':
      return coordinateRotationPortConditions;

    case 'core.CoordinateRotationConversion':
      return coordinateRotationConversionPortConditions;

    default:
      return null;
  }
}

export function getPortDisplayName(
  nodeType: BlockClassName,
  portName: string,
): string {
  // Expose localized names where the display name is different from the name in the schema.
  switch (nodeType) {
    case 'core.CoordinateRotation':
    case 'core.CoordinateRotationConversion':
      if (portName === 'quaternion_w') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.quaternion_w',
          message: 'q_w',
        });
      }
      if (portName === 'quaternion_x') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.quaternion_x',
          message: 'q_x',
        });
      }
      if (portName === 'quaternion_y') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.quaternion_y',
          message: 'q_y',
        });
      }
      if (portName === 'quaternion_z') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.quaternion_z',
          message: 'q_z',
        });
      }
      if (portName === 'roll') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.roll',
          message: 'roll',
        });
      }
      if (portName === 'pitch') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.pitch',
          message: 'pitch',
        });
      }
      if (portName === 'yaw') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.yaw',
          message: 'yaw',
        });
      }
      break;
  }

  return portName;
}
