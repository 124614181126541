import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import {
  LinkInstance,
  NodeInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { MutableRefObject } from 'react';

export type CurrentModelRef = {
  projectId: string;
  modelId: string;
  topLevelModelType: ModelKind | null;
  topLevelNodes: NodeInstance[];
  topLevelLinks: LinkInstance[];
  submodels: SubmodelsSection;
  /** current subdiagram path */
  submodelPath: string[];
};

interface CurrentModelRefWrapper {
  refs: MutableRefObject<CurrentModelRef>;
}

let currentModelRefWrapper: CurrentModelRefWrapper | null = null;

export function initCurrentModelRef(refs: MutableRefObject<CurrentModelRef>) {
  currentModelRefWrapper = {
    refs,
  };
}

export function getCurrentModelRef(): CurrentModelRef {
  if (currentModelRefWrapper) {
    return currentModelRefWrapper.refs.current;
  }

  throw new Error('Tried to access CurrentModelRef before it was initialized.');
}
