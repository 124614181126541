// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const CoordinateRotation: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "CoordinateRotation",
      "namespace": "core",
      "description": "computes rotation between coordinate systems, e.g. quaternion, DCM, etc.",
      "keywords": [
        "quaternion",
        "dcm",
        "rotation",
        "angles",
        "euler",
        "angle",
        "conversion",
        "coordinate"
      ],
      "development_notes": "when quaternion selected: expose either w,x,y,z inports or params. when roll_pitch_yaw selected, expose either roll,pitch,yaw inports or params. when DCM selected, expose DCM inport or param. "
    },
    "modes": {
      "time": "agnostic",
      "feedthrough": true
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "vector"
          }
        ],
        "conditional": [
          {
            "name": "quaternion_w",
            "order": 1
          },
          {
            "name": "quaternion_x",
            "order": 2
          },
          {
            "name": "quaternion_y",
            "order": 3
          },
          {
            "name": "quaternion_z",
            "order": 4
          },
          {
            "name": "roll",
            "order": 5
          },
          {
            "name": "pitch",
            "order": 6
          },
          {
            "name": "yaw",
            "order": 7
          },
          {
            "name": "DCM",
            "order": 8
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "vector"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "rotation_type",
        "data_type": "string",
        "default_value": "quaternion",
        "description": "Select the rotation type desired.",
        "allowed_values": [
          "quaternion",
          "roll_pitch_yaw",
          "DCM"
        ],
        "display_full_width": true
      },
      {
        "name": "enable_external_rotation_definition",
        "data_type": "bool",
        "default_value": "false",
        "description": "Source for the rotation parameters. True=from inport(s), False=from the block parameters."
      },
      {
        "name": "quaternion_w",
        "data_type": "float",
        "default_value": "0.0",
        "description": "Scalar component of quaternion."
      },
      {
        "name": "quaternion_x",
        "data_type": "float",
        "default_value": "0.0",
        "description": "x-component of quaternion."
      },
      {
        "name": "quaternion_y",
        "data_type": "float",
        "default_value": "0.0",
        "description": "y-component of quaternion."
      },
      {
        "name": "quaternion_z",
        "data_type": "float",
        "default_value": "0.0",
        "description": "z-component of quaternion."
      },
      {
        "name": "roll",
        "data_type": "float",
        "default_value": "0.0",
        "description": "Roll angle (theta)."
      },
      {
        "name": "pitch",
        "data_type": "float",
        "default_value": "0.0",
        "description": "Pitch angle (phi)."
      },
      {
        "name": "yaw",
        "data_type": "float",
        "default_value": "0.0",
        "description": "Yaw angle (psi)."
      },
      {
        "name": "DCM_matrix",
        "data_type": "any",
        "default_value": "[[1.0,0.0,0.0],\n[0.0,1.0,0.0],\n[0.0,0.0,1.0]]\n",
        "description": "DCM 3x3 matrix"
      }
    ]
  }
);
