/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const AiAgent: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.60617 4.52458C9.8344 4.17891 10.2996 4.08371 10.6453 4.31194C10.991 4.54016 11.0862 5.0054 10.8579 5.35106C10.2698 6.24187 9.91352 6.97533 9.71219 7.72672C9.51085 8.47812 9.45265 9.29145 9.5166 10.357C9.54142 10.7705 9.22636 11.1258 8.81289 11.1506C8.39942 11.1754 8.04412 10.8603 8.0193 10.4469C7.94928 9.28035 8.0071 8.29463 8.2633 7.3385C8.51949 6.38236 8.96228 5.4998 9.60617 4.52458Z"
      fill={props.fill}
    />
    <path
      d="M6.723 17.8108C6.30954 17.786 5.99447 17.4307 6.01929 17.0172C6.04411 16.6038 6.39941 16.2887 6.81288 16.3135C7.87842 16.3775 8.69174 16.3193 9.44314 16.1179C10.1945 15.9166 10.928 15.5604 11.8188 14.9722C12.1645 14.744 12.6297 14.8392 12.8579 15.1848C13.0862 15.5305 12.991 15.9957 12.6453 16.224C11.6701 16.8679 10.7875 17.3106 9.83137 17.5668C8.87523 17.823 7.88952 17.8808 6.723 17.8108Z"
      fill={props.fill}
    />
    <path
      d="M4.66459 9.32918C4.2941 9.51442 4.14393 9.96493 4.32918 10.3354C4.85179 11.3806 5.39473 12.2054 6.09467 12.9053C6.7946 13.6053 7.61935 14.1482 8.66459 14.6708C9.03507 14.8561 9.48558 14.7059 9.67082 14.3354C9.85606 13.9649 9.70589 13.5144 9.33541 13.3292C8.38064 12.8518 7.70539 12.3947 7.15533 11.8447C6.60526 11.2946 6.1482 10.6194 5.67082 9.66459C5.48557 9.29411 5.03507 9.14394 4.66459 9.32918Z"
      fill={props.fill}
    />
    <path
      d="M14.3938 19.4754C14.1656 19.8211 13.7004 19.9163 13.3547 19.6881C13.009 19.4598 12.9138 18.9946 13.1421 18.6489C13.7302 17.7581 14.0865 17.0247 14.2878 16.2733C14.4891 15.5219 14.5473 14.7086 14.4834 13.643C14.4586 13.2295 14.7736 12.8742 15.1871 12.8494C15.6006 12.8246 15.9559 13.1397 15.9807 13.5531C16.0507 14.7197 15.9929 15.7054 15.7367 16.6615C15.4805 17.6176 15.0377 18.5002 14.3938 19.4754Z"
      fill={props.fill}
    />
    <path
      d="M17.9807 6.98276C18.0055 6.56929 17.6905 6.21398 17.277 6.18917C16.1105 6.11915 15.1248 6.17697 14.1686 6.43317C13.2125 6.68936 12.3299 7.13214 11.3547 7.77604C11.009 8.00427 10.9138 8.4695 11.1421 8.81516C11.3703 9.16083 11.8355 9.25603 12.1812 9.02781C13.072 8.43965 13.8055 8.08339 14.5569 7.88206C15.3083 7.68072 16.1216 7.62251 17.1871 7.68647C17.6006 7.71129 17.9559 7.39622 17.9807 6.98276Z"
      fill={props.fill}
    />
    <path
      d="M19.6708 13.6646C19.8561 14.0351 19.7059 14.4856 19.3354 14.6708C18.9649 14.8561 18.5144 14.7059 18.3292 14.3354C17.8518 13.3806 17.3947 12.7054 16.8447 12.1553C16.2946 11.6053 15.6193 11.1482 14.6646 10.6708C14.2941 10.4856 14.1439 10.0351 14.3292 9.66459C14.5144 9.2941 14.9649 9.14393 15.3354 9.32918C16.3806 9.85179 17.2054 10.3947 17.9053 11.0947C18.6053 11.7946 19.1482 12.6193 19.6708 13.6646Z"
      fill={props.fill}
    />
  </svg>
);

AiAgent.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default AiAgent;
