import { t } from '@lingui/macro';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Code } from 'ui/common/Icons/Standard';
import { useModal } from 'ui/common/Modal/useModal';
import { CreateScriptModal } from './CreateScriptModal';

interface Props {
  project: Project;
}

export const ProjectDetailCreateScriptButton: React.FC<Props> = ({
  project,
}) => {
  const navigate = useNavigate();
  const { triggerModal } = useModal();

  const onScriptCreated = (fileUuid: string) => {
    navigate(`/projects/${project.uuid}/python_script/${fileUuid}`);
  };

  const openCreateScriptModal = () => {
    triggerModal(
      <CreateScriptModal
        projectId={project.uuid}
        onCreated={onScriptCreated}
      />,
      t({
        id: 'createScriptModal.title',
        message: 'Create new Python script',
      }),
    );
  };

  return (
    <Button
      variant={ButtonVariants.SmallSecondary}
      Icon={Code}
      testId="project-detail-new-file-dropdown-button"
      onClick={openCreateScriptModal}>
      {t({
        id: 'dashboard.projectsDetailHeader.newScriptButton.Label',
        message: 'New Script',
      })}
    </Button>
  );
};
