import { useGetCsvFileQuery } from 'app/api/useVisualizerData';
import { useGetSimulationProcessResultsReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { useAppSelector } from 'app/hooks';
import { ModelLogLine, OutputLogLevel } from 'app/slices/simResultsSlice';
import React, { useRef } from 'react';
import { fetchSimulationLogs } from 'ui/modelEditor/utils';
import { useAppParams } from 'util/useAppParams';

export interface OnSimCompleteCallbackInput {
  lastResults?: string;
  columnNames?: Array<string>;
  error?: string;
}

export type OnSimCompleteCallback = (input: OnSimCompleteCallbackInput) => void;

export interface SimCompleteListenerContext {
  subscribeToNextSimRunComplete: (f: OnSimCompleteCallback) => number;
  subscribeToNextSimCompileComplete: (f: OnSimCompleteCallback) => number;
  unsubscribeToNextSimRunComplete: (id: number) => void;
  unsubscribeToNextSimCompileComplete: (id: number) => void;
  lastResults?: React.MutableRefObject<string>;
  lastResultsColumnNames?: React.MutableRefObject<string[]>;
  lastResultsIsDirty?: React.MutableRefObject<boolean>;
}

const singletonContext = React.createContext<SimCompleteListenerContext>({
  subscribeToNextSimRunComplete: () => 0,
  subscribeToNextSimCompileComplete: () => 0,
  unsubscribeToNextSimRunComplete: (id: number) => {},
  unsubscribeToNextSimCompileComplete: (id: number) => {},
});

const useSimCompleteListenerContext = () => {
  const lastResults = useRef<string>('');
  const lastResultsIsDirty = useRef<boolean>(true);
  const lastResultsColumnNames = useRef<string[]>([]);

  const { modelId } = useAppParams();

  const simulationSummary = useAppSelector(
    (state) => state.project.simulationSummary,
  );

  const [currentSimulationUuid, setCurrentSimulationUuid] = React.useState<
    string | undefined
  >();
  const [csvSimulationUuid, setCsvSimulationUuid] = React.useState<
    string | undefined
  >();

  const { data: simData } = useGetSimulationProcessResultsReadByUuidQuery(
    {
      modelUuid: modelId || '',
      simulationUuid: simulationSummary?.uuid || '',
      files: 'continuous_results.csv',
    },
    {
      skip:
        !simulationSummary?.uuid ||
        !modelId ||
        !simulationSummary.results_available,
    },
  );
  const { data: csvFile, isError: getCsvFileIsError } = useGetCsvFileQuery(
    {
      chartIds: ['continuous_results.csv'],
      files: simData?.s3_urls,
    },
    {
      skip: !simData?.s3_urls,
    },
  );

  const hasCachedResults =
    simulationSummary?.results_available &&
    simulationSummary?.uuid === csvSimulationUuid;
  const hasNewResults =
    simulationSummary?.results_available &&
    csvSimulationUuid !== undefined &&
    csvSimulationUuid !== currentSimulationUuid;
  const shouldTriggerCallbacks =
    hasNewResults || hasCachedResults || getCsvFileIsError;

  const onSimRunCompleteSubscribers = useRef<
    Map<number, OnSimCompleteCallback>
  >(new Map());
  const onSimRunCompleteSubscribersIdCount = useRef<number>(0);

  const onSimCompileCompleteSubscribers = useRef<
    Map<number, OnSimCompleteCallback>
  >(new Map());
  const onSimCompileCompleteSubscribersIdCount = useRef<number>(0);

  const editId = useAppSelector((state) => state.modelMetadata.editId);

  const lastSimulationEditId = useAppSelector(
    (state) => state.modelMetadata.lastSimulationEditId,
  );

  const modelUpdatedAtStr = useAppSelector(
    (state) => state.modelMetadata.loadedVersionUpdatedAt,
  );

  // HACK: We can't reliably predict whether the results are dirty just by
  // looking at the editId because UpdateAPIDispatcher will sometime update the
  // editId after the simulation results are updated.
  const resultExpiryInMilli = React.useMemo(() => {
    if (!simulationSummary || !modelUpdatedAtStr) return 0;
    const lastResultsUpdatedAt = new Date(simulationSummary.updated_at);
    const modelUpdatedAt = new Date(modelUpdatedAtStr);
    return modelUpdatedAt.getTime() - lastResultsUpdatedAt.getTime();
  }, [modelUpdatedAtStr, simulationSummary]);

  lastResultsIsDirty.current =
    lastSimulationEditId !== editId && resultExpiryInMilli > 2000;

  const subscribeToNextSimRunComplete = React.useCallback(
    (f: OnSimCompleteCallback) => {
      const id = onSimRunCompleteSubscribersIdCount.current;
      onSimRunCompleteSubscribersIdCount.current += 1;
      onSimRunCompleteSubscribers.current.set(id, f);
      return id;
    },
    [],
  );

  const unsubscribeToNextSimRunComplete = React.useCallback((id: number) => {
    onSimRunCompleteSubscribers.current.delete(id);
  }, []);

  const subscribeToNextSimCompileComplete = React.useCallback(
    (f: OnSimCompleteCallback) => {
      const id = onSimCompileCompleteSubscribersIdCount.current;
      onSimCompileCompleteSubscribersIdCount.current += 1;
      onSimCompileCompleteSubscribers.current.set(id, f);
      return id;
    },
    [],
  );

  const unsubscribeToNextSimCompileComplete = React.useCallback(
    (id: number) => {
      onSimCompileCompleteSubscribers.current.delete(id);
    },
    [],
  );

  React.useEffect(() => {
    if (csvFile !== undefined) {
      setCsvSimulationUuid(simulationSummary?.uuid);
    }
  }, [csvFile, simulationSummary?.uuid]);

  // Call the onSimCompileComplete callback when the simulation is completed.
  React.useEffect(() => {
    if (simulationSummary?.status !== 'completed') {
      return;
    }
    try {
      onSimCompileCompleteSubscribers.current.forEach((f) => f({}));
    } finally {
      onSimCompileCompleteSubscribers.current = new Map();
    }
  }, [simulationSummary?.status]);

  // On failure
  React.useEffect(() => {
    if (!simulationSummary?.fail_reason) {
      return;
    }

    async function fetch() {
      if (!simulationSummary || simulationSummary.status !== 'failed') {
        return;
      }
      const simulationLogs: ModelLogLine[] = await fetchSimulationLogs(
        simulationSummary,
      );
      const simulationErrorLogs = simulationLogs
        .filter(
          (log) =>
            log.level === OutputLogLevel.ERR ||
            log.level === OutputLogLevel.FATAL ||
            log.level === undefined,
        )
        .map((log: ModelLogLine) => log.message);
      try {
        onSimRunCompleteSubscribers.current.forEach((f) =>
          f({
            error:
              `Results not available: ${simulationSummary?.fail_reason}\n\n` +
              `Simulation logs:\n${simulationErrorLogs.join('\n')}`,
          }),
        );
        onSimCompileCompleteSubscribers.current.forEach((f) =>
          f({ error: simulationSummary.fail_reason }),
        );
      } finally {
        onSimRunCompleteSubscribers.current = new Map();
        onSimCompileCompleteSubscribers.current = new Map();
      }
    }
    fetch();
  }, [simulationSummary]);

  // Keep the latest results in sync when a new csvFile is available
  // and calls the onSimRunComplete callback when the simulation is completed.
  React.useEffect(() => {
    if (!shouldTriggerCallbacks) {
      return;
    }

    try {
      if (getCsvFileIsError) {
        onSimRunCompleteSubscribers.current.forEach((f) =>
          f({
            error: `Results not available: could not retrieve results file.`,
          }),
        );
      }

      const results = csvFile?.raw[0].text;
      lastResults.current = results ?? '';
      lastResultsColumnNames.current = lastResults.current
        .split('\n')[0]
        .split(',');

      onSimRunCompleteSubscribers.current.forEach((f) =>
        f({
          lastResults: results,
          columnNames: results?.split('\n')[0].split(','),
        }),
      );
    } finally {
      onSimRunCompleteSubscribers.current = new Map();
      setCurrentSimulationUuid(simulationSummary?.uuid);
    }
  }, [
    shouldTriggerCallbacks,
    getCsvFileIsError,
    csvFile?.raw,
    simulationSummary?.uuid,
  ]);

  return {
    subscribeToNextSimRunComplete,
    subscribeToNextSimCompileComplete,
    unsubscribeToNextSimRunComplete,
    unsubscribeToNextSimCompileComplete,
    lastResultsColumnNames,
    lastResultsIsDirty,
    lastResults,
  };
};

export const SimCompleteListenerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const context = useSimCompleteListenerContext();
  return (
    <singletonContext.Provider value={context}>
      {children}
    </singletonContext.Provider>
  );
};

export const useSimCompleteListener = () => {
  const context = React.useContext(singletonContext);
  if (!context) {
    throw new Error(
      'useSimCompleteListener must be used within a SimCompleteListenerProvider',
    );
  }
  return context;
};
