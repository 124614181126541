import styled from '@emotion/styled/macro';
import React from 'react';
import TabButton from './TabButton';
import { TabInfo } from './tabTypes';

const TabGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: ${({ theme }) => theme.spacing.small};
  gap: ${({ theme }) => theme.spacing.large};
`;

interface Props {
  tabs: TabInfo[];
  testId?: string;
}

const TabGroup: React.FC<Props> = ({ tabs, testId }) => (
  <TabGroupWrapper>
    {tabs.map((tab) => (
      <TabButton
        key={tab.id}
        Icon={tab.Icon}
        onClick={tab.onClick}
        displayName={tab.displayName}
        isTabSelected={tab.isSelected()}
        testId={`${testId}-${tab.id}`}
      />
    ))}
  </TabGroupWrapper>
);

export default TabGroup;
