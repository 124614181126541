/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Replicator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.75 13.5H11V15.25C11 15.5261 11.2239 15.75 11.5 15.75H15.75C16.0261 15.75 16.25 15.5261 16.25 15.25V11.75C16.25 11.4739 16.0261 11.25 15.75 11.25H13.75V10C13.75 9.72386 13.5261 9.5 13.25 9.5H11.5V8.5C11.5 8.22386 11.2761 8 11 8H8.25C7.97386 8 7.75 8.22386 7.75 8.5V10.75C7.75 11.0261 7.97386 11.25 8.25 11.25H9.25V13C9.25 13.2761 9.47386 13.5 9.75 13.5ZM12 12.25H15.25V14.75H12V12.25ZM11 11.75C11 11.4739 11.2239 11.25 11.5 11.25H12.75V10.5H10.25V12.5H11V11.75ZM9.25 10.25V10C9.25 9.72386 9.47386 9.5 9.75 9.5H10.5V9H8.75V10.25H9.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4H5ZM5.5 5.5V18.5H18.5V5.5H5.5Z"
      fill={props.fill}
    />
  </svg>
);

Replicator.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Replicator;
