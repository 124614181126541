import { Project } from 'app/apiTransformers/convertAPIProjectToProject';

export enum ProjectItemType {
  EXPERIMENT = 'Experiment',
  MODEL = 'Model',
  SUBMODEL = 'Submodel',
  BUS_TYPE = 'Bus type',
  NOTEBOOKFILE = 'Notebook file',
  PROJECTFILE = 'Data file',
  NOTEBOOK = 'Notebook',
  CODE = 'Code',
}

export interface ProjectsDetailTableData
  extends Record<string, string | boolean> {
  projectUuid: string;
  uuid: string; // A misnomer since this object ID may not be unique across projects
  name: string;
  createdAt: string;
  updatedAt: string;
  type: ProjectItemType;
  error: string;
  isProcessing: boolean;
}

export type ProjectsListTableData = Project;
