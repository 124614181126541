import { t } from '@lingui/macro';
import { useGetSimulationLogFileReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { TypeMapStruct, readTypeMapStruct } from 'app/generated_types/CmlTypes';
import { useAppDispatch } from 'app/hooks';
import { compilationAnalysisDataActions } from 'app/slices/compilationAnalysisDataSlice';
import React from 'react';
import {
  matchesExpectedError,
  useNotifications,
} from 'ui/common/notifications/useNotifications';

const NO_SIM_SIGNAL_DATA_MESSAGE = 'Signal types not generated';

export function useSignalTypeData(
  modelId: string,
  correlationId: string,
  simulationId: string,
) {
  const dispatch = useAppDispatch();
  const { showError } = useNotifications();

  const { data: rawTypeMapStruct, error: simSignalDataError } =
    useGetSimulationLogFileReadByUuidQuery({
      modelUuid: modelId,
      simulationUuid: simulationId,
      simulationJsonLogFile: 'signal_types',
      'X-Correlation-ID': correlationId,
    });

  React.useEffect(() => {
    if (rawTypeMapStruct) {
      let typeMap: TypeMapStruct;
      try {
        typeMap = readTypeMapStruct(rawTypeMapStruct);
      } catch (e) {
        console.error('Could not parse cml types:', e);
        typeMap = [];
      }

      dispatch(
        compilationAnalysisDataActions.setAllDatatypeData({
          simulationId,
          typeMap,
        }),
      );
    }
  }, [dispatch, simulationId, rawTypeMapStruct]);

  React.useEffect(() => {
    if (simulationId && simSignalDataError) {
      if (
        matchesExpectedError(simSignalDataError, NO_SIM_SIGNAL_DATA_MESSAGE)
      ) {
        showError(
          t({
            id: 'entityPreferencesApi.noSimSignalDataAvailableError',
            message:
              'No signal data available from simulation. Signal types not generated.',
          }),
          null,
          { isExpectedError: true },
        );
      } else {
        showError(
          t({
            id: 'entityPreferencesApi.getSimSignalDataError',
            message: 'Unable to get signal data from simulation.',
          }),
          simSignalDataError,
        );
      }
      dispatch(
        compilationAnalysisDataActions.clearSimulationCache({
          simulationId,
        }),
      );
    }
  }, [dispatch, showError, simulationId, simSignalDataError]);
}
