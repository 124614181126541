import React from 'react';
import Button, { ButtonProps } from 'ui/common/Button/Button';
import PortalTextTooltip from 'ui/common/Tooltip/PortalTextTooltip';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { usePortalTooltip } from 'ui/common/Tooltip/usePortalTooltip';

interface Props extends ButtonProps {
  tooltip: string;
  placement?: TooltipPlacement;
  invertedColor?: boolean;
}

const TooltipButton: React.FC<Props> = ({
  tooltip,
  placement = TooltipPlacement.BOTTOM_LEFT,
  invertedColor = true,
  ...restProps
}) => {
  const { triggerElRef, isTooltipOpen } = usePortalTooltip<HTMLDivElement>();

  return (
    <>
      {/* Put a wrapper around the button because the button will stop registering mouse events if it becomes disabled and we need all the mouse events in order to have reliable tooltip showing and hiding behaviors. */}
      <div ref={triggerElRef}>
        <Button {...restProps} />
      </div>
      {isTooltipOpen && triggerElRef.current && (
        <PortalTextTooltip
          triggerEl={triggerElRef.current}
          contentText={tooltip}
          placement={placement}
        />
      )}
    </>
  );
};

export default TooltipButton;
