/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Model: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.6583 3.07622C11.879 2.99595 12.121 2.99595 12.3417 3.07622L20.3417 5.98532C20.7369 6.12902 21 6.5046 21 6.92511V17.0749C21 17.4954 20.7369 17.871 20.3417 18.0147L12.3417 20.9238C12.121 21.0041 11.879 21.0041 11.6583 20.9238L3.65826 18.0147C3.26307 17.871 3 17.4954 3 17.0749V6.92511C3 6.5046 3.26307 6.12902 3.65826 5.98532L11.6583 3.07622ZM4.5 8.01341L7.125 9.44523V14.4232L11.25 16.2393V19.1792L4.5 16.7247V8.01341ZM12.75 19.1792L19.5 16.7247V8.01341L16.875 9.44523V14.4232L12.75 16.2393V19.1792ZM12 14.9305L14.3124 13.9125L12 12.8283L9.68758 13.9125L12 14.9305ZM8.625 12.754V9.52532L11.25 8.57078V11.5233L8.625 12.754ZM12.75 11.5233L15.375 12.754V9.52532L12.75 8.57078V11.5233ZM16.0631 8.17945L18.4324 6.8871L12 4.54805L5.5676 6.8871L7.93689 8.17945L12 6.70195L16.0631 8.17945Z"
      fill={props.fill}
    />
  </svg>
);

Model.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Model;
