import { t } from '@lingui/macro';
import { useGetSnapshotReadAllQuery } from 'app/apiGenerated/generatedApi';
import { DiagramVersion } from 'app/apiTransformers/convertGetSnapshotReadAll';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import React from 'react';
import SelectInput from 'ui/common/SelectInput';
import { Spinner } from 'ui/common/Spinner';
import { SingleLineSpinnerWrapper } from 'ui/objectBrowser/sections/ReferenceSubmodelTreeContent';
import VersionPickerOption, {
  VersionPickerOptionData,
} from 'ui/versionHistory/VersionPickerOption';

type Props = {
  selectedModelId: string;
  currentValue: string;
  onSelectValue: (newValue: string) => void;
};

const workingVersionOption = {
  value: VersionTagValues.LATEST_VERSION,
  label: t({
    id: 'modelEditor.blockProperties.ExperimentModelVersionParameter.workingVersionOption.label',
    message: 'Working version',
  }),
};

export const ExperimentModelVersionParameter: React.FC<Props> = ({
  selectedModelId,
  currentValue,
  onSelectValue,
}) => {
  const { data: diagramVersions, isLoading } = useGetSnapshotReadAllQuery(
    {
      modelUuid: selectedModelId,
      kind: 'Model',
    },
    {
      skip: !selectedModelId,
    },
  );

  // This is mostly a copy of `options` from `VersionPicker.tsx`, since I wasn't sure
  // what would the final version of the version picker would look like. This might need
  // to change based on the final version of experiment runner's UX design.
  const options: VersionPickerOptionData[] = React.useMemo(() => {
    if (!selectedModelId) {
      return [];
    }
    const taggedDiagramVersions: DiagramVersion[] =
      (diagramVersions?.filter(
        (version) => (version as DiagramVersion).isTagged,
      ) as DiagramVersion[]) || [];

    if (taggedDiagramVersions.length === 0) {
      return [workingVersionOption];
    }

    const latestTaggedVersionOption = {
      value: VersionTagValues.LATEST_TAGGED_VERSION,
      label: t({
        id: 'modelEditor.blockProperties.versionPickerMenu.lastNamedVersionOption.label',
        message: 'Latest tagged version',
      }),
      currentAssociatedVersionName: taggedDiagramVersions[0].name,
      createdAt: taggedDiagramVersions[0].createdAt,
    };

    return [
      workingVersionOption,
      latestTaggedVersionOption,
      ...taggedDiagramVersions.map((version) => ({
        value: version.uuid,
        label: version.name,
        authorProfileImageUrl: version.authorProfileImageUrl,
        authorDisplayName: version.authorDisplayName,
        createdAt: version.createdAt,
      })),
    ];
  }, [diagramVersions, selectedModelId]);

  const selectedVersion: string = React.useMemo(() => {
    if (
      selectedModelId &&
      currentValue &&
      options.filter((option) => option.value === currentValue).length > 0
    ) {
      return currentValue;
    }
    return VersionTagValues.LATEST_VERSION;
  }, [selectedModelId, currentValue, options]);

  return isLoading ? (
    <SingleLineSpinnerWrapper>
      <Spinner />
    </SingleLineSpinnerWrapper>
  ) : (
    <SelectInput
      testId="version-picker"
      components={{ Option: VersionPickerOption }}
      onSelectValue={onSelectValue}
      currentValue={selectedVersion}
      options={options}
    />
  );
};
