import styled from '@emotion/styled';
import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import {
  NodeInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { getCodeIcon, nodeTypeIsCode } from 'app/helpers';
import { useAppSelector } from 'app/hooks';
import { getNestedNode } from 'app/utils/modelDiagramUtils';
import {
  setNavigationURLParams,
  setSelectionURLParams,
} from 'app/utils/URLParamsUtils';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { STATE_MACHINE_EDITOR_BLOCK_QUERY_PARAM } from 'state_machine_tempdir/StateMachineEditor';
import { CODE_EDITOR_BLOCK_QUERY_PARAM } from 'ui/codeEditor/CodeEditor';
import BreadcrumbLink from 'ui/common/Breadcrumb/BreadcrumbLink';
import {
  Conditional,
  Group,
  Iterator,
  Model,
  Replicator,
  Submodel,
} from 'ui/common/Icons/Small';
import { Spinner } from 'ui/common/Spinner';

export const LightSpinner = styled.div`
  height: ${({ theme }) => theme.spacing.xlarge};
  width: ${({ theme }) => theme.spacing.xlarge};
  display: flex;
  align-items: center;
  justify-content: center;

  & * {
    fill: ${({ theme }) => theme.colors.grey[70]};
  }
`;

interface Props {
  projectId: string;
  nodes: NodeInstance[];
  submodels: SubmodelsSection;
  parentPath?: string[];
  nodeInstanceId?: string;
  knownDisplayName?: string;
  clickable: boolean;
  onClick?: () => void;
}

function getIcon(
  topLevelModelType: ModelKind | null,
  node?: NodeInstance,
): React.FC | undefined {
  if (!node) {
    switch (topLevelModelType) {
      case 'Model':
        return Model;
      case 'Submodel':
        return Submodel;
      case 'Experiment':
        return Model; // FIXME: Experiment icon
      default:
        return undefined;
    }
  }

  if (node.type === 'core.Submodel' || node.type === 'core.ReferenceSubmodel')
    return Submodel;
  if (nodeTypeIsCode(node.type)) {
    return getCodeIcon(node.type);
  }
  if (node.type === 'core.Group') return Group;
  if (node.type === 'core.LinearizedSystem') return Group;
  if (node.type === 'core.Iterator') return Iterator;
  if (node.type === 'core.Replicator') return Replicator;
  if (node.type === 'core.Conditional') return Conditional;

  return undefined;
}

/**
 * The individual breadcrumb items separated by /. Each is a subdiagram.
 */
const ModelEditorBreadcrumbLink: React.FC<Props> = ({
  nodes,
  submodels,
  parentPath,
  nodeInstanceId,
  knownDisplayName,
  clickable,
  onClick,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present?.currentSubmodelPath,
  );
  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  const node = getNestedNode(nodes, submodels, parentPath, nodeInstanceId);

  if (nodeInstanceId && !node) {
    return (
      <LightSpinner>
        <Spinner />
      </LightSpinner>
    );
  }

  const defaultOnClickHandler = () => {
    const nextParentPath =
      parentPath && nodeInstanceId ? [...parentPath, nodeInstanceId] : [];
    const newSearchParams = new URLSearchParams();
    setNavigationURLParams(newSearchParams, {
      parentPath: nextParentPath,
    });
    setSelectionURLParams(newSearchParams, {
      selectionParentPath: nextParentPath,
      selectedBlockIds: [currentSubmodelPath[nextParentPath.length]],
    });
    setSearchParams(newSearchParams);
  };

  return (
    <BreadcrumbLink
      Icon={getIcon(topLevelModelType, node)}
      onClick={clickable ? onClick || defaultOnClickHandler : undefined}
      displayName={knownDisplayName || node?.name || 'unknown'}
    />
  );
};

export default ModelEditorBreadcrumbLink;
