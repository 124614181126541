import { t } from '@lingui/macro';
import { useState } from 'react';
import SelectInput, { SelectInputOption } from 'ui/common/SelectInput';
import {
  DetailInputRowsSection,
  DetailsLabel,
} from 'ui/modelEditor/DetailsComponents';

type CppBlockFunctionSelectProps = {
  displayName: string;
  currentValue: string;
  onSelectValue: (newValue: string) => void;
  options: SelectInputOption[];
};

const CppBlockFunctionInput = ({
  displayName,
  currentValue,
  onSelectValue,
  options,
}: CppBlockFunctionSelectProps) => {
  let cppFunctionOptions = options;
  const [isSelectedOptionValidOrEmpty, setIsSelectedOptionValidOrEmpty] =
    useState<boolean>(true);

  // function_name validation
  if (currentValue) {
    const currentValueIsValid = cppFunctionOptions.some(
      (option) => option.value === currentValue,
    );
    if (currentValueIsValid && !isSelectedOptionValidOrEmpty) {
      setIsSelectedOptionValidOrEmpty(true);
    } else if (!currentValueIsValid && isSelectedOptionValidOrEmpty) {
      cppFunctionOptions = [
        ...cppFunctionOptions,
        {
          value: currentValue,
          label: t({
            id: 'modelRenderer.parameters.cppFunctionNotFound.label',
            message: '{cppFunctionName} (Not found)',
            values: {
              cppFunctionName: currentValue,
            },
          }),
        },
      ];
      setIsSelectedOptionValidOrEmpty(false);
    }
  } else if (!isSelectedOptionValidOrEmpty) {
    // The current value is empty so we should not show an error.
    setIsSelectedOptionValidOrEmpty(true);
  }

  return (
    <DetailInputRowsSection>
      <DetailsLabel>{displayName}</DetailsLabel>
      <SelectInput
        currentValue={currentValue}
        options={cppFunctionOptions}
        onSelectValue={onSelectValue}
        isInvalid={!isSelectedOptionValidOrEmpty}
      />
    </DetailInputRowsSection>
  );
};

export default CppBlockFunctionInput;
