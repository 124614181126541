import { t } from '@lingui/macro';
import { useBusTypeActions } from 'app/api/useBusTypeActions';
import { useDeleteSubmodel } from 'app/api/useDeleteSubmodel';
import { useDuplicateSubmodel } from 'app/api/useDuplicateSubmodel';
import { useJupyter } from 'app/api/useJupyter';
import { useModels } from 'app/api/useModels';
import { useProjectActions } from 'app/api/useProjectActions';
import { useUpdateSubmodel } from 'app/api/useUpdateSubmodel';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { useAppSelector } from 'app/hooks';
import { getNextValidIdentifier } from 'app/transformers/uniqueNameGenerators';
import React from 'react';
import { useModal } from 'ui/common/Modal/useModal';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import {
  ProjectItemType,
  ProjectsDetailTableData as TableData,
} from 'ui/dashboard/dashboardTypes';
import DuplicateModal from 'ui/dashboard/projectDetail/DuplicateModal';
import RenameModal from 'ui/dashboard/projectDetail/RenameModal';
import DeleteConfirmationModal from 'ui/dashboard/projectsList/DeleteConfirmationModal';
import { splitFullName } from 'util/fileUtils';

const sameTypeItems = (
  name: string,
  itemType: ProjectItemType,
  projectItems: TableData[],
) => {
  if (itemType === ProjectItemType.PROJECTFILE) {
    const [_, extension] = splitFullName(name);
    return projectItems
      .map((d) => splitFullName(d.name))
      .filter(([_, ext]) => ext === extension)
      .map(([basename, _]) => basename);
  }
  return projectItems
    .filter((d) => d.type !== ProjectItemType.PROJECTFILE)
    .map((value) => value.name);
};

export function useProjectDetailActions(
  rowTableData: TableData,
  projectItems: TableData[],
) {
  const { deleteFile, downloadFile, updateFile } = useProjectActions();
  const { updateModelSummary, deleteModel, duplicateModel } = useModels();
  const { deleteSubmodel } = useDeleteSubmodel();
  const { updateSubmodelName } = useUpdateSubmodel();
  const { duplicateSubmodel } = useDuplicateSubmodel();
  const { showCompletion } = useNotifications();
  const {
    renameFile: renameJupyterFile,
    deleteFile: deleteHubFile,
    downloadFile: downloadHubFile,
    duplicateFile,
  } = useJupyter();
  const { renameBusType, deleteBusType } = useBusTypeActions();

  const { triggerModal, closeModal } = useModal();

  const { uuid: itemId, projectUuid: projectId, name, type } = rowTableData;

  const submodels = useAppSelector(
    (state) => state.submodels.projectIdToSubmodelInfoLites[projectId],
  );

  const deleteElement = React.useCallback(
    async (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.stopPropagation();

      const onDelete = async () => {
        switch (type) {
          case ProjectItemType.PROJECTFILE:
            await deleteFile({ projectUuid: projectId, fileUuid: itemId });
            showCompletion(
              t({
                id: 'dashboard.projects.deleteFile.notification',
                message: 'File deleted',
              }),
            );
            break;
          case ProjectItemType.MODEL:
            deleteModel(itemId);
            break;
          case ProjectItemType.SUBMODEL:
            deleteSubmodel({
              projectUuid: projectId,
              submodelUuid: itemId,
            });
            break;
          case ProjectItemType.BUS_TYPE:
            deleteBusType(projectId, itemId);
            break;
          case ProjectItemType.CODE:
          case ProjectItemType.NOTEBOOKFILE:
          case ProjectItemType.NOTEBOOK:
            await deleteHubFile(projectId, name);
            showCompletion(
              t({
                id: 'dashboard.projects.deleteFile.notification',
                message: 'File deleted',
              }),
            );
            break;
        }
      };

      let confirmationTitleText = '';
      let confirmationMessageText = '';

      switch (type) {
        case ProjectItemType.MODEL:
          confirmationTitleText = t({
            id: 'deleteModelModal.messageTitle.text',
            message: 'Delete Model?',
          });
          confirmationMessageText = t({
            id: 'deleteModelModal.messageContent.text',
            message: 'Are you sure you want to delete this model?',
          });
          break;
        case ProjectItemType.SUBMODEL:
          confirmationTitleText = t({
            id: 'deleteSubmodelModal.messageTitle.text',
            message: 'Delete Submodel?',
          });
          confirmationMessageText = t({
            id: 'deleteProjectSubmodelModal.messageContent.text',
            message: 'Are you sure you want to delete this submodel?',
          });
          break;
        case ProjectItemType.BUS_TYPE:
          confirmationTitleText = t({
            id: 'deleteBusType.messageTitle.text',
            message: 'Delete Bus Type?',
          });
          confirmationMessageText = t({
            id: 'deleteProjectBusTypeModal.messageContent.text',
            message: 'Are you sure you want to delete this bus type?',
          });
          break;
        case ProjectItemType.PROJECTFILE:
        case ProjectItemType.CODE:
        case ProjectItemType.NOTEBOOKFILE:
        case ProjectItemType.NOTEBOOK:
        default:
          confirmationTitleText = t({
            id: 'deleteFileModal.messageTitle.text',
            message: 'Delete File?',
          });
          confirmationMessageText = t({
            id: 'deleteFileModal.messageContent.text',
            message: 'Are you sure you want to delete this file?',
          });
          break;
      }

      triggerModal(
        <DeleteConfirmationModal
          onDelete={onDelete}
          message={confirmationMessageText}
        />,
        confirmationTitleText,
      );
    },
    [
      deleteFile,
      deleteHubFile,
      itemId,
      deleteModel,
      deleteSubmodel,
      deleteBusType,
      name,
      projectId,
      showCompletion,
      type,
      triggerModal,
    ],
  );

  const renameElement = React.useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.stopPropagation();

      let oldName = name;
      let extension = '';
      if (type === ProjectItemType.PROJECTFILE) {
        [oldName, extension] = splitFullName(name);
      }

      const onRename = async (newName: string) => {
        switch (type) {
          case ProjectItemType.NOTEBOOKFILE:
          case ProjectItemType.NOTEBOOK:
          case ProjectItemType.CODE:
            await renameJupyterFile(name, newName, projectId);
            closeModal();
            break;
          case ProjectItemType.BUS_TYPE:
            renameBusType(projectId, itemId, newName);
            closeModal();
            break;
          case ProjectItemType.PROJECTFILE:
            await updateFile(projectId, itemId, { name: newName });
            closeModal();
            break;
          case ProjectItemType.MODEL:
            await updateModelSummary(itemId, newName);
            closeModal();
            break;
          case ProjectItemType.SUBMODEL:
            const submodel = submodels.find(
              (s: SubmodelInfoLiteUI) => s.id === itemId,
            );
            if (!submodel) return;

            if (submodel.editId) {
              await updateSubmodelName({
                projectId,
                submodelId: itemId,
                newName,
                editId: submodel.editId,
              });
            }
            closeModal();
            break;
        }
      };

      triggerModal(
        <RenameModal
          initialValue={oldName}
          onRename={(v) => onRename(`${v}${extension && `.${extension}`}`)}
          values={sameTypeItems(name, type, projectItems)}
          comparer={(cellName) => cellName}
        />,
        'Rename',
      );
    },
    [
      submodels,
      name,
      updateSubmodelName,
      triggerModal,
      closeModal,
      itemId,
      updateFile,
      renameJupyterFile,
      updateModelSummary,
      renameBusType,
      type,
      projectId,
      projectItems,
    ],
  );

  const duplicateElement = React.useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.stopPropagation();

      let sourceItemName = name;
      let extension = '';
      if (type === ProjectItemType.PROJECTFILE) {
        [sourceItemName, extension] = splitFullName(name);
      }

      const defaultDuplicateNameStarter = t({
        id: 'duplicateProjectItemModal.defaultDuplicateItemName.format',
        message: '{originalFileName} copy',
        values: {
          originalFileName: sourceItemName,
        },
      });
      const defaultDuplicateName = getNextValidIdentifier(
        defaultDuplicateNameStarter,
        sameTypeItems(name, type, projectItems),
      );

      const onDuplicate = async (
        newName: string,
        destinationProjectUuid: string,
      ) => {
        switch (type) {
          case ProjectItemType.NOTEBOOKFILE:
          case ProjectItemType.CODE:
          case ProjectItemType.NOTEBOOK:
            await duplicateFile(
              projectId,
              name,
              newName,
              destinationProjectUuid,
            );
            closeModal();
            break;
          case ProjectItemType.MODEL:
            await duplicateModel(itemId, newName, destinationProjectUuid);
            closeModal();
            break;
          case ProjectItemType.SUBMODEL:
            await duplicateSubmodel({
              projectUuid: projectId,
              submodelUuid: itemId,
              submodelDuplicateRequest: {
                name: newName,
                destination_project_uuid: destinationProjectUuid,
              },
            });
            closeModal();
            break;
        }
      };

      triggerModal(
        <DuplicateModal
          itemType={type}
          initialDestinationProjectUuid={projectId}
          defaultDuplicateName={defaultDuplicateName}
          extension={extension}
          onDuplicate={(name, project) =>
            onDuplicate(`${name}${extension && `.${extension}`}`, project)
          }
        />,
        'Duplicate Item',
      );
    },
    [
      name,
      triggerModal,
      closeModal,
      itemId,
      duplicateFile,
      duplicateModel,
      duplicateSubmodel,
      type,
      projectId,
      projectItems,
    ],
  );

  const downloadElement = React.useCallback(
    async (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.stopPropagation();
      await downloadHubFile(projectId, name);
    },
    [projectId, name, downloadHubFile],
  );

  const downloadDataFile = React.useCallback(
    async (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.stopPropagation();

      await downloadFile(name, {
        fileUuid: itemId,
        projectUuid: projectId,
      });
    },
    [projectId, name, itemId, downloadFile],
  );

  return {
    deleteElement,
    renameElement,
    duplicateElement,
    downloadElement,
    downloadDataFile,
    type,
  };
}
