import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { isSamePath } from 'app/utils/parentPathUtils';
import {
  getUrlData,
  PARENT_PATH_KEY,
  SELECTED_ANNOTATION_IDS_KEY,
  SELECTED_BLOCK_IDS_KEY,
  SELECTED_LINK_IDS_KEY,
  SELECTION_PARENT_PATH_KEY,
} from 'app/utils/URLParamsUtils';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

// Updates the modelSlice state based on the URL params.
export const ModelEditorURLParameterTracker: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present?.currentSubmodelPath,
  );

  const parentPath = getUrlData(searchParams, PARENT_PATH_KEY);

  // Convert any URL param changes into a change in the model state.
  React.useEffect(() => {
    // Only checks the path despite changing both the path and selections.
    // This means if there's selections specified for current model, it doesn't trigger.
    // I do not feel comfortable changing the node nav behavior without more testing given how complicated it is.
    // Use modelActions.setSelections instead. - jj 2023/06/15
    if (parentPath && !isSamePath(parentPath, currentSubmodelPath)) {
      // Get the selection state for navigating to this parent path
      const selectionParentPath = getUrlData(
        searchParams,
        SELECTION_PARENT_PATH_KEY,
      );
      const selectedBlockIds = getUrlData(searchParams, SELECTED_BLOCK_IDS_KEY);
      const selectedLinkIds = getUrlData(searchParams, SELECTED_LINK_IDS_KEY);
      const selectedAnnotationIds = getUrlData(
        searchParams,
        SELECTED_ANNOTATION_IDS_KEY,
      );

      dispatch(
        modelActions.setPathWithSelections({
          parentPath,
          selectionParentPath,
          selectedBlockIds,
          selectedLinkIds,
          selectedAnnotationIds,
        }),
      );
    }
  }, [dispatch, searchParams, parentPath, currentSubmodelPath]);

  return null;
};
