import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SimulationSignalsTreeData } from 'util/simulationSignalsTree';

export interface SimulationSignalsRequest {
  modelId: string;
  simulationId: string;
}

interface SimulationsState {
  simulationIdToSignalsRequest: Record<string, SimulationSignalsRequest>;
  simulationIdToSignalsTreeData: Record<string, SimulationSignalsTreeData>;
  simulationIdToModelVersionId: Record<string, string>;
}

const initialState: SimulationsState = {
  simulationIdToSignalsRequest: {},
  simulationIdToSignalsTreeData: {},
  simulationIdToModelVersionId: {},
};

const simulationSignalsSlice = createSlice({
  name: 'simulationSignalsSlice',
  initialState,
  reducers: {
    requestSimulationSignals(
      state,
      action: PayloadAction<{
        modelId: string;
        simulationId: string;
      }>,
    ) {
      const { modelId, simulationId } = action.payload;

      // If the simulation signals were already loaded, we don't need to request it.
      const simulationTreeData =
        state.simulationIdToSignalsTreeData[simulationId];
      if (simulationTreeData) {
        return;
      }

      // Add the request for the signals that we haven't loaded yet.
      if (!state.simulationIdToSignalsRequest[simulationId]) {
        state.simulationIdToSignalsRequest[simulationId] = {
          modelId,
          simulationId,
        };
      }
    },

    onSimulationSignalsLoaded(
      state,
      action: PayloadAction<{
        simulationId: string;
        modelVersionId: string;
        signalsTreeData: SimulationSignalsTreeData;
      }>,
    ) {
      const { simulationId, modelVersionId, signalsTreeData } = action.payload;

      // Add simulation signals tree (or error information) to the cache.
      state.simulationIdToSignalsTreeData[simulationId] = signalsTreeData;

      // If this simulation signals were requested, remove the request.
      delete state.simulationIdToSignalsRequest[simulationId];

      // Update the simulation id to model version id mapping.
      if (modelVersionId) {
        state.simulationIdToModelVersionId[simulationId] = modelVersionId;
      }
    },

    onSimulationModelVersionLoaded(
      state,
      action: PayloadAction<{
        simulationId: string;
        modelVersionId: string;
      }>,
    ) {
      const { simulationId, modelVersionId } = action.payload;

      // Update the simulation id to model version id mapping.
      if (modelVersionId) {
        state.simulationIdToModelVersionId[simulationId] = modelVersionId;
      }
    },
  },
});

export const simulationSignalsActions = simulationSignalsSlice.actions;

export default simulationSignalsSlice;
