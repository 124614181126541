import {
  ModelDiagram,
  Parameter,
  Parameters,
} from '@collimator/model-schemas-ts';
import { t } from '@lingui/macro';
import { PortSide } from 'app/common_types/PortTypes';
import { useGetModelReadByUuidQuery } from 'app/enhancedApi';
import {
  BlockParameterDefinition,
  BlockParameterDefinitions,
} from 'app/generated_types/ComputationBlockClass';
import { NodeInstance, Port } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { getPortDisplayName } from 'app/utils/portConditionUtils';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import { isValidBlockPortNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import { useVisualizerPrefs } from 'ui/modelEditor/useVisualizerPrefs';
import BlockPortParameters from './BlockPortParameter';
import VisualizerToggler from './Visualizer/VisualizerToggler';

type Props = {
  parentPath: string[];
  selectedNode: NodeInstance;
  canEdit: boolean;
};

type TargetModelOutput = {
  signal: string;
  portName?: string;
};

const ExperimentModelOutportDetails: React.FC<Props> = ({
  parentPath,
  selectedNode,
  canEdit,
}) => {
  const dispatch = useAppDispatch();

  const {
    addPortsToChart,
    removePortsFromChart,
    getAreAllNodePortsInChart,
    getIsPortInChart,
  } = useVisualizerPrefs();

  const canShowAddButton = canEdit;
  const showOutputsHeader = true;

  const [targetModelId, targetVersionId] = React.useMemo(() => {
    const modelParam = selectedNode.parameters?.model;
    const versionParam = selectedNode.parameters?.model_version;
    return [modelParam?.value, versionParam?.value];
  }, [selectedNode.parameters]);

  // FIXME: we use the GET model API instead of a more appropriate API
  // that would only list the outputs and parameters of the model.
  // Such an API could benefit from running a quick compilation
  // of the model, if we want to be sure about the available outputs.
  // For now, we just assume that all top-level blocks in the target model
  // can be observed and we'll throw errors otherwise.
  const {
    data: targetModelData,
    isFetching: isFetchingTargetModelData,
    refetch: refetchTargetModelData,
  } = useGetModelReadByUuidQuery(
    { modelUuid: targetModelId || '' },
    { skip: !targetModelId },
  );

  const targetModelOutputs: TargetModelOutput[] = React.useMemo(() => {
    const diagram = targetModelData?.diagram as ModelDiagram;
    if (isFetchingTargetModelData || !diagram?.nodes) return [];

    let modelOutputs: TargetModelOutput[] = [];
    for (let i = 0; i < diagram.nodes.length; i++) {
      const node = diagram.nodes[i];
      const outputs = node.outputs;
      for (let j = 0; j < outputs.length; j++) {
        const output = outputs[j];
        modelOutputs.push({
          signal: [node.name, output.name].join('.'),
        });
      }
    }

    return modelOutputs;
  }, [targetModelData?.diagram, isFetchingTargetModelData]);

  const alreadyObservedOutputs: TargetModelOutput[] = React.useMemo(() => {
    let observedOutputs: TargetModelOutput[] = [];
    for (let i = 0; i < selectedNode.outputs.length; i++) {
      const port = selectedNode.outputs[i];
      const param = port.parameters?.signal as Parameter | undefined;
      if (param) {
        observedOutputs.push({
          signal: param.value,
          portName: port.name,
        });
      }
    }
    return observedOutputs;
  }, [selectedNode.outputs]);

  const notYetObservedOutputs = React.useMemo(
    () =>
      targetModelOutputs.filter(
        (targetOutput) =>
          !alreadyObservedOutputs.find(
            (observedOutput) => observedOutput.signal === targetOutput.signal,
          ),
      ),
    [targetModelOutputs, alreadyObservedOutputs],
  );

  const canAddOutput = canEdit && notYetObservedOutputs.length > 0;

  const getPortParamDefinitions = React.useCallback(
    (port: Port, index: number) => {
      const thisPortSignal = (port.parameters?.signal as Parameter)?.value;
      const availableSignals = notYetObservedOutputs.map((o) => o.signal);
      if (thisPortSignal) {
        availableSignals.push(thisPortSignal);
      }
      const paramDefs: BlockParameterDefinitions = [
        {
          name: 'signal',
          data_type: 'string',
          display_variant: 'list',
          allowed_values: availableSignals,
          display_full_width: true,
          default_value: thisPortSignal || availableSignals[0],
        },
      ];
      return paramDefs;
    },
    [notYetObservedOutputs],
  );

  const addOutput = React.useCallback(() => {
    // Automatically select the first available output
    const signalName = notYetObservedOutputs[0].signal;
    const parameters: Parameters = {
      signal: { value: signalName, is_string: true },
    };
    const name = signalName.replaceAll('.', '_').replaceAll('_out_0', '');

    dispatch(
      modelActions.addPort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Output,
        name,
        parameters,
      }),
    );

    // Heuristic to toggle on visualization to this new port if other ports
    // in this block are currently being visualized.
    if (getAreAllNodePortsInChart({ nodeId: selectedNode.uuid, parentPath })) {
      addPortsToChart([
        {
          nodeId: selectedNode.uuid,
          portIndex: selectedNode.outputs.length,
          parentPath,
        },
      ]);
    }
  }, [
    dispatch,
    parentPath,
    selectedNode.uuid,
    selectedNode.outputs.length,
    getAreAllNodePortsInChart,
    addPortsToChart,
    notYetObservedOutputs,
  ]);

  const removeOutput = (outputId: number) => () => {
    // Stop visualizing the port removing it.
    if (
      getIsPortInChart({
        nodeId: selectedNode.uuid,
        portIndex: outputId,
        parentPath,
      })
    ) {
      removePortsFromChart([
        {
          nodeId: selectedNode.uuid,
          portIndex: outputId,
          parentPath,
        },
      ]);
    }

    // Finally, remove the port itself.
    dispatch(
      modelActions.removePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Output,
        portId: outputId,
      }),
    );
  };

  const renameOutput = (outputId: number, newPortName: string) => {
    dispatch(
      modelActions.renamePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Output,
        portId: outputId,
        newPortName,
      }),
    );
  };

  const onPortParameterChanged = (
    paramDef: BlockParameterDefinition,
    port: Port,
    index: number,
    oldValue: string,
    newValue: string,
  ) => {
    if (paramDef.name === 'signal') {
      // If the user hasn't changed the port name, we can just rename it
      // when they changed the observed signal.
      const currentPortName = port.name;
      const expectedOldPortName = oldValue
        .replaceAll('.', '_')
        .replaceAll('_out_0', '');
      const newPortName = newValue
        .replaceAll('.', '_')
        .replaceAll('_out_0', '');
      const clashingPort = selectedNode.outputs.find(
        (p) => p.name === newPortName,
      );
      if (!clashingPort && currentPortName === expectedOldPortName) {
        renameOutput(index, newPortName);
      }
    }
  };

  return (
    <>
      {showOutputsHeader && (
        <SectionHeading
          testId="outputs"
          onButtonClick={canShowAddButton ? addOutput : undefined}
          isButtonEnabled={canAddOutput}
          buttonTooltip={t({
            id: 'blockDetails.addOutputButtonTooltip',
            message: 'Add output',
          })}>
          {t({
            id: 'blockDetails.PortOutputsTitle',
            message: 'Outputs',
          })}
        </SectionHeading>
      )}
      {(selectedNode.outputs.length > 0 || canAddOutput) && (
        <DetailInputRowsSection>
          {selectedNode.outputs.map((output, i) => (
            <div key={output.name || `row-output[${i}]`}>
              <DetailsSection>
                <DetailsInput
                  grow
                  testId={`block-output-name-${i}`}
                  value={getPortDisplayName(selectedNode.type, output.name)}
                  placeholder={`Unnamed output ${i}`}
                  onSubmitValue={(newName) => renameOutput(i, newName)}
                  disabled={!canEdit}
                  validationRules={isValidBlockPortNameRuleSet(selectedNode, {
                    id: i,
                    side: PortSide.Output,
                  })}
                />
                <VisualizerToggler
                  nodeId={selectedNode.uuid}
                  parentPath={parentPath}
                  portIndex={i}
                  output={output}
                />
                {canEdit && (
                  <Button
                    testId={`block-output-remove-button-${i}`}
                    variant={ButtonVariants.LargeTertiary}
                    Icon={Remove}
                    onClick={removeOutput(i)}
                  />
                )}
              </DetailsSection>
              <BlockPortParameters
                parentPath={parentPath}
                selectedNode={selectedNode}
                canEdit={canEdit}
                port={output}
                index={i}
                paramDefinitions={getPortParamDefinitions(output, i)}
                portSide={PortSide.Output}
                onParameterChanged={(paramDef, oldValue, newValue) =>
                  onPortParameterChanged(
                    paramDef,
                    output,
                    i,
                    oldValue,
                    newValue,
                  )
                }
              />
            </div>
          ))}
        </DetailInputRowsSection>
      )}
    </>
  );
};

export default ExperimentModelOutportDetails;
