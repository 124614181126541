/* eslint-disable import/order */
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { UpdateAPIDispatcher } from 'UpdateAPIDispatcher';
import { UpdateParametersAPIDispatcher } from 'UpdateParametersAPIDispatcher';
import { clearModelState } from 'app/api/useModelData';
import { getCodeBasedBlockParamKey } from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CurrentModelRefAccess } from 'app/sliceRefAccess/CurrentModelRefAccess';
import { cameraActions } from 'app/slices/cameraSlice';
import { selectEntityPrefs } from 'app/slices/entityPreferencesSlice';
import { modelActions } from 'app/slices/modelSlice';
import { NavbarContext, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { userPreferencesActions } from 'app/slices/userPreferencesSlice';
import { versionHistoryActions } from 'app/slices/versionHistorySlice';
import { getIsCurrentDiagramReadonly } from 'app/utils/modelDiagramUtils';
import React, { useEffect } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  STATE_MACHINE_EDITOR_BLOCK_QUERY_PARAM,
  StateMachineEditor,
} from 'state_machine_tempdir/StateMachineEditor';
import FooterContainer from 'ui/appBottomBar/FooterContainer';
import {
  CODE_EDITOR_BLOCK_QUERY_PARAM,
  CodeEditor,
} from 'ui/codeEditor/CodeEditor';
import { Spinner } from 'ui/common/Spinner';
import {
  AppContentWithFooterWrapper,
  AppContentWrapper,
} from 'ui/common/layout/appLayout';
import { SmallEmphasis } from 'ui/common/typography/Typography';
import { DataExplorerModelVersionsLoader } from 'ui/dataExplorer/loaders/DataExplorerModelVersionsLoader';
import { DataExplorerSignalTraceLoader } from 'ui/dataExplorer/loaders/DataExplorerSignalTraceLoader';
import { DragOverlay } from 'ui/dragdrop/DragOverlay';
import { DragProvider } from 'ui/dragdrop/DragProvider';
import { ConvertGroupToSubmodelTracker } from 'ui/modelEditor/ConvertGroupToSubmodelTracker';
import { CurrentDiagramTracker } from 'ui/modelEditor/CurrentDiagramTracker';
import { ModelRestoreTracker } from 'ui/modelEditor/ModelRestoreTracker';
import { NestedSubmodelLoader } from 'ui/modelEditor/NestedSubmodelLoader';
import { NodeNavigator } from 'ui/modelEditor/NodeNavigator';
import { ProjectSubmodelLoader } from 'ui/modelEditor/ProjectSubmodelLoader';
import { VisualizationTraceInitializer } from 'ui/modelEditor/VisualizationTraceInitializer';
import { VisualizationTraceRecordTracker } from 'ui/modelEditor/VisualizationTraceRecordTracker';
import { VisualizationTraceSimulationTracker } from 'ui/modelEditor/VisualizationTraceSimulationTracker';
import { VisualizationTraceTracker } from 'ui/modelEditor/VisualizationTraceTracker';
import { LayersMenu } from 'ui/navbar/LayersMenu';
import ZoomMenu from 'ui/navbar/ZoomMenu';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { isBlockNavState } from 'ui/requirements/blockNav';
import { GLOBAL_ENTITY } from 'ui/userPreferences/globalEntity';
import {
  MODEL_EDITOR_LAYOUT_PREFS_V1_KEY,
  ModelEditorLayoutPrefsV1,
} from 'ui/userPreferences/modelEditorLayoutPrefs';
import useEntityPreferences from 'ui/userPreferences/useEntityPreferences';
import { useAppParams } from 'util/useAppParams';
import DiagramRightSidebar from './DiagramRightSidebar';
import ModelEditorBreadcrumb from './ModelEditorBreadcrumb';
import { ModelEditorURLParameterTracker } from './ModelEditorURLParameterTracker';
import ModelLeftSidebar from './ModelLeftSidebar';
import { ModelRendererWrapper } from './ModelRendererWrapper';
import { UpdateSubmodelDispatcher } from './UpdateSubmodelDispatcher';

// used for type inference
const dummyTimeout = setTimeout(() => {}, 0);

const ModelRendererOverlay = styled.div<{ isReadonly: boolean }>`
  flex: 1 0 50px;
  position: relative;
  ${({ isReadonly, theme }) =>
    isReadonly ? '' : `border: 1px solid ${theme.colors.grey[10]}`};
`;

const SideMenusWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.normal};
  right: ${({ theme }) => theme.spacing.normal};
  display: flex;
  flex-direction: row;
  pointer-events: auto;
`;

const ModelLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 50px;
  background: rgba(240, 242, 242, 1);
`;

const ReadonlyOverlay = styled.div`
  position: absolute;
  border-style: inset;
  border: solid 2px ${({ theme }) => theme.colors.brand.tertiary.darker};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 2;
`;

const ReadonlyBanner = styled(SmallEmphasis)`
  background-color: ${({ theme }) => theme.colors.brand.tertiary.darker};
  border-radius: 0 0 2px 2px;
  color: white;
  padding: ${({ theme }) => theme.spacing.small};
`;

const pythonScriptTabs = [
  { paramName: 'user_statements', label: 'Step' },
  { paramName: 'init_script', label: 'Init' },
  { paramName: 'finalize_script', label: 'Finalize' },
];

const codeBlockDevTabs = [
  ...pythonScriptTabs,
  { paramName: '__c_function_code', label: 'C' },
  { paramName: '__cpp_function_code', label: 'C++' },
];

const ModelEditor: React.FC = () => {
  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );
  const { modelId, projectId, versionId } = useAppParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { state } = useLocation();

  // Left side bar opens to the tree navigator if a block is specified
  useEffect(() => {
    if (isBlockNavState(state) && !!state.block_instance_uuid) {
      dispatch(uiFlagsActions.setUIFlag({ isLeftSidebarOpen: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const areUserOptionsLoaded = useAppSelector((state) => [
    state.userOptions.areUserOptionsLoaded,
  ]);

  // Parent logic for bottom panel sizing
  const AppContentWithFooterWrapperRef = React.useRef<HTMLDivElement | null>(
    null,
  );
  useEntityPreferences(MODEL_EDITOR_LAYOUT_PREFS_V1_KEY, GLOBAL_ENTITY);
  const layoutPrefs = useAppSelector((state) =>
    selectEntityPrefs(state, MODEL_EDITOR_LAYOUT_PREFS_V1_KEY, GLOBAL_ENTITY),
  ) as ModelEditorLayoutPrefsV1 | null;

  // If the model id changes, clear out the model version history state.
  // If the version id changes, but the version id remains the same,
  // don't clear the version history because the history
  // is still relevant for the current model id.
  React.useEffect(() => {
    if (loadedModelId && modelId !== loadedModelId) {
      dispatch(versionHistoryActions.resetVersionHistoryState());
    }
  }, [dispatch, modelId, loadedModelId]);

  // When we leave the model editor, clear out the model version history state
  // as well as the model state.
  React.useEffect(
    () => () => {
      clearModelState(dispatch);
      dispatch(versionHistoryActions.resetVersionHistoryState());
    },
    [dispatch],
  );

  React.useEffect(() => {
    dispatch(uiFlagsActions.setNavbarContext(NavbarContext.ModelEditor));
    return () => {
      dispatch(uiFlagsActions.setNavbarContext(NavbarContext.None));
      dispatch(userPreferencesActions.unsetLoadModelEditor());
    };
  }, [dispatch]);

  const cameraSettingTimeout = React.useRef(dummyTimeout);

  const [searchParams, setSearchParams] = useSearchParams();
  const codeEditorOpen = !!searchParams.get(CODE_EDITOR_BLOCK_QUERY_PARAM);
  const stateMachineEditorOpen = !!searchParams.get(
    STATE_MACHINE_EDITOR_BLOCK_QUERY_PARAM,
  );
  // const blockId = searchParams.get()

  const { canEditCurrentModelVersion, arePermissionsLoaded } =
    useModelPermission(projectId, modelId, versionId);

  const currentDiagram = useAppSelector(
    (state) => state.modelMetadata.currentDiagram,
  );

  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );

  const referenceSubmodelId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelReferenceId,
  );

  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  const setTransform = React.useCallback(
    (transform) => {
      if (transform) {
        clearTimeout(cameraSettingTimeout.current);
        cameraSettingTimeout.current = setTimeout(() => {
          dispatch(
            cameraActions.setEntireTransform({
              coord: {
                x: transform.x,
                y: transform.y,
              },
              zoom: transform.zoom,
              rerender: false,
            }),
          );
        }, 50);
      }
    },
    [dispatch],
  );

  const modelRendererOpen =
    !!loadedModelId && !codeEditorOpen && !stateMachineEditorOpen;
  const isDiagramReadonly = getIsCurrentDiagramReadonly({
    modelId,
    loadedModelId,
    referenceSubmodelId,
    arePermissionsLoaded,
    canEditCurrentModelVersion,
  });

  // PythonScript and CFunction code blocks
  const codeEditorQuery = searchParams.get(CODE_EDITOR_BLOCK_QUERY_PARAM);
  const [codeEditorBlockId, codeEditorParamNameFromQuery] =
    codeEditorQuery?.split('.') ?? [];

  const codeBlock = currentDiagram?.nodes.find(
    (n) => n.uuid === codeEditorBlockId,
  );

  const codeTabs =
    codeBlock?.type === 'core.CodeBlockDev'
      ? codeBlockDevTabs
      : codeBlock?.type === 'core.PythonScript'
      ? pythonScriptTabs
      : undefined;

  const codeLanguage = React.useMemo(() => {
    if (!codeBlock) return 'text';
    switch (codeBlock.type) {
      case 'core.CodeBlockDev':
        return codeEditorParamNameFromQuery === '__c_function_code'
          ? 'c'
          : codeEditorParamNameFromQuery === '__cpp_function_code'
          ? 'cpp'
          : 'python';
      case 'core.PythonScript':
        return 'python';
      case 'core.CFunction':
        return 'c';
      case 'core.CppFunction':
        return 'cpp';
      default:
        return 'text';
    }
  }, [codeBlock, codeEditorParamNameFromQuery]);

  const codeBlockParamName =
    codeEditorParamNameFromQuery || getCodeBasedBlockParamKey(codeBlock);
  const editorDisplayPath = codeEditorParamNameFromQuery
    ? `${codeBlock?.name}: ${codeEditorParamNameFromQuery}`
    : codeBlock?.name;

  const codeDefaultValue: string = React.useMemo(() => {
    if (!currentDiagram || !codeBlock || !codeBlockParamName) {
      return '';
    }

    return codeBlock?.parameters?.[codeBlockParamName]?.value || '';
  }, [currentDiagram, codeBlock, codeBlockParamName]);

  const codeChangeTimerId = React.useRef<number | null>(null);
  const onChangeBlockCode = React.useCallback(
    (value: string | undefined, paramKey?: string) => {
      if (codeBlock && paramKey) {
        if (codeChangeTimerId.current) {
          window.clearTimeout(codeChangeTimerId.current);
        }

        codeChangeTimerId.current = window.setTimeout(async () => {
          dispatch(
            modelActions.changeBlockParameter({
              parentPath: currentSubmodelPath,
              nodeUuid: codeBlock.uuid,
              paramName: paramKey,
              value: value === undefined ? '' : value,
            }),
          );
        }, 100);
      }
    },
    [codeBlock, currentSubmodelPath, dispatch],
  );

  const externalOverlayRef = React.useRef(null);

  const codeMultiPaneData = React.useMemo(
    () =>
      pythonScriptTabs.map((tab) => ({
        paramKey: tab.paramName,
        paneTitle: tab.label,
        defaultValue: codeBlock?.parameters?.[tab.paramName]?.value,
      })),
    [codeBlock],
  );

  if (!projectId || !modelId) {
    navigate('/');
    return null;
  }

  return (
    <DragProvider>
      <ModelEditorURLParameterTracker />
      <ProjectSubmodelLoader />
      <CurrentDiagramTracker />
      <UpdateAPIDispatcher />
      <UpdateParametersAPIDispatcher />
      <UpdateSubmodelDispatcher />
      <ConvertGroupToSubmodelTracker />
      <NodeNavigator />
      <ModelRestoreTracker />
      <CurrentModelRefAccess />
      <VisualizationTraceInitializer modelId={modelId} />
      <VisualizationTraceTracker modelId={modelId} />
      <VisualizationTraceSimulationTracker modelId={modelId} />
      <VisualizationTraceRecordTracker />
      <DataExplorerModelVersionsLoader />
      <DataExplorerSignalTraceLoader />

      {/* Model renderer takes up the full content area with other content overlays to prevent canvas resizes */}
      {modelRendererOpen && (
        <ModelRendererWrapper
          currentDiagram={currentDiagram}
          isDiagramReadonly={isDiagramReadonly}
          setTransform={setTransform}
          externalOverlayRef={externalOverlayRef}
        />
      )}

      <AppContentWithFooterWrapper ref={AppContentWithFooterWrapperRef}>
        <AppContentWrapper>
          <Outlet />

          {/* Left sidebar */}
          <ModelLeftSidebar />

          {/* Center content */}
          {!loadedModelId || !areUserOptionsLoaded ? (
            <ModelLoaderWrapper>
              <Spinner />
            </ModelLoaderWrapper>
          ) : codeEditorOpen && codeEditorQuery ? (
            <CodeEditor
              editingDisabled={isDiagramReadonly}
              defaultValue={codeDefaultValue}
              onChangeCode={onChangeBlockCode}
              editorDisplayPath={editorDisplayPath || ''}
              language={codeLanguage}
              multiPaneData={codeMultiPaneData}
              codeBlockForCompletions={codeBlock}
            />
          ) : stateMachineEditorOpen ? (
            <StateMachineEditor readOnly={isDiagramReadonly} />
          ) : modelRendererOpen ? (
            <ModelRendererOverlay
              ref={externalOverlayRef}
              isReadonly={isDiagramReadonly}>
              {isDiagramReadonly && (
                <ReadonlyOverlay>
                  <ReadonlyBanner>
                    {t({
                      id: 'manageMembers.roleOptions.read_only',
                      message: 'Read only',
                    })}
                  </ReadonlyBanner>
                </ReadonlyOverlay>
              )}

              <NestedSubmodelLoader />
              <ModelEditorBreadcrumb />
              <SideMenusWrapper>
                {(topLevelModelType === 'Model' ||
                  topLevelModelType === 'Experiment') && <LayersMenu />}
                <ZoomMenu />
              </SideMenusWrapper>
            </ModelRendererOverlay>
          ) : null}

          {/* Right sidebar */}
          <DiagramRightSidebar />
        </AppContentWrapper>

        {/* Footer content */}
        <FooterContainer
          enabled={!!loadedModelId}
          savedHeight={layoutPrefs?.bottomPanelHeight}
          parentRef={AppContentWithFooterWrapperRef.current || undefined}
        />
      </AppContentWithFooterWrapper>

      <DragOverlay />
    </DragProvider>
  );
};

export default ModelEditor;
