import { SignUp } from '@clerk/clerk-react';
import React from 'react';
import { NotSignedInWrapper } from './NotSignedInWrapper';

const SignUpPage: React.FC = () => (
  <NotSignedInWrapper>
    <SignUp
      redirectUrl="/login/sign-up-success"
      path="/login/sign-up"
      routing="path"
    />
  </NotSignedInWrapper>
);

export default SignUpPage;
