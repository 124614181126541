import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import { requiredRules } from 'ui/common/Input/inputValidation';
import { isValidSubmodelParameterNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsRow,
  DetailsSection,
  DetailsDarkGroup,
  DetailsDarkGroupLabel,
} from 'ui/modelEditor/DetailsComponents';

interface Props {
  canEdit: boolean;
}

const ParameterDefinitionDetails: React.FC<Props> = ({ canEdit }: Props) => {
  const dispatch = useAppDispatch();

  const parameterDefinitions = useAppSelector(
    (state) => state.model.present.parameterDefinitions,
  );
  const portDefinitionsInputs = useAppSelector(
    (state) => state.model.present.portDefinitionsInputs,
  );
  const portDefinitionsOutputs = useAppSelector(
    (state) => state.model.present.portDefinitionsOutputs,
  );

  const addParameterDefinition = () => {
    if (!canEdit) return;

    dispatch(modelActions.addParameterDefinition({}));
  };

  const removeParameterDefinition = (paramDefId: string) => {
    dispatch(
      modelActions.removeParameterDefinition({
        paramDefId,
      }),
    );
  };

  const updateParameterName = (paramDefId: string, newName: string) => {
    dispatch(
      modelActions.updateParameterDefinitionName({
        paramDefId,
        newName,
      }),
    );
  };

  const updateParameterDefaultValue = (
    paramDefId: string,
    newDefaultValue: string,
    inputHeight?: string,
  ) => {
    dispatch(
      modelActions.updateParameterDefinitionDefaultValue({
        paramDefId,
        newDefaultValue,
        inputHeight,
      }),
    );
  };

  const updateParameterDescription = (
    paramDefId: string,
    newDescription: string,
    inputHeight?: string,
  ) => {
    dispatch(
      modelActions.updateParameterDefinitionDescription({
        paramDefId,
        newDescription,
        inputHeight,
      }),
    );
  };

  return (
    <>
      {canEdit ? (
        <SectionHeading
          testId="parameter-definition-details"
          onButtonClick={addParameterDefinition}
          isButtonEnabled={canEdit}
          buttonTooltip={t({
            id: 'referenceSubmodelDetails.addParameterDefinitionButtonTooltip',
            message: 'Add parameter definition',
          })}>
          {t({
            id: 'referenceSubmodelDetails.parameterDefinitionsTitle',
            message: 'Submodel Parameters',
          })}
        </SectionHeading>
      ) : (
        <SectionHeading testId="parameter-definition-details">
          {t({
            id: 'referenceSubmodelDetails.parameterDefinitionsTitle',
            message: 'Submodel Parameters',
          })}
        </SectionHeading>
      )}

      <DetailInputRowsSection>
        {parameterDefinitions.map((paramDef) => (
          <DetailsSection key={paramDef.uuid} vertical>
            <DetailsRow>
              <DetailsInput
                grow
                testId={`extra-param-name-${paramDef.uuid}`}
                value={paramDef.name}
                onSubmitValue={(newName) =>
                  updateParameterName(paramDef.uuid, newName)
                }
                disabled={!canEdit}
                validationRules={isValidSubmodelParameterNameRuleSet(
                  parameterDefinitions,
                  portDefinitionsInputs,
                  portDefinitionsOutputs,
                  paramDef.uuid,
                )}
              />
              {canEdit && (
                <Button
                  variant={ButtonVariants.LargeTertiary}
                  Icon={Remove}
                  onClick={() => removeParameterDefinition(paramDef.uuid)}
                />
              )}
            </DetailsRow>
            <DetailsDarkGroup>
              <DetailsDarkGroupLabel>
                {t({
                  id: 'referenceSubmodelDetails.parameter.defaultValue.label',
                  message: 'Default value',
                })}
              </DetailsDarkGroupLabel>
              <DetailsRow>
                <DetailsInput
                  grow
                  placeholder={t({
                    id: 'referenceSubmodelDetails.parameter.defaultValue.noValue',
                    message: 'None',
                  })}
                  testId={`extra-param-default-value-${paramDef.uuid}`}
                  value={paramDef.default_value}
                  onSubmitValue={(defaultValue, { inputHeight }) =>
                    updateParameterDefaultValue(
                      paramDef.uuid,
                      defaultValue,
                      inputHeight,
                    )
                  }
                  multilineHeight={paramDef.uiprops?.default_value_height}
                  disabled={!canEdit}
                  hasBorder
                  validationRules={requiredRules}
                  allowMultiline
                  isMonospaced
                />
              </DetailsRow>
              <DetailsDarkGroupLabel>
                {t({
                  id: 'referenceSubmodelDetails.parameter.description.placeholder',
                  message: 'Description',
                })}
              </DetailsDarkGroupLabel>
              <DetailsRow>
                <DetailsInput
                  grow
                  placeholderTextColorDark
                  testId={`extra-param-description-${paramDef.uuid}`}
                  value={paramDef.description}
                  onSubmitValue={(description, { inputHeight }) =>
                    updateParameterDescription(
                      paramDef.uuid,
                      description,
                      inputHeight,
                    )
                  }
                  multilineHeight={paramDef.uiprops?.description_height}
                  disabled={!canEdit}
                  hasBorder
                  allowMultiline
                />
              </DetailsRow>
            </DetailsDarkGroup>
          </DetailsSection>
        ))}
      </DetailInputRowsSection>
    </>
  );
};

export default ParameterDefinitionDetails;
