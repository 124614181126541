import { ModelGetResponse, ModelLevelParameters, getModel } from 'app/apiData';
import { SubmodelFetchItem } from 'app/apiGenerated/generatedApiTypes';
import { runAutolayout } from 'app/autolayout';
import {
  ModelConfiguration,
  ModelDiagram,
  StateMachineDiagram,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dataExplorerPlotDataActions } from 'app/slices/dataExplorerPlotDataSlice';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { modelMetadataActions } from 'app/slices/modelMetadataSlice';
import { modelActions } from 'app/slices/modelSlice';
import { navigationActions } from 'app/slices/navigationSlice';
import { projectActions } from 'app/slices/projectSlice';
import { simResultsActions } from 'app/slices/simResultsSlice';
import { submodelsActions } from 'app/slices/submodelsSlice';
import { DiagramFooterTab, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { AppDispatch } from 'app/store';
import { updateModelForSubmodelReferenceChanges } from 'app/utils/modelSubmodelFixupUtils';
import {
  areAllReferencedSubmodelsLoaded,
  getSubmodelsToFetchFromDiagrams,
} from 'app/utils/submodelUtils';
import React from 'react';
import { ActionCreators as UndoRedoActionCreators } from 'redux-undo';
import { useNotifications } from 'ui/common/notifications/useNotifications';

interface ModelToProcess {
  modelId: string;
  kind?: 'Model' | 'Experiment';
  diagram: ModelDiagram;
  submodelsSection: SubmodelsSection;
  stateMachines?: {
    [k: string]: StateMachineDiagram | undefined;
  };
  parameters: ModelLevelParameters;
  configuration?: ModelConfiguration;
  name: string;
  editId: number | string;
  updatedAt: string;
  needsAutolayout: boolean;
  referencedSubmodels: SubmodelFetchItem[];
}

export function clearModelState(dispatch: AppDispatch): void {
  dispatch(modelMetadataActions.resetModelMetadataState());
  dispatch(simResultsActions.resetSimResultsState());
  dispatch(projectActions.resetProjectState());
  dispatch(modelActions.resetModelState());
  dispatch(uiFlagsActions.allLayersOff());
  dispatch(navigationActions.resetNavigationState());
  dispatch(dataExplorerActions.resetDataExplorerState());
  dispatch(dataExplorerPlotDataActions.resetDataExplorerPlotDataState());
}

export function useModelData(projectId: string, modelId: string) {
  const dispatch = useAppDispatch();
  const { showError } = useNotifications();

  const idToVersionIdToSubmodelInfo = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelInfo,
  );
  const idToVersionIdToNotFoundReason = useAppSelector(
    (state) => state.submodels.idToVersionIdToNotFoundReason,
  );

  const forceReloadModel = useAppSelector(
    (state) => state.project.forceReloadModel,
  );

  // Store the model to process rather than updating the state directly
  // so we can check to make sure the user is still looking at the same
  // model after the async delay to retrieve the model
  // to make sure we don't update the data if the user has moved on.
  const [modelToProcess, setModelToProcess] =
    React.useState<ModelToProcess | null>(null);

  const loadLatestModel = React.useCallback(() => {
    clearModelState(dispatch);
    getModel(
      modelId,
      (modelResponse: ModelGetResponse) => {
        const diagram = modelResponse.diagram as ModelDiagram;
        const submodelsSection = modelResponse.submodels as SubmodelsSection;

        setModelToProcess({
          modelId: modelResponse.uuid,
          kind: modelResponse.kind,
          diagram,
          submodelsSection,
          stateMachines: modelResponse.state_machines,
          parameters: modelResponse.parameters,
          configuration: modelResponse.configuration,
          name: modelResponse.name,
          editId: modelResponse.version,
          updatedAt: modelResponse.updated_at || '',
          needsAutolayout: modelResponse.needs_autolayout,
          referencedSubmodels: getSubmodelsToFetchFromDiagrams(
            diagram,
            submodelsSection,
          ),
        });
      },
      (e) => showError('Unable to load model.', e),
    );
  }, [dispatch, showError, modelId]);

  // Always initially load the most up to date model.
  // Use fetch rather than RTKQuery so the model is never cached
  // and we always have the most up to date data.
  React.useEffect(() => {
    loadLatestModel();
  }, [dispatch, modelId, loadLatestModel]);

  // Reload the model if requested.
  React.useEffect(() => {
    if (forceReloadModel) {
      loadLatestModel();
      dispatch(projectActions.clearRequestToReloadModel());
    }
  }, [dispatch, forceReloadModel, loadLatestModel]);

  React.useEffect(() => {
    if (!modelToProcess) {
      return;
    }

    if (modelToProcess.modelId !== modelId) {
      setModelToProcess(null);
      return;
    }

    const areSubmodelsLoaded = areAllReferencedSubmodelsLoaded(
      idToVersionIdToSubmodelInfo,
      modelToProcess.referencedSubmodels,
      idToVersionIdToNotFoundReason,
    );
    if (!areSubmodelsLoaded) {
      dispatch(
        submodelsActions.requestLoadSubmodelInfos(
          modelToProcess.referencedSubmodels,
        ),
      );
      return;
    }

    // Update model state for any updates to submodels
    // (ports added or removed, for example)
    updateModelForSubmodelReferenceChanges(
      modelToProcess.diagram,
      modelToProcess.submodelsSection,
      idToVersionIdToSubmodelInfo,
    );

    // Load new model state.
    dispatch(
      modelActions.loadModelContent({
        diagram: modelToProcess.diagram,
        submodels: modelToProcess.submodelsSection,
        state_machines: modelToProcess.stateMachines,
        parameters: modelToProcess.parameters,
        configuration: modelToProcess.configuration,
        name: modelToProcess.name,
        kind: modelToProcess.kind,
      }),
    );
    dispatch(
      modelMetadataActions.updateOpenModel({
        modelId: modelToProcess.modelId,
        editId: modelToProcess.editId,
        updatedAt: modelToProcess.updatedAt,
      }),
    );
    dispatch(uiFlagsActions.setDiagramFooterTab(DiagramFooterTab.None));

    if (modelToProcess.needsAutolayout) {
      runAutolayout(
        modelToProcess.diagram.nodes,
        modelToProcess.diagram.links,
      ).then((layoutPayload) => {
        if (layoutPayload) {
          dispatch(modelActions.consumeAutoLayout(layoutPayload));
        }
      });
    }

    setModelToProcess(null);

    // clear out redux undo/redo history when a model is loaded
    // (shouldn't be possible to return to a state before the model is loaded)
    dispatch(UndoRedoActionCreators.clearHistory());
  }, [
    dispatch,
    modelId,
    modelToProcess,
    idToVersionIdToSubmodelInfo,
    idToVersionIdToNotFoundReason,
  ]);

  // Update submodel ports if the underlying reference submodel changes
  // or becomes available.
  React.useEffect(() => {
    if (!idToVersionIdToSubmodelInfo) {
      return;
    }

    dispatch(
      modelActions.updateReferencedSubmodelInstances({
        idToVersionIdToSubmodelInfo,
      }),
    );
  }, [dispatch, idToVersionIdToSubmodelInfo]);
}
