import { useProjectOrRedirect } from 'app/behaviorHooks/useProjectOrRedirect';
import React from 'react';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import ModelEditor from 'ui/modelEditor/ModelEditor';
import { TopLevelModelTracker } from 'ui/modelEditor/TopLevelModelTracker';
import { useAppParams } from 'util/useAppParams';

const ValidateRouteProjectModel: React.FC = () => {
  const { modelId } = useAppParams();

  const { isLoadingProject: isProjectLoading, project } =
    useProjectOrRedirect();

  if (isProjectLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (!project || !modelId) {
    return null;
  }

  return (
    <>
      <TopLevelModelTracker project={project} modelId={modelId} />
      <ModelEditor />
    </>
  );
};

export default ValidateRouteProjectModel;
