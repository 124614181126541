import { t } from '@lingui/macro';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import * as Icons from 'ui/common/Icons';
import { useModal } from 'ui/common/Modal/useModal';
import NewProjectModal from 'ui/dashboard/projectsList/NewProjectModal';

export const NewProjectButton = () => {
  const { triggerModal } = useModal();

  const createProjectModal = () =>
    triggerModal(
      <NewProjectModal />,
      t({
        id: 'dashboard.projects.newModal.title',
        message: 'Create a new project',
      }),
    );

  return (
    <Button
      Icon={Icons.Standard.Project}
      variant={ButtonVariants.LargePrimary}
      onClick={createProjectModal}
      className="tour-onboarding-1"
      testId="create-project-button">
      {t({
        id: 'dashboard.projects.newProjectButtonLabel',
        message: 'New Project',
      })}
    </Button>
  );
};
