import React from 'react';
import { useAppSelector } from 'app/hooks';
import { ModelRestoreState } from 'app/slices/modelMetadataSlice';
import { useProjectPermission } from './useProjectPermission';

export function useModelPermission(
  projectId: string | undefined,
  modelId?: string,
  versionId?: string,
) {
  const { arePermissionsLoaded, canEditProject, canAdministrateProject } =
    useProjectPermission(projectId);

  const { loadedModelId, editId, modelRestoreState } = useAppSelector(
    (state) => state.modelMetadata,
  );

  const canEditModelMetadata = React.useMemo(
    () => !!(canEditProject && modelId),
    [canEditProject, modelId],
  );

  const canEditCurrentModelVersion = React.useMemo(() => {
    if (
      !canEditProject ||
      !modelId ||
      !loadedModelId ||
      modelId !== loadedModelId ||
      editId === null
    )
      return false;

    // TODO if there is a lock on collaboration and this session doesn't possess the lock,
    // the model should not be editable.

    // If we are viewing or restoring a version, the model should not be editable.
    if (versionId || modelRestoreState !== ModelRestoreState.None) {
      return false;
    }

    return true;
  }, [
    canEditProject,
    modelId,
    loadedModelId,
    editId,
    versionId,
    modelRestoreState,
  ]);

  const canUseVisualizer = React.useMemo(
    () => !versionId && modelRestoreState === ModelRestoreState.None,
    [modelRestoreState, versionId],
  );

  return {
    arePermissionsLoaded,

    /**
     * Does the current user have permission to add models, other files, or delete the project?
     */
    canEditProject,

    /**
     * Does the current user have permission to edit project permissions or delete the project?
     */
    canAdministrateProject,

    /**
     * Can the model version history be edited?
     */
    canEditModelMetadata,

    /**
     * Can the currently loaded model version be edited?
     * Note that even if the user has edit permission to the model,
     * they cannot edit a previous version of the model (without restoring it first).
     */
    canEditCurrentModelVersion,
    /**
     * Does the current user have permission to use the visualizer?
     */
    canUseVisualizer,
  };
}
