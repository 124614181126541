import { Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React from 'react';

import Menu from 'ui/common/Menu/Menu';

const canvasSize = (theme: Theme) =>
  `calc(100vw - ${theme.sizes.leftSidebarWidth} - ${theme.sizes.rightSidebarWidth})`;

const breadcrumbSize = (theme: Theme) => `calc(${canvasSize(theme)} * 0.45)`;

export const BreadcrumbElement = styled.div({
  display: 'flex',
  maxWidth: '33%',
  overflow: 'hidden',
});

export const BreadcrumbMenu = styled(Menu)({
  display: 'flex',
  flex: 0,
  flexBasis: 'auto',
});

const BreadcrumbWrapper = styled.section(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  maxWidth: breadcrumbSize(theme),
  minHeight: theme.spacing.xxlarge,
  height: theme.spacing.xxlarge,
  alignItems: 'center',
  background: theme.colors.ui.blockBackground,
  padding: theme.spacing.small,
  border: `1px solid ${theme.colors.grey[10]}`,
  borderRadius: '2px',
  top: theme.spacing.normal,
  left: theme.spacing.normal,
  pointerEvents: 'auto',
  zIndex: 1,
}));

const Separator = styled.div(({ theme }) => ({
  width: theme.spacing.large,
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
  color: theme.colors.text.secondary,
}));

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Breadcrumb: React.FC<Props> = ({ children, className }) => {
  let childElements: React.ReactChild[] = [];
  const childrenCount = React.Children.count(children);
  React.Children.map(children, (child, index) => {
    const item = child as React.ReactElement;
    childElements.push(
      <React.Fragment key={`breadcrumb_item_${index}`}>
        {item.props.menu ? item : item}
        {index < childrenCount - 1 && <Separator>/</Separator>}
      </React.Fragment>,
    );
  });
  return (
    <BreadcrumbWrapper className={className}>{childElements}</BreadcrumbWrapper>
  );
};

export default Breadcrumb;
