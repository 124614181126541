import sanitizeDecimalInput from './sanitizeDecimalInput';

const inputNumberCallback =
  (cb: (n: number, s: string) => void) => (_s: string) => {
    const s = _s === '.' ? '0.' : _s;
    const ds =
      _s === '.'
        ? '0.'
        : _s.split('').includes('.')
        ? sanitizeDecimalInput(_s)
        : '';
    const parsedNumber = parseFloat(s);

    cb(parsedNumber, ds);
  };

export default inputNumberCallback;
