/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const VariableInspector: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.002 19.26C13.3087 19.26 12.7887 19.0433 12.442 18.61C12.1127 18.1593 11.8527 17.5093 11.662 16.66L10.934 13.696L10.674 13.28L9.894 10.628C9.686 9.9 9.46933 9.33667 9.244 8.938C9.036 8.53934 8.76733 8.262 8.438 8.106C8.126 7.95 7.70133 7.872 7.164 7.872C7.008 7.872 6.86066 7.88067 6.722 7.898C6.58333 7.91534 6.45333 7.93267 6.332 7.95V7.066L9.894 6.65H10.83C11.1247 7.014 11.376 7.456 11.584 7.976C11.8093 8.47867 12.0087 9.08534 12.182 9.796L12.52 11.408L12.78 11.954L13.43 14.086C13.7767 15.1607 14.08 15.9407 14.34 16.426C14.6173 16.9113 14.938 17.154 15.302 17.154C15.6487 17.154 15.9607 17.024 16.238 16.764C16.5153 16.504 16.81 16.1573 17.122 15.724L17.746 16.166C17.5727 16.4607 17.356 16.79 17.096 17.154C16.8533 17.5007 16.576 17.8387 16.264 18.168C15.952 18.48 15.6053 18.74 15.224 18.948C14.86 19.156 14.4527 19.26 14.002 19.26ZM6.124 19.26C5.56933 19.26 5.17066 19.1213 4.928 18.844C4.68533 18.5493 4.564 18.2373 4.564 17.908C4.564 17.596 4.668 17.3273 4.876 17.102C5.06666 16.8593 5.32666 16.738 5.656 16.738C5.916 16.738 6.19333 16.7987 6.488 16.92C6.8 17.024 7.06 17.076 7.268 17.076C7.35466 17.076 7.45866 17.05 7.58 16.998C7.70133 16.946 7.85733 16.8247 8.048 16.634C8.256 16.4433 8.49866 16.1313 8.776 15.698L10.752 12.708L11.324 14.06H10.882L9.322 16.738C8.94066 17.414 8.50733 18.0033 8.022 18.506C7.554 19.0087 6.92133 19.26 6.124 19.26ZM14.418 9.796L12.702 12.5L12.182 11.122H12.572L13.794 9.016C14.0367 8.58267 14.3053 8.184 14.6 7.82C14.912 7.43867 15.2673 7.12667 15.666 6.884C16.082 6.64134 16.5587 6.52 17.096 6.52C17.5987 6.52 17.954 6.63267 18.162 6.858C18.3873 7.066 18.5 7.36067 18.5 7.742C18.5 8.14067 18.3787 8.46134 18.136 8.704C17.8933 8.92934 17.5987 9.042 17.252 9.042C16.9747 9.042 16.7233 8.99 16.498 8.886C16.2727 8.782 16.0387 8.73 15.796 8.73C15.5187 8.73 15.276 8.81667 15.068 8.99C14.86 9.16334 14.6433 9.432 14.418 9.796Z"
      fill={props.fill}
    />
  </svg>
);

VariableInspector.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default VariableInspector;
