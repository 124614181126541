import { BlockClassName, SubmodelInstance } from '@collimator/model-schemas-ts';
import { MouseActions } from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { HoverEntityType } from 'app/common_types/SegmentTypes';
import { blockClassLookup } from 'app/generated_blocks';
import { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { NodeInstance, Port } from 'app/generated_types/SimulationModel';
import { getSubmodelPortIdOfIoNode, nodeClassToPrintName } from 'app/helpers';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { getSimulationRef } from 'app/sliceRefAccess/SimulationRef';
import {
  getSubmodelRef,
  isSubmodelRefAvailable,
} from 'app/sliceRefAccess/SubmodelRef';
import { MINIMUM_ZOOM } from 'app/slices/cameraSlice';
import { ErrorTreeNode } from 'app/slices/errorsSlice';
import { getPortNodeLocalCoordinate } from 'app/utils/getPortOffsetCoordinate';
import { renderConstants } from 'app/utils/renderConstants';
import { getSpecificReferenceSubmodelByNode } from 'app/utils/submodelUtils';
import * as NVG from 'nanovg-js';
import { SPACING } from 'theme/styleConstants';
import { cmlIsMatrix } from 'ui/modelEditor/LinkDetails';
import { getPortPathName } from 'ui/modelEditor/portPathNameUtils';
import { drawPort } from 'ui/modelRendererInternals/drawPort';
import {
  PortConnListType,
  RendererState,
} from 'ui/modelRendererInternals/modelRenderer';
import { calculateTextSize } from 'util/calculateTextSize';
import { flippableIconIDs, getBlockIconID } from 'util/getBlockIconID';
import { LINK_COLORS, getTimeModeColor } from './drawLink';
import { BLOCK_GLYPH_MAP, drawPortGlyph } from './drawPortLabel';
import { drawSignalLabels } from './drawSignalLabels';
import { drawSignalPlotter } from './drawSignalPlotter';
import { PORT_BLOCK_YOFFSET, getVisualNodeHeight } from './getVisualNodeHeight';
import { GLYPH_MARGIN, getVisualNodeWidth } from './getVisualNodeWidth';
import { multiRadiusRect } from './multiRadiusRect';
import {
  RasterLoadState,
  getOrInitLoadImageFromStore,
} from './rasterTextureStore';

export const BLOCK_FILLS = {
  normal: NVG.RGBA(249, 249, 251, 255),
  iterator: NVG.RGBA(248, 245, 227, 255),
  inside_iterator: NVG.RGBA(239, 232, 180, 255),
  linearizer: NVG.RGBA(0, 232, 180, 255), // TODO: get proper color
};
const BLOCK_FILL_TYPE_MAP: { [k in BlockClassName]?: NVG.NVGcolor } = {
  'core.Iterator': BLOCK_FILLS.iterator,
  'core.LoopBreak': BLOCK_FILLS.inside_iterator,
  'core.LoopCounter': BLOCK_FILLS.inside_iterator,
  'core.LoopMemory': BLOCK_FILLS.inside_iterator,
  'core.LinearizedSystem': BLOCK_FILLS.linearizer,
};

const NAME_FONTSIZE = 12;
const NAME_SCALEDOWN_THRESHOLD = 0.7;
const NAME_SCALEUP_THRESHOLD = 1.75;
const MIN_NAME_SIZE = 9;

// NOTE: eventually we should just pass the enum when we have more port visuals
// but it doesn't really mean anything to do that right now since there's only
// one other visual, so here's this convenience function.
// (please use very careful judgement if you decide to reuse this, because it really shouldn't be.)

const isPortHollowVis = (
  port: Port,
  portIndex: number,
  blockClass: ComputationBlockClass,
): boolean => {
  if (blockClass.ports.inputs) {
    if (port.kind === 'conditional' && blockClass.ports.inputs.conditional) {
      for (let j = 0; j < blockClass.ports.inputs.conditional.length; j++) {
        const condPortDef = blockClass.ports.inputs.conditional[j];

        if (
          condPortDef.order === portIndex &&
          condPortDef.name === port.name &&
          condPortDef.appearance === 'hollow'
        ) {
          return true;
        }
      }
    } else if (port.kind === 'static' && blockClass.ports.inputs.static) {
      for (let j = 0; j < blockClass.ports.inputs.static.length; j++) {
        const staticPortDef = blockClass.ports.inputs.static[j];

        if (
          staticPortDef.name === port.name &&
          staticPortDef.appearance === 'hollow'
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

const renderTextOverflowGradient = (
  nvg: NVG.Context,
  rs: RendererState,
  textX: number,
  textY: number,
  renderingTextWidth: number,
  textHeight: number,
) => {
  const rawScale = Math.round(window.devicePixelRatio * rs.zoom);
  const scale = rawScale > 2 ? 4 : rawScale < 1 ? 1 : rawScale;
  const scaledRasterID = `text_fader_${scale}x`;
  const rasterMeta = getOrInitLoadImageFromStore(
    nvg,
    `${process.env.PUBLIC_URL}/assets/${scaledRasterID}.png`,
    scaledRasterID,
    scale,
  );
  if (rasterMeta?.loadState === RasterLoadState.Loaded) {
    const rw = Math.min(
      renderingTextWidth,
      (rasterMeta.width / scale) * rs.zoom,
    );
    const rh = textHeight;
    const rx = textX + renderingTextWidth - rw + 0.5;
    const ry = textY - textHeight / 2;
    const imgPaint = nvg.imagePattern(rx, ry, rw, rh, 0, rasterMeta.imageId, 1);
    nvg.beginPath();
    nvg.rect(rx, ry, rw, rh);
    nvg.fillPaint(imgPaint);
    nvg.fill();
  }
};

export function drawNode(
  nvg: NVG.Context,
  rs: RendererState,
  node: NodeInstance,
  connectedPorts: PortConnListType | undefined,
  selected: boolean,
  renderX: number,
  renderY_raw: number,
): void {
  const isInportBlock = node.type === 'core.Inport';
  const isOutportBlock = node.type === 'core.Outport';
  const isIOBlock = isInportBlock || isOutportBlock;

  const isInIterator =
    rs.refs.current.currentSubdiagramType === 'core.Iterator';

  const hovering =
    rs.hoveringEntity !== undefined &&
    rs.hoveringEntity.entityType === HoverEntityType.Node &&
    rs.hoveringEntity.block.uuid === node.uuid;

  const nodeFlipped = node.uiprops.directionality === 'left';

  const currentNodeHeight = getVisualNodeHeight(node);
  const halfHeight = currentNodeHeight / 2;
  const zoomedHeight = currentNodeHeight * rs.zoom;
  const halfZoomedHeight = halfHeight * rs.zoom;
  const currentNodeWidth = getVisualNodeWidth(node);
  const zoomedWidth = currentNodeWidth * rs.zoom;

  const renderY = isIOBlock ? renderY_raw + PORT_BLOCK_YOFFSET : renderY_raw;

  const glyphMap = BLOCK_GLYPH_MAP[node.type];
  const nodeHasGlyphs = Boolean(glyphMap);

  const ioCurveLeft =
    (isInportBlock && !nodeFlipped) || (isOutportBlock && nodeFlipped);
  const ioCurveRight =
    (isOutportBlock && !nodeFlipped) || (isInportBlock && nodeFlipped);

  const blockFillColor = BLOCK_FILL_TYPE_MAP[node.type] || BLOCK_FILLS.normal;

  // draw actual block rect
  {
    const normalRadius = renderConstants.BLOCK_CORNER_RADIUS * rs.zoom;
    const ioRadius = halfZoomedHeight;
    nvg.beginPath();
    multiRadiusRect(
      nvg,
      renderX * rs.zoom,
      renderY * rs.zoom,
      zoomedWidth,
      zoomedHeight,
      ioCurveLeft ? ioRadius : normalRadius,
      ioCurveRight ? ioRadius : normalRadius,
      ioCurveRight ? ioRadius : normalRadius,
      ioCurveLeft ? ioRadius : normalRadius,
    );
    nvg.fillColor(blockFillColor);
    nvg.fill();
  }

  const parentPath = getCurrentModelRef().submodelPath;

  // get error from state
  // TODO: pull this out into a function
  // TODO: get proper UI design
  let errorHighlightColor: NVG.NVGcolor | undefined;
  let thisNodeError: ErrorTreeNode | undefined;
  let iteratingErrorNode: ErrorTreeNode = rs.refs.current.errorsState.rootNode;
  for (let i = 0; i < parentPath.length + 1; i++) {
    if (i == parentPath.length) {
      thisNodeError = iteratingErrorNode.children[node.uuid];
      if (thisNodeError?.errorKind) {
        // This very block has an error
        errorHighlightColor = nvg.RGB(213, 50, 50);
      } else if (thisNodeError) {
        // Error is in the children
        errorHighlightColor = nvg.RGB(180, 120, 120);
      }
      break;
    }

    const nextNode = iteratingErrorNode.children[parentPath[i]];
    if (!nextNode) break;

    iteratingErrorNode = nextNode;
  }

  const portPathName = getPortPathName(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    {
      parentPath,
      nodeId: node.uuid || '',
      portIndex: 0,
    },
    { includePortNameForNonSubmodels: false },
  );

  {
    const { timeMode } = (portPathName &&
      getSimulationRef().compilationData.signalsData[portPathName]) || {
      timeMode: undefined,
    };
    const { showRatesInModel } = rs.refs.current.uiFlags;

    let nodeBorderColor = errorHighlightColor || nvg.RGB(173, 184, 184);

    if (!errorHighlightColor && showRatesInModel) {
      if (isInIterator) {
        // TODO: we might want to rely on the backend for this
        // this is just for now until we're sure how the backend timemode data looks for iterators
        nodeBorderColor = LINK_COLORS.iterator;
      } else if (timeMode && timeMode.mode !== 'Unknown') {
        nodeBorderColor = getTimeModeColor(timeMode);
      }
    }

    const nodeStrokeWidth = 1.5 * rs.zoom;
    const halfNodeStroke = nodeStrokeWidth / 2;
    const normalRadius =
      renderConstants.BLOCK_CORNER_RADIUS * rs.zoom - halfNodeStroke;
    const ioRadius = isIOBlock
      ? halfZoomedHeight - halfNodeStroke
      : normalRadius;
    nvg.beginPath();
    multiRadiusRect(
      nvg,
      renderX * rs.zoom + halfNodeStroke,
      renderY * rs.zoom + halfNodeStroke,
      zoomedWidth - nodeStrokeWidth,
      zoomedHeight - nodeStrokeWidth,
      ioCurveLeft ? ioRadius : normalRadius,
      ioCurveRight ? ioRadius : normalRadius,
      ioCurveRight ? ioRadius : normalRadius,
      ioCurveLeft ? ioRadius : normalRadius,
    );
    nvg.strokeColor(nodeBorderColor);
    nvg.strokeWidth(nodeStrokeWidth);
    nvg.stroke();
  }

  const highlight = hovering || selected;
  if (highlight) {
    const highlightStrokeWidth = 2 * rs.zoom;
    const halfHighlightStroke = highlightStrokeWidth / 2;
    const normalRadius =
      renderConstants.BLOCK_CORNER_RADIUS * rs.zoom + halfHighlightStroke;
    const ioRadius = isIOBlock
      ? halfZoomedHeight + halfHighlightStroke
      : normalRadius;
    nvg.beginPath();
    multiRadiusRect(
      nvg,
      renderX * rs.zoom - halfHighlightStroke,
      renderY * rs.zoom - halfHighlightStroke,
      zoomedWidth + highlightStrokeWidth,
      zoomedHeight + highlightStrokeWidth,
      ioCurveLeft ? ioRadius : normalRadius,
      ioCurveRight ? ioRadius : normalRadius,
      ioCurveRight ? ioRadius : normalRadius,
      ioCurveLeft ? ioRadius : normalRadius,
    );
    nvg.strokeColor(
      selected ? nvg.RGB(105, 225, 219) : nvg.RGBA(105, 225, 219, 100),
    );
    nvg.strokeWidth(highlightStrokeWidth);
    nvg.stroke();
  }

  const { name } = node;
  const blockClass = blockClassLookup(node.type);
  const {
    base: { name: baseClassName },
  } = blockClass;

  const printableClassName = nodeClassToPrintName(node.type);

  const scaledDownFontSize = Math.max(
    NAME_FONTSIZE * (rs.zoom / NAME_SCALEDOWN_THRESHOLD),
    MIN_NAME_SIZE,
  );
  const fontSize =
    rs.zoom < NAME_SCALEDOWN_THRESHOLD
      ? scaledDownFontSize
      : rs.zoom > NAME_SCALEUP_THRESHOLD
      ? NAME_FONTSIZE * (rs.zoom / NAME_SCALEUP_THRESHOLD)
      : NAME_FONTSIZE;

  const rawNameOpacity =
    (rs.zoom - MINIMUM_ZOOM * 2) /
    (NAME_SCALEDOWN_THRESHOLD - MINIMUM_ZOOM * 2);
  const nameOpacity = Math.max(0, Math.min(1, rawNameOpacity));

  const blockLabelY =
    node.uiprops.label_position === 'top'
      ? (renderY - SPACING / 3) * rs.zoom - fontSize
      : (renderY + currentNodeHeight + SPACING / 3) * rs.zoom;

  if (
    nameOpacity > 0 &&
    rs.refs.current.uiFlags.editingNodeNameUUID !== node.uuid
  ) {
    nvg.fontSize(fontSize);
    nvg.fontFace('archivo');
    nvg.textAlign(NVG.Align.CENTER | NVG.Align.TOP);
    nvg.fillColor(nvg.RGBA(74, 74, 74, 255 * nameOpacity));
    nvg.text(
      (renderX + currentNodeWidth / 2) * rs.zoom,
      blockLabelY,
      name || '',
      null,
    );
  }

  const baseRasterIconID = getBlockIconID(baseClassName, node);
  const rawIconScale = Math.round(window.devicePixelRatio * rs.zoom);
  const iconScale = rawIconScale > 2 ? 4 : rawIconScale < 1 ? 1 : rawIconScale;
  const scaledIconID = `renderer_icon_rasters/${baseRasterIconID}_${iconScale}x`;
  let rasterMeta = getOrInitLoadImageFromStore(
    nvg,
    `${process.env.PUBLIC_URL}/assets/${scaledIconID}.png`,
    scaledIconID,
    iconScale,
  );

  const fallbackIconID = `renderer_icon_rasters/generic_${iconScale}x`;
  const fallbackRasterMeta = getOrInitLoadImageFromStore(
    nvg,
    `${process.env.PUBLIC_URL}/assets/${fallbackIconID}.png`,
    fallbackIconID,
    iconScale,
  );

  rasterMeta =
    rasterMeta?.loadState === RasterLoadState.Failed
      ? fallbackRasterMeta?.loadState === RasterLoadState.Loaded
        ? fallbackRasterMeta
        : rasterMeta
      : rasterMeta;

  const flipIcon = nodeFlipped
    ? flippableIconIDs.includes(baseClassName.toLowerCase())
    : false;

  const ioPortId =
    node.type === 'core.Inport' || node.type === 'core.Outport'
      ? getSubmodelPortIdOfIoNode(node)
      : NaN;

  let renderedIconWidth = 0;

  const isSmallConstantBlock =
    node.type === 'core.Constant' &&
    currentNodeHeight < renderConstants.BLOCK_MIN_HEIGHT;

  if (isSmallConstantBlock) {
    const valueTextFontSize = 10 * rs.zoom;
    const constNodeValue =
      rs.refs.current.constantBlockDisplayValues[node.uuid] ||
      `${node.parameters.value?.value}` ||
      'No value';
    const maxValueTextWidth =
      (currentNodeWidth - renderConstants.GRID_SIZE * 2) * rs.zoom;

    const { width: valueTextWidth } = calculateTextSize(constNodeValue, {
      font: 'Archivo',
      fontSize: `${valueTextFontSize}px`,
    });

    const clipValueText = maxValueTextWidth < valueTextWidth;

    const valueTextX =
      nodeFlipped || clipValueText
        ? renderX + renderConstants.GRID_SIZE
        : renderX + currentNodeWidth - renderConstants.GRID_SIZE;

    if (clipValueText) {
      nvg.save();
      nvg.scissor(
        (renderX + renderConstants.GRID_SIZE) * rs.zoom,
        renderY * rs.zoom,
        maxValueTextWidth,
        currentNodeHeight * rs.zoom,
      );
    }

    nvg.fontSize(valueTextFontSize);
    nvg.fontFace('archivo');
    if (clipValueText || nodeFlipped) {
      nvg.textAlign(NVG.Align.LEFT | NVG.Align.MIDDLE);
    } else {
      nvg.textAlign(NVG.Align.RIGHT | NVG.Align.MIDDLE);
    }
    nvg.fillColor(nvg.RGBA(74, 74, 74, 255));
    nvg.text(
      valueTextX * rs.zoom,
      (renderY + currentNodeHeight / 2) * rs.zoom,
      constNodeValue,
      null,
    );

    if (clipValueText) {
      nvg.restore();

      renderTextOverflowGradient(
        nvg,
        rs,
        (renderX + renderConstants.GRID_SIZE) * rs.zoom,
        (renderY + currentNodeHeight / 2) * rs.zoom,
        maxValueTextWidth,
        (currentNodeHeight - 8) * rs.zoom,
      );
    }
  } else if (!isNaN(ioPortId)) {
    // Similar comment as above: this special case shows the
    // port_id as label for Inport and Outport blocks.
    // TODO: https://collimator.atlassian.net/browse/UI-249
    nvg.fontSize(10 * rs.zoom);
    nvg.fontFace('archivo');
    nvg.textAlign(NVG.Align.CENTER | NVG.Align.MIDDLE);

    nvg.fillColor(nvg.RGBA(74, 74, 74, 255));
    nvg.text(
      (renderX + currentNodeWidth / 2) * rs.zoom,
      (renderY + currentNodeHeight / 2) * rs.zoom,
      `${ioPortId}`,
      null,
    );
  } else if (rasterMeta?.loadState === RasterLoadState.Loaded) {
    const raw_w = rasterMeta.width / iconScale;
    const raw_h = rasterMeta.height / iconScale;
    const raw_x = renderX + (currentNodeWidth / 2 - raw_w / 2);
    const raw_y = renderY + (currentNodeHeight / 2 - raw_h / 2);

    const rx = flipIcon ? (raw_x + raw_w) * rs.zoom : raw_x * rs.zoom;
    const ry = raw_y * rs.zoom;
    const rw = flipIcon ? -raw_w * rs.zoom : raw_w * rs.zoom;
    const rh = raw_h * rs.zoom;

    renderedIconWidth = rw;

    const imgPaint = nvg.imagePattern(rx, ry, rw, rh, 0, rasterMeta.imageId, 1);
    nvg.beginPath();
    nvg.rect(rx, ry, rw, rh);
    nvg.fillPaint(imgPaint);
    nvg.fill();
  }

  const baseLabelMargin = renderConstants.GRID_SIZE;
  const glyphOffsetLabelMargin =
    baseLabelMargin + (nodeHasGlyphs ? GLYPH_MARGIN : 0);

  const maxInputPortTextWidth =
    (currentNodeWidth * rs.zoom - renderedIconWidth) / 2 -
    glyphOffsetLabelMargin * rs.zoom;
  const maxOutputPortTextWidth =
    (currentNodeWidth * rs.zoom - renderedIconWidth) / 2 -
    baseLabelMargin * rs.zoom;

  const submodelInstance = node as SubmodelInstance;
  const isActuallyRefSubmodel =
    submodelInstance.type === 'core.ReferenceSubmodel';
  let submodelInfo;
  if (
    isActuallyRefSubmodel &&
    submodelInstance.submodel_reference_uuid &&
    isSubmodelRefAvailable()
  ) {
    const submodelSliceRef = getSubmodelRef();
    submodelInfo = getSpecificReferenceSubmodelByNode(
      submodelInstance,
      submodelSliceRef.idToVersionIdToSubmodelInfo,
    );
  }

  for (let i = 0; i < node.inputs.length; i++) {
    const portCoords = getPortNodeLocalCoordinate(node, PortSide.Input, {
      node: node.uuid,
      port: i,
    });

    if (!portCoords) continue;

    const portConnection = connectedPorts
      ? connectedPorts.find((p) => p.side === PortSide.Input && p.portId === i)
      : undefined;
    const portHasLink = Boolean(portConnection);
    const signalConnected = Boolean(portConnection?.fullyConnected);

    const connectedLinkIndex =
      rs.refs.current.linksIndexLUT[portConnection?.linkUuid || ''];
    const connectedLink = rs.refs.current.links[connectedLinkIndex];
    const connectedSrcPortPathName = getPortPathName(
      getCurrentModelRef().topLevelNodes,
      getCurrentModelRef().submodels,
      {
        parentPath: getCurrentModelRef().submodelPath,
        nodeId: connectedLink?.src?.node || '',
        portIndex: connectedLink?.src?.port || 0,
      },
      { includePortNameForNonSubmodels: false },
    );

    const {
      datatypeAndDimensions: inputDatatypeData,
      timeMode: inputTimeMode,
    } = (connectedSrcPortPathName &&
      getSimulationRef().compilationData.signalsData[
        connectedSrcPortPathName
      ]) || {
      datatypeAndDimensions: undefined,
      timeMode: undefined,
    };

    let currentPortConnectedColor = LINK_COLORS.normal;

    if (
      rs.refs.current.uiFlags.showDatatypesInModel &&
      portConnection &&
      connectedLink.src &&
      inputDatatypeData
    ) {
      const portDataTypeAndDimensions =
        inputDatatypeData[connectedLink.src.port];

      if (
        portDataTypeAndDimensions &&
        portDataTypeAndDimensions.kind === 'Tensor'
      ) {
        const dimensionsArray = portDataTypeAndDimensions.value[0];
        const isMatrix = cmlIsMatrix(dimensionsArray);
        if (isMatrix) {
          currentPortConnectedColor = LINK_COLORS.matrix;
        } else {
          currentPortConnectedColor = LINK_COLORS.vector;
        }
      } else {
        currentPortConnectedColor = LINK_COLORS.scalar;
      }
    }

    if (rs.refs.current.uiFlags.showRatesInModel && portConnection) {
      if (isInIterator) {
        // TODO: we might want to rely on the backend for this
        // this is just for now until we're sure how the backend timemode data looks for iterators
        currentPortConnectedColor = LINK_COLORS.iterator;
      } else if (inputTimeMode) {
        currentPortConnectedColor = getTimeModeColor(inputTimeMode);
      }
    }

    const portHovering =
      rs.refs.current.canEditModel &&
      rs.hoveringEntity !== undefined &&
      rs.hoveringEntity.entityType === HoverEntityType.Port &&
      rs.hoveringEntity.port.blockUuid === node.uuid &&
      rs.hoveringEntity.port.side === PortSide.Input &&
      rs.hoveringEntity.port.portId === i;

    const portX = portCoords.x + renderX;
    const portY = isIOBlock
      ? portCoords.y + renderY_raw
      : portCoords.y + renderY;

    const userDrawingPortsLink =
      (rs.mouseState.state === MouseActions.DrawingLinkFromStart ||
        rs.mouseState.state === MouseActions.DrawingLinkFromEnd) &&
      rs.mouseState.linkUuid === portConnection?.linkUuid;

    let isHollow =
      isPortHollowVis(node.inputs[i], i, blockClass) ||
      Boolean(
        submodelInfo && submodelInfo.portDefinitionsInputs[i]?.default_value,
      );

    if (node.uiprops.show_port_name_labels) {
      const labelText = node.inputs[i].name || '';

      const { width: textWidthRaw, height: textHeight } = calculateTextSize(
        labelText,
        {
          font: 'Archivo',
          fontSize: `${fontSize}px`,
        },
      );

      const clipText = maxInputPortTextWidth < textWidthRaw;
      const renderingTextWidth = clipText
        ? maxInputPortTextWidth
        : textWidthRaw;

      const textX = nodeFlipped
        ? (portX - glyphOffsetLabelMargin) * rs.zoom - renderingTextWidth
        : (portX + glyphOffsetLabelMargin) * rs.zoom;
      const textY = portY * rs.zoom;

      if (clipText) {
        nvg.save();
        nvg.scissor(
          textX,
          textY - textHeight / 2,
          maxInputPortTextWidth,
          textHeight,
        );
      }
      nvg.fontSize(fontSize);
      nvg.fontFace('archivo');
      nvg.textAlign(NVG.Align.LEFT | NVG.Align.MIDDLE);
      nvg.fillColor(nvg.RGBA(74, 74, 74, 255 * nameOpacity));
      nvg.text(textX, textY, labelText, null);
      if (clipText) {
        nvg.restore();

        renderTextOverflowGradient(
          nvg,
          rs,
          textX,
          textY,
          renderingTextWidth,
          textHeight,
        );
      }
    }

    const hasError = Boolean(thisNodeError?.inputPorts?.includes(i));

    drawPort(
      nvg,
      rs,
      portX,
      portY,
      rs.zoom,
      PortSide.Input,
      portHasLink,
      signalConnected,
      portHovering,
      node.uiprops.directionality === 'left',
      currentPortConnectedColor,
      userDrawingPortsLink,
      isHollow,
      hasError,
    );

    drawPortGlyph(nvg, portX, portY, rs.zoom, i, node);
  }

  const {
    datatypeAndDimensions: outputDatatypeData,
    timeMode: outputTimeMode,
  } = (portPathName &&
    getSimulationRef().compilationData.signalsData[portPathName]) || {
    datatypeAndDimensions: undefined,
    timeMode: undefined,
  };

  for (let i = 0; i < node.outputs.length; i++) {
    const portCoords = getPortNodeLocalCoordinate(node, PortSide.Output, {
      node: node.uuid,
      port: i,
    });

    if (!portCoords) continue;

    const portConnection = connectedPorts
      ? connectedPorts.find((p) => p.side === PortSide.Output && p.portId === i)
      : undefined;
    const portHasLink = Boolean(portConnection);
    const signalConnected = Boolean(portConnection?.fullyConnected);

    let currentPortConnectedColor = LINK_COLORS.normal;

    if (
      rs.refs.current.uiFlags.showDatatypesInModel &&
      portConnection &&
      outputDatatypeData
    ) {
      const portDataTypeAndDimensions =
        outputDatatypeData[portConnection.portId];

      if (
        portDataTypeAndDimensions &&
        portDataTypeAndDimensions.kind === 'Tensor'
      ) {
        const dimensionsArray = portDataTypeAndDimensions.value[0];
        const isMatrix = cmlIsMatrix(dimensionsArray);
        if (isMatrix) currentPortConnectedColor = LINK_COLORS.matrix;
        else currentPortConnectedColor = LINK_COLORS.vector;
      } else {
        currentPortConnectedColor = LINK_COLORS.scalar;
      }
    }

    if (rs.refs.current.uiFlags.showRatesInModel && portConnection) {
      if (isInIterator) {
        // TODO: we might want to rely on the backend for this
        // this is just for now until we're sure how the backend timemode data looks for iterators
        currentPortConnectedColor = LINK_COLORS.iterator;
      } else if (outputTimeMode) {
        currentPortConnectedColor = getTimeModeColor(outputTimeMode);
      }
    }

    const portHovering =
      rs.refs.current.canEditModel &&
      rs.hoveringEntity !== undefined &&
      rs.hoveringEntity.entityType === HoverEntityType.Port &&
      rs.hoveringEntity.port.blockUuid === node.uuid &&
      rs.hoveringEntity.port.side === PortSide.Output &&
      rs.hoveringEntity.port.portId === i;

    const portX = portCoords.x + renderX;
    const portY = isIOBlock
      ? portCoords.y + renderY_raw
      : portCoords.y + renderY;

    const userDrawingPortsLink =
      (rs.mouseState.state === MouseActions.DrawingLinkFromStart ||
        rs.mouseState.state === MouseActions.DrawingLinkFromEnd) &&
      rs.mouseState.linkUuid === portConnection?.linkUuid;

    if (node.uiprops.show_port_name_labels) {
      const labelText = node.outputs[i].name || '';

      const { width: textWidthRaw, height: textHeight } = calculateTextSize(
        labelText,
        {
          font: 'Archivo',
          fontSize: `${fontSize}px`,
        },
      );

      const clipText = maxOutputPortTextWidth < textWidthRaw;
      const renderingTextWidth = clipText
        ? maxOutputPortTextWidth
        : textWidthRaw;

      const textX = nodeFlipped
        ? (portX + baseLabelMargin) * rs.zoom
        : (portX - baseLabelMargin) * rs.zoom - renderingTextWidth;
      const textY = portY * rs.zoom;

      if (clipText) {
        nvg.save();
        nvg.scissor(
          textX,
          textY - textHeight / 2,
          maxOutputPortTextWidth,
          textHeight,
        );
      }
      nvg.fontSize(fontSize);
      nvg.fontFace('archivo');
      nvg.textAlign(NVG.Align.LEFT | NVG.Align.MIDDLE);
      nvg.fillColor(nvg.RGBA(74, 74, 74, 255 * nameOpacity));
      nvg.text(textX, textY, labelText, null);
      if (clipText) {
        nvg.restore();

        renderTextOverflowGradient(
          nvg,
          rs,
          textX,
          textY,
          renderingTextWidth,
          textHeight,
        );
      }
    }

    drawPort(
      nvg,
      rs,
      portX,
      portY,
      rs.zoom,
      PortSide.Output,
      portHasLink,
      signalConnected,
      portHovering,
      node.uiprops.directionality === 'left',
      currentPortConnectedColor,
      userDrawingPortsLink,
      false, // hollow
      false, // hasError
    );

    drawSignalLabels(
      nvg,
      rs,
      portCoords.x + renderX,
      portCoords.y + renderY,
      0,
      0,
      node.uuid,
      i,
    );
  }

  drawSignalPlotter(
    nvg,
    rs,
    renderX,
    renderY,
    currentNodeWidth,
    node.uuid,
    getCurrentModelRef().submodelPath,
    node.type,
    nodeFlipped,
  );
}
