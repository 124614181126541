// Tree shaking imports available: https://echarts.apache.org/handbook/en/basics/import/#

import * as echarts from 'echarts';
import { memo, useEffect, useRef, useState } from 'react';
import { useEChartsHandler } from 'ui/dataExplorer/charts/chartHooks';

interface Props {
  setParentEChartsInstance: (c: echarts.ECharts) => void;
  options: echarts.EChartsCoreOption;
  width: number;
  height: number;
  // edit handlers
  toggleSeriesMarkPoint?: (e: any) => void;
  prepTraceDrag?: (traceId: string, displayName: string, color: string) => void;
  // display handlers
  resetScrollZoomTimeout: () => void;
  showContextMenu: (e: any) => void;
  restoreChartRange: (e: any) => void;
  setZoom: (e: any) => void;
}

/**
 * A synchronized React component that acts as a wrapper for an ECharts instance.
 */
const ReactEChartsChart = memo(
  ({
    setParentEChartsInstance,
    options,
    width,
    height,
    toggleSeriesMarkPoint,
    resetScrollZoomTimeout,
    prepTraceDrag,
    showContextMenu,
    restoreChartRange,
    setZoom,
  }: Props) => {
    const chartRef = useRef<HTMLDivElement>(null);
    const [echartsInstance, setEChartsInstance] = useState<echarts.ECharts>();

    // Synchronize echart set up with the `option` prop
    useEffect(() => {
      if (echartsInstance !== undefined) {
        echartsInstance.setOption(options, { replaceMerge: ['series'] });
      }
    }, [options, echartsInstance]);

    // Resize to match wrapper size
    useEffect(() => {
      if (echartsInstance !== undefined) {
        echartsInstance?.resize({ width, height });
      }
    }, [width, height, echartsInstance]);

    /**
     * Attach the custom handlers to the ECharts instance here.
     * Use separate hooks so that the dependency array only contains relevant handler.
     */

    useEChartsHandler(echartsInstance, 'datazoom', resetScrollZoomTimeout);
    useEChartsHandler(echartsInstance, 'datazoom', setZoom);
    useEChartsHandler(echartsInstance, 'contextmenu', showContextMenu);
    useEChartsHandler(echartsInstance, 'restore', restoreChartRange);

    // Doesn't fit `useEChartsHandler` due to second param 'series' (query string).
    useEffect(() => {
      if (!echartsInstance || !toggleSeriesMarkPoint) return;
      echartsInstance.on('click', 'series', toggleSeriesMarkPoint);
      return () => {
        echartsInstance.off('click', toggleSeriesMarkPoint);
      };
    }, [echartsInstance, toggleSeriesMarkPoint]);

    // There's extra logic for this handler attachment because it uses ECHarts internals to determine a legend item drag
    // Also why it's attached to the zrender; there is no echarts event equivalent.
    useEffect(() => {
      if (!echartsInstance || !prepTraceDrag) return;
      const zr = echartsInstance.getZr();
      const onMouseDown = (e: any) => {
        // This type is declared as any because ECharts doesn't expose all its types.
        // Detect if we are not using the left click
        if (e.which !== 1) return;
        const root: any = e.target?.parent;
        const option: any = echartsInstance.getOption();
        if (root?.__legendDataIndex !== undefined) {
          // Implementation detail of using `replaceMerge`. Some series entries are null
          const trace = option.series.filter((traceInfo: any) => !!traceInfo)[
            root.__legendDataIndex
          ];
          const traceId = trace.id;
          const displayName = trace.name;
          // We have to do this here, because colors aren't always persisted.
          const color = trace.color;
          prepTraceDrag(traceId, displayName, color);
        }
      };
      zr.on('mousedown', onMouseDown);
      return () => {
        zr.off('mousedown', onMouseDown);
      };
    }, [echartsInstance, prepTraceDrag]);

    // Initialize echart on mount
    // ! Keep this at the bottom, after all the handler useEffects.
    // Ensures the `dispose` is called after all the handlers are detached from the instance.
    // The reverse order results in many warnings and null errors about the disposed instance.
    useEffect(() => {
      let chart: echarts.ECharts | undefined;
      if (chartRef.current !== null) {
        chart = echarts.init(chartRef.current);
        // echartsInstance stable after mount. Use it for other hooks.
        setEChartsInstance(chart);
        // Can we get rid of this? For ChartMenu sibling.
        setParentEChartsInstance(chart);
      }

      return () => {
        // Dispose on container unmount
        chart?.dispose();
      };
    }, [setParentEChartsInstance]);

    return <div ref={chartRef} />;
  },
);

export default ReactEChartsChart;
