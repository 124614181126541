import React from 'react';
import { Row } from 'react-table';
import MenuContent from 'ui/common/Menu/MenuContent';
import { PortalMenuBackground } from 'ui/common/Menu/PortalMenu';
import { ItemType, MenuItemConfig } from 'ui/common/Menu/menuItemTypes';
import {
  ProjectItemType,
  ProjectsDetailTableData as TableData,
} from 'ui/dashboard/dashboardTypes';
import { useProjectDetailActions } from 'ui/dashboard/projectDetail/useProjectDetailActions';

interface Props {
  row: Row<TableData>;
  data: TableData[];
  triggerClose: () => void;
}

const ProjectItemMenu: React.FC<Props> = ({ row, data, triggerClose }) => {
  const {
    deleteElement,
    renameElement,
    duplicateElement,
    downloadElement,
    downloadDataFile,
    type,
  } = useProjectDetailActions(row.original, data);

  const items: MenuItemConfig[] = React.useMemo(() => {
    const itemsBuilder: MenuItemConfig[] = [];

    switch (type) {
      case ProjectItemType.CODE:
      case ProjectItemType.NOTEBOOKFILE:
      case ProjectItemType.NOTEBOOK:
      case ProjectItemType.MODEL:
      case ProjectItemType.SUBMODEL:
      case ProjectItemType.PROJECTFILE:
      case ProjectItemType.BUS_TYPE:
        itemsBuilder.push({
          type: ItemType.Button,
          content: 'Rename',
          onClick: renameElement,
        });
        break;
    }

    switch (type) {
      case ProjectItemType.CODE:
      case ProjectItemType.NOTEBOOKFILE:
      case ProjectItemType.NOTEBOOK:
      case ProjectItemType.MODEL:
      case ProjectItemType.SUBMODEL:
        itemsBuilder.push({
          type: ItemType.Button,
          content: 'Duplicate',
          onClick: duplicateElement,
        });
        break;
    }

    switch (type) {
      case ProjectItemType.CODE:
      case ProjectItemType.NOTEBOOKFILE:
      case ProjectItemType.NOTEBOOK:
        itemsBuilder.push({
          type: ItemType.Button,
          content: 'Download',
          onClick: downloadElement,
        });
        break;
      case ProjectItemType.PROJECTFILE:
        itemsBuilder.push({
          type: ItemType.Button,
          content: 'Download',
          onClick: downloadDataFile,
        });
        break;
    }

    if (itemsBuilder.length) {
      itemsBuilder.push({ type: ItemType.Separator });
    }

    itemsBuilder.push({
      type: ItemType.Button,
      content: 'Delete',
      onClick: deleteElement,
    });

    return itemsBuilder;
  }, [
    deleteElement,
    renameElement,
    duplicateElement,
    downloadElement,
    downloadDataFile,
    type,
  ]);

  return (
    <PortalMenuBackground>
      <MenuContent items={items} isMenuOpen triggerCloseMenu={triggerClose} />
    </PortalMenuBackground>
  );
};

export default ProjectItemMenu;
