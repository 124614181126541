import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import blockTypeNameToInstanceDefaults from 'app/blockClassNameToInstanceDefaults';
import type { Coordinate } from 'app/common_types/Coordinate';
import { MouseActions } from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { HoverEntityType } from 'app/common_types/SegmentTypes';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { modelActions } from 'app/slices/modelSlice';
import { rcMenuActions } from 'app/slices/rcMenuSlice';
import { submodelsActions } from 'app/slices/submodelsSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import {
  snapCoordinateToGrid,
  snapNumberToGrid,
} from 'app/utils/modelDataUtils';
import { renderConstants } from 'app/utils/renderConstants';
import deepmerge from 'deepmerge';
import { autoInsertNodesIntoLinks } from '../autoInsertNodesIntoLinks';
import { convertZoomedScreenToWorldCoordinates } from '../convertScreenToWorldCoordinates';
import { RendererState } from '../modelRenderer';
import { getHoveringEntity } from '../mouseInput';
import { clickModifierConfig } from '../shortcutKeyConfig';
import { transitionMouseState } from '../transitionMouseState';
import { clickLinkDrawing } from './clickLinkDrawing';
import { drawNewLinkFromPort } from './drawNewLinkFromPort';
import { isEntityInteractable } from './isEntityInteractable';
import {
  leaveDrawingLinkHangingHandler,
  setDrawingLinkHangPoint,
} from './leaveLinkHangingHandler';
import { tapFakeSegmentThunk, tapNormalSegment } from './linkTapping';

export const mouseInputClick = (
  rs: RendererState,
  zoomedClickCoord: Coordinate,
  keys: { [k: string]: boolean },
): void => {
  const worldCursor = convertZoomedScreenToWorldCoordinates(
    rs.camera,
    zoomedClickCoord,
  );

  rs.dispatch(rcMenuActions.close());

  // Ignore our click event for certain state transitions
  if (
    rs.mouseState.state === MouseActions.MakingSelection ||
    rs.mouseState.state === MouseActions.DefiningAnnotationBox
  ) {
    const moved =
      rs.mouseState.rawScreenCursorStartX !== rs.screenCursorRaw.x ||
      rs.mouseState.rawScreenCursorStartX !== rs.screenCursorRaw.x;

    // We want to let this fall through to the normal click event
    // if the cursor didn't move, because that's just
    // a slow "normal" click.
    // If the user is slow to release the click,
    // our "mouse held down" event is triggered.
    // So, we want to basically pretend like we never entered the
    // "MakingSelection" state, because the user never moved the cursor.
    if (moved) {
      if (rs.mouseState.state === MouseActions.DefiningAnnotationBox) {
        const worldStart = convertZoomedScreenToWorldCoordinates(rs.camera, {
          x: rs.mouseState.rawScreenCursorStartX / rs.zoom,
          y: rs.mouseState.rawScreenCursorStartY / rs.zoom,
        });
        const startGridX = Math.round(worldStart.x / renderConstants.GRID_SIZE);
        const startGridY = Math.round(worldStart.y / renderConstants.GRID_SIZE);
        const cursorX = Math.round(worldCursor.x / renderConstants.GRID_SIZE);
        const cursorY = Math.round(worldCursor.y / renderConstants.GRID_SIZE);

        const gridWidth = Math.abs(cursorX - startGridX);
        const gridHeight = Math.abs(cursorY - startGridY);

        const x =
          Math.round(
            Math.min(worldStart.x, worldCursor.x) / renderConstants.GRID_SIZE,
          ) * renderConstants.GRID_SIZE;
        const y =
          Math.round(
            Math.min(worldStart.y, worldCursor.y) / renderConstants.GRID_SIZE,
          ) * renderConstants.GRID_SIZE;

        rs.dispatch(
          modelActions.addNewAnnotation({
            x,
            y,
            gridWidth,
            gridHeight,
          }),
        );
      }

      transitionMouseState(rs, { state: MouseActions.Idle });

      return;
    }

    transitionMouseState(rs, { state: MouseActions.Idle });
  } else if (rs.mouseState.state === MouseActions.DragDropLibraryBlock) {
    const baseNodeData: NodeInstance = blockTypeNameToInstanceDefaults(
      rs.mouseState.blockClassName,
      undefined,
      rs.mouseState.referenceSubmodel?.id,
    );
    const overrideProps = rs.mouseState.overridePropDefaults;
    const nodeData: NodeInstance = {
      ...baseNodeData,
      ...overrideProps,
      parameters: overrideProps?.parameters
        ? deepmerge(baseNodeData.parameters, overrideProps.parameters)
        : baseNodeData.parameters,
    };

    nodeData.uiprops = {
      ...nodeData.uiprops,
      ...snapCoordinateToGrid({
        x: worldCursor.x - rs.mouseState.cursorOffset.x,
        y: worldCursor.y - rs.mouseState.cursorOffset.y,
      }),
    };

    const referenceSubmodelIdToSubmodel: Record<string, SubmodelInfoLiteUI> = rs
      .mouseState.referenceSubmodel
      ? {
          [rs.mouseState.referenceSubmodel.id]: rs.mouseState.referenceSubmodel,
        }
      : {};

    // If we only have the SubmodelInfoLiteUI
    // (and not the SubmodelInfoUI that we need to determine the ports)
    // then we will need to reload the model submodels
    // to get the port information in order to fixup the ports for this submodel instance.
    // Wait to request the new model submodels until after the model is updated
    // to contain this new submodel instance.
    if (
      rs.mouseState.referenceSubmodel &&
      !(rs.mouseState.referenceSubmodel as SubmodelInfoUI).portDefinitionsInputs
    ) {
      rs.dispatch(
        submodelsActions.requestLoadSubmodelInfos([
          {
            submodel_uuid: rs.mouseState.referenceSubmodel.id,
            version: VersionTagValues.LATEST_VERSION,
          },
        ]),
      );
    }

    rs.dispatch(
      modelActions.addPremadeEntitiesToModel({
        nodes: [nodeData],
        links: [],
        referenceSubmodelIdToSubmodel,
      }),
    );

    rs.dispatch(
      modelActions.setSelections({
        selectionParentPath: getCurrentModelRef().submodelPath,
        selectedBlockIds: [nodeData.uuid],
        selectedLinkIds: [],
        selectedAnnotationIds: [],
      }),
    );

    autoInsertNodesIntoLinks(rs, [nodeData]);

    transitionMouseState(rs, { state: MouseActions.Idle });
    return;
  } else if (
    rs.mouseState.state === MouseActions.DraggingSelected ||
    rs.mouseState.state === MouseActions.DraggingLinkSegment ||
    rs.mouseState.state === MouseActions.ResizeNodeManually ||
    rs.mouseState.state === MouseActions.ResizeAnnotationManually ||
    rs.mouseState.state === MouseActions.Panning
  ) {
    transitionMouseState(rs, { state: MouseActions.Idle });
    return;
  }

  // we don't use "rs.hoveringEntity" here
  // because this event uses the mouse coordinates
  // at the exact time of the click.
  // this event fires at some point after that click,
  // so we need to click where the mouse "previously was"
  const hoverEnt = getHoveringEntity(
    rs.mouseState,
    worldCursor,
    rs.camera,
    rs.refs.current.nodes,
    rs.refs.current.links,
    rs.refs.current.annotations,
    rs.refs.current.linksIndexLUT,
    rs.refs.current.visualizerPrefs,
    rs.linksRenderFrameData,
    getCurrentModelRef().submodelPath,
  );

  const drawingLink =
    rs.mouseState.state === MouseActions.DrawingLinkFromStart ||
    rs.mouseState.state === MouseActions.DrawingLinkFromEnd;

  if (!hoverEnt && !drawingLink) {
    if (rs.mouseState.state == MouseActions.Idle) {
      rs.dispatch(
        uiFlagsActions.setUIFlag({
          showingCommandPalette: false,
        }),
      );
    }

    rs.dispatch(
      modelActions.setSelections({
        selectionParentPath: getCurrentModelRef().submodelPath,
      }),
    );
  }

  if (drawingLink) {
    if (rs.mouseState.draggingMode) {
      if (
        !hoverEnt ||
        (hoverEnt.entityType !== HoverEntityType.Link &&
          hoverEnt.entityType !== HoverEntityType.FakeLinkSegment &&
          hoverEnt.entityType !== HoverEntityType.TapPoint &&
          hoverEnt.entityType !== HoverEntityType.Port &&
          hoverEnt.entityType !== HoverEntityType.HangingEndPoint &&
          hoverEnt.entityType !== HoverEntityType.HangingStartPoint)
      ) {
        leaveDrawingLinkHangingHandler(rs, worldCursor);
      }
    } else if (!hoverEnt) {
      clickLinkDrawing(rs, worldCursor);
    }
  }

  if (hoverEnt) {
    if (!isEntityInteractable(rs, hoverEnt)) {
      return;
    }

    const addSelect =
      keys[clickModifierConfig.selectMultiple] ||
      keys[clickModifierConfig.selectMultipleMacOS];

    const dispatchMultipleLinkSelect = (linkUuid: string) => {
      const linkSelected = rs.refs.current.selectedLinkIds.includes(linkUuid);

      const selectionAction = addSelect
        ? linkSelected
          ? (id: string) => modelActions.unselectLink({ linkUuid: id })
          : (id: string) =>
              modelActions.selectAdditionalLinks({
                parentPath: getCurrentModelRef().submodelPath,
                linkIds: [id],
              })
        : (id: string) =>
            modelActions.setSelections({
              selectionParentPath: getCurrentModelRef().submodelPath,
              selectedBlockIds: [],
              selectedLinkIds: [id],
              selectedAnnotationIds: [],
            });

      rs.dispatch(selectionAction(linkUuid));
    };

    switch (hoverEnt.entityType) {
      case HoverEntityType.Port:
        if (
          rs.mouseState.state === MouseActions.DrawingLinkFromStart ||
          rs.mouseState.state === MouseActions.DrawingLinkFromEnd
        ) {
          const { linkUuid } = rs.mouseState;
          // TODO: lines uuid -> index LUT to avoid so many linear searches
          const link = rs.refs.current.links.find((l) => l.uuid === linkUuid);

          if (!link) return;

          const finalSegmentIndex =
            rs.mouseState.state === MouseActions.DrawingLinkFromEnd
              ? link.uiprops.segments.length - 1
              : 0;
          const finalSegment = link.uiprops.segments[finalSegmentIndex] || {};
          const finalSegmentDirection = finalSegment.segment_direction;
          const firstSegment = link.uiprops.segments[0] || {};
          const firstSegmentDirection = firstSegment.segment_direction;

          if (
            rs.mouseState.state === MouseActions.DrawingLinkFromEnd &&
            hoverEnt.port.side === PortSide.Input
          ) {
            if (finalSegmentDirection === 'horiz') {
              rs.dispatch(
                modelActions.addSegmentsToLink({
                  linkUuid: link.uuid,
                  segmentsData: [
                    {
                      segment_direction: 'vert',
                      coordinate: snapNumberToGrid(worldCursor.x),
                    },
                  ],
                  prepend: false,
                }),
              );
            }

            // we do this to avoid a "jumping glitch" where the previous hang-point
            // displays for a frame or 2
            setDrawingLinkHangPoint(rs, worldCursor);

            rs.dispatch(
              modelActions.connectLinkToNode({
                parentPath: getCurrentModelRef().submodelPath,
                linkUuid,
                linkPayload: {
                  destination: {
                    node: hoverEnt.port.blockUuid,
                    port: hoverEnt.port.portId,
                  },
                  source: link.src,
                },
                connectedPortSide: PortSide.Input,
              }),
            );

            rs.dispatch(modelActions.simplifyLinkSegments({ linkUuid }));

            // we specifically don't use transitionMouseState here
            // because we don't want our link to get deleted
            rs.mouseState = { state: MouseActions.Idle };
          } else if (
            rs.mouseState.state === MouseActions.DrawingLinkFromStart &&
            hoverEnt.port.side === PortSide.Output
          ) {
            if (firstSegmentDirection === 'horiz') {
              rs.dispatch(
                modelActions.addSegmentsToLink({
                  linkUuid: link.uuid,
                  segmentsData: [
                    {
                      segment_direction: 'vert',
                      coordinate: snapNumberToGrid(worldCursor.x),
                    },
                  ],
                  prepend: true,
                }),
              );
            }

            // we do this to avoid a "jumping glitch" where the previous hang-point
            // displays for a frame or 2
            setDrawingLinkHangPoint(rs, worldCursor);

            rs.dispatch(
              modelActions.connectLinkToNode({
                parentPath: getCurrentModelRef().submodelPath,
                linkUuid,
                linkPayload: {
                  source: {
                    node: hoverEnt.port.blockUuid,
                    port: hoverEnt.port.portId,
                  },
                  destination: link.dst,
                },
                connectedPortSide: PortSide.Output,
              }),
            );

            rs.dispatch(modelActions.simplifyLinkSegments({ linkUuid }));

            // we specifically don't use transitionMouseState here
            // because we don't want our link to get deleted
            rs.mouseState = { state: MouseActions.Idle };
          }
        } else {
          const nodeID = hoverEnt.port.blockUuid;
          const connectedPorts = rs.refs.current.connectedPortLUT[nodeID] || [];
          const portIsConnected = Boolean(
            connectedPorts.find(
              (connPort) =>
                connPort.side === hoverEnt.port.side &&
                connPort.portId === hoverEnt.port.portId,
            ),
          );

          if (!portIsConnected && rs.refs.current.canEditModel) {
            drawNewLinkFromPort(rs, hoverEnt.port);
          }
        }

        break;
      case HoverEntityType.Node:
        const hoveringNodeUuid = hoverEnt.block.uuid;

        if (!drawingLink) {
          if (
            rs.refs.current.canEditModel &&
            keys[clickModifierConfig.quickConnect] &&
            rs.refs.current.selectedNodeIds.length === 1 &&
            rs.refs.current.selectedNodeIds[0] !== hoveringNodeUuid
          ) {
            const selectedBlockUuid = rs.refs.current.selectedNodeIds[0];
            const srcConnPorts =
              rs.refs.current.connectedPortLUT[selectedBlockUuid];
            const destConnPorts =
              rs.refs.current.connectedPortLUT[hoveringNodeUuid];

            const nodeIndexLUT = rs.refs.current.nodesIndexLUT;
            const sourceNode =
              rs.refs.current.nodes[nodeIndexLUT[selectedBlockUuid]];
            const sourceNodeOutputPortIDs: number[] = [];
            const destNode =
              rs.refs.current.nodes[nodeIndexLUT[hoveringNodeUuid]];
            const destNodeInputPortIDs: number[] = [];

            for (let i = 0; i < sourceNode.outputs.length; i++) {
              const portFinder = (port: { side: PortSide; portId: number }) =>
                port.side === PortSide.Output && port.portId === i;

              if (!srcConnPorts || !srcConnPorts.some(portFinder)) {
                sourceNodeOutputPortIDs.push(i);
              }
            }

            for (let i = 0; i < destNode.inputs.length; i++) {
              const portFinder = (port: { side: PortSide; portId: number }) =>
                port.side === PortSide.Input && port.portId === i;

              if (!destConnPorts || !destConnPorts.some(portFinder)) {
                destNodeInputPortIDs.push(i);
              }
            }

            rs.dispatch(
              modelActions.connectNodesPorts({
                parentPath: getCurrentModelRef().submodelPath,
                sourceNodeUuid: selectedBlockUuid,
                destNodeUuid: hoveringNodeUuid,
                sourceNodeOutputPortIDs,
                destNodeInputPortIDs,
              }),
            );

            rs.dispatch(
              modelActions.setSelections({
                selectionParentPath: getCurrentModelRef().submodelPath,
                selectedBlockIds: [hoveringNodeUuid],
                selectedLinkIds: [],
                selectedAnnotationIds: [],
              }),
            );
          } else {
            const addSelect =
              keys[clickModifierConfig.selectMultiple] ||
              keys[clickModifierConfig.selectMultipleMacOS];
            const nodeSelected =
              rs.refs.current.selectedNodeIds.includes(hoveringNodeUuid);

            const selectionAction = addSelect
              ? nodeSelected
                ? (id: string) =>
                    modelActions.unselectNode({
                      nodeUuid: id,
                    })
                : (id: string) =>
                    modelActions.selectAdditionalNodes({
                      parentPath: getCurrentModelRef().submodelPath,
                      blockIds: [id],
                    })
              : (id: string) =>
                  modelActions.setSelections({
                    selectionParentPath: getCurrentModelRef().submodelPath,
                    selectedBlockIds: [id],
                    selectedLinkIds: [],
                    selectedAnnotationIds: [],
                  });

            rs.dispatch(selectionAction(hoveringNodeUuid));
          }
        }
        break;
      case HoverEntityType.Link:
        if (drawingLink || keys[clickModifierConfig.tap]) {
          tapNormalSegment(rs, hoverEnt.linkUuid, hoverEnt.segmentId, {
            x: snapNumberToGrid(worldCursor.x),
            y: snapNumberToGrid(worldCursor.y),
          });
        } else if (!drawingLink) {
          dispatchMultipleLinkSelect(hoverEnt.linkUuid);
        }
        break;
      case HoverEntityType.FakeLinkSegment:
        if (drawingLink || keys[clickModifierConfig.tap]) {
          rs.dispatch(
            tapFakeSegmentThunk({
              rs,
              hoveringEntity: hoverEnt,
              worldCursor,
            }),
          );
        } else if (!drawingLink) {
          dispatchMultipleLinkSelect(hoverEnt.linkUuid);
        }
        break;
      case HoverEntityType.TapPoint:
        if (drawingLink || keys[clickModifierConfig.tap]) {
          tapNormalSegment(
            rs,
            hoverEnt.tappedLinkUuid,
            hoverEnt.tappedSegmentId,
            {
              x: snapNumberToGrid(worldCursor.x),
              y: snapNumberToGrid(worldCursor.y),
            },
          );
        } else if (!drawingLink) {
          dispatchMultipleLinkSelect(hoverEnt.linkUuid);
        }
        break;
      case HoverEntityType.HangingEndPoint:
        if (drawingLink) {
          if (
            rs.mouseState.state === MouseActions.DrawingLinkFromStart &&
            rs.mouseState.linkUuid !== hoverEnt.linkUuid
          ) {
            rs.dispatch(
              modelActions.connectTwoLinks({
                inputIconSideLinkUuid: hoverEnt.linkUuid,
                outputIconSideLinkUuid: rs.mouseState.linkUuid,
                atCoordinate: {
                  x: snapNumberToGrid(worldCursor.x),
                  y: snapNumberToGrid(worldCursor.y),
                },
              }),
            );

            // we specifically don't use transitionMouseState here
            // because we don't want our link to get deleted
            rs.mouseState = { state: MouseActions.Idle };
          } else {
            break;
          }
        } else {
          transitionMouseState(rs, {
            state: MouseActions.DrawingLinkFromEnd,
            linkUuid: hoverEnt.linkUuid,
          });
        }
        break;
      case HoverEntityType.HangingStartPoint:
        if (drawingLink) {
          if (rs.mouseState.state === MouseActions.DrawingLinkFromEnd) {
            rs.dispatch(
              modelActions.connectTwoLinks({
                inputIconSideLinkUuid: rs.mouseState.linkUuid,
                outputIconSideLinkUuid: hoverEnt.linkUuid,
                atCoordinate: {
                  x: snapNumberToGrid(worldCursor.x),
                  y: snapNumberToGrid(worldCursor.y),
                },
              }),
            );

            // we specifically don't use transitionMouseState here
            // because we don't want our link to get deleted
            rs.mouseState = { state: MouseActions.Idle };
          } else {
            break;
          }
        } else {
          transitionMouseState(rs, {
            state: MouseActions.DrawingLinkFromStart,
            linkUuid: hoverEnt.linkUuid,
          });
        }
        break;
      case HoverEntityType.SignalPlotter:
        const nodeId = hoverEnt.block.uuid;
        const { toggleAllBlockPortsInChart } = rs.refs.current.visualizerPrefs;
        toggleAllBlockPortsInChart({
          nodeId,
          parentPath: getCurrentModelRef().submodelPath,
        });
        break;

      case HoverEntityType.Annotation: {
        const hoveringAnnotationUuid = hoverEnt.uuid;
        const addSelect =
          keys[clickModifierConfig.selectMultiple] ||
          keys[clickModifierConfig.selectMultipleMacOS];
        const annoSelected = rs.refs.current.selectedAnnotationIds.includes(
          hoveringAnnotationUuid,
        );

        const selectionAction = addSelect
          ? annoSelected
            ? (id: string) =>
                modelActions.unselectAnnotation({
                  uuid: id,
                })
            : (id: string) =>
                modelActions.selectAdditionalAnnotations({
                  parentPath: getCurrentModelRef().submodelPath,
                  annotationIds: [id],
                })
          : (id: string) =>
              modelActions.setSelections({
                selectionParentPath: getCurrentModelRef().submodelPath,
                selectedBlockIds: [],
                selectedLinkIds: [],
                selectedAnnotationIds: [id],
              });

        rs.dispatch(selectionAction(hoveringAnnotationUuid));

        break;
      }
    }
  }
};
