import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { useDrop } from 'react-dnd';
import {
  CellType,
  DataExplorerDragType,
  SignalDragItem,
  SignalDropArea,
} from 'ui/dataExplorer/dataExplorerTypes';
import {
  HideableCellDropZoneGrid,
  SignalDropZoneBase,
} from 'ui/dataExplorer/drag/DropZoneCommon';

type Props = {
  targetCellId: string;
};

const SignalDragDropZoneLeft = ({ targetCellId }: Props) => {
  const dispatch = useAppDispatch();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: DataExplorerDragType.SignalTree,
    drop: (item: SignalDragItem) => {
      dispatch(
        dataExplorerActions.addTracesToNewCellAtOffset({
          targetCellId,
          offset: 0,
          traceSpecs: item.traceSpecs,
        }),
      );
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <SignalDropZoneBase ref={drop} area={SignalDropArea.Left} isOver={isOver} />
  );
};

const SignalDragDropZoneRight = ({ targetCellId }: Props) => {
  const dispatch = useAppDispatch();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: DataExplorerDragType.SignalTree,
    drop: (item: SignalDragItem) => {
      dispatch(
        dataExplorerActions.addTracesToNewCellAtOffset({
          targetCellId,
          offset: 1,
          traceSpecs: item.traceSpecs,
        }),
      );
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <SignalDropZoneBase
      ref={drop}
      area={SignalDropArea.Right}
      isOver={isOver}
    />
  );
};

const SignalDragDropZoneSelf = ({ targetCellId }: Props) => {
  const dispatch = useAppDispatch();
  const existingTraces = useAppSelector((state) => {
    const traceIds =
      state.dataExplorer.idToPlotCell[targetCellId]?.traceIds || [];
    return traceIds.map((traceId) => state.dataExplorer.idToTrace[traceId]);
  });

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: DataExplorerDragType.SignalTree,
      canDrop(item, monitor) {
        const sourceTraces = item.traceSpecs;
        return !sourceTraces.every((sourceTrace) =>
          existingTraces.some(
            (existingTrace) =>
              existingTrace.simulationId === sourceTrace.simulationId &&
              existingTrace.tracePath === sourceTrace.tracePath,
          ),
        );
      },
      drop: (item: SignalDragItem) => {
        dispatch(
          dataExplorerActions.addTracesToPlotCell({
            targetCellId,
            traceSpecs: item.traceSpecs,
          }),
        );
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [existingTraces],
  );

  return (
    <SignalDropZoneBase
      ref={drop}
      area={SignalDropArea.Self}
      isOver={isOver && canDrop}
    />
  );
};

type DropZonesProps = {
  targetCellId: string;
  cellType: CellType;
};

/**
 * Signal drop zones that represent cells within the target cell's row.
 */
const HideableSignalDragCellDropZones = ({
  targetCellId,
  cellType,
}: DropZonesProps) => {
  const [{ isDraggingSignal }] = useDrop(() => ({
    accept: DataExplorerDragType.SignalTree,
    collect: (monitor) => ({
      isDraggingSignal: !!monitor.canDrop(),
    }),
  }));

  return (
    <HideableCellDropZoneGrid show={isDraggingSignal}>
      <SignalDragDropZoneLeft targetCellId={targetCellId} />
      <SignalDragDropZoneRight targetCellId={targetCellId} />
      {cellType === 'plot' && (
        <SignalDragDropZoneSelf targetCellId={targetCellId} />
      )}
    </HideableCellDropZoneGrid>
  );
};

export default HideableSignalDragCellDropZones;
