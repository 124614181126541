import { useSignalTypeData } from 'app/api/useSignalTypeData';
import React from 'react';

interface Props {
  modelId: string;
  correlationId: string;
  simulationId: string;
}

export const SignalTypePoller: React.FC<Props> = ({
  modelId,
  correlationId,
  simulationId,
}) => {
  useSignalTypeData(modelId, correlationId, simulationId);

  return null;
};
