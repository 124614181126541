import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useAppSelector } from 'app/hooks';
import { OpenAiModels } from 'app/openai';
import { ReactElement } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Checkbox } from 'ui/common/Checkbox';
import { BackArrow } from 'ui/common/Icons/Standard';
import Tooltip from 'ui/common/Tooltip/Tooltip';
import { CHAT_WIDTH, INPUT_BAR_HEIGHT } from './Sizings';

// This element is used to hide the "show advanced options" checkbox.
// The checkbox is useful for devs testing but should be hidden for demos
// and external users.
const ShowOnHover = styled.div<{ shouldShow?: boolean }>`
  opacity: ${({ shouldShow }) => (shouldShow ? '1' : '0')};
  transition: opacity 0.2s ease-in-out;

  :hover {
    opacity: 1;
  }
`;

const ModelSelector = styled.select`
  flex: 0;
  appearance: menulist;
  color: ${({ theme }) => theme.colors.grey[50]};
  background-color: ${({ theme }) => theme.colors.grey[5]};
  outline: none;
  border: none;
`;

const TemperatureSelector = styled.input`
  flex: 0;
  width: 50px;
  margin-right: ${({ theme }) => theme.spacing.normal};
  align-self: center;
  color: ${({ theme }) => theme.colors.grey[30]};
  border: none;
  text-align: right;
  appearance: textfield;
  background-color: ${({ theme }) => theme.colors.grey[5]};
`;

const BelowUserInputToolbarDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${INPUT_BAR_HEIGHT}px;
  width: ${CHAT_WIDTH}px;
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-left: ${({ theme }) => theme.spacing.xlarge};
  padding-right: ${({ theme }) => theme.spacing.xlarge};
  background-color: ${({ theme }) => theme.colors.grey[5]};
`;

// This should be a default component (with left/right box option)
const CheckboxWithLabel = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing.normal};
  align-items: start;

  > * {
    margin-right: ${({ theme }) => theme.spacing.small};
  }
`;

const Spacer = styled.div`
  flex: 100;
`;

interface BelowUserInputToolbarProps {
  showAdvancedOptions: boolean;
  setShowAdvancedOptions: (showAdvancedOptions: boolean) => void;
  aiModelId: string;
  setAiModelId: (aiModelId: string) => void;
  temperature: number;
  setTemperature: (temperature: number) => void;
  restartConversationOnClick: () => void;
}

const BelowUserInputToolbar = ({
  showAdvancedOptions,
  setShowAdvancedOptions,
  aiModelId,
  setAiModelId,
  temperature,
  setTemperature,
  restartConversationOnClick,
}: BelowUserInputToolbarProps): ReactElement => {
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );
  return (
    <BelowUserInputToolbarDiv>
      {(showAdvancedOptions && (
        <>
          <Spacer />
          <ModelSelector
            onChange={(e) => {
              setAiModelId(e.target.value);
            }}
            value={aiModelId}>
            {OpenAiModels.map((model) => (
              <option key={model.id} value={model.id}>
                {model.name}
              </option>
            ))}
          </ModelSelector>
          <TemperatureSelector
            type="number"
            min="0"
            max="100"
            step="1"
            value={temperature}
            onChange={(e) => setTemperature(parseFloat(e.target.value))}
            onWheel={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.deltaY < 0) {
                setTemperature(Math.floor(Math.min(100, temperature + 1)));
              } else {
                setTemperature(Math.floor(Math.max(0, temperature - 1)));
              }
            }}
          />
        </>
      )) || <Spacer />}
      {developerModeEnabled ? (
        <Tooltip
          contentText={
            showAdvancedOptions
              ? t`Hide advanced options`
              : t`Show advanced options`
          }>
          <Checkbox
            value={showAdvancedOptions}
            onChange={() => setShowAdvancedOptions(!showAdvancedOptions)}
            testId="show-advanced-options-checkbox"
          />
        </Tooltip>
      ) : null}
      <Button
        variant={ButtonVariants.SmallTertiary}
        Icon={BackArrow}
        onClick={restartConversationOnClick}>
        {t`Restart the conversation`}
      </Button>
    </BelowUserInputToolbarDiv>
  );
};

export default BelowUserInputToolbar;
