import blockTypeNameToInstanceDefaults from 'app/blockClassNameToInstanceDefaults';
import type { Coordinate } from 'app/common_types/Coordinate';
import { modelActions } from 'app/slices/modelSlice';
import { AppDispatch } from 'app/store';
import hotkeys from 'hotkeys-js';
import * as NVG from 'nanovg-js';
import { MutableRefObject } from 'react';
import {
  RendererRefsType,
  TransformFunc,
} from 'ui/modelEditor/ModelRendererWrapper';
import { drawScene } from 'ui/modelRendererInternals/drawScene';
import {
  getHoveringEntity,
  mouseInput,
} from 'ui/modelRendererInternals/mouseInput';

import { MAXIMUM_ZOOM, MINIMUM_ZOOM } from 'app/slices/cameraSlice';
import { userPreferencesActions } from 'app/slices/userPreferencesSlice';
import { FPS60_MILLIS } from 'app/utils/GeneralConstants';
import { OpSys, detectedOS } from 'util/detectOS';
import { onBrowserFocusChange } from 'util/onBrowserFocusChange';
import { pythagoreanDistance } from 'util/pythagoreanDistance';

import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import {
  ClickStates,
  HoverEntity,
  MouseActions,
  MouseClickState,
  MouseState,
} from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { HoverEntityType } from 'app/common_types/SegmentTypes';
import { SubmodelInstance } from 'app/generated_types/SimulationModel';
import { nodeTypeIsCode, nodeTypeIsSubdiagram } from 'app/helpers';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { LinkRenderData, linkToRenderData } from 'app/utils/linkToRenderData';
import { updateSubmodelInstanceForReferenceChanges } from 'app/utils/modelSubmodelFixupUtils';
import { renderConstants } from 'app/utils/renderConstants';
import { lineIntersect90Deg } from 'util/lineIntersectPoint';
import { blockIconIDsList } from './blockIconIDsList';
import { mouseInputClick } from './clickHandlers/mouseInputClick';
import { mouseInputClickHold } from './clickHandlers/mouseInputClickHold';
import { mouseInputDoubleClick } from './clickHandlers/mouseInputDoubleClick';
import { mouseInputMiddleClick } from './clickHandlers/mouseInputMiddleClick';
import { mouseInputMiddleClickHold } from './clickHandlers/mouseInputMiddleClickHold';
import { mouseInputRightClick } from './clickHandlers/mouseInputRightClick';
import { convertZoomedScreenToWorldCoordinates } from './convertScreenToWorldCoordinates';
import { drawNode } from './drawNode';
import { getCursorVisualState } from './getCursorVisualState';
import { getVertexHitboxForIndex } from './getVertexHitboxForIndex';
import { PORT_BLOCK_YOFFSET, getVisualNodeHeight } from './getVisualNodeHeight';
import { getVisualNodeWidth } from './getVisualNodeWidth';
import {
  RasterLoadState,
  allocMemImageAndIntoStoreFromImageBuffer,
  deleteAllImagesFromMemAndStore,
  loadArrayBufferPromise,
  rasterMetaStore,
} from './rasterTextureStore';
import {
  SingleShortcutConfig,
  clickModifierConfig,
  scrollModifierConfig,
  shortcutsConfig,
} from './shortcutKeyConfig';
import { transitionMouseState } from './transitionMouseState';

const preloadedRastersPromises = [
  ...blockIconIDsList.map((id) => `renderer_icon_rasters/${id}`),
  'text_fader',
  'plotter_toggle_active',
  'plotter_toggle_inactive',
  'input_port',
  'input_port_trigger',
  'link_end_input_blank',
  'link_occlusion_v',
  'link_occlusion_h',
  'continuous_signal_label_icon',
  'discrete_signal_label_icon',
  'matrix_signal_type_icon',
  'scalar_signal_type_icon',
  'vector_signal_type_icon',
].reduce(
  (
    acc: { iconID: string; bufferPromise: Promise<ArrayBuffer | undefined> }[],
    iconID: string,
  ) => {
    const scales = [1, 2, 4];

    return [
      ...acc,
      ...scales.map((scale) => {
        const scaledIconID = `${iconID}_${scale}x`;
        rasterMetaStore[scaledIconID] = {
          loadState: RasterLoadState.Loading,
        };

        return {
          iconID: scaledIconID,
          bufferPromise: loadArrayBufferPromise(
            `${process.env.PUBLIC_URL}/assets/${scaledIconID}.png`,
          ),
        };
      }),
    ];
  },
  [],
);

let gl: WebGLRenderingContext | null = null;
const done = false;
let windowResizer = (_event: UIEvent) => {};

export type PortConnListType = Array<{
  fullyConnected: boolean;
  side: PortSide;
  portId: number;
  linkUuid: string;
}>;

export interface PortConnLUTType {
  [k: string]: PortConnListType;
}

export interface RendererState {
  camera: Coordinate;
  zoom: number;
  screenCursorRaw: Coordinate;
  screenCursorZoomed: Coordinate;
  clickState: MouseClickState;
  mouseState: MouseState;
  linksRenderFrameData: LinkRenderData[];
  linksRenderFrameDataIndexLUT: { [uuid: string]: number };
  linksOcclusionPointLUT: { [uuid: string]: Array<LinkIntersectionCoordinate> };
  refs: MutableRefObject<RendererRefsType>;
  setTransform: TransformFunc;
  dispatch: AppDispatch;
  hoveringEntity: HoverEntity | undefined;
  debugMode?: boolean;
}

// Mutation is required for performance of graphics relative to redux:
// eslint-disable-next-line import/no-mutable-exports
export let rendererState: RendererState | null = null;

let nvg: NVG.Context | null = null;

export const keysPressed: { [k: string]: boolean } = {};
const keysJust: { [k: string]: boolean } = {};

const onBlurOrFocus = () => {
  const keyKeys = Object.keys(keysPressed);
  for (let i = 0; i < keyKeys.length; i++) {
    keysPressed[keyKeys[i]] = false;
  }

  const justKeyKeys = Object.keys(keysJust);
  for (let i = 0; i < justKeyKeys.length; i++) {
    keysJust[justKeyKeys[i]] = false;
  }
};

onBrowserFocusChange(onBlurOrFocus, onBlurOrFocus);

function keyDown(event: KeyboardEvent): void {
  let keyName = event.code;

  if (keyName.indexOf('Arrow') === -1) {
    keyName = keyName.replace('Left', '').replace('Right', '');
  }

  keysPressed[keyName] = true;
  keysJust[keyName] = true;
}

function keyUp(event: KeyboardEvent): void {
  // the alt key seems to be problematic in getting stuck,
  // so we'll take an aggressive approach on it for now.
  if (!event.altKey) {
    keysPressed.Alt = false;
    keysJust.Alt = false;
  }

  let keyName = event.code;

  if (keyName.indexOf('Arrow') === -1) {
    keyName = keyName.replace('Left', '').replace('Right', '');
  }

  keysPressed[keyName] = false;

  // on macOS, it is impossible to get keyUp events for keys while "cmd" is pressed.
  // this is well-documented, and there is no way to get around it.
  // so, we broadly emulate keyups that happened during the "cmd" key being held
  // by just clearing every pressed key. this should have minimal side effects
  // because there is no real UX case for keeping other keys held
  // after cmd is released.
  if (keyName === 'Meta') {
    const pressedKeyNames = Object.keys(keysPressed);
    for (let i = 0; i < pressedKeyNames.length; i++) {
      keysPressed[pressedKeyNames[i]] = false;
    }
  }
}

let canvasBounds: { x: number; y: number };
const getCanvasBounds = (): { x: number; y: number } => {
  if (canvasBounds) return canvasBounds;

  if (!rendererState || !rendererState.refs.current.canvas) {
    return { x: 0, y: 0 };
  }

  if (rendererState.refs.current.canvas) {
    canvasBounds = rendererState.refs.current.canvas.getBoundingClientRect();
  }

  return canvasBounds;
};

const multiSelection = () => {
  if (!rendererState) return;

  if (rendererState.mouseState.state === MouseActions.MakingSelection) {
    const worldCursor = convertZoomedScreenToWorldCoordinates(
      rendererState.camera,
      rendererState.screenCursorZoomed,
    );

    const { rawScreenCursorStartX, rawScreenCursorStartY } =
      rendererState.mouseState;

    const worldStart = convertZoomedScreenToWorldCoordinates(
      rendererState.camera,
      {
        x: rawScreenCursorStartX / rendererState.zoom,
        y: rawScreenCursorStartY / rendererState.zoom,
      },
    );

    // faster than Math.min/max 4 times
    let startX;
    let startY;
    let endX;
    let endY = 0;
    if (worldCursor.x < worldStart.x) {
      startX = worldCursor.x;
      endX = worldStart.x;
    } else {
      startX = worldStart.x;
      endX = worldCursor.x;
    }
    if (worldCursor.y < worldStart.y) {
      startY = worldCursor.y;
      endY = worldStart.y;
    } else {
      startY = worldStart.y;
      endY = worldCursor.y;
    }

    const nodes = rendererState.refs.current.nodes;
    const annotations = rendererState.refs.current.annotations;
    const selectedBlockUuids = [];
    const selectedLinkUuids = [];
    const selectedAnnotationUuids = [];

    // Just doing a brute-force "AABB" collision to keep it simple for now.
    // This is applied for both blocks AND links during multi-select.
    // We don't have any collision optimization data structures right now,
    // so this is the simplest way to do this currently.
    // TODO: update this block when we have grid-based collision
    // for links + selection area.

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      const nodeHeight = getVisualNodeHeight(node);
      const nodeWidth = getVisualNodeWidth(node);
      const nodeIsIOPort =
        node.type === 'core.Inport' || node.type === 'core.Outport';
      const fixedNodeY = nodeIsIOPort
        ? node.uiprops.y + PORT_BLOCK_YOFFSET
        : node.uiprops.y;
      if (
        node.uiprops.x < endX &&
        node.uiprops.x + nodeWidth > startX &&
        fixedNodeY < endY &&
        fixedNodeY + nodeHeight > startY
      ) {
        selectedBlockUuids.push(node.uuid);
      }
    }

    for (let i = 0; i < annotations.length; i++) {
      const anno = annotations[i];
      const annoHeight = anno.grid_height * renderConstants.GRID_SIZE;
      const annoWidth = anno.grid_width * renderConstants.GRID_SIZE;
      if (
        anno.x < endX &&
        anno.x + annoWidth > startX &&
        anno.y < endY &&
        anno.y + annoHeight > startY
      ) {
        selectedAnnotationUuids.push(anno.uuid);
      }
    }

    // see above comment about brute-force AABB
    const linksRenderFrameData = rendererState.linksRenderFrameData;
    const hitLinksMap: { [k: string]: boolean } = {};

    for (let i = 0; i < linksRenderFrameData.length; i++) {
      const linkRenderData = linksRenderFrameData[i];

      if (hitLinksMap[linkRenderData.linkUuid]) continue;

      const { vertexData } = linkRenderData;

      // final vertex's hitbox does not exist
      // so we skip it (subtract 1 from length)
      for (let j = 0; j < vertexData.length - 1; j++) {
        if (hitLinksMap[linkRenderData.linkUuid]) break;

        const hitbox = getVertexHitboxForIndex(vertexData, j);

        if (
          hitbox.x1 < endX &&
          hitbox.x2 > startX &&
          hitbox.y1 < endY &&
          hitbox.y2 > startY
        ) {
          hitLinksMap[linkRenderData.linkUuid] = true;

          selectedLinkUuids.push(linkRenderData.linkUuid);
        }
      }
    }

    if (
      keysPressed[clickModifierConfig.selectMultiple] ||
      keysPressed[clickModifierConfig.selectMultipleMacOS]
    ) {
      rendererState.dispatch(
        modelActions.setSelections({
          selectionParentPath: getCurrentModelRef().submodelPath,
          selectedBlockIds: selectedBlockUuids,
          selectedLinkIds: selectedLinkUuids,
          selectedAnnotationIds: selectedAnnotationUuids,
        }),
      );
      // these two cases are the same
    } else {
      rendererState.dispatch(
        modelActions.setSelections({
          selectionParentPath: getCurrentModelRef().submodelPath,
          selectedBlockIds: selectedBlockUuids,
          selectedLinkIds: selectedLinkUuids,
          selectedAnnotationIds: selectedAnnotationUuids,
        }),
      );
    }
  }
};

let mousemoveRollingTimeout: number | null = null;
let mousemoveFinalTimeout: number | null = null;
const mouseMove = (event: MouseEvent): void => {
  if (!rendererState) return;

  const canvas = rendererState.refs.current.canvas;

  if (!canvas) return;

  const { x: canvasOffsetX, y: canvasOffsetY } = getCanvasBounds();
  const canvasScreenX = event.x - canvasOffsetX;
  const canvasScreenY = event.y - canvasOffsetY;

  if (
    canvasScreenX >= 0 &&
    canvasScreenX < canvas.offsetWidth &&
    canvasScreenY >= 0 &&
    canvasScreenY < canvas.offsetHeight
  ) {
    rendererState.screenCursorZoomed.x = canvasScreenX / rendererState.zoom;
    rendererState.screenCursorZoomed.y = canvasScreenY / rendererState.zoom;

    rendererState.screenCursorRaw.x = canvasScreenX;
    rendererState.screenCursorRaw.y = canvasScreenY;
  }

  if (
    rendererState.clickState.state === ClickStates.ClickHeld &&
    (rendererState.mouseState.state === MouseActions.Idle ||
      rendererState.mouseState.state === MouseActions.ReadyToDefineAnnotation)
  ) {
    const movedDistance = pythagoreanDistance(
      rendererState.clickState.rawCoord,
      rendererState.screenCursorRaw,
    );
    if (movedDistance > 2) {
      mouseInputClickHold(
        rendererState,
        rendererState.clickState.zoomedCoord,
        rendererState.clickState.rawCoord,
        keysPressed,
      );
    }
  }

  // This "interlocking" timeout pattern is to allow us to throttle certain actions
  // to a lower framerate if they are performance-intensive.
  // This is because the mouse events fire faster than our render loop.
  // We can avoid smashing the CPU with a bunch of stuff
  // that would otherwise cause frame drops for everything
  // due to slowing the app down.
  if (!mousemoveRollingTimeout) {
    mousemoveRollingTimeout = window.setTimeout(
      () => {
        mousemoveRollingTimeout = null;
        multiSelection();
      },
      // throttling the FPS to 30 for selection since it's a bit expensive right now
      // TODO: make multiSelection() more efficient (but don't remove this throttle)
      FPS60_MILLIS * 2,
    );
  } else {
    if (mousemoveFinalTimeout) clearTimeout(mousemoveFinalTimeout);
    mousemoveFinalTimeout = window.setTimeout(
      () => {
        multiSelection();
      },
      // totally ensure that this never calls until the end
      // by making it over 2x as slow
      FPS60_MILLIS * 4.5,
    );
  }
};

const doubleClickInterval = 135;
let mouseTimer = setTimeout(() => {}, 1);
let downCount = 0;
const mouseDown = (event: MouseEvent): void => {
  // the alt key seems to be problematic in getting stuck,
  // so we'll take an aggressive approach on it for now.
  if (!event.altKey) {
    keysPressed.Alt = false;
    keysJust.Alt = false;
  }

  if (!rendererState) return;

  const canvas = rendererState.refs.current.canvas;

  if (!canvas) return;

  const { x: canvasOffsetX, y: canvasOffsetY } = getCanvasBounds();
  const canvasScreenX = event.x - canvasOffsetX;
  const canvasScreenY = event.y - canvasOffsetY;

  if (event.target === canvas) {
    document.getSelection()?.removeAllRanges();

    const zoomedClickCoord = {
      x: canvasScreenX / rendererState.zoom,
      y: canvasScreenY / rendererState.zoom,
    };
    const rawClickCoord = {
      x: canvasScreenX,
      y: canvasScreenY,
    };

    // if middle-click
    if (event.button === 1) {
      mouseInputMiddleClickHold(rendererState, zoomedClickCoord);
      return;
    }
    if (event.button === 2) {
      mouseInputRightClick(rendererState, zoomedClickCoord, rawClickCoord);
      event.preventDefault();
      return;
    }

    clearTimeout(mouseTimer);
    mouseTimer = setTimeout(() => {
      downCount = 0;
      if (!rendererState) return;

      rendererState.clickState = {
        state: ClickStates.ClickHeld,
        zoomedCoord: zoomedClickCoord,
        rawCoord: rawClickCoord,
      };
    }, doubleClickInterval);

    if (downCount === 2) downCount = 1;
    else downCount++;
  }
};

const getShouldWaitForDoubleClick = (
  rs: RendererState,
  hoverEnt: HoverEntity | undefined,
) => {
  if (
    hoverEnt?.entityType === HoverEntityType.NodeName ||
    hoverEnt?.entityType === HoverEntityType.AnnotationText
  ) {
    return true;
  }

  if (!rs.mouseState.draggingMode) {
    // TODO: this should be defined kinda centrally within the double-click handler code
    // in mouseInputDoubleClick.ts
    if (
      hoverEnt?.entityType === HoverEntityType.Node &&
      (nodeTypeIsCode(hoverEnt.block.type) ||
        nodeTypeIsSubdiagram(hoverEnt.block.type) ||
        hoverEnt.block.type === 'core.ExperimentModel' ||
        hoverEnt.block.type === 'core.StateMachine')
    ) {
      return true;
    }

    const isDrawingLink =
      rs.mouseState.state === MouseActions.DrawingLinkFromEnd ||
      rs.mouseState.state === MouseActions.DrawingLinkFromStart;
    const isIdle = rs.mouseState.state === MouseActions.Idle;

    if (isDrawingLink || isIdle) {
      if (
        hoverEnt?.entityType === HoverEntityType.Link ||
        hoverEnt?.entityType === HoverEntityType.FakeLinkSegment ||
        !hoverEnt
      ) {
        return true;
      }
    }

    if (isIdle && hoverEnt?.entityType === HoverEntityType.TapPoint) {
      return true;
    }
  }

  // model background double-click
  if (!hoverEnt) return true;

  return false;
};

const mouseUp = (event: MouseEvent): void => {
  if (!rendererState) return;

  const canvas = rendererState.refs.current.canvas;

  if (!canvas) return;

  if ((event.button === 0 || event.button === 1) && event.target === canvas) {
    const zoomedClickCoord = JSON.parse(
      JSON.stringify(rendererState.screenCursorZoomed),
    );
    const worldCursor = convertZoomedScreenToWorldCoordinates(
      rendererState.camera,
      rendererState.screenCursorZoomed,
    );

    // if middle-click
    if (event.button === 1) {
      mouseInputMiddleClick(rendererState);
      return;
    }
    rendererState.clickState = {
      state: ClickStates.Idle,
    };

    const hoverEnt = getHoveringEntity(
      rendererState.mouseState,
      worldCursor,
      rendererState.camera,
      rendererState.refs.current.nodes,
      rendererState.refs.current.links,
      rendererState.refs.current.annotations,
      rendererState.refs.current.linksIndexLUT,
      rendererState.refs.current.visualizerPrefs,
      rendererState.linksRenderFrameData,
      getCurrentModelRef().submodelPath,
    );

    const shouldWaitForDoubleClick = getShouldWaitForDoubleClick(
      rendererState,
      hoverEnt,
    );

    if (shouldWaitForDoubleClick) {
      if (downCount === 2) {
        downCount = 0;
        clearTimeout(mouseTimer);
        rendererState.clickState = {
          state: ClickStates.DoubleClick,
          ...zoomedClickCoord,
        };

        rendererState.dispatch(
          uiFlagsActions.setUIFlag({
            preDblConfirmClickCoordNeedsDeletion: true,
          }),
        );
        mouseInputDoubleClick(rendererState, zoomedClickCoord);
      } else {
        clearTimeout(mouseTimer);
        rendererState.mouseState.preDblConfirmClickWorldCoord = worldCursor;

        mouseTimer = setTimeout(() => {
          if (!rendererState) return;

          downCount = 0;

          rendererState.clickState = {
            state: ClickStates.Click,
            ...zoomedClickCoord,
          };

          rendererState.dispatch(
            uiFlagsActions.setUIFlag({
              preDblConfirmClickCoordNeedsDeletion: true,
            }),
          );
          mouseInputClick(rendererState, zoomedClickCoord, keysPressed);
        }, doubleClickInterval);
      }
    } else {
      clearTimeout(mouseTimer);
      downCount = 0;

      rendererState.clickState = {
        state: ClickStates.Click,
        ...zoomedClickCoord,
      };

      mouseInputClick(rendererState, zoomedClickCoord, keysPressed);
    }
  } else if (event.button === 0 && event.target !== canvas) {
    rendererState.clickState = {
      state: ClickStates.Idle,
    };

    transitionMouseState(rendererState, {
      state: MouseActions.Idle,
    });
  }
};
const contextMenuEvent = (event: MouseEvent): void => {
  if (!rendererState) return;

  const canvas = rendererState.refs.current.canvas;

  if (!canvas) return;

  if (event.target === canvas) {
    event.preventDefault();
  }
};

export const zoomAroundScreenAnchor = (
  zoomDelta: number,
  screenAnchor: Coordinate,
): { zoom: number; coord: Coordinate } => {
  if (!rendererState) {
    return { zoom: 1, coord: { x: 0, y: 0 } };
  }

  const { x: screenAnchorX, y: screenAnchorY } = screenAnchor;

  const nextZoom = Math.min(
    Math.max(rendererState.zoom + zoomDelta, MINIMUM_ZOOM),
    MAXIMUM_ZOOM,
  );

  const previousWorldAnchorX = screenAnchorX / rendererState.zoom;
  const previousWorldAnchorY = screenAnchorY / rendererState.zoom;

  const newWorldAnchorX = screenAnchorX / nextZoom;
  const newWorldAnchorY = screenAnchorY / nextZoom;

  const cameraAdjustX = newWorldAnchorX - previousWorldAnchorX;
  const cameraAdjustY = newWorldAnchorY - previousWorldAnchorY;

  rendererState.camera.x += cameraAdjustX;
  rendererState.camera.y += cameraAdjustY;

  rendererState.zoom = nextZoom;

  return {
    zoom: rendererState.zoom,
    coord: {
      x: rendererState.camera.x,
      y: rendererState.camera.y,
    },
  };
};

export const zoomAroundScreenCenter = (
  zoomDelta: number,
): { zoom: number; coord: Coordinate } => {
  if (!rendererState || !rendererState.refs.current.parent) {
    return { zoom: 1, coord: { x: 0, y: 0 } };
  }

  const screenCenterX = rendererState.refs.current.parent.clientWidth / 2;
  const screenCenterY = rendererState.refs.current.parent.clientHeight / 2;
  return zoomAroundScreenAnchor(zoomDelta, {
    x: screenCenterX,
    y: screenCenterY,
  });
};

export const setZoomAroundScreenCenter = (
  zoomLevel: number,
): { zoom: number; coord: Coordinate } => {
  if (!rendererState) {
    return { zoom: 1, coord: { x: 0, y: 0 } };
  }

  const setDelta = rendererState.zoom - zoomLevel;

  return zoomAroundScreenCenter(-setDelta);
};

export const resetZoomAroundScreenCenter = (): {
  zoom: number;
  coord: Coordinate;
} => {
  if (!rendererState) {
    return { zoom: 1, coord: { x: 0, y: 0 } };
  }

  return setZoomAroundScreenCenter(1);
};

const wheel = (event: WheelEvent) => {
  event.preventDefault();
  if (!rendererState) return;

  const macOS = detectedOS === OpSys.macOS;

  if (macOS && keysPressed[scrollModifierConfig.panVerticalMacOS]) {
    rendererState.camera.y -= event.deltaY / rendererState.zoom;
  } else if (keysPressed[scrollModifierConfig.panHorizontal]) {
    rendererState.camera.x -= event.deltaY / rendererState.zoom;
  } else if (event.ctrlKey) {
    const zoomAmount =
      (event.deltaY * 0.01) /
      (!macOS ? 8 / Math.min(1, rendererState.zoom) : 1);

    const nextZoom = Math.min(
      Math.max(rendererState.zoom - zoomAmount, MINIMUM_ZOOM),
      MAXIMUM_ZOOM,
    );

    const previousCursorX = event.offsetX / rendererState.zoom;
    const previousCursorY = event.offsetY / rendererState.zoom;

    const newCursorX = event.offsetX / nextZoom;
    const newCursorY = event.offsetY / nextZoom;

    const cameraAdjustX = newCursorX - previousCursorX;
    const cameraAdjustY = newCursorY - previousCursorY;

    rendererState.camera.x += cameraAdjustX;
    rendererState.camera.y += cameraAdjustY;

    rendererState.screenCursorZoomed.x = newCursorX;
    rendererState.screenCursorZoomed.y = newCursorY;

    rendererState.zoom = nextZoom;
  } else {
    rendererState.camera.x -= event.deltaX / rendererState.zoom;
    rendererState.camera.y -= event.deltaY / rendererState.zoom;
  }

  rendererState.setTransform({
    x: rendererState.camera.x,
    y: rendererState.camera.y,
    zoom: rendererState.zoom,
  });
};

export const externallySetRendererTransform = (
  x: number,
  y: number,
  zoom: number,
): void => {
  if (!rendererState) return;

  rendererState.camera.x = x;
  rendererState.camera.y = y;
  rendererState.zoom = zoom;
};

let fontArchivo;
const fontUrl = `${process.env.PUBLIC_URL}/assets/Archivo-Regular.ttf`;
async function loadFont(nvgContext: NVG.Context) {
  const loadArrayBuffer = async (url: string): Promise<ArrayBuffer> => {
    const response: Response = await fetch(url);
    return response.arrayBuffer();
  };
  fontArchivo = nvgContext.createFontMem(
    'archivo',
    new Uint8Array(await loadArrayBuffer(fontUrl)),
  );
  if (fontArchivo === -1) {
    console.error('Could not add font icons.\n');
    return -1;
  }
}

let backFramebuffer: NVG.NVGLUframebuffer | null = null;
let bgDotsFramebuffer: NVG.NVGLUframebuffer | null = null;

export const startNanovg = async (canvas: HTMLCanvasElement): Promise<any> => {
  await NVG.default();

  gl = canvas.getContext('webgl', {
    stencil: true,
    preserveDrawingBuffer: true,
  });
  nvg = NVG.createWebGL(
    gl,
    NVG.CreateFlags.ANTIALIAS | NVG.CreateFlags.STENCIL_STROKES,
  );

  if (gl) {
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);

    backFramebuffer = NVG.nvgluCreateFramebuffer(
      nvg.ctx,
      5000,
      5000,
      NVG.NVGimageFlags.PREMULTIPLIED,
    );
    bgDotsFramebuffer = NVG.nvgluCreateFramebuffer(
      nvg.ctx,
      64,
      64,
      NVG.NVGimageFlags.PREMULTIPLIED |
        NVG.ImageFlags.REPEATX |
        NVG.ImageFlags.REPEATY,
    );

    preloadedRastersPromises.forEach(({ bufferPromise, iconID }) =>
      bufferPromise.then((buffer) => {
        if (buffer && nvg) {
          allocMemImageAndIntoStoreFromImageBuffer(nvg, buffer, iconID, 4);
        }
      }),
    );
  }

  if (nvg === null) {
    console.error('Could not init nanovg.');
    return -1;
  }

  if ((await loadFont(nvg)) === -1) return -1;
};

export const endNanovg = async (): Promise<any> => {
  if (nvg) {
    deleteAllImagesFromMemAndStore(nvg);
    if (backFramebuffer) {
      NVG.nvgluDeleteFramebuffer(backFramebuffer);
      backFramebuffer = null;
    }
    if (bgDotsFramebuffer) {
      NVG.nvgluDeleteFramebuffer(bgDotsFramebuffer);
      bgDotsFramebuffer = null;
    }

    NVG.deleteWebGL(nvg);
  }

  nvg = null;
};

let tickRafId = window.requestAnimationFrame(() => {});
let startMillis = 0;
let fadeInLength = 200;

type LinkIntersectionCoordinate = Coordinate & {
  orientation: 'vertical' | 'horizontal';
};

const getTwoLinksIntersectionPoints = (
  linkRenderDataOne: LinkRenderData,
  linkRenderDataTwo: LinkRenderData,
): LinkIntersectionCoordinate[] => {
  const intersections: LinkIntersectionCoordinate[] = [];

  for (let i = 0; i < linkRenderDataOne.vertexData.length - 1; i++) {
    const segmentOneStart = linkRenderDataOne.vertexData[i];
    const segmentOneEnd = linkRenderDataOne.vertexData[i + 1];

    const orientation =
      segmentOneStart.coordinate[0] === segmentOneEnd.coordinate[0]
        ? 'vertical'
        : 'horizontal';

    for (let j = 0; j < linkRenderDataTwo.vertexData.length - 1; j++) {
      const segmentTwoStart = linkRenderDataTwo.vertexData[j];
      const segmentTwoEnd = linkRenderDataTwo.vertexData[j + 1];

      const intersectionPoint = lineIntersect90Deg(
        segmentOneStart.coordinate[0],
        segmentOneStart.coordinate[1],
        segmentOneEnd.coordinate[0],
        segmentOneEnd.coordinate[1],
        segmentTwoStart.coordinate[0],
        segmentTwoStart.coordinate[1],
        segmentTwoEnd.coordinate[0],
        segmentTwoEnd.coordinate[1],
      );

      if (intersectionPoint !== null)
        intersections.push({ ...intersectionPoint, orientation });
    }
  }

  return intersections;
};

const setLinksOcclusionPoints = (rs: RendererState) => {
  rs.linksOcclusionPointLUT = {};
  const renderFrameData: LinkRenderData[] = rs.linksRenderFrameData;

  for (let i = 0; i < renderFrameData.length - 1; i++) {
    const renderDataOne = renderFrameData[i];

    for (let j = i + 1; j < renderFrameData.length; j++) {
      const renderDataTwo = renderFrameData[j];

      // TODO: add aabb check on renderdata's bounding box

      const occlusionPoints = getTwoLinksIntersectionPoints(
        renderDataOne,
        renderDataTwo,
      );
      if (occlusionPoints.length > 0) {
        if (!rs.linksOcclusionPointLUT[renderDataOne.linkUuid]) {
          rs.linksOcclusionPointLUT[renderDataOne.linkUuid] = occlusionPoints;
        } else {
          for (let k = 0; k < occlusionPoints.length; k++) {
            rs.linksOcclusionPointLUT[renderDataOne.linkUuid].push(
              occlusionPoints[k],
            );
          }
        }
      }
    }
  }
};

const _tick = (time: number): void => {
  if (!rendererState || !nvg) {
    tickRafId = window.requestAnimationFrame(_tick);
    return;
  }
  let renderWidth = 0;
  let renderHeight = 0;
  let framebufWidth = 0;
  let framebufHeight = 0;

  const parent = rendererState.refs.current.parent;
  const canvas = rendererState.refs.current.canvas;

  if (parent) {
    renderWidth = parent.clientWidth;
    renderHeight = parent.clientHeight;

    if (canvas) {
      // NOTE: there is probably something rogue setting canvas width/height and causing it to "disappear",
      // so putting this here for now (and will consider removal
      // if we can diagnose the "disappearing" problem's source).
      // this won't affect performance as the value isn't actually changing every frame.
      canvas.width = parent.clientWidth * window.devicePixelRatio;
      canvas.height = parent.clientHeight * window.devicePixelRatio;
    }
  }

  if (gl) {
    framebufWidth = gl.drawingBufferWidth;
    framebufHeight = gl.drawingBufferHeight;

    gl.viewport(0, 0, framebufWidth, framebufHeight);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    gl.enable(gl.CULL_FACE);
    gl.disable(gl.DEPTH_TEST);
  }

  mouseInput(rendererState, rendererState.dispatch, rendererState.setTransform);

  if (canvas) {
    if (time - startMillis < fadeInLength) {
      canvas.style.opacity = `${(time - startMillis) / fadeInLength}`;
    } else {
      canvas.style.opacity = '1';
    }
  }

  const cursorVisualStyle = getCursorVisualState(rendererState);
  if (canvas && canvas.style.cursor !== cursorVisualStyle) {
    canvas.style.cursor = cursorVisualStyle;
  }

  // TODO: This is extremely temporary while we are developing a
  // more robust method for pre-calculating the link render data
  // that doesn't happen every frame.
  rendererState.linksRenderFrameData = [];
  rendererState.linksRenderFrameDataIndexLUT = {};

  const baseLevelLinks =
    rendererState.refs.current.linksRenderingDependencyTree.__no_dependency ||
    [];

  // this is performance-wise the fastest way to get a copy of this
  const iteratingLinkUUIDs: string[] = [];
  for (let i = 0; i < baseLevelLinks.length; i++) {
    iteratingLinkUUIDs.push(baseLevelLinks[i]);
  }

  let linkRenderIndex = 0;
  while (iteratingLinkUUIDs.length) {
    const linkUUID = iteratingLinkUUIDs.pop() || '';
    const realLinkIndex = rendererState.refs.current.linksIndexLUT[linkUUID];
    const link = rendererState.refs.current.links[realLinkIndex];

    if (!link) continue;

    const dependentLinkUUIDs =
      rendererState.refs.current.linksRenderingDependencyTree[linkUUID];

    if (dependentLinkUUIDs && dependentLinkUUIDs.length > 0) {
      for (let i = 0; i < dependentLinkUUIDs.length; i++) {
        iteratingLinkUUIDs.push(dependentLinkUUIDs[i]);
      }
    }

    rendererState.linksRenderFrameData.push(
      linkToRenderData(
        rendererState,
        link,
        rendererState.refs.current.nodes,
        rendererState.refs.current.nodesIndexLUT,
        rendererState.camera.x,
        rendererState.camera.y,
      ),
    );

    rendererState.linksRenderFrameDataIndexLUT[link.uuid] = linkRenderIndex;
    linkRenderIndex++;
  }

  setLinksOcclusionPoints(rendererState);

  if (gl) {
    if (bgDotsFramebuffer !== null) {
      const fboWidth: [number] = [0];
      const fboHeight: [number] = [0];
      nvg.imageSize(bgDotsFramebuffer.image, fboWidth, fboHeight);

      NVG.nvgluBindFramebuffer(bgDotsFramebuffer);
      gl.viewport(0, 0, fboWidth[0], fboHeight[0]);
      gl.clearColor(0, 0, 0, 0);
      gl.clear(
        gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT,
      );

      nvg.beginFrame(fboWidth[0], fboHeight[0], window.devicePixelRatio);
      nvg.beginPath();
      nvg.circle(
        fboWidth[0] / 2,
        fboHeight[0] / 2,
        fboWidth[0] / renderConstants.GRID_SIZE,
      );
      nvg.fillColor(nvg.RGBA(200, 200, 200, 255));
      nvg.fill();
      nvg.endFrame();
      NVG.nvgluBindFramebuffer(null);
    }

    if (backFramebuffer !== null) {
      const fboWidth: [number] = [0];
      const fboHeight: [number] = [0];
      nvg.imageSize(backFramebuffer.image, fboWidth, fboHeight);

      NVG.nvgluBindFramebuffer(backFramebuffer);
      gl.viewport(0, 0, fboWidth[0], fboHeight[0]);
      gl.clearColor(0, 0, 0, 0);
      gl.clear(
        gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT,
      );

      nvg.beginFrame(framebufWidth, framebufHeight, window.devicePixelRatio);
      if (
        rendererState.mouseState.state === MouseActions.DragDropLibraryBlock
      ) {
        const blockData = blockTypeNameToInstanceDefaults(
          rendererState.mouseState.blockClassName,
          undefined,
          rendererState.mouseState.referenceSubmodel?.id,
        );

        const referenceSubmodel = rendererState.mouseState
          .referenceSubmodel as SubmodelInfoUI;
        if (referenceSubmodel && referenceSubmodel.portDefinitionsInputs) {
          updateSubmodelInstanceForReferenceChanges(
            blockData as SubmodelInstance,
            referenceSubmodel,
          );
        }

        drawNode(
          nvg,
          rendererState,
          blockData,
          [],
          false,
          rendererState.screenCursorZoomed.x -
            rendererState.mouseState.cursorOffset.x,
          rendererState.screenCursorZoomed.y -
            rendererState.mouseState.cursorOffset.y,
        );
      }

      nvg.endFrame();
      NVG.nvgluBindFramebuffer(null);
    }

    gl.viewport(0, 0, framebufWidth, framebufHeight);
    gl.enable(gl.BLEND);

    if (rendererState.refs.current.modelKind === 'Experiment') {
      gl.clearColor(0.9, 0.97, 0.97, 1.0);
    } else if (
      rendererState.refs.current.currentSubdiagramType === 'core.Iterator'
    ) {
      gl.clearColor(248 / 255, 245 / 255, 227 / 255, 1);
    } else if (
      rendererState.refs.current.currentSubdiagramType ===
      'core.LinearizedSystem' // TODO: proper color. also this approach doesnt scale
    ) {
      gl.clearColor(0, 245 / 255, 227 / 255, 1);
    } else {
      gl.clearColor(0.94, 0.95, 0.95, 1.0);
    }

    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT);
    nvg.beginFrame(renderWidth, renderHeight, window.devicePixelRatio);

    const bgOpacity = Math.max(0, Math.min(1, rendererState.zoom - 0.5)) / 2;
    if (bgDotsFramebuffer !== null && bgOpacity > 0) {
      nvg.fillPaint(
        nvg.imagePattern(
          (renderConstants.GRID_SIZE / 2 + rendererState.camera.x) *
            rendererState.zoom,
          (renderConstants.GRID_SIZE / 2 + rendererState.camera.y) *
            rendererState.zoom,
          renderConstants.GRID_SIZE * rendererState.zoom,
          renderConstants.GRID_SIZE * rendererState.zoom,
          0,
          bgDotsFramebuffer.image,
          bgOpacity,
        ),
      );
      nvg.fillRect(0, 0, renderWidth, renderHeight);
    }

    drawScene(
      nvg,
      rendererState,
      rendererState.refs.current.connectedPortLUT,
      rendererState.refs.current.selectedNodeIds,
      framebufWidth,
      framebufHeight,
    );

    if (backFramebuffer !== null) {
      nvg.fillPaint(
        nvg.imagePattern(
          0,
          0,
          framebufWidth,
          framebufHeight,
          0,
          backFramebuffer.image,
          0.6,
        ),
      );
      nvg.fillRect(0, 0, renderWidth, renderHeight);
    }

    nvg.endFrame();

    gl.enable(gl.DEPTH_TEST);
  }

  // Clicks should only persist for 1 frame
  if (
    rendererState.clickState.state === ClickStates.Click ||
    rendererState.clickState.state === ClickStates.DoubleClick
  ) {
    rendererState.clickState = { state: ClickStates.Idle };
  }

  // Reset 1-frame keypresses
  const pressedKeyNames = Object.keys(keysJust);
  for (let i = 0; i < pressedKeyNames.length; i++) {
    keysJust[pressedKeyNames[i]] = false;
  }

  if (typeof window !== 'undefined') {
    if (done) {
      tickRafId = window.requestAnimationFrame(endNanovg);
    } else {
      tickRafId = window.requestAnimationFrame(_tick);
    }
  }
};

const triggerShortcut = (configItem: SingleShortcutConfig) => {
  if (rendererState !== null) {
    const uiFlags = rendererState.refs.current.uiFlags;
    const shouldIgnore =
      configItem.ignoreIfTextFocused &&
      (uiFlags.textInputFocused || uiFlags.htmlTextSelected);
    if (!shouldIgnore) configItem.handler(rendererState);
  }
};

const specialKeyCallbacks: ((e: KeyboardEvent) => void)[] = [];
const specialKeyMainCallback = (e: KeyboardEvent) => {
  for (let j = 0; j < specialKeyCallbacks.length; j++) {
    specialKeyCallbacks[j](e);
  }
};

// necessary because for some of our alt keys we get the wrong "key"
// which is the recommended layout-independent (!) value to use
const macosAltKeyMap: { [k: string]: string } = {
  '∂': 'd',
  '¬': 'l',
  '®': 'r',
};

const registerHotkeyEvents = () => {
  for (let i = 0; i < shortcutsConfig.length; i++) {
    const configItem = shortcutsConfig[i];

    if (configItem.variant === 'special') {
      /* eslint-disable */
      specialKeyCallbacks.push((e: KeyboardEvent) => {
        if (rendererState == null) return;

        const uiFlags = rendererState.refs.current.uiFlags;
        const shouldIgnore =
          configItem.ignoreIfTextFocused &&
          (uiFlags.textInputFocused || uiFlags.htmlTextSelected);

        if (shouldIgnore) return;

        const modKeys = configItem.specialConfig.modKeys;
        let modKeysPressed = true;
        for (let j = 0; j < modKeys.length; j++) {
          switch (modKeys[j]) {
            case 'CtrlCmd':
              modKeysPressed = e.ctrlKey || e.metaKey;
              break;
            case 'AltOption':
              modKeysPressed = e.altKey;
              break;
            case 'Shift':
              modKeysPressed = e.shiftKey;
              break;
          }
        }

        const configKey = configItem.specialConfig.key;
        const pressingMainKey =
          configKey == e.key || configKey == macosAltKeyMap[e.key];

        if (modKeysPressed && pressingMainKey) {
          if (configItem.preventDefault) {
            e.preventDefault();
          }
          triggerShortcut(configItem);
        }
      });
      /* eslint-enable */
    } else {
      /* eslint-disable */
      hotkeys(configItem.hotkeyString, (e) => {
        if (rendererState == null) return;

        const uiFlags = rendererState.refs.current.uiFlags;
        const shouldIgnore =
          configItem.ignoreIfTextFocused &&
          (uiFlags.textInputFocused || uiFlags.htmlTextSelected);

        if (shouldIgnore) return;

        if (configItem.preventDefault) {
          e.preventDefault();
        }
        triggerShortcut(configItem);
      });
      /* eslint-enable */
    }
  }

  document.addEventListener('keydown', specialKeyMainCallback);
};

function onTextSelectionChange() {
  if (rendererState === null) return;

  const dispatch = rendererState.dispatch;
  const { anchorOffset, focusOffset } = document.getSelection() || {
    anchorOffset: 0,
    focusOffset: 0,
  };
  const minPos = Math.min(anchorOffset, focusOffset);
  const maxPos = Math.max(anchorOffset, focusOffset);
  if (maxPos - minPos > 0) {
    dispatch(uiFlagsActions.setUIFlag({ htmlTextSelected: true }));
  } else {
    dispatch(uiFlagsActions.setUIFlag({ htmlTextSelected: false }));
  }
}

// necessary for re-mounting
export function unregisterRendererEvents(): void {
  if (!rendererState) return;

  const { canvas } = rendererState.refs.current;
  if (typeof window !== 'undefined') {
    window.removeEventListener('keydown', keyDown);
    window.removeEventListener('keyup', keyUp);
    window.removeEventListener('pointermove', mouseMove);
    window.removeEventListener('mousedown', mouseDown);
    window.removeEventListener('contextmenu', contextMenuEvent);
    window.removeEventListener('mouseup', mouseUp);

    if (canvas) {
      canvas.removeEventListener('wheel', wheel);
    }

    hotkeys.unbind();
    document.removeEventListener('keydown', specialKeyMainCallback);
    document.removeEventListener('selectionchange', onTextSelectionChange);
  }
}

export async function initModelRenderer(
  refs: MutableRefObject<RendererRefsType>,
  setTransform: TransformFunc,
  dispatch: AppDispatch,
): Promise<void> {
  await startNanovg(refs.current.canvas as HTMLCanvasElement);

  rendererState = {
    refs,
    setTransform,
    dispatch,
    linksRenderFrameData: rendererState?.linksRenderFrameData || [],
    linksRenderFrameDataIndexLUT: {},
    linksOcclusionPointLUT: {},
    camera: rendererState?.camera || { x: 0, y: 0 },
    screenCursorRaw: rendererState?.screenCursorRaw || { x: 0, y: 0 },
    screenCursorZoomed: rendererState?.screenCursorZoomed || { x: 0, y: 0 },
    clickState: rendererState?.clickState || { state: ClickStates.Idle },
    mouseState: rendererState?.mouseState || { state: MouseActions.Idle },
    zoom: rendererState?.zoom || 1,
    hoveringEntity: undefined,
  };

  registerHotkeyEvents();

  document.addEventListener('selectionchange', onTextSelectionChange);

  const canvas = refs.current.canvas;
  const parent = refs.current.parent;
  if (typeof window !== 'undefined' && canvas && parent) {
    window.addEventListener('keydown', keyDown);
    window.addEventListener('keyup', keyUp);
    window.addEventListener('pointermove', mouseMove);
    window.addEventListener('mousedown', mouseDown);
    window.addEventListener('contextmenu', contextMenuEvent);
    window.addEventListener('mouseup', mouseUp);
    canvas.addEventListener('wheel', wheel);
    canvas.tabIndex = 1;
    canvas.style.left = '0';
    canvas.style.right = '0';
    canvas.style.top = '0';
    canvas.style.bottom = '0';
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.style.position = 'absolute';
    canvas.width = parent.clientWidth * window.devicePixelRatio;
    canvas.height = parent.clientHeight * window.devicePixelRatio;

    window.removeEventListener('resize', windowResizer);

    windowResizer = (_event: UIEvent): void => {
      canvas.width = parent.clientWidth * window.devicePixelRatio;
      canvas.height = parent.clientHeight * window.devicePixelRatio;
    };

    window.addEventListener('resize', windowResizer);

    // see useModelEditorPreferences.ts for an explanation on why we're using
    // setTimeout() here.
    // not the perfect solution, but it works. -jackson
    window.setTimeout(() => {
      dispatch(userPreferencesActions.setLoadModelEditor());
      dispatch(userPreferencesActions.setLoadVisualizer());
    }, 50);

    dispatch(uiFlagsActions.setUIFlag({ rendererStateInitialized: true }));
  }

  if (typeof window !== 'undefined') {
    window.cancelAnimationFrame(tickRafId);
    tickRafId = window.requestAnimationFrame((t: number) => {
      startMillis = t;
      _tick(t);
    });
  }
}
