import styled from '@emotion/styled/macro';
import React from 'react';
import { Search } from 'ui/common/Icons/Small';
import Input, { InputSubmitMetadata } from 'ui/common/Input/Input';

const SectionSearchWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.normal};
`;

interface Props {
  onChangeText: (newText: string) => void;
  placeholder: string;
  autoFocus?: boolean;
  onCancel?: () => void;
  onSubmit?: (newValue: string, submitMetadata: InputSubmitMetadata) => void;
  inputRef?: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | null
  >;
}

const SectionSearch: React.FC<Props> = ({
  onChangeText,
  placeholder,
  autoFocus,
  onCancel,
  onSubmit,
  inputRef,
}) => (
  <SectionSearchWrapper>
    <Input
      hasBorder
      LeftIcon={Search}
      placeholder={placeholder}
      onChangeText={onChangeText}
      onCancel={onCancel}
      onSubmitValue={onSubmit}
      autoFocus={autoFocus}
      externalInputRef={inputRef}
      clearable
    />
  </SectionSearchWrapper>
);

export default SectionSearch;
