import styled from '@emotion/styled';
import { useNavigateToProjectHome } from 'app/api/useNavigateToProjectHome';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectEntityPrefs } from 'app/slices/entityPreferencesSlice';
import { jupyterSelectors } from 'app/slices/jupyterSlice';
import { NavbarContext, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { Spinner } from 'ui/common/Spinner';
import {
  AppContentWithFooterWrapper,
  AppContentWrapper,
} from 'ui/common/layout/appLayout';
import { GLOBAL_ENTITY } from 'ui/userPreferences/globalEntity';
import {
  MODEL_EDITOR_LAYOUT_PREFS_V1_KEY,
  ModelEditorLayoutPrefsV1,
} from 'ui/userPreferences/modelEditorLayoutPrefs';
import useEntityPreferences from 'ui/userPreferences/useEntityPreferences';
import FooterContainer from '../appBottomBar/FooterContainer';

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${({ theme }) => theme.colors.grey[10]};
  top: 0;
  left: 0;
`;

const NotebookPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { navigateToProjectHome } = useNavigateToProjectHome();
  const isIframeReady = useAppSelector(jupyterSelectors.selectIframeReady);
  const { hasAccessToJupyter } = useAppSelector(
    (state) => state.userOptions.options,
  );

  // Parent logic for bottom panel sizing
  const AppContentWithFooterWrapperRef = React.useRef<HTMLDivElement | null>(
    null,
  );
  useEntityPreferences(MODEL_EDITOR_LAYOUT_PREFS_V1_KEY, GLOBAL_ENTITY);
  const layoutPrefs = useAppSelector((state) =>
    selectEntityPrefs(state, MODEL_EDITOR_LAYOUT_PREFS_V1_KEY, GLOBAL_ENTITY),
  ) as ModelEditorLayoutPrefsV1 | null;

  React.useEffect(() => {
    if (!hasAccessToJupyter) {
      navigateToProjectHome();
    }
  }, [hasAccessToJupyter, navigateToProjectHome]);

  React.useEffect(() => {
    dispatch(uiFlagsActions.setNavbarContext(NavbarContext.Notebook));
    return () => {
      dispatch(uiFlagsActions.setNavbarContext(NavbarContext.None));
    };
  }, [dispatch]);

  return (
    <AppContentWithFooterWrapper ref={AppContentWithFooterWrapperRef}>
      {!isIframeReady ? (
        <Loader>
          <Spinner width="36" height="36" />
        </Loader>
      ) : null}
      <AppContentWrapper />

      {/* Footer content */}
      <FooterContainer
        enabled={isIframeReady}
        savedHeight={layoutPrefs?.bottomPanelHeight}
        parentRef={AppContentWithFooterWrapperRef?.current || undefined}
      />
    </AppContentWithFooterWrapper>
  );
};
export default NotebookPage;
