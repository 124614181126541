import { MarkedPoint } from 'ui/dataExplorer/dataExplorerTypes';

export function isSameMarkedPoint(
  point1: MarkedPoint,
  point2: MarkedPoint,
): boolean {
  return point1[0] === point2[0] && point1[1] === point2[1];
}

export function containsMarkedPoint(
  markedPoints: MarkedPoint[],
  point: MarkedPoint,
): boolean {
  return markedPoints.some((existingPoint) =>
    isSameMarkedPoint(existingPoint, point),
  );
}
