import { t } from '@lingui/macro';
import { useProjectItems } from 'app/api/useProjectItems';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import React from 'react';
import Input from 'ui/common/Input/Input';
import {
  isDuplicatedRuleSet,
  isRequiredRule,
} from 'ui/common/Input/inputValidation';
import Label from 'ui/common/Label';
import { ModalInputGroup } from 'ui/common/Modal/Modal';
import { splitFullName } from 'util/fileUtils';
import { ProjectItemType } from '../dashboardTypes';

function getItemLabel(itemType: ProjectItemType) {
  switch (itemType) {
    case ProjectItemType.MODEL:
      return t({
        id: 'duplicateModal.modelLabel',
        message: 'model',
      });
    case ProjectItemType.SUBMODEL:
      return t({
        id: 'duplicateModal.submodelLabel',
        message: 'submodel',
      });
    case ProjectItemType.NOTEBOOK:
    case ProjectItemType.NOTEBOOKFILE:
    case ProjectItemType.PROJECTFILE:
    case ProjectItemType.CODE:
      return t({
        id: 'duplicateModal.fileLabel',
        message: 'file',
      });
    default:
      // Experiments don't have duplication enabled, so this should never happen.
      // Need one place for this action <> item type logic.
      return '';
  }
}

interface Props {
  project: Project;
  /**
   * The type of item being duplicated.
   * This is pretty horrible. There are multiple sources of truth.
   * Because there is a god component AND hook that conditionally decide which buttons and actions to support,
   * the logic to determine which actions to support and which actions to display are in two different places despite being the same.
   */
  itemType: ProjectItemType;
  oldNameWithoutExtension: string;
  extension: string;
  onChangeName: (newName: string, isValid: boolean) => void;
}

const ProjectItemNameField: React.FC<Props> = ({
  project,
  itemType,
  oldNameWithoutExtension,
  extension,
  onChangeName,
}) => {
  const [newName, setName] = React.useState(oldNameWithoutExtension);

  const { projectItems } = useProjectItems(project);

  const updateName = React.useCallback(
    (newName: string, isValid: boolean) => {
      setName(newName);
      onChangeName(newName, isValid);
    },
    [onChangeName],
  );

  const validationRules = React.useMemo(() => {
    if (!projectItems) return [isRequiredRule];

    const sameTypeFiles = projectItems
      .filter((d) => splitFullName(d.name)[1] === extension)
      .map((value) => splitFullName(value.name)[0]);

    return isDuplicatedRuleSet(sameTypeFiles, (name) => name);
  }, [projectItems, extension]);

  return (
    <ModalInputGroup>
      <Label testId="duplicate-name-label">
        {t({
          id: 'duplicateModal.nameInput.label',
          message: 'Give your new {itemLabel} a unique name',
          values: { itemLabel: getItemLabel(itemType) },
        })}
      </Label>
      <Input
        value={newName}
        hasBorder
        autoFocus
        onChangeText={updateName}
        validationRules={validationRules}
      />
    </ModalInputGroup>
  );
};

export default ProjectItemNameField;
