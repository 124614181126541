import {
  SimulationListResponse,
  SimulationSummary,
} from 'app/apiGenerated/generatedApiTypes';
import { SimulationSummaryUI } from 'app/apiTransformers/convertGetSimulationReadAll';

export function convertAPISimulationSummaryToSimulationSummaryUI(
  apiSimulationSummary: SimulationSummary,
): SimulationSummaryUI {
  return {
    id: apiSimulationSummary.uuid,
    projectId: apiSimulationSummary.project_uuid,
    modelId: apiSimulationSummary.model_uuid,
    modelVersionId: apiSimulationSummary.snapshot_uuid,
    startDate: apiSimulationSummary.created_at,
    status: apiSimulationSummary.status,
    areResultsAvailable: apiSimulationSummary.results_available || false,
  };
}

export function convertGetSimulationsForUserReadAll(
  apiResponse: SimulationListResponse,
): SimulationSummaryUI[] {
  const simulations = apiResponse.simulations
    .filter((apiSimulationSummary) => !apiSimulationSummary.compile_only)
    .map((apiSimulationSummary) =>
      convertAPISimulationSummaryToSimulationSummaryUI(apiSimulationSummary),
    );

  if (simulations) {
    return [...simulations].sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
    );
  }

  return [];
}
