import styled from '@emotion/styled/macro';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import React from 'react';
import Button from 'ui/common/Button/Button';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Close, Plus } from 'ui/common/Icons/Standard';
import { BlockDocumentation } from 'ui/common/Inputs/BlockDocumentation';
import { Header, Title } from 'ui/common/Modal/Modal';
import PortalTooltip from 'ui/common/Tooltip/PortalTooltip';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { H5 } from 'ui/common/typography/Typography';

interface Props {
  children: React.ReactNode;
  isButtonEnabled?: boolean;
  onButtonClick?: () => void;
  buttonTooltip?: string;
  helpUrl?: string;
  blockDescription?: string;
  blockClass?: ComputationBlockClass;
  submodelReference?: SubmodelInfoUI;
  ButtonIcon?: React.FC<any>;
  buttonTint?: string;
  testId?: string;
  Action?: JSX.Element;
}

const SectionHeadingWrapper = styled.section`
  ${({ theme }) => `
    display: flex;
    width: 100%;
    color: ${theme.colors.text.primary};
    margin-bottom: ${theme.spacing.large};
    margin-left: ${theme.spacing.small};
    padding-left: ${theme.spacing.normal};
    padding-right: ${theme.spacing.normal};
    margin-top: ${theme.spacing.normal};
    position: relative;

    &:not(:first-of-type) {
      &:before {
        content: '';
        position: absolute;
        height: 1px;
        top: -${theme.spacing.normal};
        left: -${theme.spacing.normal};
        right: -${theme.spacing.normal};
        background: ${theme.colors.grey[5]};
      }
    }
  `}
`;
const SectionH5 = styled(H5)`
  flex: 1;
  display: flex;
  align-items: center;
`;

const TooltipWrapper = styled.div`
  width: 300px;
  padding: ${({ theme }) => theme.spacing.large};
  border: solid 1px ${({ theme }) => theme.colors.grey[10]};
`;

const SectionHeading: React.FC<Props> = ({
  children,
  isButtonEnabled = true,
  onButtonClick,
  buttonTooltip,
  helpUrl,
  blockDescription,
  ButtonIcon = Plus,
  buttonTint,
  testId,
  Action,
  blockClass,
  submodelReference,
}) => {
  const triggerElRef = React.useRef<HTMLButtonElement>(null);
  const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);

  const onClick =
    blockDescription || submodelReference
      ? () => setIsTooltipOpen(!isTooltipOpen)
      : onButtonClick;

  const showTooltipButton = !!(onClick && buttonTooltip);
  const showPlainButton = !!(onClick && !buttonTooltip);

  return (
    <SectionHeadingWrapper>
      <SectionH5 testId={`${testId}-heading-text`}>{children}</SectionH5>
      {showTooltipButton && (
        <TooltipButton
          testId={`${testId}-heading-button`}
          variant={ButtonVariants.LargeTertiary}
          Icon={ButtonIcon}
          onClick={onClick}
          disabled={!isButtonEnabled}
          tooltip={buttonTooltip}
        />
      )}
      {showPlainButton && (
        <Button
          ref={triggerElRef}
          testId={`${testId}-heading-button`}
          variant={ButtonVariants.LargeTertiary}
          Icon={ButtonIcon}
          tint={buttonTint}
          onClick={onClick}
          disabled={!isButtonEnabled}
        />
      )}
      {Action && Action}
      {isTooltipOpen && triggerElRef.current && (
        <PortalTooltip
          testId="block-info-tooltip"
          invertedColor={false}
          showBorder
          triggerEl={triggerElRef.current}
          placement={TooltipPlacement.BOTTOM_LEFT}>
          <TooltipWrapper>
            <Header>
              <Title>{children}</Title>
              <Button
                Icon={Close}
                onClick={onClick}
                testId="modal-close-button"
                variant={ButtonVariants.LargeTertiary}
              />
            </Header>

            <BlockDocumentation
              blockDescription={blockDescription}
              helpUrl={helpUrl}
              blockClass={blockClass}
              submodelReference={submodelReference}
            />
          </TooltipWrapper>
        </PortalTooltip>
      )}
    </SectionHeadingWrapper>
  );
};

export default SectionHeading;
