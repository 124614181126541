import { t } from '@lingui/macro';
import { useProjectActions } from 'app/api/useProjectActions';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Download } from 'ui/common/Icons/Standard';
import { ProjectDetailJupyterButton } from 'ui/dashboard/projectDetail/ProjectDetailJupyterButton';
import { ProjectDetailNewItemButtons } from 'ui/dashboard/projectDetail/ProjectDetailNewItemButtons';
import { useProjectPermission } from 'ui/permission/useProjectPermission';
import { ProjectDetailCreateBusTypeButton } from './ProjectDetailBusTypeButton';
import { ProjectDetailImportButton } from './ProjectDetailImportButton';
import { ProjectDetailCreateScriptButton } from './ProjectDetailScriptButton';

interface Props {
  project: Project;
  hubFiles: any;
}

export const ProjectDetailActionButtons: React.FC<Props> = ({
  project,
  hubFiles,
}) => {
  const { hasAccessToJupyter, developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const { canEditProject } = useProjectPermission(project.uuid);
  const { exportProject } = useProjectActions();

  const onExportProject = () => {
    const projectId = project?.uuid;
    const projectTitle = project?.title;
    if (!projectId || !projectTitle) {
      return null;
    }

    const fileName = `${projectTitle}.zip`;
    exportProject(fileName, {
      projectUuid: projectId,
    });
  };

  return (
    <>
      {canEditProject && (
        <>
          <ProjectDetailNewItemButtons project={project} />
          {hasAccessToJupyter && (
            <ProjectDetailJupyterButton project={project} hubFiles={hubFiles} />
          )}
          <ProjectDetailCreateScriptButton project={project} />
          {developerModeEnabled && (
            <ProjectDetailCreateBusTypeButton project={project} />
          )}
          <ProjectDetailImportButton project={project} />
        </>
      )}
      {developerModeEnabled && (
        <Button
          variant={ButtonVariants.SmallSecondary}
          testId="project-detail-export-button"
          Icon={Download}
          onClick={onExportProject}>
          {t({
            id: 'dashboard.projectsDetailHeader.exportButton.Label',
            message: 'Export',
          })}
        </Button>
      )}
    </>
  );
};
