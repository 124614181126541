import { getNestedNode } from 'app/utils/modelDiagramUtils';
import { getPortPathName } from 'ui/modelEditor/portPathNameUtils';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { TraceMetadata } from '../ui/dataExplorer/dataExplorerTypes';

interface NodePortsInChartOptions {
  nodeId: string;
  parentPath: string[];
  idToTrace: Record<string, TraceMetadata>;
}

interface PortInChartOptions extends NodePortsInChartOptions {
  portIndex: number;
}

export function getAreAnyNodePortsInVisualizer({
  nodeId,
  parentPath,
  idToTrace,
}: NodePortsInChartOptions) {
  if (!idToTrace) {
    return false;
  }
  const node = getNestedNode(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    parentPath,
    nodeId,
  );
  if (!node || node.outputs.length === 0) {
    return false;
  }

  for (let portIndex = 0; portIndex < node.outputs.length; portIndex++) {
    const signalPath = getPortPathName(
      getCurrentModelRef().topLevelNodes,
      getCurrentModelRef().submodels,
      {
        nodeId,
        parentPath,
        portIndex,
      },
      { includePortNameForNonSubmodels: true },
    );
    if (signalPath) {
      const isFound = Object.values(idToTrace).some(
        (trace) => trace.signalPath === signalPath,
      );
      if (isFound) {
        return true;
      }
    }
  }

  return false;
}

export function getAreAllNodePortsInVisualizer({
  nodeId,
  parentPath,
  idToTrace,
}: NodePortsInChartOptions) {
  if (!idToTrace) {
    return false;
  }

  const node = getNestedNode(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    parentPath,
    nodeId,
  );
  if (!node || node.outputs.length === 0) {
    return false;
  }

  for (let portIndex = 0; portIndex < node.outputs.length; portIndex++) {
    const signalPath = getPortPathName(
      getCurrentModelRef().topLevelNodes,
      getCurrentModelRef().submodels,
      {
        nodeId,
        parentPath,
        portIndex,
      },
      { includePortNameForNonSubmodels: true },
    );
    if (signalPath) {
      const isFound = Object.values(idToTrace).some(
        (trace) => trace.signalPath === signalPath,
      );
      if (!isFound) {
        return false;
      }
    }
  }

  return true;
}

export function getIsPortInVisualizer({
  nodeId,
  parentPath,
  portIndex,
  idToTrace,
}: PortInChartOptions) {
  if (!idToTrace) {
    return false;
  }

  const node = getNestedNode(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    parentPath,
    nodeId,
  );
  if (!node || node.outputs.length === 0) {
    return false;
  }

  const signalPath = getPortPathName(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    {
      nodeId,
      parentPath,
      portIndex,
    },
    { includePortNameForNonSubmodels: true },
  );
  if (!signalPath) {
    return false;
  }
  return Object.values(idToTrace).some(
    (trace) => trace.signalPath === signalPath,
  );
}
