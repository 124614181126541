import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useGetGlobalSubmodelsReadQuery } from 'app/apiGenerated/generatedApi';
import { nodeTypeIsIterator } from 'app/helpers';
import { useAppSelector } from 'app/hooks';
import { selectCurrentSubdiagramType } from 'app/slices/modelSlice';
import { googleAnalyticsTrackSearchKeyword } from 'googleAnalyticsTracking';
import React from 'react';
import { SidebarScrollContainer } from 'ui/common/layout/appLayout';
import { FoundationalBlocks } from 'ui/objectBrowser/sections/FoundationalBlocks';
import { ReferenceSubmodelBlocks } from 'ui/objectBrowser/sections/ReferenceSubmodelBlocks';
import { useAppParams } from 'util/useAppParams';
import { ExperimentModelBlocks } from './ExperimentModelBlocks';
import { IteratorBlocks } from './IteratorBlocks';
import SectionSearch from './SectionSearch';

export const LibraryScrollContainer = styled(SidebarScrollContainer)`
  padding-bottom: 64px;
`;

const SEARCH_SEND_EVENT_DELAY_MS = 500;

const LibrarySection: React.FC = () => {
  const { projectId, modelId } = useAppParams();
  const { project } = useProject();
  const submodels = useAppSelector(
    (state) => state.submodels.projectIdToSubmodelInfoLites[projectId || ''],
  );

  const modelKind = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  const currentSubdiagramType = useAppSelector((state) =>
    selectCurrentSubdiagramType(state.model.present),
  );

  const isInsideIterator = nodeTypeIsIterator(currentSubdiagramType);

  const { data: highLevelBlocks } = useGetGlobalSubmodelsReadQuery();
  const isExperimentModel = modelKind === 'Experiment';

  const projectTitle = project?.title || '';
  const [blockSearchString, setSearchString] = React.useState('');

  const searchInputTimerIdRef = React.useRef<any>(null);

  const updateSearchString = React.useCallback((newSearchString: string) => {
    setSearchString(newSearchString);

    if (newSearchString.length >= 3) {
      if (searchInputTimerIdRef.current) {
        clearTimeout(searchInputTimerIdRef.current);
      }

      searchInputTimerIdRef.current = setTimeout(() => {
        googleAnalyticsTrackSearchKeyword(newSearchString);
      }, SEARCH_SEND_EVENT_DELAY_MS);
    }
  }, []);

  const lowercaseSearchString = blockSearchString.toLowerCase();

  if (!projectId || !modelId) return null;

  // Don't show the current submodel if we are editing a submodel
  // in order to prevent circular references.
  const submodelsToShow =
    submodels?.filter((submodel) => submodel.id !== modelId) || [];

  const showProjectSubmodels = submodelsToShow.length > 0 && !isExperimentModel;
  const showHighLevelBlocks =
    highLevelBlocks && highLevelBlocks.length > 0 && !isExperimentModel;
  const showCategories =
    showProjectSubmodels || showHighLevelBlocks || isExperimentModel;

  return (
    <>
      <SectionSearch
        placeholder={t({
          id: 'modelEditor.library.filterByLabel.placeholder',
          message: 'Filter by label',
        })}
        onChangeText={updateSearchString}
      />
      <LibraryScrollContainer>
        {isInsideIterator && (
          <IteratorBlocks lowercaseSearchString={lowercaseSearchString} />
        )}
        {isExperimentModel && (
          <ExperimentModelBlocks
            lowercaseSearchString={lowercaseSearchString}
            sectionTitle={projectTitle}
          />
        )}
        {showProjectSubmodels && (
          <ReferenceSubmodelBlocks
            lowercaseSearchString={lowercaseSearchString}
            submodels={submodelsToShow}
            sectionTitle={projectTitle}
          />
        )}
        {showHighLevelBlocks && (
          <ReferenceSubmodelBlocks
            lowercaseSearchString={lowercaseSearchString}
            submodels={highLevelBlocks}
            sectionTitle={t({
              id: 'modelEditor.library.highLevelBlocks',
              message: 'High Level Blocks',
            })}
          />
        )}
        <FoundationalBlocks
          lowercaseSearchString={lowercaseSearchString}
          showCategories={showCategories}
          currentSubdiagramType={currentSubdiagramType}
        />
      </LibraryScrollContainer>
    </>
  );
};

const MemoizedLibrarySection = React.memo(LibrarySection);
export default MemoizedLibrarySection;
