import { NodeInstance } from 'app/generated_types/SimulationModel';

export const flippableIconIDs = ['gain', 'logicaloperator', 'mux', 'demux'];

const correctOp = (op?: string) => {
  switch (op) {
    case '==':
      return 'ee';
    case '>':
      return 'gt';
    case '<':
      return 'lt';
    case '>=':
      return 'gte';
    case '<=':
      return 'lte';
    case '!=':
      return 'ltgt'; // didn't expect this
    default:
      return 'ee';
  }
};

function getConcatenationIconSuffix(node?: NodeInstance): string {
  if (node?.parameters?.concatenation_orientation?.value === 'vertical') {
    return 'concatenationvertical';
  }
  return 'concatenationhorizontal';
}

function getMatrixOperatorIconSuffix(node?: NodeInstance) {
  switch (node?.parameters?.function?.value) {
    case 'transpose':
      return 'transpose';
    case 'inverse':
      return 'inverse';
    case 'multiply':
      if (node?.parameters?.multiplication_type?.value === 'vector_vector') {
        return 'dotproduct';
      }
      return 'multiply';
    case 'concatenation':
      return getConcatenationIconSuffix(node);
    case 'extraction':
      return 'extraction';
    case 'fft':
      return 'multiply'; // TODO add fft icon
    default:
      return 'multiply';
  }
}

const correctors: {
  [k: string]: ((n?: NodeInstance) => string) | undefined;
} = {
  modelicafmu: () => 'fmi',
  logicaloperator: (node?: NodeInstance) =>
    `logic.${node?.parameters?.function?.value || 'and'}`,
  matrixoperator: (node?: NodeInstance) =>
    `matrixoperator.${getMatrixOperatorIconSuffix(node)}`,
  matrixtransposition: (node?: NodeInstance) => `matrixoperator.transpose`,
  matrixinversion: (node?: NodeInstance) => `matrixoperator.inverse`,
  matrixmultiplication: (node?: NodeInstance) => `matrixoperator.multiply`,
  // FIXME when we have a proper icon for Slice block
  slice: (node?: NodeInstance) => `matrixoperator.extraction`,
  matrixconcatenation: (node?: NodeInstance) =>
    `matrixoperator.${getConcatenationIconSuffix(node)}`,
  trigonometric: (node?: NodeInstance) =>
    `trig.${node?.parameters?.function?.value || 'sin'}`,
  comparator: (node?: NodeInstance) =>
    `comparator.${correctOp(node?.parameters?.operator?.value)}`,
  discreteinitializer: (node?: NodeInstance) =>
    `discreteinitializer${
      node?.parameters?.initial_state?.value === 'true'
        ? 'initialstatetrue'
        : '.initialstatefalse'
    }`,
  derivativediscrete: () => 'discretederivative',
  integratordiscrete: () => 'discreteintegrator',
  cosinewave: () => 'cosine',
  referencesubmodel: () => 'submodel',
  experimentmodel: () => 'model',
  pythonscript: () => 'pythonscript',
  pid: () => 'pid.pid',
  pid_discrete: () => 'discretepid.pid',
  edgedetection: (node?: NodeInstance) =>
    `edgedetector.${node?.parameters?.edge_detection?.value || 'rising'}`,
  coordinaterotation: (node?: NodeInstance) => {
    switch (node?.parameters?.rotation_type?.value) {
      case 'quaternion':
        return 'coordinaterotation.quaternion';
      case 'roll_pitch_yaw':
        return 'coordinaterotation.rotationangles';
      case 'DCM':
        return 'coordinaterotation.dcm';
      default:
        return 'coordinaterotation.quaternion';
    }
  },
  coordinaterotationconversion: (node?: NodeInstance) => {
    switch (node?.parameters?.conversion_type?.value) {
      case 'quaternion_to_DCM':
        return 'coordinaterotationconversion.quaterniontodcm';
      case 'quaternion_to_RPY':
        return 'coordinaterotationconversion.quaterniontorotationangles';
      case 'DCM_to_quaternion':
        return 'coordinaterotationconversion.dcmtoquaternion';
      case 'DCM_to_RPY':
        return 'coordinaterotationconversion.dcmtorotationangles';
      case 'RPY_to_quaternion':
        return 'coordinaterotationconversion.rotationanglestoquaternion';
      case 'RPY_to_DCM':
        return 'coordinaterotationconversion.rotationanglestodcm';
      default:
        return 'coordinaterotationconversion.quaterniontodcm';
    }
  },
  codeblockdev: () => 'code',
};

export const getBlockIconID = (
  baseClassName: string,
  node?: NodeInstance,
): string => {
  const lowerBaseClass = baseClassName.toLowerCase();
  const corrector = correctors[lowerBaseClass];
  if (corrector) {
    return corrector(node);
  }

  // Get the icon for the corresponding block without the _dev suffix
  // (if one was appended)
  const lowerBaseClassNoDev = lowerBaseClass.replace(/_dev$/, '');
  if (lowerBaseClass !== lowerBaseClassNoDev) {
    const corrector_dev = correctors[lowerBaseClassNoDev];
    if (corrector_dev) {
      return corrector_dev(node);
    }
  }

  return lowerBaseClassNoDev;
};
