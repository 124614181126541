// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import deepmerge from 'deepmerge';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';

export const ObjectDetection: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "ObjectDetection",
      "namespace": "core",
      "description": "Detect objects in images. Model was trained on COCO 2017 object detection. Output is a list of python dictionary containing the \"label\", \"score\" and \"box\" (xmin, ymin, xmax, ymax) for each object detected."
    },
    "modes": {
      "time": "discrete",
      "feedthrough": true
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "image"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "results"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "model",
        "data_type": "string",
        "default_value": "detr-resnet-50",
        "allowed_values": [
          "detr-resnet-50"
        ],
        "description": "Object detection model. Only \"detr-resnet-50\" supported for now."
      }
    ]
  }
);
