import { t } from '@lingui/macro';
import { BlockParameterDefinition } from 'app/generated_types/ComputationBlockClass';
import {
  BlockClassName,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import { UserOptions } from 'app/slices/userOptionsSlice';
import { snakeCaseStringToSentenceCase } from 'util/snakeCaseStringToSentenceCase';

export enum BooleanDisplayType {
  None,
  Checkbox,
  Switch,
  List,
}

export function getBooleanDisplayType(
  paramDef: BlockParameterDefinition,
): BooleanDisplayType {
  if (paramDef.data_type === 'bool') {
    if (
      paramDef.display_variant === 'switch' &&
      paramDef.allowed_values &&
      paramDef.allowed_values.length === 2
    ) {
      return BooleanDisplayType.Switch;
    }

    if (
      paramDef.display_variant === 'list' &&
      paramDef.allowed_values &&
      paramDef.allowed_values.length === 2
    ) {
      return BooleanDisplayType.List;
    }

    return BooleanDisplayType.Checkbox;
  }
  return BooleanDisplayType.None;
}

export function isDataFileDropdown(
  selectedNode: NodeInstance,
  paramDef: BlockParameterDefinition,
): boolean {
  // Ignore selectedNode class for now, it has proven simpler to just
  // assume that 'file_name'/'python_file' means we need a file from the drop-down :)
  return paramDef.name === 'file_name' || paramDef.name === 'python_file';
}

export function isDataIntegrationIdDropdown(
  paramDef: BlockParameterDefinition,
): boolean {
  return paramDef.name === 'data_integration_id';
}

export function isParameterReadOnly(
  selectedNode: NodeInstance,
  paramDef: BlockParameterDefinition,
) {
  if (paramDef.read_only) return true;

  switch (selectedNode.type) {
    case 'core.Saturate':
    case 'core.RateLimiter':
      if (paramDef.name === 'upper_limit') {
        return (
          selectedNode.parameters.enable_dynamic_upper_limit?.value === 'true'
        );
      }
      if (paramDef.name === 'lower_limit') {
        return (
          selectedNode.parameters.enable_dynamic_lower_limit?.value === 'true'
        );
      }
      return false;
    case 'core.Integrator':
    case 'core.IntegratorDiscrete':
      if (paramDef.name === 'enable_external_reset') {
        return selectedNode.parameters.enable_reset?.value === 'false';
      }
      return false;
    default:
      return false;
  }
}

export function isParameterHidden(
  selectedNode: NodeInstance,
  paramDef: BlockParameterDefinition,
  userOptions: UserOptions,
) {
  if (paramDef.hidden) return true;
  if (paramDef.feature_level === 'disabled') return true;
  if (paramDef.feature_level === 'dev' && !userOptions.developerModeEnabled)
    return true;

  // HACK: this is to prevent default_value param displaying
  // for inports which have the param scrubbed.
  // (the only case in which this happens is for inport blocks in subdiagrams that are not ref-submodels)
  // (see line ~450-500 in insertNodeUtils.ts for the other half of this HACK explanation)
  if (
    paramDef.name === 'default_value' &&
    !selectedNode.parameters.default_value
  ) {
    return true;
  }

  switch (selectedNode.type) {
    case 'core.DataSource':
      if (paramDef.name === 'time_column') {
        return selectedNode.parameters.time_samples_as_column?.value !== 'true';
      }
      if (paramDef.name === 'sampling_interval') {
        return selectedNode.parameters.time_samples_as_column?.value === 'true';
      }
      return false;
    case 'core.MatrixOperator':
      if (paramDef.name === 'concatenation_orientation') {
        return selectedNode.parameters.function?.value !== 'concatenation';
      }
      if (paramDef.name === 'multiplication_type') {
        return selectedNode.parameters.function?.value !== 'multiply';
      }
      if (paramDef.name === 'extraction_index') {
        return selectedNode.parameters.function?.value !== 'extraction';
      }
      return false;
    case 'core.Slice':
      if (paramDef.name === 'index') {
        return selectedNode.parameters.input_type?.value !== 'vector';
      }
      if (paramDef.name === 'row') {
        return selectedNode.parameters.input_type?.value !== 'matrix';
      }
      if (paramDef.name === 'column') {
        return selectedNode.parameters.input_type?.value !== 'matrix';
      }
      return false;
    case 'core.Integrator':
    case 'core.IntegratorDiscrete':
      if (paramDef.name === 'upper_limit') {
        return selectedNode.parameters.enable_limits?.value !== 'true';
      }
      if (paramDef.name === 'lower_limit') {
        return selectedNode.parameters.enable_limits?.value !== 'true';
      }
      return false;
    case 'core.PID':
    case 'core.PID_Discrete':
      if (paramDef.name === 'initial_state') {
        return (
          selectedNode.parameters.enable_external_initial_state?.value ===
          'true'
        );
      }
      return false;
    case 'core.FilterDiscrete':
      if (paramDef.name === 'a_coefficients') {
        return selectedNode.parameters.filter_type?.value === 'FIR';
      }
      return false;
    case 'core.RigidBody':
      if (paramDef.name === 'mass') {
        return selectedNode.parameters.enable_external_mass?.value === 'true';
      }
      if (paramDef.name === 'enable_external_inertia_matrix') {
        return selectedNode.parameters.enable_rotational_DoFs?.value !== 'true';
      }
      if (paramDef.name === 'inertia_matrix') {
        return (
          selectedNode.parameters.enable_rotational_DoFs?.value !== 'true' ||
          selectedNode.parameters.enable_external_inertia_matrix?.value ===
            'true'
        );
      }
      if (paramDef.name === 'Wx0') {
        return selectedNode.parameters.enable_rotational_DoFs?.value !== 'true';
      }
      if (paramDef.name === 'ThetaX0') {
        return selectedNode.parameters.enable_rotational_DoFs?.value !== 'true';
      }
      if (paramDef.name === 'Wy0') {
        return selectedNode.parameters.enable_rotational_DoFs?.value !== 'true';
      }
      if (paramDef.name === 'ThetaY0') {
        return selectedNode.parameters.enable_rotational_DoFs?.value !== 'true';
      }
      if (paramDef.name === 'Wz0') {
        return selectedNode.parameters.enable_rotational_DoFs?.value !== 'true';
      }
      if (paramDef.name === 'ThetaZ0') {
        return selectedNode.parameters.enable_rotational_DoFs?.value !== 'true';
      }
      return false;
    case 'core.CoordinateRotation':
      if (
        paramDef.name === 'quaternion_w' ||
        paramDef.name === 'quaternion_x' ||
        paramDef.name === 'quaternion_y' ||
        paramDef.name === 'quaternion_z'
      ) {
        return (
          selectedNode.parameters.enable_external_rotation_definition?.value ===
            'true' ||
          selectedNode.parameters.rotation_type?.value !== 'quaternion'
        );
      }
      if (
        paramDef.name === 'roll' ||
        paramDef.name === 'pitch' ||
        paramDef.name === 'yaw'
      ) {
        return (
          selectedNode.parameters.enable_external_rotation_definition?.value ===
            'true' ||
          selectedNode.parameters.rotation_type?.value !== 'roll_pitch_yaw'
        );
      }
      if (paramDef.name === 'DCM_matrix') {
        return (
          selectedNode.parameters.enable_external_rotation_definition?.value ===
            'true' || selectedNode.parameters.rotation_type?.value !== 'DCM'
        );
      }
      return false;
    case 'core.CoordinateRotationConversion':
      if (paramDef.name === 'quaternion_w') {
        return selectedNode.parameters.filter_type?.value === 'FIR';
      }
      return false;
    default:
      return false;
  }
}

export function getParameterDisplayName(
  nodeType: BlockClassName,
  paramDef: BlockParameterDefinition,
): string {
  if (paramDef.display_name) {
    return paramDef.display_name;
  }

  const paramName = paramDef.name;
  // Expose localized names where the display name is different from the name in the schema.
  switch (nodeType) {
    case 'core.ScalarBroadcast':
      switch (paramName) {
        case 'm':
          return t({
            id: 'BlockParameterLabel.ScalarBroadcast.Rows',
            message: 'Rows',
          });
        case 'n':
          return t({
            id: 'BlockParameterLabel.ScalarBroadcast.Columns',
            message: 'Columns',
          });
      }
      break;
    case 'core.Integrator':
    case 'core.IntegratorDiscrete':
      switch (paramName) {
        case 'enable_external_reset':
          return t({
            id: 'BlockParameterLabel.Integrator.EnableResetValue',
            message: 'Enable external reset value',
          });
      }
      break;
    case 'core.StateMachine':
    case 'core.PythonScript':
    case 'core.RandomNormal':
    case 'core.Constant':
      switch (paramName) {
        case 'shape':
          return t({
            id: 'BlockParameter.OutputLabel.Dimensions',
            message: 'Dimensions',
          });
        case 'dtype':
          return t({
            id: 'BlockParameter.OutputLabel.DataType',
            message: 'Data type',
          });
      }
      break;
    case 'core.MatrixOperator':
      if (paramName === 'concatenation_orientation') {
        return t({
          id: 'BlockParameterLabel.MatrixOperator.ConcatenationOrientation',
          message: 'Orientation',
        });
      }
      if (paramName === 'multiplication_type') {
        return t({
          id: 'BlockParameterLabel.MatrixOperator.MultiplicationType',
          message: 'Type',
        });
      }
      if (paramName === 'extraction_index') {
        return t({
          id: 'BlockParameterLabel.MatrixOperator.ExtractionIndex',
          message: 'Index',
        });
      }
      break;
    case 'core.CoordinateRotation':
      if (paramName === 'quaternion_w') {
        return t({
          id: 'BlockParameterLabel.CoordinateRotation.quaternion_w',
          message: 'q_w',
        });
      }
      if (paramName === 'quaternion_x') {
        return t({
          id: 'BlockParameterLabel.CoordinateRotation.quaternion_x',
          message: 'q_x',
        });
      }
      if (paramName === 'quaternion_y') {
        return t({
          id: 'BlockParameterLabel.CoordinateRotation.quaternion_y',
          message: 'q_y',
        });
      }
      if (paramName === 'quaternion_z') {
        return t({
          id: 'BlockParameterLabel.CoordinateRotation.quaternion_z',
          message: 'q_z',
        });
      }
      if (paramName === 'roll') {
        return t({
          id: 'BlockParameterLabel.CoordinateRotation.roll',
          message: 'roll',
        });
      }
      if (paramName === 'pitch') {
        return t({
          id: 'BlockParameterLabel.CoordinateRotation.pitch',
          message: 'pitch',
        });
      }
      if (paramName === 'yaw') {
        return t({
          id: 'BlockParameterLabel.CoordinateRotation.yaw',
          message: 'yaw',
        });
      }
      break;
  }

  // TODO localize parameter names.
  return snakeCaseStringToSentenceCase(paramName);
}

export function getParameterParamOptionDisplayName(
  nodeType: BlockClassName,
  paramName: string,
  paramOptionName: string,
): string {
  // Expose localized names where the display name is different from the name in the schema.
  switch (nodeType) {
    case 'core.SignalDatatypeConversion':
      if (paramName === 'rounding_operation') {
        switch (paramOptionName) {
          case 'none':
            return t({
              id: 'BlockParameterLabel.SignalDatatypeConversion.none',
              message: 'None (truncate)',
            });
          case 'to_neraest':
            return t({
              id: 'BlockParameterLabel.SignalDatatypeConversion.to_nearest',
              message: 'To nearest',
            });
          case 'downward':
            return t({
              id: 'BlockParameterLabel.SignalDatatypeConversion.downward',
              message: 'Downward (-infinity)',
            });
          case 'upward':
            return t({
              id: 'BlockParameterLabel.SignalDatatypeConversion.upward',
              message: 'Upward (+infinity)',
            });
          case 'toward_zero':
            return t({
              id: 'BlockParameterLabel.SignalDatatypeConversion.toward_zero',
              message: 'Toward zero',
            });
        }
      }
      break;
    case 'core.CoordinateRotationConversion':
      if (paramName === 'conversion_type') {
        if (paramOptionName === 'quaternion_to_DCM') {
          return t({
            id: 'BlockParameterLabel.CoordinateRotationConversion.quaternion_to_DCM',
            message: 'Quaternion to DCM',
          });
        }
        if (paramOptionName === 'quaternion_to_RPY') {
          return t({
            id: 'BlockParameterLabel.CoordinateRotationConversion.quaternion_to_RPY',
            message: 'Quaternion to Roll-Pitch-Yaw',
          });
        }
        if (paramOptionName === 'DCM_to_quaternion') {
          return t({
            id: 'BlockParameterLabel.CoordinateRotationConversion.DCM_to_quaternion',
            message: 'DCM to Quaternion',
          });
        }
        if (paramOptionName === 'DCM_to_RPY') {
          return t({
            id: 'BlockParameterLabel.CoordinateRotationConversion.DCM_to_RPY',
            message: 'DCM to Roll-Pitch-Yaw',
          });
        }
        if (paramOptionName === 'RPY_to_quaternion') {
          return t({
            id: 'BlockParameterLabel.CoordinateRotationConversion.RPY_to_quaternion',
            message: 'Roll-Pitch-Yaw to Quaternion',
          });
        }
        if (paramOptionName === 'RPY_to_DCM') {
          return t({
            id: 'BlockParameterLabel.CoordinateRotationConversion.RPY_to_DCM',
            message: 'Roll-Pitch-Yaw to DCM',
          });
        }
        break;
      }
  }

  // TODO localize parameter option names.
  return snakeCaseStringToSentenceCase(paramOptionName);
}
