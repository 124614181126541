import { useProjectItems } from 'app/api/useProjectItems';
import { useProjectOrRedirect } from 'app/behaviorHooks/useProjectOrRedirect';
import React from 'react';
import { ProjectItemType } from 'ui/dashboard/dashboardTypes';
import { BlockLibraryDragItem } from 'ui/objectBrowser/sections/BlockLibraryDragItem';
import LibrarySubsection from 'ui/objectBrowser/sections/LibrarySubsection';
import { useAppParams } from 'util/useAppParams';

interface Props {
  lowercaseSearchString: string;
  sectionTitle: string;
}

export const ExperimentModelBlocks: React.FC<Props> = ({
  lowercaseSearchString,
  sectionTitle,
}) => {
  const { modelId } = useAppParams();
  const { project } = useProjectOrRedirect();
  const { projectItems } = useProjectItems(project);
  const projectModels = projectItems.filter((item) => {
    const isValidModel =
      item.type === ProjectItemType.MODEL && item.uuid != modelId;

    if (
      lowercaseSearchString?.length > 0 &&
      item.name.toLowerCase().indexOf(lowercaseSearchString) < 0
    ) {
      return false;
    }

    return isValidModel;
  });

  return (
    <LibrarySubsection title={sectionTitle} count={projectModels.length}>
      {projectModels.map((projectModel) => (
        <BlockLibraryDragItem
          blockClassName="core.ExperimentModel"
          overridePropDefaults={{
            name: projectModel.name,
            parameters: { model: { value: projectModel.uuid } },
          }}
          overrideDisplayName={projectModel.name}
          key={`experiment-${projectModel.uuid}`}
        />
      ))}
    </LibrarySubsection>
  );
};
