import styled from '@emotion/styled/macro';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Cell, Column, Row, TableState } from 'react-table';

import { useAdminApis } from 'app/api/useAdminApis';
import {
  apiDeleteClerkWhitelistById,
  apiPostClerkWhitelistNew,
} from 'app/apiData';
import {
  AdminModifyUserRequest,
  AdminUserInfo,
} from 'app/apiGenerated/generatedApiTypes';
import {
  getAdminAssumedUser,
  setAdminAssumedUser,
} from 'app/config/adminAssumedUser';
import { useAppSelector } from 'app/hooks';
import { downloadSimulationDump } from 'app/utils/simulationDump';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Checkbox } from 'ui/common/Checkbox';
import * as Icons from 'ui/common/Icons';
import { BackArrow } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import Label from 'ui/common/Label';
import Table from 'ui/common/Table/Table';
import * as Headings from 'ui/common/typography/Typography';
import { formatDate } from 'util/dateUtils';

const Wrapper = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.ui.background};
`;

const Content = styled.section`
  background-color: ${(props) => props.theme.colors.grey[5]};
  border-radius: ${(props) => props.theme.spacing.small};
  margin: ${(props) => props.theme.spacing.xlarge};
  padding: ${(props) => props.theme.spacing.xlarge};
  overflow-y: auto;
  overflow-x: hidden;
  font-size: ${(props) => props.theme.typography.font.small.size};
`;

const Head = styled.header`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.xlarge};
  padding: 10px;
`;

const Title = styled(Headings.H2)`
  width: 100%;
`;

const TableContainer = styled.section`
  width: 100%;
`;

const InputGroup = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.large};
  padding: ${(props) => props.theme.spacing.large};
  background: white;
`;
const Actions = styled.div`
  margin-top: ${(props) => props.theme.spacing.large};
`;

const TableInputGroup = styled.div`
  padding: 0;
  margin: 0;
  width: 60px;
  background: white;
`;

const ActionGroup = styled.div`
  display: flex;
`;

const ButtonGroup = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  column-gap: 8px;
`;

const DateCell: React.FC<{ value: string }> = ({ value }) => (
  <span title={value}>{formatDate(value)}</span>
);

const UsersList: React.FC = () => {
  const navigate = useNavigate();
  const { users, isFetchingUsers, refetch, modifyAdminUser } = useAdminApis();
  const userId = useAppSelector((state) => state.user.userId);
  const [usersTableData, setUsersTableData] = React.useState<AdminUserInfo[]>(
    [],
  );
  const { clerkWhitelistEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  React.useEffect(() => {
    if (users && users.users && !isFetchingUsers) {
      setUsersTableData(users.users);
    }
  }, [users, refetch, isFetchingUsers]);

  const initialState: Partial<TableState<AdminUserInfo>> = {
    hiddenColumns: ['id'],
  };

  const [newIdentifier, setNewIdentifier] = React.useState('');

  const onAdd = (identifier: string) =>
    apiPostClerkWhitelistNew(identifier, false, () => refetch(), console.error);
  const onAssumeRole = (userUuid: string | undefined) =>
    setAdminAssumedUser(userUuid);

  const ToSCell = React.useCallback(
    (cell: Cell<AdminUserInfo>) => (
      <Checkbox value={!!cell.row.original.signed_beta_tos} />
    ),
    [],
  );
  const AssumeRoleCell = React.useCallback(
    (cell: Cell<AdminUserInfo>) =>
      cell.row.original.uuid ? (
        <Button
          Icon={
            cell.row.original.uuid == getAdminAssumedUser()
              ? Icons.Standard.EyeCrossed
              : Icons.Standard.Eye
          }
          variant={ButtonVariants.LargeTertiary}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            onAssumeRole(
              cell.row.original.uuid == getAdminAssumedUser()
                ? ''
                : cell.row.original.uuid,
            );
          }}
        />
      ) : null,
    [],
  );
  const RemoveCell = React.useCallback(
    (cell: Cell<AdminUserInfo>) => {
      const onDelete = (identifierId: string) =>
        apiDeleteClerkWhitelistById(
          identifierId,
          () => refetch(),
          console.error,
        );
      return (
        <Button
          Icon={Icons.Standard.Remove}
          variant={ButtonVariants.LargeTertiary}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            onDelete(cell.row.original.id);
          }}
        />
      );
    },
    [refetch],
  );

  const IsDisabledCell = React.useCallback(
    (cell: Cell<AdminUserInfo>) => (
      <Checkbox
        onChange={async () => {
          const userUuid = cell.row.original.uuid;
          const adminModifyUserRequest: AdminModifyUserRequest = {
            is_disabled: !cell.row.original.is_disabled,
          };

          await modifyAdminUser({ userUuid, adminModifyUserRequest });
          refetch();
        }}
        value={!!cell.row.original.is_disabled}
      />
    ),
    [modifyAdminUser, refetch],
  );

  const columns: Column<AdminUserInfo>[] = [
    {
      Header: 'Name',
      accessor: 'display_name',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      Header: 'Identifier',
      accessor: 'identifier',
      minWidth: 200,
      maxWidth: 500,
    },
    {
      Header: 'Organization',
      accessor: 'organization',
      minWidth: 100,
      maxWidth: 300,
    },
    {
      Header: 'Assume Role',
      minWidth: 50,
      maxWidth: 50,
      Cell: AssumeRoleCell,
    },
    {
      Header: 'ID',
      accessor: 'id',
      minWidth: 200,
      maxWidth: 500,
    },
    {
      Header: 'Added at',
      accessor: 'created_at',
      Cell: DateCell,
      minWidth: 150,
      maxWidth: 0,
    },
    {
      Header: 'Last signed in',
      accessor: 'last_signed_in_at',
      Cell: DateCell,
      minWidth: 150,
      maxWidth: 0,
    },
    {
      Header: 'ToS',
      accessor: 'signed_beta_tos',
      disableSortBy: true,
      minWidth: 20,
      maxWidth: 0,
      Cell: ToSCell,
    },
    {
      Header: 'Disabled',
      accessor: 'is_disabled',
      minWidth: 30,
      maxWidth: 0,
      Cell: IsDisabledCell,
    },
    {
      Header: '',
      id: 'remove',
      disableSortBy: true,
      minWidth: 20,
      maxWidth: 0,
      disableResizing: true,
      Cell: RemoveCell,
    },
  ];

  return (
    <Wrapper>
      <Content>
        <Head>
          <Title>Collimator Users</Title>
          <ButtonGroup>
            <Button
              variant={ButtonVariants.LargeSecondary}
              Icon={BackArrow}
              onClick={() => navigate('/admin')}>
              Admin portal
            </Button>
            <Button onClick={() => navigate(`/admin/users/${userId}`)}>
              My option overrides
            </Button>
            <Button onClick={() => navigate(`/admin/global_projects`)}>
              Global projects
            </Button>
            <Button onClick={() => navigate(`/admin/simulations_queue`)}>
              Simulations queue
            </Button>
            <Button
              onClick={() => {
                const simUuid = prompt('Input simulation UUID below');
                if (simUuid) downloadSimulationDump(simUuid);
              }}>
              Download simulation artifacts
            </Button>
            <Button onClick={() => navigate(`/admin/chat_errors`)}>
              Chat errors
            </Button>
          </ButtonGroup>
        </Head>
        <ActionGroup>
          {clerkWhitelistEnabled && (
            <fieldset>
              <InputGroup>
                <Label>
                  Email or identifier of the user to invite? ex:
                  joshua@collimator.ai or *@collimator.ai
                </Label>
                <Input onChangeText={setNewIdentifier} hasBorder autoFocus />
                <Actions>
                  {' '}
                  <Button
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    ) => {
                      e.preventDefault();
                      onAdd(newIdentifier);
                    }}
                    variant={ButtonVariants.LargePrimary}>
                    Add to Whitelist
                  </Button>
                </Actions>
              </InputGroup>
            </fieldset>
          )}
        </ActionGroup>
        <TableContainer>
          <Table
            columns={columns}
            data={usersTableData || []}
            onRowDoubleClick={(data) => navigate(`/admin/users/${data.uuid}`)}
            initialState={initialState}
            getRowTestId={(row: Row<AdminUserInfo>) => row.original.id}
          />
        </TableContainer>
      </Content>
    </Wrapper>
  );
};

export default UsersList;
