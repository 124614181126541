/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Account: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 11.8475C13.0227 11.8475 13.8519 11.0127 13.8519 9.98305C13.8519 8.95337 13.0227 8.11864 12 8.11864C10.9773 8.11864 10.1481 8.95337 10.1481 9.98305C10.1481 11.0127 10.9773 11.8475 12 11.8475ZM12 12.9661C13.6364 12.9661 14.963 11.6305 14.963 9.98305C14.963 8.33556 13.6364 7 12 7C10.3636 7 9.03704 8.33556 9.03704 9.98305C9.03704 11.6305 10.3636 12.9661 12 12.9661Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.5185 14.2712C9.18894 14.2712 8.11111 15.3563 8.11111 16.6949V17.4407C8.11111 17.7496 7.86238 18 7.55556 18C7.24873 18 7 17.7496 7 17.4407V16.6949C7 14.7385 8.57529 13.1525 10.5185 13.1525H13.4815C15.4247 13.1525 17 14.7385 17 16.6949V17.4407C17 17.7496 16.7513 18 16.4444 18C16.1376 18 15.8889 17.7496 15.8889 17.4407V16.6949C15.8889 15.3563 14.8111 14.2712 13.4815 14.2712H10.5185Z"
      fill={props.fill}
    />
  </svg>
);

Account.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Account;
