import { getNotebookIFrameContent } from 'ui/notebook/notebookUtils';

export const OpenConsole = (consoleOpen: boolean) => {
  if (consoleOpen === false) {
    const iframeDoc = getNotebookIFrameContent();
    const elSelector = iframeDoc?.querySelectorAll(
      '.lm-Widget.p-Widget.jp-MainAreaWidget.jp-NotebookPanel.jp-Document.jp-Activity.lm-DockPanel-widget.p-DockPanel-widget',
    );
    if (elSelector) {
      for (let i = 0; i < elSelector.length; i++) {
        if (!elSelector[i].classList.contains('lm-mod-hidden')) {
          elSelector[i]
            .querySelector(
              '.lm-Widget.p-Widget.jp-Toolbar.jp-NotebookPanel-toolbar .jp-Toolbar-item:nth-child(11) button',
            )
            ?.dispatchEvent(
              new MouseEvent('mousedown', {
                bubbles: true,
                cancelable: true,
              }),
            );
        }
      }
    }
  }
  if (consoleOpen === true) {
    const iframeDoc = getNotebookIFrameContent();
    if (iframeDoc) {
      const openTabList = iframeDoc.querySelectorAll(
        '.jp-RunningSessions-section',
      )[0];
      const openTabs = openTabList?.querySelector(
        '.jp-RunningSessions-sectionContainer',
      );
      if (openTabs) {
        const tablist = openTabs.querySelectorAll('.jp-RunningSessions-item');
        for (let i = 0; i < tablist.length; i++) {
          if (tablist[i].innerHTML.includes('console')) {
            tablist[i]
              .querySelector(
                '.bp3-button.bp3-minimal.jp-RunningSessions-itemShutdown.jp-ToolbarButtonComponent.minimal.jp-Button',
              )
              ?.dispatchEvent(
                new MouseEvent('mousedown', {
                  bubbles: true,
                  cancelable: true,
                }),
              );
          }
        }
      }
    }
  }
};
