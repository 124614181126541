import { t, Trans } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { ModelConfigurationType } from 'app/utils/modelDataUtils';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Settings } from 'ui/common/Icons/Standard';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import { positiveNumberRules } from 'ui/common/Input/inputValidation';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import { useModal } from 'ui/common/Modal/useModal';
import SelectInput from 'ui/common/SelectInput';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsLabel,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import { useModelPermission } from 'ui/permission/useModelPermission';
import inputNumberCallback from 'util/inputNumberCallback';
import { useAppParams } from 'util/useAppParams';
import SimulationSettingsModal from './SimulationSettingsModal';

const recordModeOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'selected',
    label: 'Only selected ports',
  },
];

const workerTypes = ['any', 'cpu', 'gpu'];
const workerTypesOptions = workerTypes.map((o) => ({
  value: o,
  label: o,
}));
const ModelConfigurationDetails = () => {
  const dispatch = useAppDispatch();

  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );
  const { recordModeOptionEnabled, workerTypeSelectionEnabled } =
    useAppSelector((state) => state.userOptions.options);

  const model = useAppSelector((state) => state.model.present);
  const config = model.configuration;

  const { triggerModal } = useModal();

  const changeConfigValue = (name: ModelConfigurationType) =>
    inputNumberCallback((numValue, _decimalStringValue) => {
      if (!isNaN(numValue)) {
        const value = !isNaN(numValue) ? numValue : undefined;
        dispatch(modelActions.changeModelConfigurationValue({ name, value }));
      }
    });

  const changeRecordMode = (value: string) => {
    const change = {
      parameterId: 'record_mode',
      value: value === 'selected' ? 'selected' : 'all',
    };
    dispatch(modelActions.changeModelConfigurationValues([change]));
  };

  const changeWorkerType = (value: string) => {
    const change = {
      parameterId: 'worker_type',
      value,
    };
    dispatch(modelActions.changeModelConfigurationValues([change]));
  };

  const openModelSettingsDialog = () => {
    triggerModal(
      <SimulationSettingsModal />,
      t({
        id: 'modelRenderer.continuousSolverSettingsModal.title',
        message: 'Continuous Solver Settings',
      }),
    );
  };

  return (
    <>
      <SectionHeading testId="simulation-settings">
        {t({
          id: 'modelRenderer.propertiesSidebar.simulationSettings.heading',
          message: 'Simulation settings',
        })}
      </SectionHeading>
      <DetailInputRowsSection key="sim_params">
        <DetailsSection>
          <DetailsLabel>
            {t({
              id: 'modelRenderer.propertiesSidebar.simulationSettings.endTime.label',
              message: 'End time',
            })}
          </DetailsLabel>
          <DetailsInput
            onSubmitValue={changeConfigValue('stop_time')}
            value={config.stop_time}
            align={TextInputAlign.Right}
            disabled={!canEditCurrentModelVersion}
            validationRules={positiveNumberRules}
          />
        </DetailsSection>
        <DetailsSection vertical>
          <DetailsLabel stretch>
            {t({
              id: 'modelRenderer.propertiesSidebar.simulationSettings.globalDiscreteClockTick.label',
              message: 'Global discrete clock tick',
            })}
          </DetailsLabel>
          <DetailsInput
            grow
            onSubmitValue={changeConfigValue('sample_time')}
            value={config.sample_time}
            align={TextInputAlign.Right}
            disabled={!canEditCurrentModelVersion}
            validationRules={positiveNumberRules}
          />
        </DetailsSection>

        {recordModeOptionEnabled && (
          <DetailsSection>
            <DetailsLabel>
              {t({
                id: 'modelRenderer.simulationSettings.recordMode.label',
                message: 'Record outputs',
              })}
            </DetailsLabel>
            <SelectInput
              options={recordModeOptions}
              currentValue={config.record_mode || 'all'}
              onSelectValue={changeRecordMode}
              isDisabled={!canEditCurrentModelVersion}
            />
          </DetailsSection>
        )}
        {workerTypeSelectionEnabled && (
          <DetailsSection>
            <DetailsLabel>
              {t({
                id: 'modelRenderer.simulationSettings.workerType.label',
                message: 'Worker type',
              })}
            </DetailsLabel>
            <SelectInput
              options={workerTypesOptions}
              currentValue={config.worker_type || 'any'}
              onSelectValue={changeWorkerType}
              isDisabled={!canEditCurrentModelVersion}
            />
          </DetailsSection>
        )}

        <DetailsSection>
          <Button
            variant={ButtonVariants.SmallTertiary}
            Icon={Settings}
            onClick={openModelSettingsDialog}>
            <Trans id="modelDetails.simulationConfigPanelContinuousSolverSettingsButton">
              Continuous solver settings
            </Trans>
          </Button>
        </DetailsSection>
      </DetailInputRowsSection>
    </>
  );
};

export default ModelConfigurationDetails;
