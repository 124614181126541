import type { Coordinate } from 'app/common_types/Coordinate';
import { MouseActions } from 'app/common_types/MouseTypes';
import { HoverEntityType } from 'app/common_types/SegmentTypes';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { modelActions } from 'app/slices/modelSlice';
import { rcMenuActions } from 'app/slices/rcMenuSlice';
import { RCMenuSelection } from 'ui/modelEditor/RightClickMenu';
import { convertZoomedScreenToWorldCoordinates } from '../convertScreenToWorldCoordinates';
import { RendererState } from '../modelRenderer';
import { getHoveringEntity } from '../mouseInput';
import { transitionMouseState } from '../transitionMouseState';

export const mouseInputRightClick = (
  rs: RendererState,
  zoomedScreenCoord: Coordinate,
  rawScreenCoord: Coordinate,
): void => {
  const selectedEntitiesCount =
    rs.refs.current.selectedLinkIds.length +
    rs.refs.current.selectedNodeIds.length;

  if (selectedEntitiesCount > 1) {
    rs.dispatch(
      rcMenuActions.openWithContent({
        coord: rawScreenCoord,
        content: { type: RCMenuSelection.MultiEntity },
      }),
    );
  } else {
    const worldCursor = convertZoomedScreenToWorldCoordinates(
      rs.camera,
      zoomedScreenCoord,
    );

    const hoveringEntity = getHoveringEntity(
      rs.mouseState,
      worldCursor,
      rs.camera,
      rs.refs.current.nodes,
      rs.refs.current.links,
      rs.refs.current.annotations,
      rs.refs.current.linksIndexLUT,
      rs.refs.current.visualizerPrefs,
      rs.linksRenderFrameData,
      getCurrentModelRef().submodelPath,
    );

    if (hoveringEntity) {
      if (hoveringEntity.entityType === HoverEntityType.Node) {
        rs.dispatch(
          modelActions.setSelections({
            selectionParentPath: getCurrentModelRef().submodelPath,
            selectedBlockIds: [hoveringEntity.block.uuid],
            selectedLinkIds: [],
            selectedAnnotationIds: [],
          }),
        );
      }
      if (
        hoveringEntity.entityType === HoverEntityType.Link ||
        hoveringEntity.entityType === HoverEntityType.FakeLinkSegment
      ) {
        rs.dispatch(
          modelActions.setSelections({
            selectionParentPath: getCurrentModelRef().submodelPath,
            selectedBlockIds: [],
            selectedLinkIds: [hoveringEntity.linkUuid],
            selectedAnnotationIds: [],
          }),
        );
      }

      rs.dispatch(
        rcMenuActions.openWithContent({
          coord: rawScreenCoord,
          content: {
            type: RCMenuSelection.SingleEntity,
            entity: hoveringEntity,
          },
        }),
      );
    } else {
      rs.dispatch(
        rcMenuActions.openWithContent({
          coord: rawScreenCoord,
          content: { type: RCMenuSelection.NoEntity },
        }),
      );
    }
  }

  transitionMouseState(rs, { state: MouseActions.Idle });
};
