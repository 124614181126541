import * as NVG from 'nanovg-js';
import { renderConstants } from 'app/utils/renderConstants';
import { BlockClassName } from 'app/generated_types/SimulationModel';
import { getIsNodeSupported } from 'util/portTypeUtils';
import { RendererState } from './modelRenderer';
import {
  getOrInitLoadImageFromStore,
  RasterLoadState,
} from './rasterTextureStore';

export function drawSignalPlotter(
  nvg: NVG.Context,
  rs: RendererState,
  posX: number,
  posY: number,
  nodeWidth: number,
  nodeId: string,
  parentPath: string[],
  nodeType: BlockClassName,
  leftSide?: boolean,
): void {
  const { getAreAnyNodePortsInChart } = rs.refs.current.visualizerPrefs;
  if (!getIsNodeSupported(nodeId, parentPath)) {
    return;
  }

  const active = getAreAnyNodePortsInChart({ nodeId, parentPath });

  const rawScale = Math.round(window.devicePixelRatio * rs.zoom);
  const scale = rawScale > 2 ? 4 : rawScale < 1 ? 1 : rawScale;
  const baseRasterID = `plotter_toggle_${active ? 'active' : 'inactive'}`;
  const scaledRasterID = `${baseRasterID}_${scale}x`;

  const rasterMeta = getOrInitLoadImageFromStore(
    nvg,
    `${process.env.PUBLIC_URL}/assets/${scaledRasterID}.png`,
    scaledRasterID,
    scale,
  );

  if (rasterMeta?.loadState === RasterLoadState.Loaded) {
    const raw_w = rasterMeta.width / scale;
    const raw_h = rasterMeta.height / scale;
    const raw_x = leftSide ? posX - raw_w : posX + nodeWidth;
    const raw_y = posY - renderConstants.SIGNAL_PLOTTER_GRAPHIC_OFFSET_Y;

    const rx = leftSide ? (raw_x + raw_w) * rs.zoom : raw_x * rs.zoom;
    const ry = raw_y * rs.zoom;
    const rw = leftSide ? -raw_w * rs.zoom : raw_w * rs.zoom;
    const rh = raw_h * rs.zoom;

    const imgPaint = nvg.imagePattern(rx, ry, rw, rh, 0, rasterMeta.imageId, 1);
    nvg.beginPath();
    nvg.rect(rx, ry, rw, rh);
    nvg.fillPaint(imgPaint);
    nvg.fill();
  }
}
