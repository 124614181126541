import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { generatedApi } from 'app/apiGenerated/generatedApi';
import { SimulationResultsS3Url } from 'app/apiGenerated/generatedApiTypes';
import { groupFileData } from 'app/utils/visualizationUtils';
import React from 'react';

type FetchCsvArgs = {
  chartIds: string[];
  files?: SimulationResultsS3Url[];
};

type FetchCsvResponse = {
  raw: {
    url: string;
    text: string;
  }[];
  processed: { time: number; [chartId: string]: number }[];
};

const injectedApi = generatedApi.injectEndpoints({
  endpoints: (build) => ({
    getCsvFile: build.query<FetchCsvResponse, FetchCsvArgs>({
      async queryFn(args, _queryApi, _extraOptions, _fetchWithBQ) {
        const { chartIds, files } = args;
        if (!files) return { data: { raw: [], processed: [] } };
        try {
          let promises = chartIds.map((chartId) => {
            const fileUrl = files.find((f) => f.name === chartId)?.url;
            if (fileUrl) {
              return fetch(fileUrl).then((data) => ({ chartId, data }));
            }
            return Promise.reject(
              new Error(`File URL not found for chart ID ${chartId}`),
            );
          });
          const responses = await Promise.all(promises);
          const texts = await Promise.all(
            responses.map(async (resp) => {
              const text = await resp.data.text();
              return { url: resp.chartId, text };
            }),
          );

          return {
            data: {
              raw: texts,
              processed: groupFileData(texts?.map((csv) => csv.text) || []),
            },
          };
        } catch (error: any) {
          return { error: error as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const {
  useGetCsvFileQuery,
  useLazyGetCsvFileQuery,
  useLazyGetSimulationProcessResultsReadByUuidQuery,
} = injectedApi;

const resultsFiles = ['continuous_results.csv'];

export function useVisualizerAllData() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [triggerGetCsv] = useLazyGetCsvFileQuery();
  const [triggerProcessResult] =
    useLazyGetSimulationProcessResultsReadByUuidQuery();

  const fetchAllData = async ({
    simulationId,
    modelId,
  }: {
    simulationId: string;
    modelId: string;
  }) => {
    setIsLoading(true);
    const files = await triggerProcessResult({
      modelUuid: modelId,
      simulationUuid: simulationId,
      files: resultsFiles.join(','),
    }).unwrap();
    const response = await triggerGetCsv({
      chartIds: resultsFiles,
      files: files?.s3_urls,
    }).unwrap();
    setIsLoading(false);
    return response.raw[0].text;
  };
  return {
    isLoading,
    fetchAllData,
  };
}
