import { NodeInstance } from 'app/generated_types/SimulationModel';
import { renderConstants } from 'app/utils/renderConstants';

export const PORT_BLOCK_HEIGHT = 2 * renderConstants.GRID_SIZE;
export const PORT_BLOCK_YOFFSET =
  renderConstants.BLOCK_MIN_HEIGHT / 2 - PORT_BLOCK_HEIGHT / 2;

export const CONSTANT_BLOCK_MINHEIGHT = PORT_BLOCK_HEIGHT;

const nodeSizeCache: {
  [k: string]:
    | {
        height: number;
        inputCount: number;
        outputCount: number;
        gridHeight: number | undefined;
      }
    | undefined;
} = {};

export const getMinimumVisualNodeHeight = (node?: NodeInstance): number => {
  if (!node) return renderConstants.BLOCK_MIN_HEIGHT;

  if (node.type === 'core.Constant') {
    return CONSTANT_BLOCK_MINHEIGHT;
  }

  const paddedPortHeightInGridSpaces =
    Math.max(node.inputs.length, node.outputs.length) * 2;

  return Math.max(
    renderConstants.BLOCK_MIN_HEIGHT,
    (paddedPortHeightInGridSpaces + 2) * renderConstants.GRID_SIZE,
  );
};

export const getVisualNodeHeight = (node?: NodeInstance): number => {
  if (!node) return renderConstants.BLOCK_MIN_HEIGHT;

  if (node.type === 'core.Inport' || node.type === 'core.Outport') {
    return PORT_BLOCK_HEIGHT;
  }

  const cached = nodeSizeCache[node.uuid];

  if (
    cached &&
    cached.inputCount === node.inputs.length &&
    cached.outputCount === node.outputs.length &&
    cached.gridHeight === node.uiprops.grid_height
  ) {
    return cached.height;
  }

  const minViableHeight = getMinimumVisualNodeHeight(node);
  const gridHeight = node.uiprops.grid_height || 0;

  const calculatedNodeHeight = Math.max(
    gridHeight * renderConstants.GRID_SIZE,
    minViableHeight,
  );

  nodeSizeCache[node.uuid] = {
    inputCount: node.inputs.length,
    outputCount: node.outputs.length,
    height: calculatedNodeHeight,
    gridHeight: node.uiprops.grid_height,
  };

  return calculatedNodeHeight;
};
